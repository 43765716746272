import React from 'react'
import styled, { css } from 'styled-components'
import { useScreenSize } from '../../Contexts/ResponsiveContextProvider'
import importer from '@takedapdt/biolife-core/src/importer'
const PrimaryButton = importer('Components/Buttons/PrimaryButton')
const { getCssColorFromDrupalColorType, setTextColor } = importer('Constant/Utils', null)

const alignments = {
  1: 'flex-start',
  2: 'flex-end',
  4: 'center'
}

const StyledButtonCTAContainerStyled = css`
  background: ${(props) => getCssColorFromDrupalColorType(props.bgColor)};
`

const ButtonCTAAlignmentStyles = css`
  display: flex;
  justify-content: ${(props) => alignments[props.alignment]};
`

const StyledButtonCTA = styled.div`
  ${(props) => props.bgColor && StyledButtonCTAContainerStyled};
  ${({ alignment }) => alignment && ButtonCTAAlignmentStyles}
`
const Container = styled.div`
  background: ${(props) => getCssColorFromDrupalColorType(props.bgColor)};

  & + .styled-cta-container {
    .main-container.styled-cta {
      margin-top: 1.6rem !important;
    }
  }
`
const ButtonCTA = (props) => {
  const { field_cta_1: link, field_cta_1_style: link_style, field_bg_color, field_cta_alignment, disable_bg_color, fluid } = props
  const { isMobileWidth } = useScreenSize()

  return (
    <Container bgColor={disable_bg_color ? 'transparent' : field_bg_color} className='styled-cta-container'>
      <StyledButtonCTA className={`main-container styled-cta ${props.className}`} alignment={field_cta_alignment}>
        <PrimaryButton text={link?.title} variant={link_style} sendTo={link?.uri} fluid={fluid ? fluid : isMobileWidth} />
      </StyledButtonCTA>
    </Container>
  )
}

export default ButtonCTA
