import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'
import importer from '@takedapdt/biolife-core/src/importer'
const AccountCreate = importer('Components/AccountCreate')
const PublicContentActions = importer('Stores/PublicContent/Actions')
const { SLUG_TYPES } = importer('Sagas/Constants/drupalConstants', null)
const { getPathDetails } = importer('Helpers/Utils', null)
const { scrollToTop } = importer('Helpers/Utils', null)

const CreateAccount = ({ getSignupStepTwoData, signupStepTwoData }) => {
  /* 
  Scroll to the top when the component mounts
  */
  useEffect(() => {
    scrollToTop()
  }, [])
  useEffect(() => {
    getSignupStepTwoData(getPathDetails.slugType || SLUG_TYPES?.CREATEACCOUNT)
  }, [])

  return (
    <>
      <Helmet>
        <title>{`${signupStepTwoData?.metadata?.meta_title}`}</title>
        <meta name='description' content={`${signupStepTwoData?.metadata?.meta_description}`} />
        <link rel='canonical' href={`${window.location.origin}${window.location.pathname.toLowerCase()}`} />
      </Helmet>
      <AccountCreate signupPageData={signupStepTwoData} />
    </>
  )
}

const mapStateToProps = (state) => ({
  signupStepTwoData: state.publicContent?.getRegistrationPageSuccess?.createAccount
})

const mapDispatchToProps = (dispatch) => ({
  getSignupStepTwoData: (formType) => dispatch(PublicContentActions.getRegistrationPage(formType))
})

export default connect(mapStateToProps, mapDispatchToProps)(CreateAccount)
