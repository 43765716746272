import React from 'react'
import { Image } from 'semantic-ui-react'
import importer from '@takedapdt/biolife-core/src/importer'
const { getCssColorFromDrupalColorType, setTextColor } = importer('Constant/Utils', null)
const { COMMONVIEW_TYPES } = importer('Components/RegistrationFormComponent/utility', null)

function CommonViews({ type, contentData = {}, styleName = '' }) {
  // Common Views is being used for rendering the common Components Available in the component.
  // type: type of component we want to render
  // contentData: The data we need to show inside the component
  // styleName: className
  switch (type) {
    case COMMONVIEW_TYPES.COMMON_TEXT_COMPONENT: // Rendering Common Text Component
      return (
        <div
          dangerouslySetInnerHTML={{ __html: contentData?.processedText || '<span></span>' }}
          style={{ ...setTextColor(contentData?.field_color || 'rich_black') }}
          className={styleName}
        />
      )
    case COMMONVIEW_TYPES.SIGNUP_IMG_COMP: // Rendering The Image
      return (
        <div className='imageDesign'>
          <Image src={contentData?.url} alt={contentData?.alt} className={styleName} size='large' floated='left' />
        </div>
      )
    default:
      return <></>
  }
}

export default CommonViews
