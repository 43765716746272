import styled from 'styled-components'
import { devices } from '../../../../utils/styledComponentHelpers'

export const ModalContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: ${({ $userStyles }) => {
    return $userStyles.bottom || 0
  }};
`

export const ModalOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  background-color: var(--color-black);
  z-index: 1;
  opacity: 27%;
  border-radius: 0px 0px 22px 22px;
`

export const ModalContentWrapper = styled.div`
  position: absolute;
  top: 0;
  z-index: 1002;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const ModalContent = styled.div`
  position: relative;
  padding: 10px;
  display: flex;
  flex-direction: column;
  background-color: var(--color-white);
  border-radius: 25px;
  max-width: ${({ $maxWidth }) => (typeof $maxWidth === 'number' ? `${$maxWidth}px` : $maxWidth || 'auto')};
  max-height: ${({ $maxHeight }) => (typeof $maxHeight === 'number' ? `${$maxHeight}px` : $maxHeight || 'auto')};
  z-index: 2;
  overflow-y: auto;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
  ${devices.mobile} {
    /* width: ${({ isVideoModal }) => isVideoModal && ' 86.5vw'}; */
    /* height: ${({ isVideoModal }) => 100 % (isVideoModal ? '50vw' : '100%')};
    position: ${({ isVideoModal }) => isVideoModal && 'unset'};
    max-width: ${({ $maxWidthMobile }) => (typeof $maxWidthMobile === 'number' ? `${$maxWidthMobile}px` : $maxWidthMobile || 'auto')};
    max-height: ${({ $maxHeightMobile }) => `${$maxHeightMobile}px` || 'auto'};
    overflow-y: ${({ isVideoModal }) => (isVideoModal ? 'unset' : 'auto')}; */
  }
`
export const ModalHeaderDiv = styled.div`
  display: flex;
  justify-content: flex-end;
`

export const StyledImage = styled.img`
  height: 40px;
  width: 40px;
  cursor: pointer;
`

export const PaddingIfNoCross = styled.div`
  padding: 1rem;
`
