import React from 'react'
import { Modal, Image } from 'semantic-ui-react'
import styled from 'styled-components'
import CloseIcon from '../../../assets/media/icons/button-close-large-light-gray.svg'
const { devices } = importer('utils/styledComponentHelpers', null)
import importer from '@takedapdt/biolife-core/src/importer'

const CustomModalContainer = styled(Modal)`
  border-radius: 30px !important;
  padding: 4.8rem 6.4rem;
  width: 70%;
  ${devices.mobile} {
    width: 90%;
    padding: 4.8rem 2.4rem !important;
  }
`

const Header = styled(Modal.Header)`
  border: none !important;
  line-height: 30rem !important;
  display: flex !important;
  ${devices.mobile} {
    padding: 25px 20px !important;
  }
`

const Title = styled.span`
  float: left;
  word-wrap: break-word;
  ${devices.mobile} {
    float: none;
    display: block;
  }
`

const CloseButton = styled(Image)`
  cursor: pointer;
  float: right;
  position: absolute !important;
  top: 1.6rem;
  right: 2.2rem;
`

const Content = styled(Modal.Content)`
  padding: 30px 60px;
  ${devices.mobile} {
    padding: 25px !important;
  }
`

const Description = styled.p`
  margin-bottom: 20px;
  text-align: left;
  word-wrap: break-word;
  ${devices.mobile} {
    text-align: left;
    margin-bottom: 20px;
  }
`
const CustomModalDescription = styled(Modal.Description)`
  word-wrap: break-word;
  padding: 0px;
`

// props : header, headerStyle, descrption, descriptionStyle, children

const CustomModal = ({ openModal, header = null, description = null, children, onClose, headerStyle = null, descriptionStyle = null, style = {} }) => {
  return (
    <CustomModalContainer open={openModal} style={style}>
      <Header>
        <Title style={headerStyle}>{header}</Title>
        <CloseButton src={CloseIcon} onClick={() => onClose()} />
      </Header>
      <Content scroll={true}>
        {description && <Description style={descriptionStyle}>{description}</Description>}
        <CustomModalDescription>{children}</CustomModalDescription>
      </Content>
    </CustomModalContainer>
  )
}

export default CustomModal
