import React, { useState, useEffect } from 'react'
import { Form, Message, Image } from 'semantic-ui-react'
import { Form as FinalForm, Field } from 'react-final-form'
import { useNavigate } from 'react-router-dom'
import { isEmpty } from 'lodash'
import validate from './validate'

import { connect } from 'react-redux'
import styled from 'styled-components'
const { devices } = importer('utils/styledComponentHelpers', null)
import { useTranslation } from 'react-i18next'
import importer from '@takedapdt/biolife-core/src/importer'
const CentersCarousel = importer('Components/CentersCarousel')
const InputField = importer('Components/Forms/FormFields/InputField')
const { isMobileWeb, ScrollToTop } = importer('Helpers/Utils', null)
const { useScreenSize } = importer('Contexts/ResponsiveContextProvider', null)
const publicContentActions = importer('Stores/PublicContent/Actions')
const AuthCustomActions = importer('Stores/AuthCustom/Actions')
const centersActions = importer('Stores/Centers/Actions')

const StyledDiv = styled.div`
  .ui.form {
    .CentersCarouselContainer {
      .text-component__container {
        padding-top: 16px;
        ${devices.tablet} {
          padding-left: 0 !important;
          padding-right: 0 !important;
        }
        ${devices.mobile} {
          padding-left: 0 !important;
          padding-right: 0 !important;
        }
      }

      .main-container {
        padding-left: 0 !important;
      }
    }
    .CentersCarousel {
      padding-bottom: 5px;
    }
  }
  .fields {
    margin-bottom: 22px;
  }
  .fields.ButtonContainer {
    justify-content: flex-end;
    margin-bottom: 80px;
    ${devices.mobile} {
      margin-bottom: 40px;
    }

    button {
      ${devices.mobile} {
        width: 100%;
      }
    }
  }
  .main-container {
    ${devices.mobile} {
      padding-left: 0px !important;
      padding-right: 0px !important;
    }
  }
`

const ZipPopUp = React.memo(
  ({
    countries,
    states,
    centers,
    genders,
    getGenders,
    getEthnicityOptions,
    phoneTypes,
    ethnicityOptions,
    setSubmissionLoader,
    getDonorCentersAll,
    getPhoneTypes,
    getDonorCentersAllLoading,
    register,
    registerSuccess,
    registerFailure,
    donor,
    donorDetailsSaved,
    accountFormData
  }) => {
    const [selectedLocation, setSelectedLocation] = useState(null)
    const [passwordReveal, setPasswordReveal] = useState(false)
    const [statesManager, setStatesManager] = useState({})
    const [selectedCountry, setSelectedCountry] = useState('US')
    const [selectedPhoneType, setSelectedPhoneType] = useState('Mobile')
    const [countriesOptions, setCountriesOptions] = useState([])
    const [statesOptions, setStatesOptions] = useState([])
    const [errorMessages, setErrorMessages] = useState('')
    const [submited, setSubmited] = useState(false)
    const [showAgeError, setShowAgeError] = useState(false)
    const [passwordInput, setPasswordInput] = useState('')
    const [genderDropDown, setGenderDropDown] = useState([{ text: '', value: '' }])
    const [phoneTypeDropdown, setPhoneTypeDropdown] = useState([{ text: '', value: '' }])
    const [ethnicityDropdown, setEthnicityDropdown] = useState([{ text: '', value: '' }])
    const [loyaltyTnCsShow, setLoyaltyTnCsShow] = useState(false)
    const [zipcodeError, setZipcodeError] = useState(false)

    const { isMobileWidth } = useScreenSize()

    const createdFrom = isMobileWeb() ? 'MOBILE_WEB' : 'WEB'

    const { t, i18n } = useTranslation('AccountCreateForm')

    const languageOptions = [
      { key: 'English', text: t('english'), value: 'en-US' },
      { key: 'Spanish', text: t('spanish'), value: 'es-MX' }
    ]

    const navigate = useNavigate()

    useEffect(() => {
      ScrollToTop()
    }, [])

    const handleLocationChange = (value) => {
      setSelectedLocation(value.centerNum)
      if (value.isLoyaltyProgramEnabled) {
        setLoyaltyTnCsShow(true)
      } else {
        setLoyaltyTnCsShow(false)
      }
    }

    const updateCenters = (inputVal) => {
      if (inputVal && inputVal.length === 5) {
        setZipcodeError(false)
        setSelectedLocation(null)
        getDonorCentersAll(inputVal, '', 'pubDate', true, true) // second to last true corresponds to addNewDonorBonusInformation
        setLoyaltyTnCsShow(false)
      }
    }

    const onSubmit = async (values) => {
      setSubmissionLoader(true)
    }

    return (
      <StyledDiv className='main-container AccountCreateForm'>
        <FinalForm
          onSubmit={onSubmit}
          validate={validate}
          initialValues={statesManager}
          render={({ handleSubmit, values }) => (
            <Form onSubmit={handleSubmit}>
              <Form.Group>
                <Field name={'ZipCode'}>
                  {({ input, meta }) => (
                    <InputField
                      required={true}
                      meta={meta}
                      {...input}
                      width={320}
                      height={56}
                      placeholder={''}
                      fieldLabel={'Zipe Code'}
                      isHtmlLabel
                      customOnChange={(e) => (e.target.value.length !== 5 ? setZipcodeError(true) : null)}
                      onBlur={() => {
                        input.onBlur()
                        updateCenters(input.value)
                      }}
                      id={'ZipCode'}
                    />
                  )}
                </Field>
                {!isEmpty(centers) && !zipcodeError && !getDonorCentersAllLoading && (
                  <Field name='donationCenter'>
                    {({ input, meta }) => (
                      <CentersCarousel
                        input={input}
                        // componentData={component}
                        handleLocationChange={handleLocationChange}
                        selectedCenter={selectedLocation}
                        centers={centers}
                      />
                    )}
                  </Field>
                )}
              </Form.Group>
            </Form>
          )}
        />
      </StyledDiv>
    )
  }
)

const mapStateToProps = (state) => ({
  centers: state.centers.donorCenters?.data,
  center: state.centers.getAllCentersSuccess,
  genders: state.publicContent.Genders,
  ethnicityOptions: state.publicContent.Ethnicity,
  states: state.publicContent.States,
  countries: state.publicContent.Countries,
  phoneTypes: state.publicContent.PhoneTypes,
  getDonorCentersAllLoading: state.centers.getAllCentersLoading,
  signUpUser: state.users.registerSignUp,
  registerLoading: state.authCustom.registerLoading,
  registerSuccess: state.authCustom.registerSuccess,
  registerFailure: state.authCustom.registerFailure,
  donor: state.donors.donors?.data,
  donorBySfid: state.donors.getDonorBySfidSuccess?.data
})

const mapDispatchToProps = (dispatch) => ({
  getDonorCentersAll: (searchStr, isDIS8Center, searchType, addNewDonorBonusInformation, setCenterDistance) =>
    dispatch(centersActions.getDonorCentersAll(searchStr, isDIS8Center, searchType, addNewDonorBonusInformation, setCenterDistance)),
  register: (donor) => dispatch(AuthCustomActions.register(donor)),
  getDonorBySfid: (sfid, pid) => dispatch(donorsActions.getDonorBySfid(sfid, pid)),
  getGenders: () => dispatch(publicContentActions.getGenders()),
  getEthnicityOptions: () => dispatch(publicContentActions.getEthnicityOptions()),
  getAllStates: () => dispatch(publicContentActions.getAllStates()),
  getCountries: () => dispatch(publicContentActions.getCountries()),
  getPhoneTypes: () => dispatch(publicContentActions.getPhoneTypes())
})

export default connect(mapStateToProps, mapDispatchToProps)(ZipPopUp)
// export default ZipPopUp
