import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import importer from '@takedapdt/biolife-core/src/importer'
import { Helmet } from 'react-helmet'
import LoaderSpinner from '../Components/Loaders/LoaderSpinner'

const PublicContentActions = importer('Stores/PublicContent/Actions')
/* ACTIONS */
const UserActions = importer('Stores/Users/Actions')
export class LoginAuth extends Component {
  componentDidMount() {
    var urlString = window.location.href
    var url = new URL(urlString)
    var code = url.searchParams.get('code')
    this.props.fetchFormsData('login-auth')
    if (code) {
      var x = document.cookie
      // console.log('Cookies')
      // console.log(x)
      this.props.tokenExchange(code)
    }
  }

  render() {
    const { t, FormsData, fetchFormsData, loginTokenExchangeLoading } = this.props

    return (
      <>
        <Helmet>
          <title>{`${FormsData?.metadata?.meta_title}`} </title>
          <meta name='description' content={`${FormsData?.metadata?.meta_description}`} />
          <link rel='canonical' href={`${window.location.origin}${window.location.pathname.toLowerCase()}`} />
        </Helmet>

        {!loginTokenExchangeLoading && <LoaderSpinner backdrop='full' loaderMessage={!loginTokenExchangeLoading ? t('talkingToServers') : ''} />}
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  FormsData: state.publicContent?.getFormsPageSuccess?.['login-auth'],
  FormsDataLoading: state.publicContent?.getFormsPageLoading?.['login-auth'],
  loginTokenExchangeLoading: state.users?.loginTokenExchangeLoading
})

const mapDispatchToProps = (dispatch) => ({
  tokenExchange: (code) => dispatch(UserActions.loginTokenExchange(code)),
  fetchFormsData: (formType) => dispatch(PublicContentActions.getFormsPage(formType))
})

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('SignInAuth')(LoginAuth))
