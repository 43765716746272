import importer from '@takedapdt/biolife-core/src/importer'
const isValidEmail = importer('Helpers/isValidEmail')
const validate = (values) => {
  const errors = {}
  // First name validation
  if (values['First Name']) {
    if (values['First Name'].length < 1) {
      errors['First Name'] = 'firstNameShouldHaveAtLeasst1Char'
    } else if (values['First Name'].length === 1 && values['First Name'].match(/^[a-zA-Z]$/i) === null) {
      errors['First Name'] = 'firstNameShouldBeCharacteterOnly'
    } else if (
      values['First Name'].length > 1 &&
      values['First Name'].match(/^(?!.*(['ʻ,. -]){2})([a-zA-Z])([a-zA-Z'ʻ,. -]*[a-zA-Z])$/i) === null
    ) {
      errors['First Name'] = 'firstNameShouldBeCharacteterOnly'
    }
  } else {
    errors['First Name'] = 'firstNameIsRequired'
  }
  // Last name validation
  if (values['Last Name']) {
    if (values['Last Name'].length < 2) {
      errors['Last Name'] = 'lastNameShouldHaveAtLeast2Char'
    } else if (values['Last Name'].match(/^(?!.*(['ʻ,. -]){2})([a-zA-Z])([a-zA-Z'ʻ,. -]*[a-zA-Z])$/i) === null) {
      errors['Last Name'] = 'lastNameShouldBeCharacter'
    }
  } else {
    errors['Last Name'] = 'lastNameIsRequired'
  }
  // Email validation
  if (values['Email Address']) {
    if (!isValidEmail(values['Email Address'])) {
      errors['Email Address'] = 'emailAddressMustBeInValid'
    } else if (values['Email Address'].indexOf('@takeda.com') > -1 || values['Email Address'].indexOf('@shire.com') > -1) {
      errors['Email Address'] = 'pleaseEnterANonEmployeeEmail'
    }
  } else {
    errors['Email Address'] = 'emailAddressIsRequired'
  }
  // Postal code validation
  if (values['Zip Code']) {
    if (!/^\d{5}$/.test(values['Zip Code'])) {
      errors['Zip Code'] = 'zipCodeShouldHave5Digits'
    }
  } else {
    errors['Zip Code'] = 'zipCodeIsRequired'
  }
  return errors
}

export default validate
