import React from 'react'
import './styles.scss'
import importer from '@takedapdt/biolife-core/src/importer'
import renderDrupalComponent from '../../Constant/renderDrupalComponent'

const ShareWithUs = (props) => {
  return (
    <div className='ShareWithUsMainContainer'>
      {props?.ShareWithUsContainerContent?.contents?.map((component, index) => {
        if (component.type === 'breadcrumb') {
          let tempComp = { ...component, breadcrumbToggle: props?.ShareWithUsDataContent?.metadata?.field_content_breadcrumb }
          component = tempComp
        }
        return renderDrupalComponent(component, index)
      })}
    </div>
  )
}

export default ShareWithUs
