import React from 'react'
import styled from 'styled-components'
import importer from '@takedapdt/biolife-core/src/importer'
const { getCssColorFromDrupalColorType, setTextColor } = importer('Constant/Utils', null)
const { devices } = importer('utils/styledComponentHelpers', null)
const { sanitizeHtml } = importer('utils/htmlHelpers', null)
import ButtonCTA from '../ButtonCTA'
import quoteMark from '../../../assets/media/icons/quote-mark-48.svg'

const StyledCardTemplate4 = styled.div`
  display: flex;
  gap: 3rem;
  flex-direction: ${(props) => (props.$alignment === 1 ? 'row' : 'row-reverse')};
  padding: 6.4rem 8.6rem;
  border-radius: 2.5rem;
  box-shadow: 0 0.4rem 0.8rem #33333340;
  background: ${(props) => getCssColorFromDrupalColorType(props?.$bgColor)};
  ${devices.tablet} {
    flex-direction: column;
    padding: 6.4rem 2.4rem;
  }
`

const StyledCardTemplate4MessageWrapper = styled.div`
  display: grid;
  grid: auto auto/ auto auto auto;
  gap: 2rem;
  ${devices.tablet} {
    grid: 1fr / 1fr;
  }
`

const StyledCardTemplate4Image = styled.img`
  width: 10rem;
  height: 10rem;
  border-radius: 50%;
  border: 0.2rem solid transparent;
  ${StyledCardTemplate4MessageWrapper} & {
    display: none;
  }
  ${devices.tablet} {
    display: none;
    ${StyledCardTemplate4MessageWrapper} & {
      display: inline-block;
    }
  }
`

const StyledCardTemplate4Quote = styled.img`
  grid-row: 1/2;
  ${devices.tablet} {
    grid-row: auto;
    grid-column: 1/1;
  }
`

const StyledCardTemplate4Message = styled.div`
  grid-row: 1/2;
  grid-column: 2/4;
  ${(props) => setTextColor(props?.$textcolor)};
  ${devices.tablet} {
    grid-row: auto;
    grid-column: 1/1;
  }
`

const StyledCardTemplate4PersonName = styled.div`
  grid-row: 2/3;
  grid-column: 2/3;
  align-self: start;
  padding-bottom: 0.2rem;
  ${(props) => setTextColor(props?.$textColor)};
  ${devices.tablet} {
    grid-row: auto;
    grid-column: 1/1;
  }
`

const StyledCardTemplate4PersonTitle = styled.div`
  grid-row: 2/3;
  grid-column: 2/3;
  align-self: end;
  margin-top: 2rem;
  ${(props) => setTextColor(props?.$textColor)};
  ${devices.tablet} {
    grid-row: auto;
    grid-column: 1/1;
    margin-top: 0;
  }
`

const StyledCardTemplate4Cta = styled.div`
  grid-row: 2/3;
  grid-column: 3/4;
  justify-self: end;
  align-self: center;

  ${devices.tablet} {
    grid-row: auto;
    grid-column: 1/1;
    justify-self: start;
    & .main-container {
      padding: 0 !important;
    }
  }
`

const CardTemplate4 = (props) => {
  return (
    <StyledCardTemplate4 $bgColor={props.field_bg_color_1} $alignment={props.field_alignment}>
      <StyledCardTemplate4Image src={props?.field_component_image?.url} alt={props?.field_component_image?.alt} />
      <StyledCardTemplate4MessageWrapper>
        <StyledCardTemplate4Quote src={quoteMark} alt='quotation mark' />
        <StyledCardTemplate4Message
          dangerouslySetInnerHTML={sanitizeHtml(props?.field_component_desc?.processed)}
          $textColor={props.field_long_desc_color}
        />
        <StyledCardTemplate4Image src={props?.field_component_image?.url} alt={props?.field_component_image?.alt} />
        <StyledCardTemplate4PersonName
          dangerouslySetInnerHTML={sanitizeHtml(props?.field_component_title?.processed)}
          $textColor={props.field_title_color}
        />
        <StyledCardTemplate4PersonTitle
          dangerouslySetInnerHTML={sanitizeHtml(props?.field_short_description?.processed)}
          $textColor={props.field_short_desc_color}
        />
        {props?.field_cta_1 && props?.field_cta_1.uri && (
          <StyledCardTemplate4Cta>
            <ButtonCTA {...props} disable_bg_color={true} />
          </StyledCardTemplate4Cta>
        )}
      </StyledCardTemplate4MessageWrapper>
    </StyledCardTemplate4>
  )
}

export default CardTemplate4
