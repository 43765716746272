import React from 'react'
import importer from '@takedapdt/biolife-core/src/importer'
import { Grid } from 'semantic-ui-react'
const SignupForm = importer('Components/RegistrationFormComponent/SignupForm')
const { COMMONVIEW_TYPES } = importer('Components/RegistrationFormComponent/utility', null)
const CommonViews = importer('Components/RegistrationFormComponent/CommonViews')
const DesktopView = (props) => {
  // View is for Desktop View
  const {
    field_component_title: componentTitle,
    field_title_color: titleColor,
    field_short_description: shortDescription,
    field_short_desc_color: shortDescColor,
    field_component_asset: componentAsset,
    field_form_label: fieldLabel,
    field_cta_1: cta = {},
    field_cta_1_style: ctaStyle = ''
  } = props
  const fieldLabelValues =
    fieldLabel &&
    fieldLabel.map((label) => {
      const value = label.split('|')
      return { validation: value[1], label: value[0] }
    })
  return (
    <Grid className='gridStyling'>
      <Grid.Row columns={2} className='gridRow'>
        <Grid.Column computer={8} textAlign='center' className='gridColumnStyle'>
          <div className='textColumn'>
            {CommonViews({
              type: COMMONVIEW_TYPES.COMMON_TEXT_COMPONENT,
              contentData: {
                processedText: componentTitle?.processed,
                field_color: titleColor
              },
              styleName: 'gridTitle'
            })}
            {CommonViews({
              type: COMMONVIEW_TYPES.COMMON_TEXT_COMPONENT,
              contentData: {
                processedText: shortDescription?.processed,
                field_color: shortDescColor
              },
              styleName: 'gridTitleDesc'
            })}
          </div>
          {CommonViews({
            type: COMMONVIEW_TYPES.SIGNUP_IMG_COMP,
            contentData: componentAsset,
            styleName: 'Image'
          })}
        </Grid.Column>
        <Grid.Column computer={8} className='gridColumn2'>
          <SignupForm fieldLabel={fieldLabelValues} buttonStyle={{ cta, ctaStyle }} />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}

export default DesktopView
