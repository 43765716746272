import React, { useState } from 'react'
import styled from 'styled-components'
import downArrowIcon from '../../../assets/media/icons/icon-chevron-down.svg'
import upArrowIcon from '../../../assets/media/icons/icon-chevron-up.svg'
import importer from '@takedapdt/biolife-core/src/importer'
const { setTextColor } = importer('Constant/Utils', null)
const { devices, sizes } = importer('utils/styledComponentHelpers', null)
const { sanitizeHtml } = importer('utils/htmlHelpers', null)
const CenterPhotos = importer('Components/CenterPhotos')
const CenterMeetOurStaff = importer('Components/CenterMeetOurStaff')
const CenterTestimonials = importer('Components/CenterTestimonials')

const StyledAccordionWithSlider = styled.div`
  width: 1008px;
  
  ${devices.tablet} {
    padding-left: 4.8rem !important;
    padding-right: 4.8rem !important;
    width: 100%;
  }
  ${devices.mobile} {
    padding-left: 2.4rem !important;
    padding-right: 2.4rem !important;
    width: 100%;
  }
  margin: auto;
  display: flex;
  flex-direction: column;
  max-width: ${sizes.largeDesktop};
`

const StyledAccordionWithSliderDivider = styled.hr`
  border: 0;
  border-top: 0.1rem solid var(--color-dark-grey);
`

const StyledAccordionWithSliderHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.6rem;
  cursor: pointer;
`

const StyledAccordionWithSliderHeaderTitle = styled.div`
  ${(props) => setTextColor(props.textColor)};
  &:hover {
    color: var(--color-orange-hover);
  }

  ${devices.mobile} {
    h5, h6 {
      font-size: var(--h5-font-size) !important;
    }
  }
`

const StyledAccordionWithSliderHeaderIcon = styled.img`
  width: 2.4rem;
  height: 2.4rem;
`

const StyledAccordionWithSliderContent = styled.div`
  display: ${(props) => (props.defaultdisplay ? 'flex' : 'none')};
  flex-direction: column;
  padding: 0 1.6rem;
`

const AccordionWithMultiComponent = (props) => {
  const [isOpen, setIsOpen] = useState(props.field_accordian_flag)
  const handleClick = (e) => {
    setIsOpen((isOpen) => !isOpen)
  }

  const renderSliderComponent = (children) => {
    switch (children[0].field_custom_id) {
      case 'accordion-photo-gallery':
        return <CenterPhotos items={children} />
      case 'accordion-meet-staff':
        return <CenterMeetOurStaff items={children} />
      case 'accordion-testimonials':
        return <CenterTestimonials items={children} />
    }
  }

  return (
    <StyledAccordionWithSlider>
      <StyledAccordionWithSliderDivider />
      <StyledAccordionWithSliderHeader onClick={handleClick}>
        <StyledAccordionWithSliderHeaderTitle
          dangerouslySetInnerHTML={sanitizeHtml(props.field_component_title?.processed)}
          textColor={props.field_title_color}
        />
        <StyledAccordionWithSliderHeaderIcon src={isOpen ? upArrowIcon : downArrowIcon} />
      </StyledAccordionWithSliderHeader>
      <StyledAccordionWithSliderContent defaultdisplay={isOpen}>{renderSliderComponent(props.children)}</StyledAccordionWithSliderContent>
    </StyledAccordionWithSlider>
  )
}

export default AccordionWithMultiComponent
