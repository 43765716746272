import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import './styles.scss'
import { useTranslation } from 'react-i18next'
import { Form as FinalForm, Field } from 'react-final-form'
import { isEmpty } from 'lodash'
import { connect } from 'react-redux'
import { Grid, Popup, Checkbox } from 'semantic-ui-react'

import importer from '@takedapdt/biolife-core/src/importer'
const { isMobileWeb } = importer('Helpers/Utils', null)
import StartupActions from '.././../../Stores/Startup/Actions'

import { devices } from '../../../utils/styledComponentHelpers'
import PublicContentActions from '@takedapdt/biolife-core/src/Stores/PublicContent/Actions'
import centersActions from '@takedapdt/biolife-core/src/Stores/Centers/Actions'
import donorsActions from '@takedapdt/biolife-core/src/Stores/Donors/Actions'
import changeLanguageHelper from '../../../Helpers/changeLanguage'

import InfoIcon from './../../../../assets/media/images/GrayInfo-Icon.png'
import ToggleSwitch from '../../Buttons/ToggleButton'
import SelectField from './../../../Components/Forms/FormFields/SelectField'
import { useScreenSize } from './../../../Contexts/ResponsiveContextProvider'
import HomeCenterCard from '../../Card/HomeCenterCard'
import PrimaryButton from '../../Buttons/PrimaryButton'
import Modal from '../../Modal'
import SimplePopup from '../../ProfileSettings/Popups/SimplePopup/SimplePopup'
import EnrollSMSMFAForm from '../EnrollSMSMFAForm'
import { setTextColor } from '../../../Constant/Utils'
import CentersCarousel from '../../../Components/CentersCarousel'
import ChangeHomeCenterPopup from '../../Popups/ChangeHomeCenterPopup'
import LocationPreferencesCarousel from '../../LocationPreferencesCarousel'
import LoaderBar from '../../Loaders/LoaderBar'
const StyledSpacingDiv = styled.div`
  margin: ${({ $margin }) => $margin};
`

const StyledH3Header = styled.h4`
  display: flex;
  justify-content: flex-start;
  color: var(--color-bl-blue);
  margin-bottom: 1.6rem;
  font-family: var(--font-family-bold) !important;
  font-size: var(--h3-font-size) !important;
  ${devices.mobile} {
    width: 90%;
    font-size: var(--h4-font-size) !important;
  }
`
const StyledH5Header = styled.h6`
  display: flex;
  justify-content: flex-start;
  color: var(--color-black);
  margin-bottom: 2rem;
  font-family: var(--font-family-bold) !important;
  font-size: var(--h6-font-size) !important;
  ${devices.mobile} {
    width: 80%;
    font-size: var(--h5-font-size) !important;
  }
`
const H3Header = styled.h3`
  display: flex;
  justify-content: flex-start;
  color: var(--color-bl-blue);
  margin-bottom: 2.5rem;
  font-family: var(--font-family-bold) !important;

  ${devices.mobile} {
    width: 80%;
    font-size: (--h4-font-size) !important;
  }
`

const StyledPreferenceDiv = styled.div`
  margin-top: 3rem;
  width: 100%;
`

const VerticalCenter = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  ${devices.mobile} {
    width: 100%;
    justify-content: center;
    max-width: 32rem;
    margin: auto;
  }
  .CenterCardContainer.CenterCardSelected.card .content .header .HeaderWrapper .CheckMarkContainer .CheckMark {
    top: 0.4rem;
  }
`

const StyledCommunicationEachDiv = styled.div`
  margin-top: 2rem;
  margin-bottom: 5rem;
`

const StyledH4Header = styled.h5`
  color: var(--color-orange);
  margin-bottom: 2.5rem;
  font-family: var(--font-family-bold) !important;
  font-size: var(--h4-font-size) !important;
  ${devices.mobile} {
    font-family: var(--font-family) !important;
    font-size: var(--h4-mobile-font-size) !important;
  }
`

const StyledAppointmentH4Header = styled.h5`
  color: var(--color-orange);
  margin-bottom: 2.5rem;
  font-family: var(--font-family-bold) !important;
  font-size: var(--h4-font-size) !important;
  width: 80rem;

  ${devices.mobile} {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: auto;
    font-size: var(--h4-mobile-font-size) !important;
    font-family: var(--font-family-medium) !important;
  }
`

const StyledToggleDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 50%;
  margin: 3rem 0rem;

  ${devices.mobile} {
    width: 100%;
  }
`

const StyledHeaderToggleDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: ${({ $margin }) => ($margin ? $margin : 'auto')};
`

const StyledPreferenceDivMFA = styled.div`
  margin: ${({ $margin }) => $margin || 'auto'};
  width: 100%;
`

const StyledDividingLine = styled.hr`
  margin: ${({ $margin }) => $margin || 'auto'};
`

const StyledSelectLanguage = styled.div`
  width: 39%;
  margin-top: 3rem;

  ${devices.mobile} {
    width: 90%;
  }
`

const StyledToggleLable = styled.div`
  display: flex;
  align-items: center;
`
const RegularContent = styled.p`
  font-family: var(--font-family-regular) !important;
`
const RegularContentDifferent = styled.p`
  font-family: var(--font-family-regular) !important;

  margin-bottom: 2rem;
  ${devices.mobile} {
    font-size: var(--caption-font-size) !important;
  }
`
const RegularContentBold = styled.p`
  font-family: var(--font-family-bold) !important;

  margin-bottom: 1.6rem;
  ${devices.mobile} {
    font-size: var(--caption-font-size) !important;
  }
`
const StyledMarginDiv = styled.div`
  margin: 7rem 0rem 5rem 0rem;
`
const StyledEnrolledStatus = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
`
const Enrolled = styled.p`
  font-family: var(--font-family-regular) !important;
  margin-bottom: 3.2rem;
`

const StyledFluidButton = styled.div`
  ${devices.mobile} {
    width: 100%;
  }
`
const StyledFluidButton2 = styled.div`
  width: 40%;
  margin-top: 3rem;
  ${devices.mobile} {
    margin-top: 8rem;
    width: 100%;
  }
`
const StyledInfoImage = styled.img`
  width: 1.5rem;
  cursor: pointer;
  margin-left: 1.3rem;
`

const StyledApointmentInfoImage = styled.img`
  width: 1.5rem;
  cursor: pointer;
  margin-left: 1.3rem;

  ${devices.mobile} {
    margin-top: 2.5rem;
  }
`

const StyledBiolifeImage = styled.img`
  margin-bottom: 3.2rem;
  ${devices.mobile} {
    width: 90%;
  }
`

const StyledButtonContainer = styled.div`
  display: flex;
  width: 50%;
  justify-content: space-between;
  align-items: center;
  padding-left: 0rem;
  ${devices.mobile} {
    flex-direction: column;
    width: 100%;
    height: 15rem;
  }
`

const CancelButtons = styled.p`
  text-decoration: underline;
  color: (--color-bl-blue);
  cursor: pointer;
  margin: 0rem;
`

const StyledButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  ${devices.mobile} {
    width: 100%;
    flex-direction: column;
    row-gap: 4rem;
  }
`

const StyledButtonDiv = styled.div`
  margin: ${({ $margin }) => $margin};
  ${devices.mobile} {
    width: 100%;
    margin: 0;
  }
`

const StyledToggleWrapper = styled.div`
  width: 6rem;
  height: 3.2rem;
`

const StyledFixedCenterText = styled.p`
  margin-top: 1.6rem;
  ${devices.tablet} {
    margin-top: 5rem;
  }
`

const StyledNoCenterText = styled.h5`
  padding-top: 1.6rem;
  max-width: 42.5rem;
  font-family: var(--font-family-medium) !important;
`
const CommunicationPreference = (props) => {
  const {
    donor,
    preferences,
    drupalMetaData,
    updateDonorsPreferences,
    updateDonor,
    loading,
    center,
    centers,
    nearbyCenters,
    centerStatus,
    getDonorNearbyCenters,
    getCenterDetails,
    getDonorCentersAll,
    initializeApp,
    getCountries,
    postLoginPageData,
    getDonorCentersAllLoading,
    favoriteCenterList,
    getFavoriteCenters,
    getFavoriteCenterLoading,
    updateFavoriteCenterSuccess,
    updateDonorSuccess
  } = props

  const { t, i18n } = useTranslation('UpdatePreferencesForm')
  const [statesManager, setStatesManager] = useState({ isMFASms: donor?.isSmsEnrolled === 'Yes' })
  const [showSmsModal, setShowSmsModal] = useState(false)
  const [showSmsMkModal, setShowSmsMkModal] = useState(false)
  const [showMarketingModal, setShowMarketingModal] = useState(false)
  const [popUpData, setPopUpData] = useState(null)
  const [showSimplePopUp, setShowSimplePopup] = useState(false)
  const [optedOutPopUp, setOptedOutPopUp] = useState(false)
  const [selectedCenterSFId, setselectedCenterSFId] = useState([])
  const [selectedLocation, setSelectedLocation] = useState(null)
  const [isSpanishSupported, setIsSpanishSupported] = useState(null)
  const [showPopUp, setShowPopUp] = useState(false)
  const [allDiffCenter, setAllDiffCenter] = useState([])
  const [IsEnrolled, setIsEnrolled] = useState(() => {
    return donor?.isLoyaltyMember?.toLowerCase() === 'yes' ? true : false
  })
  const [language, setLanguage] = useState(preferences?.language)
  const [smsStatement, setSmsStatement] = useState(false)
  const [marketingStatement, setMarketingStatement] = useState(false)
  const [updateFavCenterList, setUpdateFavCenterList] = useState(false)
  const { isMobileWidth } = useScreenSize()
  const languageDropDown = [
    {
      key: 'enUS',
      text: t('english'),
      value: 'en-US'
    },
    {
      key: 'esMX',
      text: t('spanish'),
      value: 'es-MX'
    }
  ]

  const resetPreferences = () => {
    if (!isEmpty(preferences)) {
      const complexStateInitial = {
        isSmsOptIn: preferences?.isSmsOptIn,
        isEmailReminderOptIn: preferences?.isEmailReminderOptIn,
        isSmsReminderOptIn: preferences?.isSmsReminderOptIn,
        isEmailOptIn: preferences?.isEmailOptIn
      }
      setStatesManager((prev) => ({ ...prev, ...complexStateInitial }))
      setLanguage(() => preferences?.language)
    }
    if (center) {
      setselectedCenterSFId([])
      setIsSpanishSupported(center?.data?.isSpanishSupported)
    }
  }

  const discardPopUp_Data = {
    type: 'prefereceDiscard',
    showTickIcon: false,
    showTitle: true,
    showsecondaryTitle: false,
    discardContent: true,
    showBiolifeImage: false,
    title: t('areYouSureToDiscard'),
    buttonContent: t('yesDiscard'),
    resetPreferences: resetPreferences,
    internalPadding: '8.5rem 16.4rem'
  }

  const savePopUp_Data = {
    type: 'save',
    showTickIcon: true,
    discardContent: false,
    showsecondaryTitle: false,
    showTitle: true,
    showBiolifeImage: false,
    title: t('yourChangesHaveBeenSaved'),
    buttonContent: t('backToSettings'),
    internalPadding: '8.5rem 19rem'
  }

  const optOutPopup_Data = {
    type: 'optOut',
    showTickIcon: false,
    showBiolifeImage: true,
    biolifeImage: drupalMetaData?.field_biolifereward_logo?.url,
    Alt: center?.data?.imageAltText,
    discardContent: false,
    showTitle: true,
    showsecondaryTitle: true,
    title: t('areyouSureWantToOptOut'),
    secondaryTitle: t('youWillLoseAllPoints'),
    buttonContent: t('neverMind'),
    mainButtom: t('optOut'),
    setIsEnrolled: setIsEnrolled,
    internalPadding: '8.5rem 17rem'
  }

  const optInPopup_Data = {
    type: 'optIn',
    showTickIcon: false,
    showBiolifeImage: true,
    biolifeImage: drupalMetaData?.field_biolifereward_logo?.url,
    Alt: center?.data?.imageAltText,
    showTitle: true,
    discardContent: false,
    showsecondaryTitle: true,
    title: t('welcomeTo'),
    secondaryTitle: t('youAreNowEnrolled'),
    mainButtom: t('getStarted'),
    internalPadding: '8.5rem 25rem'
  }

  useEffect(() => {
    if (donor?.sfid && isEmpty(nearbyCenters)) {
      getDonorNearbyCenters(donor?.sfid)
    }
    if (donor?.donationCenter && isEmpty(center)) {
      getCenterDetails(donor?.donationCenter)
    }
    if (donor?.zipCode) {
      getDonorCentersAll(donor?.zipCode)
    }
    if (donor?.sfid) {
      getFavoriteCenters(donor?.sfid)
    }
    getCountries()
  }, [showPopUp, updateFavoriteCenterSuccess])

  useEffect(() => {
    if (updateDonorSuccess && donor?.sfid && showSimplePopUp) {
      getFavoriteCenters(donor?.sfid)
    }
  }, [updateDonorSuccess, showSimplePopUp])

  useEffect(() => {
    resetPreferences()
    setIsEnrolled(donor?.isLoyaltyMember?.toLowerCase() === 'yes' ? true : false)
  }, [preferences, center])

  useEffect(() => {
    setSmsStatement(isSmsOptinSelected())
    setMarketingStatement(isMarketingOptinSelected())
  }, [statesManager])

  const handleSmsStatement = (field) => {
    if (smsStatement) {
      setStatesManager((prev) => ({
        ...prev,
        ...statesManager,
        isSmsReminderOptIn: false,
        isSmsOptIn: false
      }))
    } else {
      setStatesManager((prev) => ({
        ...prev,
        ...statesManager,
        [field]: true
      }))
    }
  }

  const handleMarketingStatement = () => {
    if (statesManager.isSmsOptIn) {
      setStatesManager((prev) => ({
        ...prev,
        ...statesManager,
        isSmsOptIn: !marketingStatement,
        isEmailOptIn: !marketingStatement
      }))
    } else {
      setStatesManager((prev) => ({
        ...prev,
        ...statesManager,
        isSmsOptIn: false,
        isEmailOptIn: !marketingStatement
      }))
    }
  }

  const onChangeUseState = (e) => {
    const name = e.target.value
    const checked = e.target.checked
    switch (name) {
      case 'isSmsReminderOptIn':
        smsStatement ? update(name, checked) : setShowSmsModal(true)
        return
      case 'isSmsOptIn':
        smsStatement ? update(name, checked) : setShowSmsMkModal(true)
        return
      case 'isEmailOptIn':
        marketingStatement ? update(name, checked) : setShowMarketingModal(true)
        return
      default:
        update(name, checked)
    }
  }

  const update = (name, checked) => {
    setStatesManager((prevState) => ({ ...prevState, [name]: checked }))
  }

  const isMarketingOptinSelected = () => statesManager.isSmsOptIn || statesManager.isEmailOptIn

  const isSmsOptinSelected = () => statesManager.isSmsOptIn || statesManager.isSmsReminderOptIn

  const handleOpting = () => {
    if (IsEnrolled) {
      setPopUpData(() => optOutPopup_Data)
      setShowSimplePopup(() => true)
    } else {
      if (donor?.defaultCenter?.isLoyaltyProgramEnabled) {
        setPopUpData(() => optInPopup_Data)
        updateDonor(donor?.id, {
          donorDetails: {
            isLoyaltyMember: 'Yes'
          },
          loyaltyApplicationUsed: 'MOBILE_WEB'
        })
        setShowSimplePopup(() => true)
      }
    }
  }

  const handleOptingOut = () => {
    updateDonor(donor?.id, {
      donorDetails: {
        isLoyaltyMember: 'No'
      },
      loyaltyApplicationUsed: 'MOBILE_WEB'
    })
    setShowSimplePopup(() => false)
    setOptedOutPopUp(() => true)
  }

  const optedOut_Popup_Data = {
    type: 'optedOut',
    showTickIcon: true,
    showBiolifeImage: false,
    discardContent: false,
    showsecondaryTitle: false,
    showTitle: true,
    title: t('youHaveOptOut'),
    buttonContent: t('backToSetting'),
    optedOutPopUp: optedOutPopUp,
    setOptedOutPopUp: setOptedOutPopUp,
    internalPadding: '8.5rem 17.4rem'
  }

  const handleCancelButton = () => {
    setPopUpData(() => discardPopUp_Data)
    setShowSimplePopup(true)
  }

  const onSubmit = () => {
    setPopUpData(() => savePopUp_Data)
    let islangugeChanged = false

    if (language !== preferences?.language) {
      islangugeChanged = true
    }
    updateDonorsPreferences(donor?.id, {
      isSmsOptIn: statesManager?.isSmsOptIn,
      isEmailReminderOptIn: statesManager?.isEmailReminderOptIn,
      isSmsReminderOptIn: statesManager?.isSmsReminderOptIn,
      isEmailOptIn: statesManager?.isEmailOptIn,
      language: language
    })

    if (selectedCenterSFId) {
      updateDonor(
        donor?.id,
        {
          donorDetails: {
            donationCenter: selectedCenterSFId
          }
        },
        true
      )
      getCenterDetails(selectedCenterSFId)
      getDonorCentersAll(donor?.zipCode)
    }

    setShowSimplePopup(true)
    if (islangugeChanged) {
      internationalize(language)
    }
  }

  const handleLanguageChange = (value) => {
    setLanguage(value)
  }

  const internationalize = (lang) => {
    window?.localStorage?.setItem('originalLng', lang)
    changeLanguageHelper(lang)
    initializeApp('changeLanguage')
  }

  const handleLocationChange = (value) => {
    setselectedCenterSFId(value?.sfid)
    setSelectedLocation(value?.centerNum)
  }

  /**
   * This is to get the tooltip text from drupal based on the fieldPopupType
   * @param {string} fieldPopupType
   * @returns {object} tooltip text and styles from drupal
   */
  const getTooltipText = (fieldPopupType) => {
    // get the tooltip text based on the fieldPopupType which should match field_poup_type from postLoginPageData which is an array of objects.
    const tooltipObj = postLoginPageData?.find((item) => item.field_poup_type === fieldPopupType)
    return tooltipObj
  }
  useEffect(() => {
    let centerData = center?.data
    if (favoriteCenterList?.length) {
      setAllDiffCenter([{ ...centerData, isHomeCenter: true }, ...favoriteCenterList])
    } else {
      setAllDiffCenter([{ ...centerData, isHomeCenter: true }])
    }
  }, [center?.data, favoriteCenterList])
  return (
    <>
      {/* SMS MFA */}
      <StyledPreferenceDivMFA $margin={isMobileWidth ? '3rem 0rem 0rem 0rem' : '7rem 0rem 0rem 0rem'}>
        <StyledHeaderToggleDiv>
          <H3Header>{t('SMSMultifactor')}</H3Header>
          <StyledToggleWrapper>
            <ToggleSwitch
              value='isMFASms'
              name='isMFASms'
              checked={statesManager.isMFASms}
              onChange={onChangeUseState}
              disabled={donor?.isSmsEnrolled === 'Yes'}
            />
          </StyledToggleWrapper>
        </StyledHeaderToggleDiv>

        <RegularContent>{t('RegularTextSMSMFA')}</RegularContent>
        <StyledSpacingDiv $margin={isMobileWidth ? '0px' : '4.8rem 0rem'}>
          {statesManager.isMFASms && <EnrollSMSMFAForm updateCheckboxState={update} checkboxSate={statesManager} />}
        </StyledSpacingDiv>
      </StyledPreferenceDivMFA>
      <StyledPreferenceDivMFA $margin={isMobileWidth ? '3.5rem 0rem 3rem 0rem' : '7rem 0rem 0rem 0rem'}>
        <StyledDividingLine $margin={isMobileWidth ? '0rem' : '7rem 0rem'} />
      </StyledPreferenceDivMFA>
      <StyledPreferenceDiv>
        <StyledH3Header>{t('communicationPreference')}</StyledH3Header>
        <StyledCommunicationEachDiv>
          <StyledAppointmentH4Header>
            {t('appoinmentRemainders')} & {t('donationTips')}
            <Popup
              on='click'
              position={isMobileWeb() ? 'top right' : 'top left'}
              trigger={<StyledApointmentInfoImage src={InfoIcon} />}
              className='NotificationPopup firstNotificationPopup'
            >
              <Popup.Content>
                <div
                  className='StyledCheckBoxContent'
                  style={{ ...setTextColor(getTooltipText('appointment_reminders_tool_tip')?.field_long_desc_color) }}
                  dangerouslySetInnerHTML={{
                    __html: getTooltipText('appointment_reminders_tool_tip')?.field_component_desc?.processed
                  }}
                />
              </Popup.Content>
            </Popup>
          </StyledAppointmentH4Header>
          <StyledToggleDiv>
            <StyledToggleLable>
              <RegularContent>{t('sms')}</RegularContent>
              <Popup
                on='click'
                onClose={() => setShowSmsModal(false)}
                onOpen={() => setShowSmsModal(true)}
                open={showSmsModal}
                pinned
                position={isMobileWeb() ? 'top center' : 'top left'}
                trigger={<StyledInfoImage src={InfoIcon} />}
                className='NotificationPopup'
              >
                <Popup.Content>
                  <div>
                    <Grid columns={2}>
                      <Grid.Column width={2}>
                        <Checkbox label='' checked={smsStatement} onChange={() => handleSmsStatement('isSmsReminderOptIn')} />
                      </Grid.Column>
                      <Grid.Column width={14}>
                        <div
                          className='StyledCheckBoxContent'
                          style={{ ...setTextColor(getTooltipText('appointment_reminders_sms_text_tool_tip')?.field_long_desc_color) }}
                          dangerouslySetInnerHTML={{
                            __html: getTooltipText('appointment_reminders_sms_text_tool_tip')?.field_component_desc?.processed
                          }}
                        />
                      </Grid.Column>
                    </Grid>
                  </div>
                </Popup.Content>
              </Popup>
            </StyledToggleLable>

            <ToggleSwitch
              value='isSmsReminderOptIn'
              name='isSmsReminderOptIn'
              checked={statesManager.isSmsReminderOptIn}
              onChange={onChangeUseState}
            />
          </StyledToggleDiv>
          <StyledToggleDiv>
            <StyledToggleLable>
              <RegularContent>{t('email')}</RegularContent>
            </StyledToggleLable>

            <ToggleSwitch
              name='isEmailReminderOptIn'
              value='isEmailReminderOptIn'
              checked={statesManager.isEmailReminderOptIn}
              onChange={onChangeUseState}
            />
          </StyledToggleDiv>
        </StyledCommunicationEachDiv>

        <StyledCommunicationEachDiv>
          {/* .... Marketing Notifications .... */}
          <StyledAppointmentH4Header>
            {t('marketingNotifications')}
            <Popup
              on='click'
              onClose={() => setShowMarketingModal(false)}
              onOpen={() => setShowMarketingModal(true)}
              open={showMarketingModal}
              position={isMobileWeb() ? 'top right' : 'top left'}
              trigger={<StyledInfoImage src={InfoIcon} />}
              className='NotificationPopup NotificationPopupCheckbox'
            >
              <Popup.Content>
                <div>
                  <Grid columns={2}>
                    <Grid.Column width={2}>
                      <Checkbox label='' checked={marketingStatement} onChange={handleMarketingStatement} />
                    </Grid.Column>
                    <Grid.Column width={14}>
                      <div
                        className='StyledCheckBoxContent'
                        style={{ ...setTextColor(getTooltipText('marketing_notification_tool_tip')?.field_long_desc_color) }}
                        dangerouslySetInnerHTML={{
                          __html: getTooltipText('marketing_notification_tool_tip')?.field_component_desc?.processed
                        }}
                      />
                    </Grid.Column>
                  </Grid>
                </div>
              </Popup.Content>
            </Popup>
          </StyledAppointmentH4Header>
          <StyledToggleDiv>
            <StyledToggleLable>
              <RegularContent>{t('sms')}</RegularContent>
              <Popup
                on='click'
                onClose={() => setShowSmsMkModal(false)}
                onOpen={() => setShowSmsMkModal(true)}
                open={showSmsMkModal}
                pinned
                position={isMobileWeb() ? 'top center' : 'top left'}
                trigger={<StyledInfoImage src={InfoIcon} />}
                className='NotificationPopup'
              >
                <Popup.Content>
                  <div>
                    <Grid columns={2}>
                      <Grid.Column width={2}>
                        <Checkbox label='' checked={smsStatement} onChange={() => handleSmsStatement('isSmsOptIn')} />
                      </Grid.Column>
                      <Grid.Column width={14}>
                        <div
                          className='StyledCheckBoxContent'
                          style={{ ...setTextColor(getTooltipText('marketing_notification_sms_text_tool_tip')?.field_long_desc_color) }}
                          dangerouslySetInnerHTML={{
                            __html: getTooltipText('marketing_notification_sms_text_tool_tip')?.field_component_desc?.processed
                          }}
                        />
                      </Grid.Column>
                    </Grid>
                  </div>
                </Popup.Content>
              </Popup>
            </StyledToggleLable>

            <ToggleSwitch value='isSmsOptIn' name='isSmsOptIn' checked={statesManager.isSmsOptIn} onChange={onChangeUseState} />
          </StyledToggleDiv>
          <StyledToggleDiv>
            <StyledToggleLable>
              <RegularContent>{t('email')}</RegularContent>
            </StyledToggleLable>

            <ToggleSwitch value='isEmailOptIn' name='isEmailOptIn' checked={statesManager.isEmailOptIn} onChange={onChangeUseState} />
          </StyledToggleDiv>
        </StyledCommunicationEachDiv>

        <StyledCommunicationEachDiv>
          {/* Preferred Language... */}
          <StyledAppointmentH4Header>{t('preferedLanguage')}</StyledAppointmentH4Header>
          <StyledSelectLanguage>
            <FinalForm
              onSubmit={onSubmit}
              render={() => (
                <form>
                  <Field name='selectLanguage' value='selectLanguage'>
                    {({ input, meta }) => (
                      <SelectField
                        meta={meta}
                        input={input}
                        value={language}
                        width={'100%'}
                        height={56}
                        options={languageDropDown}
                        fieldLabel={t('selectLanguage')}
                        usedFor={'ProfileSettings'}
                        defaultValue={language ? language : 'en-US'}
                        handleOnChange={handleLanguageChange}
                      />
                    )}
                  </Field>
                </form>
              )}
            />
          </StyledSelectLanguage>
        </StyledCommunicationEachDiv>

        <StyledDividingLine />
      </StyledPreferenceDiv>

      {/* .... Home Center .... */}
      <StyledPreferenceDiv>
        <StyledMarginDiv id='fav'>
          <StyledH3Header>{t('locationPreferences')}</StyledH3Header>
          {donor?.defaultCenter && (
            <>
              <RegularContentBold>{t('yourFavCenter')}</RegularContentBold>
              <RegularContentDifferent>{t('canChooseCenter')}</RegularContentDifferent>
            </>
          )}
          <VerticalCenter>
            {getDonorCentersAllLoading || getFavoriteCenterLoading ? (
              <LoaderBar />
            ) : !isEmpty(centers) ? (
              donor?.defaultCenter ? (
                favoriteCenterList?.length === 0 ? (
                  <HomeCenterCard
                    currentCenter={center?.data}
                    centers={centers}
                    isSpanishSupported={isSpanishSupported}
                    setIsSpanishSupported={setIsSpanishSupported}
                    selectedCenterSFId={selectedCenterSFId}
                    setselectedCenterSFId={setselectedCenterSFId}
                    isPDN={donor?.pdn}
                    getDonorCentersAll={getDonorCentersAll}
                    donor={donor}
                    getDonorCentersAllLoading={getDonorCentersAllLoading}
                    changeCheck={'Home-Center'}
                    isHomeCenter={true}
                  />
                ) : (
                  <LocationPreferencesCarousel
                    currentCenter={center?.data}
                    centers={allDiffCenter}
                    isSpanishSupported={isSpanishSupported}
                    setIsSpanishSupported={setIsSpanishSupported}
                    selectedCenterSFId={selectedCenterSFId}
                    setselectedCenterSFId={setselectedCenterSFId}
                    isPDN={donor?.pdn}
                    getDonorCentersAll={getDonorCentersAll}
                    donor={donor}
                    favoriteCenterList={favoriteCenterList}
                    changeCheck={'Favorite-Center'}
                    setUpdateFavCenterList={setUpdateFavCenterList}
                    updateFavCenterListCheck={updateFavCenterList}
                  />
                )
              ) : (
                <CentersCarousel handleLocationChange={handleLocationChange} selectedCenter={selectedLocation} centers={centers} />
              )
            ) : (
              <StyledNoCenterText>{t('noCentersInYourArea')}</StyledNoCenterText>
            )}
          </VerticalCenter>
          {donor?.pdn && <StyledFixedCenterText>{t('pleaseCallUsToChangeYourCenter')}</StyledFixedCenterText>}
          {favoriteCenterList?.length < 3 && donor?.defaultCenter && (
            <StyledFluidButton2>
              <PrimaryButton
                fluid={true}
                onClick={() => {
                  setShowPopUp(true)
                }}
                text={t('addFavCenter')}
                variant={'outline_blue'}
              />
            </StyledFluidButton2>
          )}

          <Modal
            maxWidth={936}
            maxWidthMobile={'87.7%'}
            maxHeight={861}
            maxHeightMobile={580}
            isOpen={showPopUp}
            onClose={() => {
              setShowPopUp(false)
            }}
          >
            <ChangeHomeCenterPopup
              setIsSpanishSupported={setIsSpanishSupported}
              togglePOPUp={setShowPopUp}
              selectedCenterSFId={selectedCenterSFId}
              setselectedCenterSFId={setselectedCenterSFId}
              donor={donor}
              changeCheck={'Favorite-Center'}
            />
          </Modal>
        </StyledMarginDiv>
        <StyledDividingLine />
      </StyledPreferenceDiv>

      {/* .... Rewards .... */}
      <StyledPreferenceDiv>
        <StyledMarginDiv>
          <StyledBiolifeImage src={drupalMetaData?.field_biolifereward_logo?.url} alt={drupalMetaData?.field_biolifereward_logo?.alt} />
          <StyledEnrolledStatus>
            <Enrolled>{IsEnrolled ? t('enrolled') : t('notEnrolled')}</Enrolled>
            <StyledFluidButton>
              {IsEnrolled ? (
                <PrimaryButton fluid={true} onClick={handleOpting} text={t('optOut')} variant={'outline_blue'} />
              ) : (
                <PrimaryButton fluid={true} onClick={handleOpting} text={t('optIn')} variant={'outline_orange'} />
              )}
            </StyledFluidButton>
          </StyledEnrolledStatus>
        </StyledMarginDiv>

        <StyledDividingLine />
      </StyledPreferenceDiv>

      {/* .... Submit Button .... */}
      <StyledPreferenceDiv>
        <StyledButtonsContainer>
          <StyledButtonDiv $margin='0rem 4rem 0rem 0rem'>
            <PrimaryButton onClick={onSubmit} variant={'solid_blue'} text={t('saveChanges')} fluid={isMobileWidth} />
          </StyledButtonDiv>
          <PrimaryButton onClick={handleCancelButton} variant={'black_underline'} text={t('cancel')} fluid={isMobileWidth} />
        </StyledButtonsContainer>
      </StyledPreferenceDiv>

      <Modal
        maxWidth={836}
        maxWidthMobile={'87.7%'}
        maxHeight={461}
        maxHeightMobile={480}
        modalContent={popUpData}
        isOpen={showSimplePopUp}
        onClose={() => {
          setShowSimplePopup(false)
        }}
      >
        <SimplePopup
          modalContent={popUpData}
          togglePOPUp={setShowSimplePopup}
          updateDonor={updateDonor}
          id={donor?.id}
          handleOptingOut={handleOptingOut}
        />
      </Modal>

      <Modal
        maxWidth={836}
        maxWidthMobile={'87.7%'}
        maxHeight={461}
        maxHeightMobile={380}
        modalContent={optedOut_Popup_Data}
        isOpen={optedOutPopUp}
        onClose={() => {
          setOptedOutPopUp(false)
        }}
      >
        <SimplePopup modalContent={optedOut_Popup_Data} id={donor?.id} toggleOptedOutPopUp={setOptedOutPopUp} favCenter={center} />
      </Modal>
    </>
  )
}

const mapStateToProps = (state) => ({
  donor: state.donors.donors?.data,
  drupalMetaData: state.publicContent?.getDrupalNavigationSuccess?.metadata,
  centers: state.centers.donorCenters?.data,
  center: state.centers.getAllCentersSuccess,
  nearbyCenters: state.centers,
  centerStatus: state.centers.centerStatus,
  getDonorCentersAllLoading: state?.centers?.getAllCentersLoading,
  favoriteCenterList: state?.centers?.favouriteCenters,
  getFavoriteCenterLoading: state?.centers?.getFavoriteCenterInfoLoading,
  updateFavoriteCenterSuccess: state?.centers?.updateFavoriteCenterSuccess,
  updateDonorSuccess: state?.donors?.updateDonorSuccess
})

const mapDispatchToProps = (dispatch) => ({
  getDrupalNavigation: () => dispatch(PublicContentActions.getDrupalNavigation()),
  getDonorCentersAll: (searchStr) => dispatch(centersActions.getDonorCentersAll(searchStr)),
  getCenterDetails: (sfid) => dispatch(centersActions.getAllCenters(sfid)),
  getDonorProfile: (id) => dispatch(donorsActions.getDonors(id)),
  updateDonor: (id, donor) => dispatch(donorsActions.updateDonor(id, donor)),
  getDonorNearbyCenters: (donorSfid) => dispatch(centersActions.getDonorCenterInfo(donorSfid)),
  initializeApp: (res) => dispatch(StartupActions.initializeApp(res)),
  getCountries: () => dispatch(PublicContentActions.getCountries()),
  getFavoriteCenters: (donorSfid) => dispatch(centersActions.getFavoriteCenterInfo(donorSfid)),
  updateFavoriteCenter: (donorSfid, centerSfid) => dispatch(centersActions.updateFavoriteCenter(donorSfid, centerSfid))
})

export default connect(mapStateToProps, mapDispatchToProps)(CommunicationPreference)
