import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
/* DYNAMIC IMPORTS */
import importer from '@takedapdt/biolife-core/src/importer'
const CheckEmailComponent = importer('Components/CheckEmailComponent/')
const { SLUG_TYPES } = importer('Sagas/Constants/drupalConstants', null)

const CheckEmail = () => {
  const [slugValue, setSlugValue] = useState('')
  const [queryValue, setQueryValue] = useState('')
  let location = useLocation()
  /* 
    fetching the query param value for 'type' from url 
    if url -- /check-email?type=AccountUpdate, param passed to setSlugByQueryType -- AccountUpdate
  */
  useEffect(() => {
    /* fetch query value with type */
    const searchParams = new URLSearchParams(location.search)
    let queryValue = searchParams.get('type')
    setQueryValue(queryValue)
    /* set slug value based on queryType if present, else set slug from url */
    setSlugValue(
      queryValue
        ? queryValue === 'ForgotPassword'
          ? SLUG_TYPES.FORGOTPASSWORDCONFIRMATION
          : SLUG_TYPES.ACCOUNTUPDATECONFIRMATION
        : location.pathname.substring(1)
    )
    /* for pathname /check-email with no  query type, redirecting it to  ACCOUNTUPDATECONFIRMATION */
    location.pathname === '/check-email' && !queryValue && setSlugValue(SLUG_TYPES.ACCOUNTUPDATECONFIRMATION)
  }, [window.location])

  return <CheckEmailComponent slug_value={slugValue} classname={queryValue} />
}

export default CheckEmail
