import React from 'react'
import styled from 'styled-components'
import importer from '@takedapdt/biolife-core/src/importer'
const { getCssColorFromDrupalColorType, setTextColor } = importer('Constant/Utils', null)
const { devices } = importer('utils/styledComponentHelpers', null)
const { sanitizeHtml } = importer('utils/htmlHelpers', null)
const PrimaryButton = importer('Components/Buttons/PrimaryButton')

const StyledCardTemplate6 = styled.div`
    background: ${(props) => getCssColorFromDrupalColorType(props?.$bgColor)};
`

const StyledCardTemplate6InnerWrapper = styled.div`
    display: flex;
    ${props => {
        if (props.$alignment === 2) {
            return `
                flex-direction: row-reverse;
            `;
        } else {
            return ``;
        }
    }}
    ${devices.mobile} {
        flex-direction: column;
    }
`

const StyledCardTemplate6ImageColumn = styled.div`
    width: 50%;
    img {
        width: 100%;
        height: 100%;
    }
    ${props => {
        if (props.$alignment === 2) {
            return `
                img {
                    border-top-right-radius: 25px;
                    border-bottom-right-radius: 25px;
                }
            `;
        } else {
            return `
                img {
                    border-top-left-radius: 25px;
                    border-bottom-left-radius: 25px;
                    object-fit: cover;
                }
            `;
        }
    }}
    ${devices.mobile} {
        width: 100%;
        line-height: 0;
        img {
            border-top-left-radius: 25px;
            border-top-right-radius: 25px;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }
    }
`

const StyledCardTemplate6TextWithCTAColumn = styled.div`
    width: 50%;
    padding: 80px 90px 38px 90px;
    background: ${(props) => getCssColorFromDrupalColorType(props?.$bgColor)};
    ${props => {
        if (props.$alignment === 2) {
            return `
                border-top-left-radius: 25px;
                border-bottom-left-radius: 25px;
            `;
        } else {
            return `
                border-top-right-radius: 25px;
                border-bottom-right-radius: 25px;
            `;
        }
    }}
    ${devices.mobile} {
        width: 100%;
        padding: 48px 32px;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border-bottom-left-radius: 25px;
        border-bottom-right-radius: 25px;
        .ui.button {
            width: 100%;
        }
    }
`

const StyledCardTemplate6Title = styled.div`
    ${(props) => setTextColor(props?.$textColor)};
    margin-bottom: 16px;
`

const StyledCardTemplate6ShortDescription = styled.div`
    ${(props) => setTextColor(props?.$textColor)};
    margin-bottom: 16px;
`

const StyledCardTemplate6LongDescription = styled.div`
    ${(props) => setTextColor(props?.$textColor)};
    margin-bottom: 32px;
`

const StyledCardTemplate6BtnContainer = styled.div`
    ${devices.mobile} {
        button {
            width: 100% !important;
        }
    }
`

const CardTemplate6 = (props) => {
    const {
        field_cta_1: cta1,
        field_cta_1_style: cta1Style,
        field_component_desc: componentDesc,
        field_component_image: componentImage,
        field_component_title: componentTitle,
        field_short_description: shortDesc,
        field_alignment: alignment,
        field_bg_color: bgColor,
        field_bg_color_1: bgColor1,
        field_long_desc_color: longDescColor,
        field_short_desc_color: shortDescColor,
        field_title_color: titleColor
    } = props

  return (
    <StyledCardTemplate6 $bgColor={bgColor}>
        <div className='main-container'>
            <StyledCardTemplate6InnerWrapper $alignment={alignment}>
                <StyledCardTemplate6ImageColumn $alignment={alignment}>
                    <img src={componentImage?.url} alt={componentImage?.alt} />
                </StyledCardTemplate6ImageColumn>
                <StyledCardTemplate6TextWithCTAColumn $bgColor={bgColor1} $alignment={alignment}>
                    <StyledCardTemplate6Title
                        dangerouslySetInnerHTML={sanitizeHtml(componentTitle?.processed)}
                        $textColor={titleColor}
                    />
                    <StyledCardTemplate6ShortDescription
                        dangerouslySetInnerHTML={sanitizeHtml(shortDesc?.processed)}
                        $textColor={shortDescColor}
                    />
                    <StyledCardTemplate6LongDescription
                        dangerouslySetInnerHTML={sanitizeHtml(componentDesc?.processed)}
                        $textColor={longDescColor}
                    />

                    {cta1 ? <StyledCardTemplate6BtnContainer><PrimaryButton text={cta1?.title} variant={cta1Style} sendTo={cta1?.uri}/></StyledCardTemplate6BtnContainer> : null}
                </StyledCardTemplate6TextWithCTAColumn>
            </StyledCardTemplate6InnerWrapper>
        </div>
    </StyledCardTemplate6>
  )
}

export default CardTemplate6
