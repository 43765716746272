import styled from 'styled-components'
import { devices } from '../../../utils/styledComponentHelpers'

export const StyledAppointmentType = styled.div`
  padding: 1.6rem 0 4rem;
  text-align: center;
  display: flex;
  justify-contents: center;
`
export const StyledAppointmentTypeContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 66.4rem;
  margin: 0 auto;
  ${devices.mobile} {
    width: 34.3rem;
  }
`
export const StyledAppointmentTypeSelection = styled.div`
  margin-top: 1.9rem;
`
export const FirstTimeDonortext = styled.div`
  margin-top: 1.1rem;
  font-size: 1.2rem;
`
