import React from 'react'
import './styles.scss'
import renderDrupalComponent from '../../Constant/renderDrupalComponent'

const ContactUsConfirmation = (props) => {
  console.log(props)
  return (
    <div className='ContactUsConfirmationContainer'>
      {props?.ContactUsConfirmationContent?.contents?.map((component, index) => {
        if (component.type === 'breadcrumb') {
          let tempComp = { ...component, breadcrumbToggle: props?.ContactUsConfirmationContent?.metadata?.field_content_breadcrumb }
          component = tempComp
        }
        return renderDrupalComponent(component, index)
      })}
    </div>
  )
}

export default ContactUsConfirmation
