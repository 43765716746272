import styled, { keyframes } from 'styled-components'
import { devices } from '../../../utils/styledComponentHelpers'
import PrimaryTextArea from '../../Inputs/PrimaryTextArea'
import TextArea from '../Components/TextArea'

export const MessageAreaMainContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--color-white);
  padding: 2rem 0.8rem 2rem 1.4rem;;
  width: 100%;
  height: calc(100% - 70px);
`

export const MessageAreaContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overscroll-behavior-y: contain;
`

export const MainBotMessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
`

export const PillsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  /* grid-auto-rows: 1fr; */
  /* margin-top: 16px; */
  grid-gap: 12px;
`

export const Pills = styled.div`
  border: 2px solid var(--color-pills-light-grey);
  border-radius: 28px;
  text-align: center;
  /* align-self: center; */
  color: var(--color-bl-blue);
  font-size: 14px;
  /* 
    If small -> 
    19 characters in one line

    if big -> 

  */
  min-height: 55px;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  padding: ${({ $wordLength, $text, $needToBeFullSized, $intent }) => {
    if ($intent === 'CSATIntent' || $intent === 'CSATIntent_Sp') return '15px 30px'
    return $needToBeFullSized
      ? $wordLength > 53
        ? '5px 30px'
        : '15px 30px'
      : $wordLength > 37
      ? '15px 30px'
      : $wordLength > 19
      ? '5px 30px'
      : '15px 30px'
  }};

  grid-column: ${({ $wordLength, $needToBeFullSized, $intent }) => {
    if ($intent === 'CSATIntent' || $intent === 'CSATIntent_Sp') {
      return '1/3'
    }
    return $needToBeFullSized || $wordLength > 37 ? '1 / 3' : 'auto'
  }};

  &:last-child {
    padding: ${({ $wordLength, $text, $needToBeFullSized, $pillsCount, $intent }) => {
      // if 19 characters then on desktop only one line of pills is required and so 15px 40px
      // console.log('$wordLength>>', $wordLength)
      if ($intent === 'CSATIntent' || $intent === 'CSATIntent_Sp') return '15px 30px'
      return $wordLength > 37 || $needToBeFullSized || $pillsCount % 2 !== 0
        ? $wordLength > 53
          ? '5px 30px'
          : '15px 30px'
        : $wordLength > 19
        ? '5px 30px'
        : '15px 30px'
    }};
    grid-column: ${({ $pillsCount, $text, $needToBeFullSized, $wordLength, $intent }) => {
      if ($intent === 'CSATIntent' || $intent === 'CSATIntent_Sp') return '1/3'
      // console.log('$pillsCount>>', $pillsCount)
      return $wordLength > 37 || $needToBeFullSized || $pillsCount % 2 !== 0 ? '1/3' : 'auto'
    }};
  }

  ${devices.mobile} {
    /* 
      if not full length:
        after 35 characters will be full length
        and after 17 characters will be on second line
        -- create new--
        after 30 characters will be full length
        and after 17 characters will be on second line
    */
    /* word-spacing: 10px; */
    min-height: 55px;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    padding: ${({ $wordLength, $text, $needToBeFullSized, $pillsCount, $intent }) => {
      // console.log('$wordLength>>', $wordLength)
      if ($intent === 'CSATIntent' || $intent === 'CSATIntent_Sp') return '15px 30px'
      // console.log('$wordLength>>', $wordLength)
      return $needToBeFullSized || $wordLength > 30
        ? $wordLength > 43
          ? '5px 15px'
          : '15px 15px'
        : $wordLength > 17
        ? '5px 15px'
        : '15px 15px'
    }};
    grid-column: ${({ $wordLength, $needToBeFullSized, $intent }) => {
      // console.log('$wordLength>>', $wordLength, $wordLength > 26)
      if ($intent === 'CSATIntent' || $intent === 'CSATIntent_Sp') return '1 / 3'
      return $needToBeFullSized || $wordLength > 30 ? '1 / 3' : 'auto'
    }};

    &:last-child {
      padding: ${({ $wordLength, $text, $needToBeFullSized, $pillsCount, $intent }) => {
        // if 19 characters then on desktop only one line of pills is required and so 15px 40px
        // console.log('$wordLength>>', $wordLength)
        if ($intent === 'CSATIntent' || $intent === 'CSATIntent_Sp') return '15px 30px'
        return $wordLength > 35 || $needToBeFullSized || $pillsCount % 2 !== 0
          ? $wordLength > 43
            ? '5px 15px'
            : '15px 15px'
          : $wordLength > 17
          ? '5px 15px'
          : '15px 15px'
      }};
      grid-column: ${({ $pillsCount, $text, $needToBeFullSized, $wordLength, $intent }) => {
        if ($intent === 'CSATIntent' || $intent === 'CSATIntent_Sp') {
          return '1/3'
        }
        // console.log('$wordLength>>', $wordLength)
        // console.log('pills Count>>', $pillsCount)
        return $wordLength > 35 || $needToBeFullSized || $pillsCount % 2 !== 0 ? '1/3' : 'auto'
      }};
    }
  }

  &:hover {
    background-color: var(--color-dark-focus-blue);
    color: var(--color-white);
    cursor: pointer;
  }
`

export const BotMessageContainer = styled.div`
  display: flex;
  justify-self: flex-start;
  column-gap: 16px;
`

export const BotMessageImgContainer = styled.div``

export const BotMessageImg = styled.img`
  height: 40px;
  width: 40px;
`

export const BotTextContainerWithPills = styled.div`
  width: 84%;
`

export const BotMessage = styled.p`
  font-family: var(--font-family-regular);
  font-size: 16px;
  background: ${({ $loaderMessage }) => ($loaderMessage ? 'none' : 'var(--color-pills-light-grey) 0% 0% no-repeat padding-box')};
  border-radius: 12px;
  padding: 9px 12px;
  margin-bottom: ${({ $intent }) => ($intent === 'FinalIntent' ? '25rem' : '16px')};
  aspect-ratio: ${({ $loaderBot }) => ($loaderBot ? '1' : 'auto')};
  ol,
  ul {
    margin: 0 0 0 20px;
  }
  p {
    font-size: 16px;
    font-family: 'AvenirNextRegular';
    font-weight: normal;
  }
  li {
    font-size: 16px;
    font-family: 'AvenirNextRegular';
    font-weight: normal;
  }
`

export const BotButtonLinkDisplayDiv = styled.div`
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 2px 2px 8px #00000029;
  border-radius: 1.2rem;
  padding: 10px;
  max-width: 30rem;
  cursor: pointer;
  ${devices.mobile} {
    max-width: 26rem;
  }
`

export const BotButtonLinkTextContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const BotButtonLinkText = styled.p`
  font-family: var(--font-family-bold) !important;
  font-size: var(--caption-font-size);
  color: var(--color-bl-blue);
`

export const ButtonLinkRightArrow = styled.img`
  height: 16px;
  width: 16px;
`

export const ImageContainer = styled.div`
  #rightArrow {
    display: block;
  }
  #hoverRightArrow {
    display: none;
  }
  &:hover > #rightArrow {
    display: none;
  }
  &:hover > #hoverRightArrow {
    display: block;
  }
`

export const BotButtonLinkDescText = styled.p`
  font-size: var(--caption-mobile-font-size);
  color: var(--color-rich-black);
`

export const CustomPayloadContainer = styled.div`
  margin-bottom: 1.6rem;
`

export const CustomPayloadHTMLTextDiv = styled.div`
  // if $linksData is false means there is links button to be shown which needs 1.6rem marginBottom
  margin-bottom: ${({ $linksData }) => ($linksData ? '0' : '1.6rem')};
`

export const CustomPayloadHTMLLinksDiv = styled.div``

export const UserMessageContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 0px 8px 16px 0;
`

export const UserMessage = styled.p`
  font-family: var(--font-family-regular);
  font-size: 16px;
  background: #ebf7f9 0% 0% no-repeat padding-box;
  border-radius: 12px;
  padding: 9px 12px;
`

export const InputFieldContainer = styled.div`
  width: 100%;
  align-self: flex-end;
  margin: ${({ $intent }) => {
    switch ($intent) {
      case 'FinalIntent':
      case 'FinalIntent_Sp':
        return '1rem 0 0.5rem 0';
      default:
        return '2rem 0 0.5rem 0';
    }
  }};
  `

export const StyledTextArea = styled(TextArea)`
  width: 94%;
  border: none;
  max-height: 85px;
  overflow: hidden;
  font-family: var(--font-family-regular);
  font-size: var(--p-font-size);
  color: var(--color-rich-black);

  &:focus {
    border-radius: 25px;
  }

  &:focus-visible {
    outline: none; // var(--color-dark-grey) auto 1px;
  }

  ${devices.mobile} {
    font-size: var(--caption-font-size);
  }
`

export const StyledPrimaryTextArea = styled(PrimaryTextArea)`
  .styledTextArea {
    min-height: 50px !important;
    padding: 15px;
  }

  .styledTextArea:focus {
    border-radius: 22px !important;
  }
  .styledTextArea:focus-visible {
    outline: var(--color-dark-grey) auto 1px !important;
  }
`
export const ContainerEndChat = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 12px;
  /* margin-top: 20rem; */
`

export const EndChatText = styled.p`
  font-size: var(--caption-font-size);
  color: var(--color-dark-grey);
`

export const EndChatSpaceDiv = styled.div`
  height: 25rem;
`

const l5 = keyframes`
  0%{
    --_g: no-repeat radial-gradient(circle closest-side, #707070 90%, #0000),
    no-repeat radial-gradient(circle closest-side, #efefef 90%, #0000),
    no-repeat radial-gradient(circle closest-side, #333333 90%, #0000);
  }
  20%{
    background-position:0%   0%, 50%  50%,100%  50%;
    --_g: no-repeat radial-gradient(circle closest-side, #333333 90%, #0000),
    no-repeat radial-gradient(circle closest-side, #707070 90%, #0000),
    no-repeat radial-gradient(circle closest-side, #efefef 90%, #0000);
  }
  40%{
    background-position:0% 100%, 50%   0%,100%  50%;
    --_g: no-repeat radial-gradient(circle closest-side, #efefef 90%, #0000),
    no-repeat radial-gradient(circle closest-side, #707070 90%, #0000),
    no-repeat radial-gradient(circle closest-side, #333333 90%, #0000);
  }
  60%{
    background-position:0%  50%, 50% 100%,100%   0%;
    --_g: no-repeat radial-gradient(circle closest-side, #333333 90%, #0000),
    no-repeat radial-gradient(circle closest-side, #efefef 90%, #0000),
    no-repeat radial-gradient(circle closest-side, #707070 90%, #0000);
  }
  80%{
    background-position:0%  50%, 50%  50%,100% 100%;
    --_g: no-repeat radial-gradient(circle closest-side, #707070 90%, #0000),
    no-repeat radial-gradient(circle closest-side, #333333 90%, #0000),
    no-repeat radial-gradient(circle closest-side, #efefef 90%, #0000);
  }
  100%{
    background-position:0%  50%, 50%  50%,100% 100%;
    --_g: no-repeat radial-gradient(circle closest-side, #707070 90%, #0000),
    no-repeat radial-gradient(circle closest-side, #efefef 90%, #0000),
    no-repeat radial-gradient(circle closest-side, #efefef 90%, #0000);
  }
 `

export const LoaderDiv = styled.div`
  width: 38px;
  aspect-ratio: 2;
  --_g: no-repeat radial-gradient(circle closest-side, #707070 90%, #0000);
  background: var(--_g) 0% 10%, var(--_g) 50% 50%, var(--_g) 100% 50%;
  background-size: calc(100% / 3) 50%;
  animation: ${l5} 1.5s infinite linear;
`

export const MessageAreaAndSeeMoreWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: ${({ $intent }) => {
    switch ($intent) {
      case 'FinalIntent':
      case 'FinalIntent_Sp':
        return 'calc(100% - 110px)';
      default:
        return 'calc(100% - 90px)';
    }
  }};
`
  
export const SeeMoreContainer = styled.div`
  display: flex; 
  align-items: center;
  display: flex;
  justify-content: flex-end;
`

export const SeeMore = styled.div`
  position: absolute;
  padding: 12px 12px;
  bottom: 1%;
  right: 3%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: var(--color-white) 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 6px var(--color-box-shadow-grey);
  border: 2px solid var(--color-bl-orange);
  border-radius: 22px;
  cursor: pointer;
`
export const Text = styled.p`
  font-size: var(--caption-font-size);
  margin-right: 8px;
  color: var(--color-black);
`
export const SeeMoreImg = styled.img`
  height: 16px;
  width: 16px;
`

// const l5 = keyframes
//     background-position:0%   0%, 50%  50%,100%  50%; //   20%{
//   }
//   40%{
//     background-position:0% 100%, 50%   0%,100%  50%;
//   }
//   60%{
//     background-position:0%  50%, 50% 100%,100%   0%;
//   }
//   80%{
//     background-position:0%  50%, 50%  50%,100% 100%;
//   }
//  `

// export const LoaderDiv = styled.div`
//   width: 38px;
//   aspect-ratio: 2;
//   --_g: no-repeat radial-gradient(circle closest-side, #707070 90%, #0000);
//   background: var(--_g) 0% 10%, var(--_g) 50% 50%, var(--_g) 100% 50%;
//   background-size: calc(100% / 3) 50%;
//   animation: ${l5} 1s infinite linear;

// const l5 = keyframes`
//    0%  {
//      box-shadow: 20px 0 #efefef,-20px 0 #707070;
//      background-color: #707070;
//    }
//    33%  {
//      box-shadow: 20px 0 #efefef,-20px 0 #707070;
//      background-color: #efefef;

//    }
//    50%  {
//      box-shadow: 20px 0 #707070,-20px 0 #efefef;
//      background-color: #efefef;
//    }
//    100% {
//      box-shadow: 20px 0 #707070,-20px 0 #efefef;
//      background-color: #707070;
//    }
//  `

// export const LoaderDiv = styled.div`
//   width: 10px;
//   aspect-ratio: 1;
//   border-radius: 50%;
//   animation: ${l5} 1s infinite;
//   margin-left: 1.6rem;
// `