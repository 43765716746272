import React from 'react'
import moment from 'moment'

const myInfoValidations = (values) => {
  const errors = {}
  // First name validation
  if (values['firstName']) {
    if (values['firstName'].length < 1) {
      errors['firstName'] = 'firstNameShouldHaveAtLeasst1Char'
    } else if (values['firstName'].length === 1 && values['firstName'].match(/^[a-zA-Z]$/i) === null) {
      errors['firstName'] = 'firstNameShouldBeCharacteterOnly'
    } else if (
      values['firstName'].length > 1 &&
      values['firstName'].match(/^(?!.*(['ʻ,. -]){2})([a-zA-Z])([a-zA-Z'ʻ,. -]*[a-zA-Z])$/i) === null
    ) {
      errors['firstName'] = 'firstNameShouldBeCharacteterOnly'
    }
  } else {
    errors['firstName'] = 'firstNameIsRequired'
  }

  // Last name validation
  if (values['lastName']) {
    if (values['lastName'].length < 2) {
      errors['lastName'] = 'lastNameShouldHaveAtLeast2Char'
    } else if (values['lastName'].match(/^(?!.*(['ʻ,. -]){2})([a-zA-Z])([a-zA-Z'ʻ,. -]*[a-zA-Z])$/i) === null) {
      errors['lastName'] = 'lastNameShouldBeCharacter'
    }
  } else {
    errors['lastName'] = 'lastNameIsRequired'
  }

  // City Validations.....
  if (values.city) {
    values.city = values.city.trim()
    const expression = /^(?!.*(['ʻ,. -]){2})([A-Za-zÀ-ÖØ-öø-įĴ-őŔ-žǍ-ǰǴ-ǵǸ-țȞ-ȟȤ-ȳɃɆ-ɏḀ-ẞƀ-ƓƗ-ƚƝ-ơƤ-ƥƫ-ưƲ-ƶẠ-ỿ])([A-Za-zÀ-ÖØ-öø-įĴ-őŔ-žǍ-ǰǴ-ǵǸ-țȞ-ȟȤ-ȳɃɆ-ɏḀ-ẞƀ-ƓƗ-ƚƝ-ơƤ-ƥƫ-ưƲ-ƶẠ-ỿ'ʻ,. -]*[A-Za-zÀ-ÖØ-öø-įĴ-őŔ-žǍ-ǰǴ-ǵǸ-țȞ-ȟȤ-ȳɃɆ-ɏḀ-ẞƀ-ƓƗ-ƚƝ-ơƤ-ƥƫ-ưƲ-ƶẠ-ỿ])$/i
    if (values.city.length < 3) {
      errors.city = 'cityShouldHaveAtLeast3Char'
    } else if (!expression.test(String(values.city).toLowerCase())) {
      errors.city = 'cityIsInvalid'
    }
  } else {
    errors.city = 'cityCannotBeEmpty'
  }

  // Postal code validation
  if (values.zipCode) {
    if (!/^\d{5}$/.test(values.zipCode)) {
      errors.zipCode = 'zipCodeShouldHave5Digits'
    }
  } else {
    errors.zipCode = 'zipCodeIsRequired'
  }

  // Phoneand validation ..
  if (values.phoneNumber) {
    if (values.phoneNumber.replace(/\D/g, '').length !== 10) {
      errors.phoneNumber = 'phoneNumberMustHave10Digit'
    }
  } else {
    errors.phoneNumber = 'phoneNumberCannotBeEmpty'
  }

  // Birth date validation
  if (values.birthDate && values.birthDate.replace(/[_\/]/g, '')) {
    const validDate = moment(values.birthDate, 'MM/DD/YYYY', true).isValid()
    if (!validDate) {
      errors.birthDate = 'pleaseCorrectYourDateOfBirth'
    } else {
      if (values.birthDate && moment().diff(values.birthDate, 'years') < 18) {
        errors.birthDate = 'youMustBe18OrOlderToDonate'
      } else if (values.birthDate && moment().diff(values.birthDate, 'years') > 150) {
        errors.birthDate = 'mustBeLessThan150YearsOfAge'
      }
    }
  } else {
    errors.birthDate = 'dateOfBirthIsRequired'
  }

  if (!values.country) {
    errors.country = 'countryCannotBeEmpty'
  }

  if (!values.state) {
    errors.state = 'stateCannotBeEmpty'
  }

  if (!values.phoneType) {
    errors.phoneType = 'PhoneTypeCannotBeEmpty'
  }

  if (!values.gender) {
    errors.gender = 'genderIsRequired'
  }

  return errors
}

export default myInfoValidations
