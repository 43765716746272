import React, { useState, useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'
import importer from '@takedapdt/biolife-core/src/importer'
import { useLocation, useNavigate } from 'react-router-dom'
import { ROUTES } from '../../src/NavigationService'

const renderDrupalComponent = importer('Constant/renderDrupalComponent')
const LoaderSpinner = importer('Components/Loaders/LoaderSpinner')

// Actions
const PublicContentActions = importer('Stores/PublicContent/Actions')

const AppointmentScheduling = ({ donor, getPostLoginPage, PostLoginPageData, PostLoginPageMetaData, PostLoginPageDataLoading }) => {
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const existingAppointmentStartTime = sessionStorage.getItem('appointmentStartTime') || null

  useEffect(() => {
    if(!existingAppointmentStartTime) {
      const appointmentStartTime = new Date().getTime()
      sessionStorage.setItem('appointmentStartTime', appointmentStartTime)
    }
    getPostLoginPage(pathname.substring(1))
    window.scrollTo(0, 0)
  }, [])

  useEffect(()=>{
    if(donor?.defaultCenter === null){
      navigate(ROUTES.ChooseDonationCenter)
    }
  },[donor])

  return (
    <>
      <Helmet>
        <title>{PostLoginPageMetaData?.meta_title}</title>
        <meta name='description' content={PostLoginPageMetaData?.meta_description} />
        <link rel='canonical' href={`${window.location.origin}${window.location.pathname.toLowerCase()}`} />
      </Helmet>
      {PostLoginPageDataLoading ? (
        <LoaderSpinner />
      ) : (
        PostLoginPageData?.map((component, index) => {
          if (component?.type === 'breadcrumb') {
            let tempComp = {
              ...component,
              breadcrumbToggle: PostLoginPageMetaData?.field_content_breadcrumb,
              breadcrumbMobileToggle: PostLoginPageMetaData?.field_mobile_breadcrumb
            }
            component = tempComp
          }
          return renderDrupalComponent(component, index)
        })
      )}
    </>
  )
}

const mapStateToProps = (state) => ({
  PostLoginPageData: state.publicContent?.getPostLoginPageSuccess?.contents,
  PostLoginPageMetaData: state.publicContent?.getPostLoginPageSuccess?.metadata,
  PostLoginPageDataLoading: state.publicContent?.getPostLoginPageLoading,
  donor: state?.donors?.donors?.data,
})

const mapDispatchToProps = (dispatch) => ({
  getPostLoginPage: (slug) => dispatch(PublicContentActions.getPostLoginPage(slug))
})

export default connect(mapStateToProps, mapDispatchToProps)(AppointmentScheduling)
