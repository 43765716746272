const validate = (values) => {
  const errors = {}

  if (!values['loyaltyEnrolment']) {
    errors['loyaltyEnrolment'] = 'youmustaccepttheloyaltyenrolmenttermsandconditions'
  }

  return errors
}

export default validate
