import React, { useState } from 'react'
import styled from 'styled-components'
import './styles.scss'
import { ROUTES } from '../../../../NavigationService'
import { devices } from '../../../../utils/styledComponentHelpers'
import PrimaryButton from '../../../Buttons/PrimaryButton'
import { useScreenSize } from './../../../../Contexts/ResponsiveContextProvider'
import { useNavigate } from 'react-router-dom'

const StyledContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: ${(props) => props.internalPadding};
  width: 100%;
  ${devices.mobile} {
    padding: 5.5rem 1.4rem;
  }
`
const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 3rem;
`

const StyledTitle = styled.p`
  color: var(--color-bl-blue);
  margin-bottom: 2rem;
  font-size: 3rem;
  line-height: 3.5rem;
  font-weight: bolder !important;
  font-family: 'AvenirNextBold' !important;

  ${devices.mobile} {
    font-size: 2rem;
    line-height: 2.5rem;
  }
`

const StyledBiolifeImage = styled.img`
  margin-bottom: 2rem;

  ${devices.mobile} {
    width: 90%;
  }
`

const CancelButton = styled.p`
  margin-top: 1rem;
  text-decoration: underline;
  color: var(--color-bl-blue);
  cursor: pointer;

  ${devices.mobile} {
    margin-top: 2rem;
  }
`

const StyledButtonsContainer = styled.div`
  display: flex;
  justify-content: ${(props) => (props.type === 'optOut' ? 'space-between' : 'center')};
  width: 60%;

  ${devices.mobile} {
    flex-direction: column;
    width: 100%;
  }
`

const SimplePopup = ({ modalContent, form, togglePOPUp, toggleOptedOutPopUp, handleOptingOut }) => {
  const {
    type,
    showTickIcon,
    showBiolifeImage,
    showTitle,
    showsecondaryTitle,
    biolifeImage,
    Alt,
    title,
    buttonContent,
    discardContent,
    secondaryTitle,
    mainButtom,
    internalPadding,
    resetPreferences,
    setIsEnrolled,
    optedOutPopUp,
    setOptedOutPopUp,
    isFavCenter,
    favCenter,
    toggleDiscardPopup,
    handleRemoveFavoriteCenter
  } = modalContent

  const { isMobileWidth } = useScreenSize()
  const navigate = useNavigate()

  const handleCancel = () => {
    if (type === 'prefereceDiscard' && resetPreferences) {
      resetPreferences()
    } else if (type === 'profileDiscard' && form) {
      form.reset()
    } else if (type === 'optedOut' && toggleOptedOutPopUp) {
      toggleOptedOutPopUp(false)
    }
    if (togglePOPUp) {
      togglePOPUp(false)
    }
    if (toggleDiscardPopup) {
      toggleDiscardPopup(false)
    }
  }
  const removeFavCenter = () => {
    handleRemoveFavoriteCenter()
  }
  const handleTabChange = () => {
    navigate(`/${ROUTES.MyRewards}`)
  }

  return (
    <StyledContentWrapper internalPadding={internalPadding} icon={showTickIcon}>
      <Content>
        {showTickIcon && (
          <div className='iconImg completeIcon'>
            <div className='checkmark'></div>
          </div>
        )}

        {type === 'optIn' && showTitle && <StyledTitle>{title}</StyledTitle>}
        {showBiolifeImage && <StyledBiolifeImage src={biolifeImage} alt={Alt} />}
        {type !== 'optIn' && showTitle && <StyledTitle>{title}</StyledTitle>}

        {showsecondaryTitle && <p>{secondaryTitle}</p>}
      </Content>
      <StyledButtonsContainer type={type}>
        {type === 'optOut' && <PrimaryButton onClick={handleOptingOut} text={mainButtom} variant={'outline_blue'} />}
        {type === 'optIn' && <PrimaryButton onClick={handleTabChange} variant={'solid_blue'} text={mainButtom} fluid={isMobileWidth} />}
        <CancelButton onClick={isFavCenter ? removeFavCenter : handleCancel}>{buttonContent}</CancelButton>
      </StyledButtonsContainer>
    </StyledContentWrapper>
  )
}

export default SimplePopup
