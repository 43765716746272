import React from 'react'
import { Inactivity5minContainer, Inactivity5minDiv, H4Text, Desc, ButtonsDiv } from './Inactivity5min.styled'
import PrimaryButton from '../../../Buttons/PrimaryButton'

function Inactivity5min({ resumeChat, resetChat, selectedLanguage }) {
  return (
    <Inactivity5minContainer>
      <Inactivity5minDiv>
        <H4Text>{selectedLanguage === 'Español' ? 'Inactivo' : 'Inactive'}</H4Text>
        <Desc>
          {selectedLanguage === 'Español'
            ? '¿Sigues aquí? Por favor selecciona una opción para continuar.'
            : 'Are you still there? Please select an option to continue.'}
        </Desc>
      </Inactivity5minDiv>
      <ButtonsDiv>
        <PrimaryButton text={selectedLanguage === 'Español' ? 'Continuar' : 'Continue'} fluid onClick={() => resumeChat()} />
        <PrimaryButton
          variant='blue_underline'
          text={selectedLanguage === 'Español' ? 'Empezar de nuevo' : 'Start Over'}
          fluid
          onClick={resetChat}
        />
      </ButtonsDiv>
    </Inactivity5minContainer>
  )
}

export default Inactivity5min
