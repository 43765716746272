import React from 'react'
import { useSelector } from 'react-redux'
import { useScreenSize } from '../../../Contexts/ResponsiveContextProvider'
import ChatScreenBrokenIcon from '../../../../assets/media/icons/chatbotScreenBrokenIcon.svg'
import {
  BrokenScreenMainContainer,
  ExclamationImage,
  TextContainer,
  FAQLink,
  FAQMessage,
  OopsMessage,
  UnderstandingMessage,
  TextWrappingDiv
} from './brokenScreen.styled'

function ChatbotBrokenScreen({ selectedLanguage }) {
  const { isMobileWidth, isTabletWidth } = useScreenSize()
  const loggedInUser = useSelector((state) => state.users.user)
  const loggedInSession = useSelector((state) => state.users.session)

  const isDonorLoggedIn = () => {
    return loggedInUser && loggedInSession && loggedInUser.loginId
  }

  const takeMeToHref = () => {
    let link = '/donation-process/faqs'
    const UserLoggedIn = isDonorLoggedIn()
    if (UserLoggedIn && UserLoggedIn.length) {
      link = 'dashboard/faqs'
    }
    window.open(`${link}?origin=chatbot`, '_blank')
  }

  return (
    <BrokenScreenMainContainer>
      <ExclamationImage src={ChatScreenBrokenIcon} />
      <TextContainer>
        <OopsMessage>{selectedLanguage === 'Español' ? 'Ups… Algo salió mal.' : 'Oops… something went wrong'}</OopsMessage>
        <TextWrappingDiv>
          {selectedLanguage === 'Español' ? (
            <FAQMessage>
              Lamentamos las molestias. Puedes consultar nuestras <FAQLink onClick={() => takeMeToHref()}>preguntas frecuentes</FAQLink>{' '}
              para obtener respuestas inmediatas o intentar nuevamente más tarde.
            </FAQMessage>
          ) : (
            <FAQMessage>
              You can explore <FAQLink onClick={() => takeMeToHref()}>our FAQ’s</FAQLink> for immediate answers or try again later.
            </FAQMessage>
          )}

          <UnderstandingMessage>
            {selectedLanguage === 'Español' ? 'Agradecemos tu comprensión.' : 'We appreciate your understanding.'}
          </UnderstandingMessage>
        </TextWrappingDiv>
      </TextContainer>
    </BrokenScreenMainContainer>
  )
}

export default ChatbotBrokenScreen
