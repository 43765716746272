import React, { useState } from 'react'
import { Image, Input, TextArea } from 'semantic-ui-react'
import styled from 'styled-components'
import infoIcon from '../../../../assets/media/icons/InfoIcon.svg'
import success_check_circle from '../../../../assets/media/icons/success_check_circle.svg'
import hidePasswordIcon from '../../../../assets/media/icons/UI-password-visibility-icon-default.svg'
import passwordIcon from '../../../../assets/media/icons/UI-password-visibility-icon-show.svg'
import './styles.scss'
import { useTranslation } from 'react-i18next'

const IconContainer = styled.div`
  padding-left: 0.8rem;
`

const ErrorMsgContainer = styled.div`
  margin-top: 0.4rem;
`

const PrimaryTextArea = ({
  width,
  height,
  as,
  children,
  disabled,
  error,
  fluid,
  focus,
  inverted,
  onChange,
  type,
  placeholder,
  value,
  onBlur,
  defaultValue,
  asterisk,
  fieldLabel,
  success,
  isHtmlLabel,
  required,
  className,
  icon,
  id,
  isTextArea,
  autoComplete,
  meta
}) => {
  const [passwordView, setPasswordView] = useState(false)
  const { t } = useTranslation('ValidationErrorMessages')
  const message = meta?.touched && t(meta?.error)
  const togglePasswordView = () => {
    setPasswordView(!passwordView)
  }
  const fieldIcon = icon
    ? icon
    : type === 'password' && (
        <Image
          className='passwordIcon'
          src={passwordView ? passwordIcon : hidePasswordIcon}
          width={24}
          height={21}
          alt='Toggle Password Visibility'
          onClick={togglePasswordView}
        />
      )
  return (
    <div className={className}>
      {fieldLabel ? (
        isHtmlLabel ? (
          <div className='HTMLLabelWrapper'>
            <div dangerouslySetInnerHTML={{ __html: fieldLabel }} className={'FieldLabel'} />
            {required ? <small className='HtmlLabelAsterisk'>*</small> : null}
          </div>
        ) : (
          <label className={`FieldLabel ${disabled ? 'disabled' : ''}`}>
            {fieldLabel} {(required && !disabled) && <span role='presentation' className='AsteriskStyle'>*</span>}
          </label>
        )
      ) : null}
      {isTextArea ? (
        <div className='PrimaryTextArea'>
          <div className='TextAreaField'>
            <TextArea
              disabled={disabled}
              error={!!error || !!message}
              fluid={fluid}
              focus={focus}
              inverted={inverted}
              onChange={onChange}
              placeholder={placeholder}
              style={{ width: width ? width : '470px', height: height ? height : '56px' }}
              value={value}
              defaultValue={defaultValue}
              onBlur={onBlur}
              icon={fieldIcon}
              id={id}
              aria-label={fieldLabel ? fieldLabel : placeholder}
              className={error || message ? 'error-border' : ''}
            />
            <IconContainer>
              {(error || message) && <Image src={infoIcon} width='24' height='24' />}
              {(success || (meta && meta.touched && meta.valid)) && <Image src={success_check_circle} width='24' height='24' />}
            </IconContainer>
          </div>
          {/* error message */}
          {(error || message) && (
            <small>
              <ErrorMsgContainer>{<span className='ErrorMsg'>{error ? error : message}</span>}</ErrorMsgContainer>
            </small>
          )}
        </div>
      ) : (
        <div className='PrimaryInput'>
          <div className='InputField'>
            <Input
              as={as}
              disabled={disabled}
              error={!!error}
              fluid={fluid}
              focus={focus}
              inverted={inverted}
              onChange={onChange}
              type={type === 'password' ? (passwordView ? 'text' : 'password') : type}
              placeholder={placeholder}
              style={{ width: width ? width : '470px', height: height ? height : '56px' }}
              value={value}
              defaultValue={defaultValue}
              onBlur={onBlur}
              icon={fieldIcon}
              id={id}
              autoComplete={autoComplete}
              aria-label={fieldLabel ? fieldLabel : placeholder}
            >
              {children}
            </Input>
            {/* error state */}
            <div className='InfoIcon'>
              {error && <Image src={infoIcon} width='24' height='24' />}
              {success && required && <Image src={success_check_circle} width='24' height='24' />}
            </div>
          </div>
          {/* error message */}
          {error && (
            <small>
              <div className='ErrorMsgContainer'>{<span className='ErrorMsg'>{error}</span>}</div>
            </small>
          )}
        </div>
      )}
    </div>
  )
}

export default PrimaryTextArea
