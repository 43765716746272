import React, { useEffect } from 'react'
import './styles.scss'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import importer from 'common/importer'
const { getCssColorFromDrupalColorType, getCTAAlignment, setTextColor } = importer('Constant/Utils', null)
const PublicContentActions = importer('Stores/PublicContent/Actions')
const Loader = importer('Components/Loaders/LoaderSpinner')
const PrimaryButton = importer('Components/Buttons/PrimaryButton')

const CenterStatesList = ({
  getAllStatesList,
  allStatesList,
  getAllStatesLoading,
  field_component_title: componentTitle,
  field_title_color: componentTitleColor,
  field_cta_1: componentCTA,
  field_cta_1_style: componentCTAStyle,
  field_cta_alignment: componentCTAAlignment,
  field_bg_color: componentBackgroundColor
}) => {
  useEffect(() => {
    getAllStatesList()
  }, [])

  return (
    <div className='main-container' style={{ background: getCssColorFromDrupalColorType(componentBackgroundColor) }}>
      <div className='CenterStatesListContainer'>
        <div
          className='CenterStatesListContainerTitle'
          dangerouslySetInnerHTML={{ __html: componentTitle?.processed }}
          style={{ ...setTextColor(componentTitleColor) }}
        />
        <div className='CenterStatesList'>
          {getAllStatesLoading ? (
            <Loader />
          ) : (
            allStatesList?.length > 0 &&
            allStatesList?.map((item, index) => {
              if (item.countrycode == 'US')
                return (
                  <p key={index}>
                    <Link
                      to={`/locations/${item.description
                        .split(' ')
                        .join('-')
                        .toLowerCase()}`}
                    >
                      {item.description}
                    </Link>
                  </p>
                )
            })
          )}
        </div>
        {componentCTA?.title && componentCTA?.uri ? (
          <div style={{ textAlign: getCTAAlignment(componentCTAAlignment) }}>
            <PrimaryButton text={componentCTA?.title} sendTo={componentCTA?.uri} variant={componentCTAStyle} />
          </div>
        ) : null}
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  allStatesList: state.publicContent.States,
  getAllStatesLoading: state.publicContent?.getAllStatesLoading
})

const mapDispatchToProps = (dispatch) => ({
  getAllStatesList: () => dispatch(PublicContentActions.getAllStates())
})

export default connect(mapStateToProps, mapDispatchToProps)(CenterStatesList)
