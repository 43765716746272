import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
// import { string, func } from 'prop-types'
import { Form as FinalForm, Field } from 'react-final-form'
import { Form, Message, Dimmer } from 'semantic-ui-react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { isEmpty } from 'lodash'
import validate from './validate'
import './styles.scss'
import LoaderSpinner from '../../Loaders/LoaderSpinner'
/* DYNAMIC IMPORTS */
import importer from '@takedapdt/biolife-core/src/importer'
const { ROUTES } = importer('NavigationService', null)
const { useScreenSize } = importer('Contexts/ResponsiveContextProvider', null)
/* COMPONENTS */
const UserActions = importer('Stores/Users/Actions')
const PrimaryButton = importer('Components/Buttons/PrimaryButton')
const PasswordValidation = importer('Components/PasswordValidation')
const InputField = importer('Components/Forms/FormFields/InputField')
const PasswordLinkExpired = importer('Components/PasswordLinkExpired')

const VerifyAccountForm = ({
  verifyTokenLoading,
  verifyTokenError,
  verifyTokenSuccess,
  resetPasswordLoading,
  resetPasswordError,
  uniqueFormData,
  forgotPasswordError,
  setupNewPasswordLoading,
  handleSubmitFunc
}) => {
  const location = useLocation()
  const { isMobileWidth } = useScreenSize()
  const { t, i18n } = useTranslation('VerifyAccountForm')
  const [resetPasswordPage, setResetPasswordPage] = useState(false)
  const [isAccountVerified, setIsAccountVerified] = useState(false)
  const [passwordReveal, setPasswordReveal] = useState(false)
  const [passwordConfirmReveal, setPasswordConfirmReveal] = useState(false)
  const [hasPasswordExpired, setHasPasswordExpired] = useState(false)
  const [saveClicked, setSaveClicked] = useState(false)

  useEffect(() => {
    location.pathname === ROUTES.AuthResetPassword ? setResetPasswordPage(true) : setResetPasswordPage(false)
  }, [])

  // Checking the error code returning from OKTA on expiration or used token. This error code is hardcoded in OKTA response of /verify-token
  if (resetPasswordPage && verifyTokenError && (verifyTokenError.errorCode === 'E0000011' || verifyTokenError?.error)) {
    return <PasswordLinkExpired />
  }

  if (resetPasswordPage && verifyTokenLoading) {
    return <LoaderSpinner />
  }

  const handleSubmit = (values) => {
    setSaveClicked(true)
    handleSubmitFunc(values)
  }

  return (
    <>
      {!isAccountVerified && !hasPasswordExpired && (
        <div className='main-container resetpassword__block'>
          <FinalForm
            onSubmit={handleSubmit}
            validate={validate}
            initialValues={resetPasswordPage ? { ...verifyTokenSuccess?.userInfo, loginId: verifyTokenSuccess?.userInfo?.login } : {}}
            render={({ handleSubmit, values }) => {
              return (
                <Form onSubmit={handleSubmit}>
                  {uniqueFormData.map(({ type, field_component_title_1, field_component_title, field_cta_1, field_cta_1_style }) => {
                    if (type === 'form_field_component') {
                      return (
                        <div className='resetpassword__block--inputblock'>
                          <Field name={field_component_title_1}>
                            {({ input, meta }) => {
                              return (
                                <InputField
                                  required
                                  meta={meta}
                                  {...input}
                                  fieldLabel={field_component_title.processed}
                                  value={input.value}
                                  isHtmlLabel
                                  type={passwordReveal || passwordConfirmReveal ? 'text' : 'password'}
                                />
                              )
                            }}
                          </Field>
                        </div>
                      )
                    }
                    if (type === 'form_cta_component') {
                      return (
                        <div>
                          <div className='resetpassword__block--validation__block'>
                            <PasswordValidation passwordInput={values['New Password'] || ''} />
                            <p className='resetpassword__block--required--text'>
                              <span>* </span>
                              {t('requiredField')}
                            </p>
                            {/* {formErrors(errors, submitFailed)} */}
                            {resetPasswordPage && resetPasswordError && (
                              <Message visible warning>
                                <p>{resetPasswordError?.data ? resetPasswordError?.data?.message : resetPasswordError?.message}</p>
                              </Message>
                            )}
                          </div>
                          {saveClicked && !resetPasswordPage && forgotPasswordError && (
                            <Message visible warning>
                              <p> {forgotPasswordError} </p>
                            </Message>
                          )}
                          <PrimaryButton
                            text={field_cta_1?.title}
                            loading={resetPasswordLoading || setupNewPasswordLoading}
                            variant={field_cta_1_style}
                            type='submit'
                            fluid={isMobileWidth}
                          />
                        </div>
                      )
                    }
                  })}
                </Form>
              )
            }}
          />
        </div>
      )}
    </>
  )
}

const mapStateToProps = ({ users }) => ({
  verifyTokenLoading: users.resetPassword2Loading,
  verifyTokenSuccess: users.resetPassword2Success,
  verifyTokenError: users.resetPassword2Failure?.response?.data,
  resetPasswordLoading: users.resetPassword3Loading,
  resetPasswordSuccess: users.resetPassword3Success,
  resetPasswordError: users.resetPassword3Failure,
  forgotPasswordError: users.setupNewPasswordFailure?.message,
  setupNewPasswordLoading: users.setupNewPasswordLoading
})

export default connect(mapStateToProps, null)(VerifyAccountForm)
