import React from 'react'
import { Link } from 'react-router-dom'
import { Menu } from 'semantic-ui-react'
import './styles.scss'

import { CareersSpanishURL } from '../../../NavigationService'

const SecondaryMenu = ({ items }) => {
  return (
    <Menu pointing secondary vertical className='SecondaryMenu' borderless>
      {items?.map((item, index) => {
        if (item.href.includes('https')) {
          return (
            <Menu.Item
              key={index}
              name={item.title}
              className='SecondaryMenuItem'
              // active={activeItem === item.title}
              href={(item?.title?.toLowerCase() === 'carreras' ? CareersSpanishURL : item?.href)}
              as='a'
              target={item.target}
            >
              {item.title}
            </Menu.Item>
          )
        } else {
          return <Menu.Item className='SecondaryMenuItem' as={Link} to={item.href} name={item.title} key={index} content={item.title} />
        }
      })}
    </Menu>
  )
}

export default SecondaryMenu
