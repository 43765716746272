import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useScreenSize } from '../../../Contexts/ResponsiveContextProvider'
import { connect } from 'react-redux'
import moment from 'moment'
import { isEmpty, isNull, isUndefined } from 'lodash'
import { Link } from 'react-router-dom'
import { ROUTES } from '../../../NavigationService'
import {
  StyledConfirmedAppointment,
  StyledConfirmedAppointmentHeader,
  StyledConfirmedAppointmentContent,
  StyledConfirmedAppointmentContentTitle,
  StyledConfirmedAppointmentContentAppointmentDetails,
  StyledConfirmedAppointmentContentCenter,
  StyledConfirmedAppointmentContentCenterAddress,
  StyledConfirmedAppointmentContentDateTime,
  StyledConfirmedAppointmentContentDateTimeButton,
  StyledIcon,
  StyledExpandIcon,
  StyledCollapsedIcon,
  StyledAppointmentChecklistAccordion,
  StyledAppointmentChecklistAccordionTitle,
  StyledAppointmentChecklistAccordionContent,
  StyledNewDonorBonusAccordion,
  StyledNewDonorBonusAccordionTitle,
  StyledNewDonorBonusAccordionContent,
  StyledButtonsContainer,
  StyledCalendarIcon,
  StyledClockIcon,
  StyledCalendarLink,
  StyledList,
  StyledUList,
  TextComponent
} from './styled'
import PrimaryButton from '../../Buttons/PrimaryButton'
import appointmentsActions from '@takedapdt/biolife-core/src/Stores/Appointments/Actions'
import { google, outlook, office365, yahoo, ics } from 'calendar-link'

const ConfirmedAppointmentModal = ({
  lastPhysicalDate,
  open,
  toggleModal,
  appointment,
  center,
  createNewAppointmentSuccess,
  update,
  createNewAppointmentFailure,
  loggedInUser,
  centers,
  isNewDonorBonusEligible,
  newDonorBonusEligibilityLoading,
  inquiry,
  donor,
  checkNewDonorBonusEligibility,
  searchedCenters,
  rescheduleAppointmentSuccess,
  toggleRescheduleModal,
  setIsAppointmentRescheduling
}) => {
  const { t, i18n } = useTranslation('AddToCalendarModal')
  const { isMobileWidth } = useScreenSize()
  const [donorBonusInformation, setDonorBonusInformation] = useState({})
  const [openDonorChecklistAccordion, setOpenDonorChecklistAccordion] = useState(false)
  const [openNDBAccordion, setOpenNDBAccordion] = useState(false)
  const [calendarisOpen, setCalendarIsOpen] = useState()

  const getCalendarData = () => {
    if (!isEmpty(createNewAppointmentSuccess)) {
      const event = {
        title: createNewAppointmentSuccess.appointmentType,
        description: `${createNewAppointmentSuccess.appointmentType} ${t('appointmentat')} ${createNewAppointmentSuccess.addressLine1} ${
          createNewAppointmentSuccess.addressLine2
        }`,
        location: `${createNewAppointmentSuccess.addressLine1} ${createNewAppointmentSuccess.addressLine2}`,
        startTime: createNewAppointmentSuccess.startDateTime,
        endTime: createNewAppointmentSuccess.endDateTime,
        duration: [0.5, 'hour']
      }
      return event
    }
    return null
  }
  const event = getCalendarData()
  const calendarTypes = [
    {
      href: ics(event),
      name: 'Apple'
    },
    {
      href: google(event),
      name: 'Google'
    },
    {
      href: outlook(event),
      name: 'Outlook'
    },
    {
      href: office365(event),
      name: 'Office365'
    },
    {
      href: yahoo(event),
      name: 'Yahoo'
    }
  ]

  const viewCentersButton = () => {
    setOpenDonorChecklistAccordion(!openDonorChecklistAccordion)
  }
  const viewCentersButton1 = () => {
    setOpenNDBAccordion(!openNDBAccordion)
  }

  const openMap = () => {
    return window.open(`http://www.google.com/maps/place/${center?.latitude},${center?.longitude}`)
  }

  useEffect(() => {
    if (open) {
      if (donor.pdn) {
        inquiry.pastDonations.length === 0 ? checkNewDonorBonusEligibility() : null
      } else {
        checkNewDonorBonusEligibility()
      }
    }
  }, [open])

  useEffect(() => {
    if ((center && centers) || (center && searchedCenters)) {
      let centerNewDonorBonusInformation = (centers || searchedCenters).find((item) => item.sfid === center.sfid)?.newDonorBonusInformation
      if (centerNewDonorBonusInformation) {
        setDonorBonusInformation(centerNewDonorBonusInformation)
      }
    }
  }, [center, centers])

  return (
    <StyledConfirmedAppointment>
      <StyledConfirmedAppointmentHeader>
        <StyledIcon />
      </StyledConfirmedAppointmentHeader>
      <StyledConfirmedAppointmentContent>
        <StyledConfirmedAppointmentContentTitle>
          <strong>{!update ? t('appointmentconfirmed') : t('appointmentRescheduled')}</strong>
        </StyledConfirmedAppointmentContentTitle>
        <StyledConfirmedAppointmentContentAppointmentDetails>
          <StyledConfirmedAppointmentContentCenter>
            <strong>{center && loggedInUser?.role === 'Donor' && `${center?.name}, ${center?.stateCode}`}</strong>
          </StyledConfirmedAppointmentContentCenter>
          <StyledConfirmedAppointmentContentCenterAddress>
            {center && loggedInUser?.role === 'Donor' && `${center?.addressLine1}, ${center?.stateCode} ${center?.zipcode}`}
          </StyledConfirmedAppointmentContentCenterAddress>
          <TextComponent
            $color='titleBlue'
            $fontFamily='var(--font-family-medium)'
            $textDecoration='underline'
            $fontSize='1.4rem'
            onClick={() => openMap()}
          >
            {t('getDirections')}
          </TextComponent>
          <StyledConfirmedAppointmentContentDateTime>
            <StyledCalendarIcon />
            {`${moment.parseZone(appointment.startDateTime).format('MMMM D, YYYY')}`}
          </StyledConfirmedAppointmentContentDateTime>
          <StyledConfirmedAppointmentContentDateTime>
            <StyledClockIcon />
            {`${appointment.startTime && appointment.startTime}`}
          </StyledConfirmedAppointmentContentDateTime>
          {/* //Add to Calendar button */}
          <StyledConfirmedAppointmentContentDateTimeButton>
            <PrimaryButton variant='blue_underline' text={t('addToCalendar')} onClick={() => setCalendarIsOpen(!calendarisOpen)} />

            <StyledUList $calendarisOpen={calendarisOpen}>
              {calendarTypes.map((calendar) => (
                <StyledList>
                  <StyledCalendarLink href={calendar.href} target='_blank'>
                    {calendar.name} {t('calendar')}
                  </StyledCalendarLink>
                </StyledList>
              ))}
            </StyledUList>
          </StyledConfirmedAppointmentContentDateTimeButton>
        </StyledConfirmedAppointmentContentAppointmentDetails>
        {isNewDonorBonusEligible && Object.keys(donorBonusInformation)?.length > 0 && (
          <StyledNewDonorBonusAccordion>
            <StyledNewDonorBonusAccordionTitle onClick={viewCentersButton1}>
              <p>
                {t('newdonorbonus')} ({t('upto')} ${donorBonusInformation?.ndbAmount})
              </p>
              {!openNDBAccordion ? <StyledCollapsedIcon /> : <StyledExpandIcon />}
            </StyledNewDonorBonusAccordionTitle>
            <StyledNewDonorBonusAccordionContent $expanded={openNDBAccordion}>
              <div>
                {t('code')} <strong>{donorBonusInformation?.payCode}</strong>
              </div>
              <div>
                {t('youWillBePaidOut')}$
                {donorBonusInformation?.bonusStructure
                  ?.split('/')
                  .join(', $')
                  .substring(
                    0,
                    donorBonusInformation?.bonusStructure
                      .split('/')
                      .join(', $')
                      .lastIndexOf(', $')
                  ) +
                  ` ${t('and')}` +
                  donorBonusInformation?.bonusStructure
                    .split('/')
                    .join(', $')
                    .substring(
                      donorBonusInformation?.bonusStructure
                        .split('/')
                        .join(', $')
                        .lastIndexOf(', $') + 1
                    )}
              </div>
              <div>
                {t('newdonorscanreceive')} ${donorBonusInformation?.ndbAmount} {t('in')}{' '}
                {donorBonusInformation?.bonusStructure.split('/').length} {t('donations')}
              </div>
              <div>
                {t('exp')} {moment(donorBonusInformation?.offerExpDate).format('MM/DD/YY')}
              </div>
            </StyledNewDonorBonusAccordionContent>
          </StyledNewDonorBonusAccordion>
        )}
        {(isNull(donor?.pdn) || isEmpty(lastPhysicalDate) || isUndefined(lastPhysicalDate)) && (
          <StyledAppointmentChecklistAccordion>
            <StyledAppointmentChecklistAccordionTitle onClick={viewCentersButton}>
              <p>{t('prepareforappointment')}</p>
              {!openDonorChecklistAccordion ? <StyledCollapsedIcon /> : <StyledExpandIcon />}
            </StyledAppointmentChecklistAccordionTitle>
            <StyledAppointmentChecklistAccordionContent $expanded={openDonorChecklistAccordion}>
              {t('prepareAppointment')},{' '}
              <a href={t('clickHereEndpont')} target='_blank' className='underline-dark-blue'>
                {t('viewappointmentchecklist')}
              </a>
              {t('emailedtoyou')}
            </StyledAppointmentChecklistAccordionContent>
          </StyledAppointmentChecklistAccordion>
        )}
      </StyledConfirmedAppointmentContent>
      <StyledButtonsContainer>
        {(isNull(donor?.pdn) || isEmpty(lastPhysicalDate) || isUndefined(lastPhysicalDate)) && (
          <PrimaryButton
            text={t('viewchecklist')}
            sendTo={t('clickHereEndpont')}
            variant='outline_blue'
            fluid={isMobileWidth}
            onClick={() => toggleModal()}
          />
        )}
        {!update && (
          <PrimaryButton
            text={t('rescheduleOrCancelAppointment')}
            variant='outline_black'
            style={{ fontSize: '1.8rem' }}
            onClick={() => {
              toggleRescheduleModal()
              toggleModal()
              setIsAppointmentRescheduling(true)
            }}
            fluid={isMobileWidth}
          />
        )}
        <Link
          to={`${ROUTES && ROUTES.AppointmentDetails}`}
          state={createNewAppointmentSuccess || rescheduleAppointmentSuccess}
          onClick={() => toggleModal()}
        >
          <span className='underline-dark-blue'>{t('seedetails')}</span>
        </Link>
      </StyledButtonsContainer>
    </StyledConfirmedAppointment>
  )
}

const mapStateToProps = (state) => ({
  loggedInUser: state.users?.user,
  isNewDonorBonusEligible: state.appointments?.newDonorBonusEligibilitySuccess?.data?.isEligible,
  newDonorBonusEligibilityLoading: state.appointments?.newDonorBonusEligibilityLoading,
  inquiry: state.donors.inquiryDonorSuccess?.data,
  donor: state.donors?.donors?.data,
  searchedCenters: state.centers.getZipcodeSearchCentersSuccess?.data
})

const mapDispatchToProps = (dispatch) => ({
  checkNewDonorBonusEligibility: () => dispatch(appointmentsActions.newDonorBonusEligibility())
})

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmedAppointmentModal)
