export const cssAppliedContent = (body) => `
<div>
  ${body}
<div>
`

export const saveDataInLocalStorage = (data) => {}

export const extractDataFromLocalStorage = (data) => {
  const retrievedData = JSON.parse(data)
  return retrievedData
}
