import React from 'react'
import { Div } from './styled'

function ExternalComponentSkeleton(props) {
  const {
    field_title_color: titleColor,
    field_component_title: title,
    field_bg_color: bgColor,
    field_cta_1: ctaTitle,
    field_cta_1_style: CTAStyle,
    field_cta_alignment: fieldCTAAlignment
  } = props || {}
  return (
    <Div $bgColor={bgColor || 'var(--color-white)'}>
      <div>
        <div className='main-container'>
          {title && title.processed && <TextComponent field_title_color={titleColor} field_component_title={title} />}
        </div>
        {props.children}
        <div className='main-container'>
          {CTAStyle && <ButtonCTA field_cta_1_style={CTAStyle} field_cta_1={ctaTitle} field_cta_alignment={fieldCTAAlignment} />}
        </div>
      </div>
    </Div>
  )
}

export default ExternalComponentSkeleton
