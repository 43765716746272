import React, { useEffect } from 'react'
import './styles.scss'
import importer from '@takedapdt/biolife-core/src/importer'
const PrimaryButton = importer('Components/Buttons/PrimaryButton')
const { getCssColorFromDrupalColorType, setTextColor, getMediaFromDrupalMimeType } = importer('Constant/Utils', null)
import { connect } from 'react-redux'

const PageNotFound = ({ drupalNavData }) => {
  const drupal404ErrorData = drupalNavData?.find((item) => item.type === '404_component')

  return (
    <div className='error404Container'>
      <div className='error404ImgContainer'>
        {getMediaFromDrupalMimeType(drupal404ErrorData?.field_component_asset?.mimetype, drupal404ErrorData?.field_component_asset?.url, drupal404ErrorData?.field_component_asset?.alt)}
      </div>
      <div
        className='error404ShortDesc'
        style={{ ...setTextColor(drupal404ErrorData?.field_short_desc_color) }}
        dangerouslySetInnerHTML={{ __html: drupal404ErrorData?.field_short_description?.processed }}
      />
      <div
        className='error404Desc'
        style={{ ...setTextColor(drupal404ErrorData?.field_long_desc_color) }}
        dangerouslySetInnerHTML={{ __html: drupal404ErrorData?.field_component_desc?.processed }}
      />
      <div className='error404ButtonContainer'>
        <PrimaryButton
          text={drupal404ErrorData?.field_cta_1?.title}
          variant={drupal404ErrorData?.field_cta_1_style}
          sendTo={drupal404ErrorData?.field_cta_1?.uri}
        />
      </div>
      <div className='error404ViewSiteMap'>
        <PrimaryButton
          text={drupal404ErrorData?.field_cta_2?.title}
          variant={drupal404ErrorData?.field_cta_2_style}
          sendTo={drupal404ErrorData?.field_cta_2?.uri}
        />
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  drupalNavData: state.publicContent?.getDrupalNavigationSuccess?.contents
})

export default connect(mapStateToProps, null)(PageNotFound)
