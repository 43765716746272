import styled from 'styled-components'
import { devices } from '../../../../utils/styledComponentHelpers'

export const KababContainer = styled.div`
  position: fixed;
  left: ${({ $positions }) => `${$positions.x}px`};
  top: ${({ $positions }) => `${$positions.y}px`};
  background-color: var(--color-white);
  border: 1px solid var(--color-dark-grey);
  z-index: 100000;
`
