import React, { useEffect, useState } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import { TextComponent, Highlight4, DivWithFlex, Div } from './styles/BlogView.styled'
import moment from 'moment'
import { connect, useDispatch, useSelector } from 'react-redux'
import { Helmet } from 'react-helmet'
import styled from 'styled-components'
const { devices, sizes } = importer('utils/styledComponentHelpers', null)

import { Grid, Image, Embed } from 'semantic-ui-react'
// import ClockIcon from '../../../../assets/media/icons/icon-clock.svg'
// import BackIcon from '../../../../assets/media/icons/back-icon.svg'
import importer from '@takedapdt/biolife-core/src/importer'
const getYoutubeId = importer('Helpers/YoutubeId')
import BlogCard from './BlogCard'
import { useTranslation } from 'react-i18next'
const { sanitizeHtml } = importer('utils/htmlHelpers', null)
const PublicContentActions = importer('Stores/PublicContent/Actions')
const RelatedArticlesCarousel = importer('Components/BlogPage/RelatedArticlesCorousel')
const { useScreenSize } = importer('Contexts/ResponsiveContextProvider', null)

const StyledDiv = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 0.3fr));
  grid-gap: 2rem;
  ${devices.tablet} {
    display: block;
  }
`
const BlogView = (props) => {
  const { pathname } = useLocation()
  const { category, blogsData, field_bg_color: backgroundColor, blog, setFilteredBlog, field_component_title } = props || {}
  const { isMobileWidth, isTabletWidth } = useScreenSize()
  const [exploreMoreBlogs, setExploreMoreBlogs] = useState([])
  const { t } = useTranslation('BlogView')
  useEffect(() => {
    const exploreBlogs = []
    const otherBlogs = []
    if (blogsData && blogsData.length) {
      for (let blog of blogsData) {
        // if (blog.field_slug_value === pathname.slice(1)) {
        //   continue
        // }
        if (blog['field_ref_topic'] === category) {
          exploreBlogs.push(blog)
        }
        if (blog['field_category'] === category) {
          exploreBlogs.push(blog)
        } else {
          otherBlogs.push(blog)
        }
      }
      if (exploreBlogs.length <= 2) {
        exploreBlogs.push(...otherBlogs)
      }
      setExploreMoreBlogs([...new Set(exploreBlogs)])
    }

    return () => {
      setExploreMoreBlogs([])
    }
  }, [blogsData])

  return (
    <Div bgColor={backgroundColor || 'transparent'} padding={'0 0 4rem 0'}>
      <div className='main-container'>
        {exploreMoreBlogs.length > 0 && (
          <>
            <Highlight4 color='bl_blue' dangerouslySetInnerHTML={sanitizeHtml(field_component_title?.processed)}></Highlight4>

            <StyledDiv>
              {isMobileWidth || isTabletWidth ? (
                <RelatedArticlesCarousel exploreMoreBlogs={exploreMoreBlogs?.slice(0, 3)} />
              ) : (
                exploreMoreBlogs?.slice(0, 3).map((blog) => <BlogCard blog={blog} />)
              )}
            </StyledDiv>
          </>
        )}
      </div>
    </Div>
  )
}
export default BlogView
