import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { sanitizeHtml } from '../../../src/utils/htmlHelpers'
import PrimaryButton from '../Buttons/PrimaryButton'
import { formatDate } from '../../../src/Helpers/Utils'
import PaymentsActions from '@takedapdt/biolife-core/src/Stores/Payments/Actions'
import styled from 'styled-components'
import { getCssColorFromDrupalColorType } from '../../../src/Constant/Utils'
import { useScreenSize } from '../../../src/Contexts/ResponsiveContextProvider'
import moment from 'moment'

const PaymentContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1rem 0;
  padding: 2.4rem 0 2.4rem 0;
  border-bottom: 1px solid #ccc;
`

const PaymentDetails = styled.div`
  display: flex;
  flex-direction: column;
`

const PaymentAmount = styled.strong`
  display: flex;
  flex-direction: column;
  font-weight: bold !important;
`
const Status = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 0.8rem;
`

const TransactionType = styled.strong`
  font-weight: bold !important;
`

const DateStatusWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

const TransactionDate = styled.div`
  margin-right: 1rem; /* Adjust margin as needed */
  margin-top: 0.8rem;
`

const Title = styled.div`
  padding-bottom: 2.4rem;
  border-bottom: 1px solid #ccc;
`

const Button = styled.div`
  border: none;
  padding-top: 4.8rem;
  background-color: white;
  text-align: center;
`
const RecentPaymentActivity = ({
  donor,
  paymentHistory,
  getPaymentHistory,
  field_title_color,
  field_component_title,
  field_cta_1,
  field_cta_1_style
}) => {
  const { t, i18n } = useTranslation('PaymentHistory')
  useEffect(() => {
    if (donor?.pdn) {
      getPaymentHistory(donor?.pdn, null, null)
    }
  }, [])
  const { isMobileWidth, isTabletWidth } = useScreenSize()
  const sortedPaymentHistoryData = paymentHistory?.data?.paymentHistory?.sort((a, b) => {
    // Create TransactionDate objects for comparison
    const dateA = new Date(a.transactionDate)
    const dateB = new Date(b.transactionDate)
    // Sort in descending order (latest first)
    return dateB - dateA
  })
  return (
    <div className='main-container recent-payment-activity'>
      {paymentHistory && (
        <>
          <Title
            style={{ color: getCssColorFromDrupalColorType(field_title_color) }}
            dangerouslySetInnerHTML={sanitizeHtml(field_component_title?.value)}
          />

          {sortedPaymentHistoryData?.map((item, index) => (
            <PaymentContainer key={index}>
              <PaymentDetails>
                <TransactionType> {item.type === 6 ? t('giftCard') : t('debitCard')}</TransactionType>
                <DateStatusWrapper>
                  <TransactionDate>{moment(item.transactionDate).format('MMMM DD, YYYY')}</TransactionDate>
                </DateStatusWrapper>
              </PaymentDetails>
              <PaymentDetails>
                <PaymentAmount>${item.transactionAmount}</PaymentAmount>
                <Status>{item.processed ? t('received') : t('pending')}</Status>
              </PaymentDetails>
            </PaymentContainer>
          ))}
          <Button>
            <PrimaryButton
              fluid={isMobileWidth || isTabletWidth}
              sendTo={field_cta_1?.uri}
              text={field_cta_1?.title}
              variant={field_cta_1_style}
            />
          </Button>
        </>
      )}
    </div>
  )
}

const mapStateToProps = (state) => ({
  donor: state?.donors?.donors?.data,
  user: state.users.loginHerokuSuccess,
  paymentHistory: state.payments?.getPaymentHistorySuccess,
  paymentHistoryLoading: state.payments?.getPaymentHistoryLoading,
  paymentHistoryError: state.payments?.getPaymentHistoryFailure
})

const mapDispatchToProps = (dispatch) => ({
  getPaymentHistory: (pdn, startDate, endDate) => dispatch(PaymentsActions.getPaymentHistory(pdn, startDate, endDate))
})

export default connect(mapStateToProps, mapDispatchToProps)(RecentPaymentActivity)
