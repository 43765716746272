import React from 'react'
import styled from 'styled-components'
import importer from '@takedapdt/biolife-core/src/importer'
const { getCssColorFromDrupalColorType, setTextColor } = importer('Constant/Utils', null)
const { devices } = importer('utils/styledComponentHelpers', null)
const { sanitizeHtml } = importer('utils/htmlHelpers', null)
import ButtonCTA from '../ButtonCTA'

const StyledCardTemplate5 = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  padding: 4rem;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 2.5rem;
  background: ${(props) => getCssColorFromDrupalColorType(props?.$bgColor)};
  ${devices.tablet} {
    padding-top: 4rem;
    padding-bottom: 4.7rem;
  }
  ${devices.mobile} {
    padding: 6.4rem 2rem;
  }
`
const StyledCardTemplate5Title = styled.div`
  ${(props) => setTextColor(props?.$textColor)};
`

const StyledCardTemplate5Description = styled.div`
  ${(props) => setTextColor(props?.$textColor)};
`
const StyledCardTemplate5ButtonContainer = styled.div`
  padding-top: 1.6rem;
`

const CardTemplate5 = (props) => {
  return (
    <StyledCardTemplate5 $bgColor={props?.field_bg_color_1}>
      <StyledCardTemplate5Title
        dangerouslySetInnerHTML={sanitizeHtml(props?.field_component_title?.processed)}
        $textColor={props?.field_title_color}
      />
      <StyledCardTemplate5Description
        dangerouslySetInnerHTML={sanitizeHtml(props?.field_short_description?.processed)}
        $textColor={props?.field_short_desc_color}
      />
      <StyledCardTemplate5ButtonContainer>
        <ButtonCTA {...props} />
      </StyledCardTemplate5ButtonContainer>
    </StyledCardTemplate5>
  )
}

export default CardTemplate5
