import React, { useEffect, useRef } from 'react'
import Slider from 'react-slick'
import importer from 'common/importer'
import { connect } from 'react-redux'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
const TestimonialCard = importer('Components/PatientTestimonials/TestimonialCard')
const PublicContentActions = importer('Stores/PublicContent/Actions')
const { getCssColorFromDrupalColorType, setTextColor } = importer('Constant/Utils', null)
import { Grid } from 'semantic-ui-react'
import { ReactComponent as NextArrow } from '../../../assets/media/icons/circle-arrow-right.svg'
import { ReactComponent as PrevArrow } from '../../../assets/media/icons/circle-arrow-left.svg'
import './styles.scss'
const { useScreenSize } = importer('Contexts/ResponsiveContextProvider', null)

const SliderNextArrow = ({ className, onClick }) => {
  return <NextArrow width='100' height='100' className={className} onClick={onClick} />
}

const SliderPrevArrow = ({ className, onClick }) => {
  return <PrevArrow width='50' height='50' className={className} onClick={onClick} />
}

const PatientTestimonials = ({
  field_bg_color: bgColor,
  field_component_desc: componentDesc,
  getPatientTestimonials,
  patientTestimonials,
  field_long_desc_color: longDescColor,
  field_ref_slug_value: slug,
  formsData
}) => {
  const { isMobileWidth, isTabletWidth } = useScreenSize()
  const slider = useRef(null)

  useEffect(() => {
    getPatientTestimonials(slug)
  }, [slug, formsData])

  useEffect(() => {
    if (slider.current) {
      slider.current.slickGoTo(0)
    }
  }, [patientTestimonials])

  var settings = {
    dots: true,
    intialSlide: 1,
    infinite: true,
    speed: 800,
    slidesToShow: 3,
    slidesToScroll: 3,
    arrows: isMobileWidth || isTabletWidth ? false : true,
    nextArrow: <SliderNextArrow />,
    prevArrow: <SliderPrevArrow />,
    responsive: [
      {
        breakpoint: 1422,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 1366,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3
        }
      },
      {
        breakpoint: 1080,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  }
  return (
    <>
      <Grid textAlign='center' className='TestimonialsSliderContainer' style={{ background: getCssColorFromDrupalColorType(bgColor) }}>
        <Grid.Row className='TitleRow'>
          <div
            dangerouslySetInnerHTML={{ __html: componentDesc?.processed }}
            style={{ ...setTextColor(longDescColor) }}
          />
        </Grid.Row>
        <Grid.Row className='testimonials-slider-section'>
          <Slider ref={slider} className='customSlider' {...settings}>
            {patientTestimonials?.map((item) => (
              <TestimonialCard
                field_component_image={item.field_component_image}
                field_component_title_1={item.field_component_title_1}
                field_component_title_2={item.field_component_title_2}
                field_short_desc_color={item.field_short_desc_color}
                field_short_description={item.field_short_description}
              />
            ))}
          </Slider>
        </Grid.Row>
      </Grid>
    </>
  )
}

const mapStateToProps = (state) => ({
  patientTestimonials: state.publicContent?.getPatientTestimonialsSuccess?.contents,
  formsData: state.publicContent?.getFormsPageSuccess
})

const mapDispatchToProps = (dispatch) => ({
  getPatientTestimonials: (refType) => dispatch(PublicContentActions.getPatientTestimonials(refType))
})

export default connect(mapStateToProps, mapDispatchToProps)(PatientTestimonials)
