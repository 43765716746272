import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Form, Message } from 'semantic-ui-react'
import { Form as FinalForm, Field } from 'react-final-form'
import { Link, useNavigate, Redirect } from 'react-router-dom'
import './styles.scss'
import { useTranslation } from 'react-i18next'
import importer from '@takedapdt/biolife-core/src/importer'
import { filterNonNomogramCentersPublicPage } from '../../../Helpers/Utils'
import validate from './validate'
import styled from 'styled-components'

const { useScreenSize } = importer('Contexts/ResponsiveContextProvider', null)
const PrimaryButton = importer('Components/Buttons/PrimaryButton')
const InputField = importer('Components/Forms/FormFields/InputField')
const SelectField = importer('Components/Forms/FormFields/SelectField')
const CustomCheckbox = importer('Components/Inputs/CustomCheckbox')
const PrimaryTextArea = importer('Components/Inputs/PrimaryTextArea')
const CentersActions = importer('Stores/Centers/Actions')
const PublicContentActions = importer('Stores/PublicContent/Actions')

const FormNote = styled.p`
  padding-bottom: 1.6rem;
  font-size: 1.6rem !important;
`

const Asterisk = styled.span`
  color: var(--color-dark-orange);
  margin-right: 0.3rem;
`

const ShareWithUsForm = (props) => {
  const { isMobileWidth } = useScreenSize()
  const { t } = useTranslation('ShareWithUsForm')
  const {
    uniqueFormData,
    getAllCenters,
    centers,
    states,
    saveContactForm,
    saveContactFormSuccess,
    saveContactFormError: error,
    saveContactFormLoading: loading,
    getAllStates
  } = props
  const navigate = useNavigate()
  const [saveClicked, setSaveClicked] = useState(false)
  const [centerOptions, setCenterOptions] = useState([])
  const [stateOptions, setStateOptions] = useState([])
  const [selectedStateCode, setSelectedStateCode] = useState(null)
  const [selectedStateText, setSelectedStateText] = useState(null)

  useEffect(() => {
    getAllCenters()
    getAllStates()
  }, [])

  useEffect(() => {
    setCenterOptions([])
    const filteredState = states?.filter((state) => state?.countrycode === 'US')
    filteredState &&
      setStateOptions(
        filteredState.map((item, index) => ({
          key: index,
          value: { description: item?.description, code: item?.code, countrycode: item?.countrycode },
          text: item?.description
        }))
      )
  }, [states])

  useEffect(() => {
    const filteredCenters = centers?.filter(
      (center) => selectedStateCode?.stateCode === center.stateCode && selectedStateCode?.countrycode === 'US'
    )
    if (filteredCenters?.length > 0) {
      const filterRes = filterNonNomogramCentersPublicPage(filteredCenters)
      setCenterOptions(
        filterRes.map((item, index) => ({
          key: index,
          value: item.sfid,
          text: item.name + ', ' + item.stateCode
        }))
      )
    } else {
      setCenterOptions([
        {
          key: 0,
          value: 'Other',
          text: 'Other'
        }
      ])
    }
  }, [selectedStateCode])

  const handleStateChange = (value) => {
    setSelectedStateText(value.description)
    const state = states?.find((state) => state.description === value.description && state.countrycode === value.countrycode)
    setSelectedStateCode({ stateCode: state?.code, countrycode: state?.countrycode })
  }

  useEffect(() => {
    if (saveClicked && saveContactFormSuccess) {
      handleNavigation()
    }
  }, [saveClicked, saveContactFormSuccess])

  const handleNavigation = (code) => {
    setSaveClicked(false)
    navigate('/share-with-us-success')
  }

  const handleSubmit = (values) => {
    const ShareWithUsFormData = {
      firstName: values['First Name'],
      lastName: values['Last Name'],
      email: values['Email'],
      phone: values['Phone'],
      duration: values['How Long Have You Been Donating'],
      subject: 'Feedback Form',
      country: 'United States',
      state: values['State'].description,
      center: values['Center'] === 'Other' ? null : values['Center'],
      description: values['Story'],
      isShareTermsAccepted: values['isShareTermsAccepted']
    }

    const errors = validate(values)
    if (Object.keys(errors).length == 0) {
      setSaveClicked(true)
      saveContactForm(ShareWithUsFormData)
    }
  }
  const optionMapper = (value) => {
    switch (value) {
      case 'State':
        return stateOptions
        break

      case 'Center':
        return centerOptions
        break
    }
  }

  function renderFormFields({ type, props }) {
    const {
      name = '<p></p>',
      field_component_title_1: fieldName = '',
      field_component_title_2,
      field_cta_1_style = '',
      field_cta_1 = {},
      field_status,
      values,
      error
    } = props
    const formComponent = {
      form_field_component: (
        <>
          <Field name={fieldName}>
            {({ input, meta }) => {
              if (props.field_input_type === 'textarea') {
                return (
                  <PrimaryTextArea
                    required={field_status}
                    width={isMobileWidth ? '90%' : '63%'}
                    meta={meta}
                    {...input}
                    fieldLabel={name}
                    value={input.value}
                    placeholder={field_component_title_2 || ''}
                    height={226}
                    isHtmlLabel
                    isTextArea={true}
                  />
                )
              } else if (props.field_input_type === 'select' && name) {
                return (
                  <SelectField
                    required={field_status}
                    width={isMobileWidth ? '90%' : '63%'}
                    meta={meta}
                    input={input}
                    fieldLabel={name}
                    value={input.value}
                    placeholder={field_component_title_2 || ''}
                    isHtmlLabel
                    options={optionMapper(fieldName)}
                    handleOnChange={(value) => {
                      input.onChange(value)
                      if (fieldName === 'State') {
                        handleStateChange(value)
                      }
                    }}
                    defaultValue={undefined}
                    disabled={fieldName === 'Center' && !selectedStateCode}
                  />
                )
              } else if (props.field_input_type === 'checkbox') {
                return (
                  <CustomCheckbox
                    checkboxId={fieldName}
                    style={{ marginTop: '35px' }}
                    meta={meta}
                    input={input}
                    label={name}
                    isRequired={field_status}
                    className='checkBoxSharewithUs'
                  />
                )
              }

              return (
                <InputField
                  required={field_status}
                  width={isMobileWidth ? '90%' : '63%'}
                  meta={meta}
                  {...input}
                  fieldLabel={name}
                  value={input.value}
                  placeholder={field_component_title_2 || ''}
                  isHtmlLabel
                />
              )
            }}
          </Field>
        </>
      ),
      form_cta_component: (
        <>
          <PrimaryButton
            variant={field_cta_1_style}
            text={field_cta_1.title}
            style={{ marginRight: '10px', marginBottom: '30px', marginTop: '40px' }}
            loading={saveClicked && loading}
            fluid={isMobileWidth}
          />
        </>
      )
    }
    return formComponent[type]
  }

  return (
    <div className='ShareWithUsForm'>
      <FinalForm
        onSubmit={handleSubmit}
        validate={validate}
        render={({ handleSubmit, values }) => (
          <Form onSubmit={handleSubmit}>
            <FormNote>
              <Asterisk>*</Asterisk>
              {t('indicatesRequiredField')}
            </FormNote>
            {uniqueFormData.map((formComponent) => {
              return renderFormFields({
                type: formComponent.type,
                props: {
                  name: formComponent?.field_component_title?.processed,
                  fieldLabelType: formComponent?.field_component_title?.format,
                  field_component_title_1: formComponent?.field_component_title_1,
                  field_component_title_2: formComponent?.field_component_title_2,
                  field_cta_1_style: formComponent?.field_cta_1_style,
                  field_cta_1: formComponent?.field_cta_1,
                  handleSubmit: { handleSubmit },
                  field_input_type: formComponent?.field_input_type,
                  field_status: formComponent?.field_status,
                  values,
                  error
                }
              })
            })}

            {saveClicked && error && error.response && error.response.data && (
              <Message visible warning>
                <p>{error.response.data.message}</p>
              </Message>
            )}
          </Form>
        )}
      />
    </div>
  )
}

const mapStateToProps = (state) => ({
  centers: state.centers.donorCenters?.data,
  states: state.publicContent.States,
  saveContactFormSuccess: state.publicContent.saveContactFormSuccess,
  saveContactFormError: state.publicContent.saveContactFormFailure,
  saveContactFormLoading: state.publicContent.saveContactFormLoading
})

const mapDispatchToProps = (dispatch) => ({
  saveContactForm: (formData) => dispatch(PublicContentActions.saveContactForm(formData)),
  getAllCenters: () => dispatch(CentersActions.getDonorCentersAll()),
  getAllStates: () => dispatch(PublicContentActions.getAllStates())
})

export default connect(mapStateToProps, mapDispatchToProps)(ShareWithUsForm)
