import React, { useEffect } from 'react'
import './styles.scss'
import importer from 'common/importer'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'
const { setTextColor } = importer('Constant/Utils', null)

// This component is used for state center pages only

const IntegrationTextContent = ({
  field_component_title: componentTitle,
  field_title_color: componentTitleColor,
  field_component_desc: componentDescription,
  field_long_desc_color: componentDescriptionColor,
  stateCenters
}) => {
  const params = useParams()
  const search = JSON.parse(new URLSearchParams(location.search).get('search'))
  useEffect(() => {
    const descriptionElement = document.getElementById('state-name')
    if (descriptionElement) {
      descriptionElement.textContent = params.locationSlug
        ?.split('-')
        .join(' ')
        .replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase())
    }
  }, [stateCenters, componentTitle])

  return (
    <div className='IntegrationTextContentContainer'>
      <div
        id='integration-component-title'
        style={{ ...setTextColor(componentTitleColor), padding: '25px 0' }}
        dangerouslySetInnerHTML={{ __html: componentTitle?.processed }}
      />
      <div
        id='integration-component-description'
        style={{ ...setTextColor(componentDescriptionColor) }}
        dangerouslySetInnerHTML={{ __html: componentDescription?.processed }}
      />
    </div>
  )
}

const mapStateToProps = (state) => ({
  stateCenters: state.centers?.getStateCentersSuccess?.data
})

const mapDispatchToProps = (dispatch) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(IntegrationTextContent)
