
import React from 'react'
import { NavLink } from 'react-router-dom'
import { Grid, Menu } from 'semantic-ui-react'
import importer from '@takedapdt/biolife-core/src/importer'
const { ScrollToTop } = importer('Helpers/Utils', null)
const CommonView = importer('Components/Footer/FooterViews/CommonView')
const { COMMONVIEW_TYPES } = importer('Constant/commonviewComponentTypes', null)
import { CareersSpanishURL } from '../../../NavigationService'

const DesktopFooter = ({footerMenuData, footerContentData}) => {
    const logoAlignment = footerContentData?.biolifeLogo.alignment;
    return (
        <Grid className='DesktopFooterContainer' verticalAlign='bottom' stackable>
            <Grid.Row columns={3} className={`FooterTopRow ${ logoAlignment === 2 ? 'Reversed' : ''}`}>
                <Grid.Column computer={4} className='BiolifeLogoBox'>
                    <CommonView sectionType={COMMONVIEW_TYPES.BIOLIFE_LOGO_BLOCK} contentData={footerContentData}/>
                </Grid.Column>
                <Grid.Column className='StoreLogoContainer' computer={logoAlignment === 2 ? 6 : 7}>
                    <CommonView sectionType={COMMONVIEW_TYPES.APP_STORE_LOGOS_BLOCK} contentData={footerContentData}/>
                </Grid.Column>
                {/* <Grid.Column computer={logoAlignment === 2 ? 6 : 5}> */}
                    {/* Input box for user subscription to be implemented in future sprints*/}
                    {/* <CommonView sectionType={COMMONVIEW_TYPES.SUBSCRIPTION_BOX_BLOCK} contentData={footerContentData}/> */}
                {/* </Grid.Column> */}
            </Grid.Row>
            <hr style={{width: '100%', marginTop: '20px'}}/>
            <Grid.Row columns={1} className='FooterMiddleRow'>
                <Grid.Column computer={16}>
                    <Menu pointing secondary className='FooterMenu'>
                        <Menu.Menu position='left'>
                            {footerMenuData?.map((item, itemIndex) => (
                                item?.href.includes('https') ? (
                                    <Menu.Item 
                                        className={item?.class} 
                                        key={itemIndex} 
                                        name={item?.title}
                                        content={item?.title} 
                                        as='a' 
                                        href={(item?.title?.toLowerCase() === 'carreras' ? CareersSpanishURL : item?.href)} 
                                        target="_blank"
                                        aria-label={item?.title}
                                    />
                                    ) : (
                                    <Menu.Item 
                                        className={item?.class} 
                                        key={itemIndex} 
                                        name={item?.title}
                                        content={item?.title}
                                        onClick={ScrollToTop} 
                                        as={NavLink} 
                                        to={item?.href} 
                                    />
                                )
                            ))}
                        </Menu.Menu>
                    </Menu>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={2} className='FooterBottomRow'>
                <Grid.Column computer={9} className='TrademarkStatement'>
                    {footerContentData?.biolifeTrademarkStatement?.text ? 
                        <CommonView sectionType={COMMONVIEW_TYPES.TRADEMARK_STATEMENT_BLOCK} contentData={footerContentData}/>
                    : null}
                </Grid.Column>
                <Grid.Column computer={2} className='TrademarkLogo'>
                    {footerContentData?.field_takeda_logo ? 
                        <CommonView sectionType={COMMONVIEW_TYPES.TAKEDA_LOGO_BLOCK} contentData={footerContentData}/>
                    : null}
                </Grid.Column>
                <Grid.Column computer={5} textAlign='right' className='SocialIcons'>
                    <CommonView sectionType={COMMONVIEW_TYPES.SOCIAL_MEDIA_ICONS_BLOCK} contentData={footerContentData}/>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    )
}
  
export default DesktopFooter;