import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { connect } from 'react-redux'
import { Form as FinalForm, Field } from 'react-final-form'
import { isEmpty, isArray, debounce } from 'lodash'
import { devices } from '../../utils/styledComponentHelpers'

import usersActions from '@takedapdt/biolife-core/src/Stores/Users/Actions'
import AuthCustomActions from '@takedapdt/biolife-core/src/Stores/AuthCustom/Actions'
import donorsActions from '@takedapdt/biolife-core/src/Stores/Donors/Actions'
import UserActions from '@takedapdt/biolife-core/src/Stores/Users/Actions'
import centersActions from '@takedapdt/biolife-core/src/Stores/Centers/Actions'

import PrimaryButton from './../../Components/Buttons/PrimaryButton'
import InputField from './../../Components/Forms/FormFields/InputField'
import SelectField from './../../Components/Forms/FormFields/SelectField'
import MaskedField from './../../Components/Forms/FormFields/MaskedField'
import Modal from './../../Components/Modal'
import PasswordValidation from './../../Components/PasswordValidation'
import { useScreenSize } from './../../Contexts/ResponsiveContextProvider'
import { ReactComponent as GrayInfoIcon } from '../../../assets/media/icons/GrayInfoIcon.svg'

import styled from 'styled-components'
import SimplePopup from './Popups/SimplePopup/SimplePopup'

const StyledButtonContainer = styled.div`
  display: flex;
  padding: 1rem 0;
  width: 50%;
  justify-content: space-between;
  align-items: center;

  ${devices.mobile} {
    flex-direction: column;
    width: 100%;
    height: 15rem;
  }
`

const StyledFormContainer = styled.div`
  margin-bottom: 3.8rem;
  max-width: 63.2rem;
`
const StyledFieldContainer = styled.div`
  margin-bottom: 2rem;
  width: 100%;
  ${devices.mobile} {
  }
`

const StyledNonEditableTextContainer = styled.div`
  display: flex;
  align-items: center;
  font-size: 1.5rem;
  padding-left: 0.5 rem;
  margin-bottom: 3rem;
`

const StyledNonEditableText = styled.span`
  margin-left: 1rem;
  font-size: $caption-font-size;
`

const StyledPasswordIndicator = styled.div`
  margin-top: 2.6rem;
`

const StyledErrorAfterValidation = styled.p`
  background-color: var(--color-validation-pink-background);
  color: var(--color-validation-red);
  border: 1px solid;
  padding: 0.8rem;
  margin-bottom: 1.6rem;
  width: 85%;

  ${devices.mobile} {
    width: 100%;
  }
`
const StyledDonorNote = styled.p`
  margin-bottom: 3.8rem;

  ${devices.mobile} {
    margin-top: 3.8rem;
    margin-bottom: 4.8rem;
  }
`

const StyledCancelButtons = styled.p`
  text-decoration: underline;
  color: #2a398c;
  cursor: pointer;
  margin: 0rem;
`

const StyledZipInfo = styled.div`
  display: flex;
  flex-direction: row;
  padding: 1.6rem;
  padding-left: 1rem;
  gap: 0.6rem;
  background: var(--color-info-blue);
  width: 85%;
  margin-bottom: 2rem;
  flex: 1;
  ${devices.mobile} {
    width: 90%;
  }
`

const StyledZipText = styled.span`
  flex: 1;
`

function SettingForm(props) {
  const { isMobileWidth } = useScreenSize()
  const {
    fieldLabel,
    buttonStyle,
    donorInfoLocked,
    showPasswordValidations,
    fieldOptions,
    validations,
    componentType,
    donor,
    updateAccountSetting,
    updateAccountSettingSuccess,
    updateAccountSettingFailure,
    updateAccountSettingLoading,
    changePasswordFailure,
    changePasswordSuccess,
    changePassword,
    updateDonor,
    updateDonorLoading,
    updateDonorSuccess,
    updateDonorFailure,
    signOut,
    getDonorCentersAll,
    getCentersLoading,
    centers,
    resetNearbyCenters,
    changePasswordBlockOnSuccess,
    passwordChangeSuccessState
  } = props || {}
  const phoneTypes = fieldOptions.phoneTypes
  const ethnicityOptions = fieldOptions.ethnicityOptions
  const genders = fieldOptions.genders
  const countries = fieldOptions.countries
  const states = fieldOptions.states

  const [errorMessages, setErrorMessages] = useState([])
  const [next, setNext] = useState(false)

  const [passwordInput, setPasswordInput] = useState('')
  const [passwordLoading, setPasswordLoading] = useState(false)
  const [passwordReveal, setPasswordReveal] = useState(false)
  const [genderDropDown, setGenderDropDown] = useState([{ text: '', value: '' }])
  const [phoneTypeDropdown, setPhoneTypeDropdown] = useState([{ text: '', value: '' }])
  const [ethnicityDropdown, setEthnicityDropdown] = useState([{ text: '', value: '' }])
  const [statesOptions, setStatesOptions] = useState([])
  const [selectedCountry, setSelectedCountry] = useState('')
  const [selectedState, setSelectedState] = useState('')
  const [selectedEthnicity, setSelectedEthnicity] = useState('')
  const [countriesOptions, setCountriesOptions] = useState([])
  const [simplePopUp, setSimplePopUp] = useState(false)
  const [popUpData, setPopUpData] = useState(null)
  const [isSubmit, setIsSubmit] = useState({
    isInfoSubmitted: false,
    isPasswordChangeSubmitted: false,
    isEmailChangeSubmitted: false
  })

  const [showEmailFailState, setShowEmailFailState] = useState(false)
  const [statesManager, setStatesManager] = useState({})
  const [passwordConfirmationState, setPasswordConfirmationState] = useState({
    loginId: donor?.loginId,
    firstName: donor?.firstName,
    lastName: donor?.lastName
  })
  const [zipCodeForDisplayMessage, setZipCodeForDisplayMessage] = useState(null)
  const [oldZipCode, setOldZipCode] = useState('')
  const isSmsMfaEnrolled = donor?.isSmsEnrolled === 'Yes'

  const { t, i18n } = useTranslation('ProfileSettings')
  const loading =
    componentType === 'changeEmail' ? updateAccountSettingLoading : componentType === 'myInfo' ? updateDonorLoading : passwordLoading

  const savePopUp_Data = {
    type: 'save',
    showTickIcon: true,
    showBiolifeImage: false,
    showsecondaryTitle: false,
    showTitle: true,
    discardContent: false,
    title: t('yourChangesHaveBeenSaved'),
    buttonContent: t('backToSettings'),
    internalPadding: '8.5rem 16.4rem'
  }

  const discardPopUp_Data = {
    type: 'profileDiscard',
    showTickIcon: false,
    showBiolifeImage: false,
    showsecondaryTitle: false,
    showTitle: true,
    discardContent: true,
    title: t('areYouSureToDiscard'),
    buttonContent: t('yesDiscard'),
    internalPadding: '8.5rem 16.4rem'
  }

  useEffect(() => {
    // It is for showing the password is successfully change after the success using the changePasswordBlockOnSuccess
    if (
      !changePasswordFailure &&
      !passwordChangeSuccessState &&
      changePasswordSuccess &&
      changePasswordSuccess &&
      !simplePopUp &&
      isSubmit?.isPasswordChangeSubmitted &&
      changePasswordBlockOnSuccess
    ) {
      setIsSubmit((prev) => {
        return {
          ...prev,
          isPasswordChangeSubmitted: false
        }
      })
      changePasswordBlockOnSuccess(true)
    }
  }, [simplePopUp])

  useEffect(() => {
    let tempSelectedCountry
    if (donor?.country && !isEmpty(countries)) {
      let countryItem = countries.find((c) => c.description === donor?.country)
      tempSelectedCountry = countryItem.code.trim()
      setSelectedCountry(tempSelectedCountry)
    }

    if (!isEmpty(countries) && !isEmpty(states)) {
      const availableContries = countries.map((c) => ({
        key: c.code,
        value: c.code,
        text: c.description
      }))
      setCountriesOptions(availableContries)
      getStatesByCountries(tempSelectedCountry)
    }
  }, [countries])

  useEffect(() => {
    if (donor?.state && !isEmpty(states)) {
      let stateItem = states.find((s) => s.description === donor?.state)
      setSelectedState(stateItem.code.trim())
    }
  }, [states])

  useEffect(() => {
    setGenderDropDown(transformGenderCollection())
  }, [genders])

  useEffect(() => {
    setPhoneTypeDropdown(transformPhoneTypeCollection())
  }, [phoneTypes])

  useEffect(() => {
    setEthnicityDropdown(transformEthnicityOptionsCollection())

    if (donor?.ethnicity && !isEmpty(ethnicityOptions)) {
      let ethnicityItem = ethnicityOptions.find((e) => e.en_label === donor?.ethnicity)
      i18n.language === 'es-MX' ? setSelectedEthnicity(ethnicityItem.es_label) : setSelectedEthnicity(ethnicityItem.en_label)
    }
  }, [ethnicityOptions])

  useEffect(() => {
    if (isSubmit?.isPasswordChangeSubmitted && isEmpty(errorMessages) && changePasswordSuccess) {
      setSimplePopUp(true)
      setPasswordLoading(false)
    }
  }, [changePasswordSuccess])

  useEffect(() => {
    if (isSubmit?.isInfoSubmitted && updateDonorSuccess?.status == 200) {
      setSimplePopUp(true)
      setIsSubmit((prev) => {
        return {
          ...prev,
          isInfoSubmitted: false
        }
      })
    }
  }, [updateDonorSuccess])

  useEffect(() => {
    if (isSubmit?.isEmailChangeSubmitted && updateAccountSettingSuccess?.status == 200) {
      setSimplePopUp(true)
      setIsSubmit((prev) => {
        return {
          ...prev,
          isEmailChangeSubmitted: false
        }
      })
      signOut()
    }
  }, [updateAccountSettingSuccess])

  useEffect(() => {
    if (changePasswordFailure !== null && isSubmit?.isPasswordChangeSubmitted) {
      if (isArray(changePasswordFailure.errorCauses)) {
        if (changePasswordFailure?.errorCauses?.map((el) => el?.errorSummary?.includes('too recently'))) {
          const result = changePasswordFailure?.errorCauses?.map((el) => {
            if (el?.errorSummary?.includes('too recently')) {
              return { errorSummary: t('usedPasswordEntered') }
            } else {
              return el
            }
          })
          setErrorMessages(result)
        } else {
          setErrorMessages(changePasswordFailure.errorCauses)
        }
      } else {
        if (changePasswordFailure?.errorSummary?.includes('too recently')) {
          setErrorMessages([{ errorSummary: t('usedPasswordEntered') }])
        } else {
          setErrorMessages([
            changePasswordFailure?.errorSummary
              ? { errorSummary: changePasswordFailure?.errorSummary }
              : { errorSummary: changePasswordFailure?.data?.message }
          ])
        }
      }
      setIsSubmit((prev) => {
        return {
          ...prev,
          isPasswordChangeSubmitted: false
        }
      })
      setPasswordLoading(false)
    }
  }, [changePasswordFailure])

  useEffect(() => {
    if (!isEmpty(donor)) {
      const complexStateInitial = {
        firstName: donor?.firstName,
        middleName: donor?.middleName,
        lastName: donor?.lastName,
        email: donor?.email,
        zipCode: donor?.zipCode,
        birthDate: donor.birthDate !== null && donor.birthDate !== undefined && moment(donor.birthDate).format('MM/DD/YYYY'),
        language: donor?.language,
        phoneNumber: donor?.phoneNumber,
        phoneType: donor?.phoneType,
        addressLine1: donor?.addressLine1,
        addressLine2: donor?.addressLine2,
        city: donor.city !== null && donor.city !== undefined && donor.city.trim(),
        country: donor?.country,
        state: donor?.state,
        donationCenter: donor?.donationCenter,
        gender: donor?.gender,
        ethnicityCode: donor?.ethnicity,
        pdn: donor?.pdn
      }
      setStatesManager(complexStateInitial)
      setOldZipCode(donor?.zipCode)
    }
  }, [donor])

  const handlePasswordChange = ({ target: { value } }) => {
    setPasswordInput(value)
  }

  const transformGenderCollection = () =>
    genders &&
    genders.map((gender) => ({
      key: gender.key,
      text: gender.label,
      value: gender.value
    }))

  const transformPhoneTypeCollection = () =>
    phoneTypes &&
    phoneTypes.map((phoneTypes) => ({
      key: phoneTypes.key,
      text: phoneTypes.label,
      value: phoneTypes.value
    }))

  const transformEthnicityOptionsCollection = () =>
    ethnicityOptions &&
    ethnicityOptions.map((ethnicityOption) => ({
      key: ethnicityOption.key,
      text: i18n.language === 'es-MX' ? ethnicityOption.es_label : ethnicityOption.en_label,
      value: ethnicityOption.value
    }))

  const getStatesByCountries = (country) => {
    if (country) {
      const countryItem = countries.find((c) => c.code === country)
      if (countryItem) {
        if (!isEmpty(states)) {
          const filteredStates = states
            .filter((state) => state.countrycode === countryItem.code)
            .map((s) => ({
              key: s.code,
              value: s.code,
              text: s.description
            }))
          setStatesOptions(filteredStates)
        }
      }
    }
  }

  const togglePasswordReveal = () => setPasswordReveal(!passwordReveal)

  function onSubmit(values) {
    const numericPhoneNumber = values.phoneNumber !== null && values.phoneNumber !== undefined && values.phoneNumber?.replace(/[^0-9]/g, '')

    setPopUpData(() => savePopUp_Data)
    if (componentType == 'changeEmail') {
      setIsSubmit((prev) => {
        return {
          ...prev,
          isEmailChangeSubmitted: true
        }
      })
      setShowEmailFailState(true)
      updateAccountSetting(values)
    } else if (componentType == 'changePassword') {
      if (errorMessages && errorMessages.length) setErrorMessages([])
      const { currentPassword, passwordConfirm } = values
      setIsSubmit((prev) => {
        return {
          ...prev,
          isPasswordChangeSubmitted: true
        }
      })
      setPasswordLoading(true)
      changePassword(currentPassword, passwordConfirm)
    } else {
      let countryItem, stateItem, ethnicityItem
      setIsSubmit((prev) => {
        return {
          ...prev,
          isInfoSubmitted: true
        }
      })
      if (!isEmpty(countries) && !isEmpty(states) && !isEmpty(ethnicityOptions)) {
        countryItem = countries.find((c) => c.code === selectedCountry)
        stateItem = states.find((s) => s.code === selectedState && s.countrycode === selectedCountry)
        ethnicityItem = ethnicityOptions.find((e) => e?.value === selectedEthnicity)
      }
      if (
        zipCodeForDisplayMessage &&
        (isEmpty(centers) || (!isEmpty(centers) && !centers.some((center) => center.sfid === donor.donationCenter)))
      ) {
        setZipCodeForDisplayMessage(null)
        values.donationCenter = null
        resetNearbyCenters({ data: { nearbyCenters: null } })
      }
      updateDonor(donor.id, {
        donorDetails: {
          ...values,
          phoneNumber: numericPhoneNumber,
          birthDate: moment(values.birthDate).format('YYYY-MM-DD'),
          country: countryItem.description,
          state: stateItem.description,
          ethnicityCode: ethnicityItem?.value
        }
      })
    }
  }

  const handleCancelButton = ({ componentType }) => {
    setPopUpData(() => discardPopUp_Data)
    setShowEmailFailState(false)
    switch (componentType) {
      case 'myInfo':
        setIsSubmit((prev) => {
          return {
            ...prev,
            isInfoSubmitted: false
          }
        })
        break
      case 'changeEmail':
        setIsSubmit((prev) => {
          return {
            ...prev,
            isEmailChangeSubmitted: false
          }
        })
        break
      case 'changePassword':
        setIsSubmit((prev) => {
          return {
            ...prev,
            isPasswordChangeSubmitted: false
          }
        })
        break
    }
    setSimplePopUp(true)
  }

  const debouncedZipcodeChange = debounce((value) => {
    if (oldZipCode !== value && value.length === 5) {
      getDonorCentersAll(value)
      setZipCodeForDisplayMessage(value)
    } else {
      setZipCodeForDisplayMessage(null)
    }
  }, 500)
  return (
    <StyledFormContainer>
      <FinalForm
        onSubmit={onSubmit}
        validate={validations}
        initialValues={
          componentType == 'changeEmail'
            ? { currentEmail: donor?.loginId }
            : componentType == 'changePassword'
            ? passwordConfirmationState
            : componentType == 'myInfo' && statesManager
        }
        render={({ handleSubmit, form, invalid, pristine }) => (
          <form onSubmit={handleSubmit}>
            {/* <div className='eachField'> */}
            {fieldLabel &&
              fieldLabel.length &&
              fieldLabel.map((names, index) => {
                if (names.type === 'PasswordField') {
                  return (
                    <StyledFieldContainer>
                      <Field name={names.validation}>
                        {({ input, meta }) => (
                          <InputField
                            required={names.mandatory}
                            meta={meta}
                            {...input}
                            width={isMobileWidth ? '90%' : '85%'}
                            height={56}
                            id={names.label}
                            value={input.value}
                            {...(names.validation === 'password' && { customOnChange: handlePasswordChange })}
                            type={passwordReveal ? 'text' : 'password'}
                            fieldLabel={t(names.validation)}
                            autoComplete='one-time-code'
                          />
                        )}
                      </Field>
                    </StyledFieldContainer>
                  )
                } else if (names.type === 'MaskedField') {
                  let mask = names.validation === 'birthDate' ? '99 / 99 / 9999' : '(999) 999-9999'
                  let placeholder = names.validation === 'birthDate' ? '    /      /' : '    -     -'
                  return (
                    <StyledFieldContainer>
                      <Field key={index} name={names.validation}>
                        {({ input, meta }) => (
                          <MaskedField
                            required={names.mandatory}
                            meta={meta}
                            {...input}
                            width={isMobileWidth ? '90%' : '85%'}
                            height={56}
                            fieldLabel={t(names.validation)}
                            mask={mask}
                            placeholder={placeholder}
                            maskChar={null}
                            autoComplete='one-time-code'
                            usedFor={'ProfileSettings'}
                            disabled={
                              names?.validation === 'phoneNumber' ? (!donor?.pdn ? isSmsMfaEnrolled : names.readOnly) : names.readOnly
                            }
                          />
                        )}
                      </Field>
                    </StyledFieldContainer>
                  )
                } else if (names.type === 'TextField') {
                  return (
                    <StyledFieldContainer>
                      <Field key={index} name={names.validation}>
                        {({ input, meta }) => (
                          <InputField
                            required={names.mandatory}
                            meta={meta}
                            width={isMobileWidth ? '90%' : '85%'}
                            {...input}
                            fieldLabel={t(names.validation)}
                            value={input.value}
                            id={names.label}
                            className='registrationFormFields'
                            disabled={names?.readOnly}
                            customOnChange={
                              names.validation === 'zipCode'
                                ? (e) => {
                                    debouncedZipcodeChange(e.target.value)
                                  }
                                : null
                            }
                          />
                        )}
                      </Field>
                      {names.validation === 'currentEmail' && (
                        <StyledNonEditableTextContainer>
                          <GrayInfoIcon width='19' height='19' />
                          <StyledNonEditableText>{t('nonEditableField')}</StyledNonEditableText>
                        </StyledNonEditableTextContainer>
                      )}
                    </StyledFieldContainer>
                  )
                } else {
                  var dropDown, width, defaultSelection
                  if (names.validation === 'ethnicityCode') {
                    dropDown = ethnicityDropdown
                    width = '85%'
                    if (donor?.ethnicity && !isEmpty(ethnicityOptions)) {
                      let ethnicityItem = ethnicityOptions.find((e) => e.en_label === donor?.ethnicity)
                      defaultSelection = ethnicityItem.value
                    }
                  } else if (names.validation === 'gender') {
                    dropDown = genderDropDown
                    width = '85%'
                    defaultSelection = donor?.gender
                  } else if (names.validation === 'phoneType') {
                    dropDown = phoneTypeDropdown
                    width = '85%'
                    defaultSelection = donor?.phoneType
                  } else if (names.validation === 'state') {
                    width = '85%'
                    dropDown = statesOptions
                    if (donor?.state && !isEmpty(states)) {
                      let stateItem = states.find((s) => s.description === donor?.state)
                      defaultSelection = stateItem.code.trim()
                    }
                  } else if (names.validation === 'country') {
                    width = '85%'
                    dropDown = countriesOptions
                    if (donor?.country && !isEmpty(countries)) {
                      let countryItem = countries.find((c) => c.description === donor?.country)
                      defaultSelection = countryItem.code.trim()
                    }
                  }

                  return (
                    <StyledFieldContainer>
                      <Field key={index} name={names.validation}>
                        {({ input, meta }) => (
                          <SelectField
                            meta={meta}
                            input={input}
                            width={isMobileWidth ? '90%' : width}
                            required={names.mandatory}
                            options={dropDown}
                            fieldLabel={t(names.validation)}
                            usedFor={'ProfileSettings'}
                            defaultValue={defaultSelection}
                            disabled={names.readOnly}
                            handleOnChange={(value, form) => {
                              if (names.validation == 'country') {
                                setSelectedCountry(value)
                                getStatesByCountries(value)
                                input.onChange(value)
                              } else if (names.validation == 'state') {
                                setSelectedState(value)
                                input.onChange(value)
                              } else if (names.validation == 'ethnicityCode') {
                                setSelectedEthnicity(value)
                                input.onChange(value)
                              } else {
                                input.onChange(value)
                              }
                            }}
                          />
                        )}
                      </Field>
                    </StyledFieldContainer>
                  )
                }
              })}

            {!getCentersLoading &&
              zipCodeForDisplayMessage &&
              !isEmpty(centers) &&
              !centers.some((center) => center.sfid === donor.donationCenter) && (
                <StyledZipInfo>
                  <GrayInfoIcon width='20' height='20' />
                  <StyledZipText className='caption-text'>
                    {t('updateZip')} {zipCodeForDisplayMessage} {t('willTakeYouOut')} {t('reschedule')} {t('andOr')}{' '}
                    {t('reApplyAnyActivePromo')}.{t('pleaseSelectANewCenter')}
                  </StyledZipText>
                </StyledZipInfo>
              )}
            {!getCentersLoading && zipCodeForDisplayMessage && isEmpty(centers) && (
              <StyledZipInfo>
                <GrayInfoIcon width='20' height='20' />
                <StyledZipText className='caption-text'>
                  {t('updateZip')} {zipCodeForDisplayMessage} {t('weAreGrowing')}
                </StyledZipText>
              </StyledZipInfo>
            )}

            {donorInfoLocked && <StyledDonorNote>{donorInfoLocked}</StyledDonorNote>}

            {showPasswordValidations && (
              <StyledPasswordIndicator>
                <PasswordValidation passwordInput={passwordInput} />
              </StyledPasswordIndicator>
            )}

            {!isEmpty(errorMessages) &&
              errorMessages.map((message, index) => (
                <StyledErrorAfterValidation key={index}>
                  {' '}
                  {i18n.exists(`apiErrorMessages:${message?.errorSummary}`)
                    ? t(`apiErrorMessages:${message?.errorSummary}`)
                    : message?.errorSummary}{' '}
                </StyledErrorAfterValidation>
              ))}

            {updateAccountSettingFailure && showEmailFailState && (
              <StyledErrorAfterValidation>{updateAccountSettingFailure}</StyledErrorAfterValidation>
            )}

            {isSubmit?.isInfoSubmitted && updateDonorFailure && Array.isArray(updateDonorFailure.data.message) && (
              <StyledErrorAfterValidation warning visible>
                {updateDonorFailure.data.message.map((message) => (
                  <p>{message}</p>
                ))}
              </StyledErrorAfterValidation>
            )}
            <StyledButtonContainer>
              <PrimaryButton
                type='submit'
                variant={buttonStyle?.ctaStyle || 'solid_blue'}
                text={t('saveChanges')}
                fluid={isMobileWidth}
                loading={loading}
                disabled={componentType == 'changePassword' && (invalid || pristine)}
              />

              <StyledCancelButtons onClick={() => handleCancelButton({ componentType })}> {t('cancel')} </StyledCancelButtons>
            </StyledButtonContainer>

            <Modal
              maxWidth={836}
              maxWidthMobile={'87.7%'}
              maxHeight={461}
              maxHeightMobile={280}
              modalContent={popUpData}
              isOpen={simplePopUp}
              onClose={() => {
                setSimplePopUp(false)
              }}
            >
              <SimplePopup modalContent={popUpData} form={form} togglePOPUp={setSimplePopUp} />
            </Modal>
          </form>
        )}
      />
    </StyledFormContainer>
  )
}

const mapStateToProps = (state) => ({
  user: state.users.user,
  donor: state.donors.donors?.data,
  updateAccountSettingLoading: state.users?.updateAccountSettingLoading,
  updateAccountSettingSuccess: state.users?.updateAccountSettingSuccess,
  updateAccountSettingFailure: state.users?.updateAccountSettingFailure?.response?.data.message,
  changePasswordSuccess: state.authCustom.changePasswordSuccess,
  changePasswordFailure: state.authCustom.changePasswordFailure,
  updateDonorLoading: state.donors?.updateDonorLoading,
  updateDonorSuccess: state.donors?.updateDonorSuccess,
  updateDonorFailure: state.donors?.updateDonorFailure?.response,
  getCentersLoading: state.centers.getDonorCentersAllLoading,
  centers: state.centers.donorCenters?.data
})

const mapDispatchToProps = (dispatch) => ({
  updateAccountSetting: (currentEmail, newEmail, confirmEmail) =>
    dispatch(usersActions.updateAccountSetting(currentEmail, newEmail, confirmEmail)),
  changePassword: (oldPassword, newPassword) => dispatch(AuthCustomActions.changePassword(oldPassword, newPassword)),
  updateDonor: (id, donor) => dispatch(donorsActions.updateDonor(id, donor)),
  signOut: () => dispatch(UserActions.logout()),
  getDonorCentersAll: (searchStr) => dispatch(centersActions.getDonorCentersAll(searchStr)),
  resetNearbyCenters: (centers) => dispatch(centersActions.getDonorCenterInfoSuccess(centers))
})

export default connect(mapStateToProps, mapDispatchToProps)(SettingForm)
