import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { StyledCustomPageWrapper } from '../Components/CustomPage/styles.js'
import { Helmet } from 'react-helmet'
import importer from '@takedapdt/biolife-core/src/importer'

const AccountCreate = importer('Components/AccountCreate')
const ContactUs = importer('Components/ContactUs')
const CheckEmailComponent = importer('Components/CheckEmailComponent')
const { getArrayFromTextCTAGrid } = importer('Helpers/Utils', null)
const PreviewActions = importer('Stores/Preview/Actions')
const renderDrupalComponent = importer('Constant/renderDrupalComponent')
const { getClassFromSlug } = importer('Helpers/Utils', null)
const LoaderSpinner = importer('Components/Loaders/LoaderSpinner')
const ArticleImage = importer('Components/ArticleImage')
const ArticleDetailsHeading = importer('Components/ArticleDetailsHeading')
const { calculateReadTime } = importer('Helpers/Utils', null)

const StyledInvalidPreview = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const Preview = ({ getDrupalPreviewContent, getDrupalPreviewContentLoading, getDrupalPreviewContentSuccess }) => {
  const location = useLocation()
  const searchParams = new URLSearchParams(location?.search)
  const previewId = searchParams?.get('uuid')
  const lang = searchParams?.get('langcode')
  const previewerId = searchParams?.get('uid')
  const slug = searchParams?.get('slug')
  const contentTypeMachineName = searchParams?.get('bundle')

  const [dataWithGrid, setDataWithGrid] = useState([])

  const isBlogNews = slug?.startsWith('blog/') || slug?.startsWith('news/')

  useEffect(() => {
    getDrupalPreviewContent(contentTypeMachineName, { lang, slug, previewId, previewerId })
  }, [])

  useEffect(() => {
    const dataWithTextCTAGrid = getArrayFromTextCTAGrid(getDrupalPreviewContentSuccess)
    if (Object.keys(dataWithTextCTAGrid)?.length) {
      setDataWithGrid(dataWithTextCTAGrid)
    }
  }, [getDrupalPreviewContentSuccess])

  if (!(previewId && lang && previewerId && slug && contentTypeMachineName)) {
    return <StyledInvalidPreview>Invalid Preview</StyledInvalidPreview>
  }

  let previewContent = (
    <div className={getClassFromSlug(slug)}>
      {getDrupalPreviewContentLoading ? (
        <LoaderSpinner />
      ) : (
        dataWithGrid?.contents?.map((component, index) => {
          return renderDrupalComponent(component, index)
        })
      )}
    </div>
  )

  if (slug === 'create-account') {
    return <AccountCreate signupPageData={dataWithGrid} />
  }
  if (slug === 'about-biolife/contact-us') {
    return <ContactUs ContactUsContent={dataWithGrid} />
  }
  if (slug === 'check-email') {
    return <CheckEmailComponent slug_value={slug} classname={slug} />
  }
  if (slug === 'account-setup-success') {
    return <CheckEmailComponent slug_value={slug} classname={slug} />
  }
  if (slug === 'social-login-success') {
    return <CheckEmailComponent slug_value={slug} classname={slug} />
  }

  return (
    <StyledCustomPageWrapper>
      <Helmet>
        <title>{dataWithGrid?.metadata?.meta_title}</title>
        <link rel='canonical' href={`${window.location.origin}${window.location.pathname.toLowerCase()}`} />
        <meta name='description' content={`${dataWithGrid?.metadata?.meta_description}`} />
      </Helmet>
      {isBlogNews && dataWithGrid && (
        <>
          <ArticleDetailsHeading
            testMeta={{
              ...dataWithGrid?.metadata,
              field_read_time: dataWithGrid?.metadata?.field_read_time || calculateReadTime(dataWithGrid)
            }}
          />
          <ArticleImage
            testMetaImage={dataWithGrid?.metadata?.field_content_image}
            testMetaVideo={dataWithGrid?.metadata?.field_content_video}
          />
        </>
      )}
      {previewContent}
    </StyledCustomPageWrapper>
  )
}

const mapStateToProps = (state) => ({
  getDrupalPreviewContentLoading: state?.preview?.getDrupalPreviewContentLoading,
  getDrupalPreviewContentSuccess: state?.preview?.getDrupalPreviewContentSuccess,
  getDrupalPreviewContentFailure: state?.preview?.getDrupalPreviewContentFailure
})

const mapDispatchToProps = (dispatch) => ({
  getDrupalPreviewContent: (...params) => dispatch(PreviewActions.getDrupalPreviewContent(...params))
})

export default connect(mapStateToProps, mapDispatchToProps)(Preview)
