import React from 'react'
import styled, { css } from 'styled-components'
import { connect } from 'react-redux'
import importer from '@takedapdt/biolife-core/src/importer'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

const { useScreenSize } = importer('Contexts/ResponsiveContextProvider', null)
const { getCssColorFromDrupalColorType, setTextColor } = importer('Constant/Utils', null)
const { devices, sizes } = importer('utils/styledComponentHelpers', null)
const { sanitizeHtml } = importer('utils/htmlHelpers', null)
const PrimaryButton = importer('Components/Buttons/PrimaryButton')
const donorsActions = importer('Stores/Donors/Actions')

const StyledMyAppointments = styled.div`
  background: ${(props) => getCssColorFromDrupalColorType(props?.$bgColor)};
  ${devices.mobile} {
    padding-top: 24px;
  }
`
const StyledMyAppointmentsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 990px) {
    flex-direction: column-reverse;
    gap: 32px;
    align-items: flex-start;
  }
`
const StyledMyAppointmentsTitle = styled.div`
  ${(props) => setTextColor(props?.$textColor)};
  ${devices.mobile} {
    & > :first-child {
      font-size: var(--h4-font-size) !important;
    }
  }
`
const StyledMyAppointmentsDescription = styled.div`
  padding-top: 32px;
`
const StyledMyAppointmentsDateColumn = styled.div`
  ${devices.mobile} {
    width: 100%;
  }
`
const StyledMyAppointmentsDateContainer = styled.div`
  padding: 16px 31px;
  display: flex;
  gap: 48px;
  background-color: var(--color-white);
  border-radius: 10px;
  ${devices.mobile} {
    padding: 7px 16px;
    gap: 97px;
  }
`
const StyledMyAppointmentsDateContainerText = styled.div`
  color: var(--color-bl-blue);
  font-size: var(--caption-mobile-font-size);
  ${devices.mobile} {
    font-size: 1rem;
  }
`
const StyledMyAppointmentsDateContainerDate = styled.div`
  color: var(--color-orange);
  font-size: var(--h4-font-size);
  ${devices.mobile} {
    font-size: var(--p-font-size);
  }
`
const StyledMyAppointmentsDescriptionHeading = styled.h4`
  color: var(--color-orange);
`
const StyledMyAppointmentsDescriptionText = styled.p`
  padding-top: 16px;
`
const StyledMyAppointmentsButton = styled.div`
  margin-top: 32px;
  ${devices.mobile} {
    width: 100%;
  }
`

const MyAppointments = (props) => {
  const {
    field_component_title: componentTitle,
    field_cta_1: componentCTA,
    field_cta_1_style: componentCTAStyle,
    field_title_color: componentTitleColor,
    field_bg_color: backgroundColor,
    inquiry,
    donor,
    appointments
  } = props

  const { isMobileWidth } = useScreenSize()
  const { t, i18n } = useTranslation('MyAppointments')

  return (
    <StyledMyAppointments $bgColor={backgroundColor}>
      <div className='main-container'>
        <StyledMyAppointmentsContainer>
          <StyledMyAppointmentsTitle $textColor={componentTitleColor} dangerouslySetInnerHTML={sanitizeHtml(componentTitle?.processed)} />
          {donor?.pdn && (
            <StyledMyAppointmentsDateColumn>
              <StyledMyAppointmentsDateContainer>
                <div>
                  <StyledMyAppointmentsDateContainerText>
                    <strong>{t('lastPhysical')}</strong>
                  </StyledMyAppointmentsDateContainerText>
                  <StyledMyAppointmentsDateContainerDate>
                    <strong>{inquiry && inquiry.lastPhysicalDate ? moment(inquiry.lastPhysicalDate).format('MM/DD/YY') : '-'}</strong>
                  </StyledMyAppointmentsDateContainerDate>
                </div>
                <div>
                  <StyledMyAppointmentsDateContainerText>
                    <strong>{t('nextPhysicalDue')}</strong>
                  </StyledMyAppointmentsDateContainerText>
                  <StyledMyAppointmentsDateContainerDate>
                    <strong>{inquiry && inquiry.nextPhysicalDate ? moment(inquiry.nextPhysicalDate).format('MM/DD/YY') : '-'}</strong>
                  </StyledMyAppointmentsDateContainerDate>
                </div>
              </StyledMyAppointmentsDateContainer>
            </StyledMyAppointmentsDateColumn>
          )}
        </StyledMyAppointmentsContainer>
        {!donor?.pdn && !(appointments && appointments.length) && (
          <StyledMyAppointmentsDescription>
            <StyledMyAppointmentsDescriptionHeading>
              <strong>{t('letsGetStarted')}</strong>
            </StyledMyAppointmentsDescriptionHeading>
            <StyledMyAppointmentsDescriptionText>{t('scheduleFirstAppointment')}</StyledMyAppointmentsDescriptionText>
          </StyledMyAppointmentsDescription>
        )}
        <StyledMyAppointmentsButton>
          <PrimaryButton text={componentCTA?.title} sendTo={componentCTA?.uri} variant={componentCTAStyle} fluid={isMobileWidth} />
        </StyledMyAppointmentsButton>
      </div>
    </StyledMyAppointments>
  )
}
const mapStateToProps = (state) => ({
  appointments: state.appointments?.getUpcomingAppointmentsSuccess,
  inquiry: state.donors.inquiryDonorSuccess?.data,
  donor: state.donors.donors?.data
})

const mapDispatchToProps = (dispatch) => ({
  inquiryDonor: (pdn) => dispatch(donorsActions.inquiryDonor(pdn))
})

export default connect(mapStateToProps, mapDispatchToProps)(MyAppointments)
