//Following are the depencies used...
import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'

import { useTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet'

//Following are the components imported...
import { useLocation } from 'react-router-dom'
import ProfileSettings from './../Components/ProfileSettings'
import Breadcrumbs from './../Components/Breadcrumbs'
import { devices } from './../utils/styledComponentHelpers'
import PreferenceSettings from '../Components/PreferenceSettings'
import { getPathDetails } from '../Helpers/Utils'
import { SLUG_TYPES } from '@takedapdt/biolife-core/src/Sagas/Constants/drupalConstants'
import PublicContentActions from '@takedapdt/biolife-core/src/Stores/PublicContent/Actions'
import LoaderSpinner from '../Components/Loaders/LoaderSpinner'

// all Styled components...
const TabsContainer = styled.div`
  ${devices.mobile} {
    margin-bottom: 0;
    padding-bottom: 0;
  }
  display: flex;
  padding-top: 2.4rem;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-bottom: 2rem;
`
const TabButton = styled.button`
  cursor: pointer;
  border: none;
  background: none;
  padding: 1rem 1.5rem;
  font-size: 1.8rem;
  font-family: ${(props) => (props.active ? 'AvenirNextBold' : 'AvenirNextRegular')}!important;
  font-weight: ${(props) => (props.active ? 'bold' : '100')} !important;
  color: ${(props) => (props.active ? '#f96506' : '#333333')};
  border-bottom: ${(props) => (props.active ? '4px solid' : '4px solid var(--color-light-shaded-grey)')};
  border-image: ${(props) =>
    props.active ? `linear-gradient(90deg, var(--color-dark-shaded-orange) 0%,var(--color-light-shaded-orange) 100%) 30;` : ''};
  width: 50%;
`

const SettingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const MainDiv = styled.div`
  ${devices.tablet} {
    width: 99%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .Breadcrumbs {
      padding-left: 0 !important;
    }
  }

  ${devices.mobile} {
    width: 99%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`
const StyledBreadcrumbs = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
`

const Settings = ({ getPostLoginPage, PostLoginPageData, PostLoginPageDataLoading, PostLoginPageMetaData, PostLoginPageSuccess }) => {
  const [activeTab, setActiveTab] = useState('profile')
  const { t, i18n } = useTranslation('ProfileSettings')
  let location = useLocation()
  let tab = location.state?.preferences

  useEffect(() => {
    if (tab) {
      handleTabChange('preferences')
      const fav = document.getElementById('fav')
      fav?.scrollIntoView({ behavior: 'smooth' })
    }
  }, [PostLoginPageSuccess])
  useEffect(() => {
    getPostLoginPage(getPathDetails.slugType || SLUG_TYPES?.SETTINGS)
    window.scrollTo(0, 0)
  }, [])

  const handleTabChange = (tab) => {
    setActiveTab(tab)
  }

  return (
    <>
      <Helmet>
        <title>{PostLoginPageMetaData?.meta_title}</title>
        <meta name='description' content={PostLoginPageMetaData?.meta_description} />
        <link rel='canonical' href={`${window.location.origin}${window.location.pathname.toLowerCase()}`} />
      </Helmet>
      {PostLoginPageDataLoading ? (
        <LoaderSpinner />
      ) : (
        <SettingContainer>
          <MainDiv className='main-container'>
            <StyledBreadcrumbs>
              <Breadcrumbs levelOnePropsTitle={t('dashboard')} levelTwoPropsTitle={false} />
            </StyledBreadcrumbs>
            <TabsContainer>
              <TabButton onClick={() => handleTabChange('profile')} active={activeTab === 'profile'}>
                {t('profile')}
              </TabButton>
              <TabButton onClick={() => handleTabChange('preferences')} active={activeTab === 'preferences'}>
                {t('preference')}
              </TabButton>
            </TabsContainer>

            {/* Tab Content */}
            {activeTab == 'profile' ? <ProfileSettings /> : <PreferenceSettings postLoginPageData={PostLoginPageData} />}
          </MainDiv>
        </SettingContainer>
      )}
    </>
  )
}

const mapStateToProps = (state) => ({
  PostLoginPageData: state.publicContent?.getPostLoginPageSuccess?.contents,
  PostLoginPageDataLoading: state.publicContent?.getPostLoginPageLoading,
  PostLoginPageSuccess: state.publicContent?.getPostLoginPageSuccess,
  PostLoginPageMetaData: state.publicContent?.getPostLoginPageSuccess?.metadata
})

const mapDispatchToProps = (dispatch) => ({
  getPostLoginPage: (slug) => dispatch(PublicContentActions.getPostLoginPage(slug))
})

export default connect(mapStateToProps, mapDispatchToProps)(Settings)
