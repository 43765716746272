import React, { useRef } from 'react'
import { Icon, Button } from 'semantic-ui-react'
import Select, { components } from 'react-select'
import { useTranslation } from 'react-i18next'
import './style.scss'
import caretUp from '../../../assets/media/icons/UIOpen.png'
import caretDown from '../../../assets/media/icons/UIexpandarrow.png'
import importer from '@takedapdt/biolife-core/src/importer'
const { getCssColorFromDrupalColorType } = importer('Constant/Utils', null)
//custom styling for the filter dropdown.
const customStyles = {
  option: (defaultStyles, state) => ({
    ...defaultStyles,
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'transparent',
    ':active': {
      ...defaultStyles[':active'],
      backgroundColor: 'white'
    }
  }),
  dropdownIndicator: (defaultStyles, state) => ({
    ...defaultStyles,
    color: 'gray'
  }),
  placeholder: (defaultStyles) => {
    return {
      ...defaultStyles,
      color: '#ABABAB',
      paddingLeft: 7
    }
  },
  control: (defaultStyles) => {
    return {
      ...defaultStyles,
      height: '5.5rem',
      opacity: 1,
      fontSize: '1.8rem',
      borderRadius: '0px',
      borderColor: getCssColorFromDrupalColorType('dark_gray')
    }
  },
  indicatorSeparator: (defaultStyles) => {
    return {
      ...defaultStyles,
      display: 'none'
    }
  },
  multiValueRemove: (defaultStyles) => {
    return {
      ...defaultStyles,
      display: 'none'
    }
  },
  multiValueLabel: (defaultStyles) => ({
    ...defaultStyles,
    backgroundColor: '#2d2d2d',
    color: 'white',
    opacity: 1
  }),
  valueContainer: (defaultStyles) => ({
    ...defaultStyles,
    overflow: 'hidden',
    flexWrap: 'nowrap',
    maxWidth: '80%'
  }),
  menuList: (defaultStyles) => ({
    ...defaultStyles,
    '::-webkit-scrollbar': {
      width: '4px',
      height: '0px'
    },
    '::-webkit-scrollbar-track': {
      background: '#f1f1f1'
    },
    '::-webkit-scrollbar-thumb': {
      background: '#ababab'
    },
    '::-webkit-scrollbar-thumb:hover': {
      background: '#555'
    },
    paddingTop: '1rem',
    paddingBottom: '1rem'
  })
}

const CustomValueContainer = ({ children, ...props }) => {
  let [values, input] = children
  if (Array.isArray(values)) {
    const { length } = values
    switch (length) {
      case 1:
        values = [values[0]]
        break
      case 2:
        values = [values[0], values[1]]
        break
      default:
        values = [values[0], values[1], values[2]]
        break
    }
  }
  return (
    <components.ValueContainer {...props}>
      {/* {values} */}
      {input}
    </components.ValueContainer>
  )
}

const CustomMenu = (props) => {
  const { t } = useTranslation('BlogsContainer')

  const handleClearFilterOnTouch = (event) => {
    event.stopPropagation()
    event.preventDefault()
    props.selectProps.menuProps?.handleClearFilter()
  }

  const handleClearFilterOnClick = () => {
    props.selectProps.menuProps?.handleClearFilter()
  }

  return (
    <components.Menu {...props}>
      {props.children}
      {/* <Button className='ClearFilterBtn' onClick={handleClearFilterOnClick} onTouchEnd={handleClearFilterOnTouch} icon labelPosition='left'>
        <Icon name='close' />
        {t('clearFilterLabel')}
      </Button> */}
    </components.Menu>
  )
}

const CustomOption = (props) => {
  const handleClick = (event) => {
    event.preventDefault() // Prevent the default behavior of the checkbox's onChange event
    props.selectOption(props) // Manually select the option
  }
  return (
    <components.Option {...props}>
      <label onClick={handleClick} className='OptionContainer'>
        {props.label}
        <input type='checkbox' checked={props.isSelected} onChange={() => null} />
        <span className='checkmark'></span>
      </label>
    </components.Option>
  )
}

const DropdownIndicator = (props) => {
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        <img src={props.selectProps.menuIsOpen ? caretUp : caretDown} />
      </components.DropdownIndicator>
    )
  )
}

const BlogFilter = (props) => {
  const { t } = useTranslation('BlogsContainer')

  const allOption = {
    value: '<SELECT_ALL>',
    label: `${t('filterPlaceholder')}`
  }

  // isOptionSelected sees previous props.value after onChange
  const { handleClearFilter } = props
  const valueRef = useRef(props.value)
  valueRef.current = props.value

  const isSelectAllSelected = () => valueRef.current.length === props.options.length

  const isOptionSelected = (option) => valueRef.current.some(({ value }) => value === option.value) || isSelectAllSelected()

  const getOptions = () => [allOption, ...props.options]
  const getValue = () => (isSelectAllSelected() ? [allOption] : props.value)
  const topicValue = getOptions()
  const sortedOptions = topicValue?.sort(function(a, b) {
    if (a.label < b.label) {
      return -1
    }
    return 0
  })
  const onChange = (newValue, actionMeta) => {
    const { action, option, removedValue } = actionMeta
    if (action === 'select-option' && option.value === allOption.value) {
      props.onChange(props.options, actionMeta)
    } else if (
      (action === 'deselect-option' && option.value === allOption.value) ||
      (action === 'remove-value' && removedValue.value === allOption.value)
    ) {
      props.onChange([], actionMeta)
    } else if (actionMeta.action === 'deselect-option' && isSelectAllSelected()) {
      props.onChange(
        props.options.filter(({ value }) => value !== option.value),
        actionMeta
      )
    } else {
      props.onChange(newValue || [], actionMeta)
    }
  }

  return (
    <Select
      styles={customStyles}
      isSearchable={false}
      isClearable={false}
      // controlShouldRenderValue={false}
      isOptionSelected={isOptionSelected}
      options={sortedOptions}
      value={getValue()}
      onChange={onChange}
      hideSelectedOptions={false}
      closeMenuOnSelect={false}
      menuProps={{ handleClearFilter }}
      isMulti
      placeholder={t('filterPlaceholder')}
      components={{
        ValueContainer: CustomValueContainer,
        Option: CustomOption,
        Menu: CustomMenu,
        DropdownIndicator
      }}
    />
  )
}

export default BlogFilter
