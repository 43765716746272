import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import importer from '@takedapdt/biolife-core/src/importer'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
const renderDrupalComponent = importer('Constant/renderDrupalComponent')
const { SLUG_TYPES } = importer('Sagas/Constants/drupalConstants', null)
const PublicContentActions = importer('Stores/PublicContent/Actions')
const { getPathDetails } = importer('Helpers/Utils', null)
const LoaderSpinner = importer('Components/Loaders/LoaderSpinner')

const DonorForgotPassword = ({ fetchFormsData, FormsData, FormsDataLoading }) => {
  useEffect(() => {
    fetchFormsData(getPathDetails.slugType || SLUG_TYPES?.FORGOTPASSWORD)
  }, [])
  return (
    <>
      <Helmet>
        <title>{`${FormsData?.metadata?.meta_title}`} </title>
        <meta name='description' content={`${FormsData?.metadata?.meta_description}`} />
        <link rel='canonical' href={`${window.location.origin}${window.location.pathname.toLowerCase()}`} />
      </Helmet>

      {FormsDataLoading ? (
        <LoaderSpinner />
      ) : (
        FormsData?.contents.map((component, index) => {
          if (component.type === 'breadcrumb') {
            let tempComp = {
              ...component,
              breadcrumbToggle: FormsData?.metadata?.field_content_breadcrumb,
              breadcrumbMobileToggle: FormsData?.metadata?.field_mobile_breadcrumb
            }
            component = tempComp
          }
          return renderDrupalComponent(component, index)
        })
      )}
    </>
  )
}

const mapStateToProps = (state) => ({
  FormsData: state.publicContent?.getFormsPageSuccess?.[SLUG_TYPES?.FORGOTPASSWORD],
  FormsDataLoading: state.publicContent?.getFormsPageLoading?.[SLUG_TYPES?.FORGOTPASSWORD]
})

const mapDispatchToProps = (dispatch) => ({
  fetchFormsData: (formType) => dispatch(PublicContentActions.getFormsPage(formType))
})

export default connect(mapStateToProps, mapDispatchToProps)(DonorForgotPassword)
