import React from 'react'
import { Container, Header, Image } from 'semantic-ui-react'
import HeroImage from '../../../assets/media/images/hero-image-headquarters.jpg'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import './styles.scss'
import importer from '@takedapdt/biolife-core/src/importer'
const PrimaryButton = importer('Components/Buttons/PrimaryButton')

const PasswordLinkExpired = () => {
  const { t } = useTranslation('PasswordLinkExpired');
  const navigate = useNavigate();
  return (
    <div className='PasswordLinkExpired'>
      <div className='HeroContent'>
        <Container>
          <div className='PasswordLinkExpiredTextContainer'>
            <div className='SuccessContainer'>
            <Header as='h4' className='PasswordLinkExpiredHeader'>
               {t('passwordResetLinkExpired')}
            </Header>
            <p className='PasswordLinkExpiredRegularText'>
                {t('pleaseclickForgotPassword')}
            </p>
            </div>
            <PrimaryButton 
              text={t('ForgotPassword')}
              onClick={() => {
                navigate('/donor-forgot-password')
              }}
            />
          </div>
        </Container>
      </div>
    </div>
  )
}

export default PasswordLinkExpired
