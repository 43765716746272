import React from 'react'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import {
  Image,
  TitleTextComponent,
  ImageDiv,
  StyledDiv,
  Container,
  /* CardContainer, */ TextComponent,
  Highlight4,
  ContainerWithFlex,
  PlayIcon
} from './styles/BlogCard.styled'
import BlogTimer from '../../../assets/media/icons/BlogTimer.svg'
import importer from '@takedapdt/biolife-core/src/importer'
const { getCssColorFromDrupalColorType } = importer('Constant/Utils', null)
const { sanitizeHtml } = importer('utils/htmlHelpers', null)
const PrimaryButton = importer('Components/Buttons/PrimaryButton')
const { useScreenSize } = importer('Contexts/ResponsiveContextProvider', null)

import { ReactComponent as ButtonVideoPlayGrey } from '../../../assets/media/icons/button-video-play-grey.svg'

function BlogCard({ blog, isNews = false, relatedArticle = false }) {
  const { isMobileWidth, isTabletWidth } = useScreenSize()
  const { t } = useTranslation(!isNews ? 'BlogsContainer' : 'NewsContainer')
  const {
    field_read_time: readTime = '',
    created: latestChangedate = '',
    field_ref_topic = '',
    title = '',
    field_slug_value: goToLink = '',
    field_content_image = {},
    field_content_video = undefined,
    body = { processed: '' },
    field_category: newzCategory = ''
  } = blog || {}
  const shortDescription = body && body.processed && body.processed.replace(/(<([^>]+)>)/gi, '')
  const fieldTopic = t(field_ref_topic)
  const newzCategoryName = t(newzCategory)
  const topicName =
    fieldTopic &&
    fieldTopic.length &&
    fieldTopic
      .split('_')
      .join(' ')
      .replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase())
  const newsCategory =
    newzCategoryName &&
    newzCategoryName.length &&
    newzCategoryName
      .split('_')
      .join(' ')
      .replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase())
  const navigate = useNavigate()
  const handleClick = () => {
    navigate(`/${goToLink}`)
  }
  return (
    <Container marginTop={relatedArticle ? '3.2rem' : isMobileWidth ? '4.8rem' : '3.2rem'} width={isMobileWidth ? '100%' : 'auto'}>
      <ContainerWithFlex
        bgColor='white'
        direction='column'
        width={isTabletWidth ? '32rem' : isMobileWidth ? (relatedArticle ? '32rem' : '100%') : '32rem'}
        borderRadius='2.5rem'
        boxShadow={`0px 0.3rem 0.6rem ${getCssColorFromDrupalColorType('cardShadow')}`}
        overflow='hidden'
        style={({ height: '47rem' }, isMobileWidth && !relatedArticle ? { maxWidth: '32rem', margin: 'auto' } : { maxWidth: 'unset' })}
        onClick={handleClick}
        cursor='pointer'
      >
        <ImageDiv>
          <Image src={field_content_image.url} alt={field_content_image.alt} />
          {field_content_video && <PlayIcon className='play-icon' />}
        </ImageDiv>
        <ContainerWithFlex direction='column' padding='2.4rem' flexGrow='1'>
          <TitleTextComponent className='caption-text' $color='orange' style={{ overflow: 'visible' }}>
            {newsCategory ? newsCategory : topicName}
          </TitleTextComponent>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              flexGrow: 1,
              height: '17rem'
            }}
          >
            <TextComponent color='bl_blue' margin='0rem 0rem 0.8rem 0rem' wordBreak='break-word' lineHeight='2.7rem'>
              {title && title?.length > 70 ? `${title.substring(0, 70)}...` : title}
              {/* H2 Headline lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do */}
            </TextComponent>
            <TextComponent
              // className='caption-text'
              height='5rem'
              color='rich_black'
              fontFamily='AvenirNextRegular'
              margin='0rem 0rem 2.4rem 0rem'
              lineHeight='2.7rem'
            >
              {/* Article summary meta description lorem ipsum dolor elit sed dolor… */}
              <StyledDiv
                fontSize='1.6rem'
                fontFamily='AvenirNextRegular'
                lineHeight='2.7rem'
                dangerouslySetInnerHTML={{
                  __html: shortDescription && shortDescription?.length > 60 ? `${shortDescription.substring(0, 60)}...` : shortDescription
                }}
              />
            </TextComponent>
            <Container>
              <PrimaryButton variant='black_underline' text={t('readArticle')} style={{ fontSize: '1.6rem' }} />
            </Container>
          </div>

          <ContainerWithFlex display={'flex'} marginTop='2.4rem'>
            <TextComponent
              fontFamily='AvenirNextRegular'
              paddingRight='1.6rem'
              borderRight={readTime ? `1px solid ${getCssColorFromDrupalColorType('rich_black')}` : `0px`}
              lineHeight='2.7rem'
            >
              <span className='caption-text'>
                {moment(latestChangedate).format('MMM D')}, {moment(latestChangedate).format('YYYY')}
              </span>
            </TextComponent>
            {/* {all_data || readTime && (
              <ContainerWithFlex align='center' padding='0rem 0rem 0rem 1.6rem'>
                <img src={BlogTimer} style={{ height: '19px', width: '16px', marginRight: '0.8rem' }} />
                <TextComponent className='caption-text' fontFamily='AvenirNextRegular'>
                  <span className='caption-text'>
                    {all_data || readTime} {t('min')}
                  </span>
                </TextComponent>
              </ContainerWithFlex>
            )} */}
            {readTime && (
              <ContainerWithFlex align='center' padding='0rem 0rem 0rem 1.6rem'>
                <img src={BlogTimer} alt='Blog Timer' style={{ height: '19px', width: '16px', marginRight: '0.8rem' }} />
                <TextComponent className='caption-text' fontFamily='AvenirNextRegular' lineHeight='2.7rem'>
                  <span className='caption-text'>
                    {readTime} {t('min')}
                  </span>
                </TextComponent>
              </ContainerWithFlex>
            )}
          </ContainerWithFlex>
        </ContainerWithFlex>
      </ContainerWithFlex>
    </Container>
  )
}

export default BlogCard
