import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import TagManager from 'react-gtm-module'
import { useSelector, useDispatch } from 'react-redux'
import importer from '@takedapdt/biolife-core/src/importer'
import { Form, Message } from 'semantic-ui-react'
import { Form as FinalForm, Field } from 'react-final-form'
const userActions = importer('Stores/Users/Actions')
const donorsActions = importer('Stores/Donors/Actions')
const centersActions = importer('Stores/Centers/Actions')
const { validate } = importer('Components/RegistrationFormComponent/validate', null)
const PrimaryButton = importer('Components/Buttons/PrimaryButton')
const InputField = importer('Components/Forms/FormFields/InputField')
const { useScreenSize } = importer('Contexts/ResponsiveContextProvider', null)

function SignupForm(props) {
  const { isMobileWidth } = useScreenSize()
  const { t } = useTranslation('SignUpForm')
  // Common Form being Used inside the different Viewa(i.e. Desktop, Tablet, Mobile)
  const { fieldLabel, buttonStyle } = props || {}
  const initialData = {
    // Fields are acc to the data from Drupal
    first_name: '',
    last_name: '',
    zip_code: '',
    email_address: ''
  }
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [submitted, setSubmitted] = useState(false)
  const [errorMessages, setErrorMessages] = useState([])
  const [next, setNext] = useState(false)
  // Redux Variables And Functions Start
  const registerDonorSuccess = useSelector((state) => state.donors.registerDonorSuccess)
  const registerDonorFailure = useSelector((state) => state.donors.registerDonorFailure)
  const registerDonorLoading = useSelector((state) => state.donors.registerDonorLoading)
  const centers = useSelector((state) => state.centers.donorCenters?.data)
  const saveSignUp = (user) => dispatch(userActions.saveSignUp(user))
  const registerDonor = (donor) => dispatch(donorsActions.registerDonor(donor))
  const getDonorCentersAll = (searchStr, isDIS8Center, searchType) =>
    dispatch(centersActions.getDonorCentersAll(searchStr, isDIS8Center, searchType))
  const handleRegisterDonor = async (donor) => {
    await registerDonor(donor)
    saveSignUp(donor)
    setSubmitted(true)
  }
  // Redux Variables And Functions End
  function handleSubmit(values) {
    setNext(true)
    const { isEmailOptIn = true } = values
    const donor = {
      donorDetails: {
        firstName: values['first_name'],
        lastName: values['last_name'],
        email: values['email_address'].toLowerCase(),
        zipCode: values['zip_code'],
        isEmailOptIn,
        gclickId: getGclickId()
      }
    }
    getDonorCentersAll(values['zip_code'], '', 'pubDate')
    handleRegisterDonor(donor)
  }
  function getGclickId() {
    var b = document.cookie.match('(^|;)\\s*' + '_gcl_aw' + '\\s*=\\s*([^;]+)')
    return b ? b.pop().split('.')[2] : null
  }

  useEffect(() => {
    // Success Case
    if (registerDonorSuccess != null && submitted) {
      const lead = { ...registerDonorSuccess.data }
      TagManager.dataLayer({
        dataLayer: {
          event: 'profileRegistration',
          profileRegistrationStage: 'profile lead generated',
          donorZip: lead.zipCode,
          donorId: lead.id,
          bdpDonorId: lead.bdpDonorId || null
        }
      })
      window.sessionStorage.setItem('lead', JSON.stringify(lead))
      setSubmitted(false)
      if (centers && centers.length) {
        navigate('/create-account')
      } else {
        //TODO
        navigate('/no-center-zone')
      }
    }
  }, [registerDonorSuccess])
  useEffect(() => {
    // Error Case
    if (registerDonorFailure !== null && submitted) {
      if (Array.isArray(registerDonorFailure.message)) {
        setErrorMessages(registerDonorFailure.message)
      } else {
        setErrorMessages([registerDonorFailure.message])
      }

      setSubmitted(false)
    }
  }, [registerDonorFailure])
  return (
    <div>
      <FinalForm
        onSubmit={handleSubmit}
        validate={validate}
        initialValues={initialData}
        render={({ handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <div className='eachField'>
              {fieldLabel &&
                fieldLabel.length &&
                fieldLabel.map((names) => {
                  return (
                    <Field name={names.validation}>
                      {({ input, meta }) => {
                        return (
                          <InputField
                            required
                            meta={meta}
                            width={isMobileWidth ? '90%' : '85%'}
                            {...input}
                            fieldLabel={names.label}
                            value={input.value}
                            id={names.label}
                            className='registrationFormFields'
                          />
                        )
                      }}
                    </Field>
                  )
                })}
            </div>
            <span className='IndicateRequiredText caption-text'>
              <span>* </span>
              {t('requiredField')}
            </span>
            <div style={{ paddingTop: '10px' }}>
              <PrimaryButton
                type='submit'
                variant={buttonStyle?.ctaStyle || 'solid_blue'}
                text={t(buttonStyle?.cta?.title)}
                loading={registerDonorLoading && next}
                fluid={isMobileWidth}
              />
            </div>
          </Form>
        )}
      />
      {(errorMessages && errorMessages.length && (
        <Message error>
          {errorMessages.map((message, index) => (
            <p key={index}>{message}</p>
          ))}
        </Message>
      )) ||
        null}
    </div>
  )
}

export default SignupForm
