import styled from 'styled-components'
import importer from '@takedapdt/biolife-core/src/importer'
const { getCssColorFromDrupalColorType } = importer('Constant/Utils', null)
const { devices } = importer('utils/styledComponentHelpers', null)

export const Container = styled.div`
  background: ${({ bgColor }) => getCssColorFromDrupalColorType(bgColor)};
`

export const ContainerWithFlex = styled.div`
  display: flex;
  background: ${({ bgColor }) => getCssColorFromDrupalColorType(bgColor)};
  flex-direction: ${({ direction }) => (direction ? direction : 'row')};
`
export const ChecklistInnerContainer = styled(ContainerWithFlex)`
  padding: 4rem;
  border-radius: 25px;
  box-shadow: 0px 4px 8px ${getCssColorFromDrupalColorType('rich_black_border')};
`
export const ChecklistContentFlexContainer = styled(ContainerWithFlex)`
  flex-wrap: wrap;
  max-width: 90%;
  ${devices.tablet} {
    max-width: 80%;
  }
  ${devices.mobile} {
    max-width: 100%;
  }
`
