import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Form as FinalForm, Field } from 'react-final-form'
import { Form } from 'semantic-ui-react'
import { isEmpty } from 'lodash'
import infoIcon from '../../../../assets/media/images/GrayInfo-Icon.png'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import PrimaryButton from '../../Buttons/PrimaryButton'
import CentersCarousel from '../../../Components/CentersCarousel'
import donorsActions from '@takedapdt/biolife-core/src/Stores/Donors/Actions'
import InputField from '../FormFields/InputField'

const InfoIconContainer = styled.div`
  color: #333333;
  display: flex;
  align-items: center;
  padding-left: 0.5rem;
`

const InfoIconImage = styled.img`
  width: 1.6rem;
  height: 1.6rem;
`

const InfoText = styled.p`
  padding-left: 0.8rem;
  font: normal normal normal 1.6rem/2.2rem Avenir Next;
`

const ButtonContainer = styled.div`
  display: flex;
  padding-top: 4.8rem;
  padding-bottom: 0rem;
  margin-bottom: 17rem;
`

const StyledNoCenterText = styled.h5`
  max-width: 42.5rem;
  padding: 3.2rem 0;
  font-family: var(--font-family-medium) !important;
`
const ChooseDonationCenterFormWrapper = styled.div`
  .main-container {
    .ui.form {
      .fields {
        flex-direction: column;
        margin-bottom: 0;
      }
    }
  }
`

const ChooseDonationCenterForm = React.memo(
  ({
    PostLoginPageData,
    centers,
    getDonorCentersAll,
    getDonorCentersAllLoading,
    donor,
    donorDetailsSaved,
    user,
    updateDonor,
    updateDonorSuccess
  }) => {
    const [selectedLocation, setSelectedLocation] = useState(null)
    const [selectedCenter, setSelectedCenter] = useState(null)
    const [statesManager, setStatesManager] = useState({})
    const readOnly = donor?.zipCode
    const [submited, setSubmited] = useState(false)
    const navigate = useNavigate()
    const { t } = useTranslation('ChooseDonationCenter')

    const handleLocationChange = (value) => {
      setSelectedCenter(value?.sfid)
      setSelectedLocation(value?.centerNum)
    }

    const updateCenters = (inputVal) => {
      if (inputVal && inputVal.length === 5) {
        setSelectedLocation(null)
        getDonorCentersAll(inputVal, '', 'pubDate', true, true)
      }
    }

    const handleSubmit = () => {
      if (selectedLocation) {
        setSubmited(true)
        updateDonor(donor?.id, {
          donorDetails: {
            donationCenter: selectedCenter
          }
        })
      }
    }

    useEffect(() => {
      if (selectedLocation && submited && updateDonorSuccess && donor?.defaultCenter) {
        setSubmited(false)
        navigate('/dashboard/donor-dashboard')
      }
    }, [selectedLocation, submited, updateDonorSuccess, donor])

    useEffect(() => {
      updateCenters(donor?.zipCode)
    }, [donor])

    useEffect(() => {
      const storageData = JSON.parse(window.sessionStorage.getItem('lead'))
      if (!isEmpty(storageData)) {
        getDonorCentersAll(storageData.zipCode, '', 'pubDate', true, true)
        const complexStateInitial = {
          donorId: storageData?.id,
          zipCode: storageData?.zipCode
        }
        setStatesManager(complexStateInitial)
      }
    }, [donor, donorDetailsSaved])
    return (
      <ChooseDonationCenterFormWrapper>
        <div className='main-container '>
          <FinalForm
            onSubmit={handleSubmit}
            initialValues={statesManager}
            render={({ handleSubmit, values }) => (
              <Form onSubmit={handleSubmit}>
                {PostLoginPageData?.map((component, index) => {
                  if (component.type === 'form_field_component' || component.type === 'ext_integration_component') {
                    if (component.field_integration_type === 'center_list_carousel') {
                      return (
                        <>
                          {!isEmpty(centers) && !getDonorCentersAllLoading ? (
                            <>
                              <div key={index}>
                                <Field name='donationCenter'>
                                  {({ input, meta }) => (
                                    <CentersCarousel
                                      key={index}
                                      input={input}
                                      componentData={component}
                                      handleLocationChange={handleLocationChange}
                                      selectedCenter={selectedLocation}
                                      centers={centers}
                                    />
                                  )}
                                </Field>
                              </div>
                              <ButtonContainer>
                                {component.field_cta_1 && (
                                  <div className='ButtonContainer'>
                                    <PrimaryButton
                                      text={component?.field_cta_1?.title}
                                      variant={component?.field_cta_1_style}
                                      disabled={!selectedLocation}
                                      type='submit'
                                    />
                                  </div>
                                )}
                              </ButtonContainer>
                            </>
                          ) : (
                            <StyledNoCenterText>{t('noCentersInYourArea')}</StyledNoCenterText>
                          )}
                        </>
                      )
                    } else if (component.field_component_title_1 === 'zipCode') {
                      return (
                        <>
                          <Form.Group key={index}>
                            <Field name={component.field_component_title_1}>
                              {({ input, meta }) => (
                                <InputField
                                  required={component.field_status}
                                  meta={meta}
                                  {...input}
                                  width={320}
                                  height={56}
                                  placeholder={component.field_component_title_2 || ''}
                                  fieldLabel={component.field_component_title?.processed}
                                  isHtmlLabel
                                  id={component?.field_component_title_1}
                                  value={donor?.zipCode}
                                  disabled={readOnly}
                                />
                              )}
                            </Field>
                            <InfoIconContainer>
                              <InfoIconImage src={infoIcon} />
                              <InfoText>{t('noneditablefield')}</InfoText>
                            </InfoIconContainer>
                          </Form.Group>
                        </>
                      )
                    }
                  }
                })}
              </Form>
            )}
          />
        </div>
      </ChooseDonationCenterFormWrapper>
    )
  }
)

const mapStateToProps = (state) => ({
  donor: state.donors?.donors?.data,
  updateDonorSuccess: state.donors?.updateDonorSuccess
})

const mapDispatchToProps = (dispatch) => ({
  updateDonor: (id, donor) => dispatch(donorsActions.updateDonor(id, donor))
})

export default connect(mapStateToProps, mapDispatchToProps)(ChooseDonationCenterForm)
