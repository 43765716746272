import React, { useEffect, useState, useRef } from 'react'
import { useLocation } from 'react-router-dom'
import { connect, useDispatch, useSelector } from 'react-redux'
import { Helmet } from 'react-helmet'
import styled from 'styled-components'
import importer from 'common/importer'
import { devices } from '../utils/styledComponentHelpers'

// import './styles.scss'
const PublicContentActions = importer('Stores/PublicContent/Actions')
const renderDrupalComponent = importer('Constant/renderDrupalComponent')
const PageNotFound = importer('Components/PageNotFound')
const LoaderSpinner = importer('Components/Loaders/LoaderSpinner')
const { getClassFromPathname } = importer('Helpers/Utils', null)
const Breadcrumbs = importer('Components/Breadcrumbs')
const NewsImage = importer('Components/NewsImage')
const ArticleDetailsHeading = importer('Components/ArticleDetailsHeading')

const StyledDiv = styled.div`
  .text-component-description {
    padding-top: 1.6rem !important;
    padding-bottom: 4.8rem !important;
    ol {
      li {
        font-size: var(--p-font-size) !important;
        padding-bottom: 1.6rem;
        margin-left: 2rem;
        &:last-child {
          padding-bottom: 0;
        }
      }
    }
  }
  ${devices.mobile} {
    .gridImage {
      width: 100%;
      object-fit: contain !important;
    }
  }
`

const NewsDetailsContainer = ({ getNewsPage, getNewsPageSuccess, getNewsPageLoading }) => {
  const [searchedNews, setSearchedNews] = useState({})
  const { pathname } = useLocation()
  useEffect(() => {
    getNewsPage() // extract one level slug from url
    window.scrollTo(0, 0)
  }, [pathname])
  useEffect(() => {
    if (getNewsPageSuccess && getNewsPageSuccess.length) {
      const newsData = getNewsPageSuccess.filter((news) => news && news?.field_slug_value === pathname.slice(1))
      setSearchedNews(...newsData)
    }
  }, [getNewsPageSuccess])
  return (
    <>
      <Helmet>
        <title>{(searchedNews && searchedNews?.meta_title) || ''}</title>
        <meta name='description' content={`${(searchedNews && searchedNews?.meta_description) || ''}`} />
        <link rel='canonical' href={`${window.location.origin}${window.location.pathname.toLowerCase()}`} />
      </Helmet>
      {getNewsPageLoading ? (
        <LoaderSpinner />
      ) : searchedNews && Object.keys(searchedNews).length ? (
        /*className is dynamically generated based on the pathname.
                @example
                if pathname is '/donation-process/faqs' then classname will be 'donation-process-faqs'*/
        <StyledDiv className={getClassFromPathname(pathname)}>
          <Breadcrumbs
            breadcrumbToggle={searchedNews?.field_content_breadcrumb}
            breadcrumbMobileToggle={searchedNews?.field_mobile_breadcrumb}
            clickableLevelTwo={true}
            title={searchedNews?.title || ''}
          />
          <ArticleDetailsHeading testMeta={searchedNews} />
          <NewsImage testMetaImage={searchedNews?.field_content_image} testMetaVideo={searchedNews?.field_content_video} />

          {/* for getting breadcrumbToggle from pageMetadata  */}
          {searchedNews?.contents?.map((component, index) => {
            if (component.type === 'breadcrumb') {
              let tempComp = {
                ...component,
                breadcrumbToggle: searchedNews?.field_content_breadcrumb,
                breadcrumbMobileToggle: searchedNews?.field_mobile_breadcrumb,
                levelTwoPropsTitle: pathname.includes('faqs') ? 'FAQs' : null
              }

              component = tempComp
            } else if (component.type === 'ext_integration_component' && component.field_integration_type === 'related_article') {
              let tempComp = {
                ...component,
                category: searchedNews && searchedNews?.field_category,
                blogsData: getNewsPageSuccess.filter((item) => item.title !== searchedNews.title),
                blog: searchedNews
              }
              component = tempComp
            }

            return renderDrupalComponent(component, index)
          })}
        </StyledDiv>
      ) : (
        <PageNotFound />
      )}
    </>
  )
}

const mapStateToProps = (state) => ({
  getNewsPageLoading: state.publicContent?.getNewsPageLoading,
  getNewsPageSuccess: state.publicContent?.getNewsPageSuccess
})

const mapDispatchToProps = (dispatch) => ({
  getNewsPage: (slug) => dispatch(PublicContentActions.getNewsPage(slug))
})

export default connect(mapStateToProps, mapDispatchToProps)(NewsDetailsContainer)
