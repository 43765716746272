import React from 'react'
import importer from 'common/importer'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import './styles.scss'
import { Card, Grid } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
const { useScreenSize } = importer('Contexts/ResponsiveContextProvider', null)
import { ReactComponent as NextArrowBlack } from '../../../assets/media/icons/circle-arrow-right.svg'
import { ReactComponent as PrevArrowBlack } from '../../../assets/media/icons/circle-arrow-left.svg'
import { ReactComponent as Quotes } from '../../../assets/media/icons/quote-mark-48.svg'
const { getCssColorFromDrupalColorType, setTextColor, getMediaFromDrupalMimeType, getDrupalUrlTarget } = importer('Constant/Utils', null)
const PrimaryButton = importer('Components/Buttons/PrimaryButton')

const SliderNextArrowBlack = ({ className, onClick }) => {
  return <NextArrowBlack width='100' height='100' className={className} onClick={onClick} />
}

const SliderPrevArrowBlack = ({ className, onClick }) => {
  return <PrevArrowBlack width='50' height='50' className={className} onClick={onClick} />
}

/**
 * @param {string} drupalUrl
 * @returns Modified link based on internal or external URL, else returns null.
 */
const getDrupalLink = (drupalUrl) => {
  if (drupalUrl && drupalUrl !== 'route:<nolink>') {
    if (drupalUrl?.includes('internal:')) {
      return drupalUrl?.split(':')[1]
    } else {
      return drupalUrl
    }
  }
}

const CenterTestimonialCard = ({ image, title, subtitle, description, descriptionColor, buttonCTA, buttonStyle }) => {
  return (
    <Link
      to={getDrupalLink(buttonCTA?.uri)}
      target={getDrupalUrlTarget(buttonCTA?.uri)}
      style={{ pointerEvents: getDrupalLink(buttonCTA?.uri) === undefined && 'none' }}
    >
      <Card className='testimonial-card-container' raised>
        <Card.Header>
          <Quotes />
        </Card.Header>
        <Card.Content>
          <Card.Description>
            <div
              className='patient-verbiage'
              dangerouslySetInnerHTML={{ __html: description?.processed }}
              style={{ ...setTextColor(descriptionColor) }}
            />
            {buttonCTA && buttonCTA?.uri && (
              <PrimaryButton text={buttonCTA?.title} variant={buttonStyle} style={{ marginBottom: 10, textAlign: 'left' }} />
            )}
            <Grid className='patient-details'>
              <Grid.Row columns={2} verticalAlign='middle'>
                <Grid.Column computer={6}>
                  {getMediaFromDrupalMimeType(image?.mimetype, image?.url, image?.alt, { width: 60, height: 60 })}
                </Grid.Column>
                <Grid.Column verticalAlign='middle' computer={10}>
                  <Grid.Row>
                    <span className='caption-text patient-title'>
                      <strong>{title}</strong>
                    </span>
                  </Grid.Row>
                  <Grid.Row>
                    <span className='caption-text patient-subtitle'>{subtitle}</span>
                  </Grid.Row>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Card.Description>
        </Card.Content>
      </Card>
    </Link>
  )
}

const CenterTestimonials = ({ items }) => {
  const { isMobileWidth, isTabletWidth } = useScreenSize()

  var settings = {
    dots: true,
    intialSlide: 1,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: isMobileWidth || isTabletWidth ? false : true,
    nextArrow: <SliderNextArrowBlack />,
    prevArrow: <SliderPrevArrowBlack />,
    responsive: [
      {
        breakpoint: 1422,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 1366,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 1080,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  }

  return (
    <div className={`center-testimonials-container ${items?.length <= 3 ? 'LessDataWrapper' : ''}`}>
      {items.length > 0 ? (
        <Slider className='center-testimonials-slider' {...settings}>
          {items?.map(
            ({
              field_component_image,
              field_component_title_1,
              field_component_title_2,
              field_short_desc_color,
              field_short_description,
              field_cta_1,
              field_cta_1_style
            }) => (
              <CenterTestimonialCard
                image={field_component_image}
                title={field_component_title_1}
                subtitle={field_component_title_2}
                description={field_short_description}
                descriptionColor={field_short_desc_color}
                buttonCTA={field_cta_1}
                buttonStyle={field_cta_1_style}
              />
            )
          )}
        </Slider>
      ) : null}
    </div>
  )
}

export default CenterTestimonials
