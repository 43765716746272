import React, { useEffect, useState } from 'react'
import { Container, Accordion as AccordionSemantic } from 'semantic-ui-react'
import './styles.scss'
import importer from '@takedapdt/biolife-core/src/importer'
import { ReactComponent as CheveronUpIcon } from '../../../assets/media/icons/icon-chevron-up.svg'
import { ReactComponent as CheveronDownIcon } from '../../../assets/media/icons/icon-chevron-down.svg'
const PrimaryButton = importer('Components/Buttons/PrimaryButton')
const { setTextColor } = importer('Constant/Utils', null)

const Accordion = (props) => {
  const {
    field_component_title: AccordionTitle,
    field_component_desc: AccordionDescription,
    field_cta_1: AccordionButton,
    field_cta_1_style: AccordionButtonStyle,
    field_title_color: AccordionTitleColor,
    field_long_desc_color: AccordionDescriptionColor,
    field_accordian_flag: AccordionDefaultCondition,
    title,
    description
  } = props
  const [activeIndex, setActiveIndex] = useState()

  useEffect(() => {
    setActiveIndex(AccordionDefaultCondition)
  }, [])

  const handleClick = () => {
    setActiveIndex(!activeIndex)
  }

  return (
    <div className='main-container accordion-component'>
      <div className='AccordionContainer'>
        <AccordionSemantic fluid>
          <div key={`accordion_tab_${activeIndex}`}>
            <AccordionSemantic.Title
              active={activeIndex}
              onClick={handleClick}
              style={{ ...setTextColor(AccordionTitleColor) }}
            >
              {title ? (
                <div className='accordionTitle'>
                  <h5>
                    <strong>{title}</strong>
                  </h5>
                </div>
              ) : (
                <span dangerouslySetInnerHTML={{ __html: AccordionTitle?.value }} />
              )}

              <span className='chevron-arrow'>{activeIndex ? <CheveronUpIcon /> : <CheveronDownIcon />}</span>
            </AccordionSemantic.Title>
            <AccordionSemantic.Content active={activeIndex} style={{ ...setTextColor(AccordionDescriptionColor) }}>
              {description ? (
                <div>{description} </div>
              ) : (
                <div
                  className='AccordionContentContainer'
                  dangerouslySetInnerHTML={{
                    __html: AccordionDescription?.value
                  }}
                />
              )}
              {AccordionButton ? (
                <PrimaryButton
                  text={AccordionButton?.title}
                  className='AccordionButton'
                  variant={AccordionButtonStyle}
                  sendTo={AccordionButton?.uri}
                />
              ) : null}
            </AccordionSemantic.Content>
          </div>
        </AccordionSemantic>
      </div>
    </div>
  )
}

export default Accordion
