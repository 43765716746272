const COMMONVIEW_TYPES = {
  COMMON_TEXT_COMPONENT: 'commonTextComponent',
  SIGNUP_IMG_COMP: 'signupImageComponent'
}

export { COMMONVIEW_TYPES }

export const filterNonNomogramCentersPublicPage = (centers) => {
  let filterData = []
  centers?.forEach(function(obj) {
    if (obj?.isCenterHiddenFromDonors === null) {
      filterData.push(obj)
    }
  })
  return filterData
}
