import React from 'react'
import { Header } from 'semantic-ui-react'
import importer from '@takedapdt/biolife-core/src/importer'
import './styles.scss'
const { getCssColorFromDrupalColorType, setTextColor } = importer('Constant/Utils', null)
const { useScreenSize } = importer('Contexts/ResponsiveContextProvider', null)

const OrangeBannerWithTitle = ({
  title,
  subtitle,
  background,
  backgroundImage,
  borderRadius,
  fontColor,
  fontColorSubText,
  primaryButtonComponent,
  field_component_title: bannerFieldComponentTitle,
  field_title_color: bannerFieldTitleColor,
  field_short_description: bannerSubtitle,
  field_short_desc_color: bannerSubtitleColor
}) => {
  const { isMobileWidth } = useScreenSize()
  return (
    <div
      className='orange-banner'
      style={{
        // background: background || `url(${backgroundImage})`,
        background: 'linear-gradient(261deg, #f9a13a 0%, #ff4000 100%)' || background,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover'
      }}
    >
      <div className='main-container'>
        <div className='header-container'>
          <div
            className='MainHeader'
            dangerouslySetInnerHTML={{ __html: bannerFieldComponentTitle?.value }}
            style={{ ...setTextColor(bannerFieldTitleColor) }}
          />
        </div>
        {bannerSubtitle && (
          <div>
            <div
              className='subText'
              dangerouslySetInnerHTML={{ __html: bannerSubtitle?.processed }}
              style={{ ...setTextColor(bannerSubtitleColor) }}
            ></div>
          </div>
        )}
        {subtitle && (
          <p className='subText' style={{ color: fontColorSubText }}>
            {subtitle}
          </p>
        )}
        {primaryButtonComponent && <div className='buttonComponent'>{primaryButtonComponent}</div>}
      </div>
    </div>
  )
}

export default OrangeBannerWithTitle
