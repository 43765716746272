import React from 'react'
import importer from 'common/importer'
import styled from 'styled-components'
import { ReactComponent as Quotes } from '../../../assets/media/icons/quote-mark-48.svg'
const { sanitizeHtml } = importer('utils/htmlHelpers', null)

const { getCssColorFromDrupalColorType, setTextColor } = importer('Constant/Utils', null)
const { useScreenSize } = importer('Contexts/ResponsiveContextProvider', null)
const { devices, sizes } = importer('utils/styledComponentHelpers', null)

const QuoteComponent = (props) => {
  const { field_component_title: title, field_component_color: textColor } = props

  const StyledQuote = styled.div`
    display: flex;
    ${devices.mobile} {
      flex-direction: column;
    }
  `

  const StyledIcon = styled.div`
    svg {
      width: 43px;
      height: 33px;
    }
  `

  const StyledText = styled.div`
    ${(props) => setTextColor(props?.$descColor)};
    margin-left: 29px;
    ${devices.mobile} {
      margin-left: 0px;
    }
  `

  return (
    <StyledQuote className='main-container'>
      <StyledIcon>
        <Quotes />
      </StyledIcon>
      <StyledText $descColor={textColor} dangerouslySetInnerHTML={sanitizeHtml(title?.processed)} className='text-description' />
    </StyledQuote>
  )
}

export default QuoteComponent
