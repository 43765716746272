import React from 'react'
import { Icon, Image, Input } from 'semantic-ui-react'
import importer from '@takedapdt/biolife-core/src/importer'
import ArrowRight from '../../../../assets/media/icons/UI-arrow-right.png'
import { ReactComponent as ErrorIcon } from '../../../../assets/media/icons/UI-circle-error-1.svg'
import './styles.scss'

const PrimaryButton = importer('Components/Buttons/PrimaryButton')

const SearchInputButton = ({
  width,
  height,
  as,
  children,
  disabled,
  error,
  fluid,
  focus,
  inverted,
  onChange,
  type,
  placeholder,
  value,
  onBlur,
  defaultValue,
  asterisk,
  fieldLabel,
  errorMessage
}) => {
  return (
    <div className='SearchInputButton'>
      <div>
        {fieldLabel && (
          <label className={'FieldLabel'}>
            {fieldLabel} <span role='presentation' className='asteriskStyle'>{asterisk}</span>
          </label>
        )}
        <div className='ui action input'>
          <div className='TextButtonContainer'>
            <Input
              as={as}
              disabled={disabled}
              error={error}
              fluid={fluid}
              focus={focus}
              inverted={inverted}
              onChange={onChange}
              type={type}
              placeholder={placeholder}
              style={{ width: width, height: height }}
              value={value}
              defaultValue={defaultValue}
              onBlur={onBlur}
              aria-label={fieldLabel ? fieldLabel : placeholder}
            >
              {children}
            </Input>
            {/* error state */}
            {error && (
              <div className='ui icon message'>               
                <p>
                  <ErrorIcon />
                </p>
                <div className='content'>
                  <p
                    dangerouslySetInnerHTML={{ __html: errorMessage }}
                  />
                </div>
              </div>
            )}
          </div>
          {/* button SearchInitialized class is for analytics tracking , please do not remove */}
          <PrimaryButton text={<Image src={ArrowRight} alt={'right arrow'} />} className={'SearchInitialized'}/>
        </div>
      </div>
    </div>
  )
}

export default SearchInputButton
