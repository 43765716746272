import React from 'react'
import { Grid } from 'semantic-ui-react'
import styled, { css } from 'styled-components'
import importer from 'common/importer'
const PrimaryButton = importer('Components/Buttons/PrimaryButton')
const { getMediaFromDrupalMimeType, getCssColorFromDrupalColorType, setTextColor } = importer('Constant/Utils', null)
const { useScreenSize } = importer('Contexts/ResponsiveContextProvider', null)
const { devices, sizes } = importer('utils/styledComponentHelpers', null)
const { sanitizeHtml } = importer('utils/htmlHelpers', null)

const StyledGridTemplate3 = styled.div`
  background: ${(props) => getCssColorFromDrupalColorType(props.$bgColor)};
  display: flex;
  text-align: left;
`

const StyledGridTemplate3Container = styled.div`
  display: flex;
  flex-direction: column;
`

export const StyledGridTemplate3Title = styled.div`
  ${(props) => setTextColor(props.$textColor)};
  padding-bottom: 2.4rem;
  text-align: ${(props) => (props.$alignment === 'center' ? 'center' : props.$alignment === 'right' ? 'right' : 'left')};
`
const StyledGridTemplate3Description = styled.div`
  ${(props) => setTextColor(props.$textColor)};
  text-align: ${(props) => (props.$alignment === 'center' ? 'center' : props.$alignment === 'right' ? 'right' : 'left')};
  align-self: ${(props) => (props.$alignment === 'center' ? 'center' : props.$alignment === 'right' ? 'flex-end' : 'flex-start')};
  width: ${(props) => (props.$alignment === 'center' ? '100%' : '74%')};

  ${devices.tablet} {
    width: 100%;
  }
  ${devices.mobile} {
    width: 100%;
  }
`
export const StyledGridTemplate3BottomDescription = styled.div`
  ${(props) => setTextColor(props.$textColor)};
  text-align: ${(props) => (props.$alignment === 'center' ? 'center' : props.$alignment === 'right' ? 'right' : 'left')};
  align-self: ${(props) => (props.$alignment === 'center' ? 'center' : props.$alignment === 'right' ? 'flex-end' : 'flex-start')};
  width: ${(props) => (props.$alignment === 'center' ? '100%' : '74%')};

  padding-top: 4.8rem;
  ${devices.tablet} {
    width: 100%;
  }
  ${devices.mobile} {
    width: 100%;
  }
`
const StyledGridTemplate3PointsContainer = styled.div`
  padding-top: 4.8rem;
  display: grid;
  grid: auto / repeat(${(props) => props.$pointsLength}, 1fr);
  gap: 2.4rem;
  ${devices.tablet} {
    grid: ${(props) => (props.$pointsLength < 4 ? `auto / repeat(${props.$pointsLength}, 1fr)` : 'auto / repeat(2, 1fr)')};
    padding-top: 2.4rem;
  }
  ${devices.mobile} {
    grid: auto / repeat(1, 1fr);
    padding-top: 2.4rem;
  }
`
const StyledGridTemplate3Points = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: ${(props) => (props.$alignment === 'left' ? 'flex-start' : props.$alignment === 'right' ? 'flex-end' : 'center')};
  text-align: center;
  height: fit-content;

  ${devices.mobile} {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }
`
export const StyledGridTemplate3PointsIcon = styled.div`
  margin-bottom: 1.2rem;
  display: flex;
  justify-content: center;

  ${devices.mobile} {
    img.ui.image {
      max-width: 7.5rem;
      min-width: 7.5rem;
    }
  }
`
export const StyledGridTemplate3PointsText = styled.div`
  ${devices.mobile} {
    display: flex;
    flex-direction: column;
  }
`

export const StyledGridTemplate3PointsTitle = styled.div`
  ${(props) => setTextColor(props.$textColor)};
  text-align: ${(props) => (props.$alignment === 'left' ? 'left' : props.$alignment === 'right' ? 'right' : 'center')};

  ${devices.mobile} {
    text-align: left;
    padding-left: 1.8rem;
  }
`
export const StyledGridTemplate3PointsDescription = styled.div`
  ${(props) => setTextColor(props.$textColor)};
  margin-top: 16px;
  text-align: ${(props) => (props.$alignment === 'left' ? 'left' : props.$alignment === 'right' ? 'right' : 'center')};

  ${devices.mobile} {
    text-align: left;
    padding-left: 1.8rem;
  }
`
const StyledGridTemplate3ButtonContainer = styled.div`
  padding-top: 1.2rem;
`
const StyledContainer = styled.div`
  background: ${(props) => getCssColorFromDrupalColorType(props.$containerColor)};
`
const GridTemplate3 = (props) => {
  const {
    field_column_1_icon: gridColumn1Icon,
    field_column_1_title: gridColumn1Title,
    field_column_1_desc: gridColumn1Description,
    field_column_2_icon: gridColumn2Icon,
    field_column_2_title: gridColumn2Title,
    field_column_2_desc: gridColumn2Description,
    field_column_3_icon: gridColumn3Icon,
    field_column_3_title: gridColumn3Title,
    field_column_3_desc: gridColumn3Description,
    field_column_4_icon: gridColumn4Icon,
    field_column_4_title: gridColumn4Title,
    field_column_4_desc: gridColumn4Description,
    field_component_desc: componentLongDescription,
    field_column_title_color: gridColumnTitleColor,
    field_column_desc_color: gridColumnDescriptionColor,
    field_component_title: componentTitle,
    field_title_color: componentTitleColor,
    field_short_description: componentShortDescription,
    field_long_desc_color: componentLongDescriptionColor,
    field_short_desc_color: componentShortDescriptionColor,
    field_cta_1: componentButton,
    field_cta_1_style: componentButtonStyle,
    field_bg_color: componentBackgroundColor,
    field_text_alignment: gridAlignment,
    field_bg_color_1: containerColor
  } = props
  const { isMobileWidth, isTabletWidth } = useScreenSize()

  const GridPoints = [
    gridColumn1Title && {
      gridColumnIcon: gridColumn1Icon,
      gridColumnTitle: gridColumn1Title,
      gridColumnDescription: gridColumn1Description
    },
    gridColumn2Title && {
      gridColumnIcon: gridColumn2Icon,
      gridColumnTitle: gridColumn2Title,
      gridColumnDescription: gridColumn2Description
    },
    gridColumn3Title && {
      gridColumnIcon: gridColumn3Icon,
      gridColumnTitle: gridColumn3Title,
      gridColumnDescription: gridColumn3Description
    },
    gridColumn4Title && {
      gridColumnIcon: gridColumn4Icon,
      gridColumnTitle: gridColumn4Title,
      gridColumnDescription: gridColumn4Description
    }
  ]

  const PointsGridContainer = (gridColumnIcon, gridColumnTitle, gridColumnDescription) => {
    return (
      <StyledGridTemplate3Points $alignment={gridAlignment} className='grid-template3__point'>
        {gridColumnIcon ? (
          <StyledGridTemplate3PointsIcon className='grid-template3__point-icon'>
            {getMediaFromDrupalMimeType(gridColumnIcon?.mimetype, gridColumnIcon?.url, gridColumnIcon?.alt)}
          </StyledGridTemplate3PointsIcon>
        ) : null}
        <StyledGridTemplate3PointsText>
          {gridColumnTitle && (
            <StyledGridTemplate3PointsTitle
              $alignment={gridAlignment}
              className='grid-template3__point-title'
              dangerouslySetInnerHTML={sanitizeHtml(gridColumnTitle?.processed)}
              $textColor={gridColumnTitleColor}
            />
          )}
          {gridColumnDescription && (
            <StyledGridTemplate3PointsDescription
              $alignment={gridAlignment}
              className='grid-template3__point-description'
              dangerouslySetInnerHTML={sanitizeHtml(gridColumnDescription?.processed)}
              $textColor={gridColumnDescriptionColor}
            />
          )}
        </StyledGridTemplate3PointsText>
      </StyledGridTemplate3Points>
    )
  }

  return (
    <StyledContainer $containerColor={containerColor}>
      <StyledGridTemplate3 $bgColor={componentBackgroundColor} className='grid-template3'>
        <div className='main-container'>
          <StyledGridTemplate3Container className='grid-template3__container'>
            {componentTitle && (
              <StyledGridTemplate3Title
                $textColor={componentTitleColor}
                dangerouslySetInnerHTML={sanitizeHtml(componentTitle?.processed)}
                className='grid-template3__title'
                $alignment={gridAlignment}
              />
            )}
            {componentShortDescription && (
              <StyledGridTemplate3Description
                $textColor={componentShortDescriptionColor}
                dangerouslySetInnerHTML={sanitizeHtml(componentShortDescription?.processed)}
                className='grid-template3__description'
                $alignment={gridAlignment}
              />
            )}
            {(gridColumn1Title || gridColumn2Title || gridColumn3Title || gridColumn4Title) && (
              <StyledGridTemplate3PointsContainer
                $pointsLength={GridPoints.filter(Boolean).length}
                className='grid-template3__points-container'
              >
                {GridPoints?.map(
                  (item) => item && PointsGridContainer(item?.gridColumnIcon, item?.gridColumnTitle, item?.gridColumnDescription)
                )}
              </StyledGridTemplate3PointsContainer>
            )}
            {componentLongDescription && (
              <StyledGridTemplate3BottomDescription
                $textColor={componentLongDescriptionColor}
                dangerouslySetInnerHTML={sanitizeHtml(componentLongDescription?.processed)}
                className='grid-template3__bottom-description'
                $alignment={gridAlignment}
              />
            )}
            {componentButton && (
              <StyledGridTemplate3ButtonContainer className='grid-template3__button'>
                <PrimaryButton
                  text={componentButton?.title}
                  variant={componentButtonStyle}
                  sendTo={componentButton?.uri}
                  fluid={isMobileWidth}
                />
              </StyledGridTemplate3ButtonContainer>
            )}
          </StyledGridTemplate3Container>
        </div>
      </StyledGridTemplate3>
    </StyledContainer>
  )
}

export default GridTemplate3
