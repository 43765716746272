import importer from '@takedapdt/biolife-core/src/importer'
const isValidEmail = importer('Helpers/isValidEmail')
const validate = (values) => {
  const errors = {}
  if (values['Email Address']) {
    if (!isValidEmail(values['Email Address'])) {
      errors['Email Address'] = 'emailAddressMustBeInValid'
    }
  } else {
    errors['Email Address'] = 'emailAddressIsRequired'
  }

  if (!values['Status']) {
    errors['Status'] = 'pleaseSelectAStatus'
  }

  if (!values['Subject']) {
    errors['Subject'] = 'subjectIsRequired'
  }

  if (!values['Message']) {
    errors['Message'] = 'messageIsRequired'
  }

  if (!values['Home Center']) {
    errors['Home Center'] = 'centerIsRequired'
  }

  if (!values['state']) {
    errors['state'] = 'stateIsRequired'
  }

  if (values['Subject'] == 'Other Questions' && !values['Type Your Own Subject Line']) {
    errors['Type Your Own Subject Line'] = 'typeSubject'
  }

  return errors
}

export default validate