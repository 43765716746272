import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { devices } from '../../utils/styledComponentHelpers'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${devices.mobile} {
    align-items: flex-end;
  }
`

const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;

  ${devices.mobile} {
    flex-direction: column;
  }
`

const DivContainer = styled.div`
  flex: 0.3;
`

const Item = styled.div`
  margin: 0 0 3rem 0;
  font-family: var(--font-family-bold) !important;
  font-size: var(--p-font-size);
`

const StyledLink = styled.a`
  display: block;
  font-family: var(--font-family-bold) !important;
  text-decoration: underline;
  color: #29398c;
  margin: 0 0 2rem 0;
`

const StyledContainer = styled.div`
  width: 100.8rem;
  margin: auto;

  ${devices.tablet} {
    width: 80rem;
    margin-left: 4.8rem;
  }

  ${devices.mobile} {
    width: 30rem;
    margin: 2.4rem;
  }
`

const SitemapNavigationMenu = ({ drupalNavData }) => {
  const [tableData, setTableData] = useState([])

  useEffect(() => {
    if (drupalNavData) {
      const newData = drupalNavData.map((item) => ({
        title: item.title,
        submenus: item.submenu.map((submenu) => {
          if (submenu.title === 'Careers') {
            return {
              title: 'Blog',
              href: '/blog'
            }
          } else {
            return {
              title: submenu.title,
              href: submenu.href
            }
          }
        })
      }))

      const filteredData = newData.filter(
        (item) => item.title !== 'Our Locations' && item.title !== 'Blog' && !item.title.includes('Nuestros centros')
      )
      setTableData(filteredData)
    }
  }, [drupalNavData])

  return (
    <StyledContainer>
      <Container>
        <RowContainer>
          {tableData.map((row, index) => (
            <DivContainer>
              <Item key={index}>{row.title}</Item>
              <Item>
                {row.submenus.map((submenu, subIndex) => (
                  <StyledLink key={subIndex} href={submenu.href}>
                    {submenu.title}
                  </StyledLink>
                ))}
              </Item>
            </DivContainer>
          ))}
        </RowContainer>
      </Container>
    </StyledContainer>
  )
}

const mapStateToProps = (state) => ({
  drupalNavData: state.publicContent.drupalMenuSuccess?.main
})

export default connect(mapStateToProps, null)(SitemapNavigationMenu)
