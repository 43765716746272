import React from 'react'
import { useTranslation } from 'react-i18next'
import { Form } from 'semantic-ui-react'
import './styles.scss'

import importer from '@takedapdt/biolife-core/src/importer'
const PrimaryTextArea = importer('Components/Inputs/PrimaryTextArea')

const InputField = (props) => {
  const {
    meta,
    input,
    required,
    height,
    placeholder,
    className,
    width,
    icon,
    type,
    onChange,
    customOnChange,
    value,
    disabled,
    onBlur,
    name,
    fieldLabel,
    autoComplete,
    isHtmlLabel,
    isTextArea,
    id
  } = props
  const { t, i18n } = useTranslation('ValidationErrorMessages')
  const message = meta.touched && t(meta.error)
  const onBlurHandler = (value) => {
    onBlur(value)
  }

  return (
    <Form.Field required={required} className='FormField'>
      <PrimaryTextArea
        height={height}
        placeholder={placeholder}
        className={className}
        width={width}
        {...input}
        value={value}
        // error={!!(meta.touched && meta.error)}
        required={required}
        icon={icon}
        type={type}
        disabled={disabled}
        onChange={(e) => {
          onChange(e)
          customOnChange && customOnChange(e)
        }}
        onBlur={() => {
          onBlurHandler(value)
        }}
        name={name}
        autoComplete={autoComplete}
        fieldLabel={fieldLabel}
        error={message}
        isTextArea={isTextArea}
        success={(meta && meta.touched && meta.valid) || false}
        isHtmlLabel={isHtmlLabel}
        id={id}
      />
    </Form.Field>
  )
}

export default InputField
