import React from 'react'
// import { BgImageCard, LeftContainer, RightContainer } from './styles/BgImageCard.styled'
import { Container, ChecklistInnerContainer, ChecklistContentFlexContainer } from './styles/Container.styled'
import importer from '@takedapdt/biolife-core/src/importer'
const { getMediaFromDrupalMimeType, getCssColorFromDrupalColorType, setTextColor } = importer('Constant/Utils', null)
const { useScreenSize } = importer('Contexts/ResponsiveContextProvider', null)
const { sanitizeHtml } = importer('utils/htmlHelpers', null)
const { sizes, devices } = importer('utils/styledComponentHelpers', null)
function ChecklistComponent({
  field_bg_color: bgColor = 'white',
  field_bg_color_1: cardBgColor = 'white',
  field_column_1_icon: icon1 = {},
  field_component_desc: desc = {},
  field_component_title: title = {},
  field_long_desc_color: descColor = 'rich_black',
  field_title_color: titleColor = 'rich_black'
}) {
  const { isMobileWidth, isTabletWidth } = useScreenSize()
  return (
    <Container bgColor={bgColor || 'white'}>
      <Container className='main-container checklistBlock'>
        {/* Default value not there currently value is null
            reason maybe if undefined it will work but not with null
        */}
        <ChecklistInnerContainer
          className='checklistBlock__inner'
          bgColor={cardBgColor || 'white'}
          direction={isMobileWidth ? 'column' : 'row'}
        >
          <Container className='checklistBlock__leftContainer'>
            {getMediaFromDrupalMimeType(
              icon1?.mimetype,
              icon1?.url,
              icon1.alt,
              '',
              { height: '62px', width: '62px' },
              { className: 'checklist--icon1style' }
            )}
          </Container>
          <ChecklistContentFlexContainer className='checklistBlock__rightContainer' direction='column'>
            <div
              className='checklistBlock--title_text_container'
              dangerouslySetInnerHTML={sanitizeHtml(title.processed)}
              style={{ ...setTextColor(titleColor) }}
            ></div>
            <div
              className='checklistBlock--desc_text_container'
              dangerouslySetInnerHTML={sanitizeHtml(desc.processed)}
              style={{ ...setTextColor(descColor) }}
            ></div>
          </ChecklistContentFlexContainer>
        </ChecklistInnerContainer>
      </Container>
    </Container>
  )
}

export default ChecklistComponent
