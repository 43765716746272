import React from 'react'
import { useTranslation } from 'react-i18next'
import { Form, Image } from 'semantic-ui-react'
import './styles.scss'

import importer from '@takedapdt/biolife-core/src/importer'

const PrimaryMaskedInput = importer('Components/Inputs/PrimaryMaskedInput')

const MaskedField = (props) => {
  const {
    meta,
    input,
    required,
    height,
    placeholder,
    className,
    width,
    icon,
    type,
    onChange,
    customOnChange,
    topLabel,
    value,
    mask,
    maskPlaceholder,
    maskChar,
    disabled,
    onBlur,
    fieldLabel,
    autoComplete,
    usedFor
  } = props
  const { t } = useTranslation('ValidationErrorMessages')
  const message = meta.touched && meta.error ? meta.error : topLabel
  const hasErrorsClass = meta.touched && meta.error ? 'error' : ''
  return (
    <Form.Field required={required} className='FormField'>
      {fieldLabel ? (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div
            dangerouslySetInnerHTML={{ __html: fieldLabel }}
            className={`TopLabel ${disabled ? "disabled" : ""} ${usedFor == 'ProfileSettings' ? 'fieldLabelClass' : ''}`}
          />
          {(required && !disabled) ? <small className='HtmlLabelAsterisk'>*</small> : null}
        </div>
      ) : null}
      <PrimaryMaskedInput
        height={height}
        placeholder={placeholder}
        className={className}
        width={width}
        {...input}
        value={value}
        error={!!(meta.touched && meta.error)}
        required={required}
        icon={icon}
        type={type}
        onChange={(e) => {
          onChange(e)
          customOnChange && customOnChange(e)
        }}
        meta={meta}
        onBlur={(e) => {
          onBlur(e)
        }}
        mask={mask}
        maskPlaceholder={maskPlaceholder}
        maskChar={maskChar}
        disabled={disabled}
        hasErrorsClass={hasErrorsClass}
        autoComplete={autoComplete}
      />
      {message && (
        <small className={meta.touched && meta.error ? 'TopLabelError' : 'TopLabel'}>
          <div className='ErrorMsgContainer'>
            <span className='ErrorMsg'>{t(message)}</span>
          </div>
        </small>
      )}
    </Form.Field>
  )
}

export default MaskedField
