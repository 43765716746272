import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { bool } from 'prop-types'
import { List } from 'semantic-ui-react'
import './styles.scss'
import { ReactSVG } from 'react-svg'
import RedCloseIcon from '../../../assets/media/icons/UI-circle-error-alt.svg'
import GreenSuccessIcon from '../../../assets/media/icons/UI-circle-success.svg'

const PasswordValidation = ({ isValid, passwordInput, formType = 'ResetPassword' }) => {
  const { t, i18n } = useTranslation('PasswordValidation')
  const [isPasswordOk, setIsPasswordOk] = useState(['empty', 'lCase', 'uCase', 'num', 'length', 'symbol'])

  useEffect(() => {
    let isOk = []
    if (!passwordInput) {
      isOk.push('empty')
    }
    if (!passwordInput.match(/[a-z]/)) {
      isOk.push('lCase')
    }
    if (!passwordInput.match(/[A-Z]/)) {
      isOk.push('uCase')
    }
    if (!passwordInput.match(/[0-9]/)) {
      isOk.push('num')
    }
    if (passwordInput.length < 8) {
      isOk.push('length')
    }
    if (!passwordInput.match(/["!#$%&'()*+,-.\/\\:;<=>?@\[\]^_`{|}~]/)) {
      isOk.push('symbol')
    }
    setIsPasswordOk(isOk)
  }, [passwordInput])

  return (
    <div className={`password-validation ${formType}`}>
      <div className='password-validation__list'>
        <div className='ListContentContainer'>
          <p className='ListContent'>
            <ReactSVG src={isPasswordOk.includes('lCase') || isPasswordOk.includes('empty') ? RedCloseIcon : GreenSuccessIcon} />
            <span className='errortext'>{t('OneLowercaseCharacter')}</span>
          </p>
        </div>
        <div className='ListContentContainer'>
          <p className='ListContent'>
            <ReactSVG src={isPasswordOk.includes('uCase') || isPasswordOk.includes('empty') ? RedCloseIcon : GreenSuccessIcon} />
            <span className='errortext'>{t('OneUppercaseCharacter')}</span>
          </p>
        </div>
        <div className='ListContentContainer'>
          <p className='ListContent'>
            <ReactSVG src={isPasswordOk.includes('num') || isPasswordOk.includes('empty') ? RedCloseIcon : GreenSuccessIcon} />
            <span className='errortext'>{t('OneNumber')}</span>
          </p>
        </div>
        <div className='ListContentContainer'>
          <p className='ListContent'>
            <ReactSVG src={isPasswordOk.includes('length') || isPasswordOk.includes('empty') ? RedCloseIcon : GreenSuccessIcon} />
            <span className='errortext'>{t('8CharactersMinimum')}</span>
          </p>
        </div>
        <div className='ListContentContainer'>
          <p className='ListContent'>
            <ReactSVG src={isPasswordOk.includes('symbol') || isPasswordOk.includes('empty') ? RedCloseIcon : GreenSuccessIcon} />
            <span className='errortext'>{t('symbolRequired')}</span>
          </p>
        </div>
      </div>
    </div>
  )
}
PasswordValidation.defaultProps = {
  isValid: false
}

PasswordValidation.propType = {
  isValid: bool
}

export default PasswordValidation
