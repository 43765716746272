import React, { useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import renderDrupalComponent from '../../Constant/renderDrupalComponent'
import downArrowIcon from '../../../assets/media/icons/icon-chevron-down.svg'
import upArrowIcon from '../../../assets/media/icons/icon-chevron-up.svg'
import importer from '@takedapdt/biolife-core/src/importer'
const { setTextColor } = importer('Constant/Utils', null)
const { devices, sizes } = importer('utils/styledComponentHelpers', null)
const { sanitizeHtml } = importer('utils/htmlHelpers', null)
const { getArrayFromTextCTAGrid } = importer('Helpers/Utils', null)

const StyledAccordionWithMutliComponent = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto;
  padding: 0 var(--padding-horizontal-desktop);
  max-width: ${sizes.tablet};
  ${devices.tablet} {
    padding: 0 var(--padding-horizontal-tablet);
  }
  ${devices.mobile} {
    padding: 0 var(--padding-horizontal-mobile);
  }
`

const StyledAccordionWithMutliComponentDivider = styled.hr`
  border: 0;
  border-top: 0.1rem solid var(--color-dark-grey);
`

const StyledAccordionWithMutliComponentHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.6rem;
  cursor: pointer;
`

const StyledAccordionWithMutliComponentHeaderTitle = styled.div`
  ${(props) => setTextColor(props?.$textColor)};
  &:hover {
    color: var(--color-orange-hover);
  }
`

const StyledAccordionWithMutliComponentHeaderIcon = styled.img`
  width: 2.4rem;
  height: 2.4rem;
`

const StyledAccordionWithMutliComponentContent = styled.div`
  display: ${(props) => (props?.$defaultdisplay ? 'flex' : 'none')};
  flex-direction: column;
  padding: 0 1.6rem;
  & > .main-container.separator-wrapper {
    padding: 0 !important;
  }
  ${devices.tablet} {
    padding: 0;
  }
`

const AccordionWithMultiComponent = (props) => {
  const [finalData, setFinalData] = useState({})
  const accordionHeaderRef = useRef(null)
  const location = useLocation()
  const navigate = useNavigate()
  const [isOpen, setIsOpen] = useState(props?.field_accordian_flag)
  const [isInView, setIsInView] = useState(false)
  useEffect(() => {
    const dataWithTextCTAGrid = getArrayFromTextCTAGrid({ meta: {}, contents: props?.children })
    setFinalData(dataWithTextCTAGrid)
  }, [])

  useEffect(() => {
    if (location.hash === `#${props?.field_custom_id}`) {
      setIsOpen(true)
      accordionHeaderRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }, [location.hash])

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsInView(entry.isIntersecting)
      },
      { threshold: 1 }
    )
    if (accordionHeaderRef.current) {
      observer.observe(accordionHeaderRef.current)
    }
    return () => {
      if (accordionHeaderRef.current) {
        observer.unobserve(accordionHeaderRef.current)
      }
    }
  }, [])

  useEffect(() => {
    const customLocation = location.pathname.split('#')[0]
    if (isInView && location.hash) {
      navigate(customLocation)
    }
  }, [isInView, location.pathname, location.hash, navigate])

  const handleClick = (_) => {
    setIsOpen((isOpen) => !isOpen)
  }

  return (
    <StyledAccordionWithMutliComponent id={props?.field_custom_id}>
      <StyledAccordionWithMutliComponentDivider />
      <StyledAccordionWithMutliComponentHeader onClick={handleClick} ref={accordionHeaderRef}>
        <StyledAccordionWithMutliComponentHeaderTitle
          dangerouslySetInnerHTML={sanitizeHtml(props?.field_component_title?.processed)}
          $textColor={props.field_title_color}
        />
        <StyledAccordionWithMutliComponentHeaderIcon
          src={isOpen ? upArrowIcon : downArrowIcon}
          alt={isOpen ? 'upArrowIcon' : 'downArrowIcon'}
        />
      </StyledAccordionWithMutliComponentHeader>
      <StyledAccordionWithMutliComponentContent $defaultdisplay={isOpen}>
        {finalData?.contents?.map((component, index) => {
          return renderDrupalComponent(component, index + 'accordion' + Date.now())
        })}
      </StyledAccordionWithMutliComponentContent>
    </StyledAccordionWithMutliComponent>
  )
}

export default AccordionWithMultiComponent
