import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { getCssColorFromDrupalColorType, getCTAAlignment, setTextColor } from '../../Constant/Utils'
import { sanitizeHtml } from '../../utils/htmlHelpers'
import { useTranslation } from 'react-i18next'
import { useScreenSize } from '../../Contexts/ResponsiveContextProvider'
import LoaderBar from '../Loaders/LoaderBar'
import { devices, sizes } from '../../utils/styledComponentHelpers'
import importer from '@takedapdt/biolife-core/src/importer'
const DonorsActions = importer('Stores/Donors/Actions')

const StyledLifeTimeImpactDiv = styled.div`
  position: relative;
  padding: 6.4rem 0;
  background: ${(props) => getCssColorFromDrupalColorType(props?.$bgColor)};
  ${devices.mobile} {
    padding: 6.4rem 3.2rem;
  }
`
const StyledImpactCardsContainer = styled.div`
  display: flex;
  gap: 2.4rem;
  padding-top: 4rem;
  ${devices.mobile} {
    flex-direction: column;
    padding-top: 3.2rem;
  }
`

const StyledImpactCard = styled.div`
  padding: 0.8rem 1.6rem;
  background-color: var(--color-white);
  border-radius: 1rem;
  width: 100%;
  ${devices.mobile} {
    padding: 1.6rem 1.6rem;
  }
`

const StyledTitleInfoIconDiv = styled.div`
  display: flex;
  flex-direction: row;
  img {
    width: 1.6rem;
    height: 1.6rem;
    cursor: pointer;
    margin-left: 1.6rem;
    margin-bottom: 1.6rem;
    ${devices.mobile} {
      position: absolute;
      float: right;
      right: 2.4rem;
      top: 6.4rem;
      margin: 0;
    }
  }
`

const StyledImpactText = styled.p`
  font-size: var(--caption-font-size-mobile);
  font-family: var(--font-family-medium) !important;
  color: var(--color-bl-blue);
  ${devices.mobile} {
    font-size: var(--p-font-size);
  }
`
const StyledImpactNumber = styled.h1`
  color: var(--color-light-orange);
  font-family: var(--font-family-bold) !important;
  ${devices.mobile} {
    font-size: 6rem !important;
    padding-top: 1rem;
  }
`

const StyledLoaderBar = styled.div`
  padding: 10rem 0;
  background-color: var(--color-smoke-grey);
`

function LifeTimeImpactStatistics({
  field_bg_color: bgColor,
  field_component_title: title,
  field_cta_1,
  field_cta_1_style,
  field_title_color: titleColor,
  setDisclaimerPopup,
  getDonorLifetimeImpact,
  getDonorLifetimeImpactSuccess,
  getDonorLifetimeImpactLoading
}) {
  const { t } = useTranslation('donorDashboardPage')

  const { isMobileWidth } = useScreenSize()

  const [totalDonations, setTotalDonations] = useState(0)

  const handleIconClick = (event) => {
    setDisclaimerPopup()
  }

  useEffect(() => {
    getDonorLifetimeImpact()
  }, [])

  useEffect(() => {
    setTotalDonations(getDonorLifetimeImpactSuccess?.totaldonations)
  }, [getDonorLifetimeImpactSuccess])

  useEffect(() => {
    if (totalDonations > 0) {
      document.getElementById('info-icon')?.addEventListener('click', handleIconClick)
    }
  }, [totalDonations])

  return (
    <>
      {getDonorLifetimeImpactLoading && (
        <StyledLoaderBar>
          <LoaderBar />
        </StyledLoaderBar>
      )}
      {totalDonations && totalDonations > 0 ? (
        <StyledLifeTimeImpactDiv $bgColor={bgColor} className={isMobileWidth ? 'main-container' : null}>
          <div className={!isMobileWidth ? 'main-container' : null}>
            <StyledTitleInfoIconDiv>
              {title && title.processed ? (
                <div dangerouslySetInnerHTML={sanitizeHtml(title.processed)} style={{ ...setTextColor(titleColor) }} />
              ) : null}
            </StyledTitleInfoIconDiv>
            <StyledImpactCardsContainer>
              <StyledImpactCard>
                <StyledImpactText>{t('myDonations')}</StyledImpactText>
                <StyledImpactNumber>{totalDonations}</StyledImpactNumber>
              </StyledImpactCard>
              <StyledImpactCard>
                <StyledImpactText>{t('piPatientsTreated')}</StyledImpactText>
                <StyledImpactNumber>{totalDonations * 2}</StyledImpactNumber>
              </StyledImpactCard>
            </StyledImpactCardsContainer>
          </div>
        </StyledLifeTimeImpactDiv>
      ) : null}
    </>
  )
}

const mapStateToProps = (state) => ({
  getDonorLifetimeImpactSuccess: state.donors?.getDonorLifetimeImpactSuccess,
  getDonorLifetimeImpactLoading: state.donors?.getDonorLifetimeImpactLoading
})

const mapDispatchToProps = (dispatch) => ({
  getDonorLifetimeImpact: () => dispatch(DonorsActions.getDonorLifetimeImpact())
})

export default connect(mapStateToProps, mapDispatchToProps)(LifeTimeImpactStatistics)
