import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { useLocation } from 'react-router-dom'
import importer from '@takedapdt/biolife-core/src/importer'
import { Helmet } from 'react-helmet'
import styled from 'styled-components'
const { SLUG_TYPES } = importer('Sagas/Constants/drupalConstants', null)
const PublicContentActions = importer('Stores/PublicContent/Actions')
const renderDrupalComponent = importer('Constant/renderDrupalComponent')

const SocialLogin = ({ fetchFormsData, formsData, formsMetaData }) => {
  let location = useLocation()

  useEffect(() => {
    fetchFormsData(location.pathname.substring(1) || SLUG_TYPES?.SOCIALLOGIN)
  }, [])

  const StyledSocialLoginDiv = styled.div`
    .text-component-description {
      max-width: 664px;
      margin-left: 0px;
      p {
        margin-bottom: 2rem;
        &:last-child {
          margin-bottom: 0px;
        }
      }
    }
  `
  return (
    <>
      <Helmet>
        <title>{`${formsMetaData?.meta_title}`}</title>
        <meta name='description' content={`${formsMetaData?.meta_description}`} />
        <link rel='canonical' href={`${window.location.origin}${window.location.pathname.toLowerCase()}`} />
      </Helmet>
      <StyledSocialLoginDiv>
        {formsData?.map((component, index) => {
          if (component.type === 'breadcrumb') {
            component = {
              ...component,
              breadcrumbToggle: formsMetaData?.field_content_breadcrumb,
              breadcrumbMobileToggle: formsMetaData?.field_mobile_breadcrumb
            }
          }
          return renderDrupalComponent(component, index)
        })}
      </StyledSocialLoginDiv>
    </>
  )
}

const mapStateToProps = (state) => ({
  formsData: state.publicContent?.getFormsPageSuccess?.[SLUG_TYPES?.SOCIALLOGIN]?.contents,
  formsMetaData: state.publicContent?.getFormsPageSuccess?.[SLUG_TYPES?.SOCIALLOGIN]?.metadata
})

const mapDispatchToProps = (dispatch) => ({
  fetchFormsData: (formType) => dispatch(PublicContentActions.getFormsPage(formType))
})

export default connect(mapStateToProps, mapDispatchToProps)(SocialLogin)
