import { put, select, delay } from 'redux-saga/effects'
import TagManager from 'react-gtm-module'
import importer from '@takedapdt/biolife-core/src/importer'
/* ACTIONS */
const AuthCustomActions = importer('Stores/AuthCustom/Actions')
const DonorsActions = importer('Stores/Donors/Actions')
/* ENDPOINT */
const { mulesoft } = importer('APIs/auth', null)
const { register } = mulesoft
const { getDonorAgeGroup } = importer('Helpers/getDonorAgeGroup', null)

const getDonor = (state) => state.donors

export default function * main ({ donor }) {
  yield put(DonorsActions.getDonors(donor.donorDetails?.donorId))
  yield delay(1000)
  const updatedDonor = yield select(getDonor)
  const sfid = updatedDonor && updatedDonor?.donors?.data.sfid
  const donorWithSfid = { ...donor, salesforceId: sfid || '' }
  const accountCreationStartTime = window.sessionStorage.getItem('accountCreationStartTime') || null
  let accountCreationDuration = 0

  try {
    yield put(AuthCustomActions.registerLoading())
    const res = yield register(donorWithSfid)
     if (res.status === 200 || res.status === 304 || res.status === 201) {
      // This is to track account creation time through the donor profile complete event on GTM
      if(accountCreationStartTime) {
        const accountCreationEndTime = new Date().getTime()
        accountCreationDuration = (accountCreationEndTime - accountCreationStartTime) / 1000
        window.sessionStorage.removeItem('accountCreationStartTime')
      }
      TagManager.dataLayer({
        dataLayer: {
          event: 'profileRegistration',
          profileRegistrationStage: 'donor profile complete',
          ...(accountCreationDuration) && {profileCreationDuration: accountCreationDuration},
          donorId: donor.donorDetails.donorId,
          donorEmail:donor.donorDetails.email?encodeURIComponent(Buffer.from(donor.donorDetails.email).toString('base64')):donor.donorDetails.email,
          //donorEmail:donor.donorDetails.email,
          bdpDonorId: res.data.donor.profile.bdpDonorId || null,
          sfid: res.data.donor.profile.sfid || null,
          donorLanguage: res.data.donor.profile.language || null,
          donorCountry: donor.donorDetails.country,
          bdpDonorId: res.data.donor.profile.bdpDonorId || null,
          sfid: res.data.donor.profile.sfid || null,
          donorState: donor.donorDetails.state,
          donorZip: donor.donorDetails.zipCode,
          donorAgeGroup: getDonorAgeGroup(donor.donorDetails.birthDate),
          donorGender: donor.donorDetails.gender,
          disCenterId: donor.donorDetails.donationCenter
        }
      })
      yield put(AuthCustomActions.registerSuccess(res))
    } else {
      throw Error(res)
    }
  } catch (error) {
    yield put(AuthCustomActions.registerFailure(error.response?.data))
  }
}
