import styled from 'styled-components'
import importer from '@takedapdt/biolife-core/src/importer'
const { getCssColorFromDrupalColorType, setTextColor } = importer('Constant/Utils', null)
const { devices } = importer('utils/styledComponentHelpers', null)

export const TextComponent = styled.p`
  ${({ color }) => setTextColor(color)};
  padding-right: ${({ paddingRight }) => paddingRight};
  border-right: ${({ borderRight }) => borderRight};
  font-family: ${({ fontFamily }) => (fontFamily ? `${fontFamily} !important` : 'AvenirNextBold !important')};
  margin: ${({ margin }) => margin || '0'};
  text-decoration: ${({ textDecoration }) => textDecoration || 'none'};
  word-break: ${({ wordBreak }) => wordBreak};
  height: ${({ height }) => height || 'auto'};
  font-size: ${({ fontSize }) => `${fontSize} !important`};
`

export const Highlight5 = styled.h5`
  ${({ color }) => setTextColor(color)};
  font-family: 'AvenirNextBold' !important;
  margin: ${({ margin }) => margin || '0'};
`

export const Highlight4 = styled.h4`
  ${({ color }) => setTextColor(color)};
  font-family: 'AvenirNextBold' !important;
  margin: ${({ margin }) => margin || '0'};
`

export const Div = styled.div`
  background: ${({ bgColor }) => getCssColorFromDrupalColorType(bgColor)};
  padding: ${({ padding }) => padding};
  height: ${({ minHeight }) => minHeight};
  margin: ${({ margin }) => margin};
  position: ${({ position }) => position};
  max-width: ${({ maxWidth }) => maxWidth};
  flex-grow: ${({ flexGrow }) => flexGrow};
  text-align: ${({ textAlign }) => textAlign};
`

export const DivWithFlex = styled.div`
  display: flex;
  background: ${({ bgColor }) => getCssColorFromDrupalColorType(bgColor)};
  flex-direction: ${({ direction }) => direction || 'row'};
  align-items: ${({ align }) => align};
  justify-content: ${({ justifyContent }) => justifyContent};
  border-radius: ${({ borderRadius }) => borderRadius};
  padding: ${({ padding }) => padding};
  margin: ${({ margin }) => margin};
  position: ${({ position }) => position};
  max-width: ${({ maxWidth }) => maxWidth};
  box-shadow: ${({ boxShadow }) => boxShadow};
  flex-wrap: ${({ flexWrap }) => flexWrap};
  column-gap: ${(columnGap) => columnGap};
  row-gap: ${(rowGap) => rowGap};
  flex-grow: ${({ flexGrow }) => flexGrow};
`
