import styled from 'styled-components'
import { devices } from '../../../utils/styledComponentHelpers'

export const HeaderMainContainer = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: var(--color-orange);
  padding: 2rem 3rem;
  border-radius: 22px 22px 0px 0px;
`
export const HeaderText = styled.h5`
  color: var(--color-info-blue);
  font-family: var(--font-family-bold) !important;
  align-self: center;
`

export const SideOptionsStyledComponent = styled.div`
  color: var(--color-info-blue);
  display: flex;
  justify-content: center;
  align-items: center;
`
export const HeaderImage = styled.img`
  height: 25px;
  width: 25px;
  cursor: pointer;
  margin: 5px 0px 5px 16px;
`
