import React, { useEffect, useState } from 'react'
import { Grid } from 'semantic-ui-react'
import importer from '@takedapdt/biolife-core/src/importer'
import styled from 'styled-components'
import { Embed } from 'semantic-ui-react'
import { ReactComponent as ButtonVideoPlay } from '../../../assets/media/icons/button-video-play.svg'

const Config = importer('Config')
const { devices, sizes } = importer('utils/styledComponentHelpers', null)
const { sanitizeHtml } = importer('utils/htmlHelpers', null)
const { useScreenSize } = importer('Contexts/ResponsiveContextProvider', null)
const PrimaryButton = importer('Components/Buttons/PrimaryButton')
const { getCssColorFromDrupalColorType, getMediaFromDrupalMimeType } = importer('Constant/Utils', null)
const { parseExtVideoLink } = importer('Helpers/Utils', null)

const StyledVideoContainer = styled.div`
  width: 100%;
  height: 500px;
  position: relative;

  ${devices.tablet} {
    height: 100%;
    aspect-ratio: 2/1;
  }
  ${devices.mobile} {
    flex: auto;
  }
`

const StyledVideo = styled.iframe`
  width: 100%;
  height: 100%;
  border: 0;
  position: absolute;
  overflow: hidden;
`
const StyledImage = styled.img`
  width: 100%;
  height: 100%;
  border: 0;
  position: absolute;
  object-fit: cover;
  overflow: hidden;
  transform: scale(1.01);
  cursor: ${(props) => (props.$testMetaVideo ? 'pointer' : 'inherit')};
  display: ${(props) => (props.$videoCoverImageVisible ? 'block' : 'none')};
  object-position: center top;
  ${devices.mobile} {
    img {
      width: 20rem;
      height: 20rem;
    }
  }
`

const StyledButton = styled.div`
  margin-left: 50%;
  margin-top: 230px;
  position: absolute !important;
  display: ${(props) => (props.$videoCoverImageVisible ? 'block' : 'none')};
  svg {
    width: 40px !important;
    height: 40px !important;
  }
  ${devices.tablet} {
    margin-top: 0;
    margin-left: 0;
    top: calc(50% - 2rem);
    left: calc(50% - 2rem);
  }
`
const StyledMain = styled.div``

const ArticleImageComponent = ({ testMetaImage, testMetaVideo }) => {
  const [videoCoverImageVisible, setVideoCoverImageVisible] = useState(true)

  const onVideoCoverImageClicked = () => {
    setVideoCoverImageVisible(false)
  }
  return (
    <StyledMain className='main-container'>
      <StyledVideoContainer className='video-container'>
        {(testMetaVideo?.field_media_oembed_video || testMetaVideo?.url) && (
          <StyledVideo
            src={testMetaVideo.url || parseExtVideoLink(testMetaVideo?.field_media_oembed_video)}
            as={testMetaVideo?.field_media_oembed_video ? 'iframe' : 'video'}
            controls
            autoplay='false'
            preload='metadata'
            controlsList='nodownload nofullscreen noremoteplayback'
          />
        )}
        <StyledImage
          src={testMetaImage?.url}
          alt={testMetaImage?.alt}
          as='img'
          onClick={testMetaVideo && onVideoCoverImageClicked}
          $videoCoverImageVisible={videoCoverImageVisible}
        />

        {(testMetaVideo?.field_media_oembed_video || testMetaVideo?.url) && (
          <StyledButton onClick={onVideoCoverImageClicked} $videoCoverImageVisible={videoCoverImageVisible}>
            <ButtonVideoPlay />
          </StyledButton>
        )}
      </StyledVideoContainer>
    </StyledMain>
    // <p></p>
  )
}
export default ArticleImageComponent
