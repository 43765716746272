import i18n from '../i18n'

const changeLanguageHelper = (lang) => {
  //change i18n language
  i18n.changeLanguage(lang)
  if (window.dataLayer) {
    window.dataLayer.push({
      event: 'languageChange',
      languageChosen: i18n.language === 'en-US' ? 'English' : 'Spanish'
    })
  }
  if (window.OneTrust) {
    window.OneTrust.changeLanguage(i18n.language.split('-')[0])
  }
}

export default changeLanguageHelper
