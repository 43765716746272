import styled from 'styled-components'
// import { devices } from '../../../utils/styledComponentHelpers'

export const TextAreaContainer = styled.div`
  width: 100%;
`

export const IconContainer = styled.div`
  display: ${({ $icon }) => ($icon ? 'flex' : 'none')};
  align-items: center;
  border: ${({ $focused }) => ($focused ? '1px solid var(--color-dark-grey) !important' : 'none')};
  padding: 5px 20px;
  background-color: ${({ $disabled }) => ($disabled ? 'var(--color-light-grey)' : 'var(--color-white)')};
`

export const StyledTextArea = styled.textarea`
  padding: 7px;
  resize: none;
  width: ${({ $icon }) => ($icon ? '90%' : '100%')};
  outline: ${({ $icon }) => ($icon ? 'none' : 'var(--color-dark-grey) auto 1px;')};
  border: ${({ $icon }) => ($icon ? 'none' : '1px solid var(--color-dark-grey)')};
  &::placeholder {
    padding: ${({ $icon }) => ($icon ? '0px' : '0')};
  }
  &:focus-visible {
    outline: ${({ $icon }) => ($icon ? 'none' : '1px solid var(--color-dark-grey)')}; // var(--color-dark-grey) auto 1px;
  }
  &:disabled {
    background-color: var(--color-light-grey);
  }
`

export const StyledImage = styled.img`
  height: 28px;
  width: 28px;
  margin: ${({ $noOfLine }) => ($noOfLine > 39 ? '0rem 0rem 0.5rem 0rem' : '0.6rem 0rem')};
`

export const StyledImageContainer = styled.div`
  display: flex;
  align-self: ${({ $scrollHeight }) => ($scrollHeight ? 'flex-end' : 'center')};
`
