import React from 'react'
import { Popup } from 'semantic-ui-react'
import './styles.scss'

const DropdownMenu = ({ trigger, content, onBlur }) => {
  return (
    <Popup
      className='DropdownMenu'
      onUnmount={onBlur}
      trigger={trigger}
      content={content}
      on={['click']}
      position='bottom right'
      basic
      hoverable
    />
  )
}

export default DropdownMenu
