export const sizes = {
  smallMobile: '390px',
  mobile: '767px',
  tablet: '1365px',
  desktop: '1920px'
}

export const devices = {
  mobile: `@media screen and (max-width: ${sizes.mobile})`,
  tablet: `@media screen and (max-width: ${sizes.tablet})`,
  desktop: `@media screen and (max-width: ${sizes.desktop})`
}
