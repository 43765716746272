import React from 'react'
import { storiesOf } from '@storybook/react'
import SelectSearch from './index'

storiesOf('Inputs', module).add('SelectSearch', () => (
  <SelectSearch
    placeholder='Status'
    options={[
      { key: 'fl', value: 'fl', text: 'Florida' },
      { key: 'tx', value: 'tx', text: 'Texas' }
    ]}
    required
  />
))
