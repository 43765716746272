import React, { useEffect } from 'react'
import styled from 'styled-components'
import './styles.scss'
import { Grid, GridColumn, GridRow } from 'semantic-ui-react'
import importer from '@takedapdt/biolife-core/src/importer'
const { devices, sizes } = importer('utils/styledComponentHelpers', null)
const { sanitizeHtml } = importer('utils/htmlHelpers', null)

const { useScreenSize } = importer('Contexts/ResponsiveContextProvider', null)
const PrimaryButton = importer('Components/Buttons/PrimaryButton')
const { getCssColorFromDrupalColorType, setTextColor, getMediaFromDrupalMimeType } = importer('Constant/Utils', null)

const StyledCardTemp1 = styled.div`
  background: ${(props) => getCssColorFromDrupalColorType(props?.$bgColor)};
`

const StyledCardTemp1Container = styled.div`
  padding: 40px;
  box-shadow: 0px 4px 8px #999999;
  background: ${(props) => getCssColorFromDrupalColorType(props?.$bgColor1)};
  border-radius: 25px;
  display: flex;
  flex-direction: ${(props) => (props?.$mediaAlignment === 2 ? 'row' : 'row-reverse')};
  align-items: flex-start;
  gap: 24px;
  ${devices.mobile} {
    padding: 40px 24px;
    flex-direction: column-reverse;
    gap: 32px;
    align-items: center;
    text-align: center;
  }
`
const StyledCardTemp1TextColumn = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  ${devices.mobile} {
    justify-content: center;
    display: flex;
    align-items: flex-start;
    text-align: left;
  }
`

const StyledCardTemp1Title = styled.div`
  ${(props) => setTextColor(props?.$titleColor)};
`

const StyledCardTemp1DescriptionTop = styled.div`
  ${(props) => setTextColor(props?.$shortDescColor)};

  ${devices.tablet} {
    padding-top: 16px;
  }
  ${devices.mobile} {
    text-align: left;
  }
`

const StyledCardTemp1Description = styled.div`
  ${(props) => setTextColor(props?.$longDescColor)};
  padding-top: 16px;
  ${devices.mobile} {
    text-align: left;
  }
`
const StyledCardTemp1ButtonColumn = styled.div`
  padding-top: 45px;
  display: flex;
`

const StyledCardTemp1ImageColumn = styled.div`
  width: 320px;

  ${devices.tablet} {
    width: 243px;
  }
  ${devices.mobile} {
    width: 100%;
  }
`

const StyledImage = styled.img`
  aspect-ratio: auto;
  object-fit: contain;
  object-position: center;
  width: 100%;
`

const CardTemplate1 = (props) => {
  const {
    field_component_title: componentTitle,
    field_title_color: titleColor,
    field_short_description: shortDescription,
    field_short_desc_color: shortDescColor,
    field_component_desc: componentDesc,
    field_long_desc_color: longDescColor,
    field_component_image: componentImage,
    field_alignment: alignment,
    field_cta_1: cta,
    field_cta_1_style: ctaStyle,
    field_bg_color_1: bgColor1,
    field_bg_color: bgColor
  } = props
  const { isMobileWidth } = useScreenSize()

  return (
    <StyledCardTemp1 className='card-temp1' $bgColor={bgColor}>
      <div className='main-container'>
        <StyledCardTemp1Container $bgColor1={bgColor1} $mediaAlignment={alignment} className='card-temp1__container'>
          <StyledCardTemp1TextColumn className='card-temp1__text-column'>
            <StyledCardTemp1Title
              $titleColor={titleColor}
              dangerouslySetInnerHTML={sanitizeHtml(componentTitle?.processed)}
              className='card-temp1__title'
            />
            <StyledCardTemp1DescriptionTop
              $shortDescColor={shortDescColor}
              dangerouslySetInnerHTML={sanitizeHtml(shortDescription?.processed)}
              className='card-temp1__description-top'
            />
            <StyledCardTemp1Description
              $longDescColor={longDescColor}
              dangerouslySetInnerHTML={sanitizeHtml(componentDesc?.processed)}
              className='card-temp1__description-bottom'
            />
            <StyledCardTemp1ButtonColumn className='card-temp1__button-column'>
              <PrimaryButton text={cta?.title} variant={ctaStyle} sendTo={cta?.uri} fluid={isMobileWidth} />
            </StyledCardTemp1ButtonColumn>
          </StyledCardTemp1TextColumn>
          <StyledCardTemp1ImageColumn className='card-temp1__image-column'>
            <StyledImage src={componentImage?.url} alt={componentImage?.alt} className='card-temp1__img' />
          </StyledCardTemp1ImageColumn>
        </StyledCardTemp1Container>
      </div>
    </StyledCardTemp1>
  )
}

export default CardTemplate1
