import React from 'react'
import styled from 'styled-components'
import importer from '@takedapdt/biolife-core/src/importer'
const { getCssColorFromDrupalColorType, setTextColor } = importer('Constant/Utils', null)
const { devices } = importer('utils/styledComponentHelpers', null)
const { sanitizeHtml } = importer('utils/htmlHelpers', null)
import ButtonCTA from '../../ButtonCTA'

const alignmentStyles = {
  1: `row-reverse`,
  2: `row`,
  3: `column-reverse`,
  4: `column`
}

const alignmentStylesTablet = {
  1: `column`,
  2: `column`,
  3: `column-reverse`,
  4: `column`
}

const StyledImageBannerTemplate2 = styled.div`
  display: flex;
  flex-direction: ${(props) => alignmentStyles[props?.$ctaAlignment]};
  gap: 1.6rem;
  padding: 4rem;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 2.5rem;
  background: url(${(props) => props?.$bgImageDesktop?.url}) no-repeat, ${(props) => getCssColorFromDrupalColorType(props?.$bgColor)};
  ${devices.tablet} {
    flex-direction: ${(props) => alignmentStylesTablet[props?.$ctaAlignment]};
    background: url(${(props) => props?.$bgImageMobile?.url || props?.$bgImageDesktop?.url}) no-repeat center / cover,
      ${(props) => getCssColorFromDrupalColorType(props?.$bgColor)};
    padding-top: 4rem;
    padding-bottom: 4.7rem;
  }
  ${devices.mobile} {
    padding: 6.4rem 2rem;
  }
`
const StyledImageBannerTemplate2Title = styled.div`
  ${(props) => setTextColor(props.$textColor)};
`

const StyledImageBannerTemplate2Description = styled.div`
  ${(props) => setTextColor(props.$textColor)};
`

const StyledImageBannerTemplate2ButtonContainer = styled.div`
  padding-top: 1.6rem;
`

const ImageBannerTemplate2 = (props) => {
  return (
    <StyledImageBannerTemplate2
      $bgImageDesktop={props?.field_component_bg_image}
      $bgImageMobile={props?.field_component_mbg_image}
      $bgColor={props?.field_bg_color}
      $ctaAlignment={props?.field_alignment_2}
    >
      <div>
        <StyledImageBannerTemplate2Title
          dangerouslySetInnerHTML={sanitizeHtml(props?.field_component_title?.processed)}
          $textColor={props?.field_title_color}
        />
        <StyledImageBannerTemplate2Description
          dangerouslySetInnerHTML={sanitizeHtml(props?.field_short_description?.processed)}
          $textColor={props?.field_short_desc_color}
        />
      </div>
      <StyledImageBannerTemplate2ButtonContainer>
        <ButtonCTA {...props} field_bg_color={'transparent'} />
      </StyledImageBannerTemplate2ButtonContainer>
    </StyledImageBannerTemplate2>
  )
}

export default ImageBannerTemplate2
