import React from 'react'
import { useTranslation } from 'react-i18next';

import {
  ChipTitle,
  Div,
  FeaturedBackgroundImage,
  Label,
  Container,
  TextComponent,
  NoResultsFoundTitle,
  Highlight5,
  DivWithFlex,
  ImageDiv,
  Image
} from './styles/Chips.styled'
import importer from 'common/importer'
const { useScreenSize } = importer('Contexts/ResponsiveContextProvider', null)
import CloseIcon from '../../../assets/media/icons/FilterCloseIcon.svg'
function FilterChips({ filterData, topic_name = '', uncheckFunction }) {
  const { isMobileWidth, isTabletWidth } = useScreenSize()
  const { t } = useTranslation('AccessManagementSuccessModal');

  return (
    <DivWithFlex
      $bgColor='light_gray'
      $padding='0.8rem 1.6rem'
      $borderRadius='19px'
      $flexWrap='nowrap'
      $margin={!isMobileWidth ? '0rem 0.9rem 0rem 0rem' : '2.5rem 0.9rem 0rem 0rem'}
    >
      <ImageDiv onClick={() => uncheckFunction(filterData)}>
        <Image src={CloseIcon} alt={t('close')} />
      </ImageDiv>
      <ChipTitle $color='rich_black'>{topic_name.toUpperCase()}</ChipTitle>
    </DivWithFlex>
  )
}

export default FilterChips
