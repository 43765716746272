import React from 'react'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import './styles.scss'
import importer from '@takedapdt/biolife-core/src/importer'
const { useScreenSize } = importer('Contexts/ResponsiveContextProvider', null)
import { ReactComponent as NextArrowWhite } from '../../../assets/media/icons/circle-arrow-right-white.svg'
import { ReactComponent as PrevArrowWhite } from '../../../assets/media/icons/circle-arrow-left-white.svg'

const SliderNextArrowWhite = ({ className, onClick }) => {
  return <NextArrowWhite width='100' height='100' className={className} onClick={onClick} />
}

const SliderPrevArrowWhite = ({ className, onClick }) => {
  return <PrevArrowWhite width='50' height='50' className={className} onClick={onClick} />
}

const SliderCarousel = ({ children }) => {
  const { isMobileWidth, isTabletWidth } = useScreenSize()

  var settings = {
    dots: true,
    intialSlide: 1,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: isMobileWidth || isTabletWidth ? false : true,
    nextArrow: <SliderNextArrowWhite />,
    prevArrow: <SliderPrevArrowWhite />,
    responsive: [
      {
        breakpoint: 1422,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 1366,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 1080,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  }
  return (
    <Slider className='slider-carousel' {...settings}>
      {children}
    </Slider>
  )
}

export default SliderCarousel
