import React, { useState } from 'react'

import MyInformation from './MyInformation'

const ProfileSettings = () => {
  return (
    <>
      <MyInformation />
    </>
  )
}

export default ProfileSettings
