import React, { useEffect } from 'react'
import importer from '@takedapdt/biolife-core/src/importer'
import { useLocation } from 'react-router-dom'
const Login = importer('Components/Login')

const LoginPage = () => {
  let location = useLocation()
  useEffect(() => {
    if (location.search) {
      const searchParams = new URLSearchParams(location.search)
      if (searchParams.get('redirectUri') === '/my-rewards' || searchParams.get('redirectUri') === '/redeem-rewards') {
        sessionStorage.setItem('routeToLoyaltyDashboard', 'true')
      } else if (searchParams.get('redirectUri') === '/ways-to-earn') {
        sessionStorage.setItem('routeToWaysToEarn', 'true')
      }
    }
  }, [location])

  return <Login />
}

export default LoginPage
