import importer from '@takedapdt/biolife-core/src/importer'
const isValidEmail = importer('Helpers/isValidEmail')
const validate = (values) => {
  const errors = {}
  // First name validation
  if (values['first_name']) {
    if (values['first_name'].length < 1) {
      errors['first_name'] = 'firstNameShouldHaveAtLeasst1Char'
    } else if (values['first_name'].length === 1 && values['first_name'].match(/^[a-zA-Z]$/i) === null) {
      errors['first_name'] = 'firstNameShouldBeCharacteterOnly'
    } else if (
      values['first_name'].length > 1 &&
      values['first_name'].match(/^(?!.*(['ʻ,. -]){2})([a-zA-Z])([a-zA-Z'ʻ,. -]*[a-zA-Z])$/i) === null
    ) {
      errors['first_name'] = 'firstNameShouldBeCharacteterOnly'
    }
  } else {
    errors['first_name'] = 'firstNameIsRequired'
  }
  // Last name validation
  if (values['last_name']) {
    if (values['last_name'].length < 2) {
      errors['last_name'] = 'lastNameShouldHaveAtLeast2Char'
    } else if (values['last_name'].match(/^(?!.*(['ʻ,. -]){2})([a-zA-Z])([a-zA-Z'ʻ,. -]*[a-zA-Z])$/i) === null) {
      errors['last_name'] = 'lastNameShouldBeCharacter'
    }
  } else {
    errors['last_name'] = 'lastNameIsRequired'
  }

  // Email validation
  if (values['email_address']) {
    if (!isValidEmail(values['email_address'])) {
      errors['email_address'] = 'emailAddressMustBeInValid'
    } else if (values['email_address'].indexOf('@takeda.com') > -1 || values['email_address'].indexOf('@shire.com') > -1) {
      errors['email_address'] = 'sorryCannotRegisterWithTakedaEmail'
    }
  } else {
    errors['email_address'] = 'emailAddressIsRequired'
  }
  // Postal code validation
  if (values['zip_code']) {
    if (!/^\d{5}$/.test(values['zip_code'])) {
      errors['zip_code'] = 'zipCodeShouldHave5Digits'
    }
  } else {
    errors['zip_code'] = 'zipCodeIsRequired'
  }
  return errors
}

export { validate }
