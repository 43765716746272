import React from 'react'
import moment from 'moment'
import isValidEmail from './../../../Helpers/isValidEmail'

const emailValidations = (values) => {
  const errors = {}

  // current_email validation
  if (values.currentEmail) {
    if (!isValidEmail(values.currentEmail)) {
      errors.currentEmail = 'emailAddressMustBeInValid'
    } else if (values.currentEmail.indexOf('@takeda.com') > -1 || values.currentEmail.indexOf('@shire.com') > -1) {
      errors.currentEmail = 'sorryCannotRegisterWithTakedaEmail'
    }
  } else {
    errors.currentEmail = 'emailAddressIsRequired'
  }

  // newEmail validation
  if (values.newEmail) {
    if (!isValidEmail(values.newEmail)) {
      errors.newEmail = 'emailAddressMustBeInValid'
    } else if (values.newEmail.indexOf('@takeda.com') > -1 || values.newEmail.indexOf('@shire.com') > -1) {
      errors.newEmail = 'sorryCannotRegisterWithTakedaEmail'
    }
  } else {
    errors.newEmail = 'emailAddressIsRequired'
  }

  if (values.confirmEmail) {
    if (values.confirmEmail !== values.newEmail) {
      errors.confirmEmail = 'emailDoNotMatch'
    }
  } else {
    errors.confirmEmail = 'confirmEmailCannotBeEmpty'
  }

  return errors
}

export default emailValidations
