import React, { useEffect, useState } from 'react'
import { Segment, Menu, Image, Icon, Button } from 'semantic-ui-react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import bioLifeLogo from '../../../assets/media/images/BioLife_logo.png'
import importer from '@takedapdt/biolife-core/src/importer'
import './style.scss'
const PrimaryButton = importer('Components/Buttons/PrimaryButton')
const { getMediaFromDrupalMimeType } = importer('Constant/Utils', null)
const SecondaryMenu = importer('Components/Navbar/SecondaryMenu')
import styled from 'styled-components'
const { devices } = importer('utils/styledComponentHelpers', null)
import { ReactComponent as HamburgerIcon } from '../../../assets/media/icons/Hamburger-UI.svg'
const PublicContentActions = importer('Stores/PublicContent/Actions')
const { DRUPAL_MENU_TYPES } = importer('Sagas/Constants/drupalConstants', null)
const MobileWebNavbar = importer('Components/Navbar/MobileWebNavbar')
const DropdownMenu = importer('Components/Navbar/DropdownMenu')
const { getCssColorFromDrupalColorType } = importer('Constant/Utils', null)
const { useScreenSize } = importer('Contexts/ResponsiveContextProvider', null)
const LoaderSpinner = importer('Components/Loaders/LoaderSpinner')

const StickyNav = styled.div`
  position: sticky !important;
  z-index: 100;
  top: ${(props) => (props.$bannerHeight ? props.$bannerHeight + 'px !important' : '0px')};
  ${devices.tablet} {
    top: 0px !important;
  }
`

const LoggedInNavbarContainer = styled(Menu.Item)`
  width: 100%;
  z-index: 100;
  box-shadow: 0px 4px 8px var(--header-shadow-color);
`

const LoggedInNav = styled(Segment.Group)`
  border: none !important;
  margin: 0 !important;
  align-items: center;
  box-shadow: none !important;
  height: 9rem;
  .segment {
    padding: 13px 0 13px 40px;
    ${devices.mobile} {
      padding: 7px 24px;
    }
  }
  ${devices.mobile} {
    height: 6rem;
    border-top: 5px solid var(--color-bl-orange) !important;
    .donateNow {
      padding: 5px 12px !important;
      font-size: var(--caption-mobile-font-size) !important;
      line-height: var(--caption-line-height) !important;
    }
  }
`

const HeaderLogo = styled(Link)`
  display: inline-block;
  vertical-align: middle;

  ${devices.mobile} {
    img {
      max-width: 92px !important;
    }
  }
`

const HamburgerButton = styled(Button)`
  padding-left: 0 !important;
  background-color: transparent !important;
  margin-left: 48px !important;
  margin-right: 40px !important;
  ${devices.mobile} {
    margin: 0 24px 0 24px !important;
  }
  svg {
    width: 24px;
    height: 17px;
    &:hover g {
      stroke: var(--color-orange-hover) !important;
    }
  }
`

const HamburgerMsgDot = styled.span`
  position: relative;
  display: inline-block;
  right: 29px;
  bottom: 12px;
  height: 12px;
  width: 12px;
  padding: 0px 6px;
  border-radius: 50%;
  background: var(--color-bl-blue);
`

const LoggedInNavbar = ({
  isDonor,
  donor,
  handleToggleLocal,
  handleToggleSidebar,
  drupalNavData,
  getMessages,
  drupalNavDataLoading,
  drupalHeaderMenu,
  drupalHeaderMenuLoading,
  sfDonorMessages,
  fetchPostLoginHeader,
  user,
  bannerHeight
}) => {
  const [dashboardCTAButton, setDashboardCTAButton] = useState(
    drupalNavData?.find((item) => item.type === 'header_component' && item.field_menu_type === 'user_dashboard_menu') || {}
  )
  const { isMobileWidth, isTabletWidth } = useScreenSize()
  const [sidebarOpened, setSidebarOpened] = useState(false)

  const [activeItem, setActiveItem] = useState()
  const handleItemClick = (value, { name }) => {
    setActiveItem(name)
  }
  const dashboardMenuBar = drupalNavData?.find((item) => item.type === 'header_component' && item.field_menu_type === 'user_dashboard_menu')
  const mainNavBar = drupalNavData?.find((item) => item.type === 'header_component' && item.field_menu_type === 'main')

  useEffect(() => {
    fetchPostLoginHeader(DRUPAL_MENU_TYPES.POSTLOGIN_HEADER)
    getMessages(user?.defaultCenter?.sfid)
  }, [])

  return (
    <>
      {/* To be removed in the future. Used by DIS */}
      <img src={bioLifeLogo} alt='biolifeLogo' style={{ display: 'none' }} />
      {!drupalHeaderMenuLoading && !drupalNavDataLoading ? (
        isMobileWidth || isTabletWidth ? (
          <Menu.Item position='right' className='MobileWebNavbar'>
            <MobileWebNavbar handleToggleSidebar={handleToggleSidebar} />
          </Menu.Item>
        ) : (
          <StickyNav $bannerHeight={bannerHeight}>
            {dashboardMenuBar ? (
              <LoggedInNavbarContainer className='loggedInNavbarContainer'>
                <Segment className='PrimaryMenu' style={{ background: getCssColorFromDrupalColorType(mainNavBar?.field_bg_color) }}>
                  <LoggedInNav horizontal>
                    <Segment>
                      <HeaderLogo to='/'>
                        {getMediaFromDrupalMimeType(
                          dashboardMenuBar?.field_logo?.mimetype,
                          dashboardMenuBar?.field_logo?.url,
                          dashboardMenuBar?.field_logo?.alt
                        )}
                      </HeaderLogo>
                    </Segment>
                    {dashboardMenuBar ? (
                      <>
                        {!isMobileWidth && dashboardCTAButton ? (
                          <>
                            <Menu>
                              {drupalHeaderMenu?.map((item, index) => {
                                if (item.submenu.length > 0) {
                                  return (
                                    <DropdownMenu
                                      key={index}
                                      onBlur={() => setActiveItem(null)}
                                      trigger={
                                        <Menu.Item
                                          name={item.title}
                                          className='NavbarMenuItem'
                                          active={activeItem === item.title}
                                          onClick={handleItemClick}
                                          role='button'
                                        >
                                          {item.title}
                                          <Icon name='angle down' className='DropdownIcon' />
                                        </Menu.Item>
                                      }
                                      content={<SecondaryMenu items={item.submenu} />}
                                    />
                                  )
                                } else if (item.external) {
                                  return (
                                    <Menu.Item
                                      key={index}
                                      name={item.title}
                                      className='NavbarMenuItem'
                                      active={activeItem === item.title}
                                      onClick={handleItemClick}
                                      as='a'
                                      href={item.href}
                                      target={item.target}
                                      onBlur={() => setActiveItem(null)}
                                    >
                                      {item.title}
                                    </Menu.Item>
                                  )
                                } else {
                                  return (
                                    <Menu.Item
                                      key={index}
                                      className='NavbarMenuItem'
                                      name={item.title}
                                      active={activeItem === item.title}
                                      as={Link}
                                      to={item.href}
                                      onClick={handleItemClick}
                                      onBlur={() => setActiveItem(null)}
                                    >
                                      {item.title}
                                    </Menu.Item>
                                  )
                                }
                              })}
                            </Menu>
                            <Menu style={{ padding: '0 0 0 25px' }}>
                              {console.log(mainNavBar?.field_cta_1?.title, mainNavBar?.field_cta_2?.title)}
                              {mainNavBar && mainNavBar?.field_cta_1?.title ? (
                                <PrimaryButton
                                  text={mainNavBar?.field_cta_1?.title}
                                  variant={mainNavBar?.field_cta_1_style}
                                  sendTo={user ? 'internal:/appointment-scheduling' : mainNavBar?.field_cta_1?.uri}
                                />
                              ) : null}

                              {mainNavBar && mainNavBar?.field_cta_2?.title ? (
                                <PrimaryButton
                                  text={mainNavBar?.field_cta_2?.title}
                                  variant={mainNavBar?.field_cta_2_style}
                                  sendTo={user ? 'internal:/appointment-scheduling' : mainNavBar?.field_cta_2?.uri}
                                />
                              ) : null}
                            </Menu>
                          </>
                        ) : null}
                      </>
                    ) : null}
                    {/* {dashboardMenuBar ? (
              <PrimaryButton
                text={dashboardMenuBar?.field_cta_1?.title}
                sendTo={user ? 'internal:/appointment-scheduling' : dashboardMenuBar?.field_cta_1?.uri}
                variant={dashboardMenuBar?.field_cta_1_style}
                className='donateNow'
              />
            ) : null} */}
                    {donor ? (
                      <HamburgerButton aria-label='Hamburger button' onClick={handleToggleSidebar}>
                        <HamburgerIcon />
                        {sfDonorMessages?.length > 0 && <HamburgerMsgDot />}
                      </HamburgerButton>
                    ) : null}
                  </LoggedInNav>
                </Segment>
              </LoggedInNavbarContainer>
            ) : null}
          </StickyNav>
        )
      ) : (
        <LoaderSpinner backdrop='full' />
      )}
    </>
  )
}

const mapStateToProps = (state) => ({
  drupalNavData: state.publicContent?.getDrupalNavigationSuccess?.contents,
  drupalHeaderMenu: state.publicContent.drupalMenuSuccess?.main,
  drupalHeaderMenuLoading: state.publicContent.drupalMenuLoading?.main,
  sfDonorMessages: state.publicContent?.getMessagesSuccess?.sfDonorMessages,
  drupalNavDataLoading: state.publicContent?.getDrupalNavigationLoading,
  donor: state.donors.donors?.data,
  user: state.users?.user
})

const mapDispatchToProps = (dispatch) => ({
  fetchPostLoginHeader: (header_type) => dispatch(PublicContentActions.drupalMenuFetch(header_type)),
  getMessages: (sfid) => dispatch(PublicContentActions.getMessages(sfid))
})

export default connect(mapStateToProps, mapDispatchToProps)(LoggedInNavbar)
