import React from 'react'
import importer from '@takedapdt/biolife-core/src/importer'
import './styles.scss'
const renderDrupalComponent = importer('Constant/renderDrupalComponent')

const ShareWithUsSuccessPage = (props) => {
  return (
    <>
      <div className='ShareWithUsSuccessPageContainer'>
        {props?.ShareWithUsSuccessContent?.contents?.map((component, index) => {
          if (component.type === 'breadcrumb') {
            let tempComp = { ...component, breadcrumbToggle: props?.ShareWithUsSuccessContent?.metadata?.field_content_breadcrumb }
            component = tempComp
          }
          return renderDrupalComponent(component, index)
        })}
      </div>
    </>
  )
}

export default ShareWithUsSuccessPage
