import { createGlobalStyle } from 'styled-components'

const breakPoints = {
  mobile: 375,
  tablet: 768,
  desktop: 1366
}

const GlobalStyles = createGlobalStyle`
:root {
  --font-family-bold: 'AvenirNextBold';
  --font-family-regular: 'AvenirNextRegular';
  --font-family-medium: 'AvenirNextMedium';

  --chatbot-Oops-text: #7E7E7E;

  --color-white: #FFFFFF;
  --color-pills-light-grey: #EFEFEF;
  --color-light-grey: #EDEDED;
  --color-smoke-grey: #F5F5F5;
  --color-dark-grey: #999999;
  --color-header-grey: #f5f5f5;
  --color-rich-black: #333333;
  --color-clear-grey: #030303db;
  --color-vlight-orange: #FEECD8;
  --color-black: #000000;
  --color-bl-blue: #29398C;
  --color-bl-blue-hover: #1A2563;
  --color-light-blue: #AEE0EA;
  --color-light-blue-hover: #9DCAD3;
  --color-bg-blue: #D6EFF4;
  --color-bg-light-blue: #EBF7FA;
  --color-dark-focus-blue: #25358E;
  --color-silver: #CCCCCC;
  --color-validation-red: #C70000;
  --color-validation-red-input: #F3CCCC;
  --color-validation-pink-background: #C700001A;

  --color-dark-orange: #FF4000;
  --color-dark-orange-hover: #CC3300;
  --color-orange: #F96506;
  --color-orange-hover: #C75105;
  --color-bl-orange: #F9A13A;
  --color-bl-orange-hover: #E09134;

  --color-yellow: #FED301;
  --color-yellow-hover: #E5BE01;

  --color-info-blue: #EBF7F9;
  --color-green: #A6CE39;
  --color-green-hover: #95B933;
  --color-light-orange: #f96507;
  --color-dark-gray: #707070;
  --color-light-shade-gray: #efecec;
  --color-primary-white: #f7f7f7;
  --color-medium-shade-gray: #e0e0e0;
  --color-medium-blue: #25358e;
  --color-bg-gradient: linear-gradient(270deg, #F9A13A 0%, #FF4000 100%);
  --color-white-alpha-75: rgba(255, 255, 255, 0.75);
  --color-medium-grey: #666666;
  --header-shadow-color: #00000040;
  --color-box-shadow: #33333340;
  --color-box-shadow-grey: #00000029;
  --color-light-shaded-grey: #ece6e6;
  --color-dark-shaded-orange: #ff4000;
  --color-light-shaded-orange: #f9a13a;
  --breakpoint-mobile: 375px;
  --breakpoint-tablet: 768px;
  --breakpoint-desktop: 1366px;
 
  --caption-font-size: 1.6rem;
  --caption-mobile-font-size: 1.4rem;
  --caption-line-height: 2.4rem;
  --caption-mobile-line-height: 2.1rem;
 
  --p-font-size: 1.8rem;
  --p-line-height: 2.7rem;
 
  --h1-font-size: 5.6rem;
  --h2-font-size: 4.8rem;
  --h3-font-size: 4.0rem;
  --h4-font-size: 3.2rem;
  --h5-font-size: 2.4rem;
  --h6-font-size: 2.4rem;
 
  --h1-mobile-font-size: 4.4rem;
  --h2-mobile-font-size: 4.0rem;
  --h3-mobile-font-size: 3.2rem;
  --h4-mobile-font-size: 2.4rem;
  --h5-mobile-font-size: 1.8rem;
  --h6-mobile-font-size: 1.8rem;
 
  --h1-line-height: 7rem;
  --h2-line-height: 6rem;
  --h3-line-height: 5rem;
  --h4-line-height: 4rem;
  --h5-line-height: 3rem;
  --h6-line-height: 3rem;
 
  --h1-mobile-line-height: 5.0rem;
  --h2-mobile-line-height: 4.6rem;
  --h3-mobile-line-height: 3.8rem;
  --h4-mobile-line-height: 3.0rem;
  --h5-mobile-line-height: 2.4rem;
  --h6-mobile-line-height: 2.4rem;

  --padding-horizontal-mobile: 24px;
  --padding-horizontal-tablet: 48px;
  --padding-horizontal-desktop: 179px;

}

*,
*::before,
*::after {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html {
  font-size: 62.5%;
}

body {
  font-family: "AvenirNextRegular", sans-serif;
  color: var(--color-rich-black);
  transition: color 0.3s, background-color 0.3s;
  min-height: 100vh;
  line-height: 1.5;
  font-size: 1.6rem;
}

*:disabled {
  cursor: not-allowed;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
div,
a {
  margin: 0;
  font-family: 'AvenirNextRegular' !important;
  font-weight: normal !important;
  overflow-wrap: break-word;
}

.pushable {
  overflow-x: inherit;
} 

.pushable > .pusher {
  min-height: 100vh;
}

.ui.grid {
  margin: 0 !important;
}

.inline-orange {
  color: var(--color-orange);
}

.inline-dark-black {
  color: var(--color-rich-black);
}

.inline-dark-blue {
  color: var(--color-bl-blue);
}

.underline-orange {
  color: var(--color-orange);
  text-decoration: underline;
}

.underline-dark-black {
  color: var(--color-rich-black);
  text-decoration: underline;
}

.underline-dark-blue {
  color: var(--color-bl-blue);
  text-decoration: underline;
}

h1 {
  font-size: var(--h1-font-size) !important;
  line-height: var(--h1-line-height) !important;

  @media (max-width: ${breakPoints.tablet - 1}px) {
    font-size: var(--h1-mobile-font-size) !important;
    line-height: var(--h1-mobile-line-height) !important;
  }
}

h2 {
  font-size: var(--h2-font-size) !important;
  line-height: var(--h2-line-height) !important;

  @media (max-width: ${breakPoints.tablet - 1}px) {
    font-size: var(--h2-mobile-font-size) !important;
    line-height: var(--h2-mobile-line-height) !important;
  }
}

h3 {
  font-size: var(--h3-font-size) !important;
  line-height: var(--h3-line-height) !important;

  @media (max-width: ${breakPoints.tablet - 1}px) {
    font-size: var(--h3-mobile-font-size) !important;
    line-height: var(--h3-mobile-line-height) !important;
  }
}

h4 {
  font-size: var(--h4-font-size) !important;
  line-height: var(--h4-line-height) !important;
  @media (max-width: ${breakPoints.tablet - 1}px) {
    font-size: var(--h4-mobile-font-size) !important;
    line-height: var(--h4-mobile-line-height) !important;
  }
}

h5 {
  font-size: var(--h5-font-size) !important;
  line-height: var(--h5-line-height) !important;
  @media (max-width: ${breakPoints.tablet - 1}px) {
    font-size: var(--h5-mobile-font-size) !important;
    line-height: var(--h5-mobile-line-height) !important;
  }
}

h6 {
  font-size: var(--h6-font-size) !important;
  line-height: var(--h6-line-height) !important;
  @media (max-width: ${breakPoints.tablet - 1}px) {
    font-size: var(--h6-mobile-font-size) !important;
    line-height: var(--h6-mobile-line-height) !important;
  }
}

p {
  font-size: var(--p-font-size);
  line-height: var(--p-line-height);
}

ul, ol {
  list-style-position: outside;
  & ul,ol {
  padding-inline-start: .8rem;
  }
  & ul {
    list-style-type: disc;
  }
}

span.caption-text {
  font-size: var(--caption-font-size) !important;
  line-height: var(--caption-line-height) !important;
}

span.caption-14 {
  font-size: var(--caption-mobile-font-size) !important;
  line-height: var(--caption-mobile-line-height) !important;
}

.font-medium{
  font-family: var(--font-family-medium) !important;
}

strong, button, a[role="button"] {
  font-family: 'AvenirNextBold' !important;
}

.text-center {
  text-align: center;
}

.main-container {
  max-width: 100%;
  width: 100%;
  // Mobile
  @media (max-width: ${breakPoints.tablet - 1}px) {
    padding-left: 24px !important;
    padding-right: 24px !important;
  }
  
  // Tablet
  @media (min-width: ${breakPoints.tablet}px) and (max-width: ${breakPoints.desktop - 1}px) {
    padding-left: 48px !important;
    padding-right: 48px !important;
  }
 
  // Desktop
  @media (min-width: ${breakPoints.desktop}px) {
    margin: 0 auto !important;
    max-width: 1008px;
  }
}

.StickyNavbar {
  width: 100%;
  position: sticky !important;
  top: 0 !important;
}

.ui.menu {
    font-size: 1.6rem !important;
}

*::selection {
    background-color: var(--color-light-blue-hover);
    color: var(--color-rich-black);
    -webkit-text-fill-color: var(--color-rich-black);
}
.react-datepicker-wrapper {
  input{ 
    border: 1px solid var(--color-dark-grey);
    font-size: var(--p-font-size);
    font-family: 'AvenirNextRegular' !important;
    padding-left:1.6rem
  }
}
`

export default GlobalStyles
