import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import TagManager from 'react-gtm-module'
import { useTranslation } from 'react-i18next'
import { useLocation, useParams, useNavigate } from 'react-router-dom'
const PageNotFound = importer('Components/PageNotFound')
const LoaderSpinner = importer('Components/Loaders/LoaderSpinner')
/* DYNAMIC IMPORTS */
import importer from '@takedapdt/biolife-core/src/importer'
/* ACTIONS */
const UserActions = importer('Stores/Users/Actions')
const PublicContentActions = importer('Stores/PublicContent/Actions')
const centersActions = importer('Stores/Centers/Actions')
const donorsActions = importer('Stores/Donors/Actions')
const appointmentActions = importer('Stores/Appointments/Actions')
const wirecardActions = importer('Stores/Wirecard/Actions')
const renderDrupalComponent = importer('Constant/renderDrupalComponent')
const BiolifeRewards = importer('Components/BiolifeRewards')
const DashboardContainer = importer('Components/Dashboard/DashboardContainer')
const { getClassFromPathname } = importer('Helpers/Utils', null)
/* CONTAINERS */
const AppContainer = importer('Containers/App')
/* COMPONENTS */
const AppointmentDetails = importer('Components/Dashboard/AppointmentDetails')
// const MobileMenu = importer('Components/Menus/MembersDashboardMenu')
// const DashboardTopBanner = importer('Components/Dashboard/DashboardTopBanner')
// const VerticalMenu = importer('Components/Menus/VerticalMenu')
// const PageContent = importer('Components/PageContent')
import Modal from '../Components/Modal'
const { ROUTES } = importer('NavigationService', null)
import DebitHistoryUnavailablePopup from '../Components/DebitHistoryUnavailablePopup'
import WirecardActions from '@takedapdt/biolife-core/src/Stores/Wirecard/Actions'
import DebitHistoryContainer from '../Components/DebitHistoryContainer'
const PostLoginPageContainer = (props) => {
  const { t, i18n } = useTranslation('', 'FactoryFunctionTexts')

  const {
    user,
    getDonors,
    donor,
    getUpcomingAppointments,
    signOut,
    PostLoginPageData,
    getPostLoginPage,
    PostLoginPageDataLoading,
    PostLoginPageMetaData,
    getDebitCardHistory,
    debitCardHistory,
    debitCardLoading
  } = props
  const { pathname } = useLocation()
  const [dataLoaded, setDataLoaded] = useState(null)
  const [modalOpen, setModalOpen] = useState(true)
  const navigate = useNavigate()
  useEffect(() => {
    if (window.location.pathname !== ROUTES.DebitHistory) {
      sessionStorage.removeItem('filterOnDebit')
    }
    if (window.location.pathname !== ROUTES.PaymentHistory) {
      sessionStorage.removeItem('filterOnPayment')
    }
    getDonors(user && user?.id)
    getUpcomingAppointments(user && user?.sfid)
    setDataLoaded(true)
  }, [window.location.pathname])
  const params = useParams()
  useEffect(() => {
    if (params.segment) {
      getPostLoginPage(`${params.page}/${params.segment}`)
    } else if (params.page && !params.segment) getPostLoginPage(params.page)
    window.scrollTo(0, 0)
  }, [params.page, params.segment])
  /*
   Retrieve i18nextLng language from local storage
   and set it to i18n lanuguage 
   */
  useEffect(() => {
    const i18nextLng = window?.localStorage?.getItem('i18nextLng')
    if (i18nextLng) {
      window?.localStorage?.setItem('originalLng', i18nextLng)
    }
  }, [])

  return (
    <>
      {PostLoginPageDataLoading || (!dataLoaded && !PostLoginPageData) ? (
        <LoaderSpinner />
      ) : PostLoginPageData ? (
        <div className={getClassFromPathname(pathname)}>
          {(() => {
            if (
              donor?.defaultCenter === null &&
              (params.page === 'donor-dashboard' || params.page === 'my-appointments' || params.page === 'debit')
            ) {
              navigate(ROUTES.ChooseDonationCenter)
            }
            if (params.page === 'donor-dashboard') {
              return <DashboardContainer componentList={PostLoginPageData} dashBoardMetadata={PostLoginPageMetaData} />
            } else if (params.page === 'my-appointments' && params.segment === 'appointment-detail') {
              return <AppointmentDetails componentList={PostLoginPageData} metadata={PostLoginPageMetaData} />
            } else if (params.segment === 'debit-history') {
              return <DebitHistoryContainer componentList={PostLoginPageData} metadata={PostLoginPageMetaData} />
            } else {
              return PostLoginPageData?.map((component, index) => {
                if (component?.type === 'breadcrumb') {
                  let tempComp = {
                    ...component,
                    breadcrumbToggle: PostLoginPageMetaData?.field_content_breadcrumb,
                    breadcrumbMobileToggle: PostLoginPageMetaData?.field_mobile_breadcrumb,
                    clickableLevelTwo: ['appointment-history', 'debit-history', 'payment-history', 'reward-history'].includes(
                      params.segment
                    )
                      ? true
                      : undefined
                  }
                  component = tempComp
                }
                return renderDrupalComponent(component, index)
              })
            }
          })()}
        </div>
      ) : (
        <PageNotFound />
      )}
    </>
  )
}

const mapStateToProps = (state) => ({
  session: state.users.session,
  loggedInUser: state.users.user,
  user: state.users.loginHerokuSuccess,
  center: state.centers.getAllCentersSuccess,
  donor: state.donors.donors?.data,
  wirecard: state.wirecard.getWirecardInfoSuccess,
  wirecardError: state.wirecard.getWirecardInfoFailure,
  wirecardLoading: state.wirecard.getWirecardInfoLoading,
  getDonorsSuccess: state?.donors?.getDonorsSuccess,
  parsedDashBoardMenuItems: state.publicContent?.parsedMenuItems?.dashBoard,
  PostLoginPageData: state.publicContent?.getPostLoginPageSuccess?.contents,
  PostLoginPageMetaData: state.publicContent?.getPostLoginPageSuccess?.metadata,
  PostLoginPageDataLoading: state.publicContent?.getPostLoginPageLoading,
  debitCardHistory: state?.wirecard?.getWirecardInfoSuccess,
  debitCardLoading: state?.wirecard?.getWirecardInfoLoading
})

const mapDispatchToProps = (dispatch) => ({
  signOut: () => dispatch(UserActions.logout()),
  getAllCenters: (sfid) => dispatch(centersActions.getAllCenters(sfid)),
  getDonors: (id) => dispatch(donorsActions.getDonors(id)),
  getUpcomingAppointments: (donorSfid) => dispatch(appointmentActions.getUpcomingAppointments(donorSfid)),
  getWirecardInfo: (pdn, startDate, endDate) => dispatch(wirecardActions.getWirecardInfo(pdn, startDate, endDate)),
  inquiryDonor: (pdn) => dispatch(donorsActions.inquiryDonor(pdn)),
  getDonorCentersAll: (searchStr) => dispatch(centersActions.getDonorCentersAll(searchStr)),
  getPostLoginPage: (slug) => dispatch(PublicContentActions.getPostLoginPage(slug)),
  getDebitCardHistory: (pdn, startDate, endDate) => dispatch(WirecardActions.getWirecardInfo(pdn, startDate, endDate))
})

export default connect(mapStateToProps, mapDispatchToProps)(PostLoginPageContainer)
