import React from 'react'
import styled from 'styled-components'
import importer from '@takedapdt/biolife-core/src/importer'
const PrimaryButton = importer('Components/Buttons/PrimaryButton')
const { getCssColorFromDrupalColorType, setTextColor, getMediaFromDrupalMimeType } = importer('Constant/Utils', null)
const { devices } = importer('utils/styledComponentHelpers', null)
const { sanitizeHtml } = importer('utils/htmlHelpers', null)
import { useScreenSize } from '../../../Contexts/ResponsiveContextProvider'

const StyledHeroTemplate7 = styled.div`
  background: ${(props) => getCssColorFromDrupalColorType(props.$bgColor)};
`

const StyledHeroTemplate7Container = styled.div`
  padding: 64px 0 64px 0;
`

const StyledHeroTemplate7Logo = styled.div`
  margin-bottom: 50px;
  ${devices.mobile} {
    margin-bottom: 32px;
    img {
      width: 100%;
    }
  }
`

const StyledHeroTemplate7Content = styled.div`
  display: flex;
  column-gap: 80px;
  padding-right: 70px;
  ${(props) => {
    if (props.$mediaAlignment === 2) {
      return `
                flex-direction: row-reverse;
            `
    }
  }}
  ${devices.mobile} {
    flex-direction: column;
    padding-right: 0;
  }
`

const StyledHeroTemplate7ImageBlock = styled.div`
  width: 50%;
  ${devices.mobile} {
    width: 100%;
  }
  img {
    width: 100%;
    height: 100%;
    border-radius: 25px;
  }
`

const StyledHeroTemplate7TextWithCTABlock = styled.div`
  width: 50%;
  ${devices.mobile} {
    width: 100%;
  }
`

const StyledHeroTemplate7Title = styled.div`
  ${(props) => setTextColor(props.$textColor)};
  ${devices.mobile} {
    margin-top: 24px;
  }
`

const StyledHeroTemplate7ShortDescription = styled.div`
  ${(props) => setTextColor(props.$textColor)};
  margin-top: 24px;
  max-width: 366px;
  ${devices.mobile} {
    margin-top: 16px;
  }
`

const StyledHeroTemplate7ButtonWrapper = styled.div`
  margin-top: 32px;
`

const HeroTemplate7 = (props) => {
  const {
    field_bg_color: componentBackgroundColor,
    field_alignment: componentMediaAlignment,
    field_title_color: componentTitleColor,
    field_component_title: componentTitle,
    field_cta_1: componentCTA1,
    field_cta_1_style: componentCTA1Style,
    field_cta_2: componentCTA2,
    field_cta_2_style: componentCTA2Style,
    field_component_asset: componentMedia,
    field_column_1_icon: componentLogo,
    field_short_description: componentShortDescription,
    field_short_desc_color: componentShortDescriptonColor,
    handleLoyaltyEnrolmentButtonClick
  } = props

  const { isMobileWidth, isTabletWidth } = useScreenSize()

  return (
    <StyledHeroTemplate7 $bgColor={componentBackgroundColor}>
      <div className='main-container'>
        <StyledHeroTemplate7Container>
          <StyledHeroTemplate7Logo>
            {getMediaFromDrupalMimeType(componentLogo?.mimetype, componentLogo?.url, componentLogo?.alt)}
          </StyledHeroTemplate7Logo>
          <StyledHeroTemplate7Content $mediaAlignment={componentMediaAlignment}>
            <StyledHeroTemplate7ImageBlock>
              {getMediaFromDrupalMimeType(componentMedia?.mimetype, componentMedia?.url, componentMedia?.alt)}
            </StyledHeroTemplate7ImageBlock>
            <StyledHeroTemplate7TextWithCTABlock>
              <StyledHeroTemplate7Title
                $textColor={componentTitleColor}
                dangerouslySetInnerHTML={sanitizeHtml(componentTitle?.processed)}
              />
              <StyledHeroTemplate7ShortDescription
                $textColor={componentShortDescriptonColor}
                dangerouslySetInnerHTML={sanitizeHtml(componentShortDescription?.processed)}
              />
              <StyledHeroTemplate7ButtonWrapper>
                {componentCTA1 && (
                  <PrimaryButton
                    text={componentCTA1?.title}
                    style={{ marginRight: '2rem' }}
                    variant={componentCTA1Style}
                    onClick={componentCTA1?.uri?.includes('internal:#modal') ? () => handleLoyaltyEnrolmentButtonClick() : null}
                    sendTo={!componentCTA1?.uri?.includes('internal:#modal') ? componentCTA1?.uri : null}
                    fluid={isMobileWidth}
                  />
                )}
                {componentCTA2 && (
                  <PrimaryButton
                    text={componentCTA2?.title}
                    style={{ marginRight: '2rem' }}
                    variant={componentCTA2Style}
                    onClick={componentCTA2?.uri?.includes('internal:#modal') ? () => handleLoyaltyEnrolmentButtonClick() : null}
                    sendTo={!componentCTA2?.uri?.includes('internal:#modal') ? componentCTA2?.uri : null}
                  />
                )}
              </StyledHeroTemplate7ButtonWrapper>
            </StyledHeroTemplate7TextWithCTABlock>
          </StyledHeroTemplate7Content>
        </StyledHeroTemplate7Container>
      </div>
    </StyledHeroTemplate7>
  )
}

export default HeroTemplate7
