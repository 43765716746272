import styled from 'styled-components'
import importer from '@takedapdt/biolife-core/src/importer'
const { getCssColorFromDrupalColorType } = importer('Constant/Utils', null)
import { devices } from '../../../utils/styledComponentHelpers'
import { ReactComponent as SuccessIcon } from '../../../../assets/media/icons/sign-up-icon-gradient-ui-success.svg'
import { ReactComponent as CollapsedIcon } from '../../../../assets/media/icons/icon-circled-collapsed.svg'
import { ReactComponent as ExpandIcon } from '../../../../assets/media/icons/icon-circled-expand.svg'
import { ReactComponent as Calendar } from '../../../../assets/media/icons/icon-calendar.svg'
import { ReactComponent as Clock } from '../../../../assets/media/icons/icon-clock-orange.svg'

export const StyledConfirmedAppointment = styled.div`
  text-align: center;
`
export const StyledConfirmedAppointmentHeader = styled.div`
  padding: 4.8rem 0 1.6rem;
  position: absolute;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: var(--color-white);
  border-top-left-radius: 2.4rem;
  border-top-right-radius: 2.4rem;
  z-index: 6;

  ${devices.mobile} {
    padding: 4.7rem 0 1.5rem;
  }
`
export const StyledConfirmedAppointmentContent = styled.div`
  width: 66.4rem;
  max-height: 90vh;
  margin: 0 8.6rem;
  padding-top: 14rem;
  padding-bottom: 23.5rem;

  text-align: center;
  overflow-y: scroll;
  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  ${devices.tablet} {
    margin: 0 8vw;
  }
  ${devices.mobile} {
    width: 34.3rem;
    margin: 0 1.5rem;
    padding-top: 13rem;
    padding-bottom: 32rem;
  }
`
export const StyledConfirmedAppointmentContentTitle = styled.div`
  font-size: var(--h4-font-size);
  color: var(--color-orange);
  padding-bottom: 2.4rem;
`
export const StyledConfirmedAppointmentContentAppointmentDetails = styled.div`
  padding: 2.4rem 17.2rem;
  border-top: 0.1rem solid var(--color-light-grey);
  ${devices.mobile} {
    padding: 2.4rem 0;
  }
`
export const StyledConfirmedAppointmentContentCenter = styled.div`
  font-size: var(--h5-font-size);
  color: var(--color-bl-blue);
`
export const StyledConfirmedAppointmentContentCenterAddress = styled.div`
  font-size: var(--caption-mobile-font-size);
  padding-top: 0.4rem;
  /* padding-bottom: 1.6rem; */
`
export const StyledConfirmedAppointmentContentDateTime = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.8rem;
  justify-content: center;
  align-items: center;
  padding-top: 0.8rem;
  font-size: var(--h5-font-size);
`
export const StyledConfirmedAppointmentContentDateTimeButton = styled.div`
  padding-top: 0.8rem;
  button {
    font-size: var(--caption-mobile-font-size) !important;
  }
  position: relative;
`
export const StyledUList = styled.ul`
  ${(props) => {
    if (props.$calendarisOpen) {
      return `
      padding: 5px 0 5px 8px;
      font-size: var(--caption-font-size);
      box-shadow: 1px 3px 6px rgba(0, 0, 0, 0.15);
      border: 1px solid var(--color-bl-blue);
      background-color: #fff;
      text-align: center;
      width: 50%;
      margin: 0 auto;
      opacity: 1;
      font-family: $font-family;
      color: var(--color-bl-blue) !important;
      list-style: none;`
    }
    if (!props.$calendarisOpen) {
      return `
      opacity: 0;
      position: absolute;
      visibility: hidden;
      `
    }
  }}
`

export const StyledList = styled.li``
export const StyledCalendarLink = styled.a`
  color: var(--color-bl-blue) !important;
`
export const StyledAppointmentChecklistAccordion = styled.div`
  padding: 1.6rem 0;
  border-top: 0.1rem solid var(--color-light-grey);
`
export const StyledAppointmentChecklistAccordionTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
export const StyledAppointmentChecklistAccordionContent = styled.div`
  font-size: var(--caption-font-size);
  padding-top: 1.6rem;
  display: ${(props) => !props.$expanded && 'none'};
  text-align: left;
  & > :not(:first-child) {
    padding-top: 1.6rem;
  }
`
export const StyledNewDonorBonusAccordion = styled.div`
  padding: 1.6rem 0;
  border-top: 0.1rem solid var(--color-light-grey);
`
export const StyledNewDonorBonusAccordionTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
export const StyledNewDonorBonusAccordionContent = styled.div`
  font-size: var(--caption-font-size);
  padding-top: 1.6rem;
  display: ${(props) => !props.$expanded && 'none'};
  text-align: left;
  & > :not(:first-child) {
    padding-top: 1.6rem;
  }
`
export const StyledIcon = styled(SuccessIcon)`
  width: 6rem;
  height: 6rem;
`
export const StyledExpandIcon = styled(ExpandIcon)`
  width: 1.6rem;
  height: 1.6rem;
  &:hover path {
    fill: red;
  }
`
export const StyledCollapsedIcon = styled(CollapsedIcon)`
  width: 1.6rem;
  height: 1.6rem;
  &:hover path {
    fill: red;
  }
`
export const StyledButtonsContainer = styled.div`
  display: flex;
  position: absolute;
  bottom: 0;
  flex-direction: column;
  gap: 2.4rem;
  padding: 1.4rem 24.7rem 4.8rem;
  justify-content: center;
  align-items: center;
  z-index: 6;
  background: var(--color-white);
  margin: 0 !important;
  border-bottom-left-radius: 2.4rem;
  border-bottom-right-radius: 2.4rem;
  width: 100%;
  ${devices.tablet} {
    padding: 1.4rem 22.7rem 4.8rem;
  }
  ${devices.mobile} {
    padding: 2.4rem 1.5rem 4.8rem;
    box-shadow: 0rem -0.4rem 0.8rem var(--color-box-shadow-grey);
    gap: 3.2rem;
    position: absolute;
    bottom: 0;
  }
`
export const StyledCalendarIcon = styled(Calendar)`
  width: 1.6rem;
  height: 1.6rem;
`
export const StyledClockIcon = styled(Clock)`
  width: 1.6rem;
  height: 1.6rem;
`
export const TextComponent = styled.p`
  color: ${({ $color }) => getCssColorFromDrupalColorType($color)};
  font-size: ${({ $fontSize }) => $fontSize};
  font-family: ${({ $fontFamily }) => ($fontFamily ? `${$fontFamily} !important` : 'AvenirNextBold !important')};
  margin-top: ${({ $marginBottom }) => $marginBottom};
  margin-bottom: ${({ $marginBottom }) => $marginBottom};
  padding-right: ${({ $paddingRight }) => $paddingRight};
  border-right: ${({ $borderRight }) => $borderRight};
  text-decoration: ${({ $textDecoration }) => $textDecoration || 'none'};
`
