import React from 'react'
import { KababOptionsContainer, KababOptionText, KababOption } from './chatbotoptions.styled'
function KababChatbot({ options = [] }) {
  return (
    <KababOptionsContainer>
      {options &&
        options.length &&
        options.map((option) => {
          return (
            <KababOption onClick={() => option.onClicke(true)}>
              <KababOptionText>{option.text}</KababOptionText>
            </KababOption>
          )
        })}
    </KababOptionsContainer>
  )
}

export default KababChatbot
