import React from 'react'
import './styles.scss'
import importer from '@takedapdt/biolife-core/src/importer'
import renderDrupalComponent from '../../Constant/renderDrupalComponent'

const SignUp = (props) => {
  return (
    <div className='SignUpContainer'>
      {props?.signUpPageContents?.map((component, index) => {
        return renderDrupalComponent(component, index)
      })}
    </div>
  )
}

export default SignUp
