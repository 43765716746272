import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import importer from '@takedapdt/biolife-core/src/importer'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { getCssColorFromDrupalColorType } from '../../../Constant/Utils'
const LoyaltyDonorActions = importer('Stores/Loyalty/Actions')
const { sanitizeHtml } = importer('utils/htmlHelpers', null)
const PrimaryButton = importer('Components/Buttons/PrimaryButton')
const { devices, sizes } = importer('utils/styledComponentHelpers', null)
const RewardsContainer = styled.div`
  background-color: ${(props) => getCssColorFromDrupalColorType(props?.$recentRewardBGColor)};
  padding-bottom: 4.8rem;
  ${devices.mobile} {
    padding-bottom: 6.4rem;
  }
  padding-top: 6.4rem;
  border-radius: 0.8rem;
`
const Title = styled.h2`
  padding-bottom: 0.8rem;
`
const ActivityList = styled.ul`
  list-style: none;
  padding: 0;
`
const ActivityItemContainer = styled.li`
  display: flex;
  justify-content: space-between;
  padding-top: 2.4rem;
  padding-bottom: 2.4rem;
  border-bottom: 1px solid #ccc;
`
const ActivityItem = styled.div``

const ItemTitle = styled.p`
  font-weight: bold;
  display: flex;
  color: var(--color-rich-black);
  padding-bottom: 0.8rem;
  font-size: var(--p-font-size) !important;
`
const Date = styled.span`
  color: var(--color-rich-black);
  padding-bottom: 0.4rem;
`
const Points = styled.span`
  color: var(--color-rich-black);
  font-size: 1.6rem;
  ${devices.mobile} {
    text-align: right;
  }
`
const Button = styled.div`
  border: none;
  padding-top: 4.8rem;
  text-align: center;
`
const DateToDisplay = styled.div`
  display: flex;
  flex-direction: column;
`

const RecentRewardActivity = ({
  field_component_title: recentRewardComponentTitle,
  field_title_color: recentRewardTitleColor,
  field_integration_type: recentIntegrationType,
  field_cta_1: recentCTAOne,
  field_cta_1_style: recentCTAOneStyle,
  field_bg_color: recentRewardBGColor,
  donor,
  getLoyaltyActivity,
  loyaltyActivity,
  loyaltyActivityLoading
}) => {
  const { t, i18n } = useTranslation('RewardsCard')
  const [recentRewardsHistoryData, setRecentRewardsHistoryData] = useState([])
  useEffect(() => {
    if (donor?.pdn && donor?.isLoyaltyMember === 'Yes') {
      getLoyaltyActivity(donor.pdn)
    }
  }, [donor])
  useEffect(() => {
    if (loyaltyActivity) {
      const transformedRewardsHistoryData = []
      loyaltyActivity?.transactions?.slice(0, 5).map((item) => {
        transformedRewardsHistoryData.push({
          ...item,
          activityType: i18n.language === 'es-MX' ? item.activityTypeSpanish : item.activityType,
          transDesc: i18n.language === 'es-MX' ? item.transDescSpanish : item.transDesc
        })
      })
      setRecentRewardsHistoryData(transformedRewardsHistoryData)
    }
  }, [i18n.language, loyaltyActivity])
  /* 
  checking for if donor default center is loyalty program enabled or not 
  checking if donor is loyalty enrolled member and loyalty center enabled and based on integration type 
  we are showing recent reward component
*/
  /* 
 in recent reard history data we are taking first five items 
 based on activity type we are chaging date formate 
*/
  const isLoyaltyEnabled = donor?.defaultCenter?.isLoyaltyProgramEnabled || false
  return (
    <>
      {donor?.isLoyaltyMember === 'Yes' &&
        (isLoyaltyEnabled || !isLoyaltyEnabled) &&
        recentIntegrationType === 'recent_reward_activities' &&
        (recentRewardsHistoryData && recentRewardsHistoryData.length > 0 ? (
          <RewardsContainer $recentRewardBGColor={recentRewardBGColor}>
            <div className='main-container'>
              <Title $textColor={recentRewardTitleColor} dangerouslySetInnerHTML={sanitizeHtml(recentRewardComponentTitle?.value)} />
              <ActivityList>
                {recentRewardsHistoryData?.map((item, index) => (
                  <ActivityItemContainer key={index}>
                    <ActivityItem>
                      <ItemTitle>
                        <strong>{item.transDesc}</strong>
                      </ItemTitle>
                      <DateToDisplay>
                        {/* <Date>
                          {item.activityType === 'Redeemed' || item.activityType === 'Added'
                            ? moment(item.activityDate).format('MMMM DD')
                            : null}
                        </Date> */}
                        {item.activityType && (
                          <Date>
                            {item.activityType} {moment(item.activityDate).format('MMMM DD, YYYY')}
                          </Date>
                        )}
                        {item.expiryDate && (
                          <Date style={{ fontStyle: 'italic' }}>
                            {t('Expires')} {moment(item.expiryDate).format('MMMM DD, YYYY')}
                          </Date>
                        )}
                        {/* <Date style={{ fontStyle: item.activityType === 'Awarded' ? '' : 'italic' }}>{` ${item.dateToDisplay}`}</Date> */}
                      </DateToDisplay>
                    </ActivityItem>
                    <Points>
                      <strong>
                        {item.transOperation === 'Credit' ? `+ ${item.points}` : `- ${item.points}`} {t('points')}
                      </strong>
                    </Points>
                  </ActivityItemContainer>
                ))}
              </ActivityList>
              {recentCTAOne ? (
                <Button>
                  <PrimaryButton sendTo={recentCTAOne?.uri} text={recentCTAOne?.title} variant={recentCTAOneStyle} />
                </Button>
              ) : null}
            </div>
          </RewardsContainer>
        ) : null)}
    </>
  )
}

const mapStateToProps = (state) => ({
  donor: state.donors?.donors?.data,
  loyaltyActivity: state.loyalty?.getLoyaltyActivity,
  loyaltyActivityLoading: state.loyalty?.getLoyaltyActivityLoading
})
const mapDispatchToProps = (dispatch) => ({
  getLoyaltyActivity: (pdn) => dispatch(LoyaltyDonorActions.getLoyaltyActivity(pdn))
})

export default connect(mapStateToProps, mapDispatchToProps)(RecentRewardActivity)
