import React, { useRef } from 'react'
import { useSelector } from 'react-redux'
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { ReactComponent as NextArrow } from '../../../assets/media/icons/circle-arrow-right.svg'
import { ReactComponent as PrevArrow } from '../../../assets/media/icons/circle-arrow-left.svg'
import './styles.scss'
import importer from '@takedapdt/biolife-core/src/importer'
const { useScreenSize } = importer('Contexts/ResponsiveContextProvider', null)
const LoaderBar = importer('Components/Loaders/LoaderBar')
const TextComponent = importer('Components/TextComponent')
const CenterCard = importer('Components/CenterCard')

const SliderNextArrow = ({ className, onClick }) => {
  return <NextArrow width='100' height='100' className={className} onClick={onClick} />
}
  
const SliderPrevArrow = ({ className, onClick }) => {
  return <PrevArrow width='50' height='50' className={className} onClick={onClick} />
}

const CentersCarousel = ({selectedCenter, centers, handleLocationChange, input, componentData}) => {
  const slider = useRef(null)
  const getDonorCentersAllLoading = useSelector((state) => state.centers.getDonorCentersAllLoading)
  const { isMobileWidth, isTabletWidth } = useScreenSize()
  var settings = {
    dots: true,
    intialSlide: 1,
    infinite: true,
    speed: 800,
    slidesToShow: 3,
    slidesToScroll: 3,
    arrows: isMobileWidth || isTabletWidth ? false : true,
    nextArrow: <SliderNextArrow />,
    prevArrow: <SliderPrevArrow />,
    responsive: [
      {
        breakpoint: 1422,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 1366,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 1080,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        },
      }
    ]
  }
  
  return (
    <div className='CentersCarouselContainer'>
      <TextComponent {...componentData} />
      {getDonorCentersAllLoading ? <LoaderBar /> :
        <div className={`CentersCarousel ${centers?.length <= 3 ? 'LessDataWrapper' : ''}`}>
          <Slider ref={slider} {...settings}>
            {centers?.map((center) => (
              <CenterCard 
                key={center.id}
                onCenterCardClick={() => {
                  handleLocationChange(center)
                  input && input.onChange(center.centerNum)
                }}
                isSelected={selectedCenter === center.centerNum}
                centerData={center}
              />
            ))}
          </Slider>
        </div>
      }
    </div>
  )
}

export default CentersCarousel