import styled, { keyframes } from 'styled-components'
import { devices } from '../../utils/styledComponentHelpers'

export const MainChatbotContainer = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 10000;
  position: fixed;
  bottom: 3%;
  right: 6%;
  display: ${({ $showHideChatbot }) => ($showHideChatbot ? 'block' : 'none')};
  ${devices.tablet} {
    right: 7%;
  }
  ${`@media screen and (min-width: ${768}px) and (max-width: ${1000}px)`} {
    right: 10% !important;
    bottom: 2%;
  }
  ${devices.mobile} {
    bottom: 0%;
    right: 0%;
    z-index: 3147483646;
    bottom: ${({ $chatOpen }) => {
      return $chatOpen ? '0%' : '1%'
    }};
    right: ${({ $chatOpen }) => ($chatOpen ? '0%' : '1%')};
  }
`

export const ChatBox = styled.div`
  width: 53rem;
  height: 72rem;
  display: ${({ $display }) => $display};
  box-shadow: 2px 3px 10px #00000029;
  overflow: hidden;
  border-radius: 22px;

  ${devices.tablet} {
    // handle height of chatbot on windows
    height: 62rem;
  }

  ${devices.mobile} {
    border-radius: 0px 0px 0px 0px;
    width: ${({ $innerWidth }) => {
      return ($innerWidth && `${$innerWidth}px`) || '100%'
    }};
    height: ${({ $innerHeight }) => ($innerHeight ? `calc(${$innerHeight}px - 10vh)` : '70vh')};
  }

  ${`@media screen and (orientation:landscape) and (max-height:700px)`} {
    height: ${({ $innerHeight }) => ($innerHeight ? `calc(${$innerHeight}px - 10vh)` : '70vh')};
  }
`

export const ChatbotMainContent = styled.div`
  position: relative;
`

export const BlueDot = styled.div`
  background-color: var(--color-validation-red);
  border: 2px solid var(--color-white);
  border-radius: 16px;
  width: 16px;
  height: 16px;
  position: absolute;
  top: 0;
  right: 0;
`

const slideaway = keyframes`
  from { display: none; }
  to {  display: block;}
`

export const PopupImageContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  border: 2px solid var(--color-light-orange);
  align-items: center;
  height: 25%;
  background-color: var(--color-white);
  box-shadow: 0px 6px 6px #00000033;
  border-radius: 40px;
  overflow: hidden;
  cursor: pointer;
  padding: 1rem;
  &:hover > #askUs {
    display: block;
  }
`

export const PopupImage = styled.img`
  height: 3rem;
  width: 3rem;
  cursor: pointer;
`
export const ModalContainer = styled.div`
  position: relative;
  height: 100%;
`

export const StyledSpan = styled.span`
  display: none;
  font-family: var(--font-family-medium) !important;
  color: var(--color-light-orange);
  margin-left: 0.8rem;
  animation: ${slideaway} 1s linear 10s;
  animation-iteration-count: 1;
  ${devices.mobile} {
    display: block;
  }
`

export const StyledImage = styled.img`
  height: 16px;
  width: 16px;
`
