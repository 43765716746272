import React, { useEffect } from 'react'
import './styles.scss'
import { Grid } from 'semantic-ui-react'
import './styles.scss'
import importer from '@takedapdt/biolife-core/src/importer'

const { useScreenSize } = importer('Contexts/ResponsiveContextProvider', null)
const PrimaryButton = importer('Components/Buttons/PrimaryButton')
const { getCssColorFromDrupalColorType, setTextColor, getMediaFromDrupalMimeType } = importer('Constant/Utils', null)

const GridTextImage = (props) => {
  const {
    field_component_title: componentTitle,
    field_title_color: titleColor,
    field_short_description: componentShortDescription,
    field_short_desc_color: shortDescriptionColor,
    field_component_asset: componentMedia,
    field_alignment: mediaAlignment,
    field_asset_link: mediaRedirectLink,
    field_column_1_icon: columnIcon1,
    field_column_1_title: columnTitle1,
    field_column_2_icon: columnIcon2,
    field_column_2_title: columnTitle2,
    field_column_3_icon: columnIcon3,
    field_column_3_title: columnTitle3,
    field_column_title_color: columnTitleColor,
    field_column_desc_color: columnDescriptionColor,
    field_bg_color: componentBackgroundColor,
    field_component_desc: componentColumnDescription,
    field_cta_1: cta1,
    field_cta_1_style: ctaOneStyle,
    field_alignment_2: ctaAlignment,
    field_long_desc_color: longDescriptionColor
  } = props
  const { isMobileWidth, isTabletWidth } = useScreenSize()

  const ProcessedPoints = [
    columnTitle1 && {
      columnIcon: columnIcon1,
      columnTitle: columnTitle1
    },
    columnTitle2 && {
      columnIcon: columnIcon2,
      columnTitle: columnTitle2
    },
    columnTitle3 && {
      columnIcon: columnIcon3,
      columnTitle: columnTitle3
    }
  ]

  const ProcessedPointsGrid = (columnIcon, columnTitle) => {
    return (
      <Grid.Row columns={2} className='DonationProcessPoints'>
        <Grid.Column className='DonationProcessPointsIcon' verticalAlign='top' style={{ ...setTextColor(columnDescriptionColor) }}>
          {getMediaFromDrupalMimeType(columnIcon?.mimetype, columnIcon?.url, columnIcon?.alt)}
        </Grid.Column>
        <Grid.Column
          verticalAlign='middle'
          dangerouslySetInnerHTML={{ __html: columnTitle?.processed }}
          style={{ ...setTextColor(columnTitleColor) }}
          className='DonationProcessPointsTitle'
        />
      </Grid.Row>
    )
  }

  return (
    <div
      className='DonationProcess'
      style={{
        background: `${getCssColorFromDrupalColorType(componentBackgroundColor)}`
      }}
    >
      <div className='main-container'>
        <Grid className='DonationProcessContainer'>
          {(componentTitle || componentShortDescription) && (
            <Grid.Row computer={8} tablet={8} mobile={16} verticalAlign='middle' className='DonationProcessContentContainer'>
              <Grid.Row
                className='DonationProcessTitleContainer'
                style={{ ...setTextColor(titleColor) }}
                dangerouslySetInnerHTML={{ __html: componentTitle?.processed }}
              />
              <Grid.Row
                style={{ ...setTextColor(shortDescriptionColor) }}
                className='DonationProcessDescriptionContainer'
                dangerouslySetInnerHTML={{ __html: componentShortDescription?.processed }}
              />
            </Grid.Row>
          )}

          {isTabletWidth || isMobileWidth ? (
            <Grid.Row
              columns={2}
              className={`DonationProcessGridColumn ${mediaAlignment === 1 ? 'Reversed' : ''} ${ctaAlignment === 3 ? 'TopAligned' : ''}`}
            >
              <Grid.Column className='DonationProcessPointsContainer' tablet={16} mobile={16}>
                {ProcessedPoints?.map((item) => item && ProcessedPointsGrid(item?.columnIcon, item?.columnTitle))}

                <div
                  style={{ ...setTextColor(longDescriptionColor) }}
                  className='DonationProcessDescriptionContainer'
                  dangerouslySetInnerHTML={{ __html: componentColumnDescription?.processed }}
                />
              </Grid.Column>

              <Grid.Column tablet={16} mobile={16} className='DonationProcessImgContainer' verticalAlign='top'>
                <a
                  href={mediaRedirectLink?.uri?.includes('internal:') ? mediaRedirectLink?.uri?.split(':')[1] : mediaRedirectLink?.uri}
                  target={mediaRedirectLink?.uri?.includes('internal:') ? '_self' : '_blank'}
                >
                  <div className='backdrop'></div>
                  {getMediaFromDrupalMimeType(componentMedia?.mimetype, componentMedia?.url, componentMedia?.alt)}
                </a>
              </Grid.Column>
            </Grid.Row>
          ) : (
            <Grid.Row
              columns={2}
              className={`DonationProcessGridColumn ${mediaAlignment === 1 ? 'Reversed' : ''} ${ctaAlignment === 3 ? 'TopAligned' : ''}`}
            >
              <Grid.Column className='DonationProcessPointsContainer' computer={ctaAlignment === 3 ? 16 : 8}>
                {ProcessedPoints?.map((item) => item && ProcessedPointsGrid(item?.columnIcon, item?.columnTitle))}

                <div
                  style={{ ...setTextColor(longDescriptionColor) }}
                  className='DonationProcessDescriptionContainer'
                  dangerouslySetInnerHTML={{ __html: componentColumnDescription?.processed }}
                />
              </Grid.Column>

              <Grid.Column computer={ctaAlignment === 3 ? 16 : 8} className='DonationProcessImgContainer' verticalAlign='top'>
                <a
                  href={mediaRedirectLink?.uri?.includes('internal:') ? mediaRedirectLink?.uri?.split(':')[1] : mediaRedirectLink?.uri}
                  target={mediaRedirectLink?.uri?.includes('internal:') ? '_self' : '_blank'}
                  aria-label={mediaRedirectLink?.uri?.includes('internal:') ? null : mediaRedirectLink?.title}
                >
                  <div className='backdrop'></div>
                  {getMediaFromDrupalMimeType(componentMedia?.mimetype, componentMedia?.url, componentMedia?.alt)}
                </a>
              </Grid.Column>
            </Grid.Row>
          )}
          {cta1 && (
            <Grid.Row className='ButtonContainer'>
              <PrimaryButton
                text={cta1?.title}
                className='DonationProcessBtn'
                variant={ctaOneStyle}
                sendTo={cta1?.uri}
                fluid={isMobileWidth}
              />
            </Grid.Row>
          )}
        </Grid>
      </div>
    </div>
  )
}

export default GridTextImage
