import React from 'react'
import { EndChatContainer, EndChatDiv, H4Text, Desc, ButtonsDiv } from './endChatpopup.styled'
import PrimaryButton from '../../../Buttons/PrimaryButton'

function EndChatpopup({ closeFunction, closeChatbotAndReset, selectedLanguage }) {
  const isSpanish = selectedLanguage === 'Español';
  const endChatText = isSpanish ? 'Finalizer chat' : 'End Chat';
  const continueChatText = isSpanish ? 'Continuar chat' : 'Continue Chat';
  const endChatDesc = isSpanish 
    ? '¿Estás seguro de que quieres finalizar el chat? Esta acción no se puede deshacer.' 
    : 'Are you sure you want to end the chat? This action cannot be undone.';
  return (
    <EndChatContainer>
      <EndChatDiv selectedLanguage={selectedLanguage}>
        <H4Text>{endChatText}</H4Text>
        <Desc>{endChatDesc}</Desc>
      </EndChatDiv>
      <ButtonsDiv>
        <PrimaryButton text={endChatText} fluid onClick={() => closeChatbotAndReset()} />
        <PrimaryButton variant='blue_underline' text={continueChatText} fluid onClick={closeFunction} />
      </ButtonsDiv>
    </EndChatContainer>
  )
}

export default EndChatpopup