import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'
import styled from 'styled-components'
import renderDrupalComponent from '../../Constant/renderDrupalComponent'
import { devices } from '../../utils/styledComponentHelpers'
import { SLUG_TYPES } from '@takedapdt/biolife-core/src/Sagas/Constants/drupalConstants'
import { getPathDetails } from '../../Helpers/Utils'
import PublicContentActions from '@takedapdt/biolife-core/src/Stores/PublicContent/Actions'
import LoaderSpinner from 'Components/Loaders/LoaderSpinner'

const DashboardMessageSection = styled.div`
  padding-left: 180px;
  padding-right: 178px;

  ${devices.tablet} {
    padding-left: 48px;
    padding-right: 48px;
  }

  ${devices.mobile} {
    padding-left: 24px;
    padding-right: 24px;
  }
  .paddedRegion {
    margin: 0 !important;
    padding-left: 0px !important;
  }
`
const DashboardMessagesContainer = ({ PostLoginPageData, PostLoginPageMetaData, PostLoginPageDataLoading, getPostLoginPage }) => {
  useEffect(() => {
    getPostLoginPage(getPathDetails.slugType || SLUG_TYPES?.MESSAGES)
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
      <Helmet>
        <title>{PostLoginPageMetaData?.meta_title}</title>
        <meta name='description' content={PostLoginPageMetaData?.meta_description} />
        <link rel='canonical' href={`${window.location.origin}${window.location.pathname.toLowerCase()}`} />
      </Helmet>
      {PostLoginPageDataLoading ? (
        <LoaderSpinner />
      ) : (
        <DashboardMessageSection>
          {PostLoginPageData?.map((component, index) => {
            if (component?.type === 'breadcrumb') {
              let tempComp = {
                ...component,
                breadcrumbToggle: PostLoginPageMetaData?.field_content_breadcrumb,
                breadcrumbMobileToggle: PostLoginPageMetaData?.field_mobile_breadcrumb
              }
              component = tempComp
              return renderDrupalComponent(component, index)
            }
            return renderDrupalComponent(component, index)
          })}
        </DashboardMessageSection>
      )}
    </>
  )
}

const mapStateToProps = (state) => ({
  PostLoginPageData: state.publicContent?.getPostLoginPageSuccess?.contents,
  PostLoginPageMetaData: state.publicContent?.getPostLoginPageSuccess?.metadata,
  PostLoginPageDataLoading: state.publicContent?.getPostLoginPageLoading
})

const mapDispatchToProps = (dispatch) => ({
  getPostLoginPage: (slug) => dispatch(PublicContentActions.getPostLoginPage(slug))
})

export default connect(mapStateToProps, mapDispatchToProps)(DashboardMessagesContainer)
