import { isEmpty } from 'lodash'

const validate = (values) => {
  const errors = {}

  if (isEmpty(values.firstname)) {
    errors.firstname = 'firstNameIsRequired'
  } else if (values.firstname.length < 2) {
    errors.firstname = 'Too Short'
  }

  if (isEmpty(values.lastname)) {
    errors.lastname = 'lastNameIsRequired'
  }

  if (isEmpty(values.email)) {
    errors.email = 'emailIsRequired'
  } else if (
    values.email
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      ) == null
  ) {
    errors.email = 'enterAValidEmail'
  }

  if (isEmpty(values.center)) {
    errors.center = 'centerIsRequired'
  }
  if (isEmpty(values.state)) {
    errors.state = 'stateIsRequired'
  }
  if (isEmpty(values.status)) {
    errors.status = 'pleaseSelectAStatus'
  }

  if (isEmpty(values.message)) {
    errors.message = 'messageIsRequired'
  }

  if (values.password) {
    if (values.password.length < 8) {
      errors.password = 'passwordShouldHaveAtLeast8Char'
    }
    if (!values.password.match(/[A-Z]/)) {
      errors.password = 'uppercaseRequired'
    }
    if (!values.password.match(/[0-9]/)) {
      errors.password = 'numbersRequired'
    }
    if (!values.password.match(/[a-z]/)) {
      errors.password = 'lowercaseRequired'
    }
  } else {
    errors.password = 'passwordIsRequired'
  }

  return errors
}

export default validate
