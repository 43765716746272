import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import importer from '@takedapdt/biolife-core/src/importer'
import { Helmet } from 'react-helmet'
const ContactUs = importer('Components/ContactUs')
const PublicContentActions = importer('Stores/PublicContent/Actions')
const { getArrayFromTextCTAGrid } = importer('Helpers/Utils', null)

const ContactUsRoute = ({ fetchFormsData, FormsData }) => {
  const [finalData, setFinalData] = useState({})

  useEffect(() => {
    fetchFormsData('about-biolife/contact-us')
  }, [])

  useEffect(() => {
    if (FormsData) {
      const dataWithTextCTAGrid = getArrayFromTextCTAGrid(FormsData)
      if (Object.keys(dataWithTextCTAGrid)?.length) {
        setFinalData(dataWithTextCTAGrid)
      }
    }
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })

  }, [FormsData])
 
  return (
    <>
      <Helmet>
        <title>{`${FormsData?.metadata?.meta_title}`} </title>
        <meta name='description' content={`${FormsData?.metadata?.meta_description}`} />
        <link rel='canonical' href={`${window.location.origin}${window.location.pathname.toLowerCase()}`} />
      </Helmet>

      <ContactUs ContactUsContent={finalData}></ContactUs>
      
    </>
  )
}

const mapStateToProps = (state) => ({
  FormsData: state.publicContent?.getFormsPageSuccess?.['about-biolife/contact-us']
})

const mapDispatchToProps = (dispatch) => ({
  fetchFormsData: (formType) => dispatch(PublicContentActions.getFormsPage(formType))
})

export default connect(mapStateToProps, mapDispatchToProps)(ContactUsRoute)
