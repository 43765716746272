import React, { useState, useEffect, createContext, useContext } from 'react'

const mobileMaxWidth = 768;
const tabletMaxWidth = 1366;

export const ResponsiveContext = createContext(window.innerWidth < mobileMaxWidth)

// ResponsiveContextProvider component to provide the context value
export const ResponsiveContextProvider = ({ children }) => {
  const [isMobileWidth, setIsMobileWidth] = useState(window.innerWidth < mobileMaxWidth)
  const [isTabletWidth, setIsTabletWidth] = useState(window.innerWidth < tabletMaxWidth && window.innerWidth >= mobileMaxWidth)

  // Update the screen size and isMobileWidth and isTabletWidth when the window is resized
  useEffect(() => {
    function handleResize() {
      if (window.innerWidth < mobileMaxWidth) {
        setIsMobileWidth(true)
        setIsTabletWidth(false)
      } else if (window.innerWidth < tabletMaxWidth && window.innerWidth >= mobileMaxWidth) {
        setIsMobileWidth(false)
        setIsTabletWidth(true)
      } else {
        setIsMobileWidth(false)
        setIsTabletWidth(false)
      }
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  // Provide default values in case the state is not set yet
  const contextValue = {
    isMobileWidth: isMobileWidth !== undefined ? isMobileWidth : false,
    isTabletWidth: isTabletWidth !== undefined ? isTabletWidth : false,
  };

  return <ResponsiveContext.Provider value={contextValue}>{children}</ResponsiveContext.Provider>
}

// Custom hook to access screen size and check if isMobileWidth, isTabletWidth from the context
export function useScreenSize() {
  return useContext(ResponsiveContext)
}