import React, { useState, useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import { filterNonNomogramCenters } from '../../../Helpers/Utils'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { useTranslation } from 'react-i18next'
import { useScreenSize } from '../../../Contexts/ResponsiveContextProvider'
import {
  StyledAlternateCentersContainer,
  StyledAlternateCentersMessage,
  StyledAlternateCentersAccordionTitle,
  StyledAlternateCentersAccordionContent,
  StyledAlternateCentersCarousel,
  StyledCollapsedIcon,
  StyledExpandIcon,
  StyledAlternateCentersDisclaimerMessage,
  StyledLoadingState,
  StyledSliderPrevArrow,
  StyledSliderNextArrow,
  StyledTnC
} from './styled'
import LoaderBar from '../../Loaders/LoaderBar'
import CenterCard from '../../CenterCard'
import centersActions from '@takedapdt/biolife-core/src/Stores/Centers/Actions'

const AlternativeCenters = (props) => {
  const {
    loggedInUser,
    donor,
    appointment,
    alternateCenters,
    handleAppointment,
    getSlotsForAppointmentType,
    getAlternateDonorCenters,
    center,
    centerSlotsFailure,
    alternateCentersLoading,
    homeCenter,
    getAllCenters
  } = props
  const [selectedLocationCenterNum, setSelectedLocationCenterNum] = useState(null)
  const [selectedLocationSfid, setSelectedLocationSfid] = useState(null)
  const [nomoFilteredAlternateCenters, setNomoFilteredAlternateCenters] = useState()
  const [expandButton, setExpandButton] = useState(false)
  const { t } = useTranslation('alternateCenters')
  const slider = useRef(null)
  const { isMobileWidth, isTabletWidth } = useScreenSize()
  const todayDate = moment().format('YYYY-MM-DDT00:00:00')
  const isCenterClosed = centerSlotsFailure?.code === 'CenterIsClosedPotentialAppointmentDateRule'
  var settings = {
    dots: true,
    intialSlide: 1,
    infinite: true,
    speed: 800,
    slidesToShow: 3,
    slidesToScroll: 3,
    arrows: isMobileWidth || isTabletWidth ? false : true,
    nextArrow: <StyledSliderNextArrow />,
    prevArrow: <StyledSliderPrevArrow />,
    responsive: [
      {
        breakpoint: 1080,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  }
  const handleLocationChange = (value) => {
    setSelectedLocationCenterNum(value?.centerNum)
    setSelectedLocationSfid(value?.sfid)
    getSlotsForAppointmentType(todayDate, null, null, 0, value?.sfid, appointment?.appointmentType, null, donor?.sfid)
    handleAppointment({
      ...appointment,
      center: value?.name,
      centerCity: value?.city,
      centerName: value?.name,
      centerSfid: value?.sfid,
      name: value?.name,
      key: value?.sfid,
      zipCode: value?.zipcode,
      text: `${value?.name} Center ${value?.zipcode}`,
      address: value?.addressLine1,
      stateCode: value?.stateCode,
      telephone: value?.telephone,
      startDateTime: appointment.startDateTime
        ? moment
            .parseZone(appointment.startDateTime)
            .startOf('day')
            .format('YYYY-MM-DDT00:00:00')
        : moment().format('YYYY-MM-DDT00:00:00'),
      startTime: null,
      alternateCenterSelected: isCenterClosed
    })
    getAllCenters(value?.sfid)
  }

  const viewCentersButton = () => {
    setExpandButton(!expandButton)
  }

  useEffect(() => {
    if (appointment?.centerSfid !== selectedLocationSfid) {
      setSelectedLocationCenterNum(null)
      setSelectedLocationSfid(null)
    }
  }, [appointment])

  useEffect(() => {
    if (alternateCenters) {
      setNomoFilteredAlternateCenters(filterNonNomogramCenters(alternateCenters, donor, loggedInUser))
    }
  }, [alternateCenters])

  useEffect(() => {
    donor && appointment?.date && getAlternateDonorCenters(donor?.sfid, appointment?.startDateTime, donor?.zipCode)
  }, [donor, appointment?.date])

  return (
    <StyledAlternateCentersContainer>
      {isCenterClosed && <StyledAlternateCentersMessage>{t('selectNearByCentersMessage')}</StyledAlternateCentersMessage>}
      <StyledAlternateCentersAccordionTitle $expanded={expandButton} onClick={viewCentersButton}>
        <p>{t('checkAvailability')}</p>
        {!expandButton ? <StyledCollapsedIcon /> : <StyledExpandIcon />}
      </StyledAlternateCentersAccordionTitle>

      <StyledAlternateCentersAccordionContent $expanded={expandButton}>
        {alternateCentersLoading ? (
          <StyledLoadingState>
            <LoaderBar />
          </StyledLoadingState>
        ) : (
          nomoFilteredAlternateCenters && (
            <StyledAlternateCentersCarousel $centerCards={nomoFilteredAlternateCenters?.length}>
              <Slider ref={slider} {...settings}>
                {nomoFilteredAlternateCenters?.map((center) => (
                  <CenterCard
                    key={center.id}
                    onCenterCardClick={() => {
                      if (selectedLocationCenterNum !== center.centerNum) {
                        handleLocationChange(center)
                      }
                    }}
                    isSelected={selectedLocationCenterNum === center.centerNum || appointment?.centerSfid === center?.sfid}
                    centerData={center}
                    isHomeCenter={homeCenter?.sfid === center?.sfid}
                  />
                ))}
              </Slider>
            </StyledAlternateCentersCarousel>
          )
        )}
      </StyledAlternateCentersAccordionContent>
      {isCenterClosed && (
        <StyledAlternateCentersDisclaimerMessage>
          {t('alternateCentersDisclaimerMessage')}{' '}
          <StyledTnC href={window.location.origin + '/terms-and-conditions-of-use'} target='_blank'>
            {t('termsAndConditions')}
          </StyledTnC>
          {t('extraSpanishText')}
        </StyledAlternateCentersDisclaimerMessage>
      )}
    </StyledAlternateCentersContainer>
  )
}

const mapStateToProps = (state) => ({
  alternateCenters: state.centers?.getAlternateDonorCentersInfoSuccess?.data?.nearbyCenters,
  alternateCentersLoading: state.centers?.getAlternateDonorCentersInfoLoading,
  homeCenter: state.centers?.getAlternateDonorCentersInfoSuccess?.data?.selectedCenter
})

const mapDispatchToProps = (dispatch) => ({
  getSlotsForAppointmentType: (
    forDate,
    fromDateTime,
    toDateTime,
    numberOfChildren,
    centerSfid,
    appointmentType,
    appointmentId,
    donorSfid
  ) =>
    dispatch(
      centersActions.getSlotsForAppointmentType(
        forDate,
        fromDateTime,
        toDateTime,
        numberOfChildren,
        centerSfid,
        appointmentType,
        appointmentId,
        donorSfid
      )
    ),
  getAlternateDonorCenters: (sfid, todayDate, donorZipCode) =>
    dispatch(centersActions?.getAlternateDonorCentersInfo(sfid, todayDate, donorZipCode, true)),
  getAllCenters: (sfid) => dispatch(centersActions.getAllCenters(sfid))
})

export default connect(mapStateToProps, mapDispatchToProps)(AlternativeCenters)
