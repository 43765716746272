import React from 'react'
import { Inactivity10minContainer, Inactivity10minDiv, H4Text, Desc, ButtonsDiv } from './Inactivity10min.styled'
import PrimaryButton from '../../../Buttons/PrimaryButton'

function Inactivity10min({ resetChat, selectedLanguage }) {
  return (
    <Inactivity10minContainer>
      <Inactivity10minDiv>
        <H4Text>{selectedLanguage === 'Español' ? 'Inactivo' : 'Inactive'}</H4Text>
        <Desc>
          {selectedLanguage === 'Español'
            ? 'Gracias por contactar a BioLife. Inicia el chat nuevamente si necesitas más ayuda.'
            : 'Thank you for contacting BioLife. Please start the chat over if you need further support'}
        </Desc>
      </Inactivity10minDiv>
      <ButtonsDiv>
        <PrimaryButton text={selectedLanguage === 'Español' ? 'Empezar de nuevo' : 'Start Over'} fluid onClick={resetChat} />
      </ButtonsDiv>
    </Inactivity10minContainer>
  )
}

export default Inactivity10min
