import importer from '@takedapdt/biolife-core/src/importer'
const isValidEmail = importer('Helpers/isValidEmail')
const validate = (values) => {
  const errors = {}
  // Email validation
  if (!values['Email Address']) {
    errors['Email Address'] = 'emailAddressIsRequired'
  } else if (!isValidEmail(values['Email Address'])) {
    errors['Email Address'] = 'emailAddressMustBeInValid'
  }
  return errors
}

export { validate }
