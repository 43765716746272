import styled from 'styled-components'
import { devices } from '../../../utils/styledComponentHelpers'

export const StyledTimeSlotPickerWrapper = styled.div`
  padding-top: 3.2rem;
  padding-bottom: 1.6rem;
  width: 66.4rem;
  margin: 0 auto;
  ${devices.mobile} {
    width: 34.3rem;
  }
`
export const StyledTimeSlotPickerHeader = styled.div`
  font-size: var(--caption-mobile-font-size);
  text-align: center;
`
export const StyledTimeSlotsColumn = styled.div`
  margin: 2.4rem 0;
  padding-left: 4rem;
  padding-right: 4rem;
  ${devices.mobile} {
    padding-left: 2.1rem;
    padding-right: 2.1rem;
  }
`
export const StyledCenterSlotsFailureContainer = styled.div`
  text-align: center;
  ${devices.mobile} {
    padding: 0 4.1rem;
  }
`
