import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Segment, Button, Icon, Menu, Container, Header } from 'semantic-ui-react'
import './styles.scss'
import { Link } from 'react-router-dom'
import importer from '@takedapdt/biolife-core/src/importer'
const changeLanguageHelper = importer('Helpers/changeLanguage')
import StartupActions from '../../../Stores/Startup/Actions'
const PrimaryButton = importer('Components/Buttons/PrimaryButton')
import { ReactComponent as CloseIcon } from '../../../../assets/media/icons/button-close-large-orange.svg'
import { ReactComponent as BackIcon } from '../../../../assets/media/icons/UI-arrow-left.svg'
import { ReactComponent as RightIcon } from '../../../../assets/media/icons/UI-arrow-right.svg'
import { useNavigate } from 'react-router-dom'
import { CareersSpanishURL } from '../../../NavigationService'

const SideNavbar = ({ handleToggleSidebar, drupalHeaderMenu, drupalNavData, initializeApp }) => {
  const [selectedSidebarMenu, setSelectedSidebarMenu] = useState(null)
  const [currentLanguage, setCurrentLanguage] = useState(window?.localStorage?.getItem('originalLng'))

  const topHeaderMenu = drupalHeaderMenu?.topHeaderMenu
  const headerCTA1 = drupalNavData?.find((item) => item.type === 'header_component' && item.field_menu_type === 'top_header_menu')
  const headerCTA2 = drupalNavData?.find((item) => item.type === 'header_component' && item.field_menu_type === 'main')
  const navigate = useNavigate()
  const handleSidebarMenuItemClick = (item) => {
    setSelectedSidebarMenu(item)
  }
  const internationalize = (lang) => {
    window?.localStorage?.setItem('originalLng', lang)
    changeLanguageHelper(lang)
    initializeApp('changeLanguage')
  }

  const changeLanguage = () => {
    if (currentLanguage === 'en-US') {
      internationalize('es-MX')
      setCurrentLanguage('es-MX')
    } else {
      internationalize('en-US')
      setCurrentLanguage('en-US')
    }
  }

  const handleRedirection = (url) => {
    // if the url includes `http` or `https` then open the link in new tab
    if (url?.includes('https') || url?.includes('http')) {
      window.open(url)
    } else if (url?.includes('internal:')) {
      //if url contains `internal:` - indicating an internal link, navigate to it
      const origPath = url && url.includes('internal:') && url.split(':')[1]
      navigate(origPath)
    }
  }

  if (selectedSidebarMenu) {
    return (
      <Segment className='SideNavbar'>
        <div className='SubMenuHeader'>
          <Button onClick={() => setSelectedSidebarMenu(null)} className='BackButton'>
            <BackIcon />
          </Button>
          <Header as='h2'>{selectedSidebarMenu.title}</Header>
        </div>
        {selectedSidebarMenu &&
          selectedSidebarMenu?.submenu &&
          selectedSidebarMenu?.submenu?.map((item, index) => {
            if (item.submenu.length > 0) {
              return (
                <Menu.Item key={index} className='MobileWebNavbarItem' onClick={() => handleSidebarMenuItemClick(item)} as={Link}>
                  {item.title} <RightIcon />
                </Menu.Item>
              )
            } else {
              return (
                <Menu.Item
                  key={index}
                  className='MobileWebNavbarItem'
                  as={Link}
                  to={(item?.title?.toLowerCase() === 'carreras' ? CareersSpanishURL : item?.href)}
                  target={item.target}
                  onClick={() => handleToggleSidebar(false)}
                >
                  {item.title}
                </Menu.Item>
              )
            }
          })}
      </Segment>
    )
  }
  return (
    <>
      <Segment className='SideNavbar'>
        <Container className='MobileWebNavbarHeader'>
          <Button onClick={handleToggleSidebar} className='CloseButton'>
            <CloseIcon />
          </Button>
          <Segment.Group horizontal>
            <Segment className='AuthPagesButton'>
              {topHeaderMenu &&
                topHeaderMenu?.map((item, index) => (
                  <>
                    <Link to={item.href} onClick={() => handleToggleSidebar(false)}>
                      <strong>{item.title}</strong>
                    </Link>
                    {index !== topHeaderMenu.length - 1 ? <span>|</span> : null}
                  </>
                ))}
            </Segment>
            {/* Search Icon Code commented out. */}
            {/* <Icon name='search' className='SearchIcon' /> */}
          </Segment.Group>
        </Container>
        {drupalHeaderMenu &&
          drupalHeaderMenu?.main?.map((item, index) => {
            if (item.submenu.length > 0) {
              return (
                <div key={index} className='MobileWebNavbarItem' onClick={() => handleSidebarMenuItemClick(item)}>
                  {item.title} <RightIcon />
                </div>
              )
            } else if (item.external) {
              return (
                <Menu.Item key={index} name={item.title} className='MobileWebNavbarItem' as='a' href={item.href} target={item.target}>
                  {item.title}
                </Menu.Item>
              )
            } else {
              return (
                <Menu.Item
                  key={index}
                  className='MobileWebNavbarItem'
                  name={item.title}
                  as={Link}
                  to={item.href}
                  onClick={() => handleToggleSidebar(false)}
                >
                  {item.title}
                </Menu.Item>
              )
            }
          })}
        {headerCTA1 ? (
          <div style={{ margin: '1rem 2rem 0' }}>
            <PrimaryButton
              text={headerCTA1?.field_cta_1?.title}
              variant={headerCTA1?.field_cta_1_style}
              fluid
              onClick={() => {
                changeLanguage(), handleToggleSidebar(false)
              }}
              style={{ padding: '1.6rem 3rem', fontSize: 18 }}
            />
          </div>
        ) : null}
      </Segment>
      <div style={{ margin: 'auto 2rem 4rem' }}>
        {headerCTA2 ? (
          <PrimaryButton
            fluid
            text={headerCTA2?.field_cta_1?.title}
            sendTo={headerCTA2?.field_cta_1?.uri}
            variant={headerCTA2?.field_cta_1_style}
            onClick={() => {
              //navigating the donate now button to the url and toggle Sidebar
              handleRedirection(headerCTA2?.field_cta_1?.uri), handleToggleSidebar(false)
            }}
          />
        ) : null}
      </div>
    </>
  )
}

const mapStateToProps = (state) => ({
  drupalHeaderMenu: state.publicContent.drupalMenuSuccess,
  drupalNavData: state.publicContent?.getDrupalNavigationSuccess?.contents
})

const mapDispatchToProps = (dispatch) => ({
  initializeApp: (res) => dispatch(StartupActions.initializeApp(res))
})

export default connect(mapStateToProps, mapDispatchToProps)(SideNavbar)
