const isValidEmail = (val) => {
  if (val === undefined) {
    return false
  }

  if (val === null || val.length === 0) {
    return false
  }

  const emailRegex = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
  return emailRegex.test(val)
}

export default isValidEmail
