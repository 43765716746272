import React, { useRef, useEffect } from 'react'
import './styles.scss'
import importer from '@takedapdt/biolife-core/src/importer'
import moment from 'moment'
import { connect } from 'react-redux'
import styled, { css } from 'styled-components'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { ReactComponent as NextArrow } from '../../../assets/media/icons/circle-arrow-right.svg'
import { ReactComponent as PrevArrow } from '../../../assets/media/icons/circle-arrow-left.svg'

const { useScreenSize } = importer('Contexts/ResponsiveContextProvider', null)
const { getCssColorFromDrupalColorType, setTextColor } = importer('Constant/Utils', null)
const { devices, sizes } = importer('utils/styledComponentHelpers', null)
const { sanitizeHtml } = importer('utils/htmlHelpers', null)
const AvailableAppointmentCard = importer('Components/AvailableAppointmentCard')
const LoaderBar = importer('Components/Loaders/LoaderBar')
const PrimaryButton = importer('Components/Buttons/PrimaryButton')

const centersActions = importer('Stores/Centers/Actions')

const SliderNextArrow = ({ className, onClick }) => {
  return <NextArrow width='100' height='100' className={className} onClick={onClick} />
}

const SliderPrevArrow = ({ className, onClick }) => {
  return <PrevArrow width='50' height='50' className={className} onClick={onClick} />
}

const StyledAvailableAppointments = styled.div`
  background: ${(props) => getCssColorFromDrupalColorType(props?.$bgColor)};
`
const StyledAvailableAppointmentsContainer = styled.div``

const StyledAvailableAppointmentsTitle = styled.div`
  ${(props) => setTextColor(props?.$textColor)};
`
const StyledAvailableAppointmentsCardsContainer = styled.div`
  padding-top: 32px;
  ${devices.mobile} {
    padding-top: 24px;
  }
`
const StyledAvailableAppointmentsButtonContainer = styled.div`
  text-align: center;
  padding-top: 80px;
  ${devices.mobile} {
    padding-bottom: 48px;
  }
`

const AvailableAppointments = (props) => {
  const {
    field_component_title: componentTitle,
    field_cta_1: componentCTA,
    field_cta_1_style: componentCTAStyle,
    field_title_color: componentTitleColor,
    field_bg_color: backgroundColor,
    homeCenter,
    alternateCenters,
    alternateCentersLoading,
    getAlternateDonorCenters,
    donor,
    inquiry
  } = props
  const { isMobileWidth, isTabletWidth } = useScreenSize()

  useEffect(() => {
    getAlternateDonorCenters(donor?.sfid, moment().format('YYYY-MM-DDT00:00:00'), donor?.zipCode, true)
  }, [])

  const slider = useRef(null)
  var settings = {
    dots: true,
    intialSlide: 1,
    infinite: true,
    speed: 800,
    slidesToShow: 3,
    slidesToScroll: 3,
    arrows: isMobileWidth || isTabletWidth ? false : true,
    nextArrow: <SliderNextArrow />,
    prevArrow: <SliderPrevArrow />,
    responsive: [
      {
        breakpoint: 1422,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3
        }
      },
      {
        breakpoint: 1366,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3
        }
      },
      {
        breakpoint: 1080,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  }

  return (
    <StyledAvailableAppointments className='available-appointments' $bgColor={backgroundColor}>
      <div className='main-container'>
        <StyledAvailableAppointmentsContainer>
          <StyledAvailableAppointmentsTitle
            $textColor={componentTitleColor}
            dangerouslySetInnerHTML={sanitizeHtml(componentTitle?.processed)}
          />
          <StyledAvailableAppointmentsCardsContainer
            $cardsNumber={alternateCenters?.length}
            className={`available-appointments__cards-container ${alternateCenters?.length <= 3 ? 'LessDataWrapper' : ''}`}
          >
            {alternateCentersLoading ? (
              <LoaderBar />
            ) : (
              <Slider ref={slider} {...settings}>
                {alternateCenters?.map((center) => (
                  <AvailableAppointmentCard centerData={center} isHomeCenter={homeCenter?.sfid === center?.sfid} />
                ))}
              </Slider>
            )}
          </StyledAvailableAppointmentsCardsContainer>
          {!!donor?.pdn && !!inquiry?.pastDonations.length && (
            <StyledAvailableAppointmentsButtonContainer>
              <PrimaryButton text={componentCTA?.title} variant='outline_black' sendTo={componentCTA?.uri} fluid={isMobileWidth} />
            </StyledAvailableAppointmentsButtonContainer>
          )}
        </StyledAvailableAppointmentsContainer>
      </div>
    </StyledAvailableAppointments>
  )
}

const mapStateToProps = ({ appointments, centers, donors }) => ({
  alternateCenters: centers?.getAlternateDonorCentersInfoSuccess?.data?.nearbyCenters,
  alternateCentersLoading: centers?.getAlternateDonorCentersInfoLoading,
  homeCenter: centers?.getAlternateDonorCentersInfoSuccess?.data?.selectedCenter,
  donor: donors.donors?.data,
  inquiry: donors.inquiryDonorSuccess?.data
})

const mapDispatchToProps = (dispatch) => ({
  getAlternateDonorCenters: (sfid, todayDate, donorZipCode, setCenterDistance) =>
    dispatch(centersActions?.getAlternateDonorCentersInfo(sfid, todayDate, donorZipCode, setCenterDistance))
})

export default connect(mapStateToProps, mapDispatchToProps)(AvailableAppointments)
