import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

export const ReloadWrapper = styled.div`
  button {
    pointer-events: all;
  }
`

const Reload = () => {
  const { t } = useTranslation('Reload')

  return (
    <ReloadWrapper>
      {t('newVersion')}
      <button onClick={() => window.location.reload()}>{t('reload')}</button>
    </ReloadWrapper>
  )
}

export default Reload
