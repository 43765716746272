import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import TagManager from 'react-gtm-module'
import TextCTAModal from '../../Modal/TextCTAModal'
import { DashboardMainContainer } from './styled'
import LifeTimeImpactStatistics from '../../LifetimeImpactStatistics'
import importer from '@takedapdt/biolife-core/src/importer'
const DashboardStatistics = importer('Components/Dashboard/DashboardStatistics')
const HeroTemplate7 = importer('Components/Hero/HeroTemplate7')
const renderDrupalComponent = importer('Constant/renderDrupalComponent')
const Modal = importer('Components/Modal')
const LoyaltyEnrolmentModal = importer('Components/Modal/LoyaltyEnrolmentModal')
const LoyaltyEnrolmentSuccessModal = importer('Components/Modal/LoyaltyEnrolmentSuccessModal')
const donorsActions = importer('Stores/Donors/Actions')
const wirecardActions = importer('Stores/Wirecard/Actions')
const { getDonorAgeGroup } = importer('Helpers/getDonorAgeGroup', null)
const { ROUTES, navigate } = importer('NavigationService', null)

const DashboardContainer = ({ componentList, dashboardMetadata }) => {
  const loggedInUser = useSelector((state) => state.users?.user)
  const upcomingAppointments = useSelector((state) => state.appointments.getUpcomingAppointmentsSuccess)
  const donorData = useSelector((state) => state.donors.donors?.data)
  const center = useSelector((state) => state.center)
  const updateDonorSuccess = useSelector((state) => state.donors?.updateDonorSuccess)
  const [components, setComponents] = useState([])
  const [isLoyaltyEnrolmentModalOpen, setIsLoyaltyEnrolmentModalOpen] = useState(false)
  const [isLoyaltyEnrolmentSuccessModalOpen, setIsLoyaltyEnrolmentSuccessModalOpen] = useState(false)
  const [isLoyaltyEnrolmentModalSubmitClicked, setIsLoyaltyEnrolmentModalSubmitClicked] = useState(false)
  const [loyaltyEnrolmentSuccess, setLoyaltyEnrolmentSuccess] = useState(false)
  const [showDisclaimerPopup, setShowDisclaimerPopup] = useState(false)

  const dispatch = useDispatch()
  const { t } = useTranslation('donorDashboardPage')

  useEffect(() => {
    if (JSON.parse(sessionStorage.getItem('routeToLoyaltyDashboard'))) {
      navigate(`/${ROUTES.MyRewards}`)
      sessionStorage.removeItem('routeToLoyaltyDashboard')
    } else if (JSON.parse(sessionStorage.getItem('routeToWaysToEarn'))) {
      navigate(`/${ROUTES.MyRewards}`)
      sessionStorage.removeItem('routeToWaysToEarn')
    }
  }, [])

  useEffect(() => {
    if (window.location.pathname !== ROUTES.DebitHistory) {
      sessionStorage.removeItem('filterOnDebit')
    }
    if (window.location.pathname !== ROUTES.PaymentHistory) {
      sessionStorage.removeItem('filterOnPayment')
    }
  }, [window.location.pathname])

  useEffect(() => {
    const dashboardBannerComponent = {
      type: 'dashboard_banner',
      profileName: donorData?.firstName || loggedInUser?.firstName,
      lastName: donorData?.lastName || loggedInUser?.lastName
    }
    const customTextComponent = {
      type: 'text_component',
      field_bg_color: 'white_smoke',
      field_component_desc: {
        processed: `<p style="padding: 0">${t('scheduleNextAppointment')}</p>`
      }
    }

    let updatedComponentList = []
    // If user is not having PDN
    if (!loggedInUser?.pdn) {
      const nonPDNComponentList = componentList?.filter((component, index) => {
        if (component.field_integration_type === 'upcoming_appointments' && !upcomingAppointments?.length) {
          return false
        }
        if (component.field_hero_template_type === 'hero_template_5' && upcomingAppointments?.length) {
          return false
        }
        if (component.type === 'cta_component' && componentList[index - 1]?.field_integration_type === 'upcoming_appointments') {
          return false
        }
        if (
          component.field_hero_template_type === 'hero_template_7' &&
          (donorData?.isLoyaltyMember === 'Yes' || !donorData?.defaultCenter?.isLoyaltyProgramEnabled) // If user is a loyalty member or loyalty program is disabled for their default center
        ) {
          return false
        }
        return true
      })
      updatedComponentList = nonPDNComponentList
    }
    // If user is having PDN
    else if (loggedInUser?.pdn) {
      if (!upcomingAppointments?.length) {
        // If user is not having upcoming appointments
        if (donorData?.isLoyaltyMember === 'Yes') {
          // If user is a loyalty member
          const noAppointmentLoyaltyPDNComponentList = componentList?.reduce((acc, component) => {
            if (component.field_hero_template_type === 'hero_template_5') {
              return acc
            }
            if (component.field_hero_template_type === 'hero_template_7') {
              return acc
            }
            if (component.field_integration_type === 'upcoming_appointments') {
              component = customTextComponent
            }
            return [...acc, component]
          }, [])
          updatedComponentList = noAppointmentLoyaltyPDNComponentList
        } else if (donorData?.isLoyaltyMember === 'No' || donorData?.isLoyaltyMember === null) {
          // If user is not a loyalty member
          const noAppointmentLoyaltyDisabledPDNComponentList = componentList?.reduce((acc, component) => {
            if (component.field_hero_template_type === 'hero_template_5') {
              return acc
            }
            if (component.field_hero_template_type === 'hero_template_7' && !donorData?.defaultCenter?.isLoyaltyProgramEnabled) {
              return acc
            }
            if (component.field_integration_type === 'upcoming_appointments') {
              component = customTextComponent
            }
            return [...acc, component]
          }, [])
          updatedComponentList = noAppointmentLoyaltyDisabledPDNComponentList
        }
      } else {
        // If user is having upcoming appointments
        if (donorData?.isLoyaltyMember === 'Yes') {
          // If user is a loyalty member
          const withAppointmentsLoyaltyPDNComponentList = componentList?.filter((component) => {
            if (component.field_hero_template_type === 'hero_template_5') {
              return false
            }
            if (component.field_hero_template_type === 'hero_template_7') {
              return false
            }
            return true
          })
          updatedComponentList = withAppointmentsLoyaltyPDNComponentList
        } else if (donorData?.isLoyaltyMember === 'No' || donorData?.isLoyaltyMember === null) {
          // If user is not a loyalty member
          const withAppointmentsLoyaltyDisabledPDNComponentList = componentList?.filter((component) => {
            if (component.field_hero_template_type === 'hero_template_5') {
              return false
            }
            if (component.field_hero_template_type === 'hero_template_7' && !donorData?.defaultCenter?.isLoyaltyProgramEnabled) {
              return false
            }
            return true
          })
          updatedComponentList = withAppointmentsLoyaltyDisabledPDNComponentList
        }
      }
    }

    // DashboardBannerComponent added at the beginning of the components state
    if (updatedComponentList?.length) {
      setComponents([dashboardBannerComponent, ...updatedComponentList])
    }
  }, [componentList, loggedInUser, donorData, upcomingAppointments])

  useEffect(() => {
    if (updateDonorSuccess != null && isLoyaltyEnrolmentModalSubmitClicked) {
      setLoyaltyEnrolmentSuccess(true)
      setIsLoyaltyEnrolmentSuccessModalOpen(true)
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    }
  }, [updateDonorSuccess, isLoyaltyEnrolmentModalSubmitClicked])

  useEffect(() => {
     TagManager.dataLayer({
        dataLayer: {
          donorId: donorData?.id,
          donorPdn: donorData?.pdn,
          donorEmail: donorData?.email && encodeURIComponent(Buffer.from(donorData?.email).toString('base64')),
          //donorEmail:donor.email,
          bdpDonorId: donorData?.bdpDonorId,
          sfid: donorData?.sfid,
          donorCountry: donorData?.country,
          donorState: donorData?.state,
          donorZip: donorData?.zipCode,
          //  donorAgeGroup: getDonorAgeGroup(donorData?.birthDate),
          donorEthnicity: donorData?.ethnicity,
          donorGender: donorData?.gender,
          donorLanguage: donorData?.language,
          donorWorkStatus: donorData?.workstatus,
          centerName: donorData?.defaultCenter?.name || null,
          centerClassification: donorData?.defaultCenter?.centerClassification || null,
          disCenterId: donorData?.defaultCenter?.centerNum || null,
          bdpCenterId: donorData?.defaultCenter?.bdpCenterId || null,
          centerDemographic: donorData?.defaultCenter?.demographic || null,
          donorPhysical: 'Dummy Val',
          donorType: 'Dummy Val',
          donorDeferred: 'Dummy Val'
        }
      })

  }, [donorData])

  return (
    <DashboardMainContainer $isNotLoyaltyMember={donorData?.isLoyaltyMember === 'No' || donorData?.isLoyaltyMember === null}>
      {components?.map((component, index) => {
        if (component?.type === 'breadcrumb') {
          let tempComp = {
            ...component,
            breadcrumbToggle: dashboardMetadata?.field_content_breadcrumb,
            breadcrumbMobileToggle: dashboardMetadata?.field_mobile_breadcrumb
          }
          component = tempComp
        } else if (component?.type === 'ext_integration_component' && component?.field_integration_type === 'life_time_impact_stats') {
          return (
            <>
              {donorData?.pdn ? (
                <LifeTimeImpactStatistics key={index} {...component} setDisclaimerPopup={() => setShowDisclaimerPopup(true)} />
              ) : null}
            </>
          )
        } else if (component?.type === 'ext_integration_component' && component?.field_integration_type === 'dashboard_stats') {
          return (
            <DashboardStatistics
              key={index}
              donor={donorData}
              {...component}
              handleLoyaltyEnrolmentButtonClick={() => setIsLoyaltyEnrolmentModalOpen(true)}
            />
          )
        } else if (component?.type === 'hero' && component?.field_hero_template_type === 'hero_template_7') {
          return <HeroTemplate7 key={index} {...component} handleLoyaltyEnrolmentButtonClick={() => setIsLoyaltyEnrolmentModalOpen(true)} />
        } else if (component?.type === 'pop_up_component' && component?.field_poup_type === 'loyalty_enrollment') {
          return (
            <Modal
              key={index}
              maxWidth={836}
              maxWidthMobile={'87.7%'}
              maxHeight={550}
              maxHeightMobile={690}
              isOpen={(donorData?.isLoyaltyMember === 'No' || donorData?.isLoyaltyMember === null) && isLoyaltyEnrolmentModalOpen}
              onClose={() => setIsLoyaltyEnrolmentModalOpen(false)}
            >
              <LoyaltyEnrolmentModal
                handleModalSubmit={setIsLoyaltyEnrolmentModalSubmitClicked}
                isLoyaltyEnrolmentModalSubmitClicked={isLoyaltyEnrolmentModalSubmitClicked}
                modalContent={component}
              />
            </Modal>
          )
        } else if (component?.type === 'pop_up_component' && component?.field_poup_type === 'loyalty_enrollment_success') {
          return (
            <Modal
              key={index}
              maxWidth={836}
              maxWidthMobile={'87.7%'}
              maxHeight={461}
              maxHeightMobile={690}
              modalContent={component}
              isOpen={loyaltyEnrolmentSuccess && isLoyaltyEnrolmentSuccessModalOpen}
              onClose={() => setIsLoyaltyEnrolmentSuccessModalOpen(false)}
            >
              <LoyaltyEnrolmentSuccessModal modalContent={component} />
            </Modal>
          )
        } else if (component?.type === 'pop_up_component' && component?.field_poup_type === 'disclaimer') {
          return (
            <Modal
              key={index}
              maxWidth={836}
              maxWidthMobile={342}
              maxHeight={328}
              maxHeightMobile={538}
              modalContent={component}
              isOpen={showDisclaimerPopup}
              onClose={() => setShowDisclaimerPopup(false)}
            >
              <TextCTAModal modalContent={component} />
            </Modal>
          )
        }
        return renderDrupalComponent(component, index)
      })}
    </DashboardMainContainer>
  )
}

export default DashboardContainer
