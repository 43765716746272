import React from 'react'
import importer from 'common/importer'
import { styled } from 'styled-components'
const AdvertisementCard1 = importer('Components/AdverstisementCardContainer/AdvertisementCard1')
const AdvertisementCard2 = importer('Components/AdverstisementCardContainer/AdvertisementCard2')
const { devices } = importer('utils/styledComponentHelpers', null)

const AdverstisementCardContainer = ({ uniqueData }) => {
  const componentTemplates = {
    adv_template_1: AdvertisementCard1,
    adv_template_2: AdvertisementCard2
  }

  const AdverstisementCardContainerGrid = styled.div`
    width: 1008px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: auto;
    ${devices.tablet} {
      width: 90%;
      margin-top: 150px;
    }
    ${devices.mobile} {
      flex-direction: column;
      width: 90%;
      margin-top: 0px;
    }
    .ui.card {
      margin: 1%;
      width: 47%;
      ${devices.mobile} {
        margin: 10px 0;
        width: 100%;
      }
    }
  `

  return (
    <AdverstisementCardContainerGrid>
      {uniqueData?.map((item, index) => {
        return React.createElement(componentTemplates[item.field_adv_template_type], {
          key: index,
          ...item
        })
      })}
    </AdverstisementCardContainerGrid>
  )
}

export default AdverstisementCardContainer
