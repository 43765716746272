import React from 'react'
import './styles.scss'
import renderDrupalComponent from '../../Constant/renderDrupalComponent'

const SignUpSuccess = (props) => {
  return (
    <div className='SignUpSuccessContainer'>
      {props?.signUpSuccessContents?.map((component, index) => {
        return renderDrupalComponent(component, index)
      })}
    </div>
  )
}

export default SignUpSuccess
