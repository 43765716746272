import moment from 'moment'
const validate = (values) => {
  const errors = {}

  // Postal code validation
  if (values.zipCode) {
    if (!/^\d{5}$/.test(values.zipCode)) {
      errors.zipCode = 'zipCodeShouldHave5Digits'
    }
  } else {
    errors.zipCode = 'zipCodeIsRequired'
  }

  return errors
}

export default validate
