import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Form, Message } from 'semantic-ui-react'
import { Form as FinalForm, Field } from 'react-final-form'
import { Link, useNavigate, Redirect } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import './styles.scss'

import importer from '@takedapdt/biolife-core/src/importer'

const LoaderSpinner = importer('Components/Loaders/LoaderSpinner')
const { useScreenSize } = importer('Contexts/ResponsiveContextProvider', null)
const UserActions = importer('Stores/Users/Actions')
const PrimaryButton = importer('Components/Buttons/PrimaryButton')
const InputField = importer('Components/Forms/FormFields/InputField')
const { validate } = importer('Components/Forms/SocialLoginForm/validate', null)
const { scrollToTop } = importer('Helpers/Utils', null)

const ForgotPasswordForm = (props) => {
  const { t } = useTranslation('ForgotPasswordForm')
  const { isMobileWidth, isTabletWidth } = useScreenSize()
  const { forgotPassword, forgotPasswordLoading: loading, forgotPasswordError: error, forgotPasswordSuccess, uniqueFormData } = props
  const navigate = useNavigate()
  const [saveClicked, setSaveClicked] = useState(false)
  const initialData = {
    // Fields are acc to the data from Drupal
    'Email Address': ''
  }

  /* 
  Scroll to the top when the component mounts
  */
  useEffect(() => {
    scrollToTop()
  }, [])
  useEffect(() => {
    if (saveClicked && forgotPasswordSuccess) {
      handleNavigation()
    }
  }, [saveClicked, forgotPasswordSuccess])

  const handleNavigation = (code) => {
    setSaveClicked(false)
    navigate('/check-email?type=ForgotPassword')
  }

  const handleForgotPassword = (values) => {
    setSaveClicked(true)
    forgotPassword(values.username)
  }

  const handleSubmit = (values) => {
    handleForgotPassword({ username: values['Email Address'].replace(/\s+/g, '') })
  }
  // Rendering different form Fields
  function renderFormFields({ type, props }) {
    const {
      field_component_title_2: placeholder = '',
      name = '<p></p>',
      field_component_title_1: fieldName = '',
      field_cta_1_style = '',
      field_cta_1 = {},
      fieldLabelType,
      error
    } = props
    const formComponent = {
      form_field_component: (
        <>
          <Field name={fieldName}>
            {({ input, meta }) => {
              return (
                <InputField
                  required
                  width={isMobileWidth ? '90%' : '50%'}
                  meta={meta}
                  {...input}
                  fieldLabel={name}
                  value={input.value}
                  placeholder={placeholder}
                  isHtmlLabel
                />
              )
            }}
          </Field>
          <p className='forgotpassword__block--required--text'>
            <span role='presentation' className='asteriskStyle'>
              *
            </span>
            &nbsp;
            {t('requiredField')}
          </p>
          {saveClicked && error && error.response && error.response.data && (
            <Message visible negative>
              <p>{error.response.data.message}</p>
            </Message>
          )}
        </>
      ),
      form_cta_component: (
        <>
          {field_cta_1?.uri?.includes('login') ? (
            <div className='UnderlineBlackButtonContainer'>
              <PrimaryButton variant={field_cta_1_style} text={field_cta_1?.title} sendTo={field_cta_1?.uri} fluid={isMobileWidth} />
            </div>
          ) : (
            <PrimaryButton
              type='submit'
              variant={field_cta_1_style}
              text={field_cta_1.title}
              style={{ marginRight: '48px' }}
              loading={saveClicked && loading}
              fluid={isMobileWidth}
            />
          )}
        </>
      )
    }
    return formComponent[type]
  }
  return loading ? (
    <LoaderSpinner />
  ) : (
    <div className='ForgotPasswordForm main-container'>
      <FinalForm
        onSubmit={handleSubmit}
        validate={validate}
        initialValues={initialData}
        render={({ handleSubmit, values }) => (
          <Form onSubmit={handleSubmit}>
            {uniqueFormData.map((formComponent) => {
              return renderFormFields({
                type: formComponent.type,
                props: {
                  name: formComponent?.field_component_title?.processed,
                  fieldLabelType: formComponent?.field_component_title?.format,
                  field_component_title_1: formComponent?.field_component_title_1,
                  field_component_title_2: formComponent?.field_component_title_2,
                  field_cta_1_style: formComponent?.field_cta_1_style,
                  field_cta_1: formComponent?.field_cta_1,
                  handleSubmit: { handleSubmit },
                  values,
                  error
                }
              })
            })}
          </Form>
        )}
      />
    </div>
  )
}

const mapStateToProps = (state) => ({
  forgotPasswordLoading: state.users.resetPasswordLoading,
  forgotPasswordError: state.users.resetPasswordFailure,
  forgotPasswordSuccess: state.users.resetPasswordSuccess
})

const mapDispatchToProps = (dispatch) => ({
  forgotPassword: (email) => dispatch(UserActions.resetPassword(email))
})

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordForm)
