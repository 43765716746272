import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { devices } from '../../../utils/styledComponentHelpers'
import SearchLocation from '../../Dashboard/SearchLocation'
import CentersActions from '@takedapdt/biolife-core/src/Stores/Centers/Actions'
import { mapOptionsByState, sortCentersAlphabetically } from '../../../Helpers/Utils'
import './styles.scss'
import LoaderSpinner from '../../Loaders/LoaderSpinner'

const StyledModalInnerDiv = styled.div`
  display: flex;
  padding-top: 5rem;
  flex-direction: column;
  height: 71.2rem;
  width: 83.6rem;
  overflow-y: scroll;
  ${devices.mobile} {
    width: 37.3rem;
    max-height: 66rem;
  }
  /* For browsers that support 'scrollbar-*' properties */
  @supports (scrollbar-width: auto) {
    scrollbar-width: 0.4rem;
  }
  /* Otherwise, use '::-webkit-scrollbar-*' pseudo-elements */
  @supports selector(: : -webkit-scrollbar) {
    &::-webkit-scrollbar {
      width: 0.4rem;
    }
  }
  .SearchInputButton .FieldLabel div {
    font-family: var(--font-family-bold) !important;
  }
  .CenterCardContainer.CenterCardSelected.card .content .header .HeaderWrapper .CheckMarkContainer .CheckMark,
  .CenterCardContainer.card:hover .content .header .HeaderWrapper .CheckMarkContainer .CheckMark {
    top: 0.3rem !important;
  }
`

const StyledFindLocationHeader = styled.div`
  padding-bottom: 1.6rem;
  p {
    width: fit-content;
    margin: auto;
    padding: 0.4rem 0.8rem;
    background: var(--color-medium-shade-gray);
    font-size: var(--caption-mobile-font-size);
  }
`

const StyledTabsContainer = styled.div`
  padding: 0 8.5rem;
  display: flex;
  width: 100%;
  margin-bottom: 3.2rem;
  ${devices.mobile} {
    padding: 0 2.5rem;
  }
`

const StyledTabs = styled.div`
  width: 100%;
  text-align: center;
  border-bottom: 0.4rem solid var(--color-light-shaded-grey) !important;
  font-size: 1.8rem;
  font-family: var(--font-family-medium) !important;
  padding-bottom: 0.9rem;
  cursor: pointer;
  ${(props) =>
    props.$active &&
    `border-image: linear-gradient(90deg, var(--color-dark-shaded-orange) 0%, var(--color-light-shaded-orange) 100%) 30 !important;`}
  ${(props) => props.$active && `border-bottom: 0.4rem solid !important;`}
  ${(props) => props.$active && `font-family: var(--font-family-bold) !important;`}
  ${(props) => props.$active && `color: var(--color-orange) !important ;`}
`

const AppointmentCenterSearch = ({
  appointment,
  handleAppointment,
  getFavoriteCenterLoading,
  favCentersLoading,
  centers,
  donor,
  center,
  open,
  setAppointment,
  getFavoriteCenters,
  favoriteCenterList,
  getAppointmentCenter,
  setIsChangeLocationModal,
  defaultCenterName,
  setDefaultCenterName,
  edit
}) => {
  const { t } = useTranslation('CenterSelection')

  /**
   * @description Updates the centers based the centers State and sorts them alphabetically
   * @returns the sorted center data state wise
   */
  const preparedOptions = async (centers) => {
    const res = await mapOptionsByState(centers)
    const data = sortCentersAlphabetically(res)
    return data
  }
  const [centerOptions, setCenterOptions] = useState([])
  const [favOptions, setFavOptions] = useState([])
  const [centerList, setCenterList] = useState(centers)
  const [activeTab, setActiveTab] = useState(0)

  useEffect(() => {
    preparedOptions(centers).then((res) => {
      setCenterOptions(res)
    })
  }, [centers])

  const homeCenter = centers?.filter((center) => center?.sfid === donor?.defaultCenter?.sfid)

  useEffect(() => {
    const favCenterList = favoriteCenterList?.filter((center) => centers?.some((centers) => centers.sfid === center.sfid))
    if (homeCenter) {
      const updatedHomeCenter = { ...homeCenter[0], isHomeCenter: true }
      favCenterList?.push(updatedHomeCenter)
    }
    favCenterList?.sort((a, b) => a.name.localeCompare(b.name))
    let uniqueFavCenterList = favCenterList?.reduce((acc, obj) => {
      if (!acc?.some((o) => o?.sfid === obj?.sfid)) {
        acc.push(obj)
      }
      return acc
    }, [])

    preparedOptions(uniqueFavCenterList).then((res) => {
      setFavOptions(res)
    })
  }, [favoriteCenterList])

  useEffect(() => {
    if (donor?.sfid) {
      getFavoriteCenters(donor?.sfid)
    }
  }, [donor, open])
  /**
   * @description Updates the appointment object based on the selected value.
   * Calls getAppointmentCenter and handleAppointment functions.
   * @param {Object} value.value - The appointmnent details value
   */
  const handleSelection = (value) => {
    getAppointmentCenter(value.value)
    const selectedCenter = value ? `${value.name} Center` : ''
    handleAppointment({
      ...appointment,
      stateCode: value.stateCode,
      center: selectedCenter,
      centerSfid: value.value,
      centerCity: value.city,
      centerName: value.centerName,
      name: value.name,
      address: value.address,
      telephone: value.telephone,
      time: null,
      startTime: null,
      alternateCenterSelected: false
    })
  }

  useEffect(() => {
    setCenterList(centers)
    setDefaultCenterName(center ? center.data : '')
  }, [center])

  const FindLocationComponent = () => {
    return (
      <>
        {edit && (
          <SearchLocation
            fieldLabel={t('searchByLoc')}
            fieldColor={'bl_blue'}
            options={centerOptions}
            centers={centers}
            allCenters={centers}
            favCenterList={favoriteCenterList}
            placeholderVal={t('enterStateOrCenterName')}
            handleOnChange={handleSelection}
            defaultValue={defaultCenterName?.name}
            centerID={appointment?.centerSfid || donor?.defaultCenter?.sfid}
            donorHomeCenter={donor?.defaultCenter?.sfid}
            allowZipcodeSearch={appointment?.appointmentType === 'DONATION' ? true : false}
            setIsChangeLocationModal={setIsChangeLocationModal}
            favourites={false}
          ></SearchLocation>
        )}
      </>
    )
  }
  const FavouritesComponent = () => {
    return (
      <>
        {getFavoriteCenterLoading && <LoaderSpinner />}
        {edit && !getFavoriteCenterLoading && (
          <SearchLocation
            fieldLabel={t('searchByLoc')}
            fieldColor={'bl_blue'}
            options={favOptions}
            centers={favoriteCenterList}
            allCenters={centers}
            favCenterList={favoriteCenterList}
            placeholderVal={t('enterStateOrCenterName')}
            handleOnChange={handleSelection}
            defaultValue={defaultCenterName?.name}
            centerID={appointment?.centerSfid || donor?.defaultCenter?.sfid}
            donorHomeCenter={donor?.defaultCenter?.sfid}
            allowZipcodeSearch={appointment?.appointmentType === 'DONATION' ? true : false}
            setIsChangeLocationModal={setIsChangeLocationModal}
            favourites={true}
          ></SearchLocation>
        )}
      </>
    )
  }

  return (
    <StyledModalInnerDiv>
      <StyledTabsContainer>
        <StyledTabs
          onClick={() => {
            setActiveTab(0)
          }}
          $active={activeTab === 0}
        >
          {t('findLocation')}
        </StyledTabs>
        <StyledTabs
          onClick={() => {
            setActiveTab(1)
          }}
          $active={activeTab === 1}
        >
          {t('Favorites')}
        </StyledTabs>
      </StyledTabsContainer>
      {activeTab === 1 ? FavouritesComponent() : FindLocationComponent()}
    </StyledModalInnerDiv>
  )
}

const mapStateToProps = (state) => ({
  favoriteCenterList: state?.centers?.favouriteCenters,
  getFavoriteCenterLoading: state?.centers?.getFavoriteCenterInfoLoading
})

const mapDispatchToProps = (dispatch) => ({
  getAppointmentCenter: (sfid) => dispatch(CentersActions.getAppointmentCenter(sfid)),
  getFavoriteCenters: (donorSfid) => dispatch(CentersActions.getFavoriteCenterInfo(donorSfid))
})

export default connect(mapStateToProps, mapDispatchToProps)(AppointmentCenterSearch)
