import React from 'react'
import styled, { keyframes } from 'styled-components'

export const LoaderBarContainer = styled.div`
  width: 130px;
  height: 8px;
  background-color: var(--color-light-grey);
  border-radius: 100px;
  overflow: hidden;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
`

const move = keyframes`
  0% {
    transform: translateX(-98px);
  }
  100% {
    transform: translateX(130px);
  }
`

const LoaderBarFill = styled.div`
  width: 98px;
  height: 100%;
  background: linear-gradient(to right, var(--color-dark-orange), var(--color-bl-orange));
  background-size: 200% 100%;
  border-radius: 100px;
  animation: ${move} 1.5s linear infinite;
`

const LoaderBar = () => {
  return (
    <LoaderBarContainer className='loader-bar-component'>
      <LoaderBarFill></LoaderBarFill>
    </LoaderBarContainer>
  )
}

export default LoaderBar
