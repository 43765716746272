import React from 'react'
import { useTranslation } from 'react-i18next'
import { useScreenSize } from '../../../Contexts/ResponsiveContextProvider'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { ROUTES } from '../../../NavigationService'
import { useNavigate } from 'react-router-dom'
import {
  StyledCancelAppointment,
  StyledCancelAppointmentHeader,
  StyledCancelAppointmentContent,
  StyledCancelAppointmentContentTitle,
  StyledCancelAppointmentContentAppointmentDetails,
  StyledIcon,
  StyledButtonsContainer
} from './styled'
import PrimaryButton from '../../Buttons/PrimaryButton'

const ConfirmedCancellationModal = ({ toggleModal, isAppointmentRescheduling, setIsAppointmentRescheduling }) => {
  const { t, i18n } = useTranslation('ConfirmCancellationModal')
  const { isMobileWidth } = useScreenSize()
  const navigate = useNavigate()
  const closeModal = () => {
    setIsAppointmentRescheduling && setIsAppointmentRescheduling(false)
    toggleModal()
  }

  return (
    <StyledCancelAppointment>
      <StyledCancelAppointmentHeader>
        <StyledIcon />
      </StyledCancelAppointmentHeader>
      <StyledCancelAppointmentContent>
        <StyledCancelAppointmentContentTitle>
          <strong>{t('appointmentCancelled')}</strong>
        </StyledCancelAppointmentContentTitle>
        <StyledCancelAppointmentContentAppointmentDetails>
          {t('yourAppointmentHasCancelled')}
        </StyledCancelAppointmentContentAppointmentDetails>
      </StyledCancelAppointmentContent>
      <StyledButtonsContainer>
        <PrimaryButton
          text={t('scheduleAppointment')}
          variant='outline_blue'
          fluid={isMobileWidth}
          onClick={() => {
            if (!isAppointmentRescheduling) {
              navigate('/appointment-scheduling')
            }
            closeModal()
          }}
        />
        <Link to={`${ROUTES && ROUTES.DonorDashboard}`} onClick={() => closeModal()}>
          <span className='underline-dark-blue'>{t('backToDashboard')}</span>
        </Link>
      </StyledButtonsContainer>
    </StyledCancelAppointment>
  )
}

const mapStateToProps = (state) => ({
  loggedInUser: state.users?.user,
  isNewDonorBonusEligible: state.appointments?.newDonorBonusEligibilitySuccess?.data?.isEligible,
  newDonorBonusEligibilityLoading: state.appointments?.newDonorBonusEligibilityLoading,
  inquiry: state.donors.inquiryDonorSuccess?.data,
  donor: state.donors?.donors?.data,
  searchedCenters: state.centers.getZipcodeSearchCentersSuccess?.data
})

const mapDispatchToProps = (dispatch) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmedCancellationModal)
