import React, { useEffect, useState } from 'react'
import { Container, Accordion as AccordionSemantic, Icon, GridColumn, GridRow } from 'semantic-ui-react'
// import './styles.scss'
import { Grid } from 'semantic-ui-react'
import importer from '@takedapdt/biolife-core/src/importer'
import styled from 'styled-components'
import { ReactComponent as CheveronUpIcon } from '../../../assets/media/icons/icon-chevron-up.svg'
import { ReactComponent as CheveronDownIcon } from '../../../assets/media/icons/icon-chevron-down.svg'
const { devices, sizes } = importer('utils/styledComponentHelpers', null)
const PrimaryButton = importer('Components/Buttons/PrimaryButton')
const { setTextColor } = importer('Constant/Utils', null)

const StyledContainer = styled.div`
  border-top: 1px solid var(--color-dark-grey);
  padding: 16px;
  .ui.accordion .title {
    display: flex;
    align-items: start;
    gap: 18px;
    justify-content: space-between;
    padding: 0px !important;
    &:hover {
      color: var(--color-dark-orange-hover) !important;
    }
    * {
      ${devices.mobile} {
        font-size: var(--h4-mobile-font-size);
        line-height: var(--h4-mobile-line-height);
      }
    }
  }
  .ui.accordion .content {
    padding: 0px 0 22px 0 !important;
    width: 100%;
    ${devices.mobile} {
      p {
        width: unset;
      }
      li {
        margin-left: 10px;
        width: 100%;
        list-style-position: outside;
      }
    }
  }
  .chevron-arrow {
    svg {
      vertical-align: top;
      width: 24px;
      height: 24px;

      ${devices.mobile} {
        vertical-align: middle;
      }
    }

    ${devices.mobile} {
      display: flex;
    }
  }

  ${devices.mobile} {
    .accimg--content {
      .ui.grid > div:first-child {
        gap: 32px;
        padding-bottom: 0;
      }
    }
    .accImg--imageContainer {
      text-align: center;
    }
  }
`
const StyledContentContainer = styled.div`
  width: 100%;
  font-size: var(--p-font-size);
  line-height: var(--p-line-height);
  ul {
    margin-left: 1.6rem;
    & > li {
      padding-top: 8px;
      padding-bottom: 8px;
    }
  }
  ${devices.tablet} {
    width: unset;
  }
`

const StyledAccordionImage = styled.img`
  padding-right: 0px;
  ${devices.tablet} {
    width: auto;
  }
  ${devices.mobile} {
    aspect-ratio: auto;
    object-fit: contain;
    object-position: center;
    max-width: 30.5rem !important;
  }
`
const StyledButton = styled.div`
  margin-top: 32px;
`
const StyledGridColumn = styled(GridColumn)`
  padding-left: 0px !important;
`
export const StyledGridRow = styled(GridRow)`
  padding-top: 32px !important;
  .accimg--textContainer ul li:last-child {
    padding-bottom: 0px;
  }
  div:first-child {
    padding-left: 0px !important;
  }
  ${devices.mobile} {
    flex-direction: column-reverse !important;
  }
  &.reversedDiv {
    flex-direction: row-reverse !important;
    .accImg--imageContainer {
      padding-left: 0 !important;
      padding-right: 24px !important;
    }
  }
`

const AccordionImage = (props) => {
  const {
    field_component_title: AccordionTitle,
    field_component_desc: AccordionDescription,
    field_cta_1: AccordionButton,
    field_cta_1_style: AccordionButtonStyle,
    field_title_color: AccordionTitleColor,
    field_long_desc_color: AccordionDescriptionColor,
    field_accordian_flag: AccordionDefaultCondition,
    field_component_image: ComponentImage,
    field_alignment: ImageAlignment
  } = props

  const [activeIndex, setActiveIndex] = useState()

  useEffect(() => {
    setActiveIndex(AccordionDefaultCondition)
  }, [])

  const handleClick = () => {
    setActiveIndex(!activeIndex)
  }

  return (
    <div className='main-container'>
      <StyledContainer className='accimg--container'>
        <AccordionSemantic className='accimg--subContainer' fluid>
          <div key={`accordion_tab_${activeIndex}`}>
            <AccordionSemantic.Title
              className='accimg--title'
              active={activeIndex}
              onClick={handleClick}
              style={{ ...setTextColor(AccordionTitleColor), alignItems: 'center', lineHeight: 'normal' }}
            >
              <span className='accimg--accInfo' dangerouslySetInnerHTML={{ __html: AccordionTitle?.value }} />
              <span className='chevron-arrow'>{activeIndex ? <CheveronUpIcon /> : <CheveronDownIcon />}</span>
            </AccordionSemantic.Title>
            <AccordionSemantic.Content
              className='accimg--content'
              active={activeIndex}
              style={{ ...setTextColor(AccordionDescriptionColor) }}
            >
              <Grid>
                <StyledGridRow className={ImageAlignment === 1 ? 'reversedDiv' : null}>
                  <StyledGridColumn computer={11} tablet={10} mobile={16}>
                    <StyledContentContainer
                      className='accimg--textContainer'
                      dangerouslySetInnerHTML={{ __html: AccordionDescription?.value }}
                    />
                    {AccordionButton ? (
                      <StyledButton>
                        <PrimaryButton
                          text={AccordionButton?.title}
                          className='accimg--button'
                          variant={AccordionButtonStyle}
                          sendTo={AccordionButton?.uri}
                        />
                      </StyledButton>
                    ) : null}
                  </StyledGridColumn>
                  <StyledGridColumn className='accImg--imageContainer' computer={5} tablet={6} mobile={16}>
                    <StyledAccordionImage classsName='accImg--imageStyle' src={ComponentImage?.url} alt={ComponentImage?.alt} />
                  </StyledGridColumn>
                </StyledGridRow>
              </Grid>
            </AccordionSemantic.Content>
          </div>
        </AccordionSemantic>
      </StyledContainer>
    </div>
  )
}

export default AccordionImage
