import React from 'react'
import { Segment, Image, Button, Input } from 'semantic-ui-react'
import { ReactSVG } from 'react-svg'
import importer from '@takedapdt/biolife-core/src/importer'
const { getCssColorFromDrupalColorType, setTextColor } = importer('Constant/Utils', null)
const { COMMONVIEW_TYPES } = importer('Constant/commonviewComponentTypes', null)
import inputBtnArrowIcon from '../../../../assets/media/icons/button-arrow-solid-orange.svg'

const CommonView = ({ sectionType, contentData }) => {
  switch (sectionType) {
    case COMMONVIEW_TYPES.BIOLIFE_LOGO_BLOCK:
      return (
        <Segment basic className='BiolifeLogoContainer'>
          <Image src={contentData?.biolifeLogo?.iconUrl} alt={contentData?.biolifeLogo?.alt} size='medium' width='300' height='60' />
        </Segment>
      )
    case COMMONVIEW_TYPES.APP_STORE_LOGOS_BLOCK:
      return (
        <Segment basic>
          <div className='StoreLogoWrapper'>
            <p
              className='DownloadAppText'
              style={{ ...setTextColor(contentData?.downloadAppTitle?.color) }}
              dangerouslySetInnerHTML={{ __html: contentData?.downloadAppTitle?.text }}
            />
            <div className='StoreLogos'>
              <div className='FooterLogoContainer'>
                <Image
                  src={contentData?.appStoreBadge?.iconUrl}
                  as='a'
                  href={contentData?.appStoreBadge?.link}
                  target='_blank'
                  aria-label={contentData?.appStoreBadge?.alt}
                  alt={contentData?.appStoreBadge?.alt}
                  size='medium'
                  width='200'
                  height='60'
                />
              </div>
              <div className='FooterLogoContainer'>
                <Image
                  src={contentData?.googlePlayBadge?.iconUrl}
                  as='a'
                  href={contentData?.googlePlayBadge?.link}
                  target='_blank'
                  aria-label='Link opens in new tab'
                  alt={contentData?.googlePlayBadge?.alt}
                  size='medium'
                  width='200'
                  height='60'
                />
              </div>
            </div>
          </div>
        </Segment>
      )
    case COMMONVIEW_TYPES.SUBSCRIPTION_BOX_BLOCK:
      return (
        <Segment basic className='SubscribeBoxContainer'>
          <p
            className='LatestNewsTitle'
            style={{ ...setTextColor(contentData?.subscribeInputTitle?.color) }}
            dangerouslySetInnerHTML={{ __html: contentData?.subscribeInputTitle?.text }}
          />
          <Input size='big' className='InputBtn' placeholder='Enter your email address' action>
            <input style={{ height: '56px' }} />
            <Button basic icon size='big' className='InputBtnArrow'>
              <ReactSVG src={inputBtnArrowIcon} />
            </Button>
          </Input>
        </Segment>
      )
    case COMMONVIEW_TYPES.TRADEMARK_STATEMENT_BLOCK:
      return (
        <div
          className='TrademarkStatementText'
          style={{ ...setTextColor(contentData?.biolifeTrademarkStatement?.color) }}
          dangerouslySetInnerHTML={{ __html: contentData?.biolifeTrademarkStatement?.text }}
        />
      )
    case COMMONVIEW_TYPES.SOCIAL_MEDIA_ICONS_BLOCK:
      return (
        <>
          {contentData?.socialMediaIcons?.map(
            (item, itemIndex) =>
              item.link &&
              item.iconUrl && (
                <Button
                  key={itemIndex}
                  size='medium'
                  icon
                  className={`IconButton ${item.title}`}
                  href={item?.link?.includes('internal:') ? item?.link?.split(':')[1] : item?.link}
                  target={item?.link?.includes('internal:') ? '_self' : '_blank'}
                  aria-label={item.title}
                >
                  <ReactSVG title={item.alt} src={item.iconUrl} />
                </Button>
              )
          )}
        </>
      )
    case COMMONVIEW_TYPES.TAKEDA_LOGO_BLOCK:
      return (
        <>
          <div className='TakedaFooterLogo'>
            <Image src={contentData?.field_takeda_logo?.iconUrl} alt={contentData?.field_takeda_logo?.alt} />
          </div>
        </>
      )
    default:
      return null
  }
}

export default CommonView
