import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'
import importer from '@takedapdt/biolife-core/src/importer'
const PublicContentActions = importer('Stores/PublicContent/Actions')
const renderDrupalComponent = importer('Constant/renderDrupalComponent')
const LoaderSpinner = importer('Components/Loaders/LoaderSpinner')
import { useTranslation } from 'react-i18next'

const BiolifeHome = ({ fetchHomePageData, homepageData, homepageMetaData, homepageDataLoading }) => {
  useEffect(() => {
    fetchHomePageData()
  }, [])
  
  const { t } = useTranslation('HomePage')

  return (
    <>
      <Helmet>
        <title>{homepageMetaData?.meta_title || t('title')}</title>
        <link rel='canonical' href={`${window.location.origin}${window.location.pathname.toLowerCase()}`} />
        <meta name='description' content={homepageMetaData?.meta_description || t('homePageDescription')}></meta>
      </Helmet>
      {homepageDataLoading ? (
        <LoaderSpinner />
      ) : (
        homepageData?.map((component, index) => {
          return renderDrupalComponent(component, index)
        })
      )}
    </>
  )
}

const mapStateToProps = (state) => ({
  homepageMetaData: state.publicContent?.getdrupalHomepageSuccess?.metadata,
  homepageDataLoading: state.publicContent?.getDrupalHomepageLoading,
  homepageData: state.publicContent?.getdrupalHomepageSuccess?.contents
})

const mapDispatchToProps = (dispatch) => ({
  fetchHomePageData: () => dispatch(PublicContentActions.getDrupalHomepage())
})

export default connect(mapStateToProps, mapDispatchToProps)(BiolifeHome)
