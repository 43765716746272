import React, { useEffect, useState } from 'react'
import { Form, Message } from 'semantic-ui-react'
import { Form as FinalForm, Field } from 'react-final-form'
import { useNavigate } from 'react-router-dom'
import TagManager from 'react-gtm-module'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'

import validate from './validate'
import { isEmpty, isArray } from 'lodash'

import './styles.scss'
import importer from '@takedapdt/biolife-core/src/importer'
const userActions = importer('Stores/Users/Actions')
const donorsActions = importer('Stores/Donors/Actions')
const centersActions = importer('Stores/Centers/Actions')

const { useScreenSize } = importer('Contexts/ResponsiveContextProvider', null)
const PrimaryButton = importer('Components/Buttons/PrimaryButton')
const InputField = importer('Components/Forms/FormFields/InputField')

const SignUpForm = ({
  saveSignUp,
  registerDonor,
  registerDonorSuccess,
  registerDonorFailure,
  registerDonorLoading,
  centers,
  getDonorCentersAll,
  uniqueFormData
}) => {
  const { t } = useTranslation('SignUpForm')
  const navigate = useNavigate()
  const [submited, setSubmited] = useState(false)
  const [errorMessages, setErrorMessages] = useState([])
  const [nextClicked, setNextClicked] = useState(false)
  const { isMobileWidth } = useScreenSize()
  const fieldsWidths = isMobileWidth ? '90%' : '93%'

  const handleRegisterDonor = async (donor) => {
    await registerDonor(donor)
    saveSignUp(donor)
    setSubmited(true)
  }

  const onSubmit = (values) => {
    setNextClicked(true)
    const { isEmailOptIn = true } = values
    const donor = {
      donorDetails: {
        firstName: values['First Name'],
        lastName: values['Last Name'],
        email: values['Email Address'].toLowerCase(),
        zipCode: values['Zip Code'],
        isEmailOptIn,
        gclickId: getGclickId()
      }
    }
    getDonorCentersAll(values['Zip Code'], '', 'pubDate', true) // true corresponds to addNewDonorBonusInformation
    handleRegisterDonor(donor)
  }
  const getGclickId = () => {
    var b = document.cookie.match('(^|;)\\s*' + '_gcl_aw' + '\\s*=\\s*([^;]+)')
    return b ? b.pop().split('.')[2] : null
  }

  useEffect(() => {
    if (registerDonorSuccess != null && submited) {
      const lead = { ...registerDonorSuccess.data }
      TagManager.dataLayer({
        dataLayer: {
          event: 'profileRegistration',
          profileRegistrationStage: 'profile lead generated',
          donorZip: lead.zipCode,
          donorId: lead.id,
          bdpDonorId: lead.bdpDonorId || null
        }
      })
      window.sessionStorage.setItem('lead', JSON.stringify(lead))
      setSubmited(false)
      if (!isEmpty(centers)) {
        navigate('/create-account')
      } else {
        navigate('/no-center-zone')
      }
    }
  }, [registerDonorSuccess])
  useEffect(() => {
    if (registerDonorFailure !== null && submited) {
      console.log('Error: ', registerDonorFailure)
      if (isArray(registerDonorFailure.message)) {
        setErrorMessages(registerDonorFailure.message)
      } else {
        setErrorMessages([registerDonorFailure.message])
      }

      setSubmited(false)
    }
  }, [registerDonorFailure])

  return (
    <div className='main-container'>
      <div className='SignUpForm'>
        <FinalForm
          onSubmit={onSubmit}
          validate={validate}
          render={({ handleSubmit }) => (
            <Form onSubmit={handleSubmit}>
              {uniqueFormData?.map((item, index) => {
                if (item.type === 'form_field_component' && item.field_input_type) {
                  return (
                    <Form.Group key={index}>
                      <Field name={item?.field_component_title_1}>
                        {({ input, meta }) => (
                          <InputField
                            meta={meta}
                            {...input}
                            className='SignUpInput'
                            placeholder={item?.field_component_title_2 || ''}
                            required={item?.field_status}
                            fieldLabel={item?.field_component_title?.value}
                            width={item?.field_component_title_1 === 'Zip Code' ? (!isMobileWidth ? '48%' : '50%') : fieldsWidths}
                            isHtmlLabel
                            id={item?.field_component_title_1}
                          />
                        )}
                      </Field>
                    </Form.Group>
                  )
                }
                if (item.type === 'form_field_component' && !item.field_input_type) {
                  return (
                    <div className='SignUpFormTextContainer' key={index}>
                      <div className='RequiredFieldText'>
                        <span role='presentation' className='asteriskStyle'>
                          *
                        </span>
                        &nbsp;
                        {t('requiredField')}
                      </div>
                      <div dangerouslySetInnerHTML={{ __html: item.field_component_title.value }} className='DisclaimerText' />
                    </div>
                  )
                }
                if (item.type === 'form_cta_component') {
                  return (
                    <Form.Group className='SignUpButtonContainer' key={index}>
                      <PrimaryButton
                        text={item.field_cta_1?.title}
                        variant={item?.field_cta_1_style}
                        disabled={registerDonorLoading && nextClicked}
                        fluid={isMobileWidth}
                      />
                    </Form.Group>
                  )
                }
              })}
            </Form>
          )}
        />
        {!isEmpty(errorMessages) && (
          <Message error style={{ maxWidth: 396 }}>
            {errorMessages.map((message, index) => (
              <p key={index}>{message}</p>
            ))}
          </Message>
        )}
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  registerDonorSuccess: state.donors.registerDonorSuccess,
  registerDonorFailure: state.donors.registerDonorFailure,
  registerDonorLoading: state.donors.registerDonorLoading,
  centers: state.centers.donorCenters?.data
})

const mapDispatchToProps = (dispatch) => ({
  saveSignUp: (user) => dispatch(userActions.saveSignUp(user)),
  registerDonor: (donor) => dispatch(donorsActions.registerDonor(donor)),
  getDonorCentersAll: (searchStr, isDIS8Center, searchType, addNewDonorBonusInformation) =>
    dispatch(centersActions.getDonorCentersAll(searchStr, isDIS8Center, searchType, addNewDonorBonusInformation))
})

export default connect(mapStateToProps, mapDispatchToProps)(SignUpForm)
