import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { ROUTES } from '../../NavigationService'
import importer from '@takedapdt/biolife-core/src/importer'
import Modal from '../Modal'
import DebitHistoryPage from '../DebitHistoryPage'
const donorsActions = importer('Stores/Donors/Actions')
const WirecardActions = importer('Stores/Wirecard/Actions')
const LoaderSpinner = importer('Components/Loaders/LoaderSpinner')
const renderDrupalComponent = importer('Constant/renderDrupalComponent')
import DebitHistoryUnavailablePopup from '../DebitHistoryUnavailablePopup'
import { useLocation, useParams, useNavigate } from 'react-router-dom'

const DebitHistoryContainer = ({ componentList, metaData }) => {
  const navigate = useNavigate()
  const debitCardHistory = useSelector((state) => state?.wirecard?.getWirecardInfoSuccess?.data)
  const debitCardLoading = useSelector((state) => state?.wirecard?.getWirecardInfoLoading)
  const debitCardFailure = useSelector((state) => state?.wirecard?.getWirecardInfoFailure)
  const donor = useSelector((state) => state.donors.donors?.data)
  const [dataLoaded, setDataLoaded] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  const { t } = useTranslation('donorDashboardPage')


  useEffect(() => {
    if (debitCardHistory?.journal?.length) {
      setModalOpen(false)
    } else if ((debitCardHistory?.journal?.length === undefined && debitCardLoading === false && debitCardFailure) || !donor?.pdn) {
      setModalOpen(true)
    }
    if (debitCardLoading === false) {
      setDataLoaded(true)
    }
  }, [debitCardHistory, debitCardLoading])

  const handleCloseModal = () => {
    navigate(`/${ROUTES.Debit}`)
    setModalOpen(false)
  }

  return (
    <>
      {!dataLoaded ? (
        <LoaderSpinner />
      ) : (
        <>
          {componentList?.map((component, index) => {
            if (component?.type === 'breadcrumb') {
              let tempComp = {
                ...component,
                breadcrumbToggle: metaData?.field_content_breadcrumb,
                breadcrumbMobileToggle: metaData?.field_mobile_breadcrumb,
                clickableLevelTwo: true
              }
              component = tempComp
            }
            if (component?.type === 'pop_up_component' && component.field_poup_type === 'debit_card_history_unavailable') {
              return (
                <div className='debit_card_history_unavailable'>
                  <Modal
                    isOpen={modalOpen}
                    onClose={() => handleCloseModal()}
                    maxWidth={836}
                    width={'80%'}
                    maxWidthMobile={'87.7%'}
                    maxHeight={461}
                    maxHeightMobile={360}
                  >
                    <DebitHistoryUnavailablePopup modalContent={component}></DebitHistoryUnavailablePopup>
                  </Modal>
                </div>
              )
            } else if (component?.type === 'ext_integration_component' && component?.field_integration_type === 'debit_history') {
              return (
                <DebitHistoryPage
                  donor={donor}
                  field_component_title={component.field_component_title}
                  field_title_color={component.field_title_color}
                />
              )
            }
            return renderDrupalComponent(component, index)
          })}
        </>
      )}
    </>
  )
}

export default DebitHistoryContainer
