import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import Modal from '../Modal'
import { getCssColorFromDrupalColorType, getMediaFromDrupalMimeType, setTextColor } from '../../Constant/Utils'
import LoaderSpinner from '../Loaders/LoaderSpinner'
import renderDrupalComponent from '../../Constant/renderDrupalComponent'
import { devices } from '../../utils/styledComponentHelpers'
import { useScreenSize } from '../../Contexts/ResponsiveContextProvider'
import { ReactComponent as DownArrow } from '../../../assets/media/icons/icon-chevron-down.svg'
import { useLocation } from 'react-router-dom'

const StyledBannerDiv = styled.div`
  background-color: ${(props) => props.$bgColor && getCssColorFromDrupalColorType(props?.$bgColor)};
  display: ${(props) => (props.$showOnWeb ? 'flex' : 'none')};
  ${devices.mobile} {
    display: ${(props) => (props.$showOnMweb ? 'flex' : 'none')};
  }
`

const StyledBannerInnerDiv = styled.div`
  padding: 1.6rem 0;
  display: flex;
  align-self: center;
  justify-content: space-between;
  gap: 2.8rem;
  a {
    color: ${(props) => props.$titleColor && getCssColorFromDrupalColorType(props?.$titleColor)};
    text-decoration: underline;
  }
`

const LoaderDiv = styled.div`
  width: 83.6rem;
  height: 70rem;
  ${devices.mobile} {
    width: 34.5rem;
    height: 40rem;
  }
`

const StyledAllOffersDiv = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.8rem;

  svg {
    path:first-child {
      fill: ${(props) => props.$titleColor};
    }
  }
`

const ModalInnerDiv = styled.div`
  padding: 8rem 8.6rem;
  ${devices.mobile} {
    padding: 8rem 0rem;
  }
  .text-component-description {
    padding-top: 1.6rem;
    padding-bottom: 3.2rem;
  }
  .main-container.separator-wrapper {
    padding: 0;
  }
  .styled-cta-container {
    .OrangeArrowTextButton {
      ${devices.mobile} {
        width: unset !important;
      }
    }
  }
`

const PromotionalBanner = ({ promotionalBannerData, promotionalBannerDataLoading }) => {
  const { isMobileWidth } = useScreenSize()
  const [isOpenPromotionalBannerPopup, setIsOpenPromotionalBannerPopup] = useState()
  const [promoContentHeader, setPromoContentHeader] = useState({})
  const location = useLocation()

  useEffect(() => {
    setIsOpenPromotionalBannerPopup(false)
  }, [location])

  useEffect(() => {
    setPromoContentHeader(promotionalBannerData?.contents?.find((comp) => comp.type === 'promotional_content_header'))
  }, [promotionalBannerData])

  return (
    <>
      {promotionalBannerData?.contents && (
        <StyledBannerDiv
          $bgColor={promoContentHeader?.field_bg_color}
          $showOnWeb={promoContentHeader?.field_promo_display?.includes('web')}
          $showOnMweb={promoContentHeader?.field_promo_display?.includes('m_web')}
        >
          <StyledBannerInnerDiv className='main-container' $titleColor={promoContentHeader?.field_long_desc_color}>
            <div
              dangerouslySetInnerHTML={{ __html: promoContentHeader?.field_component_desc?.processed }}
              style={setTextColor(promoContentHeader?.field_long_desc_color)}
            />
            <StyledAllOffersDiv
              onClick={() => setIsOpenPromotionalBannerPopup(true)}
              $titleColor={promoContentHeader?.field_long_desc_color}
            >
              {!isMobileWidth && (
                <div
                  dangerouslySetInnerHTML={{ __html: promoContentHeader?.field_cta_1?.title }}
                  style={setTextColor(promoContentHeader?.field_long_desc_color)}
                />
              )}
              <DownArrow width={24} height={24} />
            </StyledAllOffersDiv>
          </StyledBannerInnerDiv>
        </StyledBannerDiv>
      )}
      <Modal
        isOpen={isOpenPromotionalBannerPopup}
        onClose={() => {
          setIsOpenPromotionalBannerPopup(false)
        }}
        maxWidth={836}
        maxWidthMobile={346}
        maxHeight={699}
        maxHeightMobile={693}
      >
        {promotionalBannerDataLoading ? (
          <LoaderDiv>
            <LoaderSpinner />
          </LoaderDiv>
        ) : (
          <ModalInnerDiv>
            {promotionalBannerData?.contents?.map((component, index) => {
              if (component.type === 'promotional_content_header') return
              return renderDrupalComponent(component, index)
            })}
          </ModalInnerDiv>
        )}
      </Modal>
    </>
  )
}

const mapStateToProps = (state) => ({
  promotionalBannerData: state.publicContent?.getPromotionalBannerSuccess,
  promotionalBannerDataLoading: state.publicContent?.getPromotionalBannerLoading
})

export default connect(mapStateToProps, null)(PromotionalBanner)
