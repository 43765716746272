import React, { useEffect, useState } from 'react'
import { Breadcrumb } from 'semantic-ui-react'
import './styles.scss'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { ReactComponent as IconHome } from '../../../assets/media/icons/Breadcrumb-Home-Icon.svg'
import { ReactComponent as BackIcon } from '../../../assets/media/icons/back-icon-grey.svg'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { ROUTES } from '../../NavigationService'
import { useScreenSize } from '../../Contexts/ResponsiveContextProvider'
import { getCssColorFromDrupalColorType } from '../../Constant/Utils'
/* all passed props
  drupalFieldData : field_column_1_url
  props to component : levelOnePropsTitle, clickableLevelOne, levelTwoPropsTitle, clickableLevelTwo, levelThreePropsTitle
  breadcrumbToggle, breadcrumbMobileToggle : getting from metaData of specific page
*/
const Breadcrumbs = ({
  breadcrumbToggle = true,
  breadcrumbMobileToggle = true,
  clickableLevelOne,
  field_column_1_url: levelOneDrualData = {},
  field_bg_color: backgroundColor,
  levelOnePropsTitle,
  levelTwoPropsTitle,
  clickableLevelTwo,
  levelThreePropsTitle,
  drupalNavData,
  drupalDashboardData,
  title: titleToShow
}) => {
  const { isMobileWidth } = useScreenSize()
  const { t, i18n } = useTranslation('breadCrumbs')

  let hrefLocation = useLocation()
  const navigate = useNavigate()
  const [levelsData, setLevelsData] = useState([])
  const [backToLevel, setBackToLevel] = useState(false)
  const [breadcrumbsDisplay, setBreadcrumbsDisplay] = useState(false)
  useEffect(() => {
    getPageLevels()
    setBreadcrumbsDisplay(isMobileWidth ? breadcrumbMobileToggle : breadcrumbToggle)
  }, [levelOnePropsTitle, levelTwoPropsTitle, levelThreePropsTitle, drupalNavData, drupalDashboardData, isMobileWidth])

  /* @example
    gets levels data from url, convert it to array of levels and check titles
    checks level1 level2 and level3 props and updates the title accordingly 
  */
  const getPageLevels = () => {
    let urlLevelsArray = hrefLocation?.pathname.split('/')
    urlLevelsArray.shift()
    let isSingleLevel = true
    /* setting initial levelsArray data to home page level data */
    let levelsArray = [{ title: 'home', uri: '/', clickable: true }]
    /* checking for title from the navbar */
    let titleFromNavbar = drupalNavData?.find((navLink) => navLink.href.includes(urlLevelsArray[0]))
    /* checking each level and assigning necessary properties for each level */
    urlLevelsArray.map((level, index) => {
      let levelTitle = level.split('-').join(' ')
      let levelData = { title: levelTitle === 'faqs' ? t('faqs') : levelTitle, uri: `/${level}`, clickable: false }
      /* for Level 1 and so on */
      if (index == 0) {
        levelData.clickable = clickableLevelOne
        levelData.title = levelOneDrualData?.title || levelOnePropsTitle || titleFromNavbar?.title || levelData.title
        /* for pages with url as dashboard with more than a single level */
        if (level === 'dashboard' && urlLevelsArray.length > 1) {
          levelData.uri = ROUTES.DonorDashboard
          levelData.clickable = true
        }
      } else if (index == 1) {
        isSingleLevel = false
        clickableLevelTwo && (levelData.clickable = clickableLevelTwo)
        levelData.uri = `/${urlLevelsArray[0]}/${level}`
        /* checking for title from the navbar */
        let titleFromSubNavbar = titleFromNavbar?.submenu?.find((navLink) => navLink?.href === levelData.uri)
        /* checking for title from the dashboard menu */
        let titleFromDashboardMenu = drupalDashboardData?.find((navLink) => navLink?.href === levelData.uri)
        levelData.title = levelTwoPropsTitle || titleFromSubNavbar?.title || titleFromDashboardMenu?.title || levelData.title
        /* Spanish translations */
        if (i18n.language === 'es-MX') {
          let translatedTitle = levelData.title
          i18n.exists(`breadCrumbs:${levelData.title.replace(/ /g, '')}`) && (translatedTitle = t(`${levelData.title.replace(/ /g, '')}`))
          levelData.title = translatedTitle
        }
        /* updating urls for debit history and rewards history pages */
        if (levelData.uri === '/dashboard/debit') levelData.uri = `/${ROUTES.Debit}`
        if (levelData.uri === '/dashboard/my-rewards') levelData.uri = `/${ROUTES.MyRewards}`
        if (levelData.uri.includes('blog'))
          levelData.title = titleToShow && titleToShow.length > 50 ? `${titleToShow.substring(0, 50)}...` : titleToShow || levelData.title
      } else if (index === 2) {
        levelThreePropsTitle && (levelData.title = levelThreePropsTitle)
        if (i18n.language === 'es-MX') {
          let translatedTitle = levelData.title
          i18n.exists(`breadCrumbs:${levelData.title.replace(/ /g, '')}`) && (translatedTitle = t(`${levelData.title.replace(/ /g, '')}`))
          levelData.title = translatedTitle
        }
        if (levelsArray.find((el) => el.uri.includes('news')))
          levelData.title = titleToShow && titleToShow.length > 30 ? `${titleToShow.substring(0, 30)}...` : titleToShow || levelData.title
      }
      levelsArray.push(levelData)
    })
    /* for mobile view with more then one level */
    if (isMobileWidth && !isSingleLevel) {
      setBackToLevel(true)
      const anyPrevLevelClickable = levelsArray.reverse().find((level) => level.clickable)
      anyPrevLevelClickable && setLevelsData([anyPrevLevelClickable])
    } else {
      setBackToLevel(false)
      setLevelsData(levelsArray)
    }
  }

  /*
    @example
    displayLevels({ prop used for Breadcrumb.Section  }, title of the level )
    displayLevels({as:Link, to:href_location, classname:'class'}, title)
    returns <>
              if "to:other than homepage" show <BackIcon />
              <Breadcrumb.Section as={Link} to={href_location} className='class'>
                    if "to:"/" then {homeIcon} or {title}
              </Breadcrumb.Section>
            </>
  */
  const displayLevels = (inputProps, title, key) => {
    return (
      <div key={key} style={{ display: 'flex' }}>
        <BackIcon
          className={backToLevel ? 'mobileViewArrow' : inputProps.to === '/' ? 'hide' : 'rightArrow'}
          onClick={() => navigate(inputProps.to)}
        />
        <Breadcrumb.Section {...inputProps}>
          {backToLevel && <span>{t('backTo')} </span>}
          {/* if home uri, show icon/text depending on backtToLevel else show the level title */}
          {inputProps.to === '/' ? (
            backToLevel ? (
              <span>{t('home')}</span>
            ) : (
              <IconHome aria-label={t('home')} className='svgHome' />
            )
          ) : (
            <span style={{ textTransform: 'capitalize' }}>{title}</span>
          )}
        </Breadcrumb.Section>
      </div>
    )
  }

  return breadcrumbsDisplay ? (
    <div
      className='paddedRegion'
      style={{
        background: getCssColorFromDrupalColorType(backgroundColor)
      }}
    >
      <Breadcrumb className='main-container Breadcrumbs'>
        {levelsData?.map((level, index) =>
          displayLevels(
            level?.clickable
              ? {
                  as: Link,
                  to: level?.uri,
                  className: 'Homelink'
                }
              : {
                  className: index === levelsData.length - 1 ? 'HomeText lastLevel' : 'HomeText'
                },
            level?.title,
            index
          )
        )}
      </Breadcrumb>
    </div>
  ) : null
}

const mapStateToProps = (state) => ({
  drupalNavData: state.publicContent.drupalMenuSuccess?.main,
  drupalDashboardData: state.publicContent.drupalMenuSuccess?.userDashboardMenu
})

export default connect(mapStateToProps, null)(Breadcrumbs)
