import React from 'react'
import { Card, Grid, Image } from 'semantic-ui-react'
import { ReactComponent as Quotes } from '../../../../assets/media/icons/quote-mark-48.svg'
import './styles.scss'
import importer from 'common/importer'
const { getMediaFromDrupalMimeType, getCssColorFromDrupalColorType, setTextColor } = importer('Constant/Utils', null)

const TestimonialCard = ({
  field_component_image,
  field_component_title_1,
  field_component_title_2,
  field_short_desc_color,
  field_short_description
}) => {
  return (
    <Card className='TestimonialCardContainer' raised>
      <Card.Content>
        <Card.Header>
          <Quotes />
        </Card.Header>
        <Card.Description>
          <div
            className='PatientVerbiage'
            dangerouslySetInnerHTML={{ __html: field_short_description?.processed }}
            style={{ ...setTextColor(field_short_desc_color) }}
          />
          <Grid className='PatientDetails'>
            <Grid.Row columns={2} verticalAlign='middle'>
              <Grid.Column computer={4}>
                {getMediaFromDrupalMimeType(field_component_image?.mimetype, field_component_image?.url, field_component_image?.alt)}
              </Grid.Column>
              <Grid.Column verticalAlign='middle' computer={12}>
                <Grid.Row>
                  <h5>{field_component_title_1}</h5>
                </Grid.Row>
                <Grid.Row>
                  <p>{field_component_title_2}</p>
                </Grid.Row>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Card.Description>
      </Card.Content>
    </Card>
  )
}

export default TestimonialCard
