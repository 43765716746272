import React, { useState, useRef } from 'react'
import { HeaderMainContainer, HeaderText, SideOptionsStyledComponent, HeaderImage } from './header.styled'
import KababChatBoticon from '../../../../assets/media/icons/KababChatBoticon.svg'
import CloseIconChatbot from '../../../../assets/media/icons/CloseIconChatbot.svg'
import MinimizeChatbotLine from '../../../../assets/media/icons/MinimizeChatbotLine.svg'
import KababModal from '../Components/KababModal'
import ChatbotOptions from '../Modals/ChatbotOptions'

function ChatBotHeader({ text, toggleChatbot, openCloseChatbotpopup, resetBotState }) {
  const [openKababModal, setOpenKababModal] = useState(false)
  const kababRef = useRef()
  const mousePosition = useRef({ x: 0, y: 0 })
  const handleKababAction = (e) => {
    mousePosition.current.x = kababRef?.current?.getBoundingClientRect()?.left - 100 || 0
    mousePosition.current.y = kababRef?.current?.getBoundingClientRect()?.bottom + 10 || 0
    handleToggleKabab()
  }
  const handleToggleKabab = () => {
    setOpenKababModal((prev) => !prev)
  }

  const resetFromKabab = (value) => {
    handleToggleKabab()
    resetBotState(value)
  }

  return (
    <HeaderMainContainer>
      <HeaderText>{text}</HeaderText>
      <SideOptionsStyledComponent>
        <HeaderImage src={KababChatBoticon} ref={kababRef} onClick={(e) => handleKababAction(e)} />
        <HeaderImage src={MinimizeChatbotLine} onClick={toggleChatbot} />
        <HeaderImage src={CloseIconChatbot} onClick={openCloseChatbotpopup} />
      </SideOptionsStyledComponent>
      <KababModal isOpen={openKababModal} mousePositions={mousePosition.current}>
        <ChatbotOptions options={[{ text: 'Reset Chat', onClicke: (value) => resetFromKabab(value) }]} />
      </KababModal>
    </HeaderMainContainer>
  )
}

export default ChatBotHeader
