import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { formatDate, getTimeFromTimestamp, formatDateByMonth } from '../../Helpers/Utils'
import donorsActions from '@takedapdt/biolife-core/Stores/Donors/Actions'
import { getCssColorFromDrupalColorType } from '../../Constant/Utils'
import Accordion from '../../Components/Accordion'
import { useScreenSize } from '../../Contexts/ResponsiveContextProvider'
import i18next from 'i18next'
import { devices } from '../../utils/styledComponentHelpers'
import { lastDayOfDecade } from 'date-fns'
import moment from 'moment'
const ItemTime = styled.div`
  font-size: var(--p-font-size);
  ${devices.mobile} {
    font-size: var(--caption-font-size);
  }
`

const ItemType = styled.div`
  font-size: var(--p-font-size);
  color: var(--color-black) !important;
  ${devices.mobile} {
    font-size: var(--caption-font-size);
  }
`

const ItemCenter = styled.div`
  font-size: var(--p-font-size);
  ${devices.mobile} {
    font-size: var(--caption-font-size);
  }
`
const ItemDate = styled.strong`
  font-size: var(--p-font-size);
  ${devices.mobile} {
    font-size: var(--caption-font-size);
  }
`

const Table = styled.table`
  width: 100%;
  padding-top: 3.2rem;
`
const NoRecordContent = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding-top: 3.2rem;
`
const TableRow = styled.tr`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  padding-bottom: 2rem;
`
const TableRow2 = styled.tr`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  padding-bottom: 1.1rem;
`
const TableRow3 = styled.tr`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  padding-bottom: 2rem;
  border-bottom: 0.5px solid var(--color-silver);
  margin-bottom: 2rem;
`
const TableCell = styled.td`
  padding: 0.5rem;
`

const TableCell2 = styled(TableCell)`
  color: var(--color-dark-grey);
`

const MobileItemDate = styled.strong`
  font-size: 1.6rem;
  padding: 0 0 1.6rem 0;
`
const AccordionDetails = styled.div`
  border-top: 1px solid var(--color-silver);
  padding-top: 2.4rem;

  &:first-child {
    margin-top: 2.4rem;
  }
`

const AppointmentHistory = ({
  donor,
  appointments,
  inquiryDonor,
  inquiry,
  field_component_title,
  field_title_color,
  getUpcomingAppointments
}) => {
  const [inquiryData, setInquiryData] = useState()
  const { isMobileWidth } = useScreenSize()
  const { t } = useTranslation('AppointmentHistory')
  useEffect(() => {
    if (donor?.pdn) {
      inquiryDonor(donor?.pdn)
    }
  }, [donor])
  useEffect(() => {
    const lastThreeMonths = []
    const currentDate = moment()
    for (let i = 0; i < 3; i++) {
      let month = currentDate.clone().subtract(i, 'months')
      lastThreeMonths.push(month.format('MMMM YYYY'))
    }
    const transformedAppointmentHistory = new Map()
    lastThreeMonths.forEach((monthYear) => {
      transformedAppointmentHistory.set(monthYear, [])
    })
    inquiry?.pastDonations?.map((item) => {
      const datePart = item?.donationDate
      const itemDate = moment(datePart, 'YYYY-MM-DD')
      const date = itemDate.format('MMMM YYYY')
      if (transformedAppointmentHistory.has(date)) {
        transformedAppointmentHistory.get(date).push({
          ...item,
          centerName: item.centerName
        })
      } else {
        transformedAppointmentHistory.set(date, [
          {
            ...item,
            centerName: item.centerName
          }
        ])
      }
    })
    transformedAppointmentHistory.forEach((value, key) => {
      if (value.length === 0) {
        transformedAppointmentHistory.set(key, ['NoRecords'])
      }
    })
    setInquiryData(transformedAppointmentHistory)
  }, [inquiry, i18next.language])

  return (
    <div className='main-container appointment-history-container'>
      <span
        style={{ color: getCssColorFromDrupalColorType(field_title_color), padding: '2rem 3rem' }}
        dangerouslySetInnerHTML={{ __html: field_component_title?.value }}
      />
      <>
        {inquiryData &&
          Array.from(inquiryData).map(([key, value], index) => (
            <Accordion
              key={index}
              title={key}
              field_title_color={field_title_color}
              description={(() => {
                if (value.length === 1 && typeof value[0] === 'string' && value[0] === 'NoRecords') {
                  return <NoRecordContent>{t('NoRecords')}</NoRecordContent>
                } else {
                  return isMobileWidth ? <RenderListMobileView description={value} /> : <RenderList description={value} />
                }
              })()}
            />
          ))}
      </>
    </div>
  )
}

const RenderList = ({ description }) => {
  const { t } = useTranslation('AppointmentHistory')
  return (
    <Table>
      <tbody>
        <TableRow>
          <TableCell2>{t('date')}</TableCell2>
          <TableCell2>{t('center')}</TableCell2>
          <TableCell2>{t('type')}</TableCell2>
        </TableRow>
        {description?.map((item, index) => (
          <>
            <TableRow3 key={index}>
              <TableCell>
                <ItemDate>{formatDate(item.donationDate)}</ItemDate>
              </TableCell>
              <TableCell>
                <ItemCenter>{item.centerName}</ItemCenter>
              </TableCell>
              <TableCell>
                <ItemType>{t('donation')}</ItemType>
              </TableCell>
            </TableRow3>
          </>
        ))}
      </tbody>
    </Table>
  )
}

const RenderListMobileView = ({ description }) => {
  const { t } = useTranslation('AppointmentHistory')
  return (
    <>
      {description?.map((item, index) => (
        <AccordionDetails key={index}>
          <TableRow2>
            <TableCell><MobileItemDate>{formatDate(item.donationDate)}</MobileItemDate></TableCell>
          </TableRow2>
          <TableRow2>
            <TableCell2>{t('center')}</TableCell2>
            <TableCell>{item.centerName}</TableCell>
          </TableRow2>
          <TableRow2>
            <TableCell2>{t('type')}</TableCell2>
            <TableCell>{t('donation')}</TableCell>
          </TableRow2>
        </AccordionDetails>
      ))}
    </>
  )
}

const mapStateToProps = (state) => ({
  appointments: state.appointments,
  inquiry: state.donors.inquiryDonorSuccess?.data,
  donor: state.donors.donors?.data
})

const mapDispatchToProps = (dispatch) => ({
  inquiryDonor: (pdn) => dispatch(donorsActions.inquiryDonor(pdn)),
  getUpcomingAppointments: (donorSfid) => dispatch(AppointmentsActions.getUpcomingAppointments(donorSfid))
})

export default connect(mapStateToProps, mapDispatchToProps)(AppointmentHistory)
