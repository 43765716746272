import { combineReducers } from 'redux'

import importer from '@takedapdt/biolife-core/src/importer'

const rootSaga = importer('Sagas/custom')
const configureStore = importer('Stores/CreateStore')

/* CUSTOM SDK REDUCERS */
const customSDKReducers = importer('Stores/CustomSDKReducers')
/* */
//const { reducer: AuthReducer } = importer('Stores/Auth/Reducers', null)
//const { reducer: AwsReducer } = importer('Stores/Aws/Reducers', null)

export default () => {
  const rootReducer = combineReducers({
    ...customSDKReducers
    //auth: AuthReducer,
    //aws: AwsReducer
  })

  return configureStore(rootReducer, rootSaga)
}
