import styled from 'styled-components'
import { devices } from '../../../../utils/styledComponentHelpers'

export const KababOptionsContainer = styled.div``

export const KababOption = styled.div`
  padding: 14px 18px;
  cursor: pointer;
`

export const KababOptionText = styled.p`
  font-size: var(--p-font-size);
  color: var(--color-rich-black);
  cursor: pointer;
`
