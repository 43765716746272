import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { isEmpty } from 'lodash'
import { Helmet } from 'react-helmet'

import './styles.scss'
/* DYNAMIC IMPORTS */
import importer from '@takedapdt/biolife-core/src/importer'

const { SLUG_TYPES } = importer('Sagas/Constants/drupalConstants', null)
/* COMPOENTS */
const PublicContentActions = importer('Stores/PublicContent/Actions')
const renderDrupalComponent = importer('Constant/renderDrupalComponent')
const { getPathDetails } = importer('Helpers/Utils', null)
const LoaderSpinner = importer('Components/Loaders/LoaderSpinner')

const LogIn = ({ fetchFormsData, FormsData, FormsDataMeta, FormsDataLoading }) => {
  useEffect(() => {
    fetchFormsData(getPathDetails.slugType || SLUG_TYPES?.LOGIN)
  }, [])
  return (
    <>
      {!isEmpty(FormsDataMeta) && (
        <Helmet>
          <title>{`${FormsDataMeta?.meta_title}`}</title>
          <meta name='description' content={`${FormsDataMeta?.meta_description}`} />
          <link rel='canonical' href={`${window.location.origin}${window.location.pathname.toLowerCase()}`} />
        </Helmet>
      )}
      {FormsDataLoading ? (
        <LoaderSpinner />
      ) : (
        <div className='LoginContainer'>
          {FormsData?.contents?.map((component, index) => {
            if (component.type === 'breadcrumb') {
              let tempComp = {
                ...component,
                breadcrumbToggle: FormsData?.metadata?.field_content_breadcrumb,
                breadcrumbMobileToggle: FormsData?.metadata?.field_mobile_breadcrumb
              }
              component = tempComp
            }
            return renderDrupalComponent(component, index)
          })}
        </div>
      )}
    </>
  )
}
const mapStateToProps = (state) => ({
  /* AWS */
  FormsDataLoading: state.publicContent?.getFormsPageLoading?.login,
  FormsData: state.publicContent?.getFormsPageSuccess?.login,
  FormsDataMeta: state.publicContent?.getFormsPageSuccess?.login?.metadata
})

const mapDispatchToProps = (dispatch) => ({
  fetchFormsData: (formType) => dispatch(PublicContentActions.getFormsPage(formType))
})

export default connect(mapStateToProps, mapDispatchToProps)(LogIn)
