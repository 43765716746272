import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'
/* DYNAMIC IMPORTS */
import importer from '@takedapdt/biolife-core/src/importer'
const LoaderSpinner = importer('Components/Loaders/LoaderSpinner')
/* COMPONENTS */
import './style.scss'

const { SLUG_TYPES } = importer('Sagas/Constants/drupalConstants', null)
const PublicContentActions = importer('Stores/PublicContent/Actions')
const renderDrupalComponent = importer('Constant/renderDrupalComponent')
const { getArrayFromTextCTAGrid, getPathDetails } = importer('Helpers/Utils', null)
const { ScrollToTop } = importer('Helpers/Utils', null)

const CenterNotFound = ({ pageDataLoading, pageData, fetchPageData }) => {
  const [finalData, setFinalData] = useState({})

  useEffect(() => {
    fetchPageData(getPathDetails.slugType || SLUG_TYPES?.NOCENTERFOUND)
  }, [])

  useEffect(() => {
    ScrollToTop()
  }, [])
  useEffect(() => {
    if (pageData) {
      const dataWithTextCTAGrid = getArrayFromTextCTAGrid(pageData)

      if (Object.keys(dataWithTextCTAGrid)?.length) {
        setFinalData(dataWithTextCTAGrid)
      }
    }
  }, [pageData])
  return (
    <>
      <Helmet>
        <title>{`${finalData?.metadata?.meta_title}`} </title>
        <meta name='description' content={`${finalData?.metadata?.meta_description}`} />
        <link rel='canonical' href={`${window.location.origin}${window.location.pathname.toLowerCase()}`} />
      </Helmet>
      {pageDataLoading ? (
        <LoaderSpinner />
      ) : (
        <div className='NoCenterFoundContainer'>
          {finalData &&
            finalData?.contents?.map((component, index) => {
              if (component.type === 'breadcrumb') {
                let tempComp = {
                  ...component,
                  breadcrumbToggle: finalData?.metadata?.field_content_breadcrumb,
                  breadcrumbMobileToggle: finalData?.metadata?.field_mobile_breadcrumb
                }
                component = tempComp
              }
              return renderDrupalComponent(component, index)
            })}
        </div>
      )}
    </>
  )
}

const mapStateToProps = (state) => ({
  pageDataLoading: state.publicContent?.getRegistrationPageLoading?.noCenterZone,
  pageData: state.publicContent?.getRegistrationPageSuccess?.noCenterZone
})

const mapDispatchToProps = (dispatch) => ({
  fetchPageData: (formType) => dispatch(PublicContentActions.getRegistrationPage(formType))
})

export default connect(mapStateToProps, mapDispatchToProps)(CenterNotFound)
