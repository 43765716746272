import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'
import importer from '@takedapdt/biolife-core/src/importer'
import { useLocation, useNavigate } from 'react-router-dom'
const renderDrupalComponent = importer('Constant/renderDrupalComponent')
const { SLUG_TYPES } = importer('Sagas/Constants/drupalConstants', null)
const PublicContentActions = importer('Stores/PublicContent/Actions')
const { getPathDetails } = importer('Helpers/Utils', null)
const LoaderSpinner = importer('Components/Loaders/LoaderSpinner')
const UserActions = importer('Stores/Users/Actions')

const ResetPassword = ({
  fetchFormsData,
  FormsData,
  FormsDataLoading,
  verifyToken,
  resetPasswordSuccess,
  resetPassword,
  verifyTokenSuccess
}) => {
  const location = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    fetchFormsData(getPathDetails.slugType || SLUG_TYPES?.RESETPASSWORD)
  }, [])

  useEffect(() => {
    if (location.search) {
      const searchParams = new URLSearchParams(location.search)
      const token = searchParams.get('token')
      if (token) {
        verifyToken(token)
      }
    }
  }, [location])

  useEffect(() => {
    if (resetPasswordSuccess && !resetPasswordSuccess.data.isError) {
      navigate('/account-setup-success')
    }
  }, [resetPasswordSuccess])

  const handleSubmit = (values) => {
    resetPassword(verifyTokenSuccess, values['New Password'])
  }

  return (
    <>
      <Helmet>
        <title>{`${FormsData?.metadata?.meta_title}`} </title>
        <meta name='description' content={`${FormsData?.metadata?.meta_description}`} />
        <link rel='canonical' href={`${window.location.origin}${window.location.pathname.toLowerCase()}`} />
      </Helmet>
      {FormsDataLoading ? (
        <LoaderSpinner />
      ) : (
        FormsData?.contents.map((component, index) => {
          if (component.type === 'breadcrumb') {
            let tempComp = { ...component, breadcrumbToggle: FormsData?.metadata?.field_content_breadcrumb }
            component = tempComp
          }
          if (component.type === 'form_data_reset-password') {
            let tempComp = {
              ...component,
              handleSubmitFunc: (values) => {
                handleSubmit(values)
              }
            }
            component = tempComp
          }
          return renderDrupalComponent(component, index)
        })
      )}
    </>
  )
}

const mapStateToProps = (state) => ({
  FormsData: state.publicContent?.getFormsPageSuccess?.[SLUG_TYPES?.RESETPASSWORD],
  FormsDataLoading: state.publicContent?.getFormsPageLoading?.[SLUG_TYPES?.RESETPASSWORD],
  resetPasswordSuccess: state.users?.resetPassword3Success,
  verifyTokenSuccess: state.users?.resetPassword2Success
})

/**
 * Broken down existing consecuent calls of /verify-token and /reset-password to individual sagas, as the flow has changed.
 * First need to verify the token on page load, depending on response show the page.
 */
const mapDispatchToProps = (dispatch) => ({
  fetchFormsData: (formType) => dispatch(PublicContentActions.getFormsPage(formType)),
  verifyToken: (token) => dispatch(UserActions.resetPassword2(token)),
  resetPassword: (token, password) => dispatch(UserActions.resetPassword3(token.stateToken, password))
})

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword)
