import React, { useState, useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { isEmpty } from 'lodash'
import { useNavigate, useLocation } from 'react-router-dom'
import TagManager from 'react-gtm-module'

import { SubmitAppointment } from './styled'
import HorizontalCalendar from '../HorizontalCalendar'
import TimeSlotPicker from '../TimeSlotPicker'
import AppointmentScheduleHeader from '../AppointmentScheduleHeader'
import Modal from '../../Modal'
import AppointmentType from '../AppointmentType'
import AlternativeCenters from '../AlternativeCenters'
import AppointmentDetailsConfirmModal from '../AppointmentDetailsConfirmModal'
import ConfirmedAppointmentModal from '../ConfirmedAppointmentModal'
import PrimaryButton from '../../Buttons/PrimaryButton'
import { useScreenSize } from '../../../Contexts/ResponsiveContextProvider'
import AppointmentReschedule from '../AppointmentReschedule'
import ConfirmedCancellationModal from '../ConfirmedCancellationModal'

// Actions
import donorsActions from '@takedapdt/biolife-core/src/Stores/Donors/Actions'
import centersActions from '@takedapdt/biolife-core/src/Stores/Centers/Actions'
import appointmentsActions from '@takedapdt/biolife-core/src/Stores/Appointments/Actions'

const AppointmentSchedule = ({
  donor,
  lastPhysicalDate,
  inquiry,
  getSlotsForAppointmentType,
  centerSlots,
  createNewAppointment,
  centers,
  center,
  getAllCenters,
  centerSlotsFailure,
  centerSlotsLoading,
  createNewAppointmentFailure,
  createNewAppointmentSuccess,
  createNewAppointmentLoading,
  getDonorCentersAll,
  apptCenter,
  loggedInUser,
  getZipcodeSearchCenters,
  searchedCenters,
  getAppointmentCenter,
  rescheduleAppointment,
  rescheduleAppointmentSuccess,
  rescheduleAppointmentLoading,
  rescheduleAppointmentFailure,
  cancelAppointment,
  cancelAppointmentSuccess,
  cancelAppointmentLoading,
  cancelAppointmentFailure
}) => {
  const navigate = useNavigate()
  const location = useLocation()
  const { state } = location
  const { isMobileWidth } = useScreenSize()
  const appointmetSuccessStateRef = useRef('')
  appointmetSuccessStateRef.current = createNewAppointmentSuccess
  const { t, i18n } = useTranslation('AppointmentScheduling')
  const [appointment, setAppointment] = useState({
    appointmentType: donor?.pdn
      ? inquiry?.defaultAppointmentType == 'PHYSICALONLY'
        ? 'PHYSICAL'
        : inquiry?.defaultAppointmentType
      : 'PHYSICAL',
    startDateTime: moment().format('YYYY-MM-DDT00:00:00'),
    date: moment().format('MMMM DD, YYYY')
  })
  const [appointmentReschedule, setAppointmentReschedule] = useState({})
  const [cancelledClicked, setCancelledClicked] = useState(false)
  const [isAppointmentRescheduling, setIsAppointmentRescheduling] = useState(false)
  const [isAppTypeChanged, setIsAppTypeChanged] = useState(false)

  const [openModal, setOpenModal] = useState(false)
  const [openRescheduleModal, setOpenRescheduleModal] = useState(false)
  const [openConfirmedAppointmentModal, setOpenConfirmedAppointmentModal] = useState(false)
  const [openConfirmedCancellationModal, setOpenConfirmedCancellationModal] = useState(false)

  const toggleModal = () => {
    setOpenModal((prevState) => !prevState)
  }
  const toggleRescheduleModal = () => {
    setOpenRescheduleModal((prevState) => !prevState)
  }

  const handleReschedule = (app) => {
    setAppointmentReschedule({ ...app, appointmentId: app?.id || app?.appointmentId })
  }
  const toggleConfirmedCancellationModal = () => {
    setOpenConfirmedCancellationModal((prevState) => !prevState)
  }
  const handleRescheduleAppointment = () => {
    getSlotsForAppointmentType(
      moment
        .parseZone(createNewAppointmentSuccess?.startDateTime)
        .startOf('day')
        .format('YYYY-MM-DDT00:00:00'),
      null,
      null,
      createNewAppointmentSuccess?.numberOfChildren || 0,
      createNewAppointmentSuccess?.centerSfid,
      createNewAppointmentSuccess?.appointmentType,
      createNewAppointmentSuccess?.appointmentId || createNewAppointmentSuccess?.id,
      donor?.sfid
    )
  }

  useEffect(() => {
    if (createNewAppointmentSuccess && createNewAppointmentSuccess.centerSfid) {
      getAllCenters(createNewAppointmentSuccess?.centerSfid)
      setAppointmentReschedule({
        ...createNewAppointmentSuccess,
        appointmentId: createNewAppointmentSuccess?.id || createNewAppointmentSuccess?.appointmentId
      })
    }
  }, [createNewAppointmentSuccess])

  useEffect(() => {
    if (openRescheduleModal) {
      getAllCenters(createNewAppointmentSuccess?.centerSfid)
      handleRescheduleAppointment()
    }
  }, [openRescheduleModal])

  const toggleConfirmedAppointmentModal = () => {
    setOpenConfirmedAppointmentModal((prevState) => !prevState)
  }

  const [isChangeLocationModal, setIsChangeLocationModal] = useState(false)

  const handleAppointment = (app) => {
    sessionStorage.removeItem('AVAILABLE_SLOTS_API_CALLED')
    setAppointment({ ...app })
  }

  const getAllAvailableSlotsPerDay = async (day, centerSfid, appointmentType, playroom = 0, donorSfid) => {
    const newDate = day ? moment(day).format('YYYY-MM-DDT00:00:00') : moment().format('YYYY-MM-DDT00:00:00')
    await getSlotsForAppointmentType(newDate, null, null, playroom, centerSfid, appointmentType, null, donorSfid)
  }

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
  }, [openModal])

  useEffect(() => {
    const prevApp = JSON.parse(sessionStorage?.getItem('APPOINTMENT_LOCATION'))
    const selectedLanguage = sessionStorage?.getItem('SELECTED_LANGUAGE')
    if (prevApp && selectedLanguage !== i18n.language) {
      handleAppointment({ ...appointment, ...prevApp })
      getAllCenters(prevApp?.centerSfid)
    } else if (state?.selectedCenterData) {
      handleAppointment({
        ...appointment,
        center: `${state?.selectedCenterData.name} ${t('center')}`,
        centerSfid: state?.selectedCenterData?.sfid
      })
      getAllCenters(state?.selectedCenterData?.sfid)
    } else {
      getAllCenters(donor?.donationCenter)
    }
  }, [state?.selectedCenterData, i18n])

  useEffect(() => {
    if (donor && !JSON.parse(window.sessionStorage.getItem('MAPS_API_CALLED'))) {
      getAllCenters(donor?.donationCenter)
      window.sessionStorage.setItem('MAPS_API_CALLED', 'true')
    }
  }, [donor])

  useEffect(() => {
    if (
      donor &&
      !appointment.startTime &&
      appointment.appointmentType &&
      appointment.centerSfid &&
      sessionStorage.getItem('AVAILABLE_SLOTS_API_CALLED') != 'true'
    ) {
      sessionStorage.removeItem('APPOINTMENT_LOCATION')
      sessionStorage?.removeItem('SELECTED_LANGUAGE')
      getAllAvailableSlotsPerDay(
        appointment.startDateTime,
        appointment.centerSfid || donor.donationCenter,
        appointment.appointmentType,
        appointment.numberOfChildren,
        donor.sfid
      )
      sessionStorage.setItem('AVAILABLE_SLOTS_API_CALLED', 'true')
      sessionStorage?.setItem('APPOINTMENT_LOCATION', JSON.stringify(appointment))
      sessionStorage?.setItem('SELECTED_LANGUAGE', i18n.language)
    }
  }, [appointment])

  useEffect(() => {
    appointment?.appointmentType !== 'DONATION'
      ? getZipcodeSearchCenters(donor?.zipCode, true, 'disdate', donor?.donationCenter, true) // true corresponds to addNewDonorBonusInformation
      : getDonorCentersAll(null, true, 'disDate', true) // true corresponds to addNewDonorBonusInformation
    isAppTypeChanged && getAllCenters(appointment?.centerSfid || donor?.donationCenter)
    setIsAppTypeChanged(false)
  }, [appointment.appointmentType])

  useEffect(() => {
    sessionStorage.removeItem('AVAILABLE_SLOTS_API_CALLED')
    return () => {
      sessionStorage.removeItem('AVAILABLE_SLOTS_API_CALLED')
      if (!appointmetSuccessStateRef.current) {
        TagManager.dataLayer({
          dataLayer: {
            event: 'appointmentAbandon'
          }
        })
      }
    }
  }, [])

  const allowCenterEdit = () => {
    return center ? center.isDIS8Center : false
  }

  return (
    <>
      {!openRescheduleModal && (
        <>
          <AppointmentScheduleHeader
            setAppointment={setAppointment}
            appointment={appointment}
            handleAppointment={handleAppointment}
            centers={appointment.appointmentType === 'DONATION' ? centers : searchedCenters}
            center={center}
            edit={allowCenterEdit}
            donor={donor}
            isChangeLocationModal={isChangeLocationModal}
            setIsChangeLocationModal={setIsChangeLocationModal}
          />
          <AppointmentType
            donor={donor}
            defaultAppointmentType={inquiry?.defaultAppointmentType || ''}
            appointment={appointment}
            handleAppointment={handleAppointment}
            edit={!donor?.pdn}
            centerSlotsFailure={centerSlotsFailure}
            centerSlotsLoading={centerSlotsLoading}
            centerSlots={centerSlots}
            inquiry={inquiry}
            setIsAppTypeChanged={setIsAppTypeChanged}
          />
          <HorizontalCalendar
            appointment={appointment}
            handleAppointment={handleAppointment}
            centers={appointment.appointmentType === 'DONATION' ? centers : searchedCenters}
            donor={donor}
            setAppointment={setAppointment}
            center={center}
            edit={allowCenterEdit}
            centerSlotsFailure={centerSlotsFailure}
          />
          <TimeSlotPicker
            appointment={appointment}
            handleAppointment={handleAppointment}
            availableSlots={centerSlots || []}
            centerSlotsFailure={centerSlotsFailure}
            centerSlotsLoading={centerSlotsLoading}
          />

          <AlternativeCenters
            loggedInUser={loggedInUser}
            donor={donor}
            appointment={appointment}
            apptCenter={apptCenter}
            handleAppointment={handleAppointment}
            center={center}
            centerSlotsFailure={centerSlotsFailure}
          />
        </>
      )}

      {centerSlots?.length > 0 && (
        <SubmitAppointment>
          <PrimaryButton
            text={t('schedule')}
            fluid={isMobileWidth}
            onClick={toggleModal}
            disabled={!appointment.startDateTime || !appointment.startTime || !appointment.appointmentType}
          />
        </SubmitAppointment>
      )}

      <Modal
        isOpen={openModal}
        onClose={() => {
          toggleModal()
          if (isAppointmentRescheduling) {
            setIsAppointmentRescheduling(false)
          }
        }}
        maxWidth={837}
        maxHeight={'100vh'}
      >
        <AppointmentDetailsConfirmModal
          open={openModal}
          toggleModal={toggleModal}
          appointment={isAppointmentRescheduling ? appointmentReschedule : appointment}
          update={isAppointmentRescheduling && (cancelledClicked ? 'Cancel' : 'Reschedule')}
          createNewAppointment={createNewAppointment}
          donor={donor}
          center={center}
          loggedInUser={loggedInUser}
          toggleRescheduleModal={toggleRescheduleModal}
          createNewAppointmentFailure={createNewAppointmentFailure}
          toggleConfirmedAppointmentModal={toggleConfirmedAppointmentModal}
          createNewAppointmentSuccess={createNewAppointmentSuccess}
          createNewAppointmentLoading={createNewAppointmentLoading}
          rescheduleAppointment={rescheduleAppointment}
          rescheduleAppointmentSuccess={rescheduleAppointmentSuccess}
          rescheduleAppointmentLoading={rescheduleAppointmentLoading}
          rescheduleAppointmentFailure={rescheduleAppointmentFailure}
          toggleConfirmedCancellationModal={toggleConfirmedCancellationModal}
          cancelAppointment={cancelAppointment}
          cancelAppointmentSuccess={cancelAppointmentSuccess}
          cancelAppointmentLoading={cancelAppointmentLoading}
          cancelAppointmentFailure={cancelAppointmentFailure}
          appointmentDetails={createNewAppointmentSuccess}
          setIsAppointmentRescheduling={setIsAppointmentRescheduling}
        />
      </Modal>

      <Modal
        isOpen={openConfirmedAppointmentModal}
        onClose={() => {
          toggleConfirmedAppointmentModal()
          if (isAppointmentRescheduling) {
            setIsAppointmentRescheduling(false)
          }
        }}
        maxWidth={836}
        maxHeight={'100vh'}
      >
        <ConfirmedAppointmentModal
          isAppointmentRescheduling={isAppointmentRescheduling}
          setIsAppointmentRescheduling={setIsAppointmentRescheduling}
          open={openConfirmedAppointmentModal}
          toggleModal={toggleConfirmedAppointmentModal}
          appointment={isAppointmentRescheduling ? appointmentReschedule : appointment}
          createNewAppointment={createNewAppointment}
          donor={donor}
          center={center}
          update={isAppointmentRescheduling}
          centers={appointment.appointmentType === 'DONATION' ? centers : searchedCenters}
          createNewAppointmentSuccess={isAppointmentRescheduling ? rescheduleAppointmentSuccess : createNewAppointmentSuccess}
          loggedInUser={loggedInUser}
          createNewAppointmentFailure={isAppointmentRescheduling ? rescheduleAppointmentFailure : createNewAppointmentFailure}
          lastPhysicalDate={lastPhysicalDate}
          pdn={donor?.pdn}
          toggleRescheduleModal={toggleRescheduleModal}
        />
      </Modal>
      <Modal
        isOpen={openRescheduleModal}
        onClose={() => {
          toggleRescheduleModal()
          setIsAppointmentRescheduling(false)
        }}
        maxWidth={'95vw'}
        maxHeight={'100vh'}
      >
        <AppointmentReschedule
          open={openRescheduleModal}
          toggleModal={toggleRescheduleModal}
          appointment={appointmentReschedule}
          setAppointment={setAppointment}
          handleAppointment={handleReschedule}
          donor={donor}
          apptcenter={apptCenter}
          center={center}
          inquiry={inquiry}
          getZipcodeSearchCenters={getZipcodeSearchCenters}
          getSlotsForAppointmentType={getSlotsForAppointmentType}
          toggleDetailConfirmationModal={toggleModal}
          centerSlots={centerSlots}
          centerSlotsLoading={centerSlotsLoading}
          centerSlotsFailure={centerSlotsFailure}
          centers={centers}
          searchedCenters={searchedCenters}
          appointmentDetails={createNewAppointmentSuccess}
          cancelledClicked={cancelledClicked}
          setCancelledClicked={setCancelledClicked}
          getAppointmentCenter={getAppointmentCenter}
          getAllCenters={getAllCenters}
          setIsAppTypeChanged={setIsAppTypeChanged}
          getDonorCentersAll={getDonorCentersAll}
        />
      </Modal>

      <Modal
        isOpen={openConfirmedCancellationModal}
        onClose={() => {
          toggleConfirmedCancellationModal()
          setIsAppointmentRescheduling(false)
        }}
        maxWidth={837}
        maxHeight={'100vh'}
      >
        <ConfirmedCancellationModal
          loggedInUser={loggedInUser}
          open={openConfirmedCancellationModal}
          toggleModal={toggleConfirmedCancellationModal}
          setOpenConfirmedCancellationModal={setOpenConfirmedCancellationModal}
          appointment={appointment}
          center={apptCenter}
          donor={donor}
          cancelAppointmentSuccess={cancelAppointmentSuccess}
          cancelAppointmentLoading={cancelAppointmentLoading}
          cancelAppointmentFailure={cancelAppointmentFailure}
          isAppointmentRescheduling={isAppointmentRescheduling}
          setIsAppointmentRescheduling={setIsAppointmentRescheduling}
        />
      </Modal>
    </>
  )
}

const mapStateToProps = (state) => ({
  donor: state.donors?.donors?.data,
  apptCenter: state?.centers?.getAppointmentCenterSuccess?.data,
  lastPhysicalDate: state.donors?.inquiryDonorSuccess?.data?.lastPhysicalDate,
  centerSlots: state.centers?.getSlotsForAppointmentTypeSuccess?.data,
  centerSlotsFailure: state?.centers?.getSlotsForAppointmentTypeFailure,
  centerSlotsLoading: state.centers?.getSlotsForAppointmentTypeLoading,
  centers: state.centers.donorCenters?.data,
  center: state.centers.centers?.data,
  createNewAppointmentFailure: state.appointments?.createNewAppointmentFailure,
  createNewAppointmentLoading: state.appointments?.createNewAppointmentLoading,
  createNewAppointmentSuccess: state.appointments?.createNewAppointmentSuccess?.data,
  inquiry: state.donors?.inquiryDonorSuccess?.data,
  loggedInUser: state.users?.user,
  searchedCenters: state.centers.getZipcodeSearchCentersSuccess?.data,
  rescheduleAppointmentSuccess: state.appointments?.rescheduleAppointmentSuccess?.data,
  rescheduleAppointmentFailure: state.appointments?.rescheduleAppointmentFailure,
  rescheduleAppointmentLoading: state.appointments?.rescheduleAppointmentLoading,
  cancelAppointmentSuccess: state.appointments?.cancelAppointmentSuccess?.data,
  cancelAppointmentLoading: state.appointments?.cancelAppointmentLoading,
  cancelAppointmentFailure: state.appointments?.cancelAppointmentFailure
})

const mapDispatchToProps = (dispatch) => ({
  getDonors: (id) => dispatch(donorsActions.getDonors(id)),
  getSlotsForAppointmentType: (
    forDate,
    fromDateTime,
    toDateTime,
    numberOfChildren,
    centerSfid,
    appointmentType,
    appointmentId,
    donorSfid
  ) =>
    dispatch(
      centersActions.getSlotsForAppointmentType(
        forDate,
        fromDateTime,
        toDateTime,
        numberOfChildren,
        centerSfid,
        appointmentType,
        appointmentId,
        donorSfid
      )
    ),
  createNewAppointment: (appointment) => dispatch(appointmentsActions.createNewAppointment(appointment)),
  getDonorCentersAll: (searchStr, isDIS8Center, searchType, addNewDonorBonusInformation) =>
    dispatch(centersActions.getDonorCentersAll(searchStr, isDIS8Center, searchType, addNewDonorBonusInformation, true)),
  getAllCenters: (sfid) => dispatch(centersActions.getAllCenters(sfid)),
  getZipcodeSearchCenters: (searchStr, isDIS8Center, searchType, defaultCenter, addNewDonorBonusInformation) =>
    dispatch(centersActions.getZipcodeSearchCenters(searchStr, isDIS8Center, searchType, defaultCenter, addNewDonorBonusInformation, true)),
  getAppointmentCenter: (sfid) => dispatch(centersActions.getAppointmentCenter(sfid)),
  cancelAppointment: (id, cancelledFrom) => dispatch(appointmentsActions.cancelAppointment(id, cancelledFrom)),
  rescheduleAppointment: (id, appointment) => dispatch(appointmentsActions.rescheduleAppointment(id, appointment))
})

export default connect(mapStateToProps, mapDispatchToProps)(AppointmentSchedule)
