import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'
import { useLocation, useNavigate } from 'react-router-dom'
import importer from '@takedapdt/biolife-core/src/importer'
const renderDrupalComponent = importer('Constant/renderDrupalComponent')
const { SLUG_TYPES } = importer('Sagas/Constants/drupalConstants', null)
const PublicContentActions = importer('Stores/PublicContent/Actions')
const { getPathDetails } = importer('Helpers/Utils', null)
import TagManager from 'react-gtm-module'
const UserActions = importer('Stores/Users/Actions')
const LoaderSpinner = importer('Components/Loaders/LoaderSpinner')

const VerifyAccountFormEmail = ({ fetchFormsData, FormsData, FormsDataLoading, setupNewPassword, setupNewPasswordSuccess }) => {
  const location = useLocation()
  const navigate = useNavigate()

  const [token, setToken] = useState(false)
  const [flowType, setFlowType] = useState(false)
  const [sfid, setSfid] = useState(null)
  const [saveClicked, setSaveClicked] = useState(false)

  useEffect(() => {
    fetchFormsData(getPathDetails.slugType || SLUG_TYPES?.RESETPASSWORD)
  }, [])

  useEffect(() => {
    if (location.search) {
      const searchParams = new URLSearchParams(location.search)
      setFlowType(searchParams.get('flow'))
      setToken(searchParams.get('recoveryToken'))
      setSfid(searchParams.get('sfid'))
    }
  }, [location])

  useEffect(() => {
    if (setupNewPasswordSuccess && saveClicked) {
      TagManager.dataLayer({
        dataLayer: {
          event: 'profileRegistration',
          profileRegistrationStage: 'account created'
        }
      })
      navigate('/account-setup-success')
    }
  }, [setupNewPasswordSuccess])

  const handleSubmit = (values) => {
    setSaveClicked(true)
    setupNewPassword(flowType, token, values['New Password'], sfid)
  }

  return (
    <>
      <Helmet>
        <title>{`${FormsData?.metadata?.meta_title}`} </title>
        <meta name='description' content={`${FormsData?.metadata?.meta_description}`} />
        <link rel='canonical' href={`${window.location.origin}${window.location.pathname.toLowerCase()}`} />
      </Helmet>
      {FormsDataLoading ? (
        <LoaderSpinner />
      ) : (
        FormsData?.contents.map((component, index) => {
          if (component.type === 'breadcrumb') {
            let tempComp = { ...component, breadcrumbToggle: FormsData?.metadata?.field_content_breadcrumb }
            component = tempComp
          }
          if (component.type === 'form_data_reset-password') {
            let tempComp = {
              ...component,
              handleSubmitFunc: (values) => {
                handleSubmit(values)
              }
            }
            component = tempComp
          }
          return renderDrupalComponent(component, index)
        })
      )}
    </>
  )
}

const mapStateToProps = (state) => ({
  FormsData: state.publicContent?.getFormsPageSuccess?.[SLUG_TYPES?.RESETPASSWORD],
  FormsDataLoading: state.publicContent?.getFormsPageLoading?.[SLUG_TYPES?.RESETPASSWORD],
  setupNewPasswordSuccess: state.users.setupNewPasswordSuccess
})

/**
 * Broken down existing consecuent calls of /verify-token and /reset-password to individual sagas, as the flow has changed.
 * First need to verify the token on page load, depending on response show the page.
 */
const mapDispatchToProps = (dispatch) => ({
  fetchFormsData: (formType) => dispatch(PublicContentActions.getFormsPage(formType)),
  setupNewPassword: (flowType, token, password, sfid) => dispatch(UserActions.setupNewPassword(flowType, token, password, sfid))
})

export default connect(mapStateToProps, mapDispatchToProps)(VerifyAccountFormEmail)
