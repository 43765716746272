import React from 'react'
import styled from 'styled-components'
import PrimaryButton from '../../Buttons/PrimaryButton'
import { useScreenSize } from '../../../Contexts/ResponsiveContextProvider'
import { getCssColorFromDrupalColorType, setTextColor } from '../../../Constant/Utils'
import { sanitizeHtml } from '../../../utils/htmlHelpers'
import { devices, sizes } from '../../../utils/styledComponentHelpers'

const StyledTextCTAModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 6.4rem;
  ${devices.mobile} {
    padding: 6.4rem 3.2rem;
  }
`

const StyledTextCTAModalTitle = styled.div`
  ${(props) => setTextColor(props.$sectionTitleColor)};
  margin-bottom: 16px;
`
const StyledTextCTAModalDescription = styled.div`
  ${(props) => setTextColor(props.$sectionDescriptionColor)};
`

const TextCTAModal = ({ modalContent }) => {
  const {
    field_component_title: sectionTitle,
    field_title_color: sectionTitleColor,
    field_component_desc: sectionDescription,
    field_long_desc_color: sectionDescriptionColor,
    field_cta_1: sectionCTA1,
    field_cta_1_style: sectionCTA1Style
  } = modalContent
  const { isMobileWidth } = useScreenSize()

  return (
    <StyledTextCTAModalWrapper>
      {sectionTitle?.processed && (
        <StyledTextCTAModalTitle $sectionTitleColor={sectionTitleColor} dangerouslySetInnerHTML={sanitizeHtml(sectionTitle?.processed)} />
      )}
      {sectionDescription?.processed && (
        <StyledTextCTAModalDescription
          $sectionDescriptionColor={sectionDescriptionColor}
          dangerouslySetInnerHTML={sanitizeHtml(sectionDescription?.processed)}
        />
      )}
      {sectionCTA1?.uri && (
        <PrimaryButton text={sectionCTA1?.title} variant={sectionCTA1Style} sendTo={sectionCTA1?.uri} fluid={isMobileWidth} />
      )}
    </StyledTextCTAModalWrapper>
  )
}

export default TextCTAModal
