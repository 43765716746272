import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import './styles.scss'
import importer from '@takedapdt/biolife-core/src/importer'
const { SLUG_TYPES } = importer('Sagas/Constants/drupalConstants', null)

const Stepper = ({ field_pb_steps: stepTitles }) => {
  //checking the slug value and deciding states of the 3 progress levels accordingly
  let data = {
    [SLUG_TYPES.REGISTRATION]: ['active', 'inactive', 'inactive'],
    [SLUG_TYPES.CREATEACCOUNT]: ['complete', 'active', 'inactive'],
    [SLUG_TYPES.SIGNUPFINALSTEP]: ['complete', 'complete', 'active']
  }
  const [activeStep, setActiveStep] = useState()
  const { pathname } = useLocation()

  const [platForm, setPlatForm] = useState(window.navigator?.userAgentData?.platform)

  useEffect(() => {
    //padding the slug value to get the states of the 3 progress levels
    setActiveStep(data[pathname.substring(1)])
  }, [pathname])

  //for displaying grey dots
  const greyDots = Array.from({ length: 13 }, (_, i) => <span className='grey-dot' key={i}></span>)

  return (
    <div className='main-container'>
      <div className='stepper'>
        {stepTitles.map((level, index) => {
          const isComplete = activeStep?.[index] === 'complete'
          const isActive = activeStep?.[index] === 'active'
          const isInactive = activeStep?.[index] === 'inactive'
          return (
            <>
              <div key={index} className={`steps ${isActive ? 'activeStep' : ''}`}>
                <div className='step'>
                  {isComplete ? (
                    <div className='iconImg completeIcon'>
                      <div className='checkmark'></div>
                    </div>
                  ) : (
                    <div className={`iconImg ${isInactive ? '' : 'activeIcon'}`}>
                      <span className={`${isInactive ? '' : 'activeStep'}  ${platForm != 'Windows' ? 'numText' : 'numTextWindows'}`}>
                        {index + 1}
                      </span>
                    </div>
                  )}
                  <div className={`title ${isActive ? 'activeTitle' : ''}`}>
                    <h5>{level}</h5>
                  </div>
                </div>
              </div>
              <div className={`dots dot${index}`}>{greyDots}</div>
            </>
          )
        })}
      </div>
    </div>
  )
}

export default Stepper
