import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { devices } from '../../utils/styledComponentHelpers'
import styled from 'styled-components'

import CommunicationPreference from './CommunicationPreference'
import donorsActions from '@takedapdt/biolife-core/src/Stores/Donors/Actions'
import PublicContentActions from '@takedapdt/biolife-core/src/Stores/PublicContent/Actions'

import importer from '@takedapdt/biolife-core/src/importer'
const { webArticles } = importer('Helpers/WebArticles')
const { ArticleByIdentifier } = importer('Stores/PublicContent/Select', null)

const PreferenceSettings = ({
  getDonors,
  donor,
  user,
  getDonorsPreferences,
  updateDonorsPreferences,
  preferences,
  loading,
  webContents,
  updateDonorsPreferencesSuccess,
  getDonorsSuccess,
  postLoginPageData
}) => {
  const smsStatement = null
  const marketingStatement = null

  useEffect(() => {
    getDonorsPreferences(user.id)
  }, [])

  return (
      <CommunicationPreference
        donor={donor}
        getDonors={getDonors}
        getDonorsSuccess={getDonorsSuccess}
        preferences={preferences}
        updateDonorsPreferences={updateDonorsPreferences}
        loading={loading}
        smsStatementContent={smsStatement}
        marketingStatementContent={marketingStatement}
        updateDonorsPreferencesSuccess={updateDonorsPreferencesSuccess}
        postLoginPageData={postLoginPageData}
      />
  )
}

const mapStateToProps = ({ users, donors }) => ({
  user: users.user,
  donor: donors.donors?.data,
  preferences: {
    isEmailOptIn: donors?.Preferences?.isEmailOptIn,
    isEmailReminderOptIn: donors?.Preferences?.isEmailReminderOptIn,
    isSmsOptIn: donors?.Preferences?.isSmsOptIn,
    isSmsReminderOptIn: donors?.Preferences?.isSmsReminderOptIn,
    language: donors?.Preferences?.language
  },
  loading: donors?.getDonorsPreferencesLoading,
  updateDonorsPreferencesSuccess: donors?.updateDonorsPreferencesSuccess,
  getDonorsSuccess: donors?.getDonorsSuccess
})

const mapDispatchToProps = (dispatch) => ({
  getDonors: (id) => dispatch(donorsActions.getDonors(id)),
  getDonorsPreferences: (id) => dispatch(donorsActions.getDonorsPreferences(id)),
  updateDonorsPreferences: (id, preferences) => dispatch(donorsActions.updateDonorsPreferences(id, preferences))
})

export default connect(mapStateToProps, mapDispatchToProps)(PreferenceSettings)
