import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { Sidebar, Menu, Sticky } from 'semantic-ui-react'
import importer from '@takedapdt/biolife-core/src/importer'
import { Helmet } from 'react-helmet'
import { isEmpty } from 'lodash'
import styled from 'styled-components'
import Announcements from '../Components/Announcements'
import LoaderSpinner from '../Components/Loaders/LoaderSpinner'
import PromotionalBanner from '../Components/PromotionalBanner'
import { SLUG_TYPES } from '@takedapdt/biolife-core/src/Sagas/Constants/drupalConstants'
import { useTranslation } from 'react-i18next'

const renderDrupalComponent = importer('Constant/renderDrupalComponent')
const { useScreenSize } = importer('Contexts/ResponsiveContextProvider', null)
const { ScrollToTop } = importer('Helpers/Utils', null)
const SideNavbar = importer('Components/Navbar/MobileWebNavbar/SideNavbar')
const DesktopHeader = importer('Components/Header/DesktopHeader')
const LoggedInNavbar = importer('Components/LoggedInNavbar')
const LoggedInSideNavbar = importer('Components/LoggedInNavbar/LoggedInSideNavbar')
const PublicContentActions = importer('Stores/PublicContent/Actions')

const SideBarDiv = styled.div`
  .ui.visible.right.overlay.sidebar {
    box-shadow: 0px 0px 2px 100vw var(--color-clear-grey) !important;
  }
`

const StickyDiv = styled(Sticky)`
  top: ${(props) => props.$bannerHeight + 'px !important'};
`

const AppContainer = ({
  children,
  drupalNavData,
  getDrupalNavigation,
  logged,
  drupalNavDataLoading,
  getPromotionalBanner,
  promotionalBannerData,
  promotionalBannerDataLoading,
  ...props
}) => {
  const { isMobileWidth, isTabletWidth } = useScreenSize()
  const [sidebarOpened, setSidebarOpened] = useState(false)
  const [isDonor, setIsDonor] = useState(false)
  const [bannerHeight, setBannerHeight] = useState(0)
  const location = useLocation()
  const [promoHeaderDisplay, setPromoHeaderDisplay] = useState()
  const { t } = useTranslation('appContainer')

  const handleToggleSidebar = () => {
    setSidebarOpened(!sidebarOpened)
  }

  const handleToggleLocal = () => {
    setSidebarOpened(false)
  }

  useEffect(() => {
    window.history.scrollRestoration = 'manual'
    window.addEventListener('beforeunload', ScrollToTop())
    return () => {
      window.removeEventListener('beforeunload', ScrollToTop())
    }
  }, [])

  useEffect(() => {
    getDrupalNavigation()
    getPromotionalBanner(SLUG_TYPES.PROMOTIONALBANNER)
  }, [])

  useEffect(() => {
    setPromoHeaderDisplay(promotionalBannerData?.contents?.find((comp) => comp.type === 'promotional_content_header')?.field_promo_display)
  }, [promotionalBannerData])

  useEffect(() => {
    logged?.role === 'Donor' || logged?.role === 'SuperAdmin' ? setIsDonor(true) : setIsDonor(false)
  }, [logged])

  useEffect(() => {
    isDonor && sidebarOpened ? (document.body.style.overflow = 'hidden') : (document.body.style.overflow = 'visible')
  }, [sidebarOpened])

  useEffect(() => {
    document.getElementById('modal-root').removeAttribute('style')
    document.getElementById('react').style.removeProperty('height')
    document.getElementById('react').style.removeProperty('overflow')
  }, [location.pathname])

  const isFooterComponentAvailable = drupalNavData?.find((component) => component.type === 'footer_component')

  return (
    <div style={{ width: '100%' }}>
      {/* The og:description is taken from en/es.json file.  */}
      <Helmet>
        <meta property='og:description' content={t('bioLifeCommonMeta')} />
      </Helmet>
      {isDonor ? (
        <>
          {!drupalNavDataLoading ? (
            <SideBarDiv>
              <Sidebar
                as={Menu}
                style={{ display: 'flex', width: isMobileWidth ? '100%' : '390px', zIndex: 902 }}
                animation='overlay'
                onHide={handleToggleLocal}
                vertical
                visible={sidebarOpened}
                direction='right'
              >
                <LoggedInSideNavbar handleToggleSidebar={handleToggleSidebar} />
              </Sidebar>
              <Sidebar.Pusher dimmed={sidebarOpened}>
                <Announcements setBannerHeight={setBannerHeight} style={{ position: 'sticky', top: '0px', zIndex: 800 }} />
                <LoggedInNavbar handleToggleSidebar={handleToggleSidebar} bannerHeight={bannerHeight} />
                {children}
              </Sidebar.Pusher>
            </SideBarDiv>
          ) : (
            <LoaderSpinner backdrop='full' />
          )}
        </>
      ) : isMobileWidth || isTabletWidth ? (
        <>
          <Announcements setBannerHeight={setBannerHeight} />
          <Sidebar
            as={Menu}
            style={{ display: 'flex' }}
            animation='overlay'
            onHide={handleToggleLocal}
            vertical
            visible={sidebarOpened}
            width='wide'
            direction='right'
          >
            <SideNavbar handleToggleSidebar={handleToggleSidebar} />
          </Sidebar>
          <Sidebar.Pusher dimmed={sidebarOpened}>
            <LoggedInNavbar handleToggleSidebar={handleToggleSidebar} />
            {location.pathname === '/'
              ? promoHeaderDisplay?.includes('home_page') && <PromotionalBanner />
              : promoHeaderDisplay?.includes('pre_login_pages') && <PromotionalBanner />}
            {children}
          </Sidebar.Pusher>
        </>
      ) : (
        <>
          <Announcements setBannerHeight={setBannerHeight} style={{ position: 'sticky', top: '0px', zIndex: 800 }} />
          <DesktopHeader />
          <StickyDiv className='ui fixed sticky StickyNavbar' $bannerHeight={bannerHeight}>
            <LoggedInNavbar handleToggleSidebar={handleToggleSidebar} bannerHeight={bannerHeight} />
          </StickyDiv>
          {location.pathname === '/'
            ? promoHeaderDisplay?.includes('home_page') && <PromotionalBanner />
            : promoHeaderDisplay?.includes('pre_login_pages') && <PromotionalBanner />}
          {children}
        </>
      )}

      {/* Render footer here */}
      {isFooterComponentAvailable ? renderDrupalComponent(isFooterComponentAvailable) : null}
    </div>
  )
}

const mapStateToProps = (state) => ({
  drupalNavData: state.publicContent?.getDrupalNavigationSuccess?.contents,
  drupalNavDataLoading: state.publicContent?.getDrupalNavigationLoading,
  promotionalBannerData: state.publicContent?.getPromotionalBannerSuccess,
  promotionalBannerDataLoading: state.publicContent?.getPromotionalBannerLoading
})

const mapDispatchToProps = (dispatch) => ({
  getDrupalNavigation: () => dispatch(PublicContentActions.getDrupalNavigation()),
  getPromotionalBanner: (slug) => dispatch(PublicContentActions.getPromotionalBanner(slug))
})

export default connect(mapStateToProps, mapDispatchToProps)(AppContainer)
