import React from 'react'
import { StyledCalendarDayButton, StyledDayLabel, StyledDayNumber } from './styled'

const HorizontalCalendarDayOfWeek = ({ dayOfWeek, dayOfMonth, isSelected, onClick, centerSlotsFailure }) => {
  //DayNumberLabel classname is being used as a trigger condition in GTM
  return (
    <StyledCalendarDayButton className="DayNumberLabel" onClick={onClick} $selectedDate={isSelected} $disabledDate={!!centerSlotsFailure}>
      <StyledDayLabel>{dayOfWeek}</StyledDayLabel>

      <StyledDayNumber>
        <strong>{dayOfMonth}</strong>
      </StyledDayNumber>
    </StyledCalendarDayButton>
  )
}

export default HorizontalCalendarDayOfWeek
