import React, { useState } from 'react'
import styled from 'styled-components'

const StyledToggleSwitchWrapper = styled.label`
  position: relative;
  display: inline-block;
  width: 5rem;
  height: 2.2rem;
`

const StyledToggleSwitchInput = styled.input`
  opacity: 0;
  width: 6rem;
  height: 3.4rem;
  position: absolute;
  z-index: 1;
  cursor: pointer;
`

const StyledSlider = styled.span`
  position: absolute;
  cursor: pointer;
  top: -0.2rem;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${({ $disabled, $isChecked }) => (!$disabled && $isChecked ? 'var(--color-bg-light-blue)' : 'var(--color-white)')};
  transition: 0.4s;
  border-radius: 3.4rem;
  border: ${({ $disabled, $isChecked }) => (!$disabled && $isChecked ? 'var(--color-bl-blue)' : 'var(--color-dark-grey)')} 1px solid;

  &::before {
    position: absolute;
    content: '';
    height: 2.2rem;
    width: 2.2rem;
    background-color: ${({ $disabled, $isChecked }) => (!$disabled && $isChecked ? 'var(--color-bl-blue)' : 'var(--color-dark-grey)')};
    transition: 0.4s;
    border-radius: 50%;
    transform: ${({ $isChecked }) => ($isChecked ? 'translateX(26px)' : 'none')};
    ${({ $isChecked }) => ($isChecked ? 'right: 2.55rem;' : 'left: -0.1rem;')}
  }
`

const ToggleSwitch = ({ checked, onChange, name, value, disabled }) => {
  return (
    <StyledToggleSwitchWrapper>
      <StyledToggleSwitchInput type='checkbox' disabled={disabled} checked={checked} value={value} name={name} onChange={onChange} />
      <StyledSlider $isChecked={checked} $disabled={disabled}/>
    </StyledToggleSwitchWrapper>
  )
}

export default ToggleSwitch
