import React from 'react'
import importer from '@takedapdt/biolife-core/src/importer'
import './style.scss'
const { getLineStylesFromDrupalLineType, getCssColorFromDrupalColorType, setTextColor } = importer('Constant/Utils', null)
const { useScreenSize } = importer('Contexts/ResponsiveContextProvider', null)

const Separator = ({ field_separator, field_bg_color }) => {
  const { isMobileWidth, isTabletWidth } = useScreenSize()
  return (
    <>
      <div className='main-container separator-wrapper' style= {{
        background: getCssColorFromDrupalColorType(field_bg_color)
      }}>
        <div className='Separator' style={getLineStylesFromDrupalLineType(field_separator, isMobileWidth)} />
      </div>
    </>
  )
}

export default Separator