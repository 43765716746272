import React from 'react'
import { BgImageCard, LeftContainer, RightContainer, Image, ImageIcon1, ImageIcon2, RightContainerTitleDiv } from './styles/BgImageCard.styled'
import { Container, ContainerWithFlex, MainContainer, ContainerWithFlexSocialMedia } from './styles/Container.styled'
import importer from '@takedapdt/biolife-core/src/importer'
const { setTextColor } = importer('Constant/Utils', null)
const { useScreenSize } = importer('Contexts/ResponsiveContextProvider', null)
const { sanitizeHtml } = importer('utils/htmlHelpers', null)
const PrimaryButton = importer('Components/Buttons/PrimaryButton')

function CardTemplate3({
  field_component_title: title = {},
  field_title_color: titleColor = 'rich_black',
  field_short_description: desc = {},
  field_short_desc_color: descColor = 'rich_black',
  field_component_desc: longDesc = {}, // this is long desc
  field_long_desc_color: longDescColor = 'rich_black',
  field_alignment: imageAlignment,
  field_cta_1: cta1 = {},
  field_cta_1_style: cta1Style = '',
  field_bg_color: bgColor = 'white',
  field_column_1_icon: icon1 = {},
  field_column_1_url: icon1url = {},
  field_column_2_icon: icon2 = {},
  field_column_2_url: icon2url = {},
  field_component_image: mainImage = {},
  field_component_bg_image: bgImage = {},
  field_bg_color_1: cardBgColor = {}
}) {
  const { isMobileWidth } = useScreenSize()
  return (
    <Container bgColor={bgColor} className='cardtemplate3--main__container'>
      <MainContainer className='main-container cardtemplate3--container'>
        <BgImageCard
          className='main-container bgCardblock cardtemplate3--bgImage__container'
          bgImage={bgImage.url}
          cardBgColor={cardBgColor}
          imageAlignment={imageAlignment}
        >
          <ContainerWithFlex imageAlignment={imageAlignment} direction={isMobileWidth ? 'column' : ''}>
            <LeftContainer imageAlignment={imageAlignment} className='cardtemplate3--leftcontainer'>
              <Image 
                $height='409px'
                $width='200px'
                src={mainImage?.url}
                alt={mainImage.alt}
              />
            </LeftContainer>
            <RightContainer imageAlignment={imageAlignment} className='cardtemplate3--rightcontainer'>
              {title && title.processed ? (
                <RightContainerTitleDiv
                  className='bgCardblock--title_text_container'
                  dangerouslySetInnerHTML={sanitizeHtml(title.processed)}
                  style={{ ...setTextColor(titleColor) }}
                ></RightContainerTitleDiv>
              ) : null}
              {desc && desc.processed ? (
                <div
                  className='bgCardblock--desc_text_container'
                  dangerouslySetInnerHTML={sanitizeHtml(desc.processed)}
                  style={{ ...setTextColor(descColor) }}
                ></div>
              ) : null}
              {longDesc && longDesc.processed ? (
                <div
                  className='bgCardblock--longDesc_text_container'
                  dangerouslySetInnerHTML={sanitizeHtml(longDesc.processed)}
                  style={{ ...setTextColor(longDescColor) }}
                ></div>
              ) : null}
              {cta1Style && cta1 && cta1.title ? <PrimaryButton sendTo={cta1?.uri} text={cta1.title} variant={cta1Style} /> : null}
              <ContainerWithFlexSocialMedia direction={isMobileWidth ? 'column' : 'row'} className='cardtemplate3-container'>
                <a target='_blank' href={icon1url.uri}>
                <ImageIcon1 
                  $height='49px'
                  $width='170px'
                  src={icon1?.url}
                  alt={icon1.alt}
                />
                </a>
                <a target='_blank' href={icon2url.uri}>
                <ImageIcon2 
                  $height='49px'
                  $width='170px'
                  src={icon2?.url}
                  alt={icon2.alt}
                />
                </a>
              </ContainerWithFlexSocialMedia>
            </RightContainer>
          </ContainerWithFlex>
        </BgImageCard>
      </MainContainer>
    </Container>
  )
}

export default CardTemplate3
