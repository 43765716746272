import styled from 'styled-components'
import { devices } from '../../utils/styledComponentHelpers'
import { StyledHeroTemplate4Container, StyledHeroTemplate4MainText } from '../Hero/HeroTemplate4'
import {
  StyledGridTemplate3Title,
  StyledGridTemplate3PointsDescription,
  StyledGridTemplate3PointsIcon,
  StyledGridTemplate3BottomDescription
} from '../TextGrid/GridTemplate3'

export const StyledCustomPageWrapper = styled.div`
  .cookie-policy{
    .opacityStyling{
      opacity: 0;
    }
    .solidOrange{
      outline: 0;
      border: none !important;
      background-color: var(--color-orange) !important;
      color: var(--color-white) !important;
      border-radius: 27px !important;
      letter-spacing: 0 !important;
      text-align: center !important;
      font-size: 1.8rem !important;
      padding: 1.6rem 3rem !important;
      &:hover{
        background-color: var(--color-orange-hover) !important;
      }
    }
  }
  .donation-process-faqs {
    .orange-banner {
      .header-container {
        max-width: 82%;
      }
    }

    .Separator {
      margin: 0 !important;

      ${devices.mobile} {
        width: auto !important;
      }
    }
    .main-container {
      .text-component-description {
        margin: 0px !important;
        padding-bottom: 0px;
        padding-top: 8px;

        ${devices.tablet} {
          padding-top: 29px;
        }

        ${devices.mobile} {
          padding-top: 29px;
        }
      }
    }
  }

  .donation-process-donor-safety {
    .ui.grid > .row > [class*='bottom aligned'].column {
      align-self: flex-start !important;
    }
    .main-container .text-component-description {
      margin: 0;
    }

    p:not(:first-child) {
      padding-top: 10px;
    }

    .text-media {
      border-radius: unset;

      &__grid-column {
        &.top-aligned {
          flex-direction: column-reverse !important;
          padding-bottom: 60px;
          padding-top: 50px;

          > .DonationProcessImgContainer {
            padding: 0 0 80px 0;
          }
        }
      }
    }

    .WhatToDoContanier .paragraph {
      max-height: 356px;
      min-height: unset;
      margin-bottom: 22px;
    }

    .text-component-description {
      max-width: 750px;
      margin: unset;
    }

    .PlasmaDonation {
      margin: 0 auto;
      max-width: 1366px;

      .PlasmaDonationGridColumn {
        .PlasmaDonationContentContainer {
          padding-left: 0px !important;
          padding-right: 0px !important;
          padding-bottom: 150px !important;
        }
      }
    }

    .text-component-title {
      padding-top: 80px;
    }

    .WhatToDoContanier .manageGridColumnPadding {
      padding-left: 0 !important;
      padding-right: 0 !important;

      &:nth-child(even) {
        padding-left: 24px !important;
      }
    }

    .text-media {
      padding-top: 0px;
      padding-bottom: 0px;

      &__title-container {
        padding-top: 70px;
      }
    }

    .main-container .text-component-description {
      padding-top: 16px;
      padding-bottom: 48px;
    }

    ${devices.tablet} {
      .PlasmaDonation .PlasmaDonationGridColumn .PlasmaDonationContentContainer .PlasmaDonationTitleContainer {
        width: 100%;
      }
      .text-media {
        &__title-container {
          padding-top: 0;
        }

        &__grid-column {
          &.top-aligned {
            padding-left: 0;
            padding-right: 0;
            padding-bottom: 64px;
            padding-top: 0px;
            gap: 64px;

            > .DonationProcessImgContainer {
              padding: 0;
            }
          }
        }
      }

      .WhatToDoContanier .paragraph {
        min-height: 25px;
        max-height: fit-content;
        padding-bottom: 24px;
        margin-bottom: 0;
      }

      .WhatToDoContanier .manageGridColumnPadding {
        margin: unset !important;

        &:nth-child(even) {
          padding-left: 0 !important;
        }
      }

      .PlasmaDonation {
        padding: 40px 0px 0px 48px;
        max-width: unset;
      }

      .text-component-title {
        padding-top: 64px;
      }
      .PlasmaDonation .PlasmaDonationGridColumn {
        .PlasmaDonationContentContainer {
          padding-bottom: 48px !important;
        }
      }

      .main-container .text-component-description {
        padding: 16px 0;
      }
    }

    ${devices.mobile} {
      .text-media {
        padding-bottom: 0 !important;

        &__img-container {
          padding: 0 !important;
        }
        &__title-container {
          padding-top: 0;
          padding-bottom: 0;
        }

        &__grid-column {
          &.top-aligned {
            padding-bottom: 64px;
            gap: 48px;
            padding-top: 0;
          }
        }
      }

      .PlasmaDonation {
        padding-left: 24px;
        padding-right: 24px;
        max-height: unset;
      }

      .WhatToDoContanier .manageGridColumnPadding {
        margin: unset !important;

        &:nth-child(even) {
          padding-left: 0 !important;
        }
      }
      .WhatToDoContanier .paragraph {
        min-height: 25px;
        margin-bottom: 0px;
        max-height: unset;
      }

      .WhatToDoContanier .headingText {
        margin-top: 24px;
      }

      .text-component-title {
        padding-top: 64px;
      }
      .main-container .text-component-description {
        padding: 16px 0px 14px 0px !important;
      }

      .PlasmaDonation .PlasmaDonationGridColumn .PlasmaDonationContentContainer {
        padding-bottom: 0px !important;

        .PlasmaDonationDescriptionContainer {
          padding-top: 0;
        }
      }
    }
  }

  .why-donate-benefits-of-donating {
    .hero-template-4--image {
      position: relative;
      top: -8.4rem;
      left: -6.0rem;
      width: 56rem;
      padding: 0;
      transform: scale(1);

      ${devices.tablet} {
        margin-bottom: 6rem;
        padding: 0 !important;
        top: 0;
        left: 0;
      }
      
      ${devices.mobile} {
        width: 100%;
        margin-top: 2.4rem;
        margin-bottom: 8rem;
        top: 0;
      }
    }
    .hero-template-4 .hero-template-4__container {
      ${devices.mobile} {
      padding: 0;
      }
      .hero-template-4--main-text, .hero-template-4--short-desc-text, .hero-template-4--long-desc-text {
        ${devices.mobile} {
          padding-left: 2.4rem !important;
          padding-right: 2.4rem !important;
        }
      }
    }
    .WhatToDoContanier {
      .headingText {
        margin-bottom: 8px !important;
      }
      .paragraph {
        margin-bottom: 1.4rem;
        min-height: auto;

        ${devices.tablet} {
          margin-bottom: 2.4rem;
          min-height: 0;
        }
      }
    }
    .text-component-title {
      text-align: center;
      padding-top: 30px;

      ${devices.mobile} {
        text-align: left !important;
      }
    }
    .text-component-description {
      padding-top: 16px !important;
      padding-bottom: 0px !important;
    }
    .TestimonialsSliderContainer.ui.grid .slick-slider {
      margin: 0 auto 100px auto;
    }
    .TextMediaTitleContainer {
      text-align: left;
    }
    .WhatToDoContanier {
      margin-top: -40px !important;

      ${devices.mobile} {
        margin-top: -8rem !important;
      }
    }
    .TestimonialsSliderContainer.ui.grid .TitleRow {
      margin-top: 16px !important;
    }
    .ui.accordion .title * {
      font-size: var(--h4-mobile-font-size);
    }
    .TestimonialCardContainer.card {
      ${devices.tablet} {
        width: 301px !important;
      }
    }
    .hero-template-4 {
      ${devices.tablet} {
        margin-left: 0;
      }
      &--image {
        ${devices.tablet} {
          padding-right: 200px;
        }
        ${devices.mobile} {
          padding-right: 0;
        }
      }
      .hero-template-4--main-text {
        grid-column: 0 !important;
      }
      .hero-template-4__container {
        row-gap: 0;
        .hero-template-4--main-text {
          ${devices.tablet} {
            padding: 48px;
          }
          ${devices.mobile} {
            padding: 2.3rem 0.5rem 0;
          }
        }
        .hero-template-4--short-desc-text {
          max-width: 750px;
          width: 75rem;
          margin-bottom: 16px;
          padding-top: 0;
          letter-spacing: 0px;
          ${devices.tablet} {
            padding: 0px 48px;
            max-width: 672px !important;
            width: 100%;
          }
          ${devices.mobile} {
            padding: 0px 0 0 0;
            margin-bottom: 0;
            width: 100%;
          }
        }
        .hero-template-4--long-desc-text {
          max-width: 750px;
          letter-spacing: 0px;
          a {
            color: $rich-black;
            text-decoration: underline;
          }

          ${devices.tablet} {
            padding-left: 48px;
            max-width: 672px !important;
          }
          ${devices.mobile} {
            padding: 16px 0;
          }
        }
      }
      .hero-template-4--round-bottom {
        display: none;
      }
    }
    ${devices.mobile} {
      .WhatToDoContanier .manageGridColumnPadding {
        margin-bottom: 0px !important;
      }

      .paragraph {
        margin-bottom: 0px;
      }
    }

    .TestimonialsSliderContainer.ui.grid {
      height: auto;
    }

    ${devices.mobile} {
      .text-media {
        padding-top: 0px !important;
        padding-bottom: 8.4rem !important;
      }
    }

    ${devices.tablet} {
      .WhatToDoContanier .manageGridColumnPadding {
        margin-bottom: 0px !important;
      }

      .WhatToDoContanier .ui.grid > .column.row > [class*='eight wide computer'].column,
      .WhatToDoContanier .ui.grid > .row > [class*='eight wide computer'].column {
        /* width: 50% !important; */
      }
    }
  }

  .donation-process-who-can-donate {
    ${StyledGridTemplate3PointsIcon} {
      & img {
        max-width: 100%;
        max-height: 100%;
      }
    }
    ${StyledGridTemplate3BottomDescription} {
      text-align: start;
    }
    ${StyledGridTemplate3PointsDescription} {
      ${devices.mobile} {
        padding-left: 0;
      }
    }
    ${StyledGridTemplate3Title} {
      text-align: left;
    }
    .hero-template-4 {
      align-items: flex-start;
      margin-bottom: 55px;
      ${devices.tablet} {
        margin-left: 0;
        padding-top: 48px;
      }
      ${devices.mobile} {
        margin-left: 0;
        margin-bottom: 64px;
        padding-top: 24px;
      }
      .hero-template-4__container {
        row-gap: 0;
        .hero-template-4--main-text {
          padding-bottom: 2.4rem;
          ${devices.tablet} {
            grid-column: 1/7;
          }
        }
        .hero-template-4--short-desc-text {
          margin-bottom: 16px;
          ${devices.mobile} {
            margin-bottom: 0;
          }
        }
        .hero-template-4--long-desc-text {
          max-width: 578px;
          ${devices.mobile} {
            padding-top: 1.6rem;
          }
        }
      }
    }
    .hero-template-4--round-bottom {
      display: none;
    }
    .grid-template2 {
      margin: 0;
      max-width: 100%;
      border-bottom-left-radius: 200px;
      border-bottom-right-radius: 200px;
      ${devices.mobile} {
        border-bottom-left-radius: 125px;
        border-bottom-right-radius: 125px;
        padding-bottom: 60px;
      }
      .grid-template2__container {
        .grid-template2__bottom-description {
          p:has(.caption-text) {
            margin-top: 24px;
            margin-bottom: 32px;
          }
        }
      }
    }
    .grid-template3 {
      padding-bottom: 40px;
      padding-top: 48px;
      ${devices.mobile} {
        padding-bottom: 64px;
        padding-top: 64px;
      }
      .grid-template3__container {
        .grid-template3__title {
          padding-bottom: 0;
          max-width: 748px;
        }
        .grid-template3__points-container {
          padding-top: 32px;
          ${devices.mobile} {
            display: flex;
            flex-direction: column;

            .grid-template3__point {
              flex-direction: column;

              .grid-template3__point-title {
                padding-left: 0;
              }
            }
          }
        }
        .grid-template3__description {
          margin-top: 32px;
        }
      }
    }
    .text-media_container {
      .text-media {
        padding-bottom: 0px;
        border-radius: 0;
        ${devices.tablet} {
          padding-top: 64px;
        }
        ${devices.mobile} {
          padding-top: 64px;
          padding-bottom: 0 !important;
        }
        .text-media__process-container {
          margin-top: 70px !important;
          ${devices.mobile} {
            margin-top: 0 !important;
          }
          .text-media__grid-column {
            ${devices.mobile} {
              flex-direction: column-reverse;
              padding-bottom: 0;
              margin-bottom: -20px;
            }
            .text-media__points_container {
              padding-left: 0;
              .text-media__title-container {
                padding-bottom: 24px;
                ${devices.mobile} {
                  padding-top: 0;
                }
              }
            }
            .text-media__img-container {
              ${devices.mobile} {
                padding-bottom: 32px !important;
              }
            }
          }
        }
      }
    }
    .Separator {
      margin-top: 0;
      margin-bottom: 0;
      ${devices.mobile} {
        width: 100% !important;
      }
    }
    .text-component__container {
      padding-top: 40px;

      ${devices.mobile} {
        padding-top: 6.4rem;
      }
      
      .main-container {
        .text-component-title {
          max-width: 74%;
          ${devices.mobile} {
            max-width: 100%;
          }
        }
        .text-component-description {
          margin-left: 0;
          margin-right: 0;
          max-width: 74%;

          ${devices.tablet} {
            max-width: 100%;
          }

          ${devices.mobile} {
            padding: 2.4rem 0 3.2rem;
          }
        }
      }
    }
    .activate-debit-card {
      justify-content: flex-start;

      max-width: unset;
      ${devices.tablet} {
        padding-left: 48px !important;
      }
      ${devices.mobile} {
        padding-left: 0 !important;
      }
      .activate-debit-card__container {
        max-width: 1366px;
        ${devices.mobile} {
          padding-top: 64px !important;
        }
        .activate-debit-card__grid--normal {
          .activate-debit-card__text {
            padding-left: 0 !important;
            .activate-debit-card__title {
              ${devices.mobile} {
                max-width: 92%;
              }
            }
            .activate-debit-card__description {
              :first-child:not(:last-child) {
                margin-top: 8px;
                margin-bottom: 16px;
              }
            }
          }
        }
        .activate-debit-card__grid {
          .activate-debit-card__text {
            .activate-debit-card__title {
              ${devices.mobile} {
                max-width: 92%;
              }
            }
            .activate-debit-card__description {
              ${devices.mobile} {
                margin-bottom: -30px;
              }
              :first-child:not(:last-child) {
                margin-top: 8px;
                margin-bottom: 16px;
              }
              h5 {
                font-size: var(--h5-font-size) !important;
                line-height: var(--h5-line-height) !important;
              }
            }
          }
          .activate-debit-card__image--right {
            img {
              width: 100%;
            }
          }
        }
      }
    }
    .styled-cta {
      margin: 0;
      max-width: 100%;
      padding: 0 calc((100vw - 1008px) / 2);
      padding-bottom: 80px;

      ${devices.mobile} {
        width: fit-content;
        padding-bottom: 6.4rem;
      }
    }
    .ImageBanner {
      margin-top: 140px;

      .ImageBannerGrid .ImageBannerRow {
        .six.wide.computer {
          text-align: right;
        }
        &.reversed .six.wide.computer {
          text-align: left;
        }
      }
    }
    .main-container.accordion-component {
      .AccordionContainer {
        .inline-dark-blue {
          text-decoration: none;
        }
      }
      &:last-child {
        margin-bottom: 64px;
        .AccordionContainer {
          border-bottom: none;
        }
      }
    }
  }

  .about-biolife-who-we-are {
    .main-container.WhatToDoContanier2 > .ui.grid > .column.row {
      padding: 0 !important;
    }
    .main-container.WhatToDoContanier > .ui.grid > .column.row {
      padding: 0 !important;
    }

    .main-container {
      .WhatToDoContanier {
        padding: 2.4rem 0 !important;
      }
    }
    .text-media__title-container {
      padding: 0 !important;
    }
    .text-media__grid-column,
    .text-media__grid-column--reversed {
      justify-content: center !important;
      gap: 2.4rem;
      .text-media__points_container {
        flex-basis: 0 !important;
        flex-grow: 1 !important;
      }
      .text-media__img-container {
        flex-basis: 0 !important;
        flex-grow: 1 !important;
        padding: 0 !important;
      }

      ${devices.mobile} {
        flex-direction: column-reverse !important;
        gap: 2.8rem;
      }
    }
    .text-media__img-container {
      ${devices.tablet} {
        padding-bottom: 0px !important;
        padding-top: 20px !important;
      }
      ${devices.mobile} {
        padding-bottom: 0px !important;
        padding-top: 20px !important;
      }
    }

    .WhatToDoContanier .manageGridColumnPadding {
      ${devices.tablet} {
        margin-bottom: 0px !important;
      }
      ${devices.mobile} {
        margin-bottom: 0px !important;
      }
    }

    .main-container > .text-component-description {
      padding: 0 !important;
      padding-top: 1.6rem !important;
    }

    * > .text-component__container > .main-container > .text-component-description {
      padding: 0 !important;
      padding-top: 2.4rem !important;
    }

    .main-container > .Separator {
      width: 100% !important;
    }

    .text-media {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }

    .main-container.paddedRegion {
      transform: translateY(4rem);
      ${devices.tablet} {
        transform: translateY(0);
        margin: 0 !important;
      }
      ${devices.mobile} {
        transform: translateY(0);
        margin: 0 !important;
      }
    }
    .text-media-temp3 {
      &__text-container {
        padding: 0 172px;
        ${devices.tablet} {
          padding: 0 calc((100vw - 9.6rem - 67.2rem) / 2);
        }
        ${devices.mobile} {
          padding: 0 calc((100vw - 4.8rem - 34.8rem) / 2);
        }
      }
    }
  }

  .why-donate-loyalty-program {
    ${StyledGridTemplate3PointsIcon} {
      width: 12rem;
      height: 12rem;
      & img {
        width: 100%;
        height: 100%;
        max-width: 100%;
        max-height: 100%;
      }
    }
    .Breadcrumbs {
      padding-top: 32px;
    }
    .text-media-temp3 {
      gap: 2.4rem;
      ${devices.tablet} {
        align-items: flex-start;
      }
      ${devices.mobile} {
        gap: 4.8rem;
        align-items: center;
      }
      &--text-container {
        gap: 2.4rem;
      }
      &--description > :not(:first-child) {
        padding-top: 16px;
      }
      &--video-container {
        width: 49.2rem;
        height: 28.9rem;
        ${devices.tablet} {
          width: 32.4rem;
          height: 19rem;
          flex: none;
        }
        ${devices.mobile} {
          width: 34.2rem;
          height: 20.1rem;
        }
      }
    }
    .card-temp1 {
      &__container {
        box-shadow: none;
        gap: 63px;
        padding: 64px 40px;
        ${devices.tablet} {
          gap: 56px;
        }
        ${devices.mobile} {
          gap: 46px;
          padding: 64px 24px;
          align-items: center;
          justify-content: center;
        }
      }
      &__text-column {
        ${devices.mobile} {
          align-items: center;
          text-align: center;
        }
      }
      &__description-top {
        padding-top: 0;
        ${devices.mobile} {
          text-align: center;
        }
      }
      &__description-bottom {
        padding-top: 12px;
        ${devices.mobile} {
          text-align: center;
        }
      }
      &__button-column {
        padding-top: 25px;
        width: 100%;
        ${devices.mobile} {
          padding-top: 32px;
        }
      }
      &__image-column {
        width: auto;
        height: auto;
      }
      &__img {
        ${devices.mobile} {
          object-fit: contain;
          object-position: unset;
        }
      }
    }
    .grid-template3__point {
      ${devices.mobile} {
      display: flex;
        flex-direction: column !important;
        justify-content: center !important;
        align-items: center !important;
      }
    }
    .grid-template3__point-title{
      ${devices.mobile} {
      text-align: center !important;
      padding-left: 0 !important;
      }
    }
  }

  .donation-process-what-to-expect {
    .hero-temp1 {
      div {
        ${devices.tablet} {
          align-items: flex-start;
        }
      }
    }
    .styled-cta .ui.button {
      ${devices.mobile} {
        width: 100%;
      }
    }
    .text-media_container .text-media {
      padding-bottom: 0px !important;

      .text-media__img-container {
        ${devices.mobile} {
          margin-bottom: 2.4rem;
        }
      }
    }
    .text-media__process-container .text-media__points_container .text-media__description-container h3 {
      margin-bottom: 2.4rem;
      ${devices.tablet} {
        margin-bottom: 1.6rem;
      }
    }

    // .text-media__process-container .text-media__points_container .text-media__title-container{
    //   margin-bottom: 4.8rem;
    //   ${devices.tablet} {
    //     margin-bottom: 3.2rem;
    //   }
    //   ${devices.mobile} {
    //     margin-bottom: 2.4rem;
    //   }
    // }

    .accimg--container .accimg--subContainer .accimg--content .accimg--textContainer {
      h4 {
        margin-bottom: 2.4rem;
      }
      margin-bottom: 3.2rem;
      ${devices.tablet} {
        margin-bottom: 2.4rem;
      }
      ${devices.mobile} {
        margin-bottom: 2.4rem;
      }
    }

    .accimg--container .accimg--subContainer .accimg--content .accImg--imageContainer .accImg--imageStyle {
      width: 492px;
      height: 450px;
      ${devices.tablet} {
        width: 308px;
        height: 282px;
      }
      ${devices.mobile} {
        width: 310px;
        height: 284px;
      }
    }

    .AccordionGridContainer .ui.accordion .title {
      h5 {
        font-size: 2.4rem !important;
        ${devices.mobile} {
          font-size: 1.8rem !important;
        }
      }
    }

    .AccordionGridContainer .ui.accordion .content {
      padding-top: 0px !important;
      padding-bottom: 0px !important;
    }

    .AccordionGridContainer .AccordionShortDescTextContainer {
      padding-bottom: 0.4rem;
    }

    .AccordionGridContentDescContainer {
      padding-bottom: 4rem;
      ${devices.mobile} {
        padding-bottom: 2.4rem;
      }
    }

    .AccordionGridContainer .AccordionGridContentTextContainer {
      h5 {
        margin-bottom: 0.8rem;
      }
      p {
        margin-bottom: 2.4rem;
      }
      ul, ol {
        margin-left: 1.6rem;

        li {
          margin-bottom: 1.6rem;
          font-size: var(--p-font-size);
        }
      }
      ul li:last-child {
        // uncomment after the line below it is visible on screen for now it is not visible
        // margin-bottom: 4.8rem;
        margin-bottom: 2.8rem;
      }
    }

    .ui.grid .row [class*='eleven wide computer'].column,
    .StyledGridColumn,
    .StyledContentContainer,
    .ui.grid .row [class*='five wide computer'].column,
    .accImg--imageStyle {
      width: 50% !important;

      &:first-of-type {
        padding-right: 2.4rem !important;
      }
      ${devices.mobile} {
        width: 100% !important;
      }
    }

    .AccordionGridContainer .ui.accordion .content .AccordionGridContentContainer .AccordionColumn {
      text-align: left;
      padding-top: 1rem;

      ${devices.mobile} {
        padding-top: 0;
      }

      h4 {
        margin-bottom: 0.8rem;
      }
    }

    .main-container .text-component-title {
      ${devices.mobile} {
        text-align: left !important;
        margin-bottom: 0.8rem !important;
      }
    }

    .main-container .text-component-description {
      padding-top: 0px;
      padding-bottom: 3.2rem;
    }

    .PlasmaDonation .PlasmaDonationGridColumn .PlasmaDonationContentContainer {
      ${devices.mobile} {
        padding-bottom: 0.8rem !important;
      }
    }

    .AccordionGridContainer .AccordionGridContentContainer .AccordionColumnContainer {
      padding-left: 0px;
      padding-right: 2.4rem;

      ${devices.mobile} {
        padding-right: 0 !important;
        padding-left: 0 !important;
      }
    }

    .AccordionGridContainer .ui.accordion .content .AccordionGridContentContainer {
      row-gap: 1.2rem !important;
    }

    .PlasmaDonation .PlasmaDonationGridColumn .PlasmaDonationContentContainer {
      padding: 3.8rem 4rem 4.8rem 3rem !important;
      ${devices.mobile} {
        padding: 3.8rem 4rem 0rem 3rem !important;
      }
    }

    .cardblock {
      border-radius: 50px;
      padding: 6.4rem 4rem;

      img {
        width: 200px;
        height: 200px;
      }
      ${devices.mobile} {
        padding: 4rem;
      }
    }
    .orange-banner {
      ${devices.mobile} {
        padding-top: 8rem;
        padding-bottom: 10rem;
      }
    }

    .cardblock .cardblock--leftContainer .cardblock__imageStyle {
      ${devices.tablet} {
        height: 160px;
        width: 160px;
      }
    }
    .cardblock--title_text_container {
      margin-bottom: 1.6rem;
      ${devices.mobile} {
        margin-top: 4.8rem;
      }
    }
    .cardblock--desc_text_container {
      p {
        margin-bottom: 1.6rem;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    .cardtemplate3--bgImage__container .cardtemplate3--leftcontainer {
      ${devices.mobile} {
        position: relative;
        height: 22rem;
        display: flex;
        justify-content: center;
      }
    }
    .cardtemplate3--bgImage__container .cardtemplate3--rightcontainer {
      ${devices.mobile} {
        text-align: center;
      }
    }
    .bgCardblock .bgCardblock--imagestyle {
      top: -9rem;
      ${devices.tablet} {
        top: -4rem;
      }
      ${devices.mobile} {
        position: absolute;
        top: -21.8rem;
      }
    }

    .text-media__imagestyle.ui.image {
      max-width: none;
      width: 100%;
      height: 343px;
      ${devices.tablet} {
        width: 100%;
        height: 220px;
      }
    }

    .text-media__process-container .text-media__points_container {
      display: flex !important;
      flex-direction: column !important;
      justify-content: center !important;
    }

    .text-media__img-container {
      margin-top: 2rem !important;
      padding-bottom: 0 !important;
    }

    div.text-media_container
      > .text-media
      > .main-container
      > .ui.grid.text-media__process-container
      > .text-media__grid-column
      > div.computer.column.text-media__points_container {
      width: 55% !important;
      ${devices.tablet} {
        width: 50% !important;
      }
    }
    div.text-media_container
      > .text-media
      > .main-container
      > .ui.grid.text-media__process-container
      > .text-media__grid-column
      > div.computer.column.text-media__img-container {
      width: 45% !important;

      ${devices.tablet} {
        width: 50% !important;
      }

      a {
        overflow: hidden;
        display: block;
        border-radius: 24px;
        position: relative;
        width: 100%;
        height: 100%;

        .backdrop {
          position: absolute;
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0.25);
          z-index: 1;
          border-radius: 24px;
          transition: all 0.5s ease;

          ${devices.mobile} {
            width: 100%;
          }

          &:hover,
          &:focus,
          &:active {
            background: transparent;

            & + img {
              transform: scale(1.2);
            }
          }
        }

        img.ui.image {
          width: 100%;
          height: 100%;
          object-fit: cover;
          transition: all 0.3s ease;

          &:hover,
          &:focus,
          &:active {
            transform: scale(1.2);
          }
        }
      }
    }

    .bgCardblock--imagestyle.ui.image {
      max-width: none;
    }

    .txtMedia5--rightContainer {
      margin-left: 0px;
      margin-right: 0px;
    }

    .txtMedia5--rightContainer .txtMedia5--imagestyle.ui.image {
      max-width: none;
      object-fit: contain;
      ${devices.tablet} {
        width: 210px;
        height: 220px;
      }
      ${devices.mobile} {
        width: 100%;
        height: 100%;
      }
    }

    .cardtemplate3--bgImage__container {
      border-radius: 50px;
      padding: 4rem;
      height: 30rem;

      ${devices.tablet} {
        height: 42rem;
        padding: 4.5rem;
      }
      ${devices.mobile} {
        height: auto;
      }
    }
    .cardtemplate3--container .cardtemplate3--bgImage__container .cardtemplate3-container {
      margin-top: 3rem;
      ${devices.mobile} {
        align-items: center;
      }
    }
    .bgCardblock .bgCardblock--icon1style {
      margin-right: 2.5rem;
      
      ${devices.tablet} {
        margin-right: 2.5rem;
      }

      ${devices.mobile} {
        margin: 0 0 2.5rem 0;
      }
    }
    .cardtemplate3--container .cardtemplate3--bgImage__container .bgCardblock--title_text_container {
      margin-bottom: 1.6rem;
    }
    .checklistBlock {
      // Check if this padding needs to be comming from drupal
      padding-bottom: 2.4rem;
    }
    .checklistBlock .checklistBlock__inner .checklistBlock__leftContainer {
      margin-right: 3rem;
      ${devices.mobile} {
        margin-bottom: 2.4rem;
      }
    }
    .checklistBlock .checklistBlock__inner .checklistBlock__rightContainer .checklistBlock--title_text_container {
      margin-bottom: 0.8rem;
    }
    .checklistBlock .checklistBlock__inner .checklistBlock__rightContainer .checklistBlock--desc_text_container {
      p {
        margin-bottom: 2.4rem;
      }
      table tbody {
        vertical-align: top;

        tr {
          td {
            padding-right: 3rem;

            ${devices.tablet} {
              padding-right: 0;
            }

            &:last-child {
              padding-right: 0;
            }
          }

          &:last-child {
            td p {
              &:last-child {
                margin-bottom: 0;
              }
            }
          }

          td p {
            &:nth-child(odd) {
              margin-bottom: 0.8rem;
            }
          }
        }
      }
      ${devices.tablet} {
        table tbody tr {
          display: flex;
          flex-direction: column;

          td {
            &:last-child {
              margin-bottom: 0;
            }
          }

          td {
            p {
              &:last-child {
                margin-bottom: 0;
              }
            }
          }
        }
        table tbody tr td {
          margin-bottom: 2.4rem;
        }
      }
    }
    .Separator {
      margin: 0 !important;

      ${devices.mobile} {
        width: auto !important;
      }
    }
  }
  .why-donate-what-is-plasma {
    ${devices.mobile} {
      .grid-template3 {
        .grid-template3__point-icon {
          margin-bottom: 0px;
        }
        img {
          min-width: 72px;
        }
      }
    }

    .hero-template-4 {
      image {
        max-width: 560px;
        max-height: 500px;
      }
    }
    .text-component__container {
      h4 {
        max-width: 750px;
      }
      p {
        max-width: 750px;
        padding-bottom: 16px;

        &:last-child {
          padding-bottom: 0;
        }
      }
    }
    .grid-template3 {
      padding-bottom: 112px;
      border-radius: 0px 0px 200px 200px;

      ${devices.mobile} {
        border-radius: 0px 0px 125px 125px;
      }

      .grid-template3__points-container {
        img {
          max-width: 72px;
        }
      }
    }
    .WhatToDoContanier {
      .paragraph {
        p {
          text-align: left;
        }
        .grid-template3__point {
          align-items: center;
          gap: 18px;
          justify-content: start;
          flex-direction: row !important;
        }
      }
      .grid-template3__bottom-description {
        :first-child {
          padding-bottom: 16px;
        }
      }
    }
    .text-component__container {
      .text-component-description {
        padding-top: 16px !important;
      }
    }
    .donation-impact-process {
      .donation-impact-process__title-container {
        padding-bottom: 4px !important;
      }
    }
    .grid-template3 {
      img {
        width: 72px;
        height: 72px;
      }
    }
    .hero-template-4--round-bottom {
      height: 80px;
    }
    .hero-template-4 {
      ${devices.mobile} {
        padding-bottom: 48px !important;
      }
      .hero-template-4--image {
        padding: 0px !important;
        max-width: 467px;
        max-height: 420px;
        position: relative;
        top: -4.4rem;
        ${devices.tablet} {
          padding-bottom: 48px !important;
          top: 0;
        }
      }
      .hero-template-4--long-desc-text {
        :first-child {
          padding-bottom: 18px;
        }
      }
    }
    .WhatToDoContanier {
      .grid {
        .row {
          :nth-child(2) {
            margin-bottom: 0rem !important;
          }
          .paragraph {
            p {
              padding-top: 16px !important;
            }
          }
        }
      }
    }
    .donation-impact-process {
      .main-container {
        .donation-impact-process__container {
          .donation-impact-process__grid-column {
            ${devices.tablet} {
              flex-direction: row !important;
            }
            ${devices.mobile} {
              flex-direction: column-reverse !important;
            }
            padding-bottom: 0 !important;
            
            .ten.wide {
              padding-left: 0 !important;
              padding-right: 11rem;
            }

            p {
              strong {
                padding-top: 9px !important;
              }
            }

            .donation-impact-process__grid-column {
              .donation-impact-process__img-container {
                ${devices.tablet} {
                  margin: auto;
                  padding-right: 0px !important;
                  padding-bottom: 48px;
                }
                width: 405px !important;
                .image {
                  padding: 0px;
                }
              }
              .donation-impact-process__description-container {
                max-width: 490px;
              }
              * {
                ${devices.mobile} {
                  max-width: 342px !important;
                  padding: 0px !important;
                }
                width: 600px !important;
                padding-right: 109px;
              }
            }
          }
        }
      }
    }

    .ImageBanner .ImageBannerGrid .ImageBannerRow {
      .six.wide.computer {
        text-align: right;
      }
      &.reversed .six.wide.computer {
        text-align: left;
      }
    }

    .accimg--content {
      .eleven.wide.computer {
        padding-right: 2.4rem !important;

        p {
          margin-bottom: 1.6rem;
        }
      }
    }
  }
  .why-donate-compensation {
    ${StyledHeroTemplate4Container} {
      gap: 1.5rem;
    }

    ${StyledHeroTemplate4MainText} {
      grid-column: 1 / 8;
    }

    .text-component__container {
      .text-component-description {
        padding: 16px 0 !important;
      }
    }
    .hero-template-4__container {
      margin: auto;
    }
    .hero-template-4 {
      .inline-orange {
        font-style: italic;
        text-decoration: none;
        * {
          font-family: $font-family !important;
        }
      }
      li {
        font-size: $p-font-size !important;
        width: 520px;
        padding: 0px 0px 16px 0px;
        margin-left:1.6rem;
      }
      ${devices.tablet} {
        li {
          width: 300px;
          padding: 0px 0px 10px 0px;
        }
      }
      ${devices.mobile} {
        tr {
          display: grid;
          width: max-content;
          max-width: 320px;
        }
        li {
          width: unset;
        }
      }
      &--round-bottom {
        height: 104px;
      }
    }
    .text-media_container {
      .text-media {
        border-radius: 0 0 12rem 12rem;
        padding-bottom: 0px !important;
        .text-media__grid-column {
          &.top-aligned,
          &--centerAligned {
            align-items: center;
            .text-media__title-container {
              padding-top: 32px;
              text-align: center;
              ${devices.mobile} {
                padding-top: 0px;
              }
            }
            .text-media__description-container {
              width: 70%;
              margin: auto;
              text-align: center;
              ${devices.tablet} {
                width: 100%;
              }
            }
          }
          ${devices.mobile} {
            .text-media__img-container {
              padding-top: 24px;
            }
          }
          .text-media__img-container,
          &--centerAligned .text-media__img-container--centerAligned,
          &--reversed .text-media__img-container {
            display: flex;
            align-items: center;
            justify-content: center;
            .image {
              align-items: center;
              max-width: 280px !important;
            }
          }

          &.top-aligned {
            flex-direction: column-reverse;
            ${devices.mobile} {
              .text-media__img-container {
                padding-top: 0px;
              }
            }
          }
          &--centerAligned {
            .computer.text-media {
              &__points_container {
                width: 100% !important;
              }
            }
          }
        }
      }
    }
    .grid-template2 {
      ${devices.mobile} {
        padding-bottom: 0 !important;
      }
      &__points-container {
        padding-top: 32px;
        gap: 1.5rem;
        ${devices.mobile} {
          padding-top: 0px;
          &--unStackedGrid {
            gap: 0;
            .grid-template2__point {
              flex-direction: column;
            }
            .grid-template2__point-title {
              width: 100%;
              padding-left: 0;
              text-align: inherit;
            }
          }
        }
      }
      &__point-icon {
        width: 120px;
        height: 120px;
        ${devices.mobile} {
          margin: 35px 0;
          
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
      &__bottom-description {
        padding-top: 48px;
        max-width: 80%;
        ${devices.tablet} {
          max-width: 100%;
        }
      }
    }
    .BannerTemplate .ImageBannerGrid .subTitle {
      padding-top: 8px !important;
    }
    .PlasmaDonation {
      padding: 0 !important;
      ${devices.tablet} {
        padding: 0 4.8rem !important;
      }
      ${devices.mobile} {
        padding: 0 2.4rem !important;
      }
      .PlasmaDonationGridColumn {
        max-width: 1008px;
        .wide.computer.PlasmaDonationContentContainer {
          width: 70% !important;
          padding-top: 0 !important;
          padding-right: 0 !important;
          padding-left: 2.4rem !important;

          ${devices.mobile} {
            width: 100% !important;
            padding: 24px 0 !important;
            .PlasmaDonationShortDescriptionContainer {
              padding-top: 8px !important;
            }
          }
          .PlasmaDonationDescriptionContainer {
            padding-top: 24px;
            ${devices.mobile} {
              padding-top: 16px !important;
            }
          }
          .ButtonContainer {
            padding-top: 24px;
            justify-content: left;
            button {
              text-align: left;
            }
          }
        }

        .wide.computer.PlasmaDonationImgContainer {
          width: 30% !important;
          align-self: start !important;
          ${devices.mobile} {
            width: 100% !important;
            margin: auto !important;
            background: inherit;
            img {
              width: 100%;
            }
          }
        }
      }
    }

    .activate-debit-card {
      .activate-debit-card__container.ui.grid {
        ${devices.mobile} {
          padding-top: 2.2rem !important;
        }
      }
    }
  }

  .privacy-notice,
  .terms-and-conditions-of-use {
    .text-component-description {
      padding: 16px 0 48px;
      ul li {
        padding-bottom: 16px;
      }
    }
  }

  .non-discrimination-statement {
    .text-component-description {
      margin: 0;
      padding-bottom: 1.6rem;
    }
  }
`
