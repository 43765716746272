import React, { useState, useEffect } from 'react'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import AppointmentScheduleIcon from '../../../../assets/media/icons/AppointmentScheduleIcon.svg'
import iconChevronDown from '../../../../assets/media/icons/icon-chevron-down.svg'
import iconChevronUp from '../../../../assets/media/icons/icon-chevron-up.svg'
import { useScreenSize } from '../../../Contexts/ResponsiveContextProvider'
import { Div, DivWithFlex, Highlight2, Image, ImageDiv, Highlight5, TextComponent, SmallTitle } from './styled'
import { getOperationHours } from '../../../Helpers/Utils'
function AccordianComponent({ data = [] }) {
  const todaysDay = moment(new Date()).format('dddd')
  const { t } = useTranslation('AppointmentDetails')
  const [isActive, setIsActive] = useState(false)
  const { isMobileWidth } = useScreenSize()
  useEffect(() => {
    setIsActive(!isMobileWidth ? true : false)
  }, [isMobileWidth])
  const fetchFontColor = (weekDay) => {
    return weekDay.toLowerCase() === todaysDay.toLowerCase() ? 'orange' : 'rich_black'
  }
  const fetchFontFamily = (weekDay) => {
    return weekDay.toLowerCase() === todaysDay.toLowerCase() ? 'var(--font-family-bold)' : 'var(--font-family-regular)'
  }

  return (
    <Div
      $borderTop='1px solid var(--color-smoke-grey)'
      $borderBottom={isMobileWidth ? '1px solid var(--color-smoke-grey)' : 'none'}
      $padding='3.2rem 0rem 2.4rem 0rem'
    >
      <DivWithFlex
        $columnGap='1.2rem'
        onClick={() => {
          setIsActive(!isActive)
        }}
      >
        <ImageDiv>
          <Image src={AppointmentScheduleIcon} alt='AppointmentScheduleIcon' $height='16px' $width='16px' />
        </ImageDiv>
        <TextComponent $paddingRight='0.4rem'>{t('centerHours')}</TextComponent>
        {!isActive && (
          <ImageDiv>
            <Image src={iconChevronDown} alt='iconChevronDown' $height='13px' $width='13px' />
          </ImageDiv>
        )}
        {isActive && (
          <ImageDiv>
            <Image src={iconChevronUp} alt='iconChevronUp' $height='13px' $width='13px' />
          </ImageDiv>
        )}
      </DivWithFlex>
      {/* Content */}
      {isActive && (
        <DivWithFlex
          $direction='column'
          $justifyContent='space-around'
          $padding={isMobileWidth ? '0rem 0rem 4rem 0rem' : '0rem'}
          $margin='2.4rem 0rem 0rem 0rem'
          $rowGap='1.6rem'
        >
          {data &&
            data.length &&
            data.map((el) => {
              return (
                <DivWithFlex $columnGap='3.2rem' $padding='0rem 0rem 0rem 2.8rem'>
                  <Div $width='10rem'>
                    <TextComponent $color={fetchFontColor(el.weekDayName)} $fontSize='1.6rem' $fontFamily={fetchFontFamily(el.weekDayName)}>
                      {t(`${el.weekDayName}`)}
                    </TextComponent>
                  </Div>
                  <Div>
                    <TextComponent $color={fetchFontColor(el.weekDayName)} $fontSize='1.6rem' $fontFamily={fetchFontFamily(el.weekDayName)}>
                      {getOperationHours(el)}
                    </TextComponent>
                  </Div>
                </DivWithFlex>
              )
            })}
        </DivWithFlex>
      )}
    </Div>
  )
}

export default AccordianComponent
