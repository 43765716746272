import React from 'react'
import { Image } from 'semantic-ui-react'
import './styles.scss'
import importer from '@takedapdt/biolife-core/src/importer'
import infoIcon from '../../../../assets/media/icons/InfoIcon.svg'
import { useTranslation } from 'react-i18next'

const { setTextColor } = importer('Constant/Utils', null)

const { useScreenSize } = importer('Contexts/ResponsiveContextProvider', null)

const CustomCheckbox = ({ checkboxId, label, checkboxLabelColor, isRequired, input, meta }) => {
  const { isMobileWidth, isTabletWidth } = useScreenSize()
  const { value, onChange } = input
  const hasError = (meta.dirty && meta.error) || (meta.error && meta.submitFailed && isRequired)
  const { t } = useTranslation('ValidationErrorMessages')

  const handleCheckboxChange = (event) => {
    if (input) {
      onChange(event.target.checked)
    }
  }

  const handleCheckmarkClick = (e) => {
    e.preventDefault()
    handleCheckboxChange({ target: { checked: !value } })
  }

  return (
    <div className={`CheckboxContainer ${hasError ? 'HasError' : ''}`}>
      <div className='custom-checkbox '>
        <input type='checkbox' id={checkboxId} checked={value} onChange={handleCheckboxChange} />
        <label htmlFor={checkboxId}>
          <span className='checkmark' onClick={handleCheckmarkClick}></span>
        </label>
        <span style={{ ...setTextColor(checkboxLabelColor) }} dangerouslySetInnerHTML={{ __html: label }} />
        <span className='InfoIcon'>{hasError && <Image src={infoIcon} width={isMobileWidth ? '35' : '24'} height='auto' />}</span>
      </div>
      {(meta.dirty && meta.error) || meta.submitFailed ? <span className='ErrorText'>{t(meta.error)}</span> : null}
    </div>
  )
}

export default CustomCheckbox
