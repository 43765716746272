import { all, fork } from 'redux-saga/effects'

import importer from '@takedapdt/biolife-core/src/importer'

const CustomSdkSagas = importer('Sagas/CustomSdkSagas')
//const AuthSaga = importer('Sagas/AuthSaga')
//const AwsSaga = importer('Sagas/AwsSaga')

export default function* root() {
  yield all([
    // fork(AuthSaga),
    //fork(AwsSaga),
    fork(CustomSdkSagas)
  ])
}
