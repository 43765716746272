import styled from 'styled-components'
import importer from '@takedapdt/biolife-core/src/importer'
const { getCssColorFromDrupalColorType } = importer('Constant/Utils', null)
const { devices } = importer('utils/styledComponentHelpers', null)

export const Container = styled.div`
  background: ${({ bgColor }) => getCssColorFromDrupalColorType(bgColor)};
`

export const MainContainer = styled(Container)`
  height: 30rem;
  ${devices.tablet} {
    height: 40rem;
  }
  ${devices.mobile} {
    height: auto;
    padding-top: 20rem;
  }
`

export const ContainerWithFlex = styled.div`
  display: flex;
  flex-direction: ${({ imageAlignment, direction = '' }) => (direction ? direction : imageAlignment ? 'row' : 'row-reverse')};
  ${devices.mobile}{
    align-items: center;
  }
`

export const ContainerWithFlexSocialMedia = styled.div`
  margin-top: 3rem;
` 
