import React from 'react'
import importer from '@takedapdt/biolife-core/src/importer'
import '../styles.scss'
const Banner = importer('Components/BannerTemplate')
const { getCssColorFromDrupalColorType } = importer('Constant/Utils', null)
const { useScreenSize } = importer('Contexts/ResponsiveContextProvider', null)

const ImageBannerTemplate1 = (props) => {
  const { isMobileWidth } = useScreenSize()
  const {
    field_bg_color: imageBannerBGColor,
    field_component_bg_image: imageBannerComponentBGImage,
    field_component_mbg_image: imageBannerComponentMBGImage
  } = props

  return (
    <div
      className='ImageBanner'
      style={{
        background: `url(${
          isMobileWidth ? imageBannerComponentMBGImage?.url : imageBannerComponentBGImage?.url
        }) no-repeat center / cover, ${getCssColorFromDrupalColorType(
          isMobileWidth && imageBannerBGColor === 'orange_gradient' ? 'orange_gradient_bottom' : imageBannerBGColor
        )}`
      }}
    >
      <Banner {...props} />
    </div>
  )
}

export default ImageBannerTemplate1
