import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import PaymentsActions from '@takedapdt/biolife-core/src/Stores/Payments/Actions'
import styled from 'styled-components'
import { setTextColor } from '../../../src/Constant/Utils'
import Accordion from '../../Components/Accordion'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import moment from 'moment'
import { isEmpty } from 'lodash'
import CustomModal from '../../Components/CustomModal'
import PrimaryButton from '../../Components/Buttons/PrimaryButton'
import { Icon, Message } from 'semantic-ui-react'
import { devices } from '../../utils/styledComponentHelpers'
import { ReactComponent as FilterIcon } from '../../../assets/media/icons/UI-filter-icon.svg'
import { ReactComponent as CloseIcon } from '../../../assets/media/icons/closeIcon.svg'
import LoaderSpinner from '../Loaders/LoaderSpinner'
import { useScreenSize } from '../../Contexts/ResponsiveContextProvider/index'

const ErrorText = styled.p`
  color: var(--color-validation-red);
`

const ItemContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  grid-column-gap: 1rem;
  padding: 3rem 0 0 0;
  border-top: 0.5px solid #ccc;
`

const ItemDetails = styled.div`
  display: flex;
  flex-direction: column;
`
const ItemActivityDate = styled.div`
  font-size: 1.6rem;
  padding-top: 0.8rem;
  font-weight: bolder !important;
  padding-bottom: 2.4rem;
`
const ItemExpiryDate = styled.div`
  font-size: 1.6rem;
  padding-top: 0.8rem;
  font-style: italic;
  font-weight: 100 !important;
`
const ItemPoints = styled.strong`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.6rem;
`

const ItemDescription = styled.strong`
  font-size: 1.8rem;
`
const DebitTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`
const FilterPopupContainer = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
  column-gap: 1.6rem;
  svg {
    width: 2.4rem !important;
    height: 2.1rem !important;
  }
  ${devices.mobile} {
    justify-content: flex-end;
    padding-bottom: 2.5rem;
  }
`
const FilterPopup = styled.p`
  font-size: var(--caption-font-size) !important;
  font-family: var(--font-family-regular) !important;
  color: var(--color-bl-blue);
`
const TransactionEntries = styled.div``
const Text = styled.span`
  font-size: var(--caption-mobile-font-size) !important;
  font-family: var(--font-family-bold) !important;
  color: var(--color-dark-grey);
`
const DateFormat = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`
const StartDate = styled.div`
  height: 8.1rem;
`
const FilterTitle = styled.div`
  text-align: center;
  padding-bottom: 2rem;
`
const ModalData = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 4rem;
  column-gap: 1.6rem;
  ${devices.mobile} {
    display: contents;
  }
`
const TextContainer = styled.div`
  text-align: left;
  padding: 1.6rem 0;
  margin-top: 3rem;
  ${devices.mobile} {
    margin-top: 0;
  }
`
const Filter = styled.span`
  background-color: var(--color-medium-shade-gray);
  padding: 0.4rem 0.8rem;
  border-radius: 0.4rem;
  font-size: var(--caption-mobile-font-size) !important;
  font-family: var(--font-family-regular) !important;
`
const Popupcontainer = styled.div`
  border-bottom: 0.1rem solid var(--color-medium-shade-gray);
  margin-bottom: 4rem;
`
const EndDate = styled.div`
  height: 8.1rem;
`
const DatePickerWrapper = styled.div`
  position: relative;
  display: inline-block;
  min-width: 34rem;
  margin-top: 0.6rem;
  width: 100%;
  ${devices.mobile} {
    width: 100%;
    min-width: 24rem;
  }
  .input {
    border-width: 0.1rem !important;
    border: 0.1rem solid red !important;
  }
`
const CalendarIcon = styled(Icon)`
  color: var(--color-light-orange);
  position: absolute;
  top: 50%;
  right: 1rem;
  transform: translateY(-50%);
  width: 1.6rem !important;
  height: 1.6rem !important;
`

const CustomDatePicker = styled(DatePicker)`
  min-width: 34rem;
  width: 100%;
  height: 5.6rem;
  ${devices.mobile} {
    width: 100%;
  }
`
const SelectedDateContainer = styled.div`
  margin-bottom: 4rem;
  display: flex;
  align-items: center;
  ${devices.mobile} {
    flex-direction: column;
    align-items: flex-start;
  }
`
const FilterClose = styled.div`
  display: flex;
  background-color: var(--color-light-grey);
  border-radius: 1.9rem;
  padding: 0.8rem 1.6rem;
`
const SelectedDate = styled.p`
  font-size: var(--caption-mobile-font-size) !important;
  font-family: var(--font-family-bold) !important;
`
const CloseIconContainer = styled.div`
  padding-right: 0.8rem;
  cursor: pointer;
  padding-top: 0.1rem;
  svg {
    width: 1.2rem !important;
    height: 1.2rem !important;
  }
`
const ClearFilterMobile = styled.span`
  font-size: var(--caption-font-size) !important;
  font-family: var(--font-family-regular) !important;
  color: var(--color-bl-blue);
  text-decoration: underline;
  padding-top: 3.2rem;
`
const ClearFilter = styled.span`
  padding-left: ${({ $isMobileWidth }) => ($isMobileWidth ? '0.2rem' : '1.6rem')};
  font-size: var(--p-font-size) !important;
  font-family: var(--font-family-regular) !important;
  color: var(--color-bl-blue);
  cursor: pointer;
  ${devices.mobile} {
    padding-top: 2.4rem;
  }
`
const WarningMessage = styled.div`
  margin-bottom: 4rem;
`
const PaymentHistoryPage = (props) => {
  const {
    donor,
    paymentHistory,
    getPaymentHistory,
    field_component_title,
    field_title_color,
    paymentHistoryError,
    paymentHistoryLoading
  } = props
  const getFilteredDates = sessionStorage.getItem('filterOnPayment')
  const [paymentHistoryData, setPaymentHistoryData] = useState()
  const { t, i18n } = useTranslation('PaymentActivity')
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [filterOn, setFilterOn] = useState(false)
  const [popUpClose, setPopUpClose] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)
  const { isMobileWidth } = useScreenSize()
  useEffect(() => {
    // storing the payment filter details in session storage
    // on language translation the filter is getting removed
    //  using the dates in sessionStorage to make the api call on language translations

    if (donor?.pdn) {
      window.addEventListener('beforeunload', function(e) {
        if (getFilteredDates) {
          window.sessionStorage.removeItem('filterOnPayment')
        }
      })
      if (getFilteredDates) {
        let filterDate = JSON.parse(sessionStorage.filterOnPayment)
        setFilterOn(true)
        getPaymentHistory(donor?.pdn, moment(filterDate?.startDate).format('YYYY-MM-DD'), moment(filterDate?.endDate).format('YYYY-MM-DD'))
      } else {
        getPaymentHistory(donor?.pdn, null, null)
      }
    }
  }, [getFilteredDates])
  useEffect(() => {
    const transformedPaymentHistoryData = new Map()
    const sortedPaymentHistoryData = paymentHistory?.data?.paymentHistory?.sort((a, b) => {
      // Create Date objects for comparison
      const dateA = new Date(a.transactionDate)
      const dateB = new Date(b.transactionDate)
      // Sort in descending order (latest first)
      return dateB - dateA
    })
    sortedPaymentHistoryData &&
      sortedPaymentHistoryData?.map((item) => {
        const date = moment(item.transactionDate).format('MMMM YYYY')
        if (transformedPaymentHistoryData.has(date)) {
          transformedPaymentHistoryData.get(date).push({
            ...item
          })
        } else {
          transformedPaymentHistoryData.set(date, [
            {
              ...item
            }
          ])
        }
      })

    setPaymentHistoryData(transformedPaymentHistoryData)
  }, [paymentHistory, i18n.language])

  useEffect(() => {
    if (getFilteredDates && !filterOn) {
      let filterDate = JSON.parse(sessionStorage.filterOnPayment)
      setStartDate(filterDate.startDate)
      setEndDate(filterDate.endDate)
    }
  }, [getFilteredDates])
  /*This function updates the startDate*/
  const handleStartDateChange = (date) => {
    let dateDetails = { startDate: date, endDate: endDate }
    sessionStorage.setItem('filterOnPayment', JSON.stringify(dateDetails))
    setStartDate(date)
    setErrorMessage(null)
  }
  /* If startDate exists, date is provided, and date is after or equal to startDate:
Updates endDate with the selected date and clears any error message.
If date is before startDate:
*/
  const handleEndDateChange = (date) => {
    let dateDetails = { startDate: startDate, endDate: date }
    sessionStorage.setItem('filterOnPayment', JSON.stringify(dateDetails))
    setEndDate(date)
    setErrorMessage(null)
  }
  /*This function resets the filter state*/
  const clearFilter = () => {
    setStartDate(null)
    setEndDate(null)
    setFilterOn(false)
    setPopUpClose(false)
    setErrorMessage(null)
    sessionStorage.removeItem('filterOnPayment')
    getPaymentHistory(donor?.pdn, null, null)
  }
  const clearFilterMobile = () => {
    setStartDate(null)
    setEndDate(null)
    setErrorMessage(null)
    sessionStorage.removeItem('filterOnPayment')
  }
  const handleOpen = () => {
    setFilterOn(true)
    setPopUpClose(true)
    setStartDate(null)
    setEndDate(null)
    sessionStorage.removeItem('filterOnPayment')
  }
  /*This function handles form submission for filtering:
Sets filterOn to false to close the modal after submission.
Checks if both startDate and endDate are selected and the difference between them is less than or equal to one year using Moment.js.
If valid dates are selected:
Formats the dates using Moment.js and calls getDebitCardHistory to fetch transactions within the specified date range.
*/
  const onSubmit = () => {
    if (startDate && endDate && endDate >= startDate) {
      setPopUpClose(false)
      if (startDate && endDate && moment(endDate).diff(moment(startDate), 'year', true) <= 1) {
        getPaymentHistory(donor?.pdn, moment(startDate).format('YYYY-MM-DD'), moment(endDate).format('YYYY-MM-DD'))
      }
    } else if (endDate < startDate) {
      setErrorMessage(t('DateRange'))
    } else {
      setErrorMessage(t('invalidDateRange'))
      setEndDate(null)
    }
  }
  return (
    <>
      <div className='main-container payment-history-container'>
        <DebitTitle>
          <span
            style={{ ...setTextColor(field_title_color), padding: '2rem 3rem 2rem 0' }}
            dangerouslySetInnerHTML={{ __html: field_component_title?.value }}
          />
          {!isMobileWidth && (
            <FilterPopupContainer>
              <FilterPopup onClick={handleOpen}>{t('filter')}</FilterPopup>
              <FilterIcon onClick={handleOpen} />
            </FilterPopupContainer>
          )}
        </DebitTitle>
        {paymentHistoryLoading ? (
          <LoaderSpinner />
        ) : (
          <>
            {isEmpty(paymentHistory?.data?.paymentHistory) ? (
              <>
                <SelectedDateContainer>
                  <FilterClose>
                    <CloseIconContainer>
                      <CloseIcon onClick={clearFilter} />
                    </CloseIconContainer>
                    <SelectedDate>
                      {moment(startDate).format('MMMM DD, YYYY')} - {moment(endDate).format('MMMM DD, YYYY')}
                    </SelectedDate>
                  </FilterClose>
                  <ClearFilter $isMobileWidth={isMobileWidth} onClick={clearFilter}>
                    {t('clearFilter')}
                  </ClearFilter>
                </SelectedDateContainer>
                {isMobileWidth && (
                  <FilterPopupContainer>
                    <FilterPopup onClick={handleOpen}>{t('filter')}</FilterPopup>
                    <FilterIcon onClick={handleOpen} />
                  </FilterPopupContainer>
                )}
                <WarningMessage>
                  <Message negative>
                    <ErrorText>{t('noMatchingTransactionsFound')}</ErrorText>
                  </Message>
                </WarningMessage>
              </>
            ) : (
              <>
                {!isEmpty(paymentHistory?.data?.paymentHistory) &&
                !paymentHistoryError &&
                ((!startDate && !endDate) || moment(endDate).diff(moment(startDate), 'year', true) <= 1) &&
                filterOn ? (
                  <>
                    <SelectedDateContainer>
                      <FilterClose>
                        <CloseIconContainer>
                          <CloseIcon onClick={clearFilter} />
                        </CloseIconContainer>
                        <SelectedDate>
                          {moment(startDate).format('MMMM DD, YYYY')} - {moment(endDate).format('MMMM DD, YYYY')}
                        </SelectedDate>
                      </FilterClose>
                      <ClearFilter $isMobileWidth={isMobileWidth} onClick={clearFilter}>
                        {t('clearFilter')}
                      </ClearFilter>
                    </SelectedDateContainer>
                    {isMobileWidth && (
                      <FilterPopupContainer>
                        <FilterPopup onClick={handleOpen}>{t('filter')}</FilterPopup>
                        <FilterIcon onClick={handleOpen} />
                      </FilterPopupContainer>
                    )}
                    <TransactionEntries>
                      {paymentHistory.data.paymentHistory.map((entry, index) => (
                        <ItemContainer>
                          <ItemDetails>
                            <ItemDescription>{entry.type === 6 ? t('giftCard') : t('debitCard')}</ItemDescription>
                            <ItemActivityDate> {moment(entry.transactionDate).format('MMMM DD, YYYY')}</ItemActivityDate>
                            {entry.expiryDate && (
                              <ItemExpiryDate>
                                {entry.expiryText} {''}
                                {moment(entry.expiryDate).format('MMMM DD, YYYY')}
                              </ItemExpiryDate>
                            )}
                          </ItemDetails>
                          <ItemPoints>${entry.transactionAmount}</ItemPoints>
                        </ItemContainer>
                      ))}
                    </TransactionEntries>
                  </>
                ) : (
                  <>
                    {paymentHistoryData && !filterOn && (
                      <>
                        {isMobileWidth && (
                          <FilterPopupContainer>
                            <FilterPopup onClick={handleOpen}>{t('filter')}</FilterPopup>
                            <FilterIcon onClick={handleOpen} />
                          </FilterPopupContainer>
                        )}
                        <>
                          {Array.from(paymentHistoryData).map((item) => (
                            <Accordion
                              title={item[0]}
                              field_title_color={field_title_color}
                              description={<RenderList description={item[1]} />}
                            />
                          ))}
                        </>
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </>
        )}
      </div>
      <CustomModal openModal={popUpClose} onClose={() => clearFilter()}>
        <DateFormat>
          <Popupcontainer>
            <FilterTitle>
              <Filter>{t('filterBy')}</Filter>
            </FilterTitle>
            <ModalData>
              <StartDate>
                <p className='selectfdatetext'>{t('startDate')}</p>
                <DatePickerWrapper>
                  <CustomDatePicker selected={startDate} onChange={handleStartDateChange} startDate={startDate} />
                  <CalendarIcon name='calendar alternate outline' size='large' />
                </DatePickerWrapper>
              </StartDate>
              <TextContainer>
                <Text>{t('to')}</Text>
              </TextContainer>
              <EndDate>
                <p className='selectfdatetext'>{t('endDate')}</p>
                <DatePickerWrapper>
                  <CustomDatePicker selected={endDate} onChange={handleEndDateChange} startDate={startDate} selectsEnd />
                  <CalendarIcon name='calendar alternate outline' size='large' />
                </DatePickerWrapper>
              </EndDate>
            </ModalData>
          </Popupcontainer>
          {errorMessage && filterOn && (
            <Message negative>
              <ErrorText>{errorMessage}</ErrorText>
            </Message>
          )}
          <div>
            <PrimaryButton
              text={t('apply')}
              type='submit'
              className='apply'
              onClick={() => onSubmit()}
              disabled={!(startDate && endDate)}
            />
          </div>
          {isMobileWidth && <ClearFilterMobile onClick={clearFilterMobile}>{t('clearFilter')}</ClearFilterMobile>}
        </DateFormat>
      </CustomModal>
    </>
  )
}
const RenderList = ({ description }) => {
  const { t } = useTranslation('PaymentHistory')
  return (
    <>
      {description?.map((item) => (
        <ItemContainer>
          <ItemDetails>
            <ItemDescription>{item.type === 6 ? t('giftCard') : t('debitCard')}</ItemDescription>
            <ItemActivityDate>{moment(item.transactionDate).format('MMMM DD, YYYY')}</ItemActivityDate>
            {item.expiryDate && (
              <ItemExpiryDate>
                {item.expiryText} {''}
                {moment(entry.expiryDate).format('MMMM DD, YYYY')}
              </ItemExpiryDate>
            )}
          </ItemDetails>
          <ItemPoints>${item.transactionAmount}</ItemPoints>
        </ItemContainer>
      ))}
    </>
  )
}

const mapStateToProps = (state) => ({
  donor: state?.donors?.donors?.data,
  user: state.users.loginHerokuSuccess,
  paymentHistory: state.payments?.getPaymentHistorySuccess,
  paymentHistoryLoading: state.payments?.getPaymentHistoryLoading,
  paymentHistoryError: state.payments?.getPaymentHistoryFailure
})

const mapDispatchToProps = (dispatch) => ({
  getPaymentHistory: (pdn, startDate, endDate) => dispatch(PaymentsActions.getPaymentHistory(pdn, startDate, endDate))
})

export default connect(mapStateToProps, mapDispatchToProps)(PaymentHistoryPage)
