import { put, select } from 'redux-saga/effects'
import importer from '@takedapdt/biolife-core/src/importer'

const UserActions = importer('Stores/Users/Actions')
const { user } = importer('Stores/Users/Select', null)

export default function * failure ({ error }) {
   const loggedInUser = yield select(user)
   if (error?.response?.status === 401 && loggedInUser) {
    yield put(UserActions.logout())
  }
}
