import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import importer from '@takedapdt/biolife-core/src/importer'
const PublicContentActions = importer('Stores/PublicContent/Actions')
import styled from 'styled-components'
const { devices } = importer('utils/styledComponentHelpers', null)
// use the field_poup_type to display the correct title and description
const WaysToEarnPopUp = ({ donor, loyaltyMilestones, getLoyaltyMilestones }) => {
  const GridContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-gap: 2.2rem;
  `

  const GridContainerEarn = styled.div`
    display: grid;
    grid-template-rows: auto 1fr;
    grid-row-gap: 0.5rem;
  `

  const GridRowWays = styled.div`
    display: grid;
    margin: 1rem 0;
    grid-template-columns: auto auto;
    grid-gap: 10px;
    ${devices.mobile} {
      grid-template-columns: 1.8fr 1fr;
    }
  `

  const GridColumnTitle = styled.div`
    font-weight: bold;
    font-size: 1.8rem;
  `

  const GridColumnPoints = styled.div`
    color: #f96506;
    font-weight: bold;
    text-align: right;
    font-size: 1.8rem;
  `

  const GridColumnSubtitle2 = styled.div`
    font-size: 1.4rem;
  `
  const { t, i18n } = useTranslation('WaystoEarnCard', 'FactoryFunctionTexts')
  useEffect(() => {
    if (donor) {
      getLoyaltyMilestones(donor?.defaultCenter?.sfid)
    }
  }, [donor, i18n.language])

  const WaysToEarn = ({ title, subtitle, points }) => (
    <GridContainerEarn className='badgeDetailsContainer' verticalAlign='middle'>
      <GridRowWays>
        <GridColumnTitle className='donationTitle'>
          <strong>{title}</strong>
        </GridColumnTitle>
        <GridColumnPoints className='donationPoints'>
          <strong>
            {points} {t('points')}
          </strong>
        </GridColumnPoints>
      </GridRowWays>
      <GridColumnSubtitle2 className='donationSubtitle'>{subtitle}</GridColumnSubtitle2>
    </GridContainerEarn>
  )
  return (
    <>
      <GridContainer>
        {loyaltyMilestones?.length > 0 &&
          loyaltyMilestones
            .filter((milestone) => milestone.bll_active__c)
            .sort((a, b) => a.bll_top_earn_activity_order__c - b.bll_top_earn_activity_order__c)
            .map((milestone) => (
              <WaysToEarn
                key={milestone.id}
                title={milestone.bll_milestone_title__c}
                subtitle={milestone.bll_milestone_sub_title__c}
                points={milestone.bll_points__c}
              />
            ))}
      </GridContainer>
    </>
  )
}

const mapStateToProps = (state) => ({
  loyaltyMilestones: state.publicContent?.getLoyaltyMilestonesSuccess
})

const mapDispatchToProps = (dispatch) => ({
  getLoyaltyMilestones: (sfid) => dispatch(PublicContentActions.getLoyaltyMilestones(sfid))
})

export default connect(mapStateToProps, mapDispatchToProps)(WaysToEarnPopUp)
