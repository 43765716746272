import { createActions } from 'reduxsauce'

const { Types, Creators } = createActions({
  initializeApp: ['res'], // PUBLIC_STARTUP
  initializeAppSuccess: ['res'], // PUBLIC_STARTUP_SUCCESS
  initializeAppFailure: ['error'], // PUBLIC_STARTUP_FAILURE
})

export const StartupTypes = Types

export default Creators
