import styled from 'styled-components'
import { devices } from '../../../utils/styledComponentHelpers'

export const AppointmentSlotsGrid = styled.div`
  display: grid;
  grid-template-columns: ${(props) => (props.$availableSlots < 6 ? `repeat(${props.$availableSlots}, 1fr)` : 'repeat(6, 1fr)')};
  grid-gap: 2.4rem 1.6rem;
  justify-items: center;
  align-items: center;
  ${devices.mobile} {
    grid-template-columns: ${(props) => (props.$availableSlots < 3 ? `repeat(${props.$availableSlots}, 1fr)` : 'repeat(3, 1fr)')};
    grid-gap: 1.6rem 2.4rem;
  }
`
export const AppointmentSlot = styled.div`
  width: 8.4rem;
  height: 3.5rem;
  border: ${(props) => (props.$selectedSlot ? 'none' : '0.1rem solid var(--color-rich-black)')};
  border-radius: 1.8rem;
  text-align: center;
  padding: 0.8rem auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => (props.$selectedSlot ? 'var(--color-orange)' : 'transparent')};
  cursor: pointer;
`
export const AvailableSlotTime = styled.input`
  display: none;
  font-size: var(--caption-mobile-font-size);
  &:checked + label {
    color: var(--color-white)
  }
}
`
export const AvailableSlotTimeLabel = styled.label`
  font-size: var(--caption-mobile-font-size);
  cursor: pointer;
`
export const AppointmentSelectionErrorContainer = styled.div`
  text-align: center;
  ${devices.mobile} {
    padding: 0 6.1rem;
  }
`
export const LoadingState = styled.div`
  padding: 2rem 0;
`

export const AppointmentSlotTimeRange = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 2.4rem;

  button {
    width: 100%;
    background: var(--color-white);
    border: 0;
    border-bottom: 0.4rem solid var(--color-light-shaded-grey);
    padding: 0.8rem 1.6rem;
    cursor: pointer;
    font-family: var(--font-family-medium) !important;
    font-size: var(--p-font-size);
    color: var(--color-rich-black);

    &:hover, &:active, &:focus {
      color: var(--color-orange);
      transition: all ease-in-out .3s;
    }

    &.active {
      border-bottom: 0.4rem solid;
      border-image: linear-gradient(90deg, var(--color-dark-shaded-orange) 0%,var(--color-light-shaded-orange) 100%) 30;
      font-family: var(--font-family-bold) !important;
      color: var(--color-orange);
      transition: all ease-in-out .3s;
    }
  }
`
