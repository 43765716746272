import React from 'react'
import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'
import importer from '@takedapdt/biolife-core/src/importer'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { ROUTES } from '../../NavigationService'

const { devices, sizes } = importer('utils/styledComponentHelpers', null)

const StyledAppointmentDetailCard = styled.div`
  padding: 2.4rem;
  background-color: var(--color-white);
  border-radius: 2.5rem;
  display: flex;
  gap: 2.4rem;
  justify-content: space-between;
  width: 35.8rem;
  ${devices.mobile} {
    max-width: 34rem;
    padding: 2.4rem 1.6rem;
  }
`
const StyledAppointmentDetailCardDateColumn = styled.div`
  color: var(--color-orange);
  display: flex;
  flex-direction: column;
  align-items: center;
`
const StyledAppointmentDetailCardDetailsColumn = styled.div`
  flex: 1;
`
const StyledAppointmentDetailCardDay = styled.span`
  font-size: var(--h4-font-size);
  margin-top: -0.7rem;
`
const StyledAppointmentDetailCardMonth = styled.span`
  font-size: var(--caption-font-size);
`
const StyledAppointmentDetailCardYear = styled.span`
  font-size: var(--caption-font-size);
  margin-top: -0.8rem;
`
const StyledAppointmentDetailCardTime = styled.p`
  color: var(--color-bl-blue);
`
const StyledAppointmentDetailCardCenter = styled.p`
  color: var(--color-bl-blue);
  padding-top: 0.4rem;
`
const StyledAppointmentDetailCardDonationType = styled.div`
  font-size: var(--caption-mobile-font-size);
  padding-top: 1.6rem;
  color: var(--color-rich-black);
`
const StyledAppointmentDetailCardButtons = styled.div`
  font-size: var(--caption-mobile-font-size);
  padding-top: 3.2rem;
  display: flex;
  gap: 2.4rem;
`

const AppointmentDetailsCard = ({ appointmentDetail }) => {
  const { t, i18n } = useTranslation('MyAppointments')
  return (
    <Link to={`${ROUTES && ROUTES.AppointmentDetails}`} state={appointmentDetail}>
      <StyledAppointmentDetailCard>
        <StyledAppointmentDetailCardDateColumn>
          <StyledAppointmentDetailCardMonth>
            {moment
              .parseZone(appointmentDetail?.startDateTime)
              .format('MMM')
              .toUpperCase()}
          </StyledAppointmentDetailCardMonth>
          <StyledAppointmentDetailCardDay>
            <strong>{moment.parseZone(appointmentDetail?.startDateTime).format('DD')}</strong>
          </StyledAppointmentDetailCardDay>

          <StyledAppointmentDetailCardYear>
            {moment.parseZone(appointmentDetail?.startDateTime).format('YYYY')}
          </StyledAppointmentDetailCardYear>
        </StyledAppointmentDetailCardDateColumn>
        <StyledAppointmentDetailCardDetailsColumn>
          <StyledAppointmentDetailCardTime>
            <strong>{appointmentDetail?.startTime}</strong>
          </StyledAppointmentDetailCardTime>
          <StyledAppointmentDetailCardCenter>
            <strong>
              {appointmentDetail?.centerName}, {appointmentDetail?.centerStateCode}
            </strong>
          </StyledAppointmentDetailCardCenter>
          <StyledAppointmentDetailCardDonationType>
            {appointmentDetail?.appointmentType === 'DONATION' ? t('donation') : t('donationWithPhysical')}
          </StyledAppointmentDetailCardDonationType>
          <StyledAppointmentDetailCardButtons>
            <span className='underline-dark-blue'>{t('details')}</span>
          </StyledAppointmentDetailCardButtons>
        </StyledAppointmentDetailCardDetailsColumn>
      </StyledAppointmentDetailCard>
    </Link>
  )
}

export default AppointmentDetailsCard
