import React, { useEffect, useRef } from 'react'
import { Card, Grid, Segment, Radio, Icon, Image } from 'semantic-ui-react'
import importer from '@takedapdt/biolife-core/src/importer'
import { ReactSVG } from 'react-svg'
import './styles.scss'
import LocationIcon from '../../../assets/media/icons/icon-location-new.svg'
import PhoneIcon from '../../../assets/media/icons/icon-phone-in-talk.svg'
import { formatPhoneNumber } from '../../Helpers/Utils'
import { useTranslation } from 'react-i18next'
const PrimaryButton = importer('Components/Buttons/PrimaryButton')
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from '../../NavigationService'
import CloseIcon from '../../../assets/media/icons/button-close-large-white.svg'
import TagManager from 'react-gtm-module/dist/TagManager'

const CenterGetDirection = styled.div`
  .BlueUnderlineButton {
    color: var(--color-white) !important;
    font-size: var(--caption-font-size) !important;
  }
`
const CloseButton = styled(Image)`
  cursor: pointer;
  position: absolute !important;
  top: 0.4em;
  right: 1rem;
  height: 2rem;
  width: 2rem;
  color: var(--color-white) !important;
  fill: var(--color-white) !important;
`
const ListViewCenterCard = ({ centerData, iconColor, key, onMapView, onClose }) => {
  const { t } = useTranslation('AppointmentDetails')
  const cardRef = useRef(null)
  useEffect(() => {
    // to remove the card when it is clicked outside the card
    if (!onMapView) return
    const handleClickOutsideCard = (event) => {
      if (cardRef.current && !cardRef.current.contains(event.target) && !centerData) {
        onClose()
      }
    }
    document.addEventListener('mousedown', handleClickOutsideCard)
    return () => {
      document.removeEventListener('mousedown', handleClickOutsideCard)
    }
  }, [onMapView, onClose])

  const openMap = (centerData) => {
    onMapView && TagManager.dataLayer({ dataLayer: { event: 'GetDirectionOnMapMarkerClicked' } })
    return window.open(`http://www.google.com/maps/place/${centerData.latitude},${centerData.longitude}`)
  }
  const navigate = useNavigate()
  const handleCenterClick = (centerData) => {
    navigate(
      `${ROUTES.Locations}/${centerData.state
        .toLowerCase()
        .split(' ')
        .join('-')}/${centerData.centerUrlName}`
    )
  }

  return (
    <div ref={cardRef}>
      <Card raised className={`ListViewCenterCardContainer ${onMapView ? 'onMapView' : 'default'}`} key={key}>
        <Card.Content>
          <Card.Header>
            <div className='HeaderWrapper'>
              {onMapView ? (
                <div className='CloseButton'>
                  <CloseButton src={CloseIcon} onClick={() => onClose()} />
                </div>
              ) : null}
              {centerData ? (
                <CenterGetDirection>
                  <PrimaryButton text={t('getDirections')} variant={'blue_underline'} onClick={() => openMap(centerData)} />
                </CenterGetDirection>
              ) : null}
              {centerData.distanceFromGivenZipcode ? (
                <div className='DistanceBox'>
                  <ReactSVG className={iconColor === 'white' ? '' : 'LocationIconSmall'} src={LocationIcon} />
                  <span className='CenterDistance'>{`${centerData.distanceFromGivenZipcode} ${t('mi')}`}</span>
                </div>
              ) : null}
            </div>
          </Card.Header>
          <Card.Description onClick={() => handleCenterClick(centerData)}>
            {!onMapView && <Image height='125' width='100%' src={centerData.webImageUrl} alt={centerData.name} />}
            <Segment basic>
              <div>
                <p className='CenterName'>{centerData.name}</p>
                <div className='AddressBox'>
                  <ReactSVG className='LocationIcon' src={LocationIcon} />
                  <p className='CenterAddress'>
                    {centerData.addressLine1}
                    <br />
                    {`${centerData.city}, ${centerData.stateCode} ${centerData.zipcode}`}
                    <br />
                  </p>
                </div>
                <div className='PhoneNumberBox'>
                  <ReactSVG className='PhoneIcon' src={PhoneIcon} />
                  <a className='PhoneNumber' href={`tel:${centerData.telephone}`}>
                    {formatPhoneNumber(centerData.telephone)}
                  </a>
                </div>
                {centerData.isSpanishSupported ? <p className='IsSpanishCenter'>{t('habloEspanol')}</p> : null}
              </div>
            </Segment>
          </Card.Description>
        </Card.Content>
      </Card>
    </div>
  )
}

export default ListViewCenterCard
