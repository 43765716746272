import React from 'react'
import { Container, Segment } from 'semantic-ui-react'
import './styles.scss'
import importer from 'common/importer'
const { getCssColorFromDrupalColorType } = importer('Constant/Utils', null)
const { useScreenSize } = importer('Contexts/ResponsiveContextProvider', null)
const DesktopView = importer('Components/RegistrationFormComponent/RegistrationFormComponentViews/DesktopView')
const OtherView = importer('Components/RegistrationFormComponent/RegistrationFormComponentViews/OtherView')

const RegistrationFormComponent = (props) => {
  const { isMobileWidth, isTabletWidth } = useScreenSize()
  return (
  <div className='RegistrationFormContainer'>
      <div className='main-container'>
        <Segment className='styleSegment'>
          <Container fluid className='contanierStyle'>
            {isMobileWidth || isTabletWidth ? <OtherView {...props} /> : <DesktopView {...props} />}
          </Container>
        </Segment>
      </div>
    </div>
  )
}

export default RegistrationFormComponent