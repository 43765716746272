import { Search, Grid, Icon, Pagination } from 'semantic-ui-react'
import styled from 'styled-components'
import importer from '@takedapdt/biolife-core/src/importer'
const { getCssColorFromDrupalColorType, setTextColor } = importer('Constant/Utils', null)
const { devices } = importer('utils/styledComponentHelpers', null)

export const TextComponent = styled.p`
  ${({ color }) => setTextColor(color)};
  font-family: ${({ fontFamily }) => (fontFamily ? `${fontFamily} !important` : 'AvenirNextBold !important')};
  margin-bottom: ${({ marginBottom }) => marginBottom};
  padding-right: ${({ paddingRight }) => paddingRight};
  border-right: ${({ borderRight }) => borderRight};
  text-decoration: ${({ textDecoration }) => textDecoration || 'none'};
`

export const NoResultsFoundTitle = styled.p`
  color: var(--color-orange);
  font-family: 'AvenirNextBold' !important;
  font-size: var(--h2-font-size) !important;
  margin-bottom: 24px;
`

export const Highlight4 = styled.h4`
  ${({ color }) => setTextColor(color)};
  font-family: 'AvenirNextBold' !important;
  margin-bottom: ${({ marginBottom }) => marginBottom};
`

export const Highlight5 = styled.h5`
  ${({ color }) => setTextColor(color)};
  font-family: 'AvenirNextBold' !important;
  margin: ${({ margin }) => margin || '0'};
`

export const Container = styled.div`
  background: ${({ bgColor }) => getCssColorFromDrupalColorType(bgColor)};
  padding: ${({ padding }) => padding};
  height: ${({ minHeight }) => minHeight};
  margin: ${({ margin }) => `${margin}`};
  position: ${({ position }) => position};
  max-width: ${({ maxWidth }) => maxWidth};
  flex-grow: ${({ flexGrow }) => flexGrow};
  text-align: ${({ textAlign }) => textAlign};
`

export const Div = styled.div`
  background: ${({ bgColor }) => getCssColorFromDrupalColorType(bgColor)};
  padding: ${({ padding }) => padding};
  height: ${({ minHeight }) => minHeight};
  margin: ${({ margin }) => `${margin}`};
  position: ${({ position }) => position};
  max-width: ${({ maxWidth }) => maxWidth};
  flex-grow: ${({ flexGrow }) => flexGrow};
  text-align: ${({ textAlign }) => textAlign};
`

export const DivWithFlex = styled.div`
  display: flex;
  background: ${({ bgColor }) => getCssColorFromDrupalColorType(bgColor)};
  flex-direction: ${({ direction }) => direction || 'row'};
  align-items: ${({ align }) => align};
  justify-content: ${({ justifyContent }) => justifyContent};
  border-radius: ${({ borderRadius }) => borderRadius};
  padding: ${({ padding }) => padding};
  margin: ${({ margin }) => margin};
  position: ${({ position }) => position};
  max-width: ${({ maxWidth }) => maxWidth};
  box-shadow: ${({ boxShadow }) => boxShadow};
  flex-wrap: ${({ flexWrap }) => flexWrap};
  column-gap: ${(columnGap) => columnGap};
  row-gap: ${(rowGap) => rowGap};
  flex-grow: ${({ flexGrow }) => flexGrow};
`

export const Label = styled.p`
  font-family: 'AvenirNextBold' !important;
  margin-bottom: 5px;
`

export const StyledSearchComponent = styled(Search)`
  .ui.search .prompt {
    border-radius: 0 !important;
  }
`

export const FeaturedBackgroundImage = styled.div`
  background-image: url(${({ bgImage }) => bgImage});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
`
