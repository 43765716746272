import React, { Component } from 'react'
import { Button, Modal, Grid, Image } from 'semantic-ui-react'
import { ReactComponent as CloseIcon } from '../../../assets/media/icons/button-close-large-light-gray.svg'
import styled from 'styled-components'
import './alertModal.scss'
var timeout = null
import { devices } from '../../utils/styledComponentHelpers'

const StyledImage = styled(CloseIcon)`
  width: 4.8rem;
  height: 4.8rem;

  ${devices.mobile} {
    width: 4rem;
    height: 4rem;
  }
`
export default class index extends Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false
    }
    this.handleClose = this.handleClose.bind(this)
    this.onOpen = this.onOpen.bind(this)
  }

  UNSAFE_componentWillReceiveProps(props) {
    const { open } = props
    const { open: _open } = this.state
    if (open && !_open) {
      this.setState({ open }, () => this.onOpen())
    }
  }

  handleOnClose() {
    this.setState({ open: false }, () => {
      if (timeout) {
        clearTimeout(timeout)
      }
    })
  }

  onOpen() {
    const { timedAction } = this.props
    if (timedAction) {
      const { callback, timer } = timedAction
      // console.log('On Open Set Timer: ', {
      //   callback,
      //   timer
      // })
      timeout = setTimeout(() => this.setState({ open: false }, () => callback()), timer)
    }
  }

  handleClose(callback) {
    this.setState({ open: false }, () => {
      if (timeout) {
        clearTimeout(timeout)
      }
      if (callback) {
        callback()
      }
    })
  }

  render() {
    const { open } = this.state
    const { title, message, actions, titleColour, dontClose } = this.props
    return (
      <Modal className='alert-modal' closeOnEscape={false} closeOnDimmerClick={false} open={open}>
        {title && (
          <Modal.Header>
            <Grid rows={2}>
              {!dontClose && (
                <Grid.Row textAlign='left'>
                  <StyledImage alt='Close' onClick={() => this.handleOnClose()} />
                </Grid.Row>
              )}
              <Grid.Row style={{ color: titleColour ? titleColour : '$brand-blue' }}>
                <h3>{title}</h3>
              </Grid.Row>
            </Grid>
          </Modal.Header>
        )}
        <Modal.Content>
          <Grid>
            <p>{message}</p>
            <br />
          </Grid>
          <Grid className='actions'>
            {actions &&
              actions.length &&
              actions.map((item, index) => (
                <Grid.Column className={actions.length > 1 ? 'actionButtons' : ''} key={index}>
                  <Button
                    primary={item.primary}
                    className={!item.primary ? 'outlineButton' : ''}
                    onClick={() => this.handleClose(item.callback)}
                    negative
                  >
                    {item.text}
                  </Button>
                </Grid.Column>
              ))}
          </Grid>
        </Modal.Content>
      </Modal>
    )
  }
}
