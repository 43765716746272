import React from 'react'
import { Grid } from 'semantic-ui-react'
import './styles.scss'
import styled, { css } from 'styled-components'
import importer from 'common/importer'
const PrimaryButton = importer('Components/Buttons/PrimaryButton')
const { getMediaFromDrupalMimeType, getCssColorFromDrupalColorType, setTextColor } = importer('Constant/Utils', null)
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
const { useScreenSize } = importer('Contexts/ResponsiveContextProvider', null)
const { devices, sizes } = importer('utils/styledComponentHelpers', null)
const { sanitizeHtml } = importer('utils/htmlHelpers', null)

const StyledGridTemplate2 = styled.div`
  background: ${(props) => getCssColorFromDrupalColorType(props.$bgColor)};
  display: flex;
  padding: 8rem 0 6.5rem;
  text-align: center;
  word-break: break-word;
  ${devices.tablet} {
    padding: 6.4rem 0 5rem;
  }
  ${devices.mobile} {
    padding: 5.4rem 0 5rem;
  }
`

const StyledGridTemplate2Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0px 86px;
  ${devices.tablet} {
    padding: 0px;
  }
  ${devices.mobile} {
    padding: 0px;
  }
`

const StyledGridTemplate2Title = styled.div`
  ${(props) => setTextColor(props.$textColor)};
  text-align: ${(props) => props.$alignment === 'left' ? 'left' : props.$alignment === 'right' ? 'right' : 'center' };
  width: 100%;
`
const StyledGridTemplate2Description = styled.div`
  ${(props) => setTextColor(props.$textColor)};
  text-align: ${(props) => props.$alignment === 'left' ? 'left' : props.$alignment === 'right' ? 'right' : 'center' };
  padding-top: 16px;
`
const StyledGridTemplate2BottomDescription = styled.div`
  ${(props) => setTextColor(props.$textColor)};
  padding-top: 3.2rem;
  padding-bottom: 4.7rem;
  text-align: ${(props) => props.$alignment === 'left' ? 'left' : props.$alignment === 'right' ? 'right' : 'center' };
  width: 100%;

  ${devices.tablet} {
    padding-bottom: 6.2rem;
  }

  ${devices.mobile} {
    padding-top: ${(props) => (props.$gridStackedInMobile ? '0' : '70px')};
  }
`
const StyledGridTemplate2PointsContainer = styled.div`
  padding-top: 24px;
  display: grid;
  grid: auto / repeat(${(props) => props.$pointsLength}, 1fr);
  gap: ${(props) => (props.$pointsLength < 4 ? '9.5rem' : '6rem')};

  ${devices.tablet} {
    grid: ${(props) => (props.$pointsLength < 4 ? `auto / repeat(${props.$pointsLength}, 1fr)` : 'auto / repeat(2, 1fr)')};
    gap: 4.8rem;
  }
  ${devices.mobile} {
    grid: auto / repeat(1, 1fr);
    ${props => props.$gridStackedInMobile ? `
      width: 100%;
    ` : ''}
    gap: 1.6rem;
  }
`
const StyledGridTemplate2Points = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: ${(props) => props.$alignment === 'left' ? 'left' : props.$alignment === 'right' ? 'right' : 'center' };
  height: fit-content;
  align-items: ${(props) => props.$alignment === 'left' ? 'flex-start' : props.$alignment === 'right' ? 'flex-end' : 'center' };
  ${devices.mobile} {
    ${props => props.$gridStackedInMobile ? `
      flex-direction: row;
      justify-content: flex-start;
    ` : ''}
  }
`
const StyledGridTemplate2PointsIcon = styled.div`
  margin-bottom: 8px;
  width: 75px;
  height: 75px;
  display: flex;
  justify-content: center;
  align-items: center;

  ${devices.mobile} {
    margin-bottom: 0;

    img {
      width: 5rem;
      height: 5rem;
    }
  }
`
const StyledGridTemplate2PointsTitle = styled.div`
  ${(props) => setTextColor(props.$textColor)};
  text-align: ${(props) => props.$alignment === 'left' ? 'left' : props.$alignment === 'right' ? 'right' : 'center' };
  ${devices.mobile} {
    ${props => props.$gridStackedInMobile ? `
      padding-left: 2rem;
      text-align: left;
      width: 100%;
    ` : ''}
  }
`
const StyledGridTemplate2ButtonContainer = styled.div`
  padding: 40px 0 16px;
  width: 100%;
`

const GridTemplate2 = (props) => {
  const {
    field_column_1_icon: gridColumn1Icon,
    field_column_1_title: gridColumn1Title,
    field_column_2_icon: gridColumn2Icon,
    field_column_2_title: gridColumn2Title,
    field_column_3_icon: gridColumn3Icon,
    field_column_3_title: gridColumn3Title,
    field_column_4_icon: gridColumn4Icon,
    field_column_4_title: gridColumn4Title,
    field_component_desc: componentLongDescription,
    field_column_title_color: gridColumnTitleColor,
    field_column_desc_color: gridColumnDescriptionColor,
    field_component_title: componentTitle,
    field_title_color: componentTitleColor,
    field_short_description: componentShortDescription,
    field_long_desc_color: componentLongDescriptionColor,
    field_short_desc_color: componentShortDescriptionColor,
    field_cta_1: componentButton,
    field_cta_1_style: componentButtonStyle,
    field_bg_color: componentBackgroundColor,
    field_grid_flag: gridStackedInMobile,
    field_text_alignment: gridAlignment
  } = props
  const { isMobileWidth, isTabletWidth } = useScreenSize()
  const slider = React.useRef(null)
  const settings = {
    dots: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    intialSlide: 1,
    arrows: isMobileWidth ? false : true
  }

  const GridPoints = [
    gridColumn1Title && {
      gridColumnIcon: gridColumn1Icon,
      gridColumnTitle: gridColumn1Title
    },
    gridColumn2Title && {
      gridColumnIcon: gridColumn2Icon,
      gridColumnTitle: gridColumn2Title
    },
    gridColumn3Title && {
      gridColumnIcon: gridColumn3Icon,
      gridColumnTitle: gridColumn3Title
    },
    gridColumn4Title && {
      gridColumnIcon: gridColumn4Icon,
      gridColumnTitle: gridColumn4Title
    }
  ]

  const PointsGridContainer = (gridColumnIcon, gridColumnTitle) => {
    return (
      <StyledGridTemplate2Points $gridStackedInMobile={gridStackedInMobile} $alignment={gridAlignment} className='grid-template2__point'>
        <StyledGridTemplate2PointsIcon className='grid-template2__point-icon'>
          {getMediaFromDrupalMimeType(gridColumnIcon?.mimetype, gridColumnIcon?.url, gridColumnIcon?.alt)}
        </StyledGridTemplate2PointsIcon>
        <StyledGridTemplate2PointsTitle
          className='grid-template2__point-title'
          $gridStackedInMobile={gridStackedInMobile}
          dangerouslySetInnerHTML={sanitizeHtml(gridColumnTitle?.processed)}
          $textColor={gridColumnTitleColor}
          $alignment={gridAlignment}
        />
      </StyledGridTemplate2Points>
    )
  }

  return (
    <StyledGridTemplate2 $bgColor={componentBackgroundColor} className='grid-template2'>
      <div className='main-container'>
        <StyledGridTemplate2Container className='grid-template2__container'>
          {componentTitle && (
            <StyledGridTemplate2Title
              $textColor={componentTitleColor}
              dangerouslySetInnerHTML={sanitizeHtml(componentTitle?.processed)}
              className='grid-template2__title'
              $alignment={gridAlignment}
            />
          )}
          {componentShortDescription && (
            <StyledGridTemplate2Description
              $textColor={componentShortDescriptionColor}
              dangerouslySetInnerHTML={sanitizeHtml(componentShortDescription?.processed)}
              className='grid-template2__description'
              $alignment={gridAlignment}
            />
          )}
          {(gridColumn1Title || gridColumn2Title || gridColumn3Title || gridColumn4Title) && (
            <StyledGridTemplate2PointsContainer
              $pointsLength={GridPoints.filter(Boolean).length}
              $gridStackedInMobile={gridStackedInMobile}
              className={`grid-template2__points-container${gridStackedInMobile ? "--unStackedGrid": ''}`}
            >
              {isMobileWidth && !gridStackedInMobile ? (
                <Slider ref={slider} {...settings}>
                  {GridPoints.map((item) => item && <div>{PointsGridContainer(item?.gridColumnIcon, item?.gridColumnTitle)}</div>)}
                </Slider>
              ) : (
                GridPoints?.map((item) => item && PointsGridContainer(item?.gridColumnIcon, item?.gridColumnTitle))
              )}
            </StyledGridTemplate2PointsContainer>
          )}
          {componentLongDescription && (
            <StyledGridTemplate2BottomDescription
              $gridStackedInMobile={gridStackedInMobile}
              $textColor={componentLongDescriptionColor}
              dangerouslySetInnerHTML={sanitizeHtml(componentLongDescription?.processed)}
              className='grid-template2__bottom-description'
              $alignment={gridAlignment}
            />
          )}
          {componentButton && (
            <StyledGridTemplate2ButtonContainer className='grid-template2__button'>
              <PrimaryButton
                text={componentButton?.title}
                variant={componentButtonStyle}
                sendTo={componentButton?.uri}
                fluid={isMobileWidth}
              />
            </StyledGridTemplate2ButtonContainer>
          )}
        </StyledGridTemplate2Container>
      </div>
    </StyledGridTemplate2>
  )
}

export default GridTemplate2
