const getFormattedFooterData = (footerObject) => {
  const formattedData = footerObject && {
    footerBackground: {
      color: footerObject?.field_bg_color
    },
    appStoreBadge: {
      mimetype: footerObject?.field_column_1_icon?.mimetype,
      iconUrl: footerObject?.field_column_1_icon?.url,
      alt: footerObject?.field_column_1_icon?.alt,
      link: footerObject?.field_column_1_url?.uri
    },
    googlePlayBadge: {
      mimetype: footerObject?.field_column_2_icon?.mimetype,
      iconUrl: footerObject?.field_column_2_icon?.url,
      alt: footerObject?.field_column_2_icon?.alt,
      link: footerObject?.field_column_2_url?.uri
    },
    subscribeInputTitle: {
      text: footerObject?.field_column_1_title?.processed,
      format: footerObject?.field_column_1_title?.format,
      color: footerObject?.field_title_color
    },
    downloadAppTitle: {
      text: footerObject?.field_component_title?.processed,
      format: footerObject?.field_component_title?.format,
      color: footerObject?.field_column_title_color
    },
    biolifeLogo: {
      mimetype: footerObject?.field_logo?.mimetype,
      iconUrl: footerObject?.field_logo?.url,
      alignment: footerObject?.field_alignment,
      alt: footerObject?.field_logo?.alt
    },
    biolifeTrademarkStatement: {
      text: footerObject?.field_short_description?.processed,
      color: footerObject?.field_short_desc_color,
      format: footerObject?.field_short_description?.format
    },
    field_takeda_logo: {
      mimetype: footerObject?.field_takeda_logo?.mimetype,
      iconUrl: footerObject?.field_takeda_logo?.url,
      alt: footerObject?.field_takeda_logo?.alt,
      title: footerObject?.field_takeda_logo_url?.title
    },
    socialMediaIcons: [
      {
        mimetype: footerObject?.field_sm_icon_1?.mimetype,
        iconUrl: footerObject?.field_sm_icon_1?.url,
        alt: footerObject?.field_sm_icon_1?.alt,
        link: footerObject?.field_sm_icon_1_url?.uri,
        title: footerObject?.field_sm_icon_1_url?.title
      },
      {
        mimetype: footerObject?.field_sm_icon_2?.mimetype,
        iconUrl: footerObject?.field_sm_icon_2?.url,
        alt: footerObject?.field_sm_icon_2?.alt,
        link: footerObject?.field_sm_icon_2_url?.uri,
        title: footerObject?.field_sm_icon_2_url?.title
      },
      {
        mimetype: footerObject?.field_sm_icon_3?.mimetype,
        iconUrl: footerObject?.field_sm_icon_3?.url,
        alt: footerObject?.field_sm_icon_3?.alt,
        link: footerObject?.field_sm_icon_3_url?.uri,
        title: footerObject?.field_sm_icon_3_url?.title
      },
      {
        mimetype: footerObject?.field_sm_icon_4?.mimetype,
        iconUrl: footerObject?.field_sm_icon_4?.url,
        alt: footerObject?.field_sm_icon_4?.alt,
        link: footerObject?.field_sm_icon_4_url?.uri,
        title: footerObject?.field_sm_icon_4_url?.title
      },
      {
        mimetype: footerObject?.field_sm_icon_5?.mimetype,
        iconUrl: footerObject?.field_sm_icon_5?.url,
        alt: footerObject?.field_sm_icon_5?.alt,
        link: footerObject?.field_sm_icon_5_url?.uri,
        title: footerObject?.field_sm_icon_5_url?.title
      },
      {
        mimetype: footerObject?.field_sm_icon_6?.mimetype,
        iconUrl: footerObject?.field_sm_icon_6?.url,
        alt: footerObject?.field_sm_icon_6?.alt,
        link: footerObject?.field_sm_icon_6_url?.uri,
        title: footerObject?.field_sm_icon_6_url?.title
      },
      {
        mimetype: footerObject?.field_sm_icon_7?.mimetype,
        iconUrl: footerObject?.field_sm_icon_7?.url,
        alt: footerObject?.field_sm_icon_7?.alt,
        link: footerObject?.field_sm_icon_7_url?.uri,
        title: footerObject?.field_sm_icon_7_url?.title
      }
    ]
  }
  return formattedData
}

export default getFormattedFooterData
