import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import importer from 'common/importer'
import Slider from 'react-slick'
import './styles.scss'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
const { getCssColorFromDrupalColorType, getCTAAlignment, setTextColor } = importer('Constant/Utils', null)
const { useScreenSize } = importer('Contexts/ResponsiveContextProvider', null)
import { ReactComponent as NextArrowBlack } from '../../../assets/media/icons/circle-arrow-right.svg'
import { ReactComponent as PrevArrowBlack } from '../../../assets/media/icons/circle-arrow-left.svg'
const CentersActions = importer('Stores/Centers/Actions')
const ListViewCenterCard = importer('Components/ListViewCenterCard')
const PrimaryButton = importer('Components/Buttons/PrimaryButton')

const SliderNextArrowBlack = ({ className, onClick }) => {
  return <NextArrowBlack width='100' height='100' className={className} onClick={onClick} />
}

const SliderPrevArrowBlack = ({ className, onClick }) => {
  return <PrevArrowBlack width='50' height='50' className={className} onClick={onClick} />
}

const NearbyCentersList = ({
  fetchNearbyCenters,
  centerInfo,
  nearbyCenters,
  field_component_title: componentTitle,
  field_title_color: componentTitleColor,
  field_cta_1: componentCTA,
  field_cta_1_style: componentCTAStyle,
  field_cta_alignment: componentCTAAlignment,
  field_bg_color: componentBackgroundColor
}) => {
  const { isMobileWidth, isTabletWidth } = useScreenSize()
  const [nearbyCentersFiltered, setNearbyCentersFiltered] = useState([])

  useEffect(() => {
    if (centerInfo) {
      fetchNearbyCenters(centerInfo?.zipcode)
    }
  }, [centerInfo])

  useEffect(() => {
    setNearbyCentersFiltered(nearbyCenters?.filter((item) => item.sfid !== centerInfo?.sfid))
  }, [nearbyCenters])

  var settings = {
    dots: true,
    intialSlide: 1,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: isMobileWidth || isTabletWidth ? false : true,
    nextArrow: <SliderNextArrowBlack />,
    prevArrow: <SliderPrevArrowBlack />,
    responsive: [
      {
        breakpoint: 1422,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 1366,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 1080,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  }

  return (
    <div
      className={`nearby-centers-list ${nearbyCentersFiltered?.length <= 3 ? 'LessDataWrapper' : ''}`}
      style={{ background: getCssColorFromDrupalColorType(componentBackgroundColor) }}
    >
      {nearbyCentersFiltered?.length > 0 ? (
        <>
          <div style={{ ...setTextColor(componentTitleColor) }} dangerouslySetInnerHTML={{ __html: componentTitle?.processed }} />
          <Slider {...settings}>
            {nearbyCentersFiltered?.map((item, index) => (
              <ListViewCenterCard centerData={item} key={index} iconColor='white' />
            ))}
          </Slider>
        </>
      ) : null}
      {componentCTA?.title && componentCTA?.uri ? (
        <div style={{ textAlign: getCTAAlignment(componentCTAAlignment) }}>
          <PrimaryButton text={componentCTA?.title} sendTo={componentCTA?.uri} variant={componentCTAStyle} />
        </div>
      ) : null}
    </div>
  )
}

const mapStateToProps = (state) => ({
  nearbyCenters: state.centers?.getDonorCentersAllSuccess?.data,
  centerInfo: state.centers?.getCenterInfoSuccess?.data
})

const mapDispatchToProps = (dispatch) => ({
  fetchNearbyCenters: (searchStr) => {
    dispatch(CentersActions.getDonorCentersAll(searchStr, '', '', '', true))
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(NearbyCentersList)
