import React, { useState, useEffect } from 'react'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import {
  StyledHorizontalCalendar,
  StyledHorizontalCalendarWrapper,
  StyledHorizontalCalendarHeaderText,
  StyledHorizontalCalendarMonth,
  StyledHorizontalCalendarDatePicker,
  StyledHorizontalCalendarDateColumn,
  StyledHorizontalCalendarPrevButton,
  StyledHorizontalCalendarNextButton,
  StyledSliderPrevArrow,
  StyledSliderNextArrow
} from './styled'
import { useScreenSize } from '../../../Contexts/ResponsiveContextProvider'
import HorizontalCalendarDayOfWeek from '../HorizontalCalendarDayOfWeek'

const getYear = (monday, sunday) =>
  moment(monday).year() === moment(sunday).year()
    ? moment(monday).format('YYYY')
    : `${moment(monday).format('YYYY')} / ${moment(sunday).format('YYYY')}`

const getMonth = (monday, sunday) =>
  moment(monday).month() === moment(sunday).month()
    ? moment(monday).format('MMMM')
    : `${moment(monday).format('MMMM')} / ${moment(sunday).format('MMMM')}`

const generateWeek = (date, isMobileWidth) => {
  const daysAfterMonday = moment(date).weekday() - 1
  const daysPassedSinceMonday = moment().day() === 0 ? 6 : moment().day() - 1
  const monday = moment(date).add(isMobileWidth ? 0 : -daysAfterMonday, 'days')
  const weekDays = []

  for (let dayCounter = 0; dayCounter < (isMobileWidth ? 4 : 7); dayCounter++) {
    weekDays.push(moment(monday).add(dayCounter, 'days'))
  }

  const sunday = weekDays[isMobileWidth ? 3 : 6]
  const month = getMonth(monday, sunday)
  const year = getYear(monday, sunday)

  const week = { year, month, weekDays }
  return week
}

const formatMonthYear = (month, year) => {
  if (year.includes('/') && month.includes('/')) {
    const years = year.split(' / ')
    const months = month.split(' / ')
    return `${months[0]} ${years[0]} / ${months[1]} ${years[1]}`
  }
  return `${month} ${year}`
}

const HorizontalCalendar = (props) => {
  const { i18n } = useTranslation('common')
  const { isMobileWidth } = useScreenSize()
  const { onDateChange, appointment, handleAppointment, edit, centers, donor, setAppointment, center, centerSlotsFailure } = props
  const [selectedDate, setSelectedDate] = useState(appointment.startDateTime || moment().format('YYYY-MM-DDT00:00:00'))
  const [currentDate, setCurrentDate] = useState(selectedDate)
  const [currentWeek, setCurrentWeek] = useState(generateWeek(selectedDate))
  const [centerList, setCenterList] = useState(centers)
  const { t } = useTranslation('HorizontalCalendar')

  useEffect(() => {
    setCurrentDate(moment(selectedDate?.split('T')[0]).format())
  }, [])

  useEffect(() => {
    if (appointment.startDateTime) {
      setSelectedDate(moment(appointment.startDateTime.split('T')[0]).format())
    }
    setCurrentWeek(generateWeek(currentDate, isMobileWidth))
    setAppointment &&
      setAppointment((prevState) => ({
        ...prevState,
        reScheduledStartDate: moment(currentDate).format('MMMM D, YYYY')
      }))
  }, [currentDate, i18n.language, isMobileWidth])

  useEffect(() => {
    if (onDateChange) {
      onDateChange(moment(selectedDate))
    }
  }, [selectedDate])

  useEffect(() => {
    setCenterList(centers)
  }, [centers])

  const clickSlotDateHandler = (wd) => {
    handleAppointment({
      ...appointment,
      startDateTime: moment(wd)
        .startOf('day')
        .format('YYYY-MM-DDTHH:mm:ss'),
      date: moment(wd).format('MMMM DD, YYYY'),
      weekDay: moment(wd).format('ddd'),
      startTime: null
    })
  }

  //chevron-left and chevron-right classnames are being used as a trigger condition in GTM
  return (
    <StyledHorizontalCalendar>
      <StyledHorizontalCalendarWrapper>
        <StyledHorizontalCalendarHeaderText className='SelectYourDate'>{t('SelectYourDate')}</StyledHorizontalCalendarHeaderText>
        <StyledHorizontalCalendarMonth>
          <strong>{formatMonthYear(currentWeek.month, currentWeek.year)}</strong>
        </StyledHorizontalCalendarMonth>
        <StyledHorizontalCalendarDatePicker>
          <StyledHorizontalCalendarPrevButton>
            <StyledSliderPrevArrow
              className='chevron-left'
              $isMobileWeb={isMobileWidth}
              onClick={() => setCurrentDate(moment(currentDate).add(window.innerWidth <= 990 ? -4 : -7, 'days'))}
            />
          </StyledHorizontalCalendarPrevButton>
          <StyledHorizontalCalendarDateColumn>
            {currentWeek.weekDays.map((wd) => (
              <div key={wd.date()}>
                <HorizontalCalendarDayOfWeek
                  centerSlotsFailure={centerSlotsFailure}
                  dayOfWeek={moment(wd).format('ddd')}
                  dayOfMonth={moment(wd).format('D')}
                  isSelected={moment(wd).format('YYYY-MM-DD') === moment(selectedDate).format('YYYY-MM-DD')}
                  onClick={() => {
                    setSelectedDate(wd)
                    clickSlotDateHandler(wd)
                  }}
                />
              </div>
            ))}
          </StyledHorizontalCalendarDateColumn>

          <StyledHorizontalCalendarNextButton>
            <StyledSliderNextArrow
              className='chevron-right'
              $isMobileWeb={isMobileWidth}
              onClick={() => setCurrentDate(moment(currentDate).add(window.innerWidth <= 990 ? 4 : 7, 'days'))}
            />
          </StyledHorizontalCalendarNextButton>
        </StyledHorizontalCalendarDatePicker>
      </StyledHorizontalCalendarWrapper>
    </StyledHorizontalCalendar>
  )
}
export default HorizontalCalendar
