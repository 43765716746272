import importer from '@takedapdt/biolife-core/src/importer'
const isValidEmail = importer('Helpers/isValidEmail')
const validate = (values) => {
  const errors = {}
  // First name validation
  if (values['First Name']) {
    if (values['First Name'].length < 1) {
      errors['First Name'] = 'firstNameShouldHaveAtLeasst1Char'
    } else if (values['First Name'].length === 1 && values['First Name'].match(/^[a-zA-Z]$/i) === null) {
      errors['First Name'] = 'firstNameShouldBeCharacteterOnly'
    } else if (
      values['First Name'].length > 1 &&
      values['First Name'].match(/^(?!.*(['ʻ,. -]){2})([a-zA-Z])([a-zA-Z'ʻ,. -]*[a-zA-Z])$/i) === null
    ) {
      errors['First Name'] = 'firstNameShouldBeCharacteterOnly'
    }
  } else {
    errors['First Name'] = 'firstNameIsRequired'
  }
  // Last name validation
  if (values['Last Name']) {
    if (values['Last Name'].length < 2) {
      errors['Last Name'] = 'lastNameShouldHaveAtLeast2Char'
    } else if (values['Last Name'].match(/^(?!.*(['ʻ,. -]){2})([a-zA-Z])([a-zA-Z'ʻ,. -]*[a-zA-Z])$/i) === null) {
      errors['Last Name'] = 'lastNameShouldBeCharacter'
    }
  } else {
    errors['Last Name'] = 'lastNameIsRequired'
  }
  // Email validation
  if (values['Email']) {
    const expression = /(?!.*\.{2})^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([ \t]*\r\n)?[ \t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([ \t]*\r\n)?[ \t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i
    if (!expression.test(String(values['Email']).toLowerCase())) {
      errors['Email'] = 'enterAValidEmail'
    }
  } else {
    errors['Email'] = 'emailAddressIsRequired'
  }
  // State validation
  if (!values['State']) {
    errors['State'] = 'stateCannotBeEmpty'
  }
  // Center validation
  if (!values['Center']) {
    errors['Center'] = 'centerCannotBeEmpty'
  }
  // Story validation
  if (!values['Story']) {
    errors['Story'] = 'storyIsRequired'
  }
  // checkbox validation
  if (!values['isShareTermsAccepted']) {
    errors['isShareTermsAccepted'] = 'mustBeCheckedToProceed'
  }
  return errors
}

export default validate
