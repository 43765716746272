import React from 'react'
import './styles.scss'
import importer from 'common/importer'
import { ReactComponent as Quotes } from '../../../../assets/media/icons/quote-mark-48.svg'
import PrimaryButton from 'Components/Buttons/PrimaryButton'

const { getCssColorFromDrupalColorType, setTextColor } = importer('Constant/Utils', null)

const SecondTestimonialCard = ({
  field_component_title_1,
  field_component_title_2,
  field_short_description,
  field_short_desc_color,
  field_cta_1,
  field_cta_1_style
}) => {
  return (
    <div className='SecondTestimonialContainer'>
      <div className='SecondTestimonialTemplateHeader'>
        <Quotes className='quotes-icon' />
      </div>
      <div className='SecondTestimonialContent'>
        <div style={{ textAlign: 'left' }}>
          <div
            className='testimonial-description'
            dangerouslySetInnerHTML={{ __html: field_short_description?.processed }}
            style={{ ...setTextColor(field_short_desc_color) }}
          />
        </div>
        <div className='testimonial-details'>
          <div className='title-section'>
            <h5 className='component-title'>{field_component_title_1}</h5>
            <p className='component-subtitle'>{field_component_title_2}</p>
          </div>
           {field_cta_1 && (
            <div className='ReadMoreBtn'>
              <PrimaryButton text={field_cta_1?.title} sendTo={field_cta_1?.uri} variant={field_cta_1_style} />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default SecondTestimonialCard
