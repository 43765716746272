import {commonMFAPasswordValidation} from '../../../Helpers/Utils';
const validate = (values) => {
  const errors = {}
  if (values['New Password']) {
    commonMFAPasswordValidation({
      passwordField: 'New Password',
      errors: errors,
      values: values
    })
  } else {
    errors['New Password'] = 'passwordCannotBeEmpty'
  }
  if (values['Confirm New Password']) {
    if (values['Confirm New Password'] !== values['New Password']) {
      errors['Confirm New Password'] = 'passwordsDoNotMatch'
    }
  } else {
    errors['Confirm New Password'] = 'confirmPasswordCannotBeEmpty'
  }

  return errors
}

export default validate
