import styled from 'styled-components'
import importer from '@takedapdt/biolife-core/src/importer'
const { getCssColorFromDrupalColorType } = importer('Constant/Utils', null)
const { devices } = importer('utils/styledComponentHelpers', null)

export const DashboardMainContainer = styled.div`
  //TextComponent styles
  .text-component__container {
    padding-top: 6.4rem;
    padding-bottom: 4.8rem;
    ${devices.mobile} {
      padding-bottom: 2.4rem;
    }
  }
  .text-component__container:has(.text-component-description) {
    padding: 0;
    .text-component-description {
      font-size: var(--p-font-size);
      padding-top: 0;
      padding-bottom: 3.2rem;
      text-align: center;
      ${devices.mobile} {
        text-align: left;
      }
    }
  }
  .upcoming-appointments {
    padding-top: 0;
  }
  //StyledCTA component styles
  .styled-cta {
    ${(props) => (props.$isNotLoyaltyMember ? 'padding-bottom: 64px;' : 'padding-bottom: 0;')}
    ${devices.mobile} {
      .ui.button {
        width: 100%;
      }
    }
  }
  //HeroTemplate5 styles
  .HeroTemplate5Container {
    padding: 0 calc((100vw - 100.8rem) / 2);
    ${(props) =>
      props.$isNotLoyaltyMember
        ? `
            padding-bottom: 6.4rem;
            ${devices.mobile} {
                padding-bottom: 4.8rem;
            }`
        : ''}
    .ui.grid {
      width: 100%;
      padding-left: 8.6rem;
      ${devices.tablet} {
        padding-left: 4.8rem;
        padding-right: 4.8rem;
      }
      ${devices.mobile} {
        padding-left: 2.4rem;
        padding-right: 2.4rem;
      }
      .HeroGridColumn {
        ${devices.mobile} {
          padding-top: 0;
          padding-bottom: 0;
        }
        .HeroContentContainer {
          .ContentContainer {
            .HeroDescriptionContainer {
              padding-top: 1.6rem;
            }
          }
          .ButtonContainer {
            ${devices.mobile} {
              padding-top: 4rem;
              padding-bottom: 4.8rem;
            }
          }
        }
        .HeroImgContainer {
          a {
            width: 100%;
          }
        }
      }
    }
  }
  //MediaAlignment change styles
  .HeroTemplate5Container.ReverseStylesContainer {
    .ui.grid {
      padding-left: 0;
      .HeroGridColumn.ReverseStyles {
        .HeroContentContainer {
          padding-right: 8.6rem;
          text-align: right;
          .ContentContainer {
            .HeroTitleContainer {
              width: 100%;
            }
            .HeroDescriptionContainer {
              p {
                width: 100%;
              }
            }
          }
          .ButtonContainer {
            justify-content: flex-end;
          }
        }
      }
    }
  }
  //CardTemplate3 styles
  .cardtemplate3--main__container {
    padding-top: 11.2rem;
    padding-bottom: 19.2rem;
    ${devices.mobile} {
      padding-top: 0;
      padding-bottom: 13.6rem;
    }
    .cardtemplate3--bgImage__container .cardtemplate3--leftcontainer {
      ${devices.mobile} {
        position: relative;
        height: 22rem;
        display: flex;
        justify-content: center;
      }
    }
    .cardtemplate3--bgImage__container .cardtemplate3--rightcontainer {
      ${devices.mobile} {
        text-align: center;
      }
    }
    .bgCardblock .bgCardblock--imagestyle {
      top: -9rem;
      ${devices.tablet} {
        top: -4rem;
      }
      ${devices.mobile} {
        position: absolute;
        top: -21.8rem;
      }
    }
    .bgCardblock--imagestyle.ui.image {
      max-width: none;
    }
    .cardtemplate3--bgImage__container {
      border-radius: 5rem;
      padding: 4rem;
      height: 30rem;
      ${devices.tablet} {
        height: 41rem;
      }
      ${devices.mobile} {
        height: auto;
      }
    }
    .cardtemplate3--container .cardtemplate3--bgImage__container .cardtemplate3-container {
      margin-top: 3rem;
      ${devices.mobile} {
        align-items: center;
      }
    }
    .bgCardblock .bgCardblock--icon1style {
      margin-right: 2.5rem;
      ${devices.tablet} {
        margin-right: 2.5rem;
      }
      ${devices.mobile} {
        margin-bottom: 2.5rem;
        margin-right: 0;
      }
    }
    .cardtemplate3--container .cardtemplate3--bgImage__container .bgCardblock--title_text_container {
      margin-bottom: 1.6rem;
    }
  }
`
