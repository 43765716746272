import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import importer from '@takedapdt/biolife-core/src/importer'
import { Helmet } from 'react-helmet'
const ContactUsConfirmation = importer('Components/ContactUsConfirmation')
const PublicContentActions = importer('Stores/PublicContent/Actions')
const { getArrayFromTextCTAGrid } = importer('Helpers/Utils', null)

const ContactUsConfirmationRoute = ({ fetchFormsData, FormsData }) => {
  const [finalData, setFinalData] = useState({})

  useEffect(() => {
    fetchFormsData('contact-us-confirmation')
  }, [])

  useEffect(() => {
    if (FormsData) {
      const dataWithTextCTAGrid = getArrayFromTextCTAGrid(FormsData)
      if (Object.keys(dataWithTextCTAGrid)?.length) {
        setFinalData(dataWithTextCTAGrid)
      }
    }
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })

  }, [FormsData])
 
  return (
    <>
      <Helmet>
        <title>{`${FormsData?.metadata?.meta_title}`} </title>
        <meta name='description' content={`${FormsData?.metadata?.meta_description}`} />
        <link rel='canonical' href={`${window.location.origin}${window.location.pathname.toLowerCase()}`} />
      </Helmet>
      <ContactUsConfirmation ContactUsConfirmationContent={finalData}></ContactUsConfirmation>
    </>
  )
}

const mapStateToProps = (state) => ({
  FormsData: state.publicContent?.getFormsPageSuccess?.['contact-us-confirmation']
})

const mapDispatchToProps = (dispatch) => ({
  fetchFormsData: (formType) => dispatch(PublicContentActions.getFormsPage(formType))
})

export default connect(mapStateToProps, mapDispatchToProps)(ContactUsConfirmationRoute)
