import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import importer from '@takedapdt/biolife-core/src/importer'
const PublicContentActions = importer('Stores/PublicContent/Actions')
const { getCssColorFromDrupalColorType } = importer('Constant/Utils', null)
import styled from 'styled-components'
const { devices } = importer('utils/styledComponentHelpers', null)
// use the field_poup_type to display the correct title and description
const LoyaltyAchievementPopUp = ({ donor, loyaltyAchievementBadges, getLoyaltyMilestones }) => {
  const GridContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-gap: 20px;
  `

  const AchievementContainer = styled.div`
    display: grid;
  `

  const GridRowAchievement = styled.div`
    display: grid;
    grid-template-columns: 35% 65%;
    grid-gap: 1rem;
    ${devices.mobile} {
      grid-template-columns: 25% 60%;
    }
    ${devices.tablet} {
      grid-template-columns: 35% 60%;
    }
  `

  const GridColumnImage = styled.div`
    width: 12rem;
    height: 12rem;
  `
  const StyledImage = styled.img`
    width: 100%;
    height: 100%;
  `
  const GridColumnText = styled.div`
    width: 100%;
    margin-left: 1rem;
  `

  const BadgeTitle = styled.div`
    font-weight: bold;
    font-size: 1.8rem;
    margin: 1rem 0;
  `

  const BadgeSubtitle = styled.div`
    font-size: 1.4rem;
  `

  useEffect(() => {
    if (donor) {
      getLoyaltyMilestones(donor?.defaultCenter?.sfid)
    }
  }, [donor])
  const { t, i18n } = useTranslation('', 'FactoryFunctionTexts')
  const AchievementBadge = ({ title, subtitle, iconUrl }) => (
    <AchievementContainer className='badgeDetailsContainer' verticalAlign='middle'>
      <GridRowAchievement>
        <GridColumnImage>
          <StyledImage src={iconUrl} alt={title} />
        </GridColumnImage>
        <GridColumnText>
          <BadgeTitle className='badgeTitle'>
            <strong>{title}</strong>
          </BadgeTitle>
          <BadgeSubtitle className='badgeSubtitle'>{subtitle}</BadgeSubtitle>
        </GridColumnText>
      </GridRowAchievement>
    </AchievementContainer>
  )
  return (
    <>
      <GridContainer>
        {loyaltyAchievementBadges
          ?.filter((item) => item.bll_active__c === true)
          ?.map((item) => (
            <AchievementBadge
              key={item.id}
              title={item.bll_achievement_badge_title__c}
              subtitle={item.bll_achievement_badge_sub_title__c}
              iconUrl={item.bll_badge_icon_url__c}
            />
          ))}
      </GridContainer>
    </>
  )
}

const mapStateToProps = (state) => ({
  donor: state.donors?.donors?.data,
  loyaltyAchievementBadges: state.publicContent?.getLoyaltyAchievementBadgesSuccess,
  loyaltyUserData: state.loyalty.getLoyaltyDetails?.data,
  loyaltyMilestones: state.publicContent?.getLoyaltyMilestonesSuccess,
  publicContent: state.publicContent
})

const mapDispatchToProps = (dispatch) => ({
  getLoyaltyMilestones: (sfid) => dispatch(PublicContentActions.getLoyaltyMilestones(sfid)),
  getDonors: (id) => dispatch(donorsActions.getDonors(id))
})

export default connect(mapStateToProps, mapDispatchToProps)(LoyaltyAchievementPopUp)
