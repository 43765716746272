import React from 'react'
import styled from 'styled-components'
import importer from '@takedapdt/biolife-core/src/importer'
const { devices } = importer('utils/styledComponentHelpers', null)
const { getCssColorFromDrupalColorType, setTextColor } = importer('Constant/Utils', null)


const StyledRowComponentCurve = styled.div`
  width: 100%;
  height: 100%;
  background: ${(props) => getCssColorFromDrupalColorType(props.$curveBgColor)};
  border-radius: 0 0 12rem 12rem;
`

const StyledRowComponent = styled.div`
  background: ${(props) => getCssColorFromDrupalColorType(props.$bgColor)};
  height: ${(props) => props.$rowHeightDesktop}px;
  ${devices.tablet} {
    height: ${(props) => props.$rowHeightTablet}px;
  }
  ${devices.mobile} {
    height: ${(props) => props.$rowHeightMobile}px;
  }
`

const Rows = (props) => {
  const {
    field_web_margin: rowHeightDesktop,
    field_tab_margin: rowHeightTablet,
    field_mobile_margin: rowHeightMobile,
    field_bg_color: rowBackgroundColor,
    field_bg_color_1: rowCurveBackgroundColor
  } = props
  return (
    <StyledRowComponent
      $rowHeightDesktop={rowHeightDesktop}
      $rowHeightTablet={rowHeightTablet}
      $rowHeightMobile={rowHeightMobile}
      $bgColor={rowBackgroundColor}
    >
      {rowCurveBackgroundColor && <StyledRowComponentCurve $curveBgColor={rowCurveBackgroundColor} />}
    </StyledRowComponent>
  )
}

export default Rows
