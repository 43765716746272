import React from 'react'
import styled, { css } from 'styled-components'
import importer from '@takedapdt/biolife-core/src/importer'
const { getCssColorFromDrupalColorType, setTextColor } = importer('Constant/Utils', null)
const { devices, sizes } = importer('utils/styledComponentHelpers', null)
const { sanitizeHtml } = importer('utils/htmlHelpers', null)

const alignmentStyles = {
  left: css`
    grid-column: 1 / 6;
  `,
  right: css`
    grid-column: 8 / -1;
  `
}

const StyledHeroTemplate4 = styled.div`
  display: flex;
  flex-direction: column;
  background: ${(props) => getCssColorFromDrupalColorType(props?.$bgColor)};
`

export const StyledHeroTemplate4Container = styled.div`
  display: grid;
  grid: auto / repeat(12, 1fr);
  gap: 2.4rem;
  padding: 0 var(--padding-horizontal-desktop);
  padding-right: 0;
  margin: auto;
  max-width: ${sizes.tablet};
  ${devices.tablet} {
    grid: auto / repeat(8, 1fr);
    padding: 0 var(--padding-horizontal-tablet);
  }
  ${devices.mobile} {
    grid: auto / repeat(6, 1fr);
    padding: 0 var(--padding-horizontal-mobile);
  }
`

export const StyledHeroTemplate4MainText = styled.div`
  grid-row: 1;
  align-self: center;
  justify-self: start;
  ${(props) => setTextColor(props?.$textColor)};
  ${(props) => alignmentStyles[props?.$fieldAlignment === 1 ? 'right' : 'left']}
  ${devices.tablet} {
    grid-row: auto;
    grid-column: 1 / -1;
  }
`

const StyledHeroTemplate4Image = styled.img`
  grid-row: 1;
  object-fit: contain;
  width: 100%;
  height: auto;
  transform: scale(1.2);
  padding-right: 4rem;
  position: relative;
  top: -4.5rem;

  ${(props) => alignmentStyles[props?.$fieldAlignment === 1 ? 'left' : 'right']}
  ${devices.tablet} {
    grid-row: auto;
    grid-column: 1 / -1;
    transform: scale(1);
    transform: translateX(-48px);
    padding-top: 2.4rem;
    padding-bottom: 2.4rem;
    padding-right: 0;
    top: 0;
  }
  ${devices.mobile} {
    transform: scale(1);
  }
`

const StyledHeroTemplate4Text = styled.div`
  grid-column: 1 / 8;
  ${(props) => setTextColor(props?.$textColor)};
  ${devices.tablet} {
    grid-column: 1 / -1;
  }
`

const HeroTemplate4 = (props) => {
  return (
    <>
      <StyledHeroTemplate4 className='hero-template-4' $bgColor={props?.field_bg_color}>
        <StyledHeroTemplate4Container className='hero-template-4__container'>
          <StyledHeroTemplate4MainText
            className='hero-template-4--main-text'
            $textColor={props?.field_title_color}
            $fieldAlignment={props?.field_alignment}
            dangerouslySetInnerHTML={sanitizeHtml(props?.field_component_title?.processed)}
          />
          <StyledHeroTemplate4Image
            className='hero-template-4--image'
            src={props?.field_component_asset?.url}
            alt={props?.field_component_asset?.alt}
            $fieldAlignment={props?.field_alignment}
          />
          <StyledHeroTemplate4Text
            className='hero-template-4--short-desc-text'
            $textColor={props?.field_short_desc_color}
            dangerouslySetInnerHTML={sanitizeHtml(props?.field_short_description?.processed)}
          />
          <StyledHeroTemplate4Text
            className='hero-template-4--long-desc-text'
            $textColor={props?.field_long_desc_color}
            dangerouslySetInnerHTML={sanitizeHtml(props?.field_component_desc?.processed)}
          />
        </StyledHeroTemplate4Container>
      </StyledHeroTemplate4>
    </>
  )
}

export default HeroTemplate4
