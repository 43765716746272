import React, { useEffect, useRef } from 'react'
import Slider from 'react-slick'
import importer from 'common/importer'
import { connect } from 'react-redux'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
const SecondTestimonialCard = importer('Components/SecondSliderTestimonialTemplate/SecondTestimonialCard')
const PublicContentActions = importer('Stores/PublicContent/Actions')
const { getCssColorFromDrupalColorType, setTextColor } = importer('Constant/Utils', null)
import { Grid } from 'semantic-ui-react'
import { ReactComponent as NextArrow } from '../../../assets/media/icons/circle-arrow-right.svg'
import { ReactComponent as PrevArrow } from '../../../assets/media/icons/circle-arrow-left.svg'
import './styles.scss'
const { useScreenSize } = importer('Contexts/ResponsiveContextProvider', null)

const SliderNextArrow = ({ className, onClick }) => {
  return <NextArrow width='100' height='100' className={className} onClick={onClick} />
}

const SliderPrevArrow = ({ className, onClick }) => {
  return <PrevArrow width='50' height='50' className={className} onClick={onClick} />
}

const SecondSliderTestimonialTemplate = ({
  field_bg_color: bgColor,
  getPatientTestimonials,
  patientTestimonials,
  field_ref_slug_value: slug,
  formsData
}) => {
  const { isMobileWidth, isTabletWidth } = useScreenSize()
  const slider = useRef(null)

  useEffect(() => {
    getPatientTestimonials(slug)
  }, [slug, formsData])

  useEffect(() => {
    if (slider.current) {
      slider.current.slickGoTo(0)
    }
  }, [patientTestimonials])

  var settings = {
    dots: true,
    intialSlide: 1,
    infinite: true,
    speed: 800,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: isMobileWidth || isTabletWidth ? false : true,
    nextArrow: <SliderNextArrow />,
    prevArrow: <SliderPrevArrow />
  }
  return (
    <>
      <Grid
        textAlign='center'
        className='SecondTestimonialsSliderContainer'
        style={{ background: getCssColorFromDrupalColorType(bgColor) }}
      >
        <Grid.Row>
          <Grid.Column>
            <Slider ref={slider} className='customSlider' {...settings}>
              {patientTestimonials?.map((item) => (
                <SecondTestimonialCard
                  field_component_title_1={item.field_component_title_1}
                  field_component_title_2={item.field_component_title_2}
                  field_short_desc_color={item.field_short_desc_color}
                  field_short_description={item.field_short_description}
                  field_cta_1={item.field_cta_1}
                  field_cta_1_style={item.field_cta_1_style}
                />
              ))}
            </Slider>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  )
}

const mapStateToProps = (state) => ({
  patientTestimonials: state.publicContent?.getPatientTestimonialsSuccess?.contents,
  formsData: state.publicContent?.getFormsPageSuccess
})

const mapDispatchToProps = (dispatch) => ({
  getPatientTestimonials: (refType) => dispatch(PublicContentActions.getPatientTestimonials(refType))
})

export default connect(mapStateToProps, mapDispatchToProps)(SecondSliderTestimonialTemplate)
