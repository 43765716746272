import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
const LoyaltyDonorActions = importer('Stores/Loyalty/Actions')
import importer from '@takedapdt/biolife-core/src/importer'
const Accordion = importer('Components/Accordion')
const { getCssColorFromDrupalColorType, setTextColor } = importer('Constant/Utils', null)
import { formatDate, formatDateByMonth } from '../../Helpers/Utils'
import './style.scss'
import moment from 'moment'
const ItemContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  grid-column-gap: 1rem;
  padding: 3rem 0 0 0;
  border-top: 0.5px solid #ccc;
  margin-top: 1.6rem;
`

const ItemDetails = styled.div`
  display: flex;
  flex-direction: column;
`
const ItemActivityDate = styled.div`
  font-size: 1.6rem;
  padding-bottom: 0.8rem;
  font-weight: bolder !important;
`
const ItemExpiryDate = styled.div`
  font-size: 1.6rem;
  padding-bottom: 0.8rem;
  font-style: italic;
  font-weight: 100 !important;
`
const ItemPoints = styled.strong`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.6rem;
`

const ItemDescription = styled.strong`
  font-size: 1.8rem;
  padding-bottom: 0.8rem;
`

const RewardsHistory = (props) => {
  const { loyaltyActivity, donor, getLoyaltyActivity, field_component_title, field_title_color } = props
  const [rewardsHistoryData, setRewardsHistoryData] = useState()
  const { t, i18n } = useTranslation('RewardsHistory')
  useEffect(() => {
    if (donor?.pdn && donor?.isLoyaltyMember === 'Yes') {
      getLoyaltyActivity(donor?.pdn)
    }
  }, [donor])
  useEffect(() => {
    const transformedRewardsHistoryData = new Map()
    loyaltyActivity?.getLoyaltyActivity?.transactions?.map((item) => {
      const date = moment(item.activityDate).format('MMMM YYYY')
      if (transformedRewardsHistoryData.has(date)) {
        transformedRewardsHistoryData.get(date).push({
          ...item,
          activityType: i18n.language === 'es-MX' ? item.activityTypeSpanish : item.activityType,
          expiryText: i18n.language === 'es-MX' ? item.expiryTextSpanish : item.expiryText,
          transType: i18n.language === 'es-MX' ? item.transTypeSpanish : item.transType,
          transDesc: i18n.language === 'es-MX' ? item.transDescSpanish : item.transDesc
        })
      } else {
        transformedRewardsHistoryData.set(date, [
          {
            ...item,
            expiryText: i18n.language === 'es-MX' ? item.expiryTextSpanish : item.expiryText,
            transType: i18n.language === 'es-MX' ? item.transTypeSpanish : item.transType,
            transDesc: i18n.language === 'es-MX' ? item.transDescSpanish : item.transDesc,
            activityType: i18n.language === 'es-MX' ? item.activityTypeSpanish : item.activityType
          }
        ])
      }
    })
    setRewardsHistoryData(transformedRewardsHistoryData)
  }, [loyaltyActivity, i18n.language])
  return (
    <div className='main-container rewards-history-container'>
      <span
        style={{ ...setTextColor(field_title_color), padding: '2rem 3rem' }}
        dangerouslySetInnerHTML={{ __html: field_component_title?.value }}
      />
      <>
        {rewardsHistoryData &&
          Array.from(rewardsHistoryData).map((item) => (
            <Accordion title={item[0]} field_title_color={field_title_color} description={<RenderList description={item[1]} />} />
          ))}
      </>
    </div>
  )
}
const RenderList = ({ description }) => {
  return (
    <>
      {description?.map((item) => (
        <ItemContainer>
          <ItemDetails>
            <ItemDescription>{item.transDesc}</ItemDescription>
            {item.activityType && (
              <ItemActivityDate>
                {item.activityType} {moment(item.activityDate).format('MMMM DD, YYYY')}
              </ItemActivityDate>
            )}
            {item.expiryDate && (
              <ItemExpiryDate>
                {item.expiryText} {moment(item.expiryDate).format('MMMM DD, YYYY')}
              </ItemExpiryDate>
            )}
          </ItemDetails>
          <ItemPoints>
            {item.transOperation === 'Debit' ? '-' : '+'}{item.points} Points
          </ItemPoints>
        </ItemContainer>
      ))}
    </>
  )
}

const mapStateToProps = (state) => ({
  loyaltyActivity: state?.loyalty,
  donor: state?.donors?.donors?.data
})

const mapDispatchToProps = (dispatch) => ({
  getLoyaltyActivity: (pdn) => dispatch(LoyaltyDonorActions.getLoyaltyActivity(pdn))
})

export default connect(mapStateToProps, mapDispatchToProps)(RewardsHistory)
