import React from 'react'
import styled from 'styled-components'
import importer from '@takedapdt/biolife-core/src/importer'
const PrimaryButton = importer('Components/Buttons/PrimaryButton')
const { useScreenSize } = importer('Contexts/ResponsiveContextProvider', null)
const { getCssColorFromDrupalColorType, setTextColor } = importer('Constant/Utils', null)
const { sanitizeHtml } = importer('utils/htmlHelpers', null)
const { devices, sizes } = importer('utils/styledComponentHelpers', null)

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 85px 64px;
  text-align: center;
  ${devices.mobile} {
    padding: 170px 32px 48px 32px;
    align-items: start;
  }
  .enrolmentSuccessButton {
    width: 409px;
  }
`

const Title = styled.div`
  ${(props) => setTextColor(props.$sectionTitleColor)};
  margin-bottom: 16px;
`

const HeadingLogo = styled.div`
  margin-bottom: 32px;
`
const Image = styled.img`
  ${devices.mobile} {
    width: 27rem;
  }
`
const Description = styled.div`
  ${devices.mobile} {
    text-align: left;
  }
  ${(props) => setTextColor(props.$sectionDescriptionColor)};
  margin-bottom: 42px;
`

const LoyaltyEnrolmentSuccessModal = ({ modalContent }) => {
  const {
    field_component_title: sectionTitle,
    field_title_color: sectionTitleColor,
    field_component_desc: sectionDescription,
    field_long_desc_color: sectionDescriptionColor,
    field_checkbox_content: sectionCheckboxContent,
    field_cta_1: sectionCTA1,
    field_cta_1_style: sectionCTA1Style,
    field_column_1_icon: column1Icon
  } = modalContent
  const { isMobileWidth } = useScreenSize()
  return (
    <ContentWrapper>
      <Title $sectionTitleColor={sectionTitleColor} dangerouslySetInnerHTML={sanitizeHtml(sectionTitle?.processed)} />
      <HeadingLogo>
        <Image src={column1Icon?.url} alt={column1Icon?.alt} />
      </HeadingLogo>
      <Description
        $sectionDescriptionColor={sectionDescriptionColor}
        dangerouslySetInnerHTML={sanitizeHtml(sectionDescription?.processed)}
      />
      <PrimaryButton
        className='enrolmentSuccessButton'
        text={sectionCTA1?.title}
        variant={sectionCTA1Style}
        sendTo={sectionCTA1?.uri}
        fluid={isMobileWidth}
      />
    </ContentWrapper>
  )
}

export default LoyaltyEnrolmentSuccessModal
