import React from 'react'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { Container, /* CardContainer, */ TextComponent, Highlight4, ContainerWithFlex } from './styles/FeaturedCard.styled'
import BlogTimer from '../../../assets/media/icons/BlogTimer.svg'
import importer from '@takedapdt/biolife-core/src/importer'
const { getCssColorFromDrupalColorType } = importer('Constant/Utils', null)
const { sanitizeHtml } = importer('utils/htmlHelpers', null)
const PrimaryButton = importer('Components/Buttons/PrimaryButton')
const { useScreenSize } = importer('Contexts/ResponsiveContextProvider', null)

function FeaturedArticleCard({ featuredBlog }) {
  const { isMobileWidth, isTabletWidth } = useScreenSize()
  const { t } = useTranslation('BlogsContainer')
  const { field_read_time: readTime = '', title = '', field_slug_value: goToLink = '', changed: latestChangedate = '' } = featuredBlog || {}
  return (
    // handle bg Image with below div
    <Container
      padding={isTabletWidth ? '4.2rem 0px 5.5rem 0px' : isMobileWidth ? '0' : '5.4rem 0px 5.4rem 0px'}
      minHeight={isMobileWidth ? '44rem' : 'auto'}
      marginBottom={isMobileWidth ? '24rem' : '0rem'}
      // bgColor='white'
    >
      {/* Commenting Bgcolor for now */}
      <div className='main-container'>
        <Container position={isMobileWidth ? 'relative' : 'static'}>
          <ContainerWithFlex
            bgColor='white'
            direction='column'
            padding={isTabletWidth ? '4rem 4rem 4rem 4rem' : isMobileWidth ? '2.4rem 2.4rem 2.4rem 2.4rem' : '4rem 4rem 4rem 4rem'}
            maxWidth={isTabletWidth ? '340px' : isMobileWidth ? 'auto' : '490px'}
            borderRadius='25px'
            position={isMobileWidth ? 'absolute' : 'static'}
            bottom={isMobileWidth ? '-68rem' : 'auto'}
            boxShadow={`0px 3px 6px ${getCssColorFromDrupalColorType('cardShadow')}`}
          >
            <TextComponent color='orange' marginBottom='1.6rem'>
              <span className='caption-text'>
                <strong>{t('featuredArticle')}</strong>
              </span>
            </TextComponent>
            <Highlight4 color='bl_blue' marginBottom='2.4rem' wordBreak='break-word'>
              {title && title?.length > 55 ? `${title.substring(0, 55)}...` : title}
            </Highlight4>
            <ContainerWithFlex display={{ desktop: 'flex', tablet: 'flex', mobile: 'flex' }}>
              <TextComponent
                paddingRight='1.6rem'
                borderRight={readTime ? `1px solid ${getCssColorFromDrupalColorType('rich_black')}` : `0px`}
              >
                <span className='caption-text'>
                  {moment(latestChangedate).format('MMMM D')}, {moment(latestChangedate).format('YYYY')}
                </span>
              </TextComponent>
              {readTime && (
                <ContainerWithFlex align='center' padding='0rem 0rem 0rem 1.6rem'>
                  <img src={BlogTimer} style={{ height: '19px', width: '16px', marginRight: '0.8rem' }} />
                  <TextComponent fontFamily='AvenirNextRegular'>
                    <span className='caption-text'>
                      {readTime} {t('min')}
                    </span>
                  </TextComponent>
                </ContainerWithFlex>
              )}
            </ContainerWithFlex>
            <div style={{ marginTop: '3rem' }}>
              <PrimaryButton
                variant='outline_orange'
                text={t('readArticle')}
                style={{ backgroundColor: 'transparent' }}
                sendTo={`internal:/${goToLink}`}
              />
            </div>
          </ContainerWithFlex>
        </Container>
      </div>
    </Container>
  )
}

export default FeaturedArticleCard
