import React from 'react'
import { Grid } from 'semantic-ui-react'
import { useNavigate } from 'react-router-dom'
import './styles.scss'
import importer from 'common/importer'
const PrimaryButton = importer('Components/Buttons/PrimaryButton')
const { getMediaFromDrupalMimeType, getCssColorFromDrupalColorType, setTextColor } = importer('Constant/Utils', null)
const { useScreenSize } = importer('Contexts/ResponsiveContextProvider', null)

const CompensationAdvertisement = (props) => {
  const {
    field_alignment,
    field_bg_color,
    field_component_title,
    field_title_color,
    field_short_description,
    field_short_desc_color,
    field_component_asset,
    field_column_1_title,
    field_column_1_desc,
    field_column_1_icon,
    field_column_2_title,
    field_column_2_desc,
    field_column_2_icon,
    field_column_3_title,
    field_column_3_desc,
    field_column_3_icon,
    field_column_title_color,
    field_column_desc_color,
    field_cta_1,
    field_cta_1_style,
    field_asset_link
  } = props
  
  const navigate = useNavigate()
  // field_alignment 1 represents left alignment, 2 represents right
  const { isMobileWidth, isTabletWidth } = useScreenSize()
  return (
    <Grid
      divided='vertically'
      verticalAlign={isTabletWidth ? 'top' : 'middle'}
      style={{ background: getCssColorFromDrupalColorType(field_bg_color) }}
      className='CompensationAdvertisementContainer'
    >
      <Grid.Row columns={2} className={`${field_alignment === 2 ? 'Reversed' : ''}`}>
        <Grid.Column computer={6} mobile={16} tablet={6}>
          {field_asset_link && field_asset_link?.uri !== 'route:<nolink>' ? (
            <a
              href={field_asset_link?.uri?.includes('internal:') ? field_asset_link?.uri?.split(':')[1] : field_asset_link?.uri}
              onClick={(e) => {
                e.preventDefault();
                if (field_asset_link?.uri?.includes('internal:')) {
                  navigate(field_asset_link?.uri?.split(':')[1])
                } else {
                  window.open(field_asset_link?.uri, '_blank')
                }
              }}
            >
              {getMediaFromDrupalMimeType(field_component_asset?.mimetype, field_component_asset?.url, field_component_asset?.alt)}
            </a>
          ) : (
            getMediaFromDrupalMimeType(field_component_asset?.mimetype, field_component_asset?.url, field_component_asset?.alt)
          )}
        </Grid.Column>
        <Grid.Column computer={10} mobile={16} tablet={10} className='CompensationAdvertisementVerbiageContainer'>
          <div
            dangerouslySetInnerHTML={{ __html: field_component_title?.processed }}
            style={{ ...setTextColor(field_title_color) }}
            className='CompensationAdvertisementTitle'
          />
          <div
            dangerouslySetInnerHTML={{ __html: field_short_description?.processed }}
            style={{ ...setTextColor(field_short_desc_color) }}
            className='CompensationAdvertisementDescription'
          />
          <Grid verticalAlign='middle' className='CompensationAdvertisementItemsContainer'>
            <Grid.Row columns={2}>
              <Grid.Column verticalAlign='middle' computer={4} mobile={16} tablet={5}>
                {getMediaFromDrupalMimeType(field_column_1_icon?.mimetype, field_column_1_icon?.url, field_column_1_icon?.alt)}
              </Grid.Column>
              <Grid.Column verticalAlign='middle' computer={12} mobile={16} tablet={11}>
                <div
                  dangerouslySetInnerHTML={{ __html: field_column_1_title?.processed }}
                  style={{ ...setTextColor(field_column_title_color) }}
                  className='CompensationAdvertisementItemTitle'
                />
                <div
                  dangerouslySetInnerHTML={{ __html: field_column_1_desc?.processed }}
                  style={{ ...setTextColor(field_column_desc_color) }}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={2}>
              <Grid.Column verticalAlign='middle' computer={4} mobile={16} tablet={5}>
                {getMediaFromDrupalMimeType(field_column_2_icon?.mimetype, field_column_2_icon?.url, field_column_2_icon?.alt)}
              </Grid.Column>
              <Grid.Column verticalAlign='middle' computer={12} mobile={16} tablet={11}>
                <div
                  dangerouslySetInnerHTML={{ __html: field_column_2_title?.processed }}
                  style={{ ...setTextColor(field_column_title_color) }}
                  className='CompensationAdvertisementItemTitle'
                />
                <div
                  dangerouslySetInnerHTML={{ __html: field_column_2_desc?.processed }}
                  style={{ ...setTextColor(field_column_desc_color) }}
                />
              </Grid.Column>
            </Grid.Row>
            {field_column_3_icon?.mimetype ? (
              <Grid.Row columns={2}>
                <Grid.Column verticalAlign='middle' computer={4} mobile={16} tablet={5}>
                  {getMediaFromDrupalMimeType(field_column_3_icon?.mimetype, field_column_3_icon?.url, field_column_3_icon?.alt)}
                </Grid.Column>
                <Grid.Column verticalAlign='middle' computer={12} mobile={16} tablet={11}>
                  <div
                    className='CompensationAdvertisementItemTitle'
                    dangerouslySetInnerHTML={{ __html: field_column_3_title?.processed }}
                    style={{ ...setTextColor(field_column_title_color) }}
                  />
                  <div
                    dangerouslySetInnerHTML={{ __html: field_column_3_desc?.processed }}
                    style={{ ...setTextColor(field_column_desc_color) }}
                  />
                </Grid.Column>
              </Grid.Row>
            ) : null}
            <PrimaryButton text={field_cta_1?.title} variant={field_cta_1_style} sendTo={field_cta_1?.uri} fluid={isMobileWidth} />
          </Grid>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}

export default CompensationAdvertisement
