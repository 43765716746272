import React, { useEffect, useRef } from 'react'
import './styles.scss'
import renderDrupalComponent from '../../Constant/renderDrupalComponent'

const ContactUs = (props) => {
  const contactusref = useRef();

  useEffect(() => {
    const handleDocumentClick = (event) => {
      if (event.target.id === 'contact-us-scroll') {
        const contactUsFormref = document.getElementById('ContactUsForm')
        const firstInput = contactUsFormref.querySelector('input:first-child')
        const elementRect = contactUsFormref.getBoundingClientRect()
        const elementTop = elementRect.top + window.scrollY - 100

        window.scrollTo({ top: elementTop, behavior: 'smooth' })
        if (firstInput) {
          setTimeout(() => {
            firstInput.focus()
          }, 1000)
        }
      }
    }

    document.addEventListener('click', handleDocumentClick)

    // Cleanup function to remove event listener when component unmounts
    return () => {
      document.removeEventListener('click', handleDocumentClick)
    }
  }, [])

  return (
    <div ref={contactusref} className='ContactUsContainer'>
      {props?.ContactUsContent?.contents?.map((component, index) => {
        if (component.type === 'breadcrumb') {
          let tempComp = { ...component, breadcrumbToggle: props?.ContactUsContent?.metadata?.field_content_breadcrumb }
          component = tempComp
        }
        return renderDrupalComponent(component, index)
      })}
    </div>
  )
}

export default ContactUs
