import React from 'react'
import { Loader, Dimmer } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'

const LoaderComponent = () => {
  const { t } = useTranslation('Loader')
  return (
    <Dimmer active inverted>
      <Loader>{t('loading')}</Loader>
    </Dimmer>
  )
}

export default LoaderComponent
