/**
 *
 * @param {String} title `REQUIRED`
 * @param {String} msg `REQUIRED`
 */
const alert = (title, msg) => {
  const message = `
    ${title}\n
    ${msg}
    `
  window.alert(message)
}
/**
 *
 * @param {String} title `REQUIRED`
 * @param {String} msg `REQUIRED`
 * @param {Object} options `REQUIRED`
 * @param {Object} options.accept  `REQUIRED`
 * @param {String} options.accept.text  `REQUIRED`
 * @param {Function} options.accept.callback  `REQUIRED`
 * @param {Object} options.reject  `REQUIRED`
 * @param {String} options.reject.text  `REQUIRED`
 * @param {Function} options.reject.callback  `REQUIRED`
 */
export const twoButtonAlert = (
  title,
  msg,
  options = {
    accept: {
      text: 'Confirm',
      callback: () => {}
    },
    reject: {
      text: 'Cancel',
      callback: () => {}
    }
  }
) => {
  const message = `
    ${title}\n
    ${msg}
    `
  var r = window.confirm(message)
  if (r === true) {
    return options.accept.callback()
  } else {
    return options.reject.callback()
  }
}

export default alert
