import React from 'react'
import { Route, Navigate } from 'react-router-dom'
import RouteWrapper from '../Helpers/RouteWrapper'

const RoutePrivate = ({ component: Component, isAuthenticated, to = '/login', ...rest }) => (
  <Route
    {...rest}
    element={
      isAuthenticated ? (
        <RouteWrapper component={Component} {...props} />
      ) : (
        <Navigate to={to} state={{ redirect: window.location.pathname, isAuthenticated }} replace />
      )
    }
  />
)

export default RoutePrivate
