import { getContext } from 'redux-saga/effects'

import importer from '@takedapdt/biolife-core/src/importer'

const NavigationService = importer('NavigationService')

/**
 * SIGN_IN_COMPLETED
 */
function * signInCompleted () {
  // console.log('signInCompleted')

  const firebase = yield getContext('firebase')
  if (firebase.apps.length === 1) {
    firebase.analytics().logEvent('sign_in_completed')
  }

  yield NavigationService.navigate(NavigationService.ROUTES.Dashboard)
}

export default signInCompleted
