import React, { useEffect } from 'react'
import './styles.scss'
import importer from 'common/importer'
import { connect } from 'react-redux'
import { getOperationHours } from '../../Helpers/Utils'
import GoogleMapReact from 'google-map-react'
import { Link } from 'react-router-dom'
import { ReactComponent as LocationIcon } from '../../../assets/media/icons/icon-location-new-orange.svg'
import { ReactComponent as MapPinIcon } from '../../../assets/media/icons/map-pin-icon-orange.svg'
import { ReactComponent as PhoneIcon } from '../../../assets/media/icons/icon-phone-in-talk-orange.svg'
const { formatPhoneNumber } = importer('Helpers/Utils', null)
const { useScreenSize } = importer('Contexts/ResponsiveContextProvider', null)
const PrimaryButton = importer('Components/Buttons/PrimaryButton')
const { setTextColor } = importer('Constant/Utils', null)

const Config = importer('Config')
import { useTranslation } from 'react-i18next'
const MapMarker = ({ text }) => (
  <div style={{ textAlign: 'center' }}>
    <MapPinIcon width='35' height='35' />
  </div>
)

const CenterNameWithMap = ({ centerInfo, field_component_title: componentTitle, field_title_color: componentTitleColor }) => {
  const { isMobileWidth } = useScreenSize()
  const { t } = useTranslation('Locations')
  const weekday = [t('sunday'), t('monday'), t('tuesday'), t('wednesday'), t('thursday'), t('friday'), t('saturday')]
  const today = weekday[new Date().getDay()]
  useEffect(() => {
    let el1 = document.getElementById('todayDayElement')
    let el2 = document.getElementById('todayHourElement')
    if (el1 && el2) {
      el1.innerHTML = `<strong>${el1?.textContent}</strong>`
      el2.innerHTML = `<strong>${el2?.textContent}</strong>`
    }
  }, [])
  const openMap = () => {
    return window.open(`http://www.google.com/maps/place/${centerInfo?.latitude},${centerInfo?.longitude}`)
  }
  return (
    <div className='center-name-with-map-container'>
      <div className='center-details'>
      <div
        id='center-title'
        style={{ ...setTextColor(componentTitleColor)}}
        dangerouslySetInnerHTML={{ __html: componentTitle?.processed }}
      />
        <div className='center-address'>
          <LocationIcon className='LocationIcon' />
          <span>
            <span>{centerInfo?.addressLine1}</span>
            {centerInfo?.addressLine2 && <p>{centerInfo?.addressLine2}</p>}
            {
              <p>
                {centerInfo?.city && <span>{centerInfo?.city + ', '}</span>}
                {centerInfo?.state && <span>{centerInfo?.state + ' '}</span>}
                {centerInfo?.zipcode && <span>{centerInfo?.zipcode}</span>}
              </p>
            }
          </span>
        </div>
        <div className='center-get-direction'>
          <PrimaryButton text={t('getDirections')} variant={'blue_underline'} onClick={() => openMap()} />
        </div>
        <div className='center-phone'>
          <PhoneIcon className='PhoneIcon' />
          <a href={`tel:${formatPhoneNumber(centerInfo?.telephone)}`}>{formatPhoneNumber(centerInfo?.telephone)}</a>
          {centerInfo?.isSpanishSupported ? <span className='is-spanish-center'>{t('habloEspanol')}</span> : null}
        </div>
        <Link to='/registration'>
          <PrimaryButton text={t('donateNow')} fluid={isMobileWidth} />
        </Link>
      </div>

      {isMobileWidth && (
        <table className='center-hours-container'>
          <tbody>
            {centerInfo?.hoursOfOperation.map((item) => (
              <tr style={{ color: today === item.weekDayName && '#eb6f24' }}>
                <td>
                  <p id={today === item.weekDayName && 'todayDayElement'}>{item.weekDayName}</p>
                </td>
                <td className='times-styles'>
                  <p id={today === item.weekDayName && 'todayHourElement'}>{getOperationHours(item)}</p>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}

      <div className='center-map-container'>
        <GoogleMapReact
          bootstrapURLKeys={{ key: Config.GOOGLE_MAP_API_KEY }}
          center={{
            lat: centerInfo?.latitude,
            lng: centerInfo?.longitude
          }}
          defaultZoom={14}
        >
          <MapMarker key={centerInfo?.id} lat={centerInfo?.latitude} lng={centerInfo?.longitude} text='BioLife Plasma Services' />
        </GoogleMapReact>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  centerInfo: state.centers?.getCenterInfoSuccess?.data
})

export default connect(mapStateToProps, null)(CenterNameWithMap)
