import React from 'react'
import './styles.scss'
import { Grid } from 'semantic-ui-react'
import importer from '@takedapdt/biolife-core/src/importer'
import { useNavigate } from 'react-router-dom'

const { useScreenSize } = importer('Contexts/ResponsiveContextProvider', null)
const { getCssColorFromDrupalColorType, setTextColor, getMediaFromDrupalMimeType } = importer('Constant/Utils', null)
const { convertTableToList } = importer('Helpers/Utils', null)

const TextMediaTemplate4 = (props) => {
  const {
    field_component_title: activateDebitCardComponentTitle,
    field_title_color: activateDebitCardComponentTitleColor,
    field_component_asset: activateDebitCardComponentAssest,
    field_alignment_2: activateDebitCardComponentAlignment2,
    field_bg_color: activateDebitCardComponentBGColor,
    field_component_desc: activateDebitCardComponentDescription,
    field_long_desc_color: activateDebitCardComponentLongDescriptionColor,
    field_column_1_icon: activateDebitCardComponentColumnOneIcon,
    field_component_title_1: activateDebitCardComponentColumnOneTitle,
    field_asset_link: activateDebitCardComponentAssetLink
  } = props
  const { isMobileWidth } = useScreenSize()

  const updatedContent = convertTableToList(activateDebitCardComponentDescription?.value)
  const navigate = useNavigate()

  const handleAssetLinkRedirection = (assetLink) => {
    if (!assetLink) {
      return
    }
    if (assetLink?.includes('internal:')) {
      // internal redirection
      navigate(assetLink.split(':')[1])
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    } else {
      // opens a new tab
      window.open(assetLink, '_blank')
    }
  }

  return (
    <div
      className='activate-debit-card'
      style={{
        background: `${getCssColorFromDrupalColorType(activateDebitCardComponentBGColor)}`
      }}
    >
      <Grid className={`activate-debit-card__container activate-debit-card__container${
              !activateDebitCardComponentAssest?.mimetype
                ? '--padding'
                : activateDebitCardComponentAlignment2 === 1
                ? '--padding--right'
                : '--padding--left'
            }`}>
        {isMobileWidth ? (
          <Grid.Row columns={2} className='activate-debit-card__grid'>
            <Grid.Column mobile={16} tablet={8} className='activate-debit-card__text'>
              {activateDebitCardComponentColumnOneTitle && (
                <div className='activate-debit-card__stepper'>
                  <h5 className='activate-debit-card__stepper--title'>{activateDebitCardComponentColumnOneTitle}</h5>
                  <div className='activate-debit-card__stepper--icon'>
                    {getMediaFromDrupalMimeType(
                      activateDebitCardComponentColumnOneIcon?.mimetype,
                      activateDebitCardComponentColumnOneIcon?.url,
                      activateDebitCardComponentColumnOneIcon?.alt
                    )}
                  </div>
                </div>
              )}
              {activateDebitCardComponentTitle && (
                <div
                  className='activate-debit-card__title'
                  style={{ ...setTextColor(activateDebitCardComponentTitleColor) }}
                  dangerouslySetInnerHTML={{ __html: activateDebitCardComponentTitle?.value }}
                />
              )}
              <div
                style={{ ...setTextColor(activateDebitCardComponentLongDescriptionColor) }}
                className='activate-debit-card__description'
                dangerouslySetInnerHTML={{ __html: updatedContent }}
              />
            </Grid.Column>

            {activateDebitCardComponentAssest?.mimetype && (
              <Grid.Column
                tablet={8}
                mobile={16}
                className={`activate-debit-card__image${activateDebitCardComponentAlignment2 === 1 ? '--left' : '--right'}`}
                verticalAlign='top'
              >
                <div
                  style={{
                    cursor: activateDebitCardComponentAssetLink?.uri ? 'pointer' : 'default'
                  }}
                  onClick={() => {
                    handleAssetLinkRedirection(activateDebitCardComponentAssetLink?.uri)
                  }}
                >
                  {getMediaFromDrupalMimeType(activateDebitCardComponentAssest?.mimetype, activateDebitCardComponentAssest?.url, activateDebitCardComponentAssest?.alt)}
                </div>
              </Grid.Column>
            )}
          </Grid.Row>
        ) : (
          <Grid.Row
            className={`activate-debit-card__grid${
              !activateDebitCardComponentAssest?.mimetype
                ? '--centered'
                : activateDebitCardComponentAlignment2 === 1
                ? '--reversed'
                : '--normal'
            }`}
          >
            <Grid.Column computer={activateDebitCardComponentAssest?.mimetype ? 8 : 16} className='activate-debit-card__text'>
              {activateDebitCardComponentColumnOneIcon && (
                <div className='activate-debit-card__stepper'>
                  <h5 className='activate-debit-card__stepper--title'>{activateDebitCardComponentColumnOneTitle}</h5>
                  <div className='activate-debit-card__stepper--icon'>
                    {getMediaFromDrupalMimeType(
                      activateDebitCardComponentColumnOneIcon?.mimetype,
                      activateDebitCardComponentColumnOneIcon?.url,
                      activateDebitCardComponentColumnOneIcon?.alt
                    )}
                  </div>
                </div>
              )}
              {activateDebitCardComponentTitle && (
                <div
                  className='activate-debit-card__title'
                  style={{ ...setTextColor(activateDebitCardComponentTitleColor) }}
                  dangerouslySetInnerHTML={{ __html: activateDebitCardComponentTitle?.value }}
                />
              )}
              <div
                style={{ ...setTextColor(activateDebitCardComponentLongDescriptionColor) }}
                className='activate-debit-card__description'
                dangerouslySetInnerHTML={{ __html: activateDebitCardComponentDescription?.value }}
              />
            </Grid.Column>

            <Grid.Column computer={8} className='activate-debit-card__image'>
              <div
                style={{
                  cursor: activateDebitCardComponentAssetLink?.uri ? 'pointer' : 'default'
                }}
                onClick={() => {
                  handleAssetLinkRedirection(activateDebitCardComponentAssetLink?.uri)
                }}
              >
                {getMediaFromDrupalMimeType(activateDebitCardComponentAssest?.mimetype, activateDebitCardComponentAssest?.url, activateDebitCardComponentAssest?.alt)}
              </div>
            </Grid.Column>
          </Grid.Row>
        )}
      </Grid>
    </div>
  )
}

export default TextMediaTemplate4
