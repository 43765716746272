import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { devices } from '../../../../src/utils/styledComponentHelpers'
import PrimaryButton from '../../Buttons/PrimaryButton'
import { useScreenSize } from '../../../Contexts/ResponsiveContextProvider'
import { setTextColor } from '../../../../src/Constant/Utils'

const TitleContainer = styled.div`
  margin: 5px;
`
const MessagesSectionContainer = styled.div`
  width: 100%;
  height: 100%;
`

const MessagesContainer = styled.div`
  margin-top: ${({ hasMessages }) => (hasMessages ? '80px' : '50px')};
  font-family: $font-family;
  background-color: $white;
  padding: ${({ hasMessages }) => (hasMessages ? '32px 0' : '0')};
  border-top: ${({ hasMessages }) => (hasMessages ? `1px solid var(--color-light-grey)` : 'none')};
  border-bottom: ${({ hasMessages }) => (hasMessages ? `1px solid var(--color-light-grey)` : 'none')};

  ${devices.mobile} {
    margin-top: 32px;
    margin-left: 0 !important;
  }
`

const Title = styled.p`
  font: normal normal medium 18px/26px Avenir Next;
  color: var(--color-bl-blue);
  padding: 0 40px 0 0;
  margin: 0;
  ${devices.mobile} {
    padding: 0 0.8rem;
  }
`

const MessageContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

const Description = styled.p`
  color: var(--color-rich-black);
  font: normal normal normal 18px/26px Avenir Next;
  padding: 16px 0 0;
  margin: 0;
  ${devices.mobile} {
    padding: 2.4rem 0.8rem 0;
  }
`

const NoMessagesText = styled.p`
  margin: 0;
`
const ButtonContainer = styled.div`
  margin-top: 50px;
`
const MessagesSection = ({
  sfDonorMessages,
  field_title_color: titleColor,
  field_component_title: componentTitle,
  field_cta_1: fieldCta,
  field_cta_1_style: fieldCtaStyle
}) => {
  const { isMobileWidth } = useScreenSize()
  const { t } = useTranslation('DashboardMessagesSection')
  const hasMessages = sfDonorMessages && sfDonorMessages.length > 0
  const firstMessage = sfDonorMessages ? sfDonorMessages[0] : null

  return (
    <>
      <TitleContainer style={{ ...setTextColor(titleColor) }} dangerouslySetInnerHTML={{ __html: componentTitle?.processed }} />
      <MessagesSectionContainer className='main-container'>
        <MessagesContainer hasMessages={hasMessages}>
          {firstMessage && (
            <div>
              <MessageContainer>
                <Title>{firstMessage.title__c}</Title>
              </MessageContainer>
              <div>
                <Description>{firstMessage.description__c}</Description>
              </div>
            </div>
          )}
          {!hasMessages && <NoMessagesText>{t('nomessages')}</NoMessagesText>}
        </MessagesContainer>
      </MessagesSectionContainer>
      {fieldCta && (
        <ButtonContainer>
          <PrimaryButton text={fieldCta?.title} variant={fieldCtaStyle} sendTo={fieldCta?.uri} fluid={isMobileWidth} />
        </ButtonContainer>
      )}
    </>
  )
}
const mapStateToProps = (state) => ({
  sfDonorMessages: state.publicContent?.getMessagesSuccess?.sfDonorMessages
})

export default connect(mapStateToProps, null)(MessagesSection)
