import React, { useState, useEffect, useRef } from 'react'
import { TextAreaContainer, StyledTextArea, StyledImage, IconContainer, StyledImageContainer } from './textarea.styled'

function TextArea({
  className,
  disabled,
  iconContainerStyle = {},
  icon,
  placeholder,
  onEnter,
  rows: noOfRows = 0,
  dynamicRowCalculation = false,
  sendFunction
}) {
  const [value, setValue] = useState('')
  const [rows, setRows] = useState(noOfRows || 1)
  const textAreaRef = useRef(null)
  const [iconContaninerFocusOnTextAreaClick, setIconContaninerFocusOnTextAreaClick] = useState(false)
  const resizeTextArea = (height) => {
    textAreaRef.current.style.height = 'auto'
    textAreaRef.current.style.height = height ? height + 'px' : textAreaRef.current.scrollHeight + 'px'
  }

  useEffect(() => {
    if (value.length === 0) {
      icon && resizeTextArea(39)
      dynamicRowCalculation && setRows(noOfRows || 1)
      return
    }
    dynamicRowCalculation && setRows(value.split('\n').length)
    icon && resizeTextArea()
  }, [value])

  const handleChange = (e) => {
    const value = e?.target?.value || ''
    setValue(value)
  }

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      // on enter Clicked
      e.preventDefault()
      if (!e?.target?.value) return null
      sendFunction(e?.target?.value)
      setValue('')
    }
  }

  const settingFocusOnContainer = () => {
    setIconContaninerFocusOnTextAreaClick(true)
  }
  const unSetSettingFocusOnContainer = () => {
    setIconContaninerFocusOnTextAreaClick(false)
  }
  // console.log('textArearef', iconContaninerFocusOnTextAreaClick)
  return (
    <TextAreaContainer>
      {icon ? (
        <IconContainer
          $disabled={disabled}
          $focused={iconContaninerFocusOnTextAreaClick}
          $icon={icon}
          style={iconContainerStyle?.componentStyle || {}}
        >
          <StyledTextArea
            disabled={disabled}
            ref={textAreaRef}
            className={className}
            onChange={(e) => handleChange(e)}
            placeholder={placeholder}
            rows={rows}
            value={value}
            $icon={icon}
            onKeyDown={(e) => handleKeyDown(e)}
            onFocus={settingFocusOnContainer}
            onBlur={unSetSettingFocusOnContainer}
          />
          <StyledImageContainer
            $scrollHeight={textAreaRef?.current?.scrollHeight > 39}
            onClick={() => {
              if (!disabled) {
                sendFunction(value)
                setValue('')
              }
            }}
          >
            <StyledImage src={icon} $noOfLine={textAreaRef?.current?.scrollHeight} />
          </StyledImageContainer>
        </IconContainer>
      ) : (
        <StyledTextArea
          ref={textAreaRef}
          disabled={disabled}
          className={className}
          onChange={(e) => handleChange(e)}
          placeholder={placeholder}
          rows={rows}
          value={value}
          $icon={icon}
          onKeyDown={(e) => handleKeyDown(e)}
        />
      )}
    </TextAreaContainer>
  )
}

export default TextArea
