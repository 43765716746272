import React from 'react'
import { Icon, Dropdown } from 'semantic-ui-react'
import './styles.scss'

const HugeSelect = (props) => {
  const { options, placeholder, className, required, error, handleOnChange, name, defaultValue, disabled, width } = props

  const selectClasses = error ? `${className} Error` : className

  const getDefaultValue = () => {
    if (defaultValue && placeholder) {
      return defaultValue
    }
    if (placeholder && !defaultValue) {
      return ''
    }

    return options[0].value
  }

  return (
    <div className={className} style={{ width: width }}>
      {required && className === 'HugeSelect' && <Icon className='Required' size='tiny' name='asterisk' />}
      <Dropdown
        fluid
        selection
        className={`Base ${selectClasses}`}
        placeholder={placeholder}
        options={options}
        icon='chevron down'
        onChange={(e, { value }) => handleOnChange && handleOnChange(value)}
        defaultValue={getDefaultValue()}
        name={name}
        floating
        pointing
        disabled= {props.disabled}
      />
    </div>
  )
}

HugeSelect.defaultProps = {
  className: 'HugeSelect'
}
export default HugeSelect
