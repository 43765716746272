import React from 'react'
import importer from 'common/importer'
import { Grid, Segment } from 'semantic-ui-react'
const { setTextColor } = importer('Constant/Utils', null)
import PrimaryButton from '../Buttons/PrimaryButton'
import { getCssColorFromDrupalColorType } from '../../Constant/Utils'
import './style.scss'

const renderGrid = (field, field_column_1_desc, titleColor, descColor, textAlignment) => {
  return (
    <Grid.Column computer={4} tablet={8} mobile={16} verticalAlign='top' textAlign={textAlignment} className='gridColumn'>
      <div dangerouslySetInnerHTML={{ __html: field?.processed }} style={{ ...setTextColor(titleColor) }} className='gridTitle' />
      <div
        dangerouslySetInnerHTML={{ __html: field_column_1_desc?.processed }}
        style={{ ...setTextColor(descColor) }}
        className='description'
      />
    </Grid.Column>
  )
}

const WhyChooseBiolife = (props) => {
  let { field_text_alignment: textAlignment } = props
  const {
    field_column_1_desc,
    field_column_1_title,
    field_column_2_desc,
    field_column_2_title,
    field_column_3_desc,
    field_column_3_title,
    field_column_4_desc,
    field_column_4_title,
    field_component_desc,
    field_component_title,
    field_short_description,
    field_column_desc_color,
    field_column_title_color,
    field_short_desc_color,
    field_title_color,
    field_long_desc_color,
    field_cta_1,
    field_cta_1_style,
    field_bg_color
  } = props
  if (!textAlignment) textAlignment = 'center'
  return (
    <div style={{ background: getCssColorFromDrupalColorType(field_bg_color) }}>
      <div className='main-container'>
        <Segment textAlign={textAlignment} className='WhyChooseContainer segmentStyle'>
          {field_component_title && field_component_title.processed && (
            <div
              dangerouslySetInnerHTML={{ __html: field_component_title?.processed }}
              style={{ ...setTextColor(field_title_color) }}
              className='componentTitle'
            />
          )}
          {field_short_description && field_short_description.processed && (
            <div
              dangerouslySetInnerHTML={{ __html: field_short_description?.processed }}
              style={{ ...setTextColor(field_short_desc_color) }}
              className='titleAccompanyText'
            />
          )}
          {field_column_1_title?.processed ||
          field_column_2_title?.processed ||
          field_column_3_title?.processed ||
          field_column_4_title?.processed ? (
            <Grid className='gridStyling' centered>
              <Grid.Row columns={4}>
                {field_column_1_title?.processed || field_column_1_desc?.processed
                  ? renderGrid(field_column_1_title, field_column_1_desc, field_column_title_color, field_column_desc_color, textAlignment)
                  : null}
                {field_column_2_title?.processed || field_column_2_desc?.processed
                  ? renderGrid(field_column_2_title, field_column_2_desc, field_column_title_color, field_column_desc_color, textAlignment)
                  : null}
                {field_column_3_title?.processed || field_column_3_desc?.processed
                  ? renderGrid(field_column_3_title, field_column_3_desc, field_column_title_color, field_column_desc_color, textAlignment)
                  : null}
                {field_column_4_title?.processed || field_column_4_desc?.processed
                  ? renderGrid(field_column_4_title, field_column_4_desc, field_column_title_color, field_column_desc_color, textAlignment)
                  : null}
              </Grid.Row>
            </Grid>
          ) : null}
          <div
            dangerouslySetInnerHTML={{ __html: field_component_desc?.processed }}
            style={{ ...setTextColor(field_long_desc_color) }}
            className='declarativeText'
          />

          {field_cta_1 && (
            <div className='ButtonContainer'>
              <PrimaryButton text={field_cta_1?.title} variant={field_cta_1_style} sendTo={field_cta_1?.uri} />
            </div>
          )}
        </Segment>
      </div>
    </div>
  )
}

export default WhyChooseBiolife
