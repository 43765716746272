import React from 'react'
import importer from 'common/importer'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import './styles.scss'
import { Image } from 'semantic-ui-react'
const { useScreenSize } = importer('Contexts/ResponsiveContextProvider', null)
import { ReactComponent as NextArrowBlack } from '../../../assets/media/icons/circle-arrow-right.svg'
import { ReactComponent as PrevArrowBlack } from '../../../assets/media/icons/circle-arrow-left.svg'

const SliderNextArrowBlack = ({ className, onClick }) => {
  return <NextArrowBlack width='100' height='100' className={className} onClick={onClick} />
}

const SliderPrevArrowBlack = ({ className, onClick }) => {
  return <PrevArrowBlack width='50' height='50' className={className} onClick={onClick} />
}

const MeetOurStaffCard = ({ title, description, image }) => (
  <div className='meet-our-staff-card-container'>
    <Image src={image?.url} alt={image?.alt} width='125' height='125' />
    <p className='staff-title'>
      <strong>{title}</strong>
    </p>
    <p className='staff-description'>{description}</p>
  </div>
)

const CenterMeetOurStaff = ({ items }) => {
  const { isMobileWidth, isTabletWidth } = useScreenSize()

  var settings = {
    dots: true,
    intialSlide: 1,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: isMobileWidth || isTabletWidth ? false : true,
    nextArrow: <SliderNextArrowBlack />,
    prevArrow: <SliderPrevArrowBlack />,
    responsive: [
      {
        breakpoint: 1422,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 1366,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 1080,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  }

  return (
    <div className={`center-meet-our-staff-container ${items?.length <= 3 ? 'LessDataWrapper' : ''}`}>
      {items.length > 0 ? (
        <Slider className='center-meet-our-staff-slider' {...settings}>
          {items?.map(({ field_component_image, field_component_title_1, field_component_title_2 }) => (
            <MeetOurStaffCard image={field_component_image} title={field_component_title_1} description={field_component_title_2} />
          ))}
        </Slider>
      ) : null}
    </div>
  )
}

export default CenterMeetOurStaff
