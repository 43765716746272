import styled from 'styled-components'
import { devices } from '../../../utils/styledComponentHelpers'
import { ReactComponent as CollapsedButton } from '../../../../assets/media/icons/icon-circled-collapsed.svg'
import { ReactComponent as ExpandButton } from '../../../../assets/media/icons/icon-circled-expand.svg'
import { ReactComponent as NextArrow } from '../../../../assets/media/icons/circle-arrow-right.svg'
import { ReactComponent as PrevArrow } from '../../../../assets/media/icons/circle-arrow-left.svg'

const lessCardsStyle = `
.slick-slider {
  margin-bottom: 0;
  ${devices.mobile} {
    margin-bottom: 7rem;
  }
  .slick-track {
    margin-left: 0;
  }
  .slick-list {
    .slick-track {
      ${devices.desktop} {
        margin-left: 0;
        margin-right: 0;
      }
      .slick-slide.slick-cloned {
        display: none;
        ${devices.mobile} {
          display: block;
        }
      }
    }
  }
}
`

export const StyledAlternateCentersContainer = styled.div`
  width: 77vw;
  margin: 0 auto;
  ${devices.tablet} {
    padding-left: 4.8rem;
    padding-right: 4.8rem;
    margin: 0;
    width: 100%;
  }
  ${devices.mobile} {
    padding-left: 2.4rem;
    padding-right: 2.4rem;
  }
`
export const StyledAlternateCentersMessage = styled.p`
  text-align: center;
  max-width: 66.4rem;
  margin: 0.8rem auto 0 !important;
`
export const StyledAlternateCentersDisclaimerMessage = styled.p`
  text-align: center;
  max-width: 66.4rem;
  margin: 0rem auto 10.7rem !important;
  font-size: var(--caption-mobile-font-size);
  line-height: 2rem;
`
export const StyledAlternateCentersAccordionTitle = styled.div`
  margin: ${(props) => (props.$expanded ? '2.4rem 0' : '2.4rem 0 3.2rem')};
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.8rem;
`
export const StyledAlternateCentersAccordionContent = styled.div`
  display: ${(props) => !props.$expanded && 'none'};
  margin: 0 0 3.2rem;
`
export const StyledExpandIcon = styled(ExpandButton)`
  padding-top: 0.2rem;
  width: 1.6rem;
  height: 1.6rem;
`
export const StyledCollapsedIcon = styled(CollapsedButton)`
  padding-top: 0.2rem;
  width: 1.6rem;
  height: 1.6rem;
`
export const StyledSliderPrevArrow = styled(PrevArrow)`
  width: 5rem;
  height: 5rem;
`
export const StyledSliderNextArrow = styled(NextArrow)`
  width: 10rem;
  height: 10rem;
`
export const StyledAlternateCentersCarousel = styled.div`
  .slick-slider {
    margin: 0 -1rem 7rem;
    padding-bottom: 0;
    .slick-list {
      padding-top: 1rem;
      padding-bottom: 1rem;
      padding-left: 0.8rem;
      margin-right: 1.8rem;
      ${devices.mobile} {
        padding: 1rem calc((100vw - 40rem) / 2) 1rem;
        margin-right: 0;
      }
    }
    .slick-dots {
      bottom: -5rem;
      margin-top: 3.2rem;
      li {
        width: 0.8rem;
      }
      li button:before {
        background-color: var(--color-dark-grey);
        opacity: 1;
        border-radius: 1rem;
        height: 0.8rem;
        width: 0.8rem;
        text-indent: -999.9rem;
        overflow: hidden;
      }
      li.slick-active {
        width: 1.6rem;
      }
      li.slick-active button:before {
        background-color: var(--color-rich-black);
        border-radius: 0.5rem;
        height: 0.8rem;
        width: 1.6rem;
        margin-left: 0;
        margin-right: 0;
      }
    }
    .slick-prev {
      width: 4.8rem;
      height: 4.8rem;
      left: -6rem;
      &:hover {
        path {
          fill: var(--color-orange);
        }
      }
    }
    .slick-next {
      width: 4.8rem;
      height: 4.8rem;
      right: -4.2rem;
      &:hover {
        path {
          fill: var(--color-orange);
        }
      }
    }
  }
  .CenterCardContainer {
    margin: 0 auto;
    &.card {
      ${devices.mobile} {
        max-width: 32rem;
        width: 100% !important;
      }
      &:first-child {
        margin-top: auto;
      }
      &:last-child {
        margin-bottom: auto;
      }
      min-height: unset !important;
      .content .header + .description {
        min-height: 23rem !important;
        ${devices.mobile} {
          min-height: 18rem !important;
          .IsSpanishCenter {
            margin-bottom: 1.8rem;
          }
        }
      }
    }
    .CheckMarkContainer {
      .CheckMark {
        top: 0.3rem !important;
      }
    }
    .description {
      svg path {
        /* fill: var(--color-orange) !important; */
      }
    }
    .CardSeparator {
      display: none;
    }
    .CardInfoText {
      display: none;
    }
  }
  ${(props) => props?.$centerCards <= 3 && lessCardsStyle}
`
export const StyledLoadingState = styled.div`
  padding: 2rem 0;
`

export const StyledTnC = styled.a`
  text-decoration: underline;
  cursor: pointer;
  color: var(--color-bl-blue);
  font-size: 1.4rem;
  &:hover {
    color: var(--color-bl-blue);
    cursor: pointer;
  }
`
