import { getContext } from 'redux-saga/effects'

import importer from '@takedapdt/biolife-core/src/importer'

const NavigationService = importer('NavigationService')

/**
 * SIGN_OUT_SUCCESS
 */
function * signOutSuccess () {
  // console.log('signOutSuccess')

  const firebase = yield getContext('firebase')
  if (firebase.apps.length === 1) {
    firebase.analytics().logEvent('sign_out_success')
  }

  yield NavigationService.navigate(NavigationService.ROUTES.AuthSignIn)
}

export default signOutSuccess
