import React from 'react'
import { Div, DivWithFlex, Image, ImageDiv, TextComponent } from './styled'
import { useScreenSize } from '../../Contexts/ResponsiveContextProvider/'
function AboutAuthorComponent({ field_component_title: title, field_component_image: image, field_component_desc: desc }) {
  const { isMobileWidth } = useScreenSize()
  return (
    <div>
      <div className='main-container'>
        <TextComponent>About this author</TextComponent>
        <DivWithFlex $margin='2.4rem 0rem 4rem 0rem' $direction={isMobileWidth ? 'column' : 'row'}>
          {/* Image */}
          {image && image?.url && (
            <Div $textAlign='center' $margin='0rem 1.6rem 0rem 0rem'>
              <ImageDiv>
                <Image src={image?.url} $height='10rem' $width='10rem' />
              </ImageDiv>
            </Div>
          )}
          {/* Author Name And Details */}
          <Div $margin={isMobileWidth ? '1.6rem 0rem 0rem 0rem' : '0px'}>
            <Div dangerouslySetInnerHTML={{ __html: title?.processed }} /* style={{ ...setTextColor(titleColor) }} */></Div>
            <Div dangerouslySetInnerHTML={{ __html: desc?.processed }} /* style={{ ...setTextColor(titleColor) }} */></Div>
          </Div>
        </DivWithFlex>
      </div>
    </div>
  )
}

export default AboutAuthorComponent
