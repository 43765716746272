import React, { useState } from 'react'
import styled from 'styled-components'
import { devices } from '../../utils/styledComponentHelpers'

import PrimaryButton from '../Buttons/PrimaryButton'
const StyledContentWrapper = styled.div`
  display: flex;
  padding: 5rem 10rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: ${(props) => props.internalPadding};
  width: 100%;
  ${devices.mobile} {
    padding: 5.5rem 1.4rem;
  }
`
const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 3rem;
`
const StyledDescription = styled.p`
  margin-bottom: 2rem;
  font-size: 2rem;
  line-height: 3.5rem;

  ${devices.mobile} {
    font-size: 2rem;
    line-height: 2.5rem;
  }
`

const StyledTitle = styled.p`
  color: var(--color-bl-blue);
  margin-bottom: 2rem;
  font-size: 3rem;
  line-height: 3.5rem;
  font-weight: bolder !important;
  font-family: 'AvenirNextBold' !important;

  ${devices.mobile} {
    font-size: 2rem;
    line-height: 2.5rem;
  }
`

const CancelButton = styled.p`
  margin-top: 1rem;
  text-decoration: underline;
  color: var(--color-bl-blue);
  cursor: pointer;

  ${devices.mobile} {
    margin-top: 2rem;
  }
`

const StyledButtonsContainer = styled.div`
  display: flex;
  justify-content: ${(props) => (props.type === 'optOut' ? 'space-between' : 'center')};
  width: 60%;

  ${devices.mobile} {
    flex-direction: column;
    width: 100%;
  }
`

const UseLocationPopup = ({ title, togglePopUp, setTogglePopUp, description, buttonText }) => {
  const handleCancel = () => {
    if (togglePopUp) {
      setTogglePopUp(false)
    }
  }

  return (
    <StyledContentWrapper>
      <Content>
        <StyledTitle>{title}</StyledTitle>
        <StyledDescription>{description}</StyledDescription>
      </Content>
      <StyledButtonsContainer>
        <PrimaryButton onClick={handleCancel} text={buttonText} variant={'solid_blue'} />
      </StyledButtonsContainer>
    </StyledContentWrapper>
  )
}

export default UseLocationPopup
