import styled from 'styled-components'
import { devices } from '../../../utils/styledComponentHelpers'

export const SubmitAppointment = styled.div`
  text-align: center;
  padding: 1.6rem 0 10rem;
  ${devices.mobile} {
    display: flex;
    padding: 2.4rem 1.5rem 4.8rem;
    justify-content: center;
    position: fixed;
    z-index: 6;
    background: var(--color-white);
    margin: 0;
    box-shadow: 0rem -0.4rem 0.8rem var(--color-box-shadow-grey);
    bottom: 0;
    width: 100%;
  }
`
