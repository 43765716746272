import React from 'react'
import './styles.scss'
import { Grid, Segment } from 'semantic-ui-react'
import importer from '@takedapdt/biolife-core/src/importer'

const { useScreenSize } = importer('Contexts/ResponsiveContextProvider', null)
const CustomCheckbox = importer('Components/Inputs/CustomCheckbox')
const { getCssColorFromDrupalColorType, setTextColor, getMediaFromDrupalMimeType } = importer('Constant/Utils', null)

const JoinMyBiolifeRewards = ( props ) => {
  const { isMobileWidth, isTabletWidth } = useScreenSize()
  const {
    field_bg_color: backgroundColor,
    field_alignment: imageAlignment,
    field_title_color: contentTitleColor,
    field_short_desc_color: contentDescriptionColor,
    field_component_title: contentTitle,
    field_short_description: contentDescription,
    field_component_asset: imageObject,
    field_component_desc: checkboxLabel,
    field_long_desc_color: checkboxLabelColor,
    checkboxId: checkboxId = '',
    meta: meta = {},
    input: input = {}
  } = props

  return (
    <div className='JoinMyBiolifeRewardsContainer' style={{ background: `${getCssColorFromDrupalColorType(backgroundColor)}` }}>
      <Grid>
        <Grid.Row columns={2} className={`JoinMyBiolifeRewardsWrapper ${ imageAlignment === 2 ? 'Reversed' : ''}`}>
          <Grid.Column width={isMobileWidth ? 7 : 4} className='ImageBox'>{getMediaFromDrupalMimeType(imageObject?.mimetype, imageObject?.url, imageObject?.alt)}</Grid.Column>
          <Grid.Column className='ContentBox' width={isMobileWidth ? 16 : 12}>
            <Segment basic>
              <div
                className='ContentTitle'
                style={{ ...setTextColor(contentTitleColor) }}
                dangerouslySetInnerHTML={{ __html: contentTitle?.processed }}
              />
              <div
                className='ContentDescription'
                style={{ ...setTextColor(contentDescriptionColor) }}
                dangerouslySetInnerHTML={{ __html: contentDescription?.processed }}
              />
              <CustomCheckbox input={input} meta={meta} checkboxId={checkboxId} checkboxLabelColor={checkboxLabelColor} label={checkboxLabel?.processed}/>
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  )
}

export default JoinMyBiolifeRewards
