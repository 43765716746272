import React from 'react'
import './styles.scss'
import { Grid } from 'semantic-ui-react'
import './styles.scss'
import importer from '@takedapdt/biolife-core/src/importer'

const { useScreenSize } = importer('Contexts/ResponsiveContextProvider', null)
const { getCssColorFromDrupalColorType, setTextColor, getMediaFromDrupalMimeType } = importer('Constant/Utils', null)

const DonationImpact = (props) => {
  const {
    field_component_title: donationImpactComponentTitle,
    field_title_color: donationImpactComponentTitleColor,
    field_component_asset: donationImpactComponentAsset,
    field_alignment_2: donationImpactComponentAlignment2,
    field_bg_color: donationImpactComponentBGColor,
    field_component_desc: donationImpactComponentDescription,
    field_long_desc_color: donationImpactComponentLongDescriptionColor
  } = props
  const { isMobileWidth } = useScreenSize()

  return (
    <div
      className='donation-impact-process'
      style={{
        background: `${getCssColorFromDrupalColorType(donationImpactComponentBGColor)}`
      }}
    >
      <div className='main-container'>
        <Grid className='donation-impact-process__container'>
          {donationImpactComponentTitle && (
            <Grid.Row computer={8} tablet={8} mobile={16} verticalAlign='middle'>
              <div
                className='donation-impact-process__title-container'
                style={{ ...setTextColor(donationImpactComponentTitleColor) }}
                dangerouslySetInnerHTML={{ __html: donationImpactComponentTitle?.value }}
              />
            </Grid.Row>
          )}

          {isMobileWidth ? (
            <Grid.Row
              columns={2}
              className={`donation-impact-process__grid-column${donationImpactComponentAlignment2 === 1 ? '--reversed' : ''}`}
            >
              <Grid.Column mobile={16} tablet={8}>
                <div
                  style={{ ...setTextColor(donationImpactComponentLongDescriptionColor) }}
                  className='donation-impact-process__description-container'
                  dangerouslySetInnerHTML={{ __html: donationImpactComponentDescription?.value }}
                />
              </Grid.Column>

              <Grid.Column tablet={8} mobile={16} className='donation-impact-process__img-container' verticalAlign='top'>
                {getMediaFromDrupalMimeType(donationImpactComponentAsset?.mimetype, donationImpactComponentAsset?.url, donationImpactComponentAsset?.alt)}
              </Grid.Column>
            </Grid.Row>
          ) : (
            <Grid.Row className={`donation-impact-process__grid-column${donationImpactComponentAlignment2 === 1 ? '--reversed' : ''}`}>
              <Grid.Column computer={10}>
                <div
                  style={{ ...setTextColor(donationImpactComponentLongDescriptionColor) }}
                  className='donation-impact-process__description-container'
                  dangerouslySetInnerHTML={{ __html: donationImpactComponentDescription?.value }}
                />
              </Grid.Column>

              <Grid.Column computer={6} className='donation-impact-process__img-container'>
                {getMediaFromDrupalMimeType(donationImpactComponentAsset?.mimetype, donationImpactComponentAsset?.url, donationImpactComponentAsset?.alt)}
              </Grid.Column>
            </Grid.Row>
          )}
        </Grid>
      </div>
    </div>
  )
}

export default DonationImpact
