import React, { useEffect } from 'react'
import { connect } from 'react-redux'
/* DYNAMIC IMPORTS */
import importer from '@takedapdt/biolife-core/src/importer'
import TagManager from 'react-gtm-module'
/* ACTIONS */
const UserActions = importer('Stores/Users/Actions')
import LoaderSpinner from '../Components/Loaders/LoaderSpinner'
/* CONTAINERS */
import { useTranslation } from 'react-i18next'

const LogOut = ({ signOut,donor }) => {
  useEffect(() => {
    window.sessionStorage.removeItem('logoutTimeOut')
    if (donor && donor.email) {
      TagManager.dataLayer({
        dataLayer: {
          event: 'signOut',
          donorEmail: donor.email
        }
      })
    }
    signOut()
  }, [])
  const { t } = useTranslation('LogOut')

  return (
    <LoaderSpinner backdrop="full"/>
  )
}

const mapStateToProps = (state) => ({
  logoutSuccess: state.users.logoutSucess,
  donor:state.donors?.donors?.data
})

const mapDispatchToProps = (dispatch) => ({
  signOut: () => dispatch(UserActions.logout())
})

export default connect(mapStateToProps, mapDispatchToProps)(LogOut)
