import React from 'react'
import { connect } from 'react-redux'
import importer from 'common/importer'
import { Card } from 'semantic-ui-react'
import Slider from 'react-slick'
import './styles.scss'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
const { useScreenSize } = importer('Contexts/ResponsiveContextProvider', null)
const PrimaryButton = importer('Components/Buttons/PrimaryButton')
const { getCssColorFromDrupalColorType, setTextColor } = importer('Constant/Utils', null)
import { ReactComponent as NextArrowBlack } from '../../../assets/media/icons/circle-arrow-right.svg'
import { ReactComponent as PrevArrowBlack } from '../../../assets/media/icons/circle-arrow-left.svg'

const SliderNextArrowBlack = ({ className, onClick }) => {
  return <NextArrowBlack width='100' height='100' className={className} onClick={onClick} />
}

const SliderPrevArrowBlack = ({ className, onClick }) => {
  return <PrevArrowBlack width='50' height='50' className={className} onClick={onClick} />
}

const AnnouncementCard = ({ title, title2, titleColor, cta, ctaStyle, backgroundColor }) => (
  <Card raised style={{ background: getCssColorFromDrupalColorType(backgroundColor) }}>
    <Card.Content>
      <Card.Header>{title}</Card.Header>
      <Card.Description dangerouslySetInnerHTML={{ __html: title2?.processed }} style={{ ...setTextColor(titleColor) }} />
      <PrimaryButton variant={ctaStyle} text={cta?.title} sendTo={cta?.uri} />
    </Card.Content>
  </Card>
)

const AnnouncementBanners = ({ uniqueData }) => {
  const { isMobileWidth, isTabletWidth } = useScreenSize()

  var settings = {
    dots: true,
    intialSlide: 1,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: isMobileWidth || isTabletWidth ? false : true,
    nextArrow: <SliderNextArrowBlack />,
    prevArrow: <SliderPrevArrowBlack />,
    responsive: [
      {
        breakpoint: 1422,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 1366,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 1080,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  }

  return (
    <>
      {uniqueData.length > 0 ? (
        <div className={`announcement-banner-container ${uniqueData?.length <= 3 ? 'LessDataWrapper' : ''}`}>
          <Slider className='announcement-banner-slider' {...settings}>
            {uniqueData?.map(
              ({ field_component_title_1, field_component_title, field_title_color, field_cta_1, field_cta_1_style, field_bg_color }) => (
                <AnnouncementCard
                  title={field_component_title_1}
                  title2={field_component_title}
                  titleColor={field_title_color}
                  cta={field_cta_1}
                  ctaStyle={field_cta_1_style}
                  backgroundColor={field_bg_color}
                />
              )
            )}
          </Slider>
        </div>
      ) : null}
    </>
  )
}

const mapStateToProps = (state) => ({
  centerInfo: state.centers?.getCenterInfoSuccess?.data,
  getCenterInfoFailure: state.centers?.getCenterInfoFailure
})

const mapDispatchToProps = (dispatch) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(AnnouncementBanners)
