import styled from 'styled-components'
import importer from '@takedapdt/biolife-core/src/importer'
const { getCssColorFromDrupalColorType } = importer('Constant/Utils', null)
const { devices } = importer('utils/styledComponentHelpers', null)
import backgroundImage from '../../../../assets/media/images/dashboard_banner.svg'

export const DashboardBannerContainer = styled.div`
  background-color: var(--color-smoke-grey);
`

export const DashboardBannerWrapper = styled.div`
  //Do not remove the below commented styles
  /* background: linear-gradient(to bottom, var(--color-dark-orange),  var(--color-bl-orange)); */
  border-bottom-left-radius: 200px;
  border-bottom-right-radius: 200px;
  background-image: url(${backgroundImage});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
  max-width: 1366px;
  margin: 0 auto;
  ${devices.mobile} {
    border-bottom-left-radius: 64px;
    border-bottom-right-radius: 64px;
  }
`

export const DashboardBannerInnerWrapper = styled.div`
  display: flex;
  padding-top: 48px;
  padding-bottom: 48px;
  align-items: center;
  ${devices.mobile} {
    padding-top: 24px;
    padding-bottom: 48px;
    flex-direction: row-reverse;
    justify-content: space-between;
  }
`

export const DashboardBannerImageBlock = styled.div`
  margin-right: 24px;
  cursor: pointer;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  background-color: var(--color-bg-light-blue);
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    font-size: var(--h5-font-size);
    line-height: var(--h5-line-height);
    font-family: var(--font-family-medium) !important;
    color: var(--color-bl-blue);
  }
  ${devices.mobile} {
    margin-right: 0;
    width: 40px;
    height: 40px;
    span {
      font-size: var(--caption-font-size);
      line-height: var(--caption-line-height);
    }
  }
`

export const DashboardBannerTextBlock = styled.div`
  color: var(--color-white);
  p:first-child {
    font-size: var(--caption-font-size) !important;
    font-family: var(--font-family-bold) !important;
    line-height: var(--caption-line-height) !important;
    ${devices.mobile} {
      font-size: var(--caption-mobile-font-size) !important;
      font-family: var(--font-family-regular) !important;
      line-height: var(--caption-mobile-line-height) !important;
    }
  }
  p:last-child {
    font-size: var(--h4-font-size) !important;
    font-family: var(--font-family-bold) !important;
    line-height: var(--h4-line-height) !important;
    ${devices.mobile} {
      font-size: var(--h4-mobile-font-size) !important;
      line-height: var(--h4-mobile-line-height) !important;
    }
  }
`
