import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import myInfoValidations from './myInfoValidations'
import passwordValidations from './passwordValidations'
import emailValidations from './emailValidations'
import { useTranslation } from 'react-i18next'
import donorsActions from '@takedapdt/biolife-core/src/Stores/Donors/Actions'
import publicContentActions from '@takedapdt/biolife-core/src/Stores/PublicContent/Actions'
import centersActions from '@takedapdt/biolife-core/src/Stores/Centers/Actions'
import styled from 'styled-components'
import SettingForm from '../SettingForm'
import { devices } from '../../../utils/styledComponentHelpers'
import { isEmpty } from 'lodash'

const H3Header = styled.h3`
  color: var(--color-bl-blue);
  margin-bottom: 1.6rem;
  font-family: var(--font-family-bold) !important;

  ${devices.mobile} {
    margin-bottom: 3.2rem;
  }
`

const FormContainer = styled.div``
const FormInfo = styled.div`
  margin: 3rem auto 7.2rem;
`

const Para = styled.p`
  margin-bottom: 2.5rem;
  font-size: var(--caption-font-size);

  ${devices.mobile} {
    margin-bottom: 4rem;
  }
`

const Asterisk = styled.span`
  color: var(--color-dark-orange);
  margin-right: 0.3rem;
`

const MyInformation = (props) => {
  const { t, i18n } = useTranslation('ProfileSettings')

  const [readOnly] = useState(props.donor?.pdn)
  const [message, setMessage] = useState(t('yourDonorInfoWillBeLocked'))
  const [passwordChangeSuccessState, setPasswordChangeSuccessState] = useState(false);

  const myInformationValues = [
    { validation: 'firstName', label: 'First Name', type: 'TextField', mandatory: true, readOnly },
    { validation: 'lastName', label: 'Last Name', type: 'TextField', mandatory: true, readOnly },
    { validation: 'addressLine1', label: 'Address', type: 'TextField', mandatory: false, readOnly },
    { validation: 'city', label: 'City', type: 'TextField', mandatory: true, readOnly },
    { validation: 'state', label: 'State', type: 'select', mandatory: true, readOnly },
    { validation: 'country', label: 'Country', type: 'select', mandatory: true, readOnly },
    { validation: 'zipCode', label: 'Zip Code', type: 'TextField', mandatory: true, readOnly },
    { validation: 'phoneNumber', label: 'Phone Number', type: 'MaskedField', mandatory: true, readOnly },
    { validation: 'phoneType', label: 'Type', type: 'select', mandatory: true, readOnly },
    { validation: 'birthDate', label: 'Date Of Birth', type: 'MaskedField', mandatory: true, readOnly },
    { validation: 'ethnicityCode', label: 'Ethnicity', type: 'select', mandatory: false },
    { validation: 'gender', label: 'Gender', type: 'select', mandatory: true }
  ]

  const changeEmail = [
    { validation: 'currentEmail', label: 'Current Email', type: 'TextField', mandatory: true, readOnly:true },
    { validation: 'newEmail', label: 'New Email', type: 'TextField', mandatory: true },
    { validation: 'confirmEmail', label: 'Confirm Email', type: 'TextField', mandatory: true }
  ]

  const changePassword = [
    { validation: 'currentPassword', label: 'Current Password', type: 'PasswordField', mandatory: true },
    { validation: 'password', label: 'New Password', type: 'PasswordField', mandatory: true },
    { validation: 'passwordConfirm', label: 'Confirm Password', type: 'PasswordField', mandatory: true }
  ]

  useEffect(() => {
    props.getDonors(props.user.id)
    props.getCountries()
    props.getAllStates()
    props.getGenders()
    props.getPhoneTypes()
    props.getEthnicityOptions()
  }, [])

  useEffect(() => {
    if (!isEmpty(props.donor)) {
      if (props.donor.pdn) {
        setMessage(t('profileIsLocked'))
      }
      if (props.donor.donationCenter) {
        props.getAllCenters(props.donor?.donationCenter)
      }
    }
  }, [props.donor])

  const changePasswordBlockOnSuccess = (value) => {
    setPasswordChangeSuccessState(value)
  }

  return (
    <FormContainer>
      <FormInfo>
        <H3Header>{t('myinformation')}</H3Header>
        <Para>
          <Asterisk>*</Asterisk>
          {t('indicatesRequiredField')}
        </Para>
        <SettingForm
          fieldLabel={myInformationValues}
          donorInfoLocked={message}
          showPasswordValidations={false}
          fieldOptions={props}
          validations={myInfoValidations}
          componentType={'myInfo'}
        />
        <hr />
      </FormInfo>

      <FormInfo>
        <H3Header>{t('changeEmail')}</H3Header>
        <SettingForm
          fieldLabel={changeEmail}
          donorInfoLocked={false}
          showPasswordValidations={false}
          fieldOptions={props}
          validations={emailValidations}
          componentType={'changeEmail'}
        />
        <hr />
      </FormInfo>

      <FormInfo>
        {
          props?.changePasswordSuccess && passwordChangeSuccessState ? (
            <H3Header>{t('updatedSuccess')}</H3Header>
          ) : (
            <>
            <H3Header>{t('changePassword')}</H3Header>
            <SettingForm
              fieldLabel={changePassword}
              donorInfoLocked={false}
              showPasswordValidations={true}
              fieldOptions={props}
              validations={passwordValidations}
              componentType={'changePassword'}
              changePasswordBlockOnSuccess={changePasswordBlockOnSuccess}
              passwordChangeSuccessState={passwordChangeSuccessState}
            />
            </>
          )
        }
        
        <hr />
      </FormInfo>
    </FormContainer>
  )
}

const mapStateToProps = (state) => ({
  user: state.users.user,
  centers: state.centers.donorCenters?.data,
  center: state.centers.getAllCentersSuccess,
  genders: state.publicContent.Genders,
  ethnicityOptions: state.publicContent.Ethnicity,
  states: state.publicContent.States,
  countries: state.publicContent.Countries,
  phoneTypes: state.publicContent.PhoneTypes,
  getDonorCentersAllLoading: state.centers.getAllCentersLoading,
  signUpUser: state.users.registerSignUp,
  registerLoading: state.authCustom.registerLoading,
  registerSuccess: state.authCustom.registerSuccess,
  registerFailure: state.authCustom.registerFailure,
  donor: state.donors.donors?.data,
  donorBySfid: state.donors.getDonorBySfidSuccess?.data,
  changePasswordSuccess: state.authCustom.changePasswordSuccess,
})

const mapDispatchToProps = (dispatch) => ({
  getDonors: (id) => dispatch(donorsActions.getDonors(id)),
  register: (donor) => dispatch(AuthCustomActions.register(donor)),
  getDonorBySfid: (sfid, pid) => dispatch(donorsActions.getDonorBySfid(sfid, pid)),
  getGenders: () => dispatch(publicContentActions.getGenders()),
  getEthnicityOptions: () => dispatch(publicContentActions.getEthnicityOptions()),
  getAllStates: () => dispatch(publicContentActions.getAllStates()),
  getCountries: () => dispatch(publicContentActions.getCountries()),
  getPhoneTypes: () => dispatch(publicContentActions.getPhoneTypes()),
  getAllCenters: (sfid) => dispatch(centersActions.getAllCenters(sfid))
})
export default connect(mapStateToProps, mapDispatchToProps)(MyInformation)
