import React, { useEffect } from 'react'
import ReactDOM from 'react-dom'
import styled from 'styled-components'
import importer from '@takedapdt/biolife-core/src/importer'
const { devices } = importer('utils/styledComponentHelpers', null)
import { ReactComponent as ButtonCloseIcon } from '../../../assets/media/icons/button-close-large-light-gray.svg'

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-rich-black);
  z-index: 1001;
`

const ModalContentWrapper = styled.div`
  position: absolute;
  top: 0;
  z-index: 1002;
  width: 100vw;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`
const ModalContent = styled.div`
  position: relative;
  background-color: var(--color-white);
  border-radius: 25px;
  width: ${({ isVideoModal }) => isVideoModal && ' 59.5vw'};
  height: ${({ isVideoModal }) => isVideoModal && '34.5vw'};
  max-width: ${({ $maxWidth }) => (typeof $maxWidth === 'number' ? `${$maxWidth}px` : $maxWidth || 'auto')};
  max-height: ${({ $maxHeight }) => (typeof $maxHeight === 'number' ? `${$maxHeight}px` : $maxHeight || 'auto')};
  z-index: 1002;
  overflow-y: auto;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
  ${devices.mobile} {
    width: ${({ isVideoModal }) => isVideoModal && ' 86.5vw'};
    height: ${({ isVideoModal }) => (isVideoModal ? '50vw' : '100%')};
    position: ${({ isVideoModal }) => isVideoModal && 'unset'};
    max-width: ${({ $maxWidthMobile }) => (typeof $maxWidthMobile === 'number' ? `${$maxWidthMobile}px` : $maxWidthMobile || 'auto')};
    max-height: ${({ $maxHeightMobile }) => `${$maxHeightMobile}px` || 'auto'};
    overflow-y: ${({ isVideoModal }) => (isVideoModal ? 'unset' : 'auto')};
  }
`
const CloseModalIcon = styled.div`
  position: absolute;
  cursor: pointer;
  top: ${(props) => (props.isVideoModal ? '-50px' : '8px')};
  right: ${(props) => (props.isVideoModal ? '-56px' : '8px')};
  height: ${({ isVideoModal }) => isVideoModal && '56px'};
  width: ${({ isVideoModal }) => isVideoModal && '56px'};
  z-index: 7;
  ${devices.mobile} {
    top: ${({ isVideoModal }) => isVideoModal && ' 2.4rem'};
    right: ${({ isVideoModal }) => isVideoModal && ' 3rem'};
    height: ${({ isVideoModal }) => isVideoModal && '48px'};
    width: ${({ isVideoModal }) => isVideoModal && '48px'};
  }
`

const StyledImage = styled(ButtonCloseIcon)`
  width: ${({ isVideoModal }) => (isVideoModal ? '56px' : '48px')};
  height: ${({ isVideoModal }) => (isVideoModal ? '56px' : '48px')};
  path:nth-child(even) {
    ${({ isVideoModal }) => isVideoModal && 'fill: var(--color-light-grey)'}
  }
  &:hover path:nth-child(even) {
    ${({ isVideoModal }) => isVideoModal && 'fill: var(--color-dark-grey)'}
  }
  ${devices.mobile} {
    width: ${({ isVideoModal }) => (isVideoModal ? '48px' : '40px')};
    height: ${({ isVideoModal }) => (isVideoModal ? '48px' : '40px')};
  }
`

const Modal = ({ isOpen, onClose, children, maxWidth, maxWidthMobile, maxHeight, maxHeightMobile, isVideoModal }) => {
  const modalRootStyles = {
    position: 'absolute',
    minHeight: '100vh',
    width: '100vw',
    zIndex: '1002',
    top: '0',
    left: '0',
    overflowY: 'scroll',
    scrollbarWidth: 'none'
  }

  const rootStyles = {
    height: '100vh',
    overflow: 'hidden'
  }

  const closeHandler = () => {
    document.getElementById('modal-root').removeAttribute('style')
    document.getElementById('react').style.removeProperty('height')
    document.getElementById('react').style.removeProperty('overflow')
  }

  useEffect(() => {
    if (isOpen) {
      Object.assign(document.getElementById('modal-root').style, modalRootStyles)
      Object.assign(document.getElementById('react').style, rootStyles)
    } else {
      closeHandler()
    }
  }, [isOpen])

  if (!isOpen) return null

  return ReactDOM.createPortal(
    <div
      onClick={() => {
        onClose()
        closeHandler()
      }}
    >
      <ModalOverlay
        onClick={() => {
          onClose()
          closeHandler()
        }}
      />
      <ModalContentWrapper>
        <ModalContent
          $maxWidth={maxWidth}
          $maxWidthMobile={maxWidthMobile}
          $maxHeight={maxHeight}
          $maxHeightMobile={maxHeightMobile}
          onClick={(event) => event.stopPropagation()}
          isVideoModal={isVideoModal}
        >
          <CloseModalIcon
            isVideoModal={isVideoModal}
            onClick={() => {
              onClose()
              closeHandler()
            }}
          >
            <StyledImage isVideoModal={isVideoModal} />
          </CloseModalIcon>
          {children}
        </ModalContent>
      </ModalContentWrapper>
    </div>,
    document.getElementById('modal-root')
  )
}

export default Modal
