import React, { useState } from 'react'
import styled from 'styled-components'
import { useSelector, useDispatch } from 'react-redux'
import { Form, Field } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import validate from './validate'
import importer from '@takedapdt/biolife-core/src/importer'
const { devices } = importer('utils/styledComponentHelpers', null)
const CustomCheckbox = importer('Components/Inputs/CustomCheckbox')
const PrimaryButton = importer('Components/Buttons/PrimaryButton')
const donorsActions = importer('Stores/Donors/Actions')
const { useScreenSize } = importer('Contexts/ResponsiveContextProvider', null)
const { getCssColorFromDrupalColorType, setTextColor, getMediaFromDrupalMimeType } = importer('Constant/Utils', null)
const { sanitizeHtml } = importer('utils/htmlHelpers', null)

const ContentWrapper = styled.div`
  padding: 64px;
  ${devices.mobile} {
    padding: 64px 32px 48px 32px;
  }
  .custom-checkbox {
    .InfoIcon {
      width: 10rem;
    }
  }
  .enrollmentSubmitButton {
    margin-top: 17px;
    width: 27.8rem !important;
    margin-right: 0 !important;
    ${devices.mobile} {
      width: 100% !important;
    }
  }
`

const Title = styled.div`
  ${(props) => setTextColor(props.$sectionTitleColor)};
`

const Description = styled.div`
  ${(props) => setTextColor(props.$sectionDescriptionColor)};
  padding-top: 16px;
  padding-bottom: 16px;
`
const EnrollButton = styled.div``
const LoyaltyEnrolmentModal = ({ modalContent, handleModalSubmit, isLoyaltyEnrolmentModalSubmitClicked }) => {
  const {
    field_component_title: sectionTitle,
    field_title_color: sectionTitleColor,
    field_component_desc: sectionDescription,
    field_long_desc_color: sectionDescriptionColor,
    field_checkbox_content: sectionCheckboxContent,
    field_cta_1: sectionCTA1,
    field_cta_1_style: sectionCTA1Style
  } = modalContent

  const { isMobileWidth } = useScreenSize()
  const [enrolmentError, setEnrolmentError] = useState(false)
  const { t } = useTranslation('MyLoyaltyEnrolment')

  const donor = useSelector((state) => state.donors.donors?.data)
  const updateDonorFailure = useSelector((state) => state.donors?.updateDonorFailure?.response?.data)
  const dispatch = useDispatch()

  const handleSubmit = (values) => {
    handleModalSubmit(true)
    if (values.loyaltyEnrolment) {
      setEnrolmentError(false)
      dispatch(
        donorsActions.updateDonor(donor?.id, {
          donorDetails: {
            isLoyaltyMember: 'Yes'
          },
          loyaltyApplicationUsed: isMobileWidth ? 'MOBILE_WEB' : 'WEB'
        })
      )
    } else {
      setEnrolmentError(true)
    }
  }

  return (
    <ContentWrapper>
      <Title $sectionTitleColor={sectionTitleColor} dangerouslySetInnerHTML={sanitizeHtml(sectionTitle?.processed)} />
      <Description
        $sectionDescriptionColor={sectionDescriptionColor}
        dangerouslySetInnerHTML={sanitizeHtml(sectionDescription?.processed)}
      />
      <Form
        onSubmit={handleSubmit}
        validate={validate}
        render={({ handleSubmit, submitting, pristine }) => (
          <form onSubmit={handleSubmit}>
            <Field name='loyaltyEnrolment' type='checkbox'>
              {({ input, meta }) => (
                <CustomCheckbox
                  input={input}
                  meta={meta}
                  checkboxId='loyaltyEnrolmentCheckbox'
                  label={sectionCheckboxContent?.processed}
                  isRequired={true}
                />
              )}
            </Field>
            <EnrollButton>
              <PrimaryButton
                className='enrollmentSubmitButton'
                text={sectionCTA1?.title}
                variant={sectionCTA1Style}
                type='submit'
                loading={updateDonorFailure && isLoyaltyEnrolmentModalSubmitClicked}
                fluid={isMobileWidth}
              />
            </EnrollButton>
            {/* {enrolmentError && <p className='enrollmentUncheckError'>{t('enrolmentUncheckError')}</p>} */}
            {updateDonorFailure && isLoyaltyEnrolmentModalSubmitClicked && (
              <p className='enrollmentUncheckError'>{updateDonorFailure.message[0]}</p>
            )}
          </form>
        )}
      />
    </ContentWrapper>
  )
}

export default LoyaltyEnrolmentModal
