import React from 'react'
import { CardContainer, LeftContainer, RightContainer } from './styles/CardContainer.styled'
import { Container } from './styles/Container.styled'
import importer from '@takedapdt/biolife-core/src/importer'
const { getCssColorFromDrupalColorType, setTextColor } = importer('Constant/Utils', null)
const { sanitizeHtml } = importer('utils/htmlHelpers', null)
const PrimaryButton = importer('Components/Buttons/PrimaryButton')

function CardTemplate2({
  field_bg_color_1: backgroundColor = 'white', // card bg color
  field_bg_color: bgColor = 'white',
  field_short_description: desc = {},
  field_component_desc: longDesc = {},
  field_long_desc_color: longDescColor = 'rich_black',
  field_short_desc_color: shortDescColor = 'rich_black',
  field_component_title: title = {},
  field_title_color: titleColor = 'rich_black',
  field_component_image: image = {},
  field_alignment: imageAlignment = 1,
  field_cta_1: cta1 = {},
  field_cta_1_style: cta1Style = ''
}) {
  return (
    <Container bgColor={bgColor || 'white'} className='cardblock__mainContainer'>
      <Container className='main-container'>
        <CardContainer className='cardblock' bg={backgroundColor} imageAlignment={imageAlignment}>
          <LeftContainer className='cardblock--leftContainer' imageAlignment={imageAlignment}>
            <img className='cardblock__imageStyle' src={image.url} alt={image.alt} />
          </LeftContainer>
          <RightContainer className='cardblock--rightContainer' imageAlignment={imageAlignment}>
            {title && title.processed ? (
              <div
                className='cardblock--title_text_container'
                dangerouslySetInnerHTML={sanitizeHtml(title.processed || '<h3></h3>')}
                style={{ ...setTextColor(titleColor) }}
              ></div>
            ) : null}
            {desc && desc.processed ? (
              <div
                className='cardblock--desc_text_container'
                dangerouslySetInnerHTML={sanitizeHtml(desc.processed || '<p></p>')}
                style={{ ...setTextColor(shortDescColor) }}
              ></div>
            ) : null}
            {longDesc && longDesc.processed ? (
              <div
                className='bgCardblock--longDesc_text_container'
                dangerouslySetInnerHTML={sanitizeHtml(longDesc.processed)}
                style={{ ...setTextColor(longDescColor) }}
              ></div>
            ) : null}
            {cta1Style && cta1 && cta1.title ? <PrimaryButton sendTo={cta1?.uri} text={cta1.title} variant={cta1Style} /> : null}
          </RightContainer>
        </CardContainer>
      </Container>
    </Container>
  )
}

export default CardTemplate2
