import React from 'react'
import importer from '@takedapdt/biolife-core/src/importer'
import { Grid } from 'semantic-ui-react'
const PrimaryButton = importer('Components/Buttons/PrimaryButton')
const { setTextColor } = importer('Constant/Utils', null)
const { useScreenSize } = importer('Contexts/ResponsiveContextProvider', null)

const BannerTemplate = (props) => {
  const { isMobileWidth, isTabletWidth } = useScreenSize()
  const {
    field_component_title: imageBannerComponentTitle,
    field_title_color: imageBannerTitleColor,
    field_cta_1: imageBannerCTA1,
    field_short_desc_color: imageBannerShortDescriptionColor,
    field_short_description: imageBannerShortDescription,
    field_cta_1_style: imageBannerCTAStyle,
    field_alignment_2: imageBannerAlignment2
  } = props

  return (
    <div className='BannerTemplate'>
      <div className='main-container'>
        {isMobileWidth || isTabletWidth ? (
          // If mobile or tablet, use a single column
          <Grid className='ImageBannerGrid'>
            <Grid.Row className={`ImageBannerRow ${imageBannerAlignment2 === 1 ? 'reversed' : ''}`}>
              <Grid.Column width={16} verticalAlign='middle' className='title'>
                <div
                  dangerouslySetInnerHTML={{ __html: imageBannerComponentTitle?.value }}
                  style={{ ...setTextColor(imageBannerTitleColor) }}
                />
                <Grid.Row>
                  <Grid.Column computer={10} mobile={16} className='subTitle'>
                    <div
                      dangerouslySetInnerHTML={{ __html: imageBannerShortDescription?.value }}
                      style={{ ...setTextColor(imageBannerShortDescriptionColor) }}
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid.Column>
              <Grid.Column width={16} verticalAlign='middle' className='ImageBannerButton'>
                <PrimaryButton
                  text={imageBannerCTA1?.title}
                  className='ImageBannerButton'
                  variant={imageBannerCTAStyle}
                  sendTo={imageBannerCTA1?.uri}
                  fluid={isMobileWidth}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        ) : (
          // If not mobile or tablet, use two columns
          <Grid className='ImageBannerGrid'>
            <Grid.Row columns={2} className={`ImageBannerRow ${imageBannerAlignment2 === 1 ? 'reversed' : ''}`}>
              <Grid.Column computer={10} mobile={16} verticalAlign='middle' className='title'>
                <div
                  dangerouslySetInnerHTML={{ __html: imageBannerComponentTitle?.value }}
                  style={{ ...setTextColor(imageBannerTitleColor) }}
                />
                <Grid.Row>
                  <Grid.Column computer={8} mobile={16} className='subTitle'>
                    <div
                      dangerouslySetInnerHTML={{ __html: imageBannerShortDescription?.value }}
                      style={{ ...setTextColor(imageBannerShortDescriptionColor) }}
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid.Column>
              <Grid.Column computer={6} mobile={16} verticalAlign='middle'>
                {imageBannerCTA1 && (
                  <PrimaryButton text={imageBannerCTA1?.title} variant={imageBannerCTAStyle} sendTo={imageBannerCTA1?.uri} />
                )}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        )}
      </div>
    </div>
  )
}

export default BannerTemplate
