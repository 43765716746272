import React from 'react'
import { Container, ContainerWithFlex, LeftContainer, RightContainer } from './styles/Container.styled'
import importer from '@takedapdt/biolife-core/src/importer'
const { getMediaFromDrupalMimeType, getCssColorFromDrupalColorType, setTextColor } = importer('Constant/Utils', null)
const { useScreenSize } = importer('Contexts/ResponsiveContextProvider', null)
const { sanitizeHtml } = importer('utils/htmlHelpers', null)
const PrimaryButton = importer('Components/Buttons/PrimaryButton')

function TextMediaTemplate5({
  field_component_title: title,
  field_title_color: titleColor,
  field_component_desc: desc,
  field_long_desc_color: descColor,
  field_component_asset: media,
  field_alignment_2: imageAlignment,
  field_asset_link: textMediaAssetLink,
  field_bg_color: textMediaBGColor,
  field_bg_color_1: insideContainerColor
}) {
  const { isMobileWidth } = useScreenSize()
  return (
    <Container bgColor={textMediaBGColor} className=''>
      <Container className='main-container txtMedia5--container'>
        <ContainerWithFlex imageAlignment={imageAlignment} direction={isMobileWidth}>
          <LeftContainer className='txtMedia5--leftContainer' imageAlignment={imageAlignment}>
            {title && title.processed ? (
              <div
                className='txtMedia5--title_text_container'
                dangerouslySetInnerHTML={sanitizeHtml(title.processed)}
                style={{ ...setTextColor(titleColor) }}
              ></div>
            ) : null}
            {desc && desc.processed ? (
              <div
                className='txtMedia5--desc_text_container'
                dangerouslySetInnerHTML={sanitizeHtml(desc.processed)}
                style={{ ...setTextColor(descColor) }}
              ></div>
            ) : null}
          </LeftContainer>
          <RightContainer className='txtMedia5--rightContainer' imageAlignment={imageAlignment}>
            <a target='_blank' href={textMediaAssetLink?.uri}>
              {getMediaFromDrupalMimeType(media?.mimetype, media?.url, media.alt, {}, { className: 'txtMedia5--imagestyle' })}
            </a>
          </RightContainer>
        </ContainerWithFlex>
      </Container>
    </Container>
  )
}

export default TextMediaTemplate5
