import React from 'react'
import {
  ModalContainer,
  ModalOverlay,
  ModalContentWrapper,
  ModalContent,
  ModalHeaderDiv,
  StyledImage,
  PaddingIfNoCross
} from './boxedModal.styled'
import ButtonCloseIcon from '../../../../../assets/media/icons/button-close-large-light-gray.svg'
function BoxedModal(props) {
  const { isOpen, closeFunction, maxWidth, maxHeight, maxWidthMobile, maxHeightMobile, modalContainerStyle, noCloseIcon = false } = props
  if (!isOpen) return null

  return (
    <ModalContainer $userStyles={modalContainerStyle}>
      <ModalOverlay />
      <ModalContentWrapper>
        <ModalContent
          $maxWidth={maxWidth}
          $maxWidthMobile={maxWidthMobile}
          $maxHeight={maxHeight}
          $maxHeightMobile={maxHeightMobile}
          onClick={(event) => event.stopPropagation()}
        >
          {!noCloseIcon ? (
            <ModalHeaderDiv>
              <StyledImage src={ButtonCloseIcon} onClick={closeFunction} />
            </ModalHeaderDiv>
          ) : (
            <PaddingIfNoCross />
          )}
          {props.children}
        </ModalContent>
      </ModalContentWrapper>
    </ModalContainer>
  )
}

export default BoxedModal
