import React, { useEffect, useState } from 'react'
import Modal from '../../Components/Modal'
import styled from 'styled-components'
import { sanitizeHtml } from '../../../src/utils/htmlHelpers'
import { getCssColorFromDrupalColorType, setTextColor } from '../../Constant/Utils'
import { devices, sizes } from '../../../src/utils/styledComponentHelpers'
const DebitHistoryUnavailablePopup = ({ modalContent }) => {
  const PopUpContainer = styled.div`
    width: 49.3rem;
    ${devices.mobile} {
      width: 34.1rem;
      max-height: 35.7rem !important;
    }
  `
  const PopUpTitle = styled.p`
    padding: 4.8rem 7.5rem;
    text-align: center;
    ${(props) => setTextColor(props.$textColor)};
    ${devices.mobile} {
      padding: 4rem 3.3rem;
    }
  `
  const PopUpDescription = styled.p`
    padding: 0.5rem 7.5rem 5.7rem 7.5rem;
    text-align: center;
    ${devices.mobile} {
      padding: 0 3.3rem 5.7rem 3.3rem;
    }
  `
  return (
    <PopUpContainer>
      <PopUpTitle
        dangerouslySetInnerHTML={sanitizeHtml(modalContent?.field_component_title?.processed)}
        $textColor={modalContent?.field_title_color}
      ></PopUpTitle>
      <PopUpDescription style={{}} dangerouslySetInnerHTML={sanitizeHtml(modalContent?.field_component_desc?.processed)}></PopUpDescription>
    </PopUpContainer>
  )
}

export default DebitHistoryUnavailablePopup
