import React from 'react'
import { useTranslation } from 'react-i18next'
import { StyledTimeSlotPickerWrapper, StyledTimeSlotPickerHeader, StyledTimeSlotsColumn, StyledCenterSlotsFailureContainer } from './styled'
import AppointmentHours from '../AppointmentHours'

const TimeSlotPicker = ({ appointment, handleAppointment, availableSlots, centerSlotsFailure, centerSlotsLoading }) => {
  const { t } = useTranslation('DateTimepicker')

  return (
    <StyledTimeSlotPickerWrapper>
        {centerSlotsFailure ? (
          <StyledCenterSlotsFailureContainer>
            <h5>
              <strong>{centerSlotsFailure?.message}</strong>
            </h5>
          </StyledCenterSlotsFailureContainer>
        ) : (
          <>
            {!centerSlotsFailure && <StyledTimeSlotPickerHeader>{t('SelectYourAppointment')}</StyledTimeSlotPickerHeader>}
            <StyledTimeSlotsColumn>
              <AppointmentHours
                appointment={appointment}
                handleAppointment={handleAppointment}
                availableSlots={availableSlots}
                centerSlotsLoading={centerSlotsLoading}
                centerSlotsFailure={centerSlotsFailure}
              />
            </StyledTimeSlotsColumn>
          </>
        )}
    </StyledTimeSlotPickerWrapper>
  )
}

export default TimeSlotPicker
