import React from 'react'
import importer from '@takedapdt/biolife-core/src/importer'
const { getCssColorFromDrupalColorType, setTextColor } = importer('Constant/Utils', null)
const PrimaryButton = importer('Components/Buttons/PrimaryButton')
import './styles.scss'

function FormConfirmation({
  field_component_title: componentTitle = {},
  field_title_color: componentTitleColor = 'rich_black',
  field_cta_1: button1 = {},
  field_cta_1_style: button1Variant = '',
  field_short_desc_color: componentDescColor = 'rich_black',
  field_short_description: componentDesc = {}
}) {
  return (
    <div className='main-container'>
      {componentTitle && componentTitle.processed && (
        <div
          className='form-confirmation-title'
          dangerouslySetInnerHTML={{ __html: componentTitle?.processed }}
          style={{ ...setTextColor(componentTitleColor) }}
        />
      )}
      {componentDesc && componentDesc.processed && (
        <div
          className='form-confirmation-description'
          dangerouslySetInnerHTML={{ __html: componentDesc?.processed }}
          style={{ ...setTextColor(componentDescColor) }}
        />
      )}
      {button1 && button1.title && (
        <div className='buttonContainer'>
          <PrimaryButton variant={button1Variant} text={button1?.title || ''} sendTo={button1?.uri} />
        </div>
      )}
    </div>
  )
}

export default FormConfirmation
