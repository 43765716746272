import styled from 'styled-components'
import { devices } from '../../../utils/styledComponentHelpers'
import { ReactComponent as SuccessIcon } from '../../../../assets/media/icons/sign-up-icon-gradient-ui-success.svg'

export const StyledCancelAppointment = styled.div`
  text-align: center;
  overflow-y: scroll;
  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  max-height: 80vh;
`
export const StyledCancelAppointmentHeader = styled.div`
  margin: 18.8vh 0 1.6rem;
  width: 100%;
  display: flex;
  justify-content: center;

  ${devices.mobile} {
    margin: 6.2rem 0 1.5rem;
  }
`
export const StyledCancelAppointmentContent = styled.div`
  width: 66.4rem;
  margin: 0 8.6rem 36.6rem;

  ${devices.tablet} {
    margin: 0 auto 36.6rem;
  }
  ${devices.mobile} {
    width: 34.3rem;
    margin: 0 1.6rem 40.4rem;
  }
`
export const StyledCancelAppointmentContentTitle = styled.div`
  font-size: var(--h4-font-size);
  color: var(--color-orange);
  padding-bottom: 2.4rem;
`
export const StyledCancelAppointmentContentAppointmentDetails = styled.div``
export const StyledIcon = styled(SuccessIcon)`
  width: 6rem;
  height: 6rem;
`

export const StyledButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
  padding: 1.4rem 24.7rem 4.8rem;
  justify-content: center;
  margin: 0;
  width: 100%;
  position: absolute;
  bottom: 0;
  background: var(--color-white);
  border-bottom-left-radius: 2.4rem;
  border-bottom-right-radius: 2.4rem;
  ${devices.tablet} {
    padding: 1.4rem 16rem 4.8rem;
    gap: 3.2rem;
  }
  ${devices.mobile} {
    padding: 2.4rem 1.5rem 4.8rem;
    gap: 3.2rem;
  }
`
