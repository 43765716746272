import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { isNull } from 'lodash'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { devices } from '../../utils/styledComponentHelpers'
import { getCssColorFromDrupalColorType, getCTAAlignment, setTextColor } from '../../Constant/Utils'
import PublicContentActions from '@takedapdt/biolife-core/src/Stores/PublicContent/Actions'
import PrimaryButton from '../Buttons/PrimaryButton'

const Container = styled.div`
  width: 100.8rem;
  background: ${(props) => getCssColorFromDrupalColorType(props?.$bgColor)};
  margin: auto;

  ${devices.tablet} {
    width: 67rem;
    margin-left: 4.8rem;
  }

  ${devices.mobile} {
    width: 30rem;
    margin: 2.4rem;
  }
`

const CenterCityListContainer = styled.div`
  padding: 5rem 0;
  height: 280rem;

  ${devices.mobile} {
    height: 550rem;
  }

  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
`

const CityLink = styled.p`
  width: 23rem;
  text-decoration: underline;
  margin: 0 0 2rem 0;
  text-decoration-color: $brand-blue;
  padding-right: 2rem;
  ${devices.tablet} {
    width: 16rem;
  }
  ${devices.mobile} {
    width: 16rem;
  }
  * {
    color: var(--color-bl-blue);
    font-family: var(--font-family-bold) !important;
  }
`

const CenterCityList = ({
  centersData,
  getAllCityList,
  field_component_title: componentTitle,
  field_title_color: componentTitleColor,
  field_cta_1: componentCTA,
  field_cta_1_style: componentCTAStyle,
  field_cta_alignment: componentCTAAlignment,
  field_bg_color: componentBackgroundColor
}) => {
  useEffect(() => {
    getAllCityList()
  }, [])

  const [citiesWithStates, setCitiesWithStates] = useState([])

  useEffect(() => {
    const extractedCitiesWithStates = []
    const extractedFacilities = []

    centersData?.map((center) => {
      extractedFacilities.push(center.facilities)
    })

    extractedFacilities.flat()?.map((facility) => {
      moment(facility.publication_date__c) <= moment() &&
        isNull(facility.is_center_hidden_from_donors__c) &&
        extractedCitiesWithStates.push({ city: facility.name, state: facility.state__c })
    })

    const uniqueCitiesWithStates = Array.from(new Set(extractedCitiesWithStates.map(JSON.stringify)), JSON.parse)
    const sortedUniqueCitiesWithStates = uniqueCitiesWithStates.sort((a, b) => a.city.localeCompare(b.city))

    setCitiesWithStates(sortedUniqueCitiesWithStates)
  }, [centersData])

  return (
    <Container>
      <div>
        <div>
          <div dangerouslySetInnerHTML={{ __html: componentTitle?.processed }} style={{ ...setTextColor(componentTitleColor) }} />
          <CenterCityListContainer>
            {citiesWithStates?.map((cityState, index) => (
              <CityLink key={index} className='link'>
                <Link
                  to={`/locations/${cityState.state.toLowerCase().replace(/\s+/g, '-')}/${cityState.city
                    .toLowerCase()
                    .replace(/\s+/g, '-')}`}
                >
                  {cityState.city}
                </Link>
              </CityLink>
            ))}
          </CenterCityListContainer>
          {componentCTA?.title && componentCTA?.uri ? (
            <div style={{ textAlign: getCTAAlignment(componentCTAAlignment) }}>
              <PrimaryButton text={componentCTA?.title} sendTo={componentCTA?.uri} variant={componentCTAStyle} />
            </div>
          ) : null}
        </div>
      </div>
    </Container>
  )
}

const mapStateToProps = (state) => ({
  centersData: state.publicContent?.getCentersSuccess
})

const mapDispatchToProps = (dispatch) => ({
  getAllCityList: () => dispatch(PublicContentActions.getCenters())
})

export default connect(mapStateToProps, mapDispatchToProps)(CenterCityList)
