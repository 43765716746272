import styled from 'styled-components'
import { devices } from '../../../utils/styledComponentHelpers'

export const BrokenScreenMainContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
`

export const ExclamationImage = styled.img`
  height: 4.8rem;
  width: 4.8rem;
  margin-bottom: 2.5rem;
`

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`

export const OopsMessage = styled.h5`
  color: var(--chatbot-Oops-text);
  margin-bottom: 6rem;
`

export const FAQMessage = styled.p`
  color: var(--chatbot-Oops-text);
  margin-bottom: 1.6rem;
  ${devices.mobile} {
    font-size: var(--caption-font-size);
  }
`

export const FAQLink = styled.a`
  font-size: var(--p-font-size);
  color: var(--color-bl-blue);
  text-decoration: underline;
  cursor: pointer;
  &:hover {
    font-size: var(--p-font-size);
    color: var(--color-bl-blue);
    text-decoration: underline;
  }
  ${devices.mobile} {
    font-size: var(--caption-font-size);
  }
`

export const UnderstandingMessage = styled.p`
  color: var(--chatbot-Oops-text);
  ${devices.mobile} {
    font-size: var(--caption-font-size);
  }
`

export const TextWrappingDiv = styled.div`
  padding: 0 10rem;
  ${devices.mobile} {
    padding: 0 5rem;
  }
`
