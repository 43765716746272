import React, { useEffect } from 'react'
import './styles.scss'
import styled, { css } from 'styled-components'
import importer from '@takedapdt/biolife-core/src/importer'

const { useScreenSize } = importer('Contexts/ResponsiveContextProvider', null)
const PrimaryButton = importer('Components/Buttons/PrimaryButton')
const { getCssColorFromDrupalColorType, setTextColor, getMediaFromDrupalMimeType } = importer('Constant/Utils', null)
const { devices, sizes } = importer('utils/styledComponentHelpers', null)
const { sanitizeHtml } = importer('utils/htmlHelpers', null)

const StyledHeroTemplate1 = styled.div`
  background-image: ${(props) =>
    props?.$bgGradientColor
      ? `url(${props?.$bgImage}), ${getCssColorFromDrupalColorType(props?.$bgGradientColor)}`
      : `url(${props?.$bgImage})`};
  background-color: ${(props) => getCssColorFromDrupalColorType(props?.$bgColor)};
  background-position: right;
  background-repeat: no-repeat;
  background-size: cover;
  ${devices.tablet} {
    background-position: bottom;
  }
  ${devices.mobile} {
    background-position: center;
    margin-bottom: ${(props) => props?.$mediaAlignment === 2 && '19.5rem'};
  }
`

const StyledHeroTemplate1Container = styled.div`
  max-width: 1366px;
  margin: auto;
  padding: ${(props) => (props?.$mediaAlignment === 2 ? '1.9rem 8.5rem 2.7rem 17.9rem' : '1.9rem 17.9rem 2.7rem 8.5rem')};
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2.4rem;
  flex-direction: ${(props) => (props?.$mediaAlignment === 2 ? 'row' : 'row-reverse')};
  ${devices.tablet} {
    max-width: 100%;
    width: 100%;
    padding: ${(props) => (props.$mediaAlignment === 2 ? '38px 0px 38px 48px' : '38px 48px 38px 0px')};
    gap: 0.8rem;
  }
  ${devices.mobile} {
    gap: 2.4rem;
    align-items: center;
    padding: 46px 24px;
    flex-direction: ${(props) => (props?.$mediaAlignment === 2 ? 'column' : 'column-reverse')};
  }
`
const StyledHeroTemplate1ContentContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  text-align: left;

  ${devices.mobile} {
    justify-content: center;
    width: 100%;
  }
`

const StyledHeroTemplate1Title = styled.div`
  ${(props) => setTextColor(props?.$textColor)}
`

const StyledHeroTemplate1Description = styled.div`
  ${(props) => setTextColor(props?.$textColor)};
  padding-top: 16px;
`

const StyledHeroTemplate1ButtonColumn = styled.div`
  ${(props) => setTextColor(props?.$textColor)};
  padding-top: 32px;
  display: flex;
  justify-content: flex-start;
  gap: 32px;
  ${devices.mobile} {
    flex-direction: column;
  }
`

const StyledHeroTemplate1ImageContainer = styled.div`
  width: 58.6rem;
  height: 58.6rem;
  ${devices.tablet} {
    width: 35.2rem;
    height: 35.2rem;
  }
  ${devices.mobile} {
    width: 39rem;
    height: ${(props) => (props?.$mediaAlignment === 2 ? '14.9rem' : '39rem')};
  }
`

const Hero = (props) => {
  const {
    field_component_mbg_image: mobileBgImage,
    field_component_bg_image: bgImage,
    field_bg_color: bgColor,
    field_alignment: alignment,
    field_title_color: titleColor,
    field_short_desc_color: shortDescColor,
    field_component_title: componentTitle,
    field_short_description: shortDescription,
    field_cta_1: cta1,
    field_cta_1_style: cta1Style,
    field_cta_2: cta2,
    field_cta_2_style: cta2Style,
    field_component_asset: componentAsset,
    field_component_desc: componentDesc,
    field_long_desc_color: longDescColor
  } = props
  const { isMobileWidth } = useScreenSize()

  return (
    <StyledHeroTemplate1
      className='hero-temp1'
      $bgColor={bgColor !== 'orange_gradient' && bgColor}
      $bgGradientColor={bgColor === 'orange_gradient' && (isMobileWidth ? 'orange_gradient_bottom' : bgColor)}
      $bgImage={isMobileWidth ? mobileBgImage?.url : bgImage?.url}
      $mediaAlignment={alignment}
    >
      <StyledHeroTemplate1Container $mediaAlignment={alignment}>
        <StyledHeroTemplate1ContentContainer className='hero-temp1__content-container'>
          <StyledHeroTemplate1Title
            className='hero-temp1__title'
            $textColor={titleColor}
            dangerouslySetInnerHTML={sanitizeHtml(componentTitle?.processed)}
          />
          <StyledHeroTemplate1Description
            className='hero-temp1__description'
            $textColor={shortDescColor}
            dangerouslySetInnerHTML={sanitizeHtml(shortDescription?.processed)}
          />
          {(cta1 || cta2) && (
            <StyledHeroTemplate1ButtonColumn className='hero-temp1__button-container'>
              <PrimaryButton
                text={cta1?.title}
                className='hero-temp1__primary-btn'
                variant={cta1Style}
                sendTo={cta1?.uri}
                fluid={isMobileWidth}
              />
              <PrimaryButton
                text={cta2?.title}
                className='hero-temp1__secondary-btn'
                variant={cta2Style}
                sendTo={cta2?.uri}
                fluid={isMobileWidth}
              />
            </StyledHeroTemplate1ButtonColumn>
          )}
        </StyledHeroTemplate1ContentContainer>

        <StyledHeroTemplate1ImageContainer className='hero-temp1__img-container' $mediaAlignment={alignment}>
          {getMediaFromDrupalMimeType(componentAsset?.mimetype, componentAsset?.url, componentAsset?.alt)}
        </StyledHeroTemplate1ImageContainer>
      </StyledHeroTemplate1Container>
    </StyledHeroTemplate1>
  )
}

export default Hero
