import styled from 'styled-components'
import { devices } from '../../../utils/styledComponentHelpers'
import { ReactComponent as NextArrow } from '../../../../assets/media/icons/circle-arrow-right.svg'
import { ReactComponent as PrevArrow } from '../../../../assets/media/icons/circle-arrow-left.svg'

export const StyledHorizontalCalendar = styled.div`
  padding: 3.2rem 0;
  background-color: var(--color-smoke-grey);
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
`
export const StyledHorizontalCalendarWrapper = styled.div`
  max-width: 100.8rem;
  margin: 0 auto;
  ${devices.mobile} {
    padding-left: 2.4rem;
    padding-right: 2.4rem;
    max-width: 39rem;
  }
`
export const StyledHorizontalCalendarHeaderText = styled.div`
  font-size: var(--caption-mobile-font-size);
  text-align: center;
`
export const StyledHorizontalCalendarMonth = styled.div`
  font-size: var(--h5-font-size);
  text-align: center;
  margin-top: 0.8rem;
`
export const StyledHorizontalCalendarDatePicker = styled.div`
  margin-top: 1.6rem;
  height: 6.5rem;
  display: grid;
  grid-template-columns: 1fr 7fr 1fr;
  align-items: center;
  justify-items: center;
  ${devices.mobile} {
    margin: 1.6rem -2.4rem 0;
  }
`
export const StyledHorizontalCalendarDateColumn = styled.div`
  display: flex;
  gap: 3.6rem;
  ${devices.mobile} {
    gap: 1.2rem;
  }
`
export const StyledHorizontalCalendarPrevButton = styled.div`
  justify-self: left;
  cursor: pointer;
`
export const StyledHorizontalCalendarNextButton = styled.div`
  justify-self: right;
  cursor: pointer;
`
export const StyledSliderPrevArrow = styled(PrevArrow)`
  width: ${(props) => (!props.$isMobileWeb ? '4.8rem' : '2.8rem')};
  height: ${(props) => (!props.$isMobileWeb ? '4.8rem' : '2.8rem')};
  cursor: pointer;
`
export const StyledSliderNextArrow = styled(NextArrow)`
  width: ${(props) => (!props.$isMobileWeb ? '4.8rem' : '2.8rem')};
  height: ${(props) => (!props.$isMobileWeb ? '4.8rem' : '2.8rem')};
  cursor: pointer;
`
