import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import Modal from '../../Modal'
import AppointmentCenterSearch from '../AppointmentCenterSearch'
import PrimaryButton from '../../Buttons/PrimaryButton'
import { devices } from '../../../utils/styledComponentHelpers'
import { useScreenSize } from '../../../Contexts/ResponsiveContextProvider'
import LoaderBar from '../../Loaders/LoaderBar'
import { ReactComponent as LocationIcon } from '../../../../assets/media/icons/icon-location-new-orange.svg'

export const StyledHeaderContainer = styled.div`
  padding: 6.4rem 0 1.6rem;
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 66.4rem;
  margin: 0 auto;
  ${devices.mobile} {
    width: 34.3rem;
    padding: 0.75rem 0 1.6rem;
  }
  p {
    font-size: var(--caption-mobile-font-size);
  }
  h4 {
    color: var(--color-bl-blue);
    padding: 0.4rem 0;
    font-family: var(--font-family-bold) !important;
    ${devices.mobile} {
      padding-top: 4.5rem;
      font-size: var(--h4-font-size) !important;
    }
  }
  .ui.BlueUnderlineButton.button {
    font-size: var(--caption-font-size) !important;
    margin: 1.6rem 1.6rem 0;
    ${devices.mobile} {
      margin-top: 0.8rem;
    }
  }
`

const AppointmentScheduleHeader = ({
  appointment,
  setAppointment,
  center,
  handleAppointment,
  centers,
  donor,
  isChangeLocationModal,
  setIsChangeLocationModal,
  edit,
  update
}) => {
  const { t } = useTranslation('AppointmentScheduling')
  const [defaultCenterName, setDefaultCenterName] = useState(center ? center.data : '')
  const { isMobileWidth } = useScreenSize()

  const findSelectedObj = (arr, value) => arr?.find((op) => op.sfid === value)

  useEffect(() => {
    let centerID = appointment?.centerSfid || donor?.defaultCenter?.sfid
    if (centerID) {
      const defaultOption = findSelectedObj(centers, centerID)
      if (defaultOption) {
        handleAppointment({
          ...appointment,
          key: defaultOption.sfid,
          zipCode: defaultOption.zipcode,
          name: defaultOption.name,
          text: `${defaultOption?.name} Center ${defaultOption?.zipcode}`,
          address: defaultOption.addressLine1,
          stateCode: defaultOption.stateCode,
          centerName: defaultOption.name,
          centerCity: defaultOption.city,
          centerSfid: defaultOption.sfid,
          telephone: defaultOption.telephone
        })
      }
    }
  }, [centers])

  useEffect(() => {
    setDefaultCenterName(center ? center.data : '')
    handleAppointment({
      ...appointment,
      center: center ? `${center.name} ${t('center')}` : '',
      centerSfid: center ? center.sfid : '',
      time: null
    })
  }, [])

  useEffect(() => {
    setDefaultCenterName(center ? center.data : '')
  }, [center])

  return (
    <>
      <StyledHeaderContainer>
        <p>{t('mySelectedLocation')}</p>
        <h4>
          {defaultCenterName ? (
            <>
              {defaultCenterName?.name}, {defaultCenterName?.stateCode}
            </>
          ) : (
            <LoaderBar />
          )}
        </h4>
        {!(update && isMobileWidth) && (
          <p>
            {defaultCenterName?.addressLine1}, {defaultCenterName?.stateCode} {defaultCenterName?.zipcode}
          </p>
        )}
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          {defaultCenterName?.distanceFromGivenZipcode && (
            <span style={{ display: 'flex', alignItems: 'center', margin: '1.6rem 1.6rem  0 1.6rem' }}>
              <LocationIcon style={{ marginRight: 5, marginBottom: 2.5 }} /> {defaultCenterName?.distanceFromGivenZipcode} {t('miles')}
            </span>
          )}
          {defaultCenterName?.distanceFromGivenZipcode && edit && <span style={{ margin: '1.6rem 0 0 0' }}>|</span>}
          {edit && <PrimaryButton variant='blue_underline' text={t('changeLocation')} onClick={() => setIsChangeLocationModal(true)} />}
        </div>
      </StyledHeaderContainer>

      <Modal
        isOpen={isChangeLocationModal}
        onClose={() => {
          setIsChangeLocationModal(false)
        }}
        maxWidth={836}
        maxHeight={712}
        maxHeightMobile={680}
      >
        <AppointmentCenterSearch
          appointment={appointment}
          handleAppointment={handleAppointment}
          centers={centers}
          donor={donor}
          center={center}
          edit={edit}
          setAppointment={setAppointment}
          setIsChangeLocationModal={setIsChangeLocationModal}
          defaultCenterName={defaultCenterName}
          setDefaultCenterName={setDefaultCenterName}
        />
      </Modal>
    </>
  )
}

const mapStateToProps = (state) => ({
  center: state.centers.getAllCentersSuccess
})

export default connect(mapStateToProps, null)(AppointmentScheduleHeader)
