import React, { useState, useEffect, useRef } from 'react'
import { sortBy } from 'lodash'
import { Helmet } from 'react-helmet'
import { Input, Search, Grid, Icon, Pagination } from 'semantic-ui-react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import './style.scss'
import importer from 'common/importer'
const { Div, NoResultsFoundTitle, FeaturedBackgroundImage, Label, Container, TextComponent, Highlight5, DivWithFlex } = importer(
  'Components/BlogPage/styles/index.styled',
  null
)
const BlogCard = importer('Components/BlogPage/BlogCard')
const PrimaryButton = importer('Components/Buttons/PrimaryButton')
const LoaderSpinner = importer('Components/Loaders/LoaderSpinner')
const PublicContentActions = importer('Stores/PublicContent/Actions')
const { useScreenSize } = importer('Contexts/ResponsiveContextProvider', null)
function NewsPage({ field_cta_1: buttonName = {}, field_cta_1_style: buttonVariant }) {
  const [searchKeyword, setSearchKeyword] = useState('')
  const [searchClicked, setSearchClicked] = useState(false)
  const [noResultFound, setNoResultFound] = useState(false)
  const [searchedBlogs, setSearchedBlogs] = useState([])
  const [allSearchedData, setAllSearchedData] = useState([])
  const [showMore, setShowMore] = useState(false)
  const [selectedOptions, setSelectedOptions] = useState([])
  const [blogsPerPage, setBlogsPerPage] = useState(9)
  const dispatch = useDispatch()
  const { isMobileWidth, isTabletWidth } = useScreenSize()
  const { t } = useTranslation('NewsContainer')
  const getNewsData = (slug = '') => dispatch(PublicContentActions.getNewsPage(slug))
  const newsLoading = useSelector((state) => state.publicContent?.getNewsPageLoading)
  const newsData = useSelector((state) => state.publicContent?.getNewsPageSuccess)
  //   const blogsCategoryData = useSelector((state) => state.publicContent?.blogsCategoryList)
  const usedCategoryfilterData = useRef([])
  useEffect(() => {
    getNewsData()
  }, [])

  useEffect(() => {
    if (newsData && newsData.length) {
      backToInitialState(newsData)
    }
  }, [newsData])

  useEffect(() => {
    // If show more does not depends on filters
    if (searchedBlogs && searchedBlogs.length && showMore) {
      checkIfBothFiltersApplied()

      if (searchKeyword?.length !== 0 && allSearchedData?.length) {
        handlelingNextPage(allSearchedData)
      } else if (searchKeyword?.length === 0) {
        handlelingNextPage(newsData)
      }
    }
  }, [showMore, searchClicked, searchKeyword, allSearchedData, selectedOptions])

  useEffect(() => {
    // if (!searchKeyword?.length) setSearchedBlogs(sortByBasedOnNews())
    if (!searchClicked && searchKeyword?.length === 0) {
      backToInitialState(newsData)
      setAllSearchedData([])
    }
    if (searchClicked && searchKeyword?.length) {
      const blogSearchResults =
        newsData && newsData.length && newsData?.filter((blog) => blog?.title.toLowerCase().includes(searchKeyword.toLowerCase()))

      // Check if the search query has no results
      if (blogSearchResults.length === 0) {
        // Update the searchResult state with all the blogPosts

        setAllSearchedData(newsData)
        setNoResultFound(true)
        if (!showMore) backToInitialState(newsData)
      } else {
        // Update the searchResult state with the filtered posts
        setAllSearchedData(blogSearchResults)
        setNoResultFound(false)
        if (!showMore) backToInitialState(blogSearchResults)
      }
      // setActivePage(1);
      // No pagination in design
    }
  }, [searchClicked, searchKeyword])

  const checkIfBothFiltersApplied = () => {
    if (searchKeyword.length && selectedOptions.length) {
      handleClearFilter({ backToInitialState: false })
    }
  }

  const backToInitialState = (data) => {
    if (data && data.length) {
      setSearchedBlogs(data.slice(0, 9))
      setShowMore(false)
    }
  }

  const handlelingNextPage = (data) => {
    if (data && data.length > blogsPerPage) {
      setSearchedBlogs((prev) => {
        return [...prev, ...data.slice(blogsPerPage, data.length > blogsPerPage + 9 ? blogsPerPage + 9 : data.length)]
      })
      setBlogsPerPage((prev) => prev + 9)
    }

    setShowMore(false)
  }

  const handleSearch = (e, { value }) => {
    setSearchKeyword(value)
    if (value === '' && noResultFound) {
      setNoResultFound(false)
    }
    setSearchClicked(false)
  }

  const handleSearchOnClick = () => {
    setSearchClicked(true)
  }

  const showMoreData = () => {
    setShowMore(!showMore)
  }

  const handleClearFilter = ({ backToInitialState = true }) => {
    setSelectedOptions([])
    backToInitialState && backToInitialState(newsData)
  }

  const renderNoSearchResultsContent = () => {
    return (
      <div className='NoResultsFound'>
        <NoResultsFoundTitle>{t('noResultsTitle')}</NoResultsFoundTitle>
        <TextComponent>{t('noNewsSearchResults')}</TextComponent>
      </div>
    )
  }

  const renderNonewsData = () => {
    return (
      <div className='NoResultsFound'>
        {/* <img src={FrameIcon} alt='frame-icon' /> */}
        <NoResultsFoundTitle>{t('noResultsTitle')}</NoResultsFoundTitle>
        <TextComponent>{t('noNews')}</TextComponent>
      </div>
    )
  }

  const renderBlogPane = () => {
    return (
      <DivWithFlex
        flexWrap='wrap'
        justifyContent={isMobileWidth || isTabletWidth ? 'space-between' : 'normal'}
        columnGap={isMobileWidth || isTabletWidth ? 'normal' : '2.4rem'}
      >
        {(searchedBlogs &&
          searchedBlogs.length &&
          searchedBlogs.map((blog) => {
            return <BlogCard blog={blog} isNews={true} />
          })) ||
          null}
      </DivWithFlex>
    )
  }

  return (
    <>
      {newsLoading ? (
        <LoaderSpinner />
      ) : (
        <>
          <div className='news--container'>
            <div className='main-container'>
              <Container>
                {/* Filter Container */}
                <DivWithFlex justifyContent='flex-end'>
                  <Div
                    maxWidth={isMobileWidth ? '32rem' : isTabletWidth ? '32rem' : '40rem'}
                    flexGrow='1'
                    margin={isMobileWidth && 'auto'}
                    style={{ width: '100%' }}
                  >
                    <Label>
                      <span className='caption-text'>
                        <strong>{t('searchLabel')}</strong>
                      </span>
                    </Label>
                    <Input
                      value={searchKeyword}
                      size='big'
                      fluid
                      onChange={handleSearch}
                      placeholder={t('searchPlaceholder')}
                      // showNoResults={false}
                      onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                          handleSearchOnClick()
                        }
                      }}
                      icon={<Icon name='search' link onClick={handleSearchOnClick} />}
                    />
                  </Div>
                </DivWithFlex>
                <Div margin={isMobileWidth ? '0rem 0rem 8rem 0rem' : '1.6rem 0rem 8rem 0rem'}>
                  {searchKeyword?.length && noResultFound ? renderNoSearchResultsContent() : null}
                  {newsData && !newsData.length ? renderNonewsData() : null}
                  <div>{renderBlogPane()}</div>
                  {!showMore &&
                  searchedBlogs &&
                  (searchClicked || selectedOptions.length
                    ? allSearchedData && (searchedBlogs.length === allSearchedData.length ? false : true) && allSearchedData.length > 9
                    : newsData && (searchedBlogs.length === newsData.length ? false : true) && newsData.length > 9) ? (
                    <Div textAlign='center' margin='3.2rem 0rem 0rem 0rem'>
                      <PrimaryButton
                        variant={buttonVariant || 'outline_black'}
                        text={buttonName?.title || 'Show More'}
                        onClick={() => {
                          showMoreData()
                        }}
                      />
                    </Div>
                  ) : null}
                </Div>
              </Container>
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default NewsPage
