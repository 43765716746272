import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import AppointmentDetails from './ApointmentDetails'
import renderDrupalComponent from '../../../Constant/renderDrupalComponent'
import LoaderSpinner from '../../../Components/Loaders/LoaderSpinner'
import PageNotFound from '../../../Components/PageNotFound'
import { ROUTES } from '../../../NavigationService/'
const AppointmentDetailsContainer = ({ componentList, dashboardMetadata }) => {
  let navigate = useNavigate()
  const location = useLocation()
  const { state } = location
  const [loading, setLoading] = useState(!(state && Object.keys(state).length) ? true : false)
  const [redirectTo, setRedirectTo] = useState(false)
  const [appointmentState, setAppointmentState] = useState(state || {})
  useEffect(() => {
    if (window.location.pathname !== ROUTES.DebitHistory) {
      sessionStorage.removeItem('filterOnDebit')
    }
    if (window.location.pathname !== ROUTES.PaymentHistory) {
      sessionStorage.removeItem('filterOnPayment')
    }
  }, [window.location.pathname])
  useEffect(() => {
    setLoading(true)
    if (!appointmentState || (appointmentState && !appointmentState.centerSfid)) setRedirectTo(true)
    window.scrollTo(0, 0)
  }, [appointmentState])
  useEffect(() => {
    if (redirectTo) {
      navigate(ROUTES && ROUTES.DonorDashboard)
    }
  }, [redirectTo, navigate])
  useEffect(() => {
    if (appointmentState && Object.keys(appointmentState).length) {
      setLoading(false)
    }
  }, [appointmentState])
  return (
    <>
      {loading ? (
        <LoaderSpinner />
      ) : componentList && componentList.length ? (
        <>
          {componentList?.map((component, index) => {
            if (component?.type === 'breadcrumb') {
              let tempComp = {
                ...component,
                breadcrumbToggle: dashboardMetadata?.field_content_breadcrumb,
                breadcrumbMobileToggle: dashboardMetadata?.field_mobile_breadcrumb,
                clickableLevelTwo: true
              }
              component = tempComp
            } else if (component?.type === 'ext_integration_component' && component?.field_integration_type === 'appointment_detail') {
              return <AppointmentDetails componentList={componentList} key={index} {...component} />
            }
            return renderDrupalComponent(component, index)
          })}
        </>
      ) : (
        <PageNotFound />
      )}
    </>
  )
}

export default AppointmentDetailsContainer
