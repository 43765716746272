import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
import styled from 'styled-components'
import importer from '@takedapdt/biolife-core/src/importer'
const PublicContentActions = importer('Stores/PublicContent/Actions')
const renderDrupalComponent = importer('Constant/renderDrupalComponent')
const LoaderSpinner = importer('Components/Loaders/LoaderSpinner')
const { scrollToTop } = importer('Helpers/Utils', null)

const CheckEmailComponent = ({ slug_value, classname, fetchPageData, formsPageLoading, formsPageSuccess }) => {
  useEffect(() => {
    fetchPageData(slug_value)
    scrollToTop();
  }, [slug_value])

  let pageData = formsPageSuccess?.[slug_value]
  let pageLaoding = formsPageLoading?.[slug_value]

  const CheckEmailContainer = styled.div`
    &.ForgotPassword {
      .form-confirmation-description {
        max-width: 492px;
      }
    }
    .form-confirmation-description {
      max-width: 406px;
    }
    .buttonContainer {
      margin-top: 32px;
    }
  `

  return (
    <>
      {pageLaoding ? (
        <LoaderSpinner />
      ) : (
        pageData?.contents && (
          <CheckEmailContainer className={classname}>
            <Helmet>
              <title>{`${pageData?.metadata?.meta_title}`} </title>
              <meta name='description' content={`${pageData?.metadata?.meta_description}`} />
              <link rel='canonical' href={`${window.location.origin}${window.location.pathname.toLowerCase()}`} />
            </Helmet>
            {pageData?.contents.map((component, index) => {
              if (component.type === 'breadcrumb') {
                let tempComp = {
                  ...component,
                  breadcrumbToggle: pageData?.metadata?.field_content_breadcrumb,
                  breadcrumbMobileToggle: pageData?.metadata?.field_mobile_breadcrumb
                }
                component = tempComp
              }
              return renderDrupalComponent(component, index)
            })}
          </CheckEmailContainer>
        )
      )}
    </>
  )
}

const mapStateToProps = (state) => ({
  formsPageSuccess: state.publicContent?.getFormsPageSuccess,
  formsPageLoading: state.publicContent?.getFormsPageLoading
})

const mapDispatchToProps = (dispatch) => ({
  fetchPageData: (formType) => dispatch(PublicContentActions.getFormsPage(formType))
})

export default connect(mapStateToProps, mapDispatchToProps)(CheckEmailComponent)
