import React from 'react'
import { Grid } from 'semantic-ui-react'
import styled, { css } from 'styled-components'
import importer from 'common/importer'
const PrimaryButton = importer('Components/Buttons/PrimaryButton')
const { getMediaFromDrupalMimeType, getCssColorFromDrupalColorType, setTextColor } = importer('Constant/Utils', null)
const { useScreenSize } = importer('Contexts/ResponsiveContextProvider', null)
const { devices, sizes } = importer('utils/styledComponentHelpers', null)
const { sanitizeHtml } = importer('utils/htmlHelpers', null)

const StyledGridTemplate4 = styled.div`
  background: ${(props) => getCssColorFromDrupalColorType(props.$bgColor)};
  display: flex;
  padding: 80px 0 112px;
  text-align: center;
  border-bottom-left-radius: 200px;
  border-bottom-right-radius: 200px;
  ${devices.tablet} {
    padding: 64px 0 112px;
  }
  ${devices.mobile} {
    border-bottom-left-radius: 125px;
    border-bottom-right-radius: 125px;
    padding: 64px 0 112px;
  }
`

const StyledGridTemplate4Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${devices.tablet} {
    grid: auto / repeat(8, 1fr);
  }
  ${devices.mobile} {
    grid: auto / repeat(4, 1fr);
  }
`

const StyledGridTemplate4Title = styled.div`
  ${(props) => setTextColor(props.$textColor)};
  padding-bottom: 1.6rem;
  text-align: ${(props) => (props.$alignment === 'left' ? 'left' : props.$alignment === 'right' ? 'right' : 'center')};
  align-self: ${(props) => (props.$alignment === 'left' ? 'flex-start' : props.$alignment === 'right' ? 'flex-end' : 'center')};
`
const StyledGridTemplate4Description = styled.div`
  ${(props) => setTextColor(props.$textColor)};
  padding: ${(props) => (props.$alignment === 'center' ? '0 17.2rem' : '0')};
  text-align: ${(props) => (props.$alignment === 'left' ? 'left' : props.$alignment === 'right' ? 'right' : 'center')};
  align-self: ${(props) => (props.$alignment === 'left' ? 'flex-start' : props.$alignment === 'right' ? 'flex-end' : 'center')};
  ${devices.tablet} {
    padding: 0;
  }
  ${devices.mobile} {
    padding: 0;
  }
`
const StyledGridTemplate4PointsContainer = styled.div`
  padding: 4.8rem 0;
  display: grid;
  grid: auto / repeat(${(props) => props.$pointsLength}, 1fr);
  gap: 2.4rem;
  ${devices.tablet} {
    grid: ${(props) => (props.$pointsLength < 4 ? `auto / repeat(${props.$pointsLength}, 1fr)` : 'auto / repeat(2, 1fr)')};
    padding: 3.2rem 0;
  }
  ${devices.mobile} {
    grid: ${(props) => (props.$pointsLength < 4 ? `auto / repeat(1, 1fr)` : 'auto / repeat(2, 1fr)')};
    padding: 3.2rem 0;
  }
`
const StyledGridTemplate4Points = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: ${(props) => (props.$alignment === 'left' ? 'flex-start' : props.$alignment === 'right' ? 'flex-end' : 'center')};
  text-align: ${(props) => (props.$alignment === 'left' ? 'left' : props.$alignment === 'right' ? 'right' : 'center')};
  height: fit-content;
`
const StyledGridTemplate4PointsIcon = styled.div`
  margin-bottom: 1.6rem;
  width: 120px;
  height: 120px;
  & img {
    width: 100%;
    height: 100%;
  }
`
const StyledGridTemplate4PointsTitle = styled.div`
  ${(props) => setTextColor(props.$textColor)};
`
const StyledGridTemplate4ButtonContainer = styled.div`
  padding-top: 1.2rem;
`

const GridTemplate4 = (props) => {
  const {
    field_column_1_icon: gridColumn1Icon,
    field_column_1_title: gridColumn1Title,
    field_column_2_icon: gridColumn2Icon,
    field_column_2_title: gridColumn2Title,
    field_column_3_icon: gridColumn3Icon,
    field_column_3_title: gridColumn3Title,
    field_column_4_icon: gridColumn4Icon,
    field_column_4_title: gridColumn4Title,
    field_component_desc: componentLongDescription,
    field_column_title_color: gridColumnTitleColor,
    field_component_title: componentTitle,
    field_title_color: componentTitleColor,
    field_short_description: componentShortDescription,
    field_long_desc_color: componentLongDescriptionColor,
    field_short_desc_color: componentShortDescriptionColor,
    field_cta_1: componentButton,
    field_cta_1_style: componentButtonStyle,
    field_bg_color: componentBackgroundColor,
    field_text_alignment: gridAlignment,
  } = props

  const { isMobileWidth, isTabletWidth } = useScreenSize()

  const GridPoints = [
    gridColumn1Title && {
      gridColumnIcon: gridColumn1Icon,
      gridColumnTitle: gridColumn1Title
    },
    gridColumn2Title && {
      gridColumnIcon: gridColumn2Icon,
      gridColumnTitle: gridColumn2Title
    },
    gridColumn3Title && {
      gridColumnIcon: gridColumn3Icon,
      gridColumnTitle: gridColumn3Title
    },
    gridColumn4Title && {
      gridColumnIcon: gridColumn4Icon,
      gridColumnTitle: gridColumn4Title
    }
  ]

  const PointsGridContainer = (gridColumnIcon, gridColumnTitle) => {
    return (
      <StyledGridTemplate4Points $alignment={gridAlignment}>
        {gridColumnIcon?.url && <StyledGridTemplate4PointsIcon>
          {getMediaFromDrupalMimeType(gridColumnIcon?.mimetype, gridColumnIcon?.url, gridColumnIcon?.alt)}
        </StyledGridTemplate4PointsIcon>}
        <StyledGridTemplate4PointsTitle
          dangerouslySetInnerHTML={sanitizeHtml(gridColumnTitle?.processed)}
          $textColor={gridColumnTitleColor}
        />
      </StyledGridTemplate4Points>
    )
  }

  return (
    <StyledGridTemplate4 $bgColor={componentBackgroundColor} className='grid-template4'>
      <div className='main-container'>
        <StyledGridTemplate4Container className='grid-template4__container'>
          {componentTitle && (
            <StyledGridTemplate4Title
              $textColor={componentTitleColor}
              dangerouslySetInnerHTML={sanitizeHtml(componentTitle?.processed)}
              className='grid-template4__title'
              $alignment={gridAlignment}
            />
          )}
          {componentShortDescription && (
            <StyledGridTemplate4Description
              $textColor={componentShortDescriptionColor}
              dangerouslySetInnerHTML={sanitizeHtml(componentShortDescription?.processed)}
              className='grid-template4__description'
              $alignment={gridAlignment}
            />
          )}
          {(gridColumn1Title || gridColumn2Title || gridColumn3Title || gridColumn4Title) && (
            <StyledGridTemplate4PointsContainer
              $pointsLength={GridPoints.filter(Boolean).length}
              className='grid-template4__points-container'
            >
              {GridPoints?.map((item) => item && PointsGridContainer(item?.gridColumnIcon, item?.gridColumnTitle))}
            </StyledGridTemplate4PointsContainer>
          )}
          {componentLongDescription && (
            <StyledGridTemplate4Description
              $textColor={componentLongDescriptionColor}
              dangerouslySetInnerHTML={sanitizeHtml(componentLongDescription?.processed)}
              className='grid-template4__bottom-description'
              $alignment={gridAlignment}
            />
          )}
          {componentButton && (
            <StyledGridTemplate4ButtonContainer className='grid-template4__button'>
              <PrimaryButton
                text={componentButton?.title}
                variant={componentButtonStyle}
                sendTo={componentButton?.uri}
                fluid={isMobileWidth}
              />
            </StyledGridTemplate4ButtonContainer>
          )}
        </StyledGridTemplate4Container>
      </div>
    </StyledGridTemplate4>
  )
}

export default GridTemplate4
