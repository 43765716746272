import React, { useEffect } from 'react'
import { Grid } from 'semantic-ui-react'
import importer from '@takedapdt/biolife-core/src/importer'
import styled from 'styled-components'
import { Link, useNavigate } from 'react-router-dom'

const { devices, sizes } = importer('utils/styledComponentHelpers', null)
const { sanitizeHtml } = importer('utils/htmlHelpers', null)
const { useScreenSize } = importer('Contexts/ResponsiveContextProvider', null)
const PrimaryButton = importer('Components/Buttons/PrimaryButton')
const { getCssColorFromDrupalColorType, getMediaFromDrupalMimeType } = importer('Constant/Utils', null)

const StyledImageComp = styled.div`
  background-color: ${(props) => getCssColorFromDrupalColorType(props.$bgColor)};
`

const StyledImage = styled.img`
  aspect-ratio: auto;
  object-fit: contain;
  object-position: center;
  max-width: 100%;
  ${devices.tablet} {
    object-fit: cover;
    max-width: 100%;
    height: 100%;
  }
  ${devices.mobile} {
    object-fit: cover;
    max-width: 100%;
    height: 100%;
  }
`

const StyledDiv = styled.div`
  text-align: ${(props) => props.$alignment};
  cursor: ${(props) => props.$isclickable && 'pointer'};
`

const ImageComponent = (props) => {
  const navigate = useNavigate()

  const handleAssetLinkRedirection = (assetLink) => {
    if (!assetLink) {
      return
    }
    if (assetLink?.includes('internal:')) {
      // internal redirection
      navigate(assetLink.split(':')[1])
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    } else {
      // opens a new tab
      window.open(assetLink, '_blank')
    }
  }
  const {
    field_component_image: componentImage,
    field_custom_id: CustomID,
    field_text_alignment: alignment,
    field_asset_link: link,
    field_bg_color: bgColor
  } = props
  return (
    <StyledImageComp $bgColor={bgColor}>
      <div className='main-container'>
        <StyledDiv $alignment={alignment} $isclickable={componentImage?.url} onClick={() => handleAssetLinkRedirection(link?.uri)}>
          <StyledImage src={componentImage?.url} alt={componentImage?.alt} className='gridImage' />
        </StyledDiv>
      </div>
    </StyledImageComp>
  )
}
export default ImageComponent
