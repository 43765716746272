import React, { useLayoutEffect, useState, useRef, useEffect } from 'react'
import styled from 'styled-components'
import Slider from 'react-slick'
import './styles.scss'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { Image } from 'semantic-ui-react'
import importer from '@takedapdt/biolife-core/src/importer'
import { connect } from 'react-redux'
import { getCssColorFromDrupalColorType, setTextColor } from '../../../Constant/Utils'
const { useScreenSize } = importer('Contexts/ResponsiveContextProvider', null)
const { devices, sizes } = importer('utils/styledComponentHelpers', null)
const { sanitizeHtml } = importer('utils/htmlHelpers', null)
import { ReactComponent as NextArrow } from '../../../../assets/media/icons/circle-arrow-right.svg'
import { ReactComponent as PrevArrow } from '../../../../assets/media/icons/circle-arrow-left.svg'
const CustomModal = importer('Components/CustomModal')
const { renderModalComponent } = importer('Constant/Utils', null)
const PublicContentActions = importer('Stores/PublicContent/Actions')
const AchievementsContainer = styled.div`
  background-color: ${(props) => getCssColorFromDrupalColorType(props?.$myAchievementsBGColor)};
  padding: 20px;
  text-align: center;
  ${devices.desktop} {
    padding: 64px 95px 60px 64px;
  }
  ${devices.mobile} {
    padding: 64px 0px;
    height: auto;
  }
  height: 499px;
`
const Title = styled.div`
  padding-bottom: 10px;
  ${devices.mobile} {
    text-align: left;
  }
`
const CarouselContainer = styled.div`
  padding-top: 30px;
`
const Arrow = styled.div`
  cursor: pointer;
`
const Achievement = styled.div`
  margin: 0 15px;
  width: auto !important;
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
const PopUpButton = styled.a`
  text-decoration: underline;
  cursor: pointer;
  color: var(--color-bl-blue);
  font-size: 1.8rem;
  &:hover {
    color: var(--color-bl-blue);
    cursor: pointer;
  }
  ${devices.mobile} {
    text-align: left;
    display: block;
  }
`
const SliderNextArrow = ({ className, onClick }) => {
  return <NextArrow width='100' height='100' className={className} onClick={onClick} />
}

const SliderPrevArrow = ({ className, onClick }) => {
  return <PrevArrow width='50' height='50' className={className} onClick={onClick} />
}

const MyAchievements = ({
  loyaltyAchievementBadges,
  loyaltyUserData,
  field_component_title: myAchievementsComponentTitle,
  field_title_color: myAchievementsTitleColor,
  field_integration_type: myAchievementsIntegrationType,
  field_bg_color: myAchievementsBGColor,
  donor,
  PostLoginPageData,
  getLoyaltyAchievementBadges,
  field_cta_1: popUpCTA
}) => {
  const [loyaltyAchievementsBadges, setLoyaltyAchievementsBadges] = useState([])
  const { isMobileWidth, isTabletWidth } = useScreenSize()
  const slider = useRef(null)

  useLayoutEffect(() => {
    const userBadgesArray = loyaltyUserData?.loyaltyBadges?.split(',')?.reverse()
    const transformedBadgesArray = []
    const sortingBadgesArray = []
    userBadgesArray?.map((userBadge) => {
      const badge = loyaltyAchievementBadges?.find((item) => item.bll_achievement_badge_code__c === userBadge)
      if (badge) {
        transformedBadgesArray.push({ ...badge, achieved: true })
      }
    })
    loyaltyAchievementBadges?.map((item) => {
      if (item.bll_active__c && !userBadgesArray?.includes(item.bll_achievement_badge_code__c)) {
        sortingBadgesArray.push({ ...item, achieved: false })
      }
    })
    setLoyaltyAchievementsBadges([...transformedBadgesArray, ...sortingBadgesArray])
  }, [loyaltyAchievementBadges, loyaltyUserData])
  useEffect(() => {
    if (!loyaltyAchievementBadges) {
      getLoyaltyAchievementBadges()
    }
  }, [])
  var settings = {
    dots: true,
    intialSlide: 1,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    arrows: isMobileWidth || isTabletWidth ? false : true,
    nextArrow: <SliderNextArrow />,
    prevArrow: <SliderPrevArrow />,
    responsive: [
      {
        breakpoint: 1422,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 1366,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 1080,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      }
    ]
  }
  const [openModal, setOpenModal] = useState(false)
  const [data, setData] = useState(null)
  const handleOpen = (type) => {
    setData(PostLoginPageData.find((item) => item.field_poup_type === type))
    setOpenModal(true)
  }
  /* 
  checking for if donor default center is loyalty program enabled or not 
*/
  const isLoyaltyEnabled = donor?.defaultCenter?.isLoyaltyProgramEnabled || false
  return (
    <>
      {donor?.isLoyaltyMember === 'Yes' && (isLoyaltyEnabled || !isLoyaltyEnabled) && myAchievementsIntegrationType === 'my_achievements' && (
        <>
          <AchievementsContainer $myAchievementsBGColor={myAchievementsBGColor}>
            <div className='main-container'>
              <Title $textColor={myAchievementsTitleColor} dangerouslySetInnerHTML={sanitizeHtml(myAchievementsComponentTitle?.value)} />
              <PopUpButton onClick={() => handleOpen('my_achievements')}>{popUpCTA?.title}</PopUpButton>

              <CarouselContainer>
                <Slider ref={slider} className='customSlider' {...settings}>
                  {loyaltyAchievementsBadges
                    ?.filter((item) => item.bll_active__c === true)
                    ?.map((item) => (
                      <Achievement className='badgeContainer' key={item.id}>
                        {item.achieved}
                        <Image
                          className='badgeImage'
                          src={item?.bll_badge_icon_url__c}
                          alt={item?.bll_achievement_badge_title__c || 'badge'}
                          style={{ filter: item.achieved ? '' : 'grayscale(1)', opacity: item.achieved ? 1 : 0.5, width: 120, height: 120 }}
                        />
                        <strong className='badgeName'>{item.bll_achievement_badge_title__c}</strong>
                      </Achievement>
                    ))}
                </Slider>
              </CarouselContainer>
            </div>
          </AchievementsContainer>

          <CustomModal
            header={
              <div
                dangerouslySetInnerHTML={{ __html: data?.field_component_title?.processed }}
                style={{ ...setTextColor(data?.field_title_color) }}
              />
            }
            description={
              <div
                dangerouslySetInnerHTML={{ __html: data?.field_component_desc?.processed }}
                style={{ ...setTextColor(data?.field_long_desc_color) }}
              />
            }
            openModal={openModal}
            onClose={() => setOpenModal(false)}
          >
            {renderModalComponent(data?.field_poup_type)}
          </CustomModal>
        </>
      )}
    </>
  )
}
const mapStateToProps = (state) => ({
  donor: state.donors?.donors?.data,
  loyaltyAchievementBadges: state.publicContent?.getLoyaltyAchievementBadgesSuccess,
  loyaltyUserData: state.loyalty.getLoyaltyDetails?.data,
  PostLoginPageData: state.publicContent?.getPostLoginPageSuccess?.contents
})

const mapDispatchToProps = (dispatch) => ({
  getLoyaltyAchievementBadges: () => dispatch(PublicContentActions.getLoyaltyAchievementBadges())
})

export default connect(mapStateToProps, mapDispatchToProps)(MyAchievements)
