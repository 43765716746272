import styled from 'styled-components'
import { devices } from '../../../utils/styledComponentHelpers'
import { ReactComponent as Calendar } from '../../../../assets/media/icons/icon-calendar.svg'
import { ReactComponent as Clock } from '../../../../assets/media/icons/icon-clock-orange.svg'

export const StyledDetailsConfirmationModal = styled.div`
  text-align: center;
  overflow-y: scroll;
  max-height: 100vh;
  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  padding-bottom: 23.6rem;
  ${devices.mobile} {
    padding-bottom: 0rem;
  }
`
export const StyledDetailsConfirmationModalHeaderColumn = styled.div`
  padding: 3.2rem 0 1rem;
  z-index: 6;
  background: var(--color-white);
  border-top-left-radius: 2.4rem;
  border-top-right-radius: 2.4rem;
  position: absolute;
  top: 0;
  width: 100%;

  ${devices.mobile} {
    padding: 0;
  }
`
export const StyledDetailsConfirmationModalHeader = styled.div`
  margin: 3.2rem auto 0;
  border-radius: 0.4rem;
  background: var(--color-medium-shade-gray);
  font-size: var(--caption-mobile-font-size);
  padding: 0.4rem 0.8rem;
  width: fit-content;

  ${devices.mobile} {
    margin: 6.2rem auto 0;
  }
`
export const StyledDetailsConfirmationModalContent = styled.div`
  width: 66.4rem;
  margin: 18.8rem 8.6rem 0;
  text-align: center;
  ${devices.tablet} {
    margin: 18.8rem auto 0;
  }
  ${devices.mobile} {
    width: 34.3rem;
    margin: 13.4rem 1.5rem 28.3rem;
  }
`
export const StyledDetailsConfirmationModalContentTitle = styled.div`
  font-size: var(--caption-mobile-font-size);
`
export const StyledDetailsConfirmationModalContentCenter = styled.div`
  font-size: var(--h4-font-size);
  color: var(--color-bl-blue);
  padding-top: 1.6rem;
  padding-bottom: 0.8rem;
`
export const StyledDetailsConfirmationModalContentDateTime = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.8rem;
  justify-content: center;
  align-items: center;
  padding-top: 0.8rem;
  font-size: var(--h5-font-size);
`
export const StyledDetailsConfirmationModalContentDateTimeButton = styled.div`
  padding-top: 2.4rem;
`
export const StyledDetailsConfirmationModalButton = styled.div`
  z-index: 6;
  background: var(--color-white);
  margin: 0;
  border-bottom-left-radius: 2.4rem;
  border-bottom-right-radius: 2.4rem;
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 2.4rem 1.5rem 4.8rem;
`
export const StyledDetailsConfirmationModalFailure = styled.div`
  padding-bottom: 2rem;
`
export const StyledCalendarIcon = styled(Calendar)`
  width: 1.6rem;
  height: 1.6rem;
`
export const StyledClockIcon = styled(Clock)`
  width: 1.6rem;
  height: 1.6rem;
`
