import React from 'react'
import { Grid } from 'semantic-ui-react'
import importer from '@takedapdt/biolife-core/src/importer'
const { useScreenSize } = importer('Contexts/ResponsiveContextProvider', null)
const SignupForm = importer('Components/RegistrationFormComponent/SignupForm')
const { COMMONVIEW_TYPES } = importer('Components/RegistrationFormComponent/utility', null)
const CommonViews = importer('Components/RegistrationFormComponent/CommonViews')

const OtherView = (props) => {
  // View is for Mobile And the Tablet
  const { isMobileWidth } = useScreenSize()
  const {
    field_component_title: componentTitle,
    field_title_color: titleColor,
    field_short_description: shortDescription,
    field_short_desc_color: shortDescColor,
    field_component_asset: componentAsset,
    field_form_label: fieldLabel,
    field_component_mb_asset: mobileAsset,
    field_cta_1: cta = {},
    field_cta_1_style: ctaStyle = ''
  } = props
  const fieldLabelValues =
    fieldLabel &&
    fieldLabel.map((label) => {
      const value = label.split('|')
      return { validation: value[1], label: value[0] }
    })
  return (
    <Grid className='gridStyling'>
      <Grid.Row columns={2} className='gridRow'>
        <Grid.Column tablet={16} mobile={16} className='gridColumnStyle'>
          <div className='notDesktopStyle'>
            <div className='textColumn'>
              {CommonViews({
                type: COMMONVIEW_TYPES.COMMON_TEXT_COMPONENT,
                contentData: {
                  processedText: componentTitle?.processed,
                  field_color: titleColor
                },
                styleName: 'gridTitle'
              })}
              {CommonViews({
                type: COMMONVIEW_TYPES.COMMON_TEXT_COMPONENT,
                contentData: {
                  processedText: shortDescription?.processed,
                  field_color: shortDescColor
                },
                styleName: 'gridTitleDesc'
              })}
            </div>
            <SignupForm fieldLabel={fieldLabelValues} buttonStyle={{ cta, ctaStyle }} />
          </div>
          {CommonViews({
            type: COMMONVIEW_TYPES.SIGNUP_IMG_COMP,
            contentData: isMobileWidth ? mobileAsset || componentAsset : componentAsset,
            styleName: 'Image'
          })}
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}

export default OtherView
