import React, { useState, useEffect } from 'react'
import { Form, Message, Label } from 'semantic-ui-react'
import { Form as FinalForm, Field } from 'react-final-form'
import { useNavigate, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import './styles.scss'
import validate from './validate'
/* DYNAMIC IMPORTS */
import importer from '@takedapdt/biolife-core/src/importer'
import styled from 'styled-components'
// import { openCookieSettingsModal } from '../../../Helpers/Utils'
/* Actions */

const UserActions = importer('Stores/Users/Actions')
/* COMPONENTS */
const InputField = importer('Components/Forms/FormFields/InputField')
const PrimaryButton = importer('Components/Buttons/PrimaryButton')
const LoaderSpinner = importer('Components/Loaders/LoaderSpinner')
// const LoadingState = importer('Components/Dashboard/StateLoadingContainer')
const { useScreenSize } = importer('Contexts/ResponsiveContextProvider', null)
const { scrollToTop } = importer('Helpers/Utils', null)
const { ROUTES } = importer('NavigationService', null)
const CustomModal = importer('Components/CustomModal')
const Config = importer('Config')

const StyledCenterOpsModalHeader = styled.h4`
  color: var(--color-bl-blue);
`

const StyledCenterOpsModalBody = styled.p`
  color: var(--color-rich-black);
  text-align: center;
  & a:link,
  a:active,
  a:visited {
    color: var(--color-orange);
    text-decoration: underline;
  }
  & a:hover {
    color: var(--color-orange-hover);
  }
`

const initialData = {
  'Email Address': '',
  Password: ''
}
const LogInForm = (props) => {
  const {
    loggedInUser,
    session,
    signIn,
    signInLoading,
    loginError,
    logoutTimeout,
    migrateLoading,
    loginLoading,
    migrateDonor,
    migrateError,
    migrateDonorLoading,
    migrateDonorSuccess,
    migrateDonorError,
    resetMigrationError,
    resetLoginError
  } = props
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [loginClicked, setLoginClicked] = useState(false)
  const [isMigrationError, setMigrationError] = useState(false)
  const [origin, setOrigin] = useState(null)
  const [centerOpsModalOpen, setCenterOpsModalOpen] = useState(false)
  const navigate = useNavigate()
  const encodedEmail = encodeURIComponent(Buffer.from(String(email)).toString('base64'))
  const encodedPassword = encodeURIComponent(Buffer.from(String(password)).toString('base64'))
  const location = useLocation()
  const [passwordView, setPasswordView] = useState(false)
  const [showInactiveMessage, setShowInactiveMessage] = useState(false)
  const [migrationInitiated, setMigrationInitiated] = useState(false)
  const { isMobileWidth } = useScreenSize()
  const fieldsWidths = isMobileWidth ? '90%' : '93%'
  const { t } = useTranslation(['LogInForm', 'centerOps'])

  /* 
  Scroll to the top when the component mounts
  */
  useEffect(() => {
    scrollToTop()
  }, [])
  /*
  Effect to handle scenarios after login
  Navigating to the appropriate dashboard based on the user's role
  */
  useEffect(() => {
    if (session && loggedInUser) {
      const route =
        session.role === 'Donor' ? ROUTES.DonorDashboard : session.role === 'SuperAdmin' ? ROUTES.DonorDashboard : ROUTES.CenterDashboard
      navigate(route)
    }
    return function cleanup() {
      setLoginClicked(false)
      resetLoginError()
      resetMigrationError()
    }
  }, [])
  /*
  Effect to handle login errors
  Checking for specific error code and initiating donor migration if needed
  */
  useEffect(() => {
    if (loginError && loginError.response && loginError.response.data) {
      if (loginClicked && loginError.response.data.code === 'DonorNotMigrated') {
        setMigrationError(true)
        migrateDonor(email, password)
      }
    }
  }, [loginError])
  /*
  Handling navigation based on migration error code & success code
  Function to handle navigation based on error code
  */
  useEffect(() => {
    if (loginClicked && migrateDonorError && migrateDonorError.response && migrateDonorError.response.data) {
      handleNavigation(migrateDonorError.response.data.code)
    }
  }, [migrateDonorError])

  useEffect(() => {
    if (loginClicked && migrateDonorSuccess && migrateDonorSuccess.data) {
      handleNavigation(migrateDonorSuccess.data.code)
    }
  }, [migrateDonorSuccess])

  const handleNavigation = (code) => {
    switch (code) {
      case 'MigrationSuccessful':
        navigate('/account-setup-success')
        break
      case 'NonOktaStandardPassword':
        navigate(`/change-password/${email}`)
        break
      case 'NonOktaStandardLoginId':
        navigate(`/login-with-email/${encodedEmail}/${encodedPassword}`)
        break
    }
  }

  const centerOpsModalHeader = (
    <StyledCenterOpsModalHeader>
      <strong>{t('centerOps:modalHeader')}</strong>
    </StyledCenterOpsModalHeader>
  )
  const centerOpsModalBody = (
    <StyledCenterOpsModalBody>
      {t('centerOps:modalBody')}
      <br />
      <a href={Config.CENTEROPS_URL} alt='centerops login message modal' target='_self'>
        {Config.CENTEROPS_URL}
      </a>
      .
    </StyledCenterOpsModalBody>
  )

  /*
  Form submission handler
  Trim email and set local state
  Initiate sign-in process and added Function to toggle password visibility
  handled various states related to logout
  */
  const handleSubmit = ({ 'Email Address': email, Password: password }) => {
    resetLoginError()
    resetMigrationError()
    if (origin) {
      sessionStorage.setItem('authOrigin', origin)
    } else if (sessionStorage.getItem('authOrigin')) {
      sessionStorage.removeItem('authOrigin')
    }

    const trimmedEmail = email?.replace(/\s+/g, '')
    setEmail(trimmedEmail)
    setPassword(password)
    setLoginClicked(true)
    if (trimmedEmail?.endsWith('@takeda.com')) {
      setCenterOpsModalOpen(true)
      return false
    }
    signIn(trimmedEmail, password)
    window.sessionStorage.removeItem('logoutTimeOut')
  }

  useEffect(() => {
    setMigrationInitiated(false)
    setShowInactiveMessage(window.sessionStorage.getItem('logoutTimeOut'))
    window.addEventListener('beforeunload', function(e) {
      if (window.sessionStorage.getItem('logOutFlow')) {
        window.sessionStorage.removeItem('logOutFlow')
      } else {
        window.sessionStorage.removeItem('logoutTimeOut')
      }
    })

    return () => {
      window.removeEventListener('beforeunload', function(e) {
        window.sessionStorage.removeItem('logoutTimeOut')
      })
    }
  }, [])

  useEffect(() => {
    if (migrateError && migrateError.response && migrateError.response.data) {
      setMigrationInitiated(true)
    }
  }, [migrateError])
  let isRequiredFieldNoteRendered = false
  return (
    <div className='login-form'>
      <div className='login-form__content '>
        <div className='main-container'>
          <CustomModal
            centered={true}
            openModal={centerOpsModalOpen}
            header={centerOpsModalHeader}
            description={centerOpsModalBody}
            onClose={() => setCenterOpsModalOpen(false)}
            headerStyle={{
              float: 'none',
              display: 'block',
              width: '100%',
              textAlign: 'center'
            }}
            style={{
              width: '95%',
              height: 'auto',
              maxWidth: '49.3rem',
              maxHeight: '29.4rem'
            }}
          ></CustomModal>
          {showInactiveMessage && (
            <Form.Group className='timeout-container'>
              <Label color='red'>{t('inactivLogout')}</Label>
            </Form.Group>
          )}
          <div className='login-container'>
            <FinalForm
              onSubmit={handleSubmit}
              validate={validate}
              initialValues={initialData}
              className='login-container'
              render={({ handleSubmit }) => (
                <Form onSubmit={handleSubmit}>
                  {props.uniqueFormData?.map((item, index) => {
                    if (item.type === 'form_field_component') {
                      return (
                        <>
                          <Form.Group key={index}>
                            <Field name={item?.field_component_title_1}>
                              {({ input, meta }) => (
                                <InputField
                                  required={item?.field_status}
                                  meta={meta}
                                  type={item?.field_input_type}
                                  {...input}
                                  className='login-form__textinput'
                                  fieldLabel={item?.field_component_title?.value}
                                  placeholder={item?.field_component_title_2 || ''}
                                  isHtmlLabel
                                  value={null}
                                  width={fieldsWidths}
                                  customOnChange={() => {
                                    if (migrationInitiated) {
                                      resetMigrationError()
                                    }
                                    if (loginError) {
                                      resetLoginError()
                                    }
                                  }}
                                />
                              )}
                            </Field>
                          </Form.Group>
                        </>
                      )
                    }
                    if (!isRequiredFieldNoteRendered && item.type === 'form_cta_component') {
                      if (item?.field_cta_1?.uri?.includes('login')) {
                        return (
                          <>
                            <p className='FormNote asterisk' key='requiredFieldNote'>
                              <small class='HtmlLabelAsterisk'>*</small>
                              {t('requiredField')}
                            </p>
                            <Form.Group className='ButtonContainer'>
                              <PrimaryButton
                                text={item?.field_cta_1?.title}
                                width={340}
                                height={50}
                                fontSize={16}
                                variant={item?.field_cta_1_style}
                                type='submit'
                                fluid={isMobileWidth}
                              />
                            </Form.Group>
                          </>
                        )
                      }
                      if (item?.field_cta_1?.uri?.includes('donor-forgot-password')) {
                        return (
                          <PrimaryButton
                            className='forgotPasswordButton'
                            text={item?.field_cta_1?.title}
                            sendTo={item?.field_cta_1?.uri}
                            variant={item?.field_cta_1_style}
                          />
                        )
                      }
                    }
                    return null
                  })}
                  <div className='MessageButtonContainer'>
                    {signInLoading || loginLoading || (migrateLoading && <LoaderSpinner backdrop='partial' />)}
                    {!signInLoading &&
                      !loginLoading &&
                      !migrateLoading &&
                      loginError &&
                      !isMigrationError &&
                      !(migrateError || migrateLoading) && (
                        <Message visible negative>
                          {loginError && loginError?.response?.data?.code === 'TryAfterSomeTime' ? (
                            <p>{loginError?.response?.data?.message}</p>
                          ) : (
                            <p>{t('unsuccessFulLogin')}</p>
                          )}
                        </Message>
                      )}
                    {migrateLoading && (
                      <Message visible negative>
                        <p>{t('registering')}</p>
                      </Message>
                    )}
                    {migrationInitiated && migrateError && migrateError.response && migrateError.response.data && (
                      <Message visible negative>
                        <p>{migrateError.response.data.message}</p>
                      </Message>
                    )}
                    {(loginClicked && migrateDonorLoading) || (loginLoading && <LoaderSpinner backdrop='partial' />)}
                  </div>
                </Form>
              )}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  user: state.auth?.user,
  loggedInUser: state.users.user,
  session: state.users.session,
  signInLoading: state.auth?.signInLoading,
  signInErrorMessage: state.auth?.signInErrorMessage,
  /* */
  logoutTimeout: state.users.logoutTimeout,
  // Login donor
  loginLoading: state.users.loginLoading,
  loginError: state.users.loginFailure,
  loginSuccess: state.users.loginSuccess,
  // Migrate donor
  migrateDonor: state.users.verifyOktaAccount,
  migrateDonorLoading: state.users.verifyOktaAccountLoading,
  migrateDonorSuccess: state.users.verifyOktaAccountSuccess,
  migrateDonorError: state.users.verifyOktaAccountFailure
})

const mapDispatchToProps = (dispatch) => ({
  signIn: (username, password) => dispatch(UserActions.login(username, password)),
  signOut: () => dispatch(UserActions.logout()),
  migrateDonor: (username, password) => dispatch(UserActions.verifyOktaAccount(username, password)),
  resetMigrationError: () => dispatch(UserActions.verifyOktaAccountFailure(null)),
  resetLoginError: () => dispatch(UserActions.loginFailure(null))
})

export default connect(mapStateToProps, mapDispatchToProps)(LogInForm)
