import React, { useState, useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import { Container, Grid, Image, Segment } from 'semantic-ui-react'
import importer from '@takedapdt/biolife-core/src/importer'
const PatientTestimonials = importer('Components/PatientTestimonials')
const { getCssColorFromDrupalColorType, setTextColor } = importer('Constant/Utils', null)
import './styles.scss'

const Statistic = (props) => {
  const {
    field_alignment,
    field_bg_color,
    field_bg_color_1,
    field_column_1_desc,
    field_column_desc_color,
    field_column_title_color,
    field_component_asset,
    field_component_desc,
    field_component_title,
    field_ref_ctype,
    field_short_desc_color,
    field_short_description,
    field_title_color,
    field_component_title_1,
    field_text_color,
    field_component_title_2,
    field_long_desc_color,
    field_ref_slug_value
  } = props

  //Commented code is for making the statistic value animated.

  // const [count, setCount] = useState(0);
  // const tempDivElement = useRef(null);
  // const animationRef = useRef(null);

  // Remove commas and convert the string to a number
  // const numberValue = parseInt(field_component_title_2?.replace(/,/g, ''), 10);
  // useEffect(() => {
  //   const targetValue = numberValue;
  //   const duration = 500; // Animation duration in milliseconds
  //   const startTime = Date.now();
  //   const updateCount = () => {
  //     const currentTime = Date.now() - startTime;
  //     const progress = Math.min(currentTime / duration, 1);
  //     setCount(Math.round(progress * targetValue));
  //     if (progress < 1) {
  //       animationRef.current = requestAnimationFrame(updateCount);
  //     }
  //   };
  //   animationRef.current = requestAnimationFrame(updateCount);
  //   return () => cancelAnimationFrame(animationRef.current);
  // }, []);

  // useEffect(() => {
  //   if (tempDivElement.current) {
  //     const strongElement = tempDivElement.current;
  //     if (strongElement) {
  //       strongElement.textContent = count.toLocaleString(); // Format with commas
  //     }
  //   }
  // }, [count]);

  return (
    <>
      <div
        className={`StatisticContainer ${field_alignment === 1 ? 'ContainerReversed' : ''}`}
        style={{ background: getCssColorFromDrupalColorType(field_bg_color) }}
        fluid
      >
        <Grid className='main-container' stackable>
          <Grid.Row className={`StatisticsRow ${field_alignment === 1 ? 'Reversed' : ''}`}>
            <Grid.Column className='TextBlock' computer={8}>
              {field_component_title?.processed?.length || field_short_description?.processed?.length ? (
                <Segment className='TextContent' basic>
                  {field_component_title?.processed?.length ? (
                    <div
                      className='StatisticsTitle'
                      style={{ ...setTextColor(field_title_color) }}
                      dangerouslySetInnerHTML={{ __html: field_component_title?.processed }}
                    />
                  ) : null}
                  {field_short_description?.processed?.length ? (
                    <div
                      className='StatisticsDescription'
                      style={{ ...setTextColor(field_short_desc_color) }}
                      dangerouslySetInnerHTML={{ __html: field_short_description?.processed }}
                    />
                  ) : null}
                </Segment>
              ) : null}
              {field_component_title_1?.length || field_component_title_2?.length || field_column_1_desc?.processed?.length ? (
                <Segment className='CurrentStatisticsContainer' basic>
                  <div className='CurrentStatsBox' style={{ background: getCssColorFromDrupalColorType(field_bg_color_1) }}>
                    {field_component_title_2?.length ? (
                      <div
                        className='CurrentStatsValue'
                        style={{ ...setTextColor(field_column_title_color) }}
                        dangerouslySetInnerHTML={{ __html: field_component_title_2 }}
                      />
                    ) : null}
                    {field_component_title_1?.length ? (
                      <div
                        className='CurrentStatsTitle'
                        style={{ ...setTextColor(field_text_color) }}
                        dangerouslySetInnerHTML={{ __html: field_component_title_1 }}
                      />
                    ) : null}
                    {field_column_1_desc?.processed?.length ? (
                      <div
                        className='CurrentStatsDescription'
                        style={{ ...setTextColor(field_column_desc_color) }}
                        dangerouslySetInnerHTML={{ __html: field_column_1_desc?.processed }}
                      />
                    ) : null}
                  </div>
                </Segment>
              ) : null}
            </Grid.Column>
            <Grid.Column className='ImageBlock' computer={8}>
              {field_component_asset?.url?.length ? <Image src={field_component_asset?.url} alt={field_component_asset?.alt} /> : null}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
      <PatientTestimonials
        field_bg_color={field_bg_color}
        field_component_desc={field_component_desc}
        field_ref_slug_value={field_ref_slug_value}
        field_long_desc_color={field_long_desc_color}
      />
    </>
  )
}

const mapStateToProps = (state) => ({
  drupalHomepageData: state.publicContent.getDrupalHomepageSuccess?.contents
})

export default connect(mapStateToProps, null)(Statistic)
