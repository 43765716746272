import React from 'react'
import './styles.scss'
import { Grid } from 'semantic-ui-react'
import importer from 'common/importer'
import { connect } from 'react-redux'
import i18next from 'i18next'
const PrimaryButton = importer('Components/Buttons/PrimaryButton')
const { getCssColorFromDrupalColorType, setTextColor, getDrupalUrlTarget } = importer('Constant/Utils', null)
const { useScreenSize } = importer('Contexts/ResponsiveContextProvider', null)

const BonusGrid = ({
  field_component_title_1: componentSubText1,
  field_column_1_title: columnTitle1,
  field_column_1_desc: columnDescription1,
  field_column_1_url: columnUrl1,
  field_component_title_2: componentSubText2,
  field_column_2_title: columnTitle2,
  field_column_2_desc: columnDescription2,
  field_column_2_url: columnUrl2,
  field_column_title_color: componentTitleColor,
  field_column_desc_color: componentDescriptionColor,
  field_cta_1: componentCTAText1,
  field_cta_1_style: componentCTAStyle1,
  field_cta_2: componentCTAText2,
  field_cta_2_style: componentCTAStyle2,
  field_bg_color: componentBackgroundColor,
  centerInfo
}) => {
  const { isMobileWidth } = useScreenSize()

  const getBadgeUrl = (drupalUrl, type) => {
    if (drupalUrl && drupalUrl !== 'route:<nolink>') {
      if (drupalUrl.includes('internal:')) {
        return drupalUrl.split(':')[1]
      } else {
        return drupalUrl
      }
    } else if (centerInfo?.ndbi && type === 'ndb') {
      return i18next.language === 'es-MX' ? centerInfo?.ndbi?.spanishurl : centerInfo?.ndbi?.englishurl
    } else {
      return ''
    }
  }

  return (
    <>
      <div style={{ background: getCssColorFromDrupalColorType(componentBackgroundColor) }}>
        <div style={{ borderRadius: '0 0 200px 200px', backgroundColor: 'white', height: isMobileWidth ? 100 : 112 }}></div>
      </div>
      <Grid stackable style={{ background: getCssColorFromDrupalColorType(componentBackgroundColor) }} className='bonus-grid-container'>
        <Grid.Row columns={2}>
          {/* New Donor Bonus Grid */}
          <Grid.Column>
            {getBadgeUrl(columnUrl1?.uri, 'ndb') ? (
              <a
                className='icon'
                href={getBadgeUrl(columnUrl1?.uri, 'ndb')}
                target={getDrupalUrlTarget(getBadgeUrl(columnUrl1?.uri, 'ndb'))}
              >
                <p>
                  <span>{componentSubText1}</span>
                  <h5 dangerouslySetInnerHTML={{ __html: columnUrl1?.title }} />
                </p>
              </a>
            ) : (
              <div className='icon'>
                <p>
                  <span>{componentSubText1}</span>
                  <h5 dangerouslySetInnerHTML={{ __html: columnUrl1?.title }} />
                </p>
              </div>
            )}
            <div
              dangerouslySetInnerHTML={{ __html: columnTitle1?.processed }}
              style={{ ...setTextColor(componentTitleColor) }}
              className={'bonus-title'}
            />
            <div
              dangerouslySetInnerHTML={{ __html: columnDescription1?.processed }}
              style={{ ...setTextColor(componentDescriptionColor) }}
            />
            {componentCTAText1?.title && getBadgeUrl(componentCTAText1?.uri, 'ndb') ? (
              <PrimaryButton
                text={componentCTAText1?.title}
                variant={componentCTAStyle1}
                sendTo={getBadgeUrl(componentCTAText1?.uri, 'ndb')}
              />
            ) : null}
          </Grid.Column>

          {/* Buddy Bonus Grid  */}
          <Grid.Column>
            {getBadgeUrl(columnUrl2?.uri) ? (
              <a className='icon' href={getBadgeUrl(columnUrl2?.uri)} target={getDrupalUrlTarget(getBadgeUrl(columnUrl2?.uri))}>
                <p>
                  <span>{componentSubText2}</span>
                  <h5 dangerouslySetInnerHTML={{ __html: columnUrl2?.title }} />
                </p>
              </a>
            ) : (
              <div className='icon'>
                <p>
                  <span>{componentSubText2}</span>
                  <h5 dangerouslySetInnerHTML={{ __html: columnUrl2?.title }} />
                </p>
              </div>
            )}
            <div
              dangerouslySetInnerHTML={{ __html: columnTitle2?.processed }}
              style={{ ...setTextColor(componentTitleColor) }}
              className={'bonus-title'}
            />
            <div
              dangerouslySetInnerHTML={{ __html: columnDescription2?.processed }}
              style={{ ...setTextColor(componentDescriptionColor) }}
            />
            {componentCTAText2?.title && getBadgeUrl(componentCTAText2?.uri) ? (
              <PrimaryButton text={componentCTAText2?.title} variant={componentCTAStyle2} sendTo={getBadgeUrl(componentCTAText2?.uri)} />
            ) : null}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  )
}

const mapStateToProps = (state) => ({
  centerInfo: state.centers?.getCenterInfoSuccess?.data
})

const mapDispatchToProps = (dispatch) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(BonusGrid)
