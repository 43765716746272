import React, { useEffect, useRef, useState } from 'react'
import './styles.scss'
import { Link } from 'react-router-dom'
import importer from '@takedapdt/biolife-core/src/importer'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { ReactComponent as NextArrow } from '../../../assets/media/icons/circle-arrow-right.svg'
import { ReactComponent as PrevArrow } from '../../../assets/media/icons/circle-arrow-left.svg'
import { useNavigate } from 'react-router-dom'

const { useScreenSize } = importer('Contexts/ResponsiveContextProvider', null)
const { getCssColorFromDrupalColorType, setTextColor } = importer('Constant/Utils', null)
const { devices, sizes } = importer('utils/styledComponentHelpers', null)
const { sanitizeHtml } = importer('utils/htmlHelpers', null)
const AppointmentDetailsCard = importer('Components/AppointmentDetailsCard')
const LoaderBar = importer('Components/Loaders/LoaderBar')
const PrimaryButton = importer('Components/Buttons/PrimaryButton')

const SliderNextArrow = ({ className, onClick }) => {
  return <NextArrow width='100' height='100' className={className} onClick={onClick} />
}

const SliderPrevArrow = ({ className, onClick }) => {
  return <PrevArrow width='50' height='50' className={className} onClick={onClick} />
}

const StyledUpcomingAppointments = styled.div`
  background: ${(props) => getCssColorFromDrupalColorType(props?.$bgColor)};
  padding-top: 80px;

  ${devices.mobile} {
    padding-top: 48px;
  }
`
const StyledUpcomingAppointmentsContainer = styled.div`
  background: ${(props) => getCssColorFromDrupalColorType(props?.$bgColor)};
`
const StyledUpcomingAppointmentsTitle = styled.div`
  ${(props) => setTextColor(props?.$textColor)};
`
const StyledUpcomingAppointmentsCardsContainer = styled.div`
  padding-top: 32px;

  ${devices.mobile} {
    padding-top: 24px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    align-items: center;
  }
`
const StyledUpcomingAppointmentsButtonContainer = styled.div`
  padding-top: 8px;
  width: 100%;
`

const CustomLink = styled(Link)`
  display: flex;
  justify-self: center;
  width: fit-content;
  margin-top: 40px;
  padding-bottom: 40px;
  text-decoration: underline;
  text-align: center;
  ${(props) => setTextColor(props?.$textColor)};
`

const UpcomingAppointments = (props) => {
  const {
    field_component_title: componentTitle,
    field_cta_1: componentCTA,
    field_cta_1_style: componentCTAStyle,
    field_title_color: componentTitleColor,
    field_bg_color: backgroundColor,
    appointments,
    appointmentsLoading,
    donor
  } = props
  const { isMobileWidth, isTabletWidth } = useScreenSize()
  const { t, i18n } = useTranslation('MyAppointments')
  const [appointmentCards, setAppointmentCards] = useState([])
  const [cardsToShow, setCardsToShow] = useState(3)
  const [expanded, setExpanded] = useState(false)

  useEffect(() => {
    setAppointmentCards(appointments)
  }, [appointments])

  const showMore = () => {
    if (cardsToShow < appointmentCards.length) {
      setCardsToShow(cardsToShow + 3), setExpanded(true)
    }
    if (cardsToShow >= appointments.length) {
      setCardsToShow(3), setExpanded(false)
    }
  }

  const slider = useRef(null)
  var settings = {
    dots: true,
    intialSlide: 1,
    infinite: true,
    speed: 800,
    slidesToShow: 2.5,
    slidesToScroll: 1,
    arrows: isMobileWidth || isTabletWidth ? false : true,
    nextArrow: <SliderNextArrow />,
    prevArrow: <SliderPrevArrow />,
    responsive: [
      {
        breakpoint: 1366,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 1250,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 850,
        settings: {
          slidesToShow: 1.5,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  }

  return (
    <>
      {(!!donor?.pdn || (!!appointments && !!appointments.length)) && (
        <StyledUpcomingAppointments className='upcoming-appointments' $bgColor={backgroundColor}>
          <div className='main-container'>
            <StyledUpcomingAppointmentsContainer>
              <StyledUpcomingAppointmentsTitle
                $textColor={componentTitleColor}
                dangerouslySetInnerHTML={sanitizeHtml(componentTitle?.processed)}
              />
              <StyledUpcomingAppointmentsCardsContainer
                className={`upcoming-appointments__cards-container ${
                  appointments?.length < 3 ? (appointments?.length === 2 && window.innerWidth <= 850 ? '' : 'LessDataWrapper') : ''
                }`}
              >
                {appointmentsLoading ? (
                  <LoaderBar />
                ) : appointments && appointments?.length ? (
                  !isMobileWidth ? (
                    <Slider ref={slider} {...settings}>
                      {appointments?.map((appointment) => (
                        <AppointmentDetailsCard appointmentDetail={appointment} />
                      ))}
                    </Slider>
                  ) : (
                    <>
                      {appointmentCards?.slice(0, cardsToShow)?.map((appointment) => (
                        <AppointmentDetailsCard appointmentDetail={appointment} />
                      ))}
                      {appointmentCards?.length > 3 && (
                        <StyledUpcomingAppointmentsButtonContainer>
                          <PrimaryButton
                            text={cardsToShow < appointments.length ? t('showMore') : t('showLess')}
                            variant='outline_black'
                            onClick={showMore}
                            fluid={isMobileWidth}
                          />
                        </StyledUpcomingAppointmentsButtonContainer>
                      )}
                    </>
                  )
                ) : (
                  <p>{t('youDontHaveanyUpComing')}</p>
                )}
              </StyledUpcomingAppointmentsCardsContainer>
              {componentCTA ? (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <CustomLink
                    className='link-style'
                    $textColor={componentCTAStyle}
                    to={
                      componentCTA?.uri?.includes('internal:')
                        ? `${componentCTA?.uri?.replace('internal:', '')}`
                        : useNavigate(componentCTA?.uri)
                    }
                  >
                    {componentCTA?.title}
                  </CustomLink>
                </div>
              ) : null}
            </StyledUpcomingAppointmentsContainer>
          </div>
        </StyledUpcomingAppointments>
      )}
    </>
  )
}

const mapStateToProps = ({ appointments, donors }) => ({
  appointments: appointments.getUpcomingAppointmentsSuccess,
  donor: donors.donors?.data,
  /* LOADING STATE */
  appointmentsLoading: appointments.getUpcomingAppointmentsLoading,
  /* ERROR STATE */
  appointmentsError: appointments.getUpcomingAppointmentsFailure
})

const mapDispatchToProps = (dispatch) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(UpcomingAppointments)
