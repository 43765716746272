import React, { useState, useEffect, useRef } from 'react'
import { sortBy } from 'lodash'
import { Helmet } from 'react-helmet'
import { Input, Search, Grid, Icon, Pagination } from 'semantic-ui-react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import {
  FeaturedBackgroundImage,
  Label,
  Container,
  Div,
  TextComponent,
  NoResultsFoundTitle,
  Highlight5,
  DivWithFlex
} from './styles/index.styled'
import './style.scss'
import importer from 'common/importer'
import FilterChips from './FilterChips'
const BlogCard = importer('Components/BlogPage/BlogCard')
const BlogFilter = importer('Components/BlogPage/BlogFilter')
const FeaturedArticleCard = importer('Components/BlogPage/FeaturedArticleCard')
const PrimaryButton = importer('Components/Buttons/PrimaryButton')
const LoaderSpinner = importer('Components/Loaders/LoaderSpinner')
const PublicContentActions = importer('Stores/PublicContent/Actions')
const { useScreenSize } = importer('Contexts/ResponsiveContextProvider', null)

function BlogPage({ field_cta_1: buttonName = {}, field_cta_1_style: buttonVariant, field_cta_alignment: buttonAlignment }) {
  const [featuredBlog, setFeaturedBlog] = useState('')
  const [searchKeyword, setSearchKeyword] = useState('')
  const [searchClicked, setSearchClicked] = useState(false)
  const [noResultFound, setNoResultFound] = useState(false)
  const [searchedBlogs, setSearchedBlogs] = useState([])
  const [allSearchedData, setAllSearchedData] = useState([])
  const [showMore, setShowMore] = useState(false)
  const [selectedOptions, setSelectedOptions] = useState([])
  const [blogsPerPage, setBlogsPerPage] = useState(9)
  const dispatch = useDispatch()
  const { isMobileWidth, isTabletWidth } = useScreenSize()
  const { t } = useTranslation('BlogsContainer', 'PageContentTabs')
  const getBlogsData = (slug = '') => dispatch(PublicContentActions.getBlogsPage(slug))
  const blogsLoading = useSelector((state) => state.publicContent?.getBlogsPageLoading)
  const blogsData = useSelector((state) => state.publicContent?.getBlogsPageSuccess)
  const blogsCategoryData = useSelector((state) => state.publicContent?.blogsCategoryList)
  const usedCategoryfilterData = useRef([])
  useEffect(() => {
    getBlogsData()
  }, [])

  useEffect(() => {
    const result = []
    blogsCategoryData.map((finalTopic) => {
      result.push({
        value: finalTopic,
        label: finalTopic
      })
    })

    usedCategoryfilterData.current = result
  }, [blogsCategoryData])

  useEffect(() => {
    if (blogsData && blogsData.length) {
      for (const blog of blogsData) {
        if (blog.field_is_featured) {
          setFeaturedBlog(blog)
          break
        }
      }
      backToInitialState(blogsData)
    }
  }, [blogsData])

  useEffect(() => {
    // If show more does not depends on filters
    if (searchedBlogs && searchedBlogs.length && showMore) {
      checkIfBothFiltersApplied()

      if (searchKeyword?.length !== 0 && allSearchedData?.length) {
        handlelingNextPage(allSearchedData)
      } else if (selectedOptions?.length === 0 && searchKeyword?.length === 0) {
        handlelingNextPage(blogsData)
      } else if (selectedOptions?.length !== 0 && allSearchedData?.length) {
        handlelingNextPage(allSearchedData)
      } else if (selectedOptions?.length === 0) {
        handlelingNextPage(blogsData)
      }
    }
  }, [showMore, searchClicked, searchKeyword, allSearchedData, selectedOptions])

  useEffect(() => {
    if (!selectedOptions.length) {
      backToInitialState(sortByBasedOnNews())
      setAllSearchedData([])
    }
    if (!searchKeyword.length) {
      if (!selectedOptions.length) {
        // setSearchedBlogs(blogsData);
        if (!showMore) backToInitialState(blogsData)
        return
      }
      // Filter the searchResult state based on the selected categories
      const selectedFilters = selectedOptions.map((option) => option.value) // label.toLowerCase() because same value is there in value

      const filteredBlogs = blogsData?.filter((blog) => {
        if (blog?.field_ref_topic) {
          const topic = blog?.field_ref_topic?.split('_').join(' ')
          // const blogCategoriesArray = blog?.field_ref_topic.map((category) => category.toLowerCase())
          // return blogCategoriesArray.some((item) => selectedFilters.includes(item))
          return topic && selectedFilters.includes(topic)
        } else {
          return false
        }
      })
      // Update the searchResult state with the filtered posts
      // setSearchedBlogs(filteredBlogs);
      setAllSearchedData(filteredBlogs)
      setNoResultFound(false)
      if (!showMore) backToInitialState(filteredBlogs)
      // setActivePage(1);
    }
    checkIfBothFiltersApplied()
  }, [selectedOptions, searchKeyword])

  useEffect(() => {
    // if (!searchKeyword?.length) setSearchedBlogs(sortByBasedOnNews())
    if (!searchClicked && searchKeyword?.length === 0) {
      backToInitialState(sortByBasedOnNews())
      setAllSearchedData([])
    }
    if (searchClicked && searchKeyword?.length) {
      const blogSearchResults =
        blogsData &&
        blogsData.length &&
        blogsData?.filter(
          (blog) =>
            blog?.title.toLowerCase().includes(searchKeyword.toLowerCase()) ||
            blog?.field_ref_topic
              ?.split('_')
              .join(' ')
              .toLowerCase()
              .includes(searchKeyword.toLowerCase())
          // either i truncate the search keyword
        )
      // Check if the search query has no results
      if (blogSearchResults.length === 0) {
        // Update the searchResult state with all the blogPosts

        setAllSearchedData(blogsData)
        setNoResultFound(true)
        if (!showMore) backToInitialState(blogsData)
      } else {
        // Update the searchResult state with the filtered posts

        setAllSearchedData(blogSearchResults)
        setNoResultFound(false)
        if (!showMore) backToInitialState(blogSearchResults)
      }
      // setActivePage(1);
      // No pagination in design
    }
  }, [searchClicked, searchKeyword])

  const sortByBasedOnNews = () =>
    blogsData && blogsData.length && sortBy(blogsData, (blog) => blog.field_ref_topic && blog.field_ref_topic.includes(t('News')))

  const checkIfBothFiltersApplied = () => {
    if (searchKeyword.length && selectedOptions.length) {
      handleClearFilter({ backToInitialState: false })
    }
  }

  const backToInitialState = (data) => {
    if (data && data.length) {
      setSearchedBlogs(data.slice(0, 9))
      setShowMore(false)
    }
  }

  const handlelingNextPage = (data) => {
    if (data && data.length > blogsPerPage) {
      setSearchedBlogs((prev) => {
        return [...prev, ...data.slice(blogsPerPage, data.length > blogsPerPage + 9 ? blogsPerPage + 9 : data.length)]
      })
      setBlogsPerPage((prev) => prev + 9)
    }

    setShowMore(false)
  }

  const handleSearch = (e, { value }) => {
    setSearchKeyword(value)
    if (value === '' && noResultFound) {
      setNoResultFound(false)
    }
    setSearchClicked(false)
  }

  const handleSearchOnClick = () => {
    setSearchClicked(true)
  }

  const showMoreData = () => {
    setShowMore(!showMore)
  }

  const handleOnFilterSelectionChange = (data, actionMeta) => {
    if (searchKeyword?.length) {
      setSearchKeyword('')
    }
    setSelectedOptions(data)
  }

  const handleFilterUncheck = (data) => {
    // {value: 'donor tips', label: 'Donor Tips'}
    const newSelectedOptions = selectedOptions && selectedOptions.length && selectedOptions.filter((el) => el.value !== data.value)
    setSelectedOptions(newSelectedOptions)
  }

  const handleClearFilter = ({ backToInitialState: backToInitialForm = true }) => {
    setSelectedOptions([])
    // setActivePage(1)
    backToInitialForm && backToInitialState(blogsData)
  }

  const renderNoSearchResultsContent = () => {
    return (
      <div className='NoResultsFound'>
        {/* <img src={FrameIcon} alt='frame-icon' /> */}
        <NoResultsFoundTitle>{t('noResultsTitle')}</NoResultsFoundTitle>
        <TextComponent>{t('noBlogSearchResults')}</TextComponent>
      </div>
    )
  }

  const renderNoBlogsData = () => {
    return (
      <div className='NoResultsFound'>
        <NoResultsFoundTitle>{t('noResultsTitle')}</NoResultsFoundTitle>
        {/* <img src={FrameIcon} alt='frame-icon' /> */}
        <TextComponent>{t('noBlogs')}</TextComponent>
      </div>
    )
  }

  const renderBlogPane = () => {
    return (
      <DivWithFlex
        flexWrap='wrap'
        justifyContent={isMobileWidth || isTabletWidth ? 'space-between' : 'normal'}
        columnGap={isMobileWidth ? 'normal' : isTabletWidth ? '2.4rem' : '2.4rem'}
      >
        {(searchedBlogs &&
          searchedBlogs.length &&
          searchedBlogs.map((blog) => {
            return <BlogCard blog={blog} />
          })) ||
          null}
      </DivWithFlex>
    )
  }
  return (
    <>
      {blogsLoading ? (
        <LoaderSpinner />
      ) : (
        <>
          <div className='blogs--container'>
            {blogsData && blogsData.length ? (
              <FeaturedBackgroundImage bgImage={featuredBlog?.field_content_image?.url}>
                {<FeaturedArticleCard featuredBlog={featuredBlog} />}
              </FeaturedBackgroundImage>
            ) : null}

            <div className='main-container'>
              <Container>
                {/* Filter Container */}

                <Div>
                  <DivWithFlex
                    direction={isMobileWidth ? 'column-reverse' : 'row'}
                    flexWrap='wrap'
                    justifyContent='space-between'
                    padding={
                      blogsData && blogsData.length
                        ? isMobileWidth
                          ? '6.4rem 0rem 0rem 0rem'
                          : isTabletWidth
                          ? '6.4rem 0rem 3.2rem 0rem'
                          : '8rem 0rem 0.8rem 0rem'
                        : 0
                    }
                    rowGap='1rem'
                  >
                    <Div
                      maxWidth={isMobileWidth ? '32rem' : isTabletWidth ? '20rem' : '30rem'}
                      flexGrow='1'
                      margin={isMobileWidth && 'auto'}
                      style={{ width: '100%' }}
                      className='FilterContainer'
                    >
                      <Label>
                        <span className='caption-text'>
                          <strong>{t('filterLabel')}</strong>
                        </span>
                      </Label>
                      <BlogFilter
                        options={usedCategoryfilterData.current}
                        value={selectedOptions}
                        onChange={handleOnFilterSelectionChange}
                        handleClearFilter={handleClearFilter}
                      />
                    </Div>
                    <Div
                      maxWidth={isMobileWidth ? '32rem' : isTabletWidth ? '32rem' : '40rem'}
                      flexGrow='1'
                      margin={isMobileWidth && 'auto'}
                      style={{ width: '100%' }}
                    >
                      <Label>
                        <span className='caption-text'>
                          <strong>{t('searchLabel')}</strong>
                        </span>
                      </Label>
                      <Input
                        value={searchKeyword}
                        size='big'
                        fluid
                        onChange={handleSearch}
                        placeholder={t('searchPlaceholder')}
                        // showNoResults={false}
                        onKeyPress={(e) => {
                          if (e.key === 'Enter') {
                            handleSearchOnClick()
                          }
                        }}
                        icon={<Icon name='search' link onClick={handleSearchOnClick} />}
                      />
                    </Div>
                  </DivWithFlex>
                  {/* Showing Filter Container */}
                  {(selectedOptions && selectedOptions.length && (
                    <DivWithFlex
                      direction={!isMobileWidth ? 'row' : 'column'}
                      maxWidth={isMobileWidth && '34rem'}
                      margin={isMobileWidth && 'auto'}
                    >
                      <DivWithFlex /*  direction={!isMobileWidth ? 'row' : 'column'} */>
                        {(selectedOptions.length &&
                          selectedOptions.map((filters) => {
                            return <FilterChips filterData={filters} topic_name={filters.label} uncheckFunction={handleFilterUncheck} />
                          })) ||
                          null}
                      </DivWithFlex>
                      <DivWithFlex margin={!isMobileWidth ? '0rem 0rem 0rem 4rem' : '2.5rem 0rem 0rem 0rem '} align='center'>
                        <PrimaryButton
                          variant='black_underline'
                          text={t('clearFilter')}
                          onClick={() => handleClearFilter({ backToInitialState: true })}
                        />
                      </DivWithFlex>
                    </DivWithFlex>
                  )) ||
                    null}
                </Div>

                <Div margin='0rem 0rem 8rem 0rem'>
                  {searchKeyword?.length && noResultFound ? renderNoSearchResultsContent() : null}
                  {blogsData && !blogsData.length ? renderNoBlogsData() : null}
                  <div>{renderBlogPane()}</div>
                  {!showMore &&
                  searchedBlogs &&
                  (searchClicked || selectedOptions.length
                    ? allSearchedData && (searchedBlogs.length === allSearchedData.length ? false : true) && allSearchedData.length > 9
                    : blogsData && (searchedBlogs.length === blogsData.length ? false : true) && blogsData.length > 9) ? (
                    <Div
                      textAlign={buttonAlignment === 1 ? 'left' : buttonAlignment === 2 ? 'right' : 'center'}
                      margin='3.2rem 0rem 0rem 0rem'
                    >
                      <PrimaryButton
                        variant={buttonVariant || 'outline_black'}
                        text={buttonName?.title || 'Show More'}
                        onClick={() => {
                          showMoreData()
                        }}
                      />
                    </Div>
                  ) : null}
                </Div>
              </Container>
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default BlogPage
