import React, { useState, useEffect, useRef } from 'react'
import {
  StyledDropdownSelect,
  StyledDropdownSelectButton,
  StyledIcon,
  StyledDropdownSelectMenu,
  StyledDropdownSelectMenuOption
} from './styled'

const DropdownSelect = (props) => {
  const { options, placeholder, error, handleOnChange, defaultValue, width, defaultOption } = props
  const [selectedValue, setSelectedValue] = useState('')
  const [isOpen, setIsOpen] = useState(false)
  const dropdownRef = useRef(null)

  const handleClick = () => {
    setIsOpen(!isOpen)
  }

  const handleOutsideClick = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false)
    }
  }

  useEffect(() => {
    const listener = document.addEventListener('click', handleOutsideClick)
    return () => document.removeEventListener('click', listener)
  }, [isOpen])

  useEffect(() => {
    if (defaultValue && defaultOption && placeholder) {
      setSelectedValue(defaultValue)
      handleOnChange(defaultOption, false)
    } else {
      setSelectedValue(options[0].value)
      handleOnChange(options[0].value, false)
    }
  }, [defaultValue, defaultOption])

  return (
    <StyledDropdownSelect $width={width}>
      <StyledDropdownSelectButton ref={dropdownRef} onClick={handleClick}>
        <p>
          <strong>{selectedValue}</strong>
        </p>
        <StyledIcon />
      </StyledDropdownSelectButton>
      {isOpen && (
        <StyledDropdownSelectMenu $width={width}>
          {options.map((option, index) => (
            <StyledDropdownSelectMenuOption
              role='option'
              key={option?.key}
              value={option?.value}
              $isOptionDisabled={option?.disabled}
              onClick={(e) => {
                if (!option?.disabled && selectedValue !== option?.text) {
                  handleOnChange(option?.value, true)
                  setSelectedValue(option?.text)
                  handleClick()
                }
              }}
            >
              {option?.text}
            </StyledDropdownSelectMenuOption>
          ))}
        </StyledDropdownSelectMenu>
      )}
    </StyledDropdownSelect>
  )
}
export default DropdownSelect
