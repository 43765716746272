import React from 'react'
import styled from 'styled-components'
import PrimaryButton from '../Buttons/PrimaryButton'
import { getCssColorFromDrupalColorType } from '../../Constant/Utils'
import { sanitizeHtml } from '../../../src/utils/htmlHelpers'
import { useScreenSize } from '../../Contexts/ResponsiveContextProvider'
const StyledConditionalText = styled.div`
  background-color: ${(props) => getCssColorFromDrupalColorType(props.$bgColor)};
  text-align: ${(props) => props.$textAlignment};
`

const StyledConditionalTitle = styled.div`
  color: ${(props) => getCssColorFromDrupalColorType(props.$titleColor)};
  text-align: ${(props) => props.$textAlignment};
`

const StyledConditionalDescription = styled.div`
  color: ${(props) => getCssColorFromDrupalColorType(props.$longDescColor)};
  text-align: ${(props) => props.$textAlignment};
  padding-top: 2rem;
`

const StyledConditionalButtonColumn = styled.div`
  padding-top: 4rem;
  text-align: ${(props) => props.$textAlignment};
  display: flex;
  justify-content: ${(props) => props.$textAlignment};
`

const ConditionalTextComponent = (props) => {
  const {
    field_donor_type: donorType,
    field_component_title: componentTitle,
    field_title_color: titleColor,
    field_component_desc: componentDesc,
    field_long_desc_color: longDescColor,
    field_cta_1: cta,
    field_cta_1_style: ctaStyle,
    field_bg_color: bgColor,
    field_text_alignment: textAlignment
  } = props
  const { isMobileWidth } = useScreenSize()
  return (
    <StyledConditionalText className='main-container conditional-text-container' $bgColor={bgColor} $textAlignment={textAlignment}>
      {donorType === 'not_enrolled' && (
        <StyledConditionalTitle
          $titleColor={titleColor}
          $textAlignment={textAlignment}
          dangerouslySetInnerHTML={sanitizeHtml(componentTitle?.processed)}
          className='conditional-text-title'
        />
      )}
      {(donorType === 'not_enrolled' || donorType === 'not_yet_donated') && (
        <StyledConditionalDescription
          $longDescColor={longDescColor}
          $textAlignment={textAlignment}
          dangerouslySetInnerHTML={sanitizeHtml(componentDesc?.processed)}
          className='conditional-text-description'
        />
      )}
      {(donorType === 'not_enrolled' || donorType === 'existing_member' || donorType === 'not_yet_donated') && (
        <StyledConditionalButtonColumn $textAlignment={textAlignment} className='conditional-text-button'>
          <PrimaryButton text={cta?.title} variant={ctaStyle} sendTo={cta?.uri} fluid={isMobileWidth} />
        </StyledConditionalButtonColumn>
      )}
    </StyledConditionalText>
  )
}

export default ConditionalTextComponent
