import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import importer from '@takedapdt/biolife-core/src/importer'
import { Helmet } from 'react-helmet'
import { useLocation, useNavigate } from 'react-router-dom'
import TagManager from 'react-gtm-module'
import { useTranslation } from 'react-i18next'
import './styles.scss'
import Modal from '../Modal'
import PrimaryButton from '../Buttons/PrimaryButton'
import { devices } from '../../utils/styledComponentHelpers'

import usersActions from '@takedapdt/biolife-core/src/Stores/Users/Actions'
const { SLUG_TYPES } = importer('Sagas/Constants/drupalConstants', null)
const { ScrollToTop } = importer('Helpers/Utils', null)
const renderDrupalComponent = importer('Constant/renderDrupalComponent')
const { getArrayFromTextCTAGrid, getPathDetails } = importer('Helpers/Utils', null)
const LoaderSpinner = importer('Components/Loaders/LoaderSpinner')

/* ACTIONS */
const PublicContentActions = importer('Stores/PublicContent/Actions')

const VerifyEmailModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  padding: 5rem;
  text-align: center;
  width: 500px;

  ${devices.tablet} {
    width: 100%;
  }
  ${devices.mobile} {
    width: 100%;
  }
`

const VerifyEmailText = styled.p`
  font-size: 1.6rem;
  line-height: 2.4rem;
`

const UserVerification = ({
  registrationPageData,
  registrationPageDataLoading,
  registrationPageMeta,
  fetchRegistrationPageData,
  verifyEmail,
  verifyEmailError,
  verifyEmailLoading,
  verifyEmailSuccess
}) => {
  const { t } = useTranslation('UserVerification')

  const [verifyEmailData, setVerifyEmailData] = useState()
  const [registrationId, setRegistrationId] = useState(null)
  const [pid, setPid] = useState(null)
  const [showModal, setShowModal] = useState(false)
  const [isEmailVerified, setIsEmailVerified] = useState(false)
  const location = useLocation()
  const navigate = useNavigate()
  const query = new URLSearchParams(location.search)

  const handleDocumentClick = (event) => {
    if (event.target.id === 'verify-email-failed') {
      // This block will execute when the button with id 'Continue' is clicked
      if (registrationId) {
        navigate('/create-account', { state: { registrationId, pid } })
      } else if (verifyEmailError?.code && verifyEmailError.code === 'TokenExpired') {
        setShowModal(true)
      } else {
        navigate('/login')
      }
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleDocumentClick)

    // Cleanup function to remove event listener when component unmounts
    return () => {
      document.removeEventListener('click', handleDocumentClick)
    }
  }, [])

  // To add to Tag Manager that new user has created an account
  useEffect(() => {
    if (verifyEmailSuccess && !verifyEmailLoading) {
      TagManager.dataLayer({
        dataLayer: {
          event: 'profileRegistration',
          profileRegistrationStage: 'account created'
        }
      })
    }
  }, [verifyEmailSuccess, verifyEmailLoading])

  // To check if the token is expired and to get the registration id and pid
  useEffect(() => {
    if (verifyEmailError) {
      if (verifyEmailError?.code && verifyEmailError.code == 'TokenExpired') {
        setRegistrationId(verifyEmailError?.sfid)
        setPid(verifyEmailError?.pid)
      }

      // Remove template which is not needed from Drupal when verification fails.
      const filteredContents = verifyEmailData?.contents?.filter((item) => {
        return item.type !== 'text_banner' && item.field_custom_id !== 'verify-component'
      })
      const modifiedContents = filteredContents?.map((item) => {
        if (item.type === 'text_cta_grid') {
          // Find a property named grid which is array of objects and remove the object in it which has type 'text_icon_grid'
          const grid = item?.grid?.filter((item) => item.type !== 'text_icon_grid')

          /**
           * From the above const 'grid', find the object which has type 'text_cta_grid' and
           * in this object change a property 'field_grid_column' to value of 1. Remove CTA.
           */
          const textCTAGrid = grid?.find((item) => item.type === 'text_cta_grid')
          textCTAGrid.field_grid_column = 1
          textCTAGrid.field_cta_1 = null
          textCTAGrid.field_column_1_title.processed = `<h4><strong>${t('linkExpired')}</strong></h4>`
          textCTAGrid.field_column_1_desc.processed = `<p>${t('completeRegistration')}</p>
            <button class='ui button SolidBlue SolidBlueButton' 
            style='font-size: 1.8rem;padding: 1.6rem 3rem; margin-top: 4rem'
            id='verify-email-failed'>${t('continue')}</button>`

          item.grid = grid
        }
        return item
      })

      const newVerify = { ...verifyEmailData, contents: modifiedContents }
      setVerifyEmailData(newVerify)
    }
  }, [verifyEmailError, verifyEmailLoading])

  useEffect(() => {
    fetchRegistrationPageData(getPathDetails.slugType || SLUG_TYPES?.SIGNUPEMAILCONFIRM)
    ScrollToTop()
  }, [])

  useEffect(() => {
    if (registrationPageData) {
      const dataWithTextCTAGrid = getArrayFromTextCTAGrid(registrationPageData)
      if (Object.keys(dataWithTextCTAGrid)?.length) {
        setVerifyEmailData(dataWithTextCTAGrid)

        const token = query.get('token')
        const digitalId = query.get('id')
        const sfid = query.get('sfid')
        verifyEmail(token, digitalId, sfid)
      }
    }
  }, [registrationPageData])

  return (
    <>
      <Helmet>
        <title>{`${registrationPageMeta?.title}`}</title>
        <meta name='description' content={`${registrationPageMeta?.meta_description}`} />
        <link rel='canonical' href={`${window.location.origin}${window.location.pathname.toLowerCase()}`} />
      </Helmet>
      {registrationPageDataLoading || verifyEmailLoading ? (
        <LoaderSpinner />
      ) : (
        <div className='verify-email-page'>
          {verifyEmailData &&
            verifyEmailData?.contents &&
            verifyEmailData?.contents?.length > 0 &&
            verifyEmailData?.contents?.map((component, index) => {
              if (component.type === 'breadcrumb') {
                let tempComp = {
                  ...component,
                  breadcrumbToggle: verifyEmailData?.metadata?.field_content_breadcrumb,
                  breadcrumbMobileToggle: verifyEmailData?.metadata?.field_mobile_breadcrumb
                }
                component = tempComp
              }
              return renderDrupalComponent(component, index)
            })}

          <Modal maxWidth={500} maxHeight={200} isOpen={showModal} onClose={() => setShowModal(false)}>
            <VerifyEmailModalContainer>
              <VerifyEmailText>{t('invaildDetails')}</VerifyEmailText>
              <PrimaryButton
                text={t('ok')}
                onClick={() => {
                  setShowModal(false)
                }}
                variant={'solid_blue'}
              />
            </VerifyEmailModalContainer>
          </Modal>
        </div>
      )}
    </>
  )
}
const mapStateToProps = (state) => ({
  registrationPageData: state.publicContent?.getRegistrationPageSuccess?.verifyEmail,
  registrationPageDataLoading: state.publicContent?.getRegistrationPageLoading?.verifyEmail,
  registrationPageMeta: state.publicContent?.getRegistrationPageSuccess?.verifyEmail?.metadata,
  verifyEmailError: state.users.verifyEmailFailure,
  verifyEmailLoading: state.users.verifyEmailLoading,
  verifyEmailSuccess: state.users.verifyEmailSuccess
})

const mapDispatchToProps = (dispatch) => ({
  fetchRegistrationPageData: (formType) => dispatch(PublicContentActions.getRegistrationPage(formType)),
  verifyEmail: (recoveryToken, digitalId, sfid) => dispatch(usersActions.verifyEmail(recoveryToken, digitalId, sfid))
})

export default connect(mapStateToProps, mapDispatchToProps)(UserVerification)
