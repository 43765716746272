import React, { useEffect, useRef } from 'react'
import './styles.scss'
import { connect } from 'react-redux'
import importer from 'common/importer'
import { TableRow, TableBody, Table, TableCell, Card } from 'semantic-ui-react'
import { useScreenSize } from '../../Contexts/ResponsiveContextProvider'
import { ReactComponent as Quotes } from '../../../assets/media/icons/quote-mark-48.svg'
const { getOperationHours } = importer('Helpers/Utils', null)
const { getCssColorFromDrupalColorType, setTextColor, getMediaFromDrupalMimeType } = importer('Constant/Utils', null)
import { useTranslation } from 'react-i18next'

const CenterPageTopComponent = ({
  centerInfo,
  field_component_title: componentTitle,
  field_title_color: componentTitleColor,
  field_short_description: componentShortDescription,
  field_short_desc_color: componentShortDescriptonColor,
  field_component_title_1: componentTitle1,
  field_component_title_2: componentTitle2,
  field_component_image: componentImage,
  field_component_desc: componentDescription,
  field_long_desc_color: componentDescriptionColor
}) => {
  const { t } = useTranslation('Locations')
  const weekday = [t('sunday'), t('monday'), t('tuesday'), t('wednesday'), t('thursday'), t('friday'), t('saturday')]
  const today = weekday[new Date().getDay()]
  const { isMobileWidth } = useScreenSize()

  useEffect(() => {
    let el1 = document.getElementById('todayDayElement')
    let el2 = document.getElementById('todayHourElement')
    if (el1 && el2) {
      el1.innerHTML = `<strong>${el1?.textContent}</strong>`
      el2.innerHTML = `<strong>${el2?.textContent}</strong>`
    }
  }, [])

  return (
    <div class='center-page-top-component-container'>
      {!isMobileWidth && <Table className='center-hours-container'>
        <TableBody>
          {centerInfo?.hoursOfOperation.map((item) => (
            <TableRow style={{ color: today === item.weekDayName && '#eb6f24' }}>
              <TableCell>
                <p id={today === item.weekDayName && 'todayDayElement'}>{item.weekDayName}</p>
              </TableCell>
              <TableCell>
                <p id={today === item.weekDayName && 'todayHourElement'}>{getOperationHours(item)}</p>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>}
      <div className='center-seo-container'>
        <div
          dangerouslySetInnerHTML={{ __html: componentTitle?.processed }}
          style={{ ...setTextColor(componentTitleColor) }}
        />
        <div
          dangerouslySetInnerHTML={{ __html: componentShortDescription?.processed }}
          style={{ padding: '16px 0', ...setTextColor(componentShortDescriptonColor) }}
        />
        <Card className='center-testimonial' raised>
          <Card.Header>
            <Quotes width='24' height='18' />
          </Card.Header>
          <Card.Content>
            <Card.Description
              dangerouslySetInnerHTML={{ __html: componentDescription?.processed }}
              style={{ ...setTextColor(componentDescriptionColor) }}
            />
          </Card.Content>
          <Card.Content extra>
            <Card.Description>
              <div className='testimonial-patient-details'>
                {componentImage ? (
                  <div>
                    {getMediaFromDrupalMimeType(componentImage?.mimetype, componentImage?.url, componentImage?.alt, {
                      height: 75,
                      width: 75
                    })}
                  </div>
                ) : null}
                <div>
                  <p className='patient-name'>
                    <strong>{componentTitle1}</strong>
                  </p>
                  <p>{componentTitle2}</p>
                </div>
              </div>
            </Card.Description>
          </Card.Content>
        </Card>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  centerInfo: state.centers?.getCenterInfoSuccess?.data
})

export default connect(mapStateToProps, null)(CenterPageTopComponent)
