import React, { useEffect, useState } from 'react'
import { Accordion as AccordionSemantic, Icon, Grid, Image } from 'semantic-ui-react'
import './styles.scss'
import { ReactComponent as CheveronUpIcon } from '../../../assets/media/icons/icon-chevron-up.svg'
import { ReactComponent as CheveronDownIcon } from '../../../assets/media/icons/icon-chevron-down.svg'
import importer from '@takedapdt/biolife-core/src/importer'
const { setTextColor, getMediaFromDrupalMimeType } = importer('Constant/Utils', null)

const AccordionGridComponent = (props) => {
  const {
    field_component_title: accordionTitle,
    field_title_color: accordionTitleColor,
    field_accordian_flag: accordionDefaultCondition,
    field_short_description: accordionShortDescription,
    field_component_desc: accordionComponentDescription,
    field_short_desc_color: accordionShortDescriptionColor,
    field_column_1_title: accordionColumnOneTitle,
    field_column_1_desc: accordionColumnOneDescription,
    field_column_1_icon: accordionColumnOneIcon,
    field_column_2_title: accordionColumnTwoTitle,
    field_column_2_desc: accordionColumnTwoDescription,
    field_column_2_icon: accordionColumnTwoIcon,
    field_column_3_title: accordionColumnThreeTitle,
    field_column_3_desc: accordionColumnThreeDescription,
    field_column_3_icon: accordionColumnThreeIcon,
    field_column_4_title: accordionColumnFourTitle,
    field_column_4_desc: accordionColumnFourDescription,
    field_column_4_icon: accordionColumnFourIcon,
    field_column_5_title: accordionColumnFiveTitle,
    field_column_5_desc: accordionColumnFiveDescription,
    field_column_5_icon: accordionColumnFiveIcon,
    field_column_6_title: accordionColumnSixTitle,
    field_column_6_desc: accordionColumnSixDescription,
    field_column_6_icon: accordionColumnSixIcon,
    field_grid_column: accordionGridColumn
  } = props

  const EligibilityPoints = [
    accordionColumnOneTitle && {
      accordionColumnIcon: accordionColumnOneIcon,
      accordionColumnTitle: accordionColumnOneTitle,
      accordionColumnDesc: accordionColumnOneDescription
    },
    accordionColumnTwoTitle && {
      accordionColumnIcon: accordionColumnTwoIcon,
      accordionColumnTitle: accordionColumnTwoTitle,
      accordionColumnDesc: accordionColumnTwoDescription
    },
    accordionColumnThreeTitle && {
      accordionColumnIcon: accordionColumnThreeIcon,
      accordionColumnTitle: accordionColumnThreeTitle,
      accordionColumnDesc: accordionColumnThreeDescription
    },
    accordionColumnFourTitle && {
      accordionColumnIcon: accordionColumnFourIcon,
      accordionColumnTitle: accordionColumnFourTitle,
      accordionColumnDesc: accordionColumnFourDescription
    },
    accordionColumnFiveTitle && {
      accordionColumnIcon: accordionColumnFiveIcon,
      accordionColumnTitle: accordionColumnFiveTitle,
      accordionColumnDesc: accordionColumnFiveDescription
    },
    accordionColumnSixTitle && {
      accordionColumnIcon: accordionColumnSixIcon,
      accordionColumnTitle: accordionColumnSixTitle,
      accordionColumnDesc: accordionColumnSixDescription
    }
  ]
  const EligibilityPointsGrid = (accordionColumnIcon, accordionColumnTitle, accordionColumnDesc) => {
    return (
      <Grid.Column className='AccordionColumnContainer'>
        {getMediaFromDrupalMimeType(accordionColumnIcon?.mimetype, accordionColumnIcon?.url, accordionColumnIcon?.alt)}
        <div className='AccordionColumn'>
          <h4 dangerouslySetInnerHTML={{ __html: accordionColumnTitle?.value }} />
          <p dangerouslySetInnerHTML={{ __html: accordionColumnDesc?.value }} />
        </div>
      </Grid.Column>
    )
  }

  const [activeIndex, setActiveIndex] = useState()

  useEffect(() => {
    setActiveIndex(accordionDefaultCondition)
  }, [])

  const handleClick = () => {
    setActiveIndex(!activeIndex)
  }

  return (
    <div className='main-container'>
      <div className='AccordionGridContainer'>
        <AccordionSemantic fluid>
          <div key={`accordion_tab_${activeIndex}`}>
            <AccordionSemantic.Title
              active={activeIndex}
              onClick={handleClick}
              style={{ ...setTextColor(accordionTitleColor) }}
            >
              <span dangerouslySetInnerHTML={{ __html: accordionTitle?.value }} />
              <span className='chevron-arrow'>{activeIndex ? <CheveronUpIcon /> : <CheveronDownIcon />}</span>
            </AccordionSemantic.Title>
            <AccordionSemantic.Content
              active={activeIndex}
              style={{ ...setTextColor(accordionShortDescriptionColor) }}
            >
              {accordionColumnOneTitle ? (
                <AccordionSemantic.Content active={activeIndex} className='accordionContent'>
                  <div className='AccordionShortDescTextContainer' dangerouslySetInnerHTML={{ __html: accordionShortDescription?.value }} />
                  <Grid columns={accordionGridColumn} stackable verticalAlign='center'>
                    <Grid.Row className='AccordionGridContentContainer'>
                      {EligibilityPoints.map(
                        (item, index) =>
                          item && (
                            <React.Fragment key={index}>
                              {EligibilityPointsGrid(item?.accordionColumnIcon, item?.accordionColumnTitle, item?.accordionColumnDesc)}
                            </React.Fragment>
                          )
                      )}
                    </Grid.Row>
                  </Grid>
                  <div
                    className='AccordionGridContentDescContainer'
                    dangerouslySetInnerHTML={{ __html: accordionComponentDescription?.value }}
                  />
                </AccordionSemantic.Content>
              ) : (
                <div className='AccordionGridContentTextContainer' dangerouslySetInnerHTML={{ __html: accordionShortDescription?.value }} />
              )}
            </AccordionSemantic.Content>
          </div>
        </AccordionSemantic>
      </div>
    </div>
  )
}

export default AccordionGridComponent
