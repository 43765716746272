import React from 'react'
import { Button } from 'semantic-ui-react'
import { useNavigate } from 'react-router-dom'
import './styles.scss'
import { getButtonClassNames } from '../buttonUtilityFunctions'
import { ReactComponent as RightArrow } from '../../../../assets/media/icons/UI-arrow-right.svg'
import { ReactComponent as LeftArrow } from '../../../../assets/media/icons/UI-arrow-left.svg'

const TransparentButton = ({ text = 'Transparent', classType = 'Transparent', fontSize = 14, arrowDirection = '', ...props }) => {
  const { style, type, variant, onClick, as, sendTo: to = '', loading, inverted, className } = props
  const navigate = useNavigate()
  const buttonStyles = getButtonClassNames(variant)
  const routeChange = (path) => {
    if (!path) {
      return
    }
    const origPath = path && path.includes('internal:') ? path.split(':')[1] : path
    navigate(origPath)
  }

  const handleClick = ({ to }) => {
    routeChange(to)
  }

  return (
    <Button
      loading={loading}
      className={`TransparentButton ${buttonStyles || ``} ${className}`}
      style={{
        ...style
      }}
      type={type}
      onClick={() => handleClick({ to })}
      as={as}
      to={to}
    >
      {arrowDirection === 'left' && <LeftArrow className='leftArrow' />}
      {text}
      {arrowDirection === 'right' && <RightArrow className='arrow' />}
    </Button>
  )
}

export default TransparentButton
