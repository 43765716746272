import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'
import importer from 'common/importer'
import { StyledCustomPageWrapper } from './styles.js'
import { useScreenSize } from '../../Contexts/ResponsiveContextProvider'
const PublicContentActions = importer('Stores/PublicContent/Actions')
const renderDrupalComponent = importer('Constant/renderDrupalComponent')
const PageNotFound = importer('Components/PageNotFound')
const LoaderSpinner = importer('Components/Loaders/LoaderSpinner')
const { getClassFromPathname } = importer('Helpers/Utils', null)
const { getArrayFromTextCTAGrid, scrollToElement } = importer('Helpers/Utils', null)

const CustomPage = ({ customPageData, customPageDataLoading, getCustomPage }) => {
  const { pathname, hash } = useLocation()
  const navigate = useNavigate()
  const [finalData, setFinalData] = useState({})
  const [cookieBtnLoaded, setCookieBtnLoaded] = useState(false)
  const { isTabletWidth, isMobileWidth } = useScreenSize()

  function reloadOTBanner() {
    var otConsentSdk = document.getElementById('onetrust-consent-sdk')
    if (otConsentSdk) {
      otConsentSdk.remove()
    }
    if (window.OneTrust != null) {
      OneTrust.Init()
      setTimeout(function() {
        OneTrust.LoadBanner()
        var toggleDisplay = document.getElementsByClassName('ot-sdk-show-settings')
        for (var i = 0; i < toggleDisplay.length; i++) {
          toggleDisplay[i].classList.remove('opacityStyling')
          toggleDisplay[i].onclick = function(event) {
            event.stopImmediatePropagation()
            window.OneTrust.ToggleInfoDisplay()
          }
        }
        setCookieBtnLoaded(false)
      }, 1000)
    }
  }

  function clearDup() {
    var sec = document.getElementById('ot-sdk-cookie-policy')
    var tally = []
    for (var i = sec?.length - 1; i >= 0; i--) {
      if (tally[sec[i].firstChild.innerText] === undefined) {
        tally[sec[i].firstChild.innerText] = 1
      } else {
        // console.log(i,sec[i].firstChild.innerText);
        sec[i].remove()
        // return true;
      }
    }
    // return false;
  }

  useEffect(() => {
    getCustomPage(pathname.substring(1)) // extract one level slug from url
    window.scrollTo(0, 0)
  }, [pathname])

  useEffect(() => {
    if (pathname.substring(1) === 'cookie-policy' && cookieBtnLoaded && window.OneTrust != null) {
      reloadOTBanner()
      clearDup()
    }
  }, [cookieBtnLoaded, window?.OneTrust])

  useEffect(() => {
    setFinalData(customPageData)
    if (customPageData) {
      pathname.substring(1) === 'cookie-policy' && setCookieBtnLoaded(true)
      const dataWithTextCTAGrid = getArrayFromTextCTAGrid(customPageData)
      if (Object.keys(dataWithTextCTAGrid)?.length) {
        setFinalData(dataWithTextCTAGrid)
      }
    }
  }, [customPageData])

  /**
   * UseEffect to scroll to specific element on the page based on the hash in the URL when customPageData is received from Drupal.
   */
  useEffect(() => {
    setTimeout(() => {
      if (hash) {
        scrollToElement(hash, isTabletWidth || isMobileWidth)
      }
    }, 1000)
  }, [customPageData, customPageDataLoading])

  /**
   * UseEffect to watch for clicks on any anchor tags.
   * If the click happens, default behavior should be prevented and the page should scroll to the anchor tag smoothly if its same page.
   * If the anchor tag is on a different page, the page should navigate to that page and scroll to the anchor.
   * If the click happens on an anchor tag or any of the children of the anchor tag and the anchor tag has a hash and has an attribute isscrollto to be true,
   * prevent the default behavior and scroll to the element with the id equal to the hash.
   */
  useEffect(() => {
    const handleDocumentClick = (event) => {
      const anchor = event?.target?.closest('a')
      if (anchor && anchor?.getAttribute('isscrollto') === 'true' && anchor?.hash) {
        event?.preventDefault()
        const hash = anchor?.hash
        const pathname = anchor?.pathname || window?.location?.pathname
        if (pathname === window?.location?.pathname) {
          scrollToElement(hash, isTabletWidth || isMobileWidth)
        } else {
          navigate(pathname + hash)
        }
      }
    }

    document.addEventListener('click', handleDocumentClick)

    return () => {
      document.removeEventListener('click', handleDocumentClick)
    }
  }, [])

  return (
    <StyledCustomPageWrapper>
      <Helmet>
        <title>{finalData?.metadata?.meta_title}</title>
        <meta name='description' content={`${finalData?.metadata?.meta_description}`} />
        <link rel='canonical' href={`${window.location.origin}${window.location.pathname.toLowerCase()}`} />
      </Helmet>
      {customPageDataLoading || (customPageData && !finalData?.contents) ? (
        <LoaderSpinner />
      ) : finalData?.contents ? (
        /*className is dynamically generated based on the pathname.
          @example
          if pathname is '/donation-process/faqs' then classname will be 'donation-process-faqs'*/
        <div className={getClassFromPathname(pathname)}>
          {/* for getting breadcrumbToggle from pageMetadata  */}
          {finalData?.contents?.map((component, index) => {
            if (component.type === 'breadcrumb') {
              let tempComp = {
                ...component,
                breadcrumbToggle: finalData?.metadata?.field_content_breadcrumb,
                breadcrumbMobileToggle: finalData?.metadata?.field_mobile_breadcrumb
              }
              component = tempComp
            }

            return renderDrupalComponent(component, index)
          })}
        </div>
      ) : (
        <PageNotFound />
      )}
    </StyledCustomPageWrapper>
  )
}

const mapStateToProps = (state) => ({
  customPageData: state.publicContent?.getCustomPageSuccess,
  // customPageMetaData: state.publicContent?.getCustomPageSuccess?.metadata,
  customPageDataLoading: state.publicContent?.getCustomPageLoading
})

const mapDispatchToProps = (dispatch) => ({
  getCustomPage: (slug) => dispatch(PublicContentActions.getCustomPage(slug))
})

export default connect(mapStateToProps, mapDispatchToProps)(CustomPage)
