import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Container, Segment } from 'semantic-ui-react'
import importer from '@takedapdt/biolife-core/src/importer'
import styled from 'styled-components'

import './styles.scss'

const DesktopFooter = importer('Components/Footer/FooterViews/DesktopFooter')
const TabletFooter = importer('Components/Footer/FooterViews/TabletFooter')
const MobileFooter = importer('Components/Footer/FooterViews/MobileFooter')
const { useScreenSize } = importer('Contexts/ResponsiveContextProvider', null)
const getFormattedFooterData = importer('Components/Footer/getFormattedFooterData')
const { getCssColorFromDrupalColorType } = importer('Constant/Utils', null)
import { ReactComponent as MySvg } from '../../../assets/media/icons/button-back-to-top.svg'
import { devices } from '../../utils/styledComponentHelpers'

const StyledImg = styled(MySvg)`
  height: 5rem;
  width: 5rem;
  margin: 20px;
  // position: fixed;
  cursor: pointer;
`
const BackToTopButton = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  ${devices.mobile} {
    justify-content: center;
  }
`

const Footer = ({ drupalFooterMenu, ...props }) => {
  const [isVisible, setIsVisible] = useState(false)

  const { isMobileWidth, isTabletWidth } = useScreenSize()
  const formattedFooterData = getFormattedFooterData(props) || {}
  const gradientStyleForHorizontalBar = {
    background: getCssColorFromDrupalColorType('orange_gradient'),
    height: '10px',
    width: '100%'
  }

  // Show button when page is scrolled down
  window.onscroll = () => {
    if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
      setIsVisible(true)
    } else {
      setIsVisible(false)
    }
  }

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }
  return (
    <>
      <BackToTopButton>
        <StyledImg onClick={scrollToTop} />
      </BackToTopButton>
      <div className='FooterContainer' style={{ background: getCssColorFromDrupalColorType(formattedFooterData?.footerBackground.color) }}>
        <div className='HorizontalBar' style={{ ...gradientStyleForHorizontalBar }}></div>
        <div className='main-container'>
          <Segment className='Footer' basic>
            <Container fluid>
              {/* Desktop View */}
              {!isMobileWidth && !isTabletWidth && (
                <DesktopFooter footerContentData={formattedFooterData} footerMenuData={drupalFooterMenu} />
              )}
              {/* Tablet View */}
              {isTabletWidth && <TabletFooter footerContentData={formattedFooterData} footerMenuData={drupalFooterMenu} />}
              {/* Mobile View */}
              {isMobileWidth && <MobileFooter footerContentData={formattedFooterData} footerMenuData={drupalFooterMenu} />}
            </Container>
          </Segment>
        </div>
      </div>
    </>
  )
}

const mapStateToProps = (state) => ({
  drupalFooterMenu: state.publicContent.drupalMenuSuccess?.footer
})

export default connect(mapStateToProps, null)(Footer)
