import React from 'react'
import styled from 'styled-components'
import importer from '@takedapdt/biolife-core/src/importer'
const { devices } = importer('utils/styledComponentHelpers', null)
const { parseExtVideoLink } = importer('Helpers/Utils', null)

const StyledVideoContent = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  padding: 1.2rem;

  ${devices.mobile} {
    padding: 1.1rem;
  }
`
const StyledVideo = styled.div`
  width: 100%;
  height: 100%;
`

const CustomVideoContent = ({ videoUrl, isExternal, isAutoplay }) => {
  return (
    <StyledVideoContent>
      <StyledVideo
        as={isExternal ? 'iframe' : 'video'}
        src={isExternal ? `${parseExtVideoLink(videoUrl)}${isAutoplay ? '?&autoplay=1' : ''}` : videoUrl}
        controls
        autoPlay={isAutoplay ? 'true' : ''}
        allow={isAutoplay ? 'autoplay' : ''}
        preload='metadata'
        controlsList='nodownload nofullscreen noremoteplayback'
      />
    </StyledVideoContent>
  )
}

export default CustomVideoContent
