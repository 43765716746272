import React, { useEffect } from 'react'
import './styles.scss'
import { Grid } from 'semantic-ui-react'
import './styles.scss'
import importer from '@takedapdt/biolife-core/src/importer'

const { useScreenSize } = importer('Contexts/ResponsiveContextProvider', null)
const { getCssColorFromDrupalColorType, setTextColor, getMediaFromDrupalMimeType } = importer('Constant/Utils', null)

const GridTextImage = (props) => {
  const {
    field_component_title: textMediaTemplateTitle,
    field_title_color: textMediaTitleColor,
    field_short_desc_color: textMediaShortDescriptionColor,
    field_component_asset: textMediaComponentAsset,
    field_asset_link: textMediaAssetLink,
    field_column_1_icon: textMediaColumnOneIcon,
    field_column_1_title: textMediaColumnOneTitle,
    field_column_2_icon: textMediaColumnTwoIcon,
    field_column_2_title: textMediaColumnTwoTitle,
    field_column_3_icon: textMediaColumnThreeIcon,
    field_column_3_title: textMediaColumnThreeTitle,
    field_column_title_color: textMediaColumnTitleColor,
    field_column_desc_color: textMediaColumnDescColor,
    field_bg_color: textMediaBGColor,
    field_component_desc: textMediaComponentAssest,
    field_alignment_2: textMediaImageAlignment,
    field_bg_color_1: textMediaBGColorOne,
    field_alignment_2: ctaAlignment,
    field_long_desc_color: textMediaLongDescriptionColor
  } = props
  const { isMobileWidth } = useScreenSize()

  const ProcessedPoints = [
    textMediaColumnOneTitle && {
      textMediaColumnIcon: textMediaColumnOneIcon,
      textMediaColumnTitle: textMediaColumnOneTitle
    },
    textMediaColumnTwoTitle && {
      textMediaColumnIcon: textMediaColumnTwoIcon,
      textMediaColumnTitle: textMediaColumnTwoTitle
    },
    textMediaColumnThreeTitle && {
      textMediaColumnIcon: textMediaColumnThreeIcon,
      textMediaColumnTitle: textMediaColumnThreeTitle
    }
  ]

  const ProcessedPointsGrid = (textMediaColumnIcon, textMediaColumnTitle) => {
    return (
      <Grid.Row columns={2} className='donation-process-points'>
        <Grid.Column
          className='donation-process-points__icon'
          verticalAlign='top'
          style={{ ...setTextColor(textMediaColumnDescColor) }}
        >
          {getMediaFromDrupalMimeType(textMediaColumnIcon?.mimetype, textMediaColumnIcon?.url, textMediaColumnIcon?.alt)}
        </Grid.Column>
        <Grid.Column
          verticalAlign='middle'
          dangerouslySetInnerHTML={{ __html: textMediaColumnTitle?.value }}
          style={{ ...setTextColor(textMediaColumnTitleColor) }}
          className='donation-process-points__title'
        />
      </Grid.Row>
    )
  }

  return (
    <div
      className='text-media_container'
      style={{
        background: `${getCssColorFromDrupalColorType(textMediaBGColorOne)}`
      }}
    >
      <div
        className='text-media'
        style={{
          background: `${getCssColorFromDrupalColorType(textMediaBGColor)}`
        }}
      >
        <div className='main-container'>
          <Grid className='text-media__process-container'>
            {isMobileWidth ? (
              <Grid.Row
                columns={2}
                className={`text-media__grid-column${textMediaImageAlignment === 1 ? '--reversed' : ''} ${
                  ctaAlignment === 3 ? 'top-aligned' : ''
                }`}
              >
                <Grid.Column className='text-media__points_container' tablet={16} mobile={16}>
                  {ProcessedPoints?.map((item) => item && ProcessedPointsGrid(item?.textMediaColumnIcon, item?.textMediaColumnTitle))}
                  <Grid.Row
                    className='text-media__title-container'
                    style={{ ...setTextColor(textMediaTitleColor) }}
                    dangerouslySetInnerHTML={{ __html: textMediaTemplateTitle?.processed }}
                  />
                  <div
                    style={{ ...setTextColor(textMediaShortDescriptionColor || textMediaLongDescriptionColor) }}
                    className='text-media__description-container'
                    dangerouslySetInnerHTML={{ __html: textMediaComponentAssest?.processed }}
                  />
                </Grid.Column>

                <Grid.Column tablet={16} mobile={16} className='text-media__img-container' verticalAlign='top'>
                  <a href={textMediaAssetLink?.uri?.includes('internal:') 
                    ? textMediaAssetLink?.uri?.split(':')[1] 
                    : textMediaAssetLink?.uri}
                    target={textMediaAssetLink?.uri?.includes('internal:') ? '_self' : '_blank'}
                  >
                    <div className='backdrop'></div>
                    {getMediaFromDrupalMimeType(textMediaComponentAsset?.mimetype, textMediaComponentAsset?.url, textMediaComponentAsset?.alt)}
                  </a>
                </Grid.Column>
              </Grid.Row>
            ) : (
              <Grid.Row
                className={`text-media__grid-column${textMediaImageAlignment === 1 ? '--reversed' : ''}${
                  textMediaImageAlignment === 4 ? '--centerAligned' : ''
                } ${ctaAlignment === 3 ? 'top-aligned' : ''}`}
              >
                <Grid.Column className='text-media__points_container' computer={ctaAlignment === 3 ? 16 : 11}>
                  {ProcessedPoints?.map((item) => item && ProcessedPointsGrid(item?.field_column_icon, item?.field_column_title))}
                  <Grid.Row
                    className='text-media__title-container'
                    style={{ ...setTextColor(textMediaTitleColor) }}
                    dangerouslySetInnerHTML={{ __html: textMediaTemplateTitle?.processed }}
                  />
                  <div
                    style={{ ...setTextColor(textMediaShortDescriptionColor || textMediaLongDescriptionColor) }}
                    className='text-media__description-container'
                    dangerouslySetInnerHTML={{ __html: textMediaComponentAssest?.processed }}
                  />
                </Grid.Column>

                <Grid.Column
                  computer={ctaAlignment === 3 ? 16 : textMediaImageAlignment === 4 ? 16 : 5}
                  className={`text-media__img-container${textMediaImageAlignment === 4 ? '--centerAligned' : ''}`}
                >
                  <a href={textMediaAssetLink?.uri?.includes('internal:') 
                    ? textMediaAssetLink?.uri?.split(':')[1] 
                    : textMediaAssetLink?.uri}
                    target={textMediaAssetLink?.uri?.includes('internal:') ? '_self' : '_blank'}
                  >
                    <div className='backdrop'></div>
                    {getMediaFromDrupalMimeType(
                      textMediaComponentAsset?.mimetype,
                      textMediaComponentAsset?.url,
                      textMediaComponentAsset.alt,
                      {},
                      { className: 'text-media__imagestyle' }
                    )}
                  </a>
                </Grid.Column>
              </Grid.Row>
            )}
          </Grid>
        </div>
      </div>
    </div>
  )
}

export default GridTextImage
