import styled from 'styled-components'
import importer from '@takedapdt/biolife-core/src/importer'
const { getCssColorFromDrupalColorType } = importer('Constant/Utils', null)
const { devices } = importer('utils/styledComponentHelpers', null)

export const LeftContainer = styled.div`
  margin-right: ${({ imageAlignment }) => (imageAlignment ? '2.4rem' : '0')};
  margin-left: ${({ imageAlignment }) => (imageAlignment ? '0' : '2.4rem')};
  ${devices.tablet} {
    margin-right: ${({ imageAlignment }) => (imageAlignment ? '2.4rem' : '0')};
    margin-left: ${({ imageAlignment }) => (imageAlignment ? '0' : '2.4rem')};
  }
  ${devices.mobile} {
    margin-right: 0px;
    margin-left: 0px;
    position: relative;
    height: 22rem;
    display: flex;
    justify-content: center;
  }
`

export const RightContainer = styled.div`
  ${devices.mobile} {
    text-align: center;
  }
`

export const BgImageCard = styled.div`
  background-image: url(${({ bgImage }) => bgImage}), ${({ cardBgColor }) => cardBgColor === 'orange_gradient' ? getCssColorFromDrupalColorType('dark_orange_gradient') : getCssColorFromDrupalColorType(cardBgColor)};
  background-color: ${({ cardBgColor }) => cardBgColor};
  background-repeat: no-repeat;
  border-radius: 50px;
  padding: 4rem;
  height: 30rem;

  ${devices.tablet} {
    height: 42rem;
    padding: 4.5rem;
  }
  ${devices.mobile} {
    background-image: url(${({ bgImage }) => bgImage}), ${({ cardBgColor }) => cardBgColor === 'orange_gradient' ? getCssColorFromDrupalColorType('dark_orange_gradient_bottom') : getCssColorFromDrupalColorType(cardBgColor)};
    height: auto; 
  }
`
export const Image = styled.img`
  position: relative;
  top: -9rem;
  height: ${({ $height }) => $height};
  width: ${({ $width }) => $width};
  ${devices.tablet} {
    // to ensure the image overlaps to match design
    top: -4rem;
  }
  ${devices.mobile} {
    position: absolute;
    top: -21.8rem;
  }
`

export const ImageIcon1 = styled.img`
  margin-right: 2.5rem;    
  ${devices.tablet} {
    margin-right: 1rem;
  }

  ${devices.mobile} {
    margin: 0 0 2.5rem 0;
  }
  height: ${({ $height }) => $height};
  width: ${({ $width }) => $width};
`

export const ImageIcon2 = styled.img`
  height: ${({ $height }) => $height};
  width: ${({ $width }) => $width};
  ${devices.mobile} {
    margin: 0 0 2.5rem 0;
  }
`

export const RightContainerTitleDiv = styled.div`
  margin-bottom: 1.6rem;
`
