import React from 'react'
import { Card, Segment } from 'semantic-ui-react'
import { ReactSVG } from 'react-svg'
import { useTranslation } from 'react-i18next'
import './styles.scss'
import LocationIcon from '../../../assets/media/icons/icon-location-new.svg'
import PhoneIcon from '../../../assets/media/icons/icon-phone-in-talk.svg'
import CheckIcon from '../../../assets/media/icons/icon-done.svg'
import { ReactComponent as HomeHeartIcon } from '../../../assets/media/icons/home-heart.svg'
import favouriteIcon from '../../../assets/media/icons/location-heart-icon.svg'
import moment from 'moment'
import { formatPhoneNumber } from '../../Helpers/Utils'

const CenterCard = ({ isSelected, centerData, onCenterCardClick, favCenterList, isHomeCenter, favourites }) => {
  const isFavourite = !!favCenterList?.find((favCenter) => favCenter?.sfid === centerData?.value)

  const { t } = useTranslation('AccountCreateForm')
  return (
    <Card
      raised
      onClick={() => onCenterCardClick()}
      className={isSelected ? 'CenterCardContainer CenterCardSelected' : 'CenterCardContainer'}
    >
      <Card.Content>
        <Card.Header>
          <div className={`HeaderWrapper ${centerData.distanceFromGivenZipcode ? '' : 'HideDistanceBox'}`}>
            {centerData.distanceFromGivenZipcode ? (
              <div className='DistanceBox'>
                <ReactSVG className='LocationIconSmall' src={LocationIcon} />
                <span className='CenterDistance'>{`${centerData.distanceFromGivenZipcode} ${t('mi')}`}</span>
              </div>
            ) : null}
            <div className='CheckMarkContainer'>
              <ReactSVG className='CheckMark' src={CheckIcon} />
            </div>
          </div>
        </Card.Header>
        <Card.Description>
          <Segment basic>
            <div>
              <div className='CenterHeading'>
                <p className='CenterName'>{centerData.name}</p>
                {(favourites || isFavourite) && !isHomeCenter && <ReactSVG className='FavouriteIcon' src={favouriteIcon} />}
                {isHomeCenter ? <HomeHeartIcon className='HomeCenterIcon' width='24px' height='24px' fill='none' /> : null}
              </div>
              <div className='AddressBox'>
                <ReactSVG className='LocationIcon' src={LocationIcon} />
                <p className='CenterAddress'>
                  {centerData.addressLine1 || centerData.address}
                  <br />
                  {`${centerData.city}, ${centerData.stateCode} ${centerData.zipcode || centerData.zipCode}`}
                  <br />
                </p>
              </div>
              <div className='PhoneNumberBox'>
                <ReactSVG className='PhoneIcon' src={PhoneIcon} />
                <p className='PhoneNumber' href={`tel:${centerData.telephone}`}>
                  {formatPhoneNumber(centerData.telephone)}
                </p>
              </div>
              {centerData.isSpanishSupported ? <p className='IsSpanishCenter'>{t('habloEspanol')}</p> : null}
            </div>
            <hr className='CardSeparator' />
            <div className='CardInfoText'>
              <p className='NewDonorBonusInfo'>
                {t('newBiolifePlasmaDonors')}
                <b>${centerData?.newDonorBonusInformation?.ndbAmount}</b>
              </p>
              <p className='ExpiryDate'>
                {t('expiryDate')}
                {moment(centerData?.newDonorBonusInformation?.offerExpDate).format('MM/DD/YYYY')}
              </p>
            </div>
          </Segment>
        </Card.Description>
      </Card.Content>
    </Card>
  )
}

export default CenterCard
