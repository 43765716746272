import React, { useEffect, useRef } from 'react'
import { Grid, Image } from 'semantic-ui-react'

// Dynamic Imports
import importer from '@takedapdt/biolife-core/src/importer'
const { getCssColorFromDrupalColorType, setTextColor } = importer('Constant/Utils', null)
const { useScreenSize } = importer('Contexts/ResponsiveContextProvider', null)
const PrimaryButton = importer('Components/Buttons/PrimaryButton')

// Stylesheet
import './style.scss'

function TextCTAGridTemplate2Component({ grid = [], bgColor }) {
  const { isMobileWidth, isTabletWidth } = useScreenSize()
  return (
    <div style={bgColor ? { background: getCssColorFromDrupalColorType(bgColor) } : {}}>
      <div className='main-container WhatToDoContanier2'>
        <Grid>
          {grid && grid.length ? (
            <Grid.Row columns={grid.length}>
              {grid.map((gridElement, index) => {
                const {
                  field_column_1_title: columnTitle = '',
                  field_column_title_color: columnTitleColor = 'rich_black',
                  field_column_1_desc: desc = '',
                  field_column_desc_color: descColor = 'rich_black',
                  field_cta_1: button1 = { title: '', uri: '' },
                  field_grid_column: columns,
                  field_cta_1_style: button1Style = 'solid_blue'
                } = gridElement || {}
                const iconImageArr = [
                  {
                    iconImage1: gridElement?.field_column_1_icon,
                    iconImageLink1: gridElement?.field_column_1_url
                  },
                  {
                    iconImage2: gridElement?.field_column_2_icon,
                    iconImageLink2: gridElement?.field_column_2_url
                  }
                ]

                return (
                  <Grid.Column
                    key={index}
                    computer={Math.floor(16 / columns)}
                    tablet={Math.floor(16 / columns)}
                    mobile={16}
                    className='manageGridColumnPadding'
                  >
                    <div className='IconImageContainer'>
                      {iconImageArr &&
                        iconImageArr.map((item, index) => {
                          if (item[`iconImage${index + 1}`] !== undefined) {
                            return (
                              <Image
                                className='IconImage'
                                src={item[`iconImage${index + 1}`]?.url}
                                as='a'
                                href={item[`iconImageLink${index + 1}`]?.uri}
                                target='_blank'
                                alt={item[`iconImage${index + 1}`]?.alt}
                                size='medium'
                              />
                            )
                          }
                        })}
                    </div>
                    <div
                      className='headingText'
                      style={{ ...setTextColor(columnTitleColor) }}
                      dangerouslySetInnerHTML={{ __html: columnTitle?.processed || `<p></p>` }}
                    />
                    <div
                      className='paragraph'
                      style={{ ...setTextColor(descColor) }}
                      dangerouslySetInnerHTML={{ __html: desc?.processed || `<p></p>` }}
                    />
                    {button1?.title && (
                      <PrimaryButton
                        className='button--cta'
                        variant={button1Style}
                        text={`${button1.title || 'Primary'}`}
                        sendTo={button1.uri}
                        fluid={isMobileWidth || isTabletWidth ? true : false}
                      />
                    )}
                  </Grid.Column>
                )
              })}
            </Grid.Row>
          ) : (
            <></>
          )}
        </Grid>
      </div>
    </div>
  )
}

export default TextCTAGridTemplate2Component
