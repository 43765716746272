import React from 'react'
import importer from 'common/importer'
import './styles.scss'
const { getCssColorFromDrupalColorType, setTextColor } = importer('Constant/Utils', null)
const { useScreenSize } = importer('Contexts/ResponsiveContextProvider', null)

const TextComponent = (props) => {
  const {
    field_component_desc: componentDescription,
    field_long_desc_color: componentDescriptionColor,
    field_component_title: componentTitle,
    field_title_color: componentTitleColor,
    field_text_alignment: componentDescriptionTextAlign,
    field_component_width: componentWidth,
    field_bg_color: backgroundColor,
    field_custom_id: customId
  } = props
  const { isMobileWidth, isTabletWidth } = useScreenSize()

  return (
    <div
      className='text-component__container'
      style={{
        background: getCssColorFromDrupalColorType(backgroundColor),
        textAlign: componentDescriptionTextAlign
      }}
      id={customId}
    >
      <div className='main-container'>
        {componentTitle ? (
          <div
            className='text-component-title'
            dangerouslySetInnerHTML={{ __html: componentTitle?.processed }}
            style={{
              margin: componentDescriptionTextAlign === 'center' ? 'auto' : 'unset',
              textAlign: componentDescriptionTextAlign,
              width: !isMobileWidth ? `${componentWidth}%` : '100%',
              ...setTextColor(componentTitleColor)
            }}
          />
        ) : null}
        {componentDescription ? (
          <div
            className='text-component-description'
            dangerouslySetInnerHTML={{ __html: componentDescription?.processed }}
            style={{
              margin: componentDescriptionTextAlign === 'center' ? 'auto' : 'unset',
              width: !isMobileWidth ? `${componentWidth}%` : '100%',
              ...setTextColor(componentDescriptionColor)
            }}
          />
        ) : null}
      </div>
    </div>
  )
}

export default TextComponent
