import styled from 'styled-components'
import { devices } from '../../../../utils/styledComponentHelpers'

export const Inactivity5minContainer = styled.div`
  padding: 10px 10px 40px 10px;
  display: flex;
  justify-content: center;
  flex-direction: column;
`

export const Inactivity5minDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 9rem;
  padding: 0 20px;
`

export const H4Text = styled.h4`
  color: var(--color-dark-focus-blue);
  margin-bottom: 1.6rem;
  font-family: var(--font-family-bold) !important;
`

export const Desc = styled.p`
  color: var(--color-rich-black);
  text-align: center;
  opacity: 62%;
`

export const ButtonsDiv = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
  padding: 0 20px;
`
