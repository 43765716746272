import React, { useEffect } from 'react'
import { Grid } from 'semantic-ui-react'
import importer from '@takedapdt/biolife-core/src/importer'
import styled from 'styled-components'
import { ReactComponent as MySvg } from '../../../assets/media/icons/BlogTimer.svg'
import { useTranslation } from 'react-i18next'
import moment from 'moment'

const { devices, sizes } = importer('utils/styledComponentHelpers', null)
const { sanitizeHtml } = importer('utils/htmlHelpers', null)
const { useScreenSize } = importer('Contexts/ResponsiveContextProvider', null)
const PrimaryButton = importer('Components/Buttons/PrimaryButton')
const { getCssColorFromDrupalColorType, getMediaFromDrupalMimeType } = importer('Constant/Utils', null)

const StyledTopic = styled.div`
  font-size: var(--p-font-size);
  text-align: center;
  width: max-content;
  max-width: 100%;

  &.topic-news {
    color: var(--color-light-orange);
    .StyledTopicName {
      font-size: var(--p-font-size);
    }
  }
  &.topic {
    background-color: var(--color-vlight-orange);
    border-radius: 19px;
    color: var(--color-dark-orange);
    .StyledTopicName {
      font-size: var(--caption-mobile-font-size);
    }
  }
  margin-bottom: 11px;
  margin-top: 24px;
  * {
    font-family: var(--font-family-bold) !important;
    text-transform: capitalize !important;
  }
`

const StyledTopicName = styled.div`
  padding-bottom: 8px;
  padding-top: 8px;
  &.topicName {
    padding-left: 16px;
    padding-right: 16px;
  }
`

const StyledHeading = styled.div`
  color: var(--color-bl-blue);
  padding-bottom: 16px;
  * {
    font-family: var(--font-family-bold) !important;
  }
`

const StyledAttributes = styled.div`
  display: flex;
  padding-bottom: 48px;
`
const StyledValue = styled.div`
  color: var(--color-rich-black);
  padding-right: 8px;
  font-size: var(--p-font-size) !important;
`
const StyledTimer = styled.div`
  padding-left: 12px;
  display: flex;
  align-items: center;
  padding: 0rem 0rem 0rem 1.6rem;
`
const StyledImg = styled(MySvg)`
  height: 19px;
  width: 16px;
  margin-right: 0.8rem;
`

const ArticleDetailsHeading = ({ testMeta }) => {
  const { isMobileWidth } = useScreenSize()
  const { t } = useTranslation(testMeta?.field_ref_topic ? 'BlogsContainer' : 'NewsContainer')
  const fieldTopicName = t(testMeta?.field_ref_topic)
  const topicName =
    fieldTopicName && fieldTopicName.length
      ? fieldTopicName
          .split('_')
          .join(' ')
          .toUpperCase()
      : t(testMeta?.field_category)
  const StyledTime = styled.div`
    font-size: var(--p-font-size) !important;
  `

  return (
    <div className='main-container'>
      <StyledTopic className={testMeta?.field_ref_topic ? 'topic' : 'topic-news'}>
        <StyledTopicName
          className={testMeta?.field_ref_topic ? 'topicName' : 'topicName-news'}
          dangerouslySetInnerHTML={sanitizeHtml(topicName)}
        ></StyledTopicName>
      </StyledTopic>
      <StyledHeading className='Heading'>
        <h2>{testMeta?.title}</h2>
      </StyledHeading>
      <StyledAttributes>
        <StyledValue className='attributes'>
          {moment(testMeta?.created).format('MMM D')}, {moment(testMeta?.created).format('YYYY')}
        </StyledValue>
        <p> | </p>
        <StyledTimer>
          <StyledImg />
          <StyledValue className='caption-text'>
            <StyledTime className='caption-text'>
              {testMeta?.field_read_time} {t('min')}
            </StyledTime>
          </StyledValue>
        </StyledTimer>
      </StyledAttributes>
    </div>
  )
}

export default ArticleDetailsHeading
