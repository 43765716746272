import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  DashboardBannerContainer,
  DashboardBannerWrapper,
  DashboardBannerInnerWrapper,
  DashboardBannerImageBlock,
  DashboardBannerTextBlock
} from './styled'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from '../../../NavigationService'
import { extractFirstLetter } from '../../../Helpers/Utils'

const DashboardBanner = ({ profileName, lastName }) => {
  const { t } = useTranslation('donorDashboardPage')
  const navigate = useNavigate()
  return (
    <DashboardBannerContainer>
      <DashboardBannerWrapper>
        <div className='main-container'>
          <DashboardBannerInnerWrapper>
            <DashboardBannerImageBlock onClick={() => navigate(ROUTES.Settings, { state: { preferences: false } })}>
              <span>
                {extractFirstLetter(profileName)}
                {extractFirstLetter(lastName)}
              </span>
            </DashboardBannerImageBlock>
            <DashboardBannerTextBlock>
              <p>{t('welcome')}</p>
              <p>{profileName}!</p>
            </DashboardBannerTextBlock>
          </DashboardBannerInnerWrapper>
        </div>
      </DashboardBannerWrapper>
    </DashboardBannerContainer>
  )
}

export default DashboardBanner
