function getButtonClassNames(data) {
  switch (data) {
    case 'solid_blue': {
      return `SolidBlue SolidBlueButton`
    }
    case 'solid_orange': {
      return `SolidOrange SolidOrangeButton`
    }
    case 'outline_black': {
      return `OulineBlack OutlineBlackButton`
    }
    case 'outline_blue': {
      return `OulineBlue OutlineBlueButton`
    }
    case 'outline_orange': {
      return `OulineOrange OutlineOrangeButton`
    }
    case 'orange_outline_white': {
      return `WhiteOutlineOrangeButton`
    }
    case 'blue_underline': {
      return `BlueUnderlineButton`
    }
    case 'black_underline': {
      return `BlackUnderlineButton`
    }
    case 'blue_arrow_left': {
      return `BlueArrowTextButton ArrowLeft`
    }
    case 'blue_arrow_right': {
      return `BlueArrowTextButton ArrowRight`
    }
    case 'orange_arrow_left': {
      return `OrangeArrowTextButton ArrowLeft`
    }
    case 'orange_arrow_right': {
      return `OrangeArrowTextButton ArrowRight`
    }
    default: {
      return `SolidBlue SolidBlueButton`
    }
  }
}

export { getButtonClassNames }
