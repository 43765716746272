//import Amplify from 'aws-amplify'
import { getContext, put, select } from 'redux-saga/effects'

import importer from '@takedapdt/biolife-core/src/importer'

const StartupActions = importer('Stores/Startup/Actions')
const WirecardActions = importer('Stores/Wirecard/Actions')
const DonorsActions = importer("Stores/Donors/Actions")
const { getPathDetails } = importer('Helpers/Utils', null)
const { ROUTES } = importer('NavigationService', null)
const { donor } = importer('Stores/Donors/Select', null)
const Config = importer('Config')

/**
 * PUBLIC_STARTUP
 */
export function * initializeApp (data) {
  /* */
  try {
    // yield Amplify.configure({
    //   Auth: {
    //     region: process.env.DEFAULT_REGION,
    //     identityPoolId: process.env.IDENTITY_POOL_ID,
    //     userPoolId: process.env.USER_POOL_ID,
    //     userPoolWebClientId: process.env.USER_POOL_WEBCLIENT_ID
    //   },
    //   Storage: {
    //     AWSS3: {
    //       bucket: process.env.ASSETS_BUCKET,
    //       region: process.env.DEFAULT_REGION
    //     }
    //   }
    // })

    const firebase = yield getContext('firebase')
    const { pageRoute, routeName, segments } = getPathDetails()
    const loggedInDonor = yield select(donor)
    if (Config.FIREBASE_CONFIG_WEB) {
      const firebaseConfig = JSON.parse(Config.FIREBASE_CONFIG_WEB)
      firebase.initializeApp(firebaseConfig)
      firebase.analytics()
      firebase.analytics().logEvent('startup')
    }
    
    if (data?.res === 'changeLanguage') 
      yield put(StartupActions.initializeAppSuccess())

    if (loggedInDonor?.data?.pdn && segments[2] === 'donor-dashboard') {
      yield put(DonorsActions.inquiryDonor(loggedInDonor?.data?.pdn))
      yield put(WirecardActions.getWirecardInfo(loggedInDonor?.data?.pdn))
    }
  } catch (error) {
    yield put(StartupActions.initializeAppFailure(error))
  }
}

export default initializeApp
