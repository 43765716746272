import React from 'react'
import { Image } from 'semantic-ui-react'
import InputMask from 'react-input-mask'
import './styles.scss'
import infoIcon from '../../../../assets/media/icons/InfoIcon.svg'
import success_check_circle from '../../../../assets/media/icons/success_check_circle.svg'

const PrimaryMaskedInput = (props) => {
  const {
    width,
    height,
    action,
    actionPosition,
    as,
    className,
    disabled,
    error,
    fluid,
    focus,
    icon,
    iconPosition,
    inverted,
    label,
    labelPosition,
    onChange,
    size,
    transparent,
    type,
    placeholder,
    required,
    value,
    mask,
    onBlur,
    maskPlaceholder,
    hasErrorsClass,
    maskChar,
    meta,
    autoComplete
  } = props

  const allClassNames = `${className} ${hasErrorsClass}`
  return (
    <div className={allClassNames}>
      <InputMask
        action={action}
        actionPosition={actionPosition}
        as={as}
        disabled={disabled}
        error={error}
        fluid={fluid}
        focus={focus}
        icon={icon}
        iconPosition={iconPosition}
        inverted={inverted}
        label={label}
        labelPosition={labelPosition}
        onChange={onChange}
        size={size}
        transparent={transparent}
        type={type}
        placeholder={placeholder}
        style={{ width: width ? width : '470px', height: height ? height : '56px' }}
        value={value}
        mask={mask}
        onBlur={onBlur}
        maskPlaceholder={maskPlaceholder}
        maskChar={maskChar}
        autoComplete={autoComplete}
      />
      <div className='InfoIcon'>
        {meta.touched && meta.error && <Image src={infoIcon} width='24' height='24' />}
        {(meta.touched && !meta.error) && <Image src={success_check_circle} width='24' height='24' />}
      </div>
    </div>
  )
}

PrimaryMaskedInput.defaultProps = {
  className: 'PrimaryMaskedInput'
}
export default PrimaryMaskedInput
