import React, { useState, useEffect } from 'react'
import { Form, Message, Image } from 'semantic-ui-react'
import { Form as FinalForm, Field } from 'react-final-form'
import { useNavigate } from 'react-router-dom'
import { isEmpty } from 'lodash'
import validate from './validate'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import './styles.scss'
import GrayInfoIcon from '../../../../assets/media/icons/GrayInfoIcon.svg'
import importer from '@takedapdt/biolife-core/src/importer'
const Config = importer('Config')
const renderDrupalComponent = importer('Constant/renderDrupalComponent')
const CentersCarousel = importer('Components/CentersCarousel')
const JoinMyBiolifeRewards = importer('Components/JoinMyBiolifeRewards')
const InputField = importer('Components/Forms/FormFields/InputField')
const SelectField = importer('Components/Forms/FormFields/SelectField')
const CustomCheckbox = importer('Components/Inputs/CustomCheckbox')
const PrimaryButton = importer('Components/Buttons/PrimaryButton')
const MaskedField = importer('Components/Forms/FormFields/MaskedField')
const PasswordValidation = importer('Components/PasswordValidation')
const { isMobileWeb, getAge, filterNonNomogramCentersPublicPage, ScrollToTop } = importer('Helpers/Utils', null)
const { useScreenSize } = importer('Contexts/ResponsiveContextProvider', null)
const { SLUG_TYPES } = importer('Sagas/Constants/drupalConstants')

const AccountCreateForm = React.memo(
  ({
    countries,
    states,
    centers,
    genders,
    getGenders,
    getEthnicityOptions,
    phoneTypes,
    ethnicityOptions,
    setSubmissionLoader,
    getDonorCentersAll,
    getPhoneTypes,
    getDonorCentersAllLoading,
    register,
    registerSuccess,
    registerFailure,
    donor,
    donorDetailsSaved,
    accountFormData
  }) => {
    const [selectedLocation, setSelectedLocation] = useState(null)
    const [passwordReveal, setPasswordReveal] = useState(false)
    const [statesManager, setStatesManager] = useState({})
    const [selectedCountry, setSelectedCountry] = useState('US')
    const [selectedPhoneType, setSelectedPhoneType] = useState('Mobile')
    const [countriesOptions, setCountriesOptions] = useState([])
    const [statesOptions, setStatesOptions] = useState([])
    const [errorMessages, setErrorMessages] = useState('')
    const [submited, setSubmited] = useState(false)
    const [showAgeError, setShowAgeError] = useState(false)
    const [passwordInput, setPasswordInput] = useState('')
    const [genderDropDown, setGenderDropDown] = useState([{ text: '', value: '' }])
    const [phoneTypeDropdown, setPhoneTypeDropdown] = useState([{ text: '', value: '' }])
    const [ethnicityDropdown, setEthnicityDropdown] = useState([{ text: '', value: '' }])
    const [loyaltyTnCsShow, setLoyaltyTnCsShow] = useState(false)
    const [zipcodeError, setZipcodeError] = useState(false)

    const { isMobileWidth } = useScreenSize()

    const createdFrom = isMobileWeb() ? 'MOBILE_WEB' : 'WEB'

    const { t, i18n } = useTranslation('AccountCreateForm')

    const languageOptions = [
      { key: 'English', text: t('english'), value: 'en-US' },
      { key: 'Spanish', text: t('spanish'), value: 'es-MX' }
    ]

    const navigate = useNavigate()

    useEffect(() => {
      ScrollToTop()
    }, [])

    const handleLocationChange = (value) => {
      setSelectedLocation(value.centerNum)
      if (value.isLoyaltyProgramEnabled) {
        setLoyaltyTnCsShow(true)
      } else {
        setLoyaltyTnCsShow(false)
      }
    }
    const togglePasswordReveal = () => setPasswordReveal(!passwordReveal)

    const handlePasswordChange = ({ target: { value } }) => {
      setPasswordInput(value)
    }

    const updateCenters = (inputVal) => {
      if (inputVal && inputVal.length === 5) {
        setZipcodeError(false)
        setSelectedLocation(null)
        getDonorCentersAll(inputVal, '', 'pubDate', true, true) // second to last true corresponds to addNewDonorBonusInformation
        setLoyaltyTnCsShow(false)
      }
    }

    const handleDateChange = ({ target: { value } }) => {
      if (!moment(value, 'MM/DD/YYYY').isValid()) {
        setShowAgeError(true)
      } else {
        if (
          value.slice(6) >
          moment()
            .subtract(18, 'years')
            .format('yyyy')
        ) {
          setShowAgeError(true)
        } else {
          setShowAgeError(false)
        }
      }
    }

    const onSubmit = async (values) => {
      setSubmissionLoader(true)
      const donorsAge = getAge(values.birthDate)
      const donorDetails = {
        donorDetails: {
          donorId: `${statesManager.donorId}`,
          firstName: values.firstName,
          lastName: values.lastName,
          birthDate: moment(values.birthDate).format('YYYY-MM-DD'),
          language: values.language,
          gender: values.gender,
          phoneNumber: values.phoneNumber.replace(/\D/g, ''),
          email: statesManager.loginId,
          ethnicityCode: values.ethnicity,
          phoneType: values.phoneType || selectedPhoneType,
          addressLine1: values.addressLine1,
          addressLine2: values.addressLine2,
          city: values.city.trim(),
          state: values.state,
          country: values.country || selectedCountry,
          zipCode: values.zipCode,
          donationCenter: selectedLocation ? selectedLocation.toString() : selectedLocation,
          isMobileTermsAccepted: values.isMobileTermsAccepted,
          isSmsOptIn: values.isSmsOptIn,
          isEmailOptIn: donorDetailsSaved.isEmailOptIn,
          isSmsReminderOptIn: values.isSmsOptIn,
          isEmailReminderOptIn: donorDetailsSaved.isEmailOptIn,
          isLoyaltyMember: loyaltyTnCsShow ? (values.hero_template_3 ? 'Yes' : null) : null // BE teams explicitly wants Yes or No with respect to boolean true or false
        },
        loginId: statesManager.loginId,
        password: values.password,
        autoVerify: false,
        salesforceId: !isEmpty(donor) && donor.sfid !== null ? donor.sfid : '',
        personContactId: !isEmpty(donor) && donor.personContactId !== null ? donor.personContactId : '',
        url: `${Config.WEB_APP_URL}/verify-email`,
        loyaltyApplicationUsed: isMobileWeb() ? 'MOBILE_WEB' : 'WEB',
        createdFrom
      }
      if (donorsAge >= 18 && donorsAge <= 150) {
        await register(donorDetails)
        setSubmited(true)
      } else {
        setShowAgeError(true)
        setSubmissionLoader(false)
      }
    }

    const getStatesByCountries = (country) => {
      if (country) {
        const dCountry = countries.find((c) => c.code === country)
        if (dCountry) {
          if (!isEmpty(states)) {
            const filteredStates = states
              .filter((state) => state.countrycode === dCountry.code)
              .map((s) => ({
                key: s.code,
                value: s.code,
                text: s.description
              }))
            setStatesOptions(filteredStates)
          }
        }
      }
    }

    useEffect(() => {
      getPhoneTypes()
      getGenders()
      getEthnicityOptions()
    }, [i18n.language])

    // Filter out the Separator component if loyaltyTnCsShow is false
    const filteredFormData = accountFormData?.filter((component, index) => {
      if (
        !loyaltyTnCsShow &&
        component.type === 'separator_component' &&
        accountFormData[index - 1]?.field_hero_template_type === 'hero_template_3'
      ) {
        return false
      }
      return true
    })

    useEffect(() => {
      setGenderDropDown(transformGenderCollection())
    }, [genders])

    useEffect(() => {
      setPhoneTypeDropdown(transformPhoneTypeCollection())
    }, [phoneTypes])

    useEffect(() => {
      setEthnicityDropdown(transformEthnicityOptionsCollection())
    }, [ethnicityOptions])

    const transformGenderCollection = () =>
      genders &&
      genders.map((gender) => ({
        key: gender.key,
        text: gender.label,
        value: gender.value
      }))

    const transformPhoneTypeCollection = () =>
      phoneTypes &&
      phoneTypes.map((phoneTypes) => ({
        key: phoneTypes.key,
        text: phoneTypes.label,
        value: phoneTypes.value
      }))

    const transformEthnicityOptionsCollection = () =>
      ethnicityOptions &&
      ethnicityOptions.map((ethnicityOption) => ({
        key: ethnicityOption.key,
        text: i18n.language === 'es-MX' ? ethnicityOption.es_label : ethnicityOption.en_label,
        value: ethnicityOption.value
      }))

    useEffect(() => {
      const storageData = JSON.parse(window.sessionStorage.getItem('lead'))
      if (!isEmpty(storageData)) {
        getDonorCentersAll(storageData.zipCode, '', 'pubDate', true, true) // second to last true corresponds to addNewDonorBonusInformation
        const complexStateInitial = {
          donorId: storageData.id,
          firstName: storageData.firstName,
          lastName: storageData.lastName,
          loginId: storageData.email,
          zipCode: storageData.zipCode
        }
        setStatesManager(complexStateInitial)
      }
    }, [donor, donorDetailsSaved])

    useEffect(() => {
      if (!isEmpty(countries) && !isEmpty(states)) {
        const availableContries = countries.map((c) => ({
          key: c.code,
          value: c.code,
          text: c.description
        }))
        setCountriesOptions(availableContries)
        getStatesByCountries(selectedCountry)
      }
    }, [countries, states])

    useEffect(() => {
      if (registerSuccess !== null && submited) {
        setSubmited(false)
        setSubmissionLoader(false)
        navigate(`/${SLUG_TYPES?.SIGNUPFINALSTEP}`)
      }
    }, [registerSuccess])

    useEffect(() => {
      if (registerFailure !== null && submited) {
        setSubmited(false)
        setSubmissionLoader(false)
        setErrorMessages(registerFailure ? registerFailure.message : t('somethingWentWrong'))
      }
    }, [registerFailure])

    return (
      <div className='main-container AccountCreateForm'>
        <FinalForm
          onSubmit={onSubmit}
          validate={validate}
          initialValues={statesManager}
          render={({ handleSubmit, values }) => (
            <Form onSubmit={handleSubmit}>
              {filteredFormData?.map((component, index) => {
                if (
                  component.type === 'form_field_component' ||
                  component.type === 'form_cta_component' ||
                  component.type === 'ext_integration_component' ||
                  component.type === 'hero'
                ) {
                  if (component.field_integration_type === 'center_list_carousel') {
                    return (
                      <div key={index}>
                        {isEmpty(centers) && !zipcodeError && !getDonorCentersAllLoading && (
                          <p className='ValidationErrorBox'>{t('noBioLifeCentreFoundMsg')}</p>
                        )}
                        {zipcodeError && <p className='ValidationErrorBox'>{t('pleaseEnterValidZipcode')}</p>}
                        {!isEmpty(centers) && !zipcodeError && !getDonorCentersAllLoading && (
                          <Field name='donationCenter'>
                            {({ input, meta }) => (
                              <CentersCarousel
                                key={index}
                                input={input}
                                componentData={component}
                                handleLocationChange={handleLocationChange}
                                selectedCenter={selectedLocation}
                                centers={centers}
                              />
                            )}
                          </Field>
                        )}
                        <p className='FormNote asterisk' key='requiredFieldNote'>
                          <small class='HtmlLabelAsterisk'>*</small>
                          {t('indicatesRequiredField')}
                        </p>
                      </div>
                    )
                  } else if (component.field_component_title_1 === 'zipCode') {
                    return (
                      <Form.Group key={index}>
                        <Field name={component.field_component_title_1}>
                          {({ input, meta }) => (
                            <InputField
                              required={component.field_status}
                              meta={meta}
                              {...input}
                              width={320}
                              height={56}
                              placeholder={component.field_component_title_2 || ''}
                              fieldLabel={component.field_component_title?.processed}
                              isHtmlLabel
                              customOnChange={(e) => (e.target.value.length !== 5 ? setZipcodeError(true) : null)}
                              onBlur={() => {
                                input.onBlur()
                                updateCenters(input.value)
                              }}
                              id={component?.field_component_title_1}
                            />
                          )}
                        </Field>
                      </Form.Group>
                    )
                  } else if (component.field_component_title_1 === 'firstName') {
                    return (
                      <Form.Group key={index}>
                        <Field name={component.field_component_title_1}>
                          {({ input, meta }) => (
                            <InputField
                              required={component.field_status}
                              meta={meta}
                              {...input}
                              width={isMobileWidth ? 310 : 632}
                              height={56}
                              id={component?.field_component_title_1}
                              placeholder={component.field_component_title_2 || ''}
                              fieldLabel={component.field_component_title?.processed}
                              isHtmlLabel
                            />
                          )}
                        </Field>
                      </Form.Group>
                    )
                  } else if (component.field_component_title_1 === 'lastName') {
                    return (
                      <Form.Group key={index}>
                        <Field name={component.field_component_title_1}>
                          {({ input, meta }) => (
                            <InputField
                              required={component.field_status}
                              meta={meta}
                              {...input}
                              width={isMobileWidth ? 310 : 632}
                              height={56}
                              id={component?.field_component_title_1}
                              placeholder={component.field_component_title_2 || ''}
                              fieldLabel={component.field_component_title?.processed}
                              isHtmlLabel
                            />
                          )}
                        </Field>
                      </Form.Group>
                    )
                  } else if (component.field_component_title_1 === 'addressLine1') {
                    return (
                      <Form.Group key={index}>
                        <Field name={component.field_component_title_1}>
                          {({ input, meta }) => (
                            <InputField
                              required={component.field_status}
                              meta={meta}
                              {...input}
                              width={isMobileWidth ? 310 : 632}
                              height={56}
                              id={component?.field_component_title_1}
                              placeholder={component.field_component_title_2 || ''}
                              fieldLabel={component.field_component_title?.processed}
                              isHtmlLabel
                            />
                          )}
                        </Field>
                      </Form.Group>
                    )
                  } else if (component.field_component_title_1 === 'city') {
                    return (
                      <Form.Group key={index}>
                        <Field name={component.field_component_title_1}>
                          {({ input, meta }) => (
                            <InputField
                              required={component.field_status}
                              meta={meta}
                              {...input}
                              width={isMobileWidth ? 310 : 632}
                              height={56}
                              id={component?.field_component_title_1}
                              placeholder={component.field_component_title_2 || ''}
                              fieldLabel={component.field_component_title?.processed}
                              isHtmlLabel
                            />
                          )}
                        </Field>
                      </Form.Group>
                    )
                  } else if (component.field_component_title_1 === 'state') {
                    return (
                      <Form.Group key={index}>
                        <Field name={component.field_component_title_1}>
                          {({ input, meta }) => (
                            <SelectField
                              meta={meta}
                              input={input}
                              width={isMobileWidth ? 310 : 632}
                              height={56}
                              required={component.field_status}
                              placeholder={component.field_component_title_2 || ''}
                              options={statesOptions}
                              fieldLabel={component.field_component_title?.processed}
                            />
                          )}
                        </Field>
                      </Form.Group>
                    )
                  } else if (component.field_component_title_1 === 'country') {
                    return (
                      <Form.Group key={index}>
                        <Field name={component.field_component_title_1}>
                          {({ input, meta }) => (
                            <SelectField
                              meta={meta}
                              input={input}
                              width={isMobileWidth ? 310 : 632}
                              height={56}
                              required={component.field_status}
                              placeholder={component.field_component_title_2 || ''}
                              options={countriesOptions}
                              fieldLabel={component.field_component_title?.processed}
                              handleOnChange={(value) => {
                                setSelectedCountry(value)
                                getStatesByCountries(value)
                                input.onChange(value)
                              }}
                              defaultValue={selectedCountry}
                            />
                          )}
                        </Field>
                      </Form.Group>
                    )
                  } else if (component.field_component_title_1 === 'phoneNumber') {
                    return (
                      <Form.Group key={index}>
                        <Field name={component.field_component_title_1}>
                          {({ input, meta }) => (
                            <MaskedField
                              required={component.field_status}
                              meta={meta}
                              {...input}
                              width={isMobileWidth ? 310 : 632}
                              height={56}
                              placeholder={component.field_component_title_2 || ''}
                              fieldLabel={component.field_component_title?.processed}
                              mask='(999) 999-9999'
                              maskChar={null}
                            />
                          )}
                        </Field>
                      </Form.Group>
                    )
                  } else if (component.field_component_title_1 === 'birthDate') {
                    return (
                      <Form.Group key={index}>
                        <Field name={component.field_component_title_1}>
                          {({ input, meta }) => (
                            <MaskedField
                              required={component.field_status}
                              meta={meta}
                              {...input}
                              width={isMobileWidth ? 310 : 632}
                              height={56}
                              fieldLabel={component.field_component_title?.processed}
                              mask='99/99/9999'
                              customOnChange={handleDateChange}
                              placeholder='     /        /'
                              maskChar={null}
                              autoComplete='one-time-code'
                            />
                          )}
                        </Field>
                      </Form.Group>
                    )
                  } else if (component.field_component_title_1 === 'language') {
                    return (
                      <Form.Group key={index}>
                        <Field name={component.field_component_title_1}>
                          {({ input, meta }) => (
                            <SelectField
                              meta={meta}
                              input={input}
                              width={isMobileWidth ? 310 : 632}
                              height={56}
                              required={component.field_status}
                              placeholder={component.field_component_title_2 || ''}
                              options={languageOptions}
                              fieldLabel={component.field_component_title?.processed}
                            />
                          )}
                        </Field>
                      </Form.Group>
                    )
                  } else if (component.field_component_title_1 === 'gender') {
                    return (
                      <Form.Group key={index}>
                        <Field name={component.field_component_title_1}>
                          {({ input, meta }) => (
                            <SelectField
                              meta={meta}
                              input={input}
                              width={isMobileWidth ? 310 : 632}
                              height={56}
                              required={component.field_status}
                              placeholder={component.field_component_title_2 || ''}
                              options={genderDropDown}
                              fieldLabel={component.field_component_title?.processed}
                            />
                          )}
                        </Field>
                      </Form.Group>
                    )
                  } else if (component.field_component_title_1 === 'ethnicity') {
                    return (
                      <Form.Group key={index}>
                        <Field name={component.field_component_title_1}>
                          {({ input, meta }) => (
                            <SelectField
                              meta={meta}
                              input={input}
                              width={isMobileWidth ? 310 : 632}
                              height={56}
                              required={component.field_status}
                              placeholder={component.field_component_title_2 || ''}
                              options={ethnicityDropdown}
                              fieldLabel={component.field_component_title?.processed}
                            />
                          )}
                        </Field>
                      </Form.Group>
                    )
                  }
                  //----------To be implemented later, post MVP----------

                  // else if(component.field_component_title_1 === 'Preferred Communication') {
                  //   return (
                  //     <Form.Group key={index}>
                  //       <Field name={component.field_component_title_1}>
                  //         {({ input, meta }) => (
                  //             <SelectField
                  //               meta={meta}
                  //               input={input}
                  //               width={isMobileWidth ? 310 : 632}
                  //               height={56}
                  //               required={component.field_status}
                  //               placeholder={component.field_component_title_2 || ''}
                  //               options={languageOptions}
                  //               fieldLabel={component.field_component_title?.processed}
                  //             />
                  //           )
                  //         }
                  //       </Field>
                  //     </Form.Group>
                  //   )
                  // }
                  else if (component.field_component_title_1 === 'phoneType') {
                    return (
                      <Form.Group key={index}>
                        <Field name={component.field_component_title_1}>
                          {({ input, meta }) => (
                            <SelectField
                              meta={meta}
                              input={input}
                              width={isMobileWidth ? 310 : 632}
                              height={56}
                              required={component.field_status}
                              placeholder={component.field_component_title_2 || ''}
                              options={phoneTypeDropdown}
                              fieldLabel={component.field_component_title?.processed}
                              handleOnChange={(value) => {
                                setSelectedPhoneType(value)
                                input.onChange(value)
                              }}
                              defaultValue={selectedPhoneType}
                            />
                          )}
                        </Field>
                      </Form.Group>
                    )
                  } else if (component.field_input_type === 'checkbox') {
                    return (
                      <Form.Group key={index}>
                        <Field name={component.field_component_title_1} type='checkbox'>
                          {({ input, meta }) => (
                            <CustomCheckbox
                              input={input}
                              meta={meta}
                              isRequired={component.field_status}
                              checkboxId={component.field_component_title_1}
                              label={component.field_component_title?.processed}
                            />
                          )}
                        </Field>
                      </Form.Group>
                    )
                  } else if (component.field_component_title_1 === 'loginId') {
                    return (
                      <Form.Group className='NonEditableFieldGroup' key={index}>
                        <Field name={component.field_component_title_1}>
                          {({ input, meta }) => (
                            <InputField
                              required
                              meta={meta}
                              {...input}
                              width={isMobileWidth ? 310 : 632}
                              height={56}
                              id={component?.field_component_title_1}
                              fieldLabel={component.field_component_title?.processed}
                              isHtmlLabel
                              disabled
                            />
                          )}
                        </Field>
                        <div className='NonEditableTextContainer'>
                          <Image className='GrayInfoIcon' src={GrayInfoIcon} width='19' height='19' />
                          <span className='NonEditableText'>{t('FieldCannotBeUpdatedMsg')}</span>
                        </div>
                      </Form.Group>
                    )
                  } else if (component.field_component_title_1 === 'password') {
                    return (
                      <div key={index}>
                        <Form.Group>
                          <Field name={component.field_component_title_1}>
                            {({ input, meta }) => {
                              return (
                                <InputField
                                  required
                                  meta={meta}
                                  {...input}
                                  width={isMobileWidth ? 310 : 632}
                                  height={56}
                                  id={component?.field_component_title_1}
                                  customOnChange={handlePasswordChange}
                                  type={passwordReveal ? 'text' : 'password'}
                                  fieldLabel={component.field_component_title?.processed}
                                  isHtmlLabel
                                  autoComplete='one-time-code'
                                />
                              )
                            }}
                          </Field>
                        </Form.Group>
                        <PasswordValidation passwordInput={passwordInput} formType='AccountCreate' />
                      </div>
                    )
                  } else if (component.field_hero_template_type === 'hero_template_3' && loyaltyTnCsShow) {
                    return (
                      <Field key={index} name={component.field_hero_template_type}>
                        {({ input, meta }) => {
                          const tempComponent = {
                            ...component,
                            checkboxId: 'isLoyaltyMember',
                            input,
                            meta
                          }
                          return <JoinMyBiolifeRewards {...tempComponent} />
                        }}
                      </Field>
                    )
                  } else if (component.type === 'form_cta_component') {
                    return (
                      <Form.Group key={index} className='ButtonContainer'>
                        <PrimaryButton text={component.field_cta_1?.title} variant={component?.field_cta_1_style} type='submit' />
                      </Form.Group>
                    )
                  }
                } else {
                  return renderDrupalComponent(component, index)
                }
              })}
              {errorMessages && (
                <Message className='ValidationErrorBox' style={!showAgeError ? { marginBottom: '80px' } : null}>
                  {errorMessages?.hasOwnProperty('map') ?
                    errorMessages.map((message, index) => (<p key={index}>{message}</p>)) : errorMessages
                  }
                </Message>
              )}
              {showAgeError && (
                <Message className='ValidationErrorBox' style={!errorMessages ? { marginBottom: '80px' } : null}>
                  <p>{t('ageRestrictionMsg')}</p>
                </Message>
              )}
            </Form>
          )}
        />
      </div>
    )
  }
)

export default AccountCreateForm
