import styled from 'styled-components'
import importer from '@takedapdt/biolife-core/src/importer'
const { getCssColorFromDrupalColorType } = importer('Constant/Utils', null)
const { devices } = importer('utils/styledComponentHelpers', null)

const alignments = {
  1: 'row-reverse', // Left
  2: 'row', // Right
  3: 'column-reverse', // Top
  4: 'column' // Bottom
}

export const Container = styled.div`
  background: ${({ bgColor }) => bgColor};
`

export const ContainerWithFlex = styled(Container)`
  display: flex;
  flex-direction: ${({ imageAlignment, direction }) =>
    direction ? (imageAlignment === 3 ? 'column-reverse' : 'column') : alignments[imageAlignment]};

  ${devices.mobile} {
    flex-direction: ${({ imageAlignment }) => ((imageAlignment === 1 || imageAlignment === 3) ? 'column-reverse' : 'column')};
  }
`

export const RightContainer = styled.div`
  display: flex;
  margin-left: ${({ imageAlignment }) => (imageAlignment === 2 ? '2.4rem' : '0')};
  margin-right: ${({ imageAlignment }) => (imageAlignment === 2 ? '0' : imageAlignment === 1 ? '2.4rem' : '0')};
  margin-top: ${({ imageAlignment }) => (imageAlignment === 3 ? '0' : '2.4rem')};
  margin-bottom: ${({ imageAlignment }) => (imageAlignment === 4 ? '0' : '2.4rem')};
  justify-content: ${({ imageAlignment }) => (imageAlignment === 3 || 4 ? 'center' : 'flex-start')};

  ${devices.mobile} {
    justify-content: center;
    margin-right: 0px;
    margin-left: 0px;
    margin-top: ${({ imageAlignment }) => (imageAlignment === 1 || 2 ? '2.4rem' : '0')};
  }
`

export const LeftContainer = styled.div`
  margin-right: ${({ imageAlignment }) => (imageAlignment === 2 ? '2.4rem' : '0')};
  margin-left: ${({ imageAlignment }) => (imageAlignment === 2 ? '0' : imageAlignment === 1 ? '2.4rem' : '0')};
  margin-top: ${({ imageAlignment }) => (imageAlignment === 3 ? '2.4rem' : '0')};
  margin-bottom: ${({ imageAlignment }) => (imageAlignment === 4 ? '2.4rem' : '0')};
  justify-content: ${({ imageAlignment }) => (imageAlignment === 3 || 4 ? 'center' : 'flex-start')};

  ${devices.mobile} {
    margin-left: 0px;
    margin-right: 0px;
    margin-top: ${({ imageAlignment }) => (imageAlignment === 1 || 2 ? '2.4rem' : '0')};
  }
`
