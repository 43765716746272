export const ROUTES = {
  /* AUTH */
  AuthConfirmSignUp: 'AuthConfirmSignUp',
  AuthForgotPassword: '/donor-forgot-password',
  AuthResetPassword: '/reset-password',
  AuthSignIn: '/login',
  AuthSignUp: '/registration',
  AuthAuthorize: '/authorize',
  /* DASHBOARD */
  Dashboard: '/dashboard',
  DonorDashboard: '/dashboard/donor-dashboard',
  ChooseDonationCenter: '/dashboard/choose-donation-center',
  CenterDashboard: '/center-ops/donor-search',
  CreateProfile: 'CreateProfile',
  EmailConfirmation: '/email-confirmation',
  NoCenters: '/',
  Welcome: '/',
  SignIn: '/login',
  LoyaltyNotEligible: '/not-eligible-loyalty',
  LoyaltyDashboard: '/dashboard/my-rewards',
  NoCenterZone: '/no-center-zone',
  CheckEmail: '/check-email',
  VerifyEmail: '/verify-email',
  CreateAccount: '/create-account',
  Locations: '/locations',
  SocialLogin: '/social-login',
  VerifyAccountFromEmail: '/verify-account-from-email',
  ShareWithUs: '/share-with-us',
  RewardsHistory: '/dashboard/my-rewards/reward-history',
  Preview: '/preview',
  AppointmentDetails: '/dashboard/my-appointments/appointment-detail',
  Debit: 'dashboard/my-balances/debit',
  MyRewards: 'dashboard/my-balances/my-rewards',
  MyBalances: 'dashboard/my-balances',
  BalancesSlug: 'my-balances',
  ContactUs: 'contact-us',
  AppointmentScheduling: '/appointment-scheduling',
  AccountSetupSuccess: '/account-setup-success',
  DebitHistory: '/dashboard/debit/debit-history',
  PaymentHistory: '/dashboard/debit/payment-history',
  Settings: '/dashboard/settings'
}

export const navigate = (routeName) => {
  window.location.href = routeName
}

export const navigateCustom = (route) => {
  window.location.href = route
}

export const genAuthUrl = (redirect) => {
  const local = window.location.href
  const arr = local.split('/')
  const returnUrl = arr[0] + '//' + arr[2] + redirect
  // console.log(returnUrl)
  return encodeURIComponent(returnUrl)
}

// Static URL for careers link in Spanish.
export const CareersSpanishURL = 'https://info.biolifeplasma.com/carreras'
