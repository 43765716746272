import React from 'react'
import importer from 'common/importer'
import ContactUsForm from '../Components/Forms/ContactUsForm'
const AppointmentSchedule = importer('Components/Dashboard/AppointmentSchedule')
const Footer = importer('Components/Footer')
const CompensationAdvertisement = importer('Components/CompensationAdvertisement')
const GridTemplate2 = importer('Components/TextGrid/GridTemplate2')
const GridTemplate3 = importer('Components/TextGrid/GridTemplate3')
const GridTemplate4 = importer('Components/TextGrid/GridTemplate4')
const FindYourCentreBanner = importer('Components/FindYourCentreBanner')
const WhyChooseBioLife = importer('Components/WhyChooseBiolife')
const RegistrationFormComponent = importer('Components/RegistrationFormComponent')
const Hero = importer('Components/Hero')
const HeroTemplate6 = importer('Components/Hero/HeroTemplate6')
const Statistic = importer('Components/Statistic')
const TextComponent = importer('Components/TextComponent')
const AboutPlasmaDonation = importer('Components/AboutPlasmaDonation')
const GridTextImage = importer('Components/GridTextImage')
const LogInForm = importer('Components/Forms/LogInForm')
const OrangeBannerWithTitle = importer('Components/OrangeBannerWithTitle')
const Breadcrumbs = importer('Components/Breadcrumbs')
const ForgotPasswordForm = importer('Components/Forms/ForgotPasswordForm')
const CenterStatesList = importer('Components/CenterStatesList')
const TextCTAGrid = importer('Components/TextCTAGrid')
const TextCTAGridTemplate2 = importer('Components/TextCTAGrid/TextCTAGridTemplate2')
const ButtonCTA = importer('Components/ButtonCTA')
const Separator = importer('Components/Separator')
const Rows = importer('Components/Rows')
const Stepper = importer('Components/SignUp/Stepper')
const SignUpForm = importer('Components/Forms/SignUpForm')
const Accordion = importer('Components/Accordion')
const SignUpPage = importer('Components/SignUp')
const CentersInAStateList = importer('Components/CentersInAStateList')
const FormConfirmation = importer('Components/FormConfirmation')
const IntegrationTextContent = importer('Components/IntegrationTextContent')
const VerifyAccountForm = importer('Components/Forms/VerifyAccountForm')
const TextMediaTemplate3 = importer('Components/TextMedia/TextMediaTemplate3')
const HeroTemplate4 = importer('Components/Hero/HeroTemplate4')
const AccordionWithMultiComponent = importer('Components/AccordionWithMultiComponent')
const AccordionWithSliderComponent = importer('Components/AccordionWithSliderComponent')
const ImageBannerTemplate1 = importer('Components/ImageBanner/ImageBannerTemplate1')
const ImageBannerTemplate2 = importer('Components/ImageBanner/ImageBannerTemplate2')
const ImageBannerTemplate3 = importer('Components/ImageBanner/ImageBannerTemplate3')
const AccordionGridComponent = importer('Components/AccordionGridComponent')
const SocialLoginForm = importer('Components/Forms/SocialLoginForm')
const TextMediaTemplate4 = importer('Components/TextMediaTemplate4')
const HeroTemplate5 = importer('Components/HeroTemplate5')
const AnnouncementBanners = importer('Components/AnnouncementBanners')
const BonusGrid = importer('Components/BonusGrid')
const CenterNameWithMap = importer('Components/CenterNameWithMap')
const CenterPageTopComponent = importer('Components/CenterPageTopComponent')
const AdverstisementCardContainer = importer('Components/AdverstisementCardContainer')
const NearbyCentersList = importer('Components/NearbyCentersList')
const CardTemplate4 = importer('Components/Card/CardTemplate4')
const CardTemplate5 = importer('Components/Card/CardTemplate5')
const DonationImpact = importer('Components/DonationImpact')
const TextMediaTemplate1 = importer('Components/TextMediaTemplate1')
const CardTemplate2 = importer('Components/CardTemplate2')
const CardTemplate3 = importer('Components/CardTemplate3')
const ChecklistComponent = importer('Components/ChecklistComponent')
const AccordionImage = importer('Components/AccordionImage')
const CardComponent = importer('Components/CardComponent')
const TextMediaTemplate5 = importer('Components/TextMediaTemplate5')
const UpcomingAppointments = importer('Components/UpcomingAppointments')
const AvailableAppointments = importer('Components/AvailableAppointments')
const MyAppointments = importer('Components/MyAppointments')
const RewardsHistoryComponent = importer('Components/RewardsHistoryComponent')
const BlogPage = importer('Components/BlogPage')
const ShareWithUsForm = importer('Components/Forms/ShareWithUsForm')
const MyAcheivements = importer('Components/BiolifeRewards/MyAcheivements')
const TopEarningActivity = importer('Components/BiolifeRewards/TopEarningActivity')
const RecentRewardActivity = importer('Components/BiolifeRewards/RecentRewardActivity')
const BiolifeRewards = importer('Components/BiolifeRewards')
const PatientTestimonials = importer('Components/PatientTestimonials')
const DashboardBanner = importer('Components/Dashboard/DashboardBanner')
const CardTemplate6 = importer('Components/Card/CardTemplate6')
const HeroTemplate7 = importer('Components/Hero/HeroTemplate7')
const NewsPage = importer('Components/NewsPage')
const BlogView = importer('Components/BlogPage/BlogView')
const ImageComponent = importer('Components/ImageComponent')
const SecondSliderTestimonialTemplate = importer('Components/SecondSliderTestimonialTemplate')
const QuoteComponent = importer('Components/QuoteComponent')
import AppointmentHistory from '../Components/AppointmentHistory'
import MessagesSection from '../Components/DashboardMessagesContainer/MessagesSectionContainer'
import ConditionalTextComponent from '../Components/ConditionalTextComponent'
import DebitCard from '../Components/DebitCard'
import RecentPayementActivity from '../Components/RecentPayementActivity'
import PaymentHistoryPage from '../Components/PaymentHistoryPage'
import DebitHistoryPage from '../Components/DebitHistoryPage'
import CenterCityList from '../Components/CenterCityList'
import SitemapNavigationMenu from '../Components/SitemapNavigationMenu'
import AboutAuthorComponent from '../Components/AboutAuthorComponent'
import JoinMyBiolifeRewards from '../Components/JoinMyBiolifeRewards'

const checkTemplateType = (component) => {
  switch (component.type) {
    case 'card_component':
      return component.field_card_template_type
    case 'column_with_text_component':
      return component.field_grid_template_type
    case 'hero_with_list_component':
      return component.field_list_template_type
    case 'hero':
      return component.field_hero_template_type
    case 'ext_integration_component':
      return component.field_integration_type
    case 'text_media':
      return component.field_text_media_type
    case 'text_cta_grid':
      return component.field_txtgrid_template_type
    case 'image_banner_component':
      return component.field_img_banner_template
    case 'accordion_component':
      return component.field_accordion_type
    case 'reference_content_component':
      return component.field_testimonial_template_type
    default:
      return component.type
  }
}

const DrupalComponentMapper = {
  blog_list: BlogPage,
  news_list: NewsPage,
  checklist_component: ChecklistComponent,
  card_template_3: CardTemplate3,
  card_template_2: CardTemplate2,
  list_template_1: CompensationAdvertisement,
  grid_template_2: GridTemplate2,
  grid_template_3: GridTemplate3,
  grid_template_4: GridTemplate4,
  statistic_component: Statistic,
  search_component: FindYourCentreBanner,
  grid_template_1: WhyChooseBioLife,
  registration_form_component: RegistrationFormComponent,
  hero_template_1: Hero,
  hero_template_6: HeroTemplate6,
  text_component: TextComponent,
  hero_template_2: AboutPlasmaDonation,
  list_template_2: GridTextImage,
  footer_component: Footer,
  form_data_login: LogInForm,
  'form_data_about-biolife/contact-us': ContactUsForm,
  'form_data_donor-forgot-password': ForgotPasswordForm,
  'form_data_reset-password': VerifyAccountForm,
  'form_data_share-with-us': ShareWithUsForm,
  text_banner: OrangeBannerWithTitle,
  breadcrumb: Breadcrumbs,
  states_list: CenterStatesList,
  city_list: CenterCityList,
  sitemap_navigation_menu: SitemapNavigationMenu,
  txtgrid_template_1: TextCTAGrid,
  txtgrid_template_2: TextCTAGridTemplate2,
  cta_component: ButtonCTA,
  separator_component: Separator,
  row: Rows,
  progress_bar_component: Stepper,
  accordion_component: Accordion,
  form_data_registration: SignUpForm,
  signup_step1_page: SignUpPage,
  centers_in_a_state_list: CentersInAStateList,
  accordion_template_1: Accordion,
  form_confirmation: FormConfirmation,
  accordion_template_2: AccordionImage,
  form_data_registration: SignUpForm,
  signup_step1_page: SignUpPage,
  progress_bar_component: Stepper,
  separator_component: Separator,
  txt_media_template_1: TextMediaTemplate1,
  progress_bar_component: Stepper,
  integration_content_component: IntegrationTextContent,
  txt_media_template_3: TextMediaTemplate3,
  hero_template_4: HeroTemplate4,
  accodion_with_multi_components: AccordionWithMultiComponent,
  accordion_with_slider_component: AccordionWithSliderComponent,
  img_banner_template_1: ImageBannerTemplate1,
  img_banner_template_2: ImageBannerTemplate2,
  img_banner_template_3: ImageBannerTemplate3,
  accordian_grid_component: AccordionGridComponent,
  'form_data_social-login': SocialLoginForm,
  txt_media_template_4: TextMediaTemplate4,
  hero_template_5: HeroTemplate5,
  announcement_component: AnnouncementBanners,
  bonus_grid_component: BonusGrid,
  center_name_with_map: CenterNameWithMap,
  center_page_top_component: CenterPageTopComponent,
  advertisement_card_component: AdverstisementCardContainer,
  nearby_centers_list: NearbyCentersList,
  card_template_4: CardTemplate4,
  card_template_5: CardTemplate5,
  txt_media_template_2: DonationImpact,
  card_template_1: CardComponent,
  txt_media_template_5: TextMediaTemplate5,
  upcoming_appointments: UpcomingAppointments,
  next_appointments: AvailableAppointments,
  my_appointments_stats: MyAppointments,
  reward_history: RewardsHistoryComponent,
  my_achievements: MyAcheivements,
  recent_reward_activities: RecentRewardActivity,
  top_earning_activities: TopEarningActivity,
  biolife_rewards_component: BiolifeRewards,
  testimonial_template_1: PatientTestimonials,
  testimonial_template_2: SecondSliderTestimonialTemplate,
  dashboard_banner: DashboardBanner,
  card_template_6: CardTemplate6,
  hero_template_7: HeroTemplate7,
  appointment_scheduler: AppointmentSchedule,
  debit_stats: DebitCard,
  recent_payment_activity: RecentPayementActivity,
  image_component: ImageComponent,
  quote_component: QuoteComponent,
  related_article: BlogView,
  appointment_history: AppointmentHistory,
  dashboard_messages: MessagesSection,
  donor_text_component: ConditionalTextComponent,
  payment_history: PaymentHistoryPage,
  debit_history: DebitHistoryPage,
  about_author_component: AboutAuthorComponent,
  hero_template_3: JoinMyBiolifeRewards
}

const renderDrupalComponent = (component, index) => {
  const componentType = checkTemplateType(component)
  const Component = DrupalComponentMapper[componentType]
  if (Component) {
    return <Component key={index} {...component} />
  }
  console.warn(`Component type "${componentType}" not found in DrupalComponentMapper`)
  return false //can also show something similar to the NotFound component
}

export default renderDrupalComponent
