import React, { useEffect, useState } from 'react'
import { useScreenSize } from './../../../Contexts/ResponsiveContextProvider'
import i18next from 'i18next'
import styled from 'styled-components'
import { Form, Message, Image } from 'semantic-ui-react'
import { Form as FinalForm, Field } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import GrayInfoIcon from '../../../../assets/media/icons/UI-circle-error.svg'
import MaskedField from '../../../Components/Forms/FormFields/MaskedField'
import SelectField from '../../../Components/Forms/FormFields/SelectField'
import PrimaryButton from '../../../Components/Buttons/PrimaryButton'
import UserActions from '@takedapdt/biolife-core/src/Stores/Users/Actions'
import MFAModal from '../MFAModal'
import { maskedPhoneNumber } from '../../../Helpers/Utils'
import { devices } from '../../../utils/styledComponentHelpers'

const StyledContainer = styled.div`
  width: 64%;
  ${devices.tablet} {
    width: 100%;
  }
`

const ErrorText = styled.p`
  color: var(--color-validation-red);
`

const StyledSpacingDiv = styled.div`
  margin: ${({ $margin }) => $margin};
`
const StyledDiv = styled.div`
  display: flex;
  justify-content: flex-start;
  /* align-items: center; */
`

const Styledp = styled.p`
  color: var(--color-rich-black);
  margin-top: ${({ $marginTop }) => $marginTop};
  margin-left: ${({ $marginLeft }) => $marginLeft};
`

const StyledFormGroup = styled(Form.Group)`
  display: flex;

  .mfa-dropdown-style {
    min-height: 56px !important;
  }
  .default.text {
    min-height: 0px !important;
  }
`
const ImageContainer = styled.div`
  margin-top: 0.5rem;
`
const EnrollSMSMFAForm = ({
  updateCheckboxState,
  checkboxSate,
  donor,
  countries,
  userOktaId,
  enrollMfa,
  enrollMfaLoading,
  enrollMfaSuccess,
  enrollMfaFailure
}) => {
  const { t } = useTranslation('EnrollMFAForm')
  const { isMobileWidth, isTabletWidth } = useScreenSize()
  const isSmsMfaEnrolled = donor?.isSmsEnrolled === 'Yes'
  const [isOptingIn, setIsOptingIn] = useState(checkboxSate?.isMFASms || isSmsMfaEnrolled)
  const [otpModalOpen, setOtpModalOpen] = useState(false)
  const [countryIsdCode, setCountryIsdCode] = useState('')
  const [phoneNumber, setPhoneNumber] = useState(donor?.phoneNumber)
  const [isSubmitted, setIsSubmitted] = useState(false)
  const phoneNumberRegex = (value) => value?.replace(/\D/g, '')
  const validate = (values) => {
    const errors = {}
    if (values.phoneNumber) {
      if (phoneNumberRegex(values.phoneNumber).replace(/\D/g, '').length !== 10) {
        errors.phoneNumber = 'phoneNumberMustHave10Digit'
      }
    } else {
      errors.phoneNumber = 'phoneNumberCannotBeEmpty'
    }
    if (values?.countryIsd) {
      if (!values.countryIsd) {
        errors.countryIsd = 'countryIsdCannotBeEmpty'
      }
    }
    return errors
  }

  const prepareCountryIsdOptions = () => {
    return countries
      ?.sort((a, b) => (a.display_order < b.display_order ? -1 : 1))
      ?.map((country) => ({ key: country?.code, value: `${country?.code} ${country?.isd}`, text: `${country?.code} ${country?.isd}` }))
  }

  const handleSubmit = (values) => {
    setIsSubmitted(true)
    enrollMfa(userOktaId, `${countryIsdCode}${values.phoneNumber.replace(/\D/g, '')}`)
  }

  const handleCountryIsdCodeChange = (value) => {
    setCountryIsdCode(value.split(' ')[1])
  }

  const initialValues = {
    phoneNumber: donor?.phoneNumber
  }

  useEffect(() => {
    if (isSubmitted && enrollMfaSuccess) {
      setOtpModalOpen(true)
    } else {
      setOtpModalOpen(false)
    }
  }, [enrollMfaSuccess])

  return (
    <>
      <StyledContainer>
        <FinalForm
          onSubmit={handleSubmit}
          validate={validate}
          initialValues={initialValues}
          render={({ handleSubmit, values }) => (
            <>
              <Form onSubmit={handleSubmit}>
                <StyledFormGroup $isMobileWidth={isMobileWidth}>
                  <Field name='countryIsd'>
                    {({ input, meta }) => (
                      <SelectField
                        className={'mfa-dropdown-style'}
                        required
                        meta={meta}
                        input={input}
                        placeholder={isSmsMfaEnrolled ? '' : t('select')}
                        usedFor={'ProfileSettings'}
                        height={56}
                        width={isMobileWidth ? '100%' : i18next.language === 'en-US' ? 143 : 160}
                        minWidth={isMobileWidth ? '100%' : 160}
                        options={prepareCountryIsdOptions()}
                        fieldLabel={t('countryCode')}
                        disabled={isSmsMfaEnrolled}
                        handleOnChange={(value) => {
                          input.onChange(value)
                          handleCountryIsdCodeChange(value)
                        }}
                      />
                    )}
                  </Field>
                  <Field name='phoneNumber'>
                    {({ input, meta }) => (
                      <MaskedField
                        required
                        meta={meta}
                        {...input}
                        width={isMobileWidth ? '100%' : isTabletWidth ? '100%' : 465}
                        height={56}
                        fieldLabel={t('phoneNumber')}
                        mask='(999) 999-9999'
                        maskChar={null}
                        disabled={isSmsMfaEnrolled}
                        usedFor={'ProfileSettings'}
                        customOnChange={(e) => setPhoneNumber(e.target.value.replace(/\D/g, ''))}
                      />
                    )}
                  </Field>
                </StyledFormGroup>
                {isSmsMfaEnrolled ? (
                  <StyledDiv>
                    <ImageContainer>
                      <img className='GrayInfoIcon' src={GrayInfoIcon} width='16' height='16' />
                    </ImageContainer>
                    <Styledp $marginLeft='1.2rem'>{t('nonEditableFieldText')}</Styledp>
                  </StyledDiv>
                ) : (
                  <StyledSpacingDiv $margin='4rem 0rem 0rem 0rem'>
                    <PrimaryButton
                      text={t('verify')}
                      type='submit'
                      onClick={handleSubmit}
                      loading={enrollMfaLoading}
                      style={{ padding: '1.6rem 4.8rem' }}
                      disabled={
                        !(values?.countryIsd && values?.phoneNumber && phoneNumberRegex(values.phoneNumber).length === 10) ||
                        enrollMfaLoading
                      }
                      fluid={isMobileWidth}
                    />
                  </StyledSpacingDiv>
                )}
              </Form>
            </>
          )}
        />
        {enrollMfaFailure && isOptingIn && isSubmitted && (
          <Message negative>
            <ErrorText>{enrollMfaFailure?.message}</ErrorText>
          </Message>
        )}
      </StyledContainer>
      <MFAModal
        open={otpModalOpen}
        onClose={() => setOtpModalOpen(false)}
        maskedPhoneNumber={maskedPhoneNumber({ countryIsdCode, phoneNumber })}
        unMaskedPhoneNumber={`${countryIsdCode}${phoneNumber}`}
      />
    </>
  )
}

const mapStateToProps = (state) => ({
  countries: state?.publicContent?.Countries,
  userOktaId: state.users?.user?.oktaUserId,
  enrollMfaLoading: state.users?.enrollMfaLoading,
  enrollMfaSuccess: state.users?.enrollMfaSuccess,
  enrollMfaFailure: state.users?.enrollMfaFailure?.response?.data,
  donor: state.donors?.donors?.data
})

const mapDispatchToProps = (dispatch) => ({
  enrollMfa: (userId, phoneNumber) => dispatch(UserActions.enrollMfa(userId, phoneNumber))
})

export default connect(mapStateToProps, mapDispatchToProps)(EnrollSMSMFAForm)
