import styled from 'styled-components'
import importer from '@takedapdt/biolife-core/src/importer'
const { getCssColorFromDrupalColorType, setTextColor } = importer('Constant/Utils', null)
const { devices } = importer('utils/styledComponentHelpers', null)

export const TextComponent = styled.p`
  ${({ color }) => setTextColor(color)};
  font-family: ${({ fontFamily }) => (fontFamily ? `${fontFamily} !important` : 'AvenirNextBold !important')};
  margin-bottom: ${({ marginBottom }) => marginBottom};
  padding-right: ${({ paddingRight }) => paddingRight};
  border-right: ${({ borderRight }) => borderRight};
  text-decoration: ${({ textDecoration }) => textDecoration || 'none'};
`

export const Highlight4 = styled.h4`
  ${({ color }) => setTextColor(color)};
  font-family: 'AvenirNextBold' !important;
  margin-bottom: ${({ marginBottom }) => marginBottom};
  word-break: ${({ wordBreak }) => wordBreak};
`

export const Highlight5 = styled.h5`
  ${({ color }) => setTextColor(color)};
  font-family: 'AvenirNextBold' !important;
  margin: ${({ margin }) => margin || '0'};
`

export const Container = styled.div`
  background: ${({ bgColor }) => getCssColorFromDrupalColorType(bgColor)};
  padding: ${({ padding }) => padding};
  height: ${({ minHeight }) => minHeight};
  margin-bottom: ${({ marginBottom }) => marginBottom};
  position: ${({ position }) => position};
`

export const ContainerWithFlex = styled.div`
  display: flex;
  background: ${({ bgColor }) => getCssColorFromDrupalColorType(bgColor)};
  flex-direction: ${({ direction }) => direction || 'row'};
  align-items: ${({ align }) => align};
  border-radius: ${({ borderRadius }) => borderRadius};
  padding: ${({ padding }) => padding};
  position: ${({ position }) => position};
  max-width: ${({ maxWidth }) => maxWidth};
  box-shadow: ${({ boxShadow }) => boxShadow};

  ${devices.tablet} {
    max-width: ${({ maxWidth }) => maxWidth};
    box-shadow: ${({ boxShadow }) => boxShadow};
    padding: ${({ padding }) => padding};
    position: ${({ position }) => position};
  }
  ${devices.mobile} {
    max-width: ${({ maxWidth }) => maxWidth};
    box-shadow: ${({ boxShadow }) => boxShadow};
    padding: ${({ padding }) => padding};
    bottom: ${({ bottom }) => bottom};
    position: ${({ position }) => position};
  }
`
