import styled from 'styled-components'
import { devices } from '../../../utils/styledComponentHelpers'

export const StyledCalendarDayButton = styled.button`
  border: 0;
  padding: 0;
  outline: none;
  cursor: pointer;
  color: ${(props) => (props?.$selectedDate ? 'var(--color-white)' : 'var(--color-dark-focus-blue)')};
  background-color: ${(props) =>
    props.$selectedDate ? (props?.$disabledDate ? 'var(--color-dark-grey)' : 'var(--color-orange)') : 'transparent'};
  border-radius: ${(props) => (props.$selectedDate ? '50%' : 'none')};
  width: 6.4rem;
  height: 6.4rem;
  transition: all 0.5s linear;
`
export const StyledDayLabel = styled.div`
  font-size: var(--caption-mobile-font-size);
  text-transform: uppercase;
`
export const StyledDayNumber = styled.div`
  font-size: var(--h4-font-size);
  text-align: center;
`
