import { put, select } from 'redux-saga/effects'
import importer from '@takedapdt/biolife-core/src/importer'
/* API */
const { mulesoft } = importer('APIs/auth', null)
const { changePassword } = mulesoft
/* ACTIONS */
const Actions = importer('Stores/AuthCustom/Actions')
/* STATE */
const { user, tokens } = importer('Stores/Users/Select', null)
/**
 *
 * @param {Object} param0
 * @param {String} param0.oldPassword Current password for user
 * @param {String} param0.newPassword New password for user
 */
export default function* main({ oldPassword, newPassword }) {
  try {
    const { oktaUserId } = yield select(user)
    const _tokens = yield select(tokens)
    const { token_type, id_token } = _tokens
    const token = `${token_type} ${id_token}`
    yield put(Actions.changePasswordLoading())
    const res = yield changePassword(oktaUserId, oldPassword, newPassword, token)
    
    if (!res.data.isError) {
      yield put(Actions.changePasswordSuccess(res))
    } else {
      yield put(Actions.changePasswordFailure(res))
    }
  } catch (error) {
    yield put(Actions.changePasswordFailure(error))
  }
}
