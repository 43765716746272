import React, { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import renderDrupalComponent from '../Constant/renderDrupalComponent'
import LoaderBar from '../Components/Loaders/LoaderBar'
import { devices } from '../../src/utils/styledComponentHelpers'
import Breadcrumbs from '../Components/Breadcrumbs'
import { getArrayFromTextCTAGrid } from '../../src/Helpers/Utils'
import { ROUTES } from '../../src/NavigationService'
import PaymentsActions from '@takedapdt/biolife-core/src/Stores/Payments/Actions'
import PublicContentActions from '@takedapdt/biolife-core/src/Stores/PublicContent/Actions'
import donorsActions from '@takedapdt/biolife-core/src/Stores/Donors/Actions'

const TabsContainer = styled.div`
  ${devices.mobile} {
    margin-bottom: 1.6rem;
    padding-bottom: 0;
  }
  display: flex;
  padding-top: 2.4rem;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-bottom: 4.8rem;
`

const TabButton = styled.button`
  cursor: pointer;
  border: none;
  background: none;
  padding: 1rem 1.5rem;
  font-size: 1.8rem;
  font-family: ${(props) => (props.active ? 'AvenirNextBold' : 'AvenirNextRegular')}!important;
  font-weight: ${(props) => (props.active ? 'bold' : '100')} !important;
  color: ${(props) => (props.active ? '#f96506' : '#333333')};
  border-bottom: ${(props) => (props.active ? '4px solid' : '4px solid var(--color-light-shaded-grey)')};
  border-image: ${(props) =>
    props.active ? `linear-gradient(90deg, var(--color-dark-shaded-orange) 0%,var(--color-light-shaded-orange) 100%) 30;` : ''};
  width: 50%;
`

const MyBalancesContainer = (props) => {
  const {
    getPostLoginPage,
    PostLoginPageData,
    PostLoginPageDataLoading,
    user,
    getDonors,
    PostLoginPageMetaData,
    donor,
    paymentHistory,
    getPaymentHistory,
    paymentHistoryLoading
  } = props

  const navigate = useNavigate()
  const params = useParams()
  const [activeTab, setActiveTab] = useState(`${ROUTES.MyBalances}/${params.tabsSlug}`)
  const { t } = useTranslation('MyBalancesTabs')
  const [breadcrumbsDrupalData, setBreadcrumbsDrupalData] = useState()
  const [finalData, setFinalData] = useState(PostLoginPageData)
  const [paymentHistoryData, setPaymentHistoryData] = useState()
  const [dataLoaded, setDataLoaded] = useState(false)
  // Fetch donors and payment history based on donor info
  useEffect(() => {
    getDonors(user && user.id)
  }, [])
  useEffect(() => {
    if (window.location.pathname !== ROUTES.DebitHistory) {
      sessionStorage.removeItem('filterOnDebit')
    }
    if (window.location.pathname !== ROUTES.PaymentHistory) {
      sessionStorage.removeItem('filterOnPayment')
    }
    if (donor?.pdn) {
      getPaymentHistory(donor?.pdn, null, null)
    }
  }, [])
  useEffect(() => {
    if (donor?.defaultCenter === null) {
      navigate(ROUTES.ChooseDonationCenter)
    }
  }, [donor])

  useEffect(() => {
    if (donor?.pdn && paymentHistory) {
      setPaymentHistoryData(paymentHistory)
    } else if (!donor?.pdn) {
      setPaymentHistoryData([])
    }
    if (!paymentHistoryLoading) {
      setDataLoaded(true)
    }
  }, [paymentHistory, donor?.pdn, paymentHistoryLoading])
  // Fetch post login page data based on active tab and tabsSlug
  useEffect(() => {
    if (activeTab === ROUTES.Debit || activeTab === ROUTES.MyRewards) {
      setActiveTab(`${ROUTES.MyBalances}/${params.tabsSlug}`)
      getPostLoginPage(`${ROUTES.BalancesSlug}/${params.tabsSlug}`)
    }
  }, [activeTab, params, params?.tabsSlug])

  useEffect(() => {
    if (PostLoginPageData) {
      const dataWithTextCTAGrid = getArrayFromTextCTAGrid(PostLoginPageData)
      if (Object.keys(dataWithTextCTAGrid)?.length) {
        setFinalData(dataWithTextCTAGrid)
      }
    }
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
  }, [PostLoginPageData])
  const handleTabChange = (tab) => {
    setActiveTab(tab)
    // Update URL slug based on the tab clicked
    navigate(`/${tab}`)
  }

  // Set breadcrumbs data
  useEffect(() => {
    let breadcrumbs = PostLoginPageData?.contents?.find((item) => item.type === 'breadcrumb')
    setBreadcrumbsDrupalData(breadcrumbs)
  }, [PostLoginPageData])
  return (
    <div className='MyBalanceContainer'>
      <Helmet>
        <title>{PostLoginPageMetaData?.meta_title}</title>
        <meta name='description' content={`${PostLoginPageMetaData?.meta_description}`} />
        <link rel='canonical' href={`${window.location.origin}${window.location.pathname.toLowerCase()}`} />
      </Helmet>

      {/* Breadcrumbs */}
      <Breadcrumbs
        clickableLevelOne={false}
        breadcrumbToggle={PostLoginPageMetaData?.field_content_breadcrumb}
        breadcrumbMobileToggle={PostLoginPageMetaData?.field_mobile_breadcrumb}
        levelOnePropsTitle={breadcrumbsDrupalData?.field_column_1_url?.title}
      />
      {/* Tab headers */}
      <TabsContainer className='main-container'>
        <TabButton onClick={() => handleTabChange(ROUTES.Debit)} active={activeTab === ROUTES.Debit}>
          {t('Debit')}
        </TabButton>
        <TabButton onClick={() => handleTabChange(ROUTES.MyRewards)} active={activeTab === ROUTES.MyRewards}>
          {t('Rewards')}
        </TabButton>
      </TabsContainer>

      {/* Tab content */}
      <>
        {PostLoginPageDataLoading && dataLoaded && <LoaderBar key='postLoginPageLoader' />}

        {(activeTab === ROUTES.MyRewards || activeTab === ROUTES.Debit) && !PostLoginPageDataLoading && (
          <>
            {finalData?.contents &&
              finalData?.contents?.length > 0 &&
              paymentHistoryData &&
              finalData?.contents?.map((component, index) => {
                if (component.type === 'breadcrumb') {
                  return false // Skip rendering breadcrumb component
                }
                if (component?.type === 'donor_text_component' && paymentHistoryData?.data?.paymentHistory?.length > 0) {
                  return false // Skip rendering donor_text_component if payment history data is available
                }
                if (
                  component?.field_integration_type === 'recent_payment_activity' &&
                  (paymentHistoryData?.data?.paymentHistory?.length === 0 ||
                    paymentHistoryData?.data?.paymentHistory == undefined ||
                    !donor?.pdn)
                ) {
                  return false // Skip rendering recent_payment_activity if payment history data is not available
                }
                return <div key={index}>{renderDrupalComponent(component)}</div>
              })}
          </>
        )}
      </>
    </div>
  )
}

const mapStateToProps = (state) => ({
  PostLoginPageData: state.publicContent?.getPostLoginPageSuccess,
  session: state.users.session,
  loggedInUser: state.users.user,
  user: state.users.loginHerokuSuccess,
  donor: state?.donors?.donors?.data,
  PostLoginPageDataLoading: state.publicContent?.getPostLoginPageLoading,
  PostLoginPageMetaData: state.publicContent?.getPostLoginPageSuccess?.metadata,
  wirecard: state.wirecard.getWirecardInfoSuccess?.data,
  wirecardError: state.wirecard.getWirecardInfoFailure,
  wirecardLoading: state.wirecard.getWirecardInfoLoading,
  centerStatus: state.centers.centerStatus,
  donor: state?.donors?.donors?.data,
  nearbyCenters: state.centers.nearbyCenters,
  paymentHistory: state.payments?.getPaymentHistorySuccess,
  paymentHistoryLoading: state.payments?.getPaymentHistoryLoading,
  paymentHistoryError: state.payments?.getPaymentHistoryFailure
})

const mapDispatchToProps = (dispatch) => ({
  getPostLoginPage: (slug) => dispatch(PublicContentActions.getPostLoginPage(slug)),
  getDonors: (id) => dispatch(donorsActions.getDonors(id)),
  getPaymentHistory: (pdn, startDate, endDate) => dispatch(PaymentsActions.getPaymentHistory(pdn, startDate, endDate))
})

export default connect(mapStateToProps, mapDispatchToProps)(MyBalancesContainer)
