import React from 'react'
import './styles.scss'
import { Grid } from 'semantic-ui-react'
import './styles.scss'
import importer from '@takedapdt/biolife-core/src/importer'

const { useScreenSize } = importer('Contexts/ResponsiveContextProvider', null)
const PrimaryButton = importer('Components/Buttons/PrimaryButton')
const { getCssColorFromDrupalColorType, setTextColor, getMediaFromDrupalMimeType } = importer('Constant/Utils', null)

const HeroTemplate5 = (props) => {
  const {
    field_component_mbg_image: componentMobileImage,
    field_component_bg_image: componentBackgroundImage,
    field_bg_color: componentBackgroundColor,
    field_alignment: componentAlignment,
    field_title_color: componentTitleColor,
    field_short_desc_color: componentShortDescriptonColor,
    field_component_title: componentTitle,
    field_short_description: componentShortDescription,
    field_cta_1: componentCTA1,
    field_cta_1_style: componentCTAStyle1,
    field_cta_2: componentCTA2,
    field_cta_2_style: componentCTAStyle2,
    field_component_asset: componentAsset,
    field_asset_link: componentAssetLink
  } = props

  const { isMobileWidth, isTabletWidth } = useScreenSize()

  return (
    <div
      className={`HeroTemplate5Container ${componentAlignment === 1 && !isMobileWidth ? 'ReverseStylesContainer' : ''}`}
      style={{
        backgroundImage:
          componentBackgroundColor === 'orange_gradient'
            ? `url(${isMobileWidth ? componentMobileImage?.url : componentBackgroundImage?.url}), ${getCssColorFromDrupalColorType(
                componentBackgroundColor
              )}`
            : `url(${isMobileWidth ? componentMobileImage?.url : componentBackgroundImage?.url})`,
        backgroundColor: componentBackgroundColor !== 'orange_gradient' && getCssColorFromDrupalColorType(componentBackgroundColor)
      }}
    >
      <Grid>
        <Grid.Row columns={2} className={`HeroGridColumn ${componentAlignment === 1 && !isMobileWidth ? 'ReverseStyles' : ''}`}>
          <Grid.Column computer={8} tablet={8} mobile={16} verticalAlign='middle' className='HeroContentContainer'>
            <div className='ContentContainer'>
              <div
                className='HeroTitleContainer'
                style={{ ...setTextColor(componentTitleColor) }}
                dangerouslySetInnerHTML={{ __html: componentTitle?.processed }}
              />
              <div
                style={{ ...setTextColor(componentShortDescriptonColor) }}
                className='HeroDescriptionContainer'
                dangerouslySetInnerHTML={{ __html: componentShortDescription?.processed }}
              />
            </div>
            <div className='ButtonContainer'>
              {componentCTA1 ? (
                <PrimaryButton
                  text={componentCTA1?.title}
                  className='HeroBtn'
                  variant={componentCTAStyle1}
                  sendTo={componentCTA1?.uri}
                  fluid={isMobileWidth}
                />
              ) : null}
              {componentCTA2 ? (
                <PrimaryButton
                  text={componentCTA2?.title}
                  className='HeroBtn'
                  variant={componentCTAStyle2}
                  sendTo={componentCTA2?.uri}
                  fluid={isMobileWidth}
                />
              ) : null}
            </div>
          </Grid.Column>

          <Grid.Column computer={8} tablet={8} mobile={16} className='HeroImgContainer' verticalAlign='middle'>
            {componentAssetLink?.title ? <p className='ImgHeading'>{componentAssetLink?.title}</p> : null}
            <a
              href={componentAssetLink?.uri?.includes('internal:') ? componentAssetLink?.uri?.split(':')[1] : componentAssetLink?.uri}
              target={componentAssetLink?.uri?.includes('internal:') ? '_self' : '_blank'}
            >
              <div className='backdrop'></div>
              {getMediaFromDrupalMimeType(componentAsset?.mimetype, componentAsset?.url, componentAsset?.alt, {
                height: 350,
                width: 200
              })}
            </a>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  )
}

export default HeroTemplate5
