import moment from 'moment'
import {commonMFAPasswordValidation} from '../../../Helpers/Utils';
const validate = (values) => {
  const errors = {}
  // First name validation
  if (values.firstName) {
    if (values.firstName.length < 1) {
      errors.firstName = 'firstNameShouldHaveAtLeasst1Char'
    } else if (values.firstName.length === 1 && values.firstName.match(/^[a-zA-Z]$/i) === null) {
      errors.firstName = 'firstNameShouldBeCharacteterOnly'
    } else if (
      values.firstName.length > 1 &&
      values.firstName.match(/^(?!.*(['ʻ,. -]){2})([a-zA-Z])([a-zA-Z'ʻ,. -]*[a-zA-Z])$/i) === null
    ) {
      errors.firstName = 'firstNameShouldBeCharacteterOnly'
    }
  } else {
    errors.firstName = 'firstNameIsRequired'
  }
  // Last name validation
  if (values.lastName) {
    if (values.lastName.length < 2) {
      errors.lastName = 'lastNameShouldHaveAtLeast2Char'
    } else if (values.lastName.match(/^(?!.*(['ʻ,. -]){2})([a-zA-Z])([a-zA-Z'ʻ,. -]*[a-zA-Z])$/i) === null) {
      errors.lastName = 'lastNameShouldBeCharacter'
    }
  } else {
    errors.lastName = 'lastNameIsRequired'
  }

  // Middle initial validation
  // blank or single character or single character with dot
  if (values.middleInitial) {
    const middleInitialRegex = /^[a-zA-Z]?$/i
    if (!middleInitialRegex.test(values.middleInitial)) {
      errors.middleInitial = 'invalid'
    }
  }

  if (!values.language) {
    errors.language = 'languageIsRequired'
  }

  // Gender Validation
  if (!values.gender) {
    errors.gender = 'genderIsRequired'
  }
  // Email validation
  if (values.loginId) {
    const expression = /(?!.*\.{2})^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([ \t]*\r\n)?[ \t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([ \t]*\r\n)?[ \t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i
    if (!expression.test(String(values.loginId).toLowerCase())) {
      errors.loginId = 'enterAValidEmail'
    }
  } else {
    errors.loginId = 'emailAddressIsRequired'
  }
  // Postal code validation
  if (values.zipCode) {
    if (!/^\d{5}$/.test(values.zipCode)) {
      errors.zipCode = 'zipCodeShouldHave5Digits'
    }
  } else {
    errors.zipCode = 'zipCodeIsRequired'
  }
  // Birth date validation
  if (values.birthDate && values.birthDate.replace(/[_\/]/g, '')) {
    const validDate = moment(values.birthDate, 'MM/DD/YYYY', true).isValid()
    if (!validDate) {
      errors.birthDate = 'pleaseCorrectYourDateOfBirth'
    } else {
      if (values.birthDate && moment().diff(values.birthDate, 'years') < 18) {
        errors.birthDate = 'youMustBe18OrOlderToDonate'
      } else if (values.birthDate && moment().diff(values.birthDate, 'years') > 150) {
        errors.birthDate = 'mustBeLessThan150YearsOfAge'
      }
    }
  } else {
    errors.birthDate = 'dateOfBirthIsRequired'
  }
  // Phone, city and state validation ..
  if (values.phoneNumber) {
    if (values.phoneNumber.replace(/\D/g, '').length !== 10) {
      errors.phoneNumber = 'phoneNumberMustHave10Digit'
    }
  } else {
    errors.phoneNumber = 'phoneNumberCannotBeEmpty'
  }
  if (!values.state) {
    errors.state = 'stateCannotBeEmpty'
  }
  if (values.city) {
    values.city = values.city.trim()
    const expression = /^(?!.*(['ʻ,. -]){2})([A-Za-zÀ-ÖØ-öø-įĴ-őŔ-žǍ-ǰǴ-ǵǸ-țȞ-ȟȤ-ȳɃɆ-ɏḀ-ẞƀ-ƓƗ-ƚƝ-ơƤ-ƥƫ-ưƲ-ƶẠ-ỿ])([A-Za-zÀ-ÖØ-öø-įĴ-őŔ-žǍ-ǰǴ-ǵǸ-țȞ-ȟȤ-ȳɃɆ-ɏḀ-ẞƀ-ƓƗ-ƚƝ-ơƤ-ƥƫ-ưƲ-ƶẠ-ỿ'ʻ,. -]*[A-Za-zÀ-ÖØ-öø-įĴ-őŔ-žǍ-ǰǴ-ǵǸ-țȞ-ȟȤ-ȳɃɆ-ɏḀ-ẞƀ-ƓƗ-ƚƝ-ơƤ-ƥƫ-ưƲ-ƶẠ-ỿ])$/i
    if (values.city.length < 3) {
      errors.city = 'cityShouldHaveAtLeast3Char'
    } else if (!expression.test(String(values.city).toLowerCase())) {
      errors.city = 'cityIsInvalid'
    }
  } else {
    errors.city = 'cityCannotBeEmpty'
  }

  // Password validation
  if (values.password) {
    commonMFAPasswordValidation({
      passwordField: 'password',
      errors: errors,
      values: values
    })
  } else {
    errors.password = 'passwordFieldIsRequired'
  }

  //privacy notice and terms validation
  if (!values.isPrivacyNoticeAccepted) {
    errors.isPrivacyNoticeAccepted = 'mustBeCheckedToProceed'
  }
  if (!values.isMobileTermsAccepted) {
    errors.isMobileTermsAccepted = 'mustBeCheckedToProceed'
  }

  return errors
}

export default validate
