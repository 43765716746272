import React, { useEffect } from 'react'
import './styles.scss'
import { Grid } from 'semantic-ui-react'
import './styles.scss'
import importer from '@takedapdt/biolife-core/src/importer'

const { useScreenSize } = importer('Contexts/ResponsiveContextProvider', null)
const PrimaryButton = importer('Components/Buttons/PrimaryButton')
const { getCssColorFromDrupalColorType, getMediaFromDrupalMimeType, setTextColor } = importer('Constant/Utils', null)

const AboutPlasmaDonation = (props) => {
  const {
    field_bg_color,
    field_alignment,
    field_title_color,
    field_short_desc_color,
    field_component_title,
    field_short_description,
    field_cta_1,
    field_cta_1_style,
    field_component_asset,
    field_component_desc,
    field_long_desc_color,
    field_cta_2,
    field_cta_2_style
  } = props
  const { isMobileWidth } = useScreenSize()
  return (
    <div className='PlasmaDonation' style={{ background: `${getCssColorFromDrupalColorType(field_bg_color)}` }}>
      <Grid>
        <Grid.Row
          columns={2}
          className={`PlasmaDonationGridColumn ${!isMobileWidth
            ? field_alignment === 1
              ? 'row-reverse'
              : 'row'
            : field_alignment === 1
            ? 'column-reverse'
            : 'column'}`}
          style={{
            flexDirection: !isMobileWidth
              ? field_alignment === 1
                ? 'row-reverse'
                : 'row'
              : field_alignment === 1
              ? 'column-reverse'
              : 'column'
          }}
        >
          <Grid.Column computer={8} tablet={8} mobile={16} className='PlasmaDonationContentContainer'>
            <div className='ContentContainer'>
              <div
                className='PlasmaDonationTitleContainer'
                style={{ ...setTextColor(field_title_color) }}
                dangerouslySetInnerHTML={{ __html: field_component_title?.processed }}
              />
              <div
                style={{ ...setTextColor(field_short_desc_color) }}
                className='PlasmaDonationShortDescriptionContainer'
                dangerouslySetInnerHTML={{ __html: field_short_description?.processed }}
              />
              {field_component_desc?.processed ? (
                <div
                  style={{ ...setTextColor(field_long_desc_color) }}
                  className='PlasmaDonationDescriptionContainer'
                  dangerouslySetInnerHTML={{ __html: field_component_desc?.processed }}
                />
              ) : null}
            </div>
            {(field_cta_1 || field_cta_2) && (
              <div className='ButtonContainer'>
                {field_cta_1 && (
                  <PrimaryButton
                    text={field_cta_1?.title}
                    className='PlasmaDonationBtn'
                    variant={field_cta_1_style}
                    sendTo={field_cta_1?.uri}
                    fluid={isMobileWidth}
                  />
                )}
                {field_cta_2 && (
                  <PrimaryButton
                    text={field_cta_2?.title}
                    className='PlasmaDonationBtn'
                    variant={field_cta_2_style}
                    sendTo={field_cta_2?.uri}
                    fluid={isMobileWidth}
                  />
                )}
              </div>
            )}
          </Grid.Column>

          <Grid.Column
            computer={8}
            tablet={8}
            mobile={16}
            className='PlasmaDonationImgContainer'
            style={{ background: `${getCssColorFromDrupalColorType(field_bg_color)}` }}
          >
            {getMediaFromDrupalMimeType(field_component_asset?.mimetype, field_component_asset?.url, field_component_asset?.alt)}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  )
}

export default AboutPlasmaDonation
