import React, { useEffect } from 'react'
import './styles.scss'
import { Grid } from 'semantic-ui-react'
import styled, { css } from 'styled-components'
import importer from '@takedapdt/biolife-core/src/importer'

const { useScreenSize } = importer('Contexts/ResponsiveContextProvider', null)
const PrimaryButton = importer('Components/Buttons/PrimaryButton')
const { getCssColorFromDrupalColorType, setTextColor, getMediaFromDrupalMimeType } = importer('Constant/Utils', null)
const { devices, sizes } = importer('utils/styledComponentHelpers', null)
const { sanitizeHtml } = importer('utils/htmlHelpers', null)

const StyledHeroTemplate6 = styled.div`
  background: ${(props) => getCssColorFromDrupalColorType(props.$bgColor)};
`

const StyledHeroTemplate6Container = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 4.9rem;
  ${devices.tablet} {
    flex-direction: ${(props) => (props.$mediaAlignment === 2 ? 'column' : 'column-reverse')};
  }
  ${devices.mobile} {
    gap: 2.4rem;
    align-items: center;
  }
`
const StyledHeroTemplate6ContentContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 24px;
  text-align: left;
  ${devices.mobile} {
    justify-content: center;
    width: 100%;
  }
`

const StyledHeroTemplate6Text = styled.div`
  ${(props) => setTextColor(props.$textColor)};
`
const StyledHeroTemplate6ButtonColumn = styled.div`
  ${devices.mobile} {
    padding-top: 8px;
  }
`

const StyledHeroTemplate6ImageContainer = styled.div`
  width: 56rem;
  height: 36.4rem;
  position: relative;
  top: -13.7rem;
  right: -18rem;
  ${devices.tablet} {
    height: 50rem;
    margin-left: -48px;
  }
  ${devices.mobile} {
    width: 39rem;
    height: 39rem;
    margin-left: -24px;
    margin-right: -24px;
    top: 0;
    right: 0;
  }
`

const HeroTemplate6 = (props) => {
  const {
    field_bg_color: componentBackgroundColor,
    field_alignment: componentMediaAlignment,
    field_title_color: componentTitleColor,
    field_component_title: componentTitle,
    field_cta_1: componentCTA1,
    field_cta_1_style: componentCTA1Style,
    field_cta_2: componentCTA2,
    field_cta_2_style: componentCTA2Style,
    field_component_asset: componentMedia,
    field_column_1_icon: componentLogo,
    field_short_desc_color: shortDescColor,
    field_short_description: shortDescription
  } = props
  const { isMobileWidth } = useScreenSize()

  return (
    <StyledHeroTemplate6 className='hero-template6' $bgColor={componentBackgroundColor}>
      <div className='main-container'>
        <StyledHeroTemplate6Container className='hero-template6__container' $mediaAlignment={componentMediaAlignment}>
          <StyledHeroTemplate6ContentContainer className='hero-template6__content-container'>
            <div className='hero-template6__logo'>
              {getMediaFromDrupalMimeType(componentLogo?.mimetype, componentLogo?.url, componentLogo?.alt)}
            </div>
            <StyledHeroTemplate6Text
              className='hero-template6__title'
              dangerouslySetInnerHTML={sanitizeHtml(componentTitle?.processed)}
              $textColor={componentTitleColor}
            />
            <StyledHeroTemplate6Text
              className='hero-template6__description'
              dangerouslySetInnerHTML={sanitizeHtml(shortDescription?.processed)}
              $textColor={shortDescColor}
            />
            <StyledHeroTemplate6ButtonColumn className='hero-template6__buttons-container'>
              {componentCTA1 && (
                <PrimaryButton
                  text={componentCTA1?.title}
                  className='hero-template6__button'
                  variant={componentCTA1Style}
                  sendTo={componentCTA1?.uri}
                  fluid={isMobileWidth}
                />
              )}
              {componentCTA2 && (
                <PrimaryButton
                  text={componentCTA2?.title}
                  className='hero-template6__button'
                  variant={componentCTA2Style}
                  sendTo={componentCTA2?.uri}
                  fluid={isMobileWidth}
                />
              )}
            </StyledHeroTemplate6ButtonColumn>
          </StyledHeroTemplate6ContentContainer>
          <StyledHeroTemplate6ImageContainer className='hero-template6__image-container'>
            {getMediaFromDrupalMimeType(componentMedia?.mimetype, componentMedia?.url, componentMedia?.alt)}
          </StyledHeroTemplate6ImageContainer>
        </StyledHeroTemplate6Container>
      </div>
    </StyledHeroTemplate6>
  )
}

export default HeroTemplate6
