import styled from 'styled-components'
import importer from '@takedapdt/biolife-core/src/importer'
const { getCssColorFromDrupalColorType } = importer('Constant/Utils', null)
const { devices } = importer('utils/styledComponentHelpers', null)

export const LeftContainer = styled.div`
  display: flex;
  margin-right: ${({ imageAlignment }) => (imageAlignment ? '4rem' : '0')};
  margin-left: ${({ imageAlignment }) => (imageAlignment ? '0' : '4rem')};

  ${devices.tablet} {
    margin-right: ${({ imageAlignment }) => (imageAlignment ? '4rem' : '0')};
    margin-left: ${({ imageAlignment }) => (imageAlignment ? '0' : '4rem')};
  }

  ${devices.mobile} {
    justify-content: center;
    margin-right: 0px;
    margin-left: 0px;
  }
`

export const RightContainer = styled.div`
  margin-left: ${({ imageAlignment }) => (imageAlignment ? '4rem' : '0')};
  margin-right: ${({ imageAlignment }) => (imageAlignment ? '0' : '4rem')};

  ${devices.tablet} {
    margin-left: ${({ imageAlignment }) => (imageAlignment ? '4rem' : '0')};
    margin-right: ${({ imageAlignment }) => (imageAlignment ? '0' : '4rem')};
  }

  ${devices.mobile} {
    margin-left: 0px;
    margin-right: 0px;
  }
`

export const CardContainer = styled.div`
  display: flex;
  flex-direction: ${({ imageAlignment }) => (imageAlignment ? 'row' : 'row-reverse')};
  background: ${({ bg }) => getCssColorFromDrupalColorType(bg)};
  ${devices.mobile} {
    flex-direction: column;
  }
`
