import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import WirecardActions from '@takedapdt/biolife-core/src/Stores/Wirecard/Actions'
import PaymentsActions from '@takedapdt/biolife-core/src/Stores/Payments/Actions'
import donorsActions from '@takedapdt/biolife-core/src/Stores/Donors/Actions'
import { devices } from '../../../src/utils/styledComponentHelpers'
import styled from 'styled-components'
import { getCssColorFromDrupalColorType } from '../../../src/Constant/Utils'
import { useTranslation } from 'react-i18next'
import { numberWithCommas } from '../../Helpers/Utils'

const DebitCard = ({ donor, getWirecardInfoHandler, getDonors, user, wirecard, field_component_title, field_title_color }) => {
  const { t } = useTranslation('DebitCard')
  useEffect(() => {
    getDonors(user && user.id)
  }, [])
  const DebitStatsContainer = styled.div`
    display: flex;
    align-items: center;
    background-color: var(--color-smoke-grey);
    height: 31rem;
    border-radius: 0 0 12rem 12rem;
    justify-content: center;
    overflow-x: auto; /* Allow horizontal scrolling if needed */
    ${devices.mobile} {
      height: 28rem;
      border-radius: 0 0 8rem 8rem;
    }
  `

  const StatCard = styled.div`
    background: white;
    height: 11rem;
    max-width: 50rem; /* Adjust minimum width as needed */
    border-radius: 1rem;
    margin: 2rem 0rem;
    ${devices.mobile} {
      min-width: 14.2rem; /* Adjust minimum width as needed */
      padding-top: 1rem;
      padding-bottom: 1.4rem;
    }
  `
  const DebitTitle = styled.div`
    padding-top: 4.8rem;
  `
  const DebitCard = styled.div``
  const CardTitle = styled.div`
    padding: 0.8rem 1.6rem 0 1.6rem;
    display: flex;
    flex-direction: column;
    border-radius: 2rem;
    color: #29398c;
    font-size: 1.4rem;
    font-weight: 300;
    ${devices.mobile} {
      padding-top: 0rem;
      padding-bottom: 1.2rem;
    }
  `

  const CardBalance = styled.strong`
    padding: 0 1.6rem 0.8rem 1.6rem;
    color: var(--color-light-orange);
    font-size: 5.6rem;
    font-weight: 900;
    ${devices.mobile} {
      font-size: 3.2rem;
    }
  `

  const getDebitAmount = (debitAccount) => {
    const amount = debitAccount?.data?.balance?.balanceAvailable
    if (amount) return numberWithCommas(amount)
    else return ' 0 '
  }

  useEffect(() => {
    if (donor?.pdn) {
      getWirecardInfoHandler(donor?.pdn, null, null)
    }
  }, [])
  return (
    <DebitStatsContainer>
      <DebitCard className='main-container debit-card-balance'>
        <DebitTitle
          style={{ color: getCssColorFromDrupalColorType(field_title_color) }}
          dangerouslySetInnerHTML={{ __html: field_component_title?.processed }}
        />
        <StatCard>
          <CardTitle>{t('Debit Card Balance')}:</CardTitle>
          <CardBalance>${getDebitAmount(wirecard)}</CardBalance>
        </StatCard>
      </DebitCard>
    </DebitStatsContainer>
  )
}

const mapStateToProps = (state) => ({
  loggedInUser: state.users.user,
  user: state.users.loginHerokuSuccess,
  wirecard: state.wirecard.getWirecardInfoSuccess,
  wirecardError: state.wirecard.getWirecardInfoFailure,
  wirecardLoading: state.wirecard.getWirecardInfoLoading,
  centerStatus: state.centers.centerStatus,
  donor: state?.donors?.donors?.data,
  nearbyCenters: state.centers.nearbyCenters,
  paymentHistory: state.payments?.getPaymentHistorySuccess,
  paymentHistoryLoading: state.payments?.getPaymentHistoryLoading,
  paymentHistoryError: state.payments?.getPaymentHistoryFailure
})
const mapDispatchToProps = (dispatch) => ({
  getWirecardInfoHandler: (pdn, startDate, endDate) => dispatch(WirecardActions.getWirecardInfo(pdn, startDate, endDate)),
  getDonors: (id) => dispatch(donorsActions.getDonors(id)),
  getPaymentHistory: (pdn, startDate, endDate) => dispatch(PaymentsActions.getPaymentHistory(pdn, startDate, endDate))
})
export default connect(mapStateToProps, mapDispatchToProps)(DebitCard)
