import styled from 'styled-components'
import importer from '@takedapdt/biolife-core/src/importer'
const { getCssColorFromDrupalColorType } = importer('Constant/Utils', null)
const { devices } = importer('utils/styledComponentHelpers', null)

export const Div = styled.div`
  background-color: ${({ $bgColor }) => getCssColorFromDrupalColorType($bgColor)};
  padding: ${({ $padding }) => $padding};
  height: ${({ $minHeight }) => $minHeight};
  margin: ${({ $margin }) => `${$margin}`};
  position: ${({ $position }) => $position};
  max-width: ${({ $maxWidth }) => $maxWidth};
  min-width: ${({ $minWidth }) => $minWidth};
  flex-grow: ${({ $flexGrow }) => $flexGrow};
  text-align: ${({ $textAlign }) => $textAlign};
  border-radius: ${({ $borderRadius }) => $borderRadius};
  flex-wrap: ${({ $flexWrap }) => $flexWrap};
  width: ${({ $width }) => $width};
  border-top: ${({ $borderTop }) => $borderTop};
  border-bottom: ${({ $borderBottom }) => $borderBottom};
  overflow: ${({ $overflow }) => $overflow};
`

export const DivWithFlex = styled.div`
  display: flex;
  background-color: ${({ $bgColor }) => getCssColorFromDrupalColorType($bgColor)};
  flex-direction: ${({ $direction }) => $direction || 'row'};
  align-items: ${({ $align }) => $align};
  justify-content: ${({ $justifyContent }) => $justifyContent};
  border-radius: ${({ $borderRadius }) => $borderRadius};
  padding: ${({ $padding }) => $padding};
  margin: ${({ $margin }) => $margin};
  position: ${({ $position }) => $position};
  max-width: ${({ $maxWidth }) => $maxWidth};
  box-shadow: ${({ $boxShadow }) => $boxShadow};
  flex-wrap: ${({ $flexWrap }) => $flexWrap};
  width: ${({ $width }) => $width};
  column-gap: ${({ $columnGap }) => $columnGap};
  row-gap: ${({ $rowGap }) => $rowGap};
  flex-grow: ${({ $flexGrow }) => $flexGrow};
  min-width: ${({ $minWidth }) => $minWidth};
`
export const Label = styled.p`
  font-family: 'AvenirNextBold' !important;
  margin-bottom: 5px;
`
export const TextComponent = styled.p`
  color: ${({ $color }) => getCssColorFromDrupalColorType($color)};
  font-size: ${({ $fontSize }) => $fontSize};
  font-family: ${({ $fontFamily }) => ($fontFamily ? `${$fontFamily} !important` : 'AvenirNextBold !important')};
  margin-bottom: ${({ $marginBottom }) => $marginBottom};
  padding-right: ${({ $paddingRight }) => $paddingRight};
  border-right: ${({ $borderRight }) => $borderRight};
  text-decoration: ${({ $textDecoration }) => $textDecoration || 'none'};
`

export const SmallTitle = styled(TextComponent)`
  font-size: var(--caption-mobile-font-size) !important;
`

export const Highlight2 = styled.h2`
  color: ${({ $color }) => getCssColorFromDrupalColorType($color)};
  font-family: 'AvenirNextBold' !important;
  margin-bottom: ${({ $marginBottom }) => $marginBottom};
`

export const Highlight4 = styled.h4`
  color: ${({ $color }) => getCssColorFromDrupalColorType($color)};
  font-family: 'AvenirNextBold' !important;
  margin-bottom: ${({ $marginBottom }) => $marginBottom};
`

export const Highlight5 = styled.h5`
  color: ${({ $color }) => getCssColorFromDrupalColorType($color)};
  font-family: ${({ $fontFamily }) => ($fontFamily ? `${$fontFamily} !important` : 'AvenirNextBold !important')};
  margin: ${({ $margin }) => $margin || '0'};
`

export const StyledSpan = styled.span`
  color: ${({ $color }) => getCssColorFromDrupalColorType($color)};
  margin: ${({ $margin }) => $margin || '0'};
  &:hover {
    color: ${({ $hoverColor }) => getCssColorFromDrupalColorType($hoverColor)};
  }
`

export const ImageDiv = styled.div`
  /* margin-right: 0.8rem; */
  display: flex;
  align-items: center;
  /* height: 12px; */
  /* object-fit: cover; */
`

export const Image = styled.img`
  /* object-fit: cover;
  object-position: center top; */
  height: ${({ $height }) => $height};
  width: ${({ $width }) => $width};
`

export const MapPinIcon = styled.div`
  border-radius: 50%;
  background-color: ${() => getCssColorFromDrupalColorType('bl_blue')};
  height: 24px;
  width: 24px;
  border: 1px solid white;
`
export const MapDiv = styled.div`
  width: 50%;
  ${devices.mobile} {
    margin: 25px 0;
    height: 270px;
    width: 100%;
  }
`
