import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'
// /* DYNAMIC IMPORTS */
import importer from '@takedapdt/biolife-core/src/importer'
// /* COMPONENTS */
import './styles.scss'
import { scrollToTop } from '../../Helpers/Utils'

const { SLUG_TYPES } = importer('Sagas/Constants/drupalConstants')
const PublicContentActions = importer('Stores/PublicContent/Actions')
const renderDrupalComponent = importer('Constant/renderDrupalComponent')
const { getArrayFromTextCTAGrid, getPathDetails } = importer('Helpers/Utils', null)
const LoaderSpinner = importer('Components/Loaders/LoaderSpinner')

const EmailConfirmationContainer = ({ pageDataLoading, pageData, fetchPageData }) => {
  const [emailConfirmationData, setEmailConfirmationData] = useState({})

  useEffect(() => {
    fetchPageData(getPathDetails.slugType || SLUG_TYPES?.SIGNUPFINALSTEP)
    scrollToTop()
  }, [])

  useEffect(() => {
    if (pageData) {
      const dataWithTextCTAGrid = getArrayFromTextCTAGrid(pageData)
      if (Object.keys(dataWithTextCTAGrid)?.length) {
        setEmailConfirmationData(dataWithTextCTAGrid)
      }
    }
  }, [pageData])

  return (
    <>
      <Helmet>
        <title>{`${emailConfirmationData?.metadata?.meta_title}`} </title>
        <meta name='robots' content='noindex' />
        <meta name='description' content={`${emailConfirmationData?.metadata?.meta_description}`} />
        <link rel='canonical' href={`${window.location.origin}${window.location.pathname.toLowerCase()}`} />
      </Helmet>
      {pageDataLoading ? (
        <LoaderSpinner />
      ) : (
        <div className='signup-step3-page'>
          {emailConfirmationData?.contents?.map((component, index) => {
            if (component.type === 'breadcrumb') {
              let tempComp = {
                ...component,
                breadcrumbToggle: emailConfirmationData?.metadata?.field_content_breadcrumb,
                breadcrumbMobileToggle: emailConfirmationData?.metadata?.field_mobile_breadcrumb
              }
              component = tempComp
            }
            return renderDrupalComponent(component, index)
          })}
        </div>
      )}
    </>
  )
}

const mapStateToProps = (state) => ({
  pageDataLoading: state.publicContent?.getRegistrationPageLoading?.emailConfirmation,
  pageData: state.publicContent?.getRegistrationPageSuccess?.emailConfirmation
})

const mapDispatchToProps = (dispatch) => ({
  fetchPageData: (formType) => dispatch(PublicContentActions.getRegistrationPage(formType))
})

export default connect(mapStateToProps, mapDispatchToProps)(EmailConfirmationContainer)
