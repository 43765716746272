import React from 'react'
import { Card } from 'semantic-ui-react'
import './styles.scss'
import importer from 'common/importer'
const PrimaryButton = importer('Components/Buttons/PrimaryButton')
const { getCssColorFromDrupalColorType, setTextColor, getMediaFromDrupalMimeType } = importer('Constant/Utils', null)

const AdvertisementCard2 = ({
  field_component_title: componentTitle,
  field_title_color: componentTitleColor,
  field_short_description: componentShortDescription,
  field_short_desc_color: componentShortDescriptionColor,
  field_component_image: componentImage,
  field_alignment: componentAlignment,
  field_cta_1: componentCTA,
  field_cta_1_style: componentCTAStyle,
  field_bg_color_1: componentBackgroundColor
}) => {
  return (
    <Card className='card-container2' style={{ background: getCssColorFromDrupalColorType(componentBackgroundColor) }}>
      <Card.Content>
        <Card.Description>
          <div>
            <div
              className='component-title'
              dangerouslySetInnerHTML={{ __html: componentTitle?.processed }}
              style={{ ...setTextColor(componentTitleColor) }}
            />
            <PrimaryButton text={componentCTA?.title} sendTo={componentCTA?.uri} variant={componentCTAStyle} />
          </div>
          <div className='image-container'>
            {getMediaFromDrupalMimeType(componentImage?.mimetype, componentImage?.url, componentImage?.alt, {
              height: 200,
              width: 175
            })}
          </div>
        </Card.Description>
      </Card.Content>
    </Card>
  )
}

export default AdvertisementCard2
