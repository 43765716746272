module.exports = [
  {
    id: 7,
    name: 'Who We Are',
    path: '/about-biolife/who-we-are',
    page: 'who-we-are',
    title: 'Who We Are'
  },
  {
    id: 8,
    name: "What's New",
    path: '/about-biolife/whats-new',
    page: 'whats-new',
    title: 'What is New at BioLife Plasma?'
  },
  // {
  //   id: 9,
  //   name: 'Upcoming Events',
  //   path: '/about-biolife/upcoming-events',
  //   page: 'upcoming-events',
  //   title: 'Upcoming Events | Information',
  // },
  {
    id: 10,
    name: 'Donation Centers',
    path: '/about-biolife/donation-centers',
    page: 'donation-centers',
    title: 'Our Plasma Donation Centers'
  },
  {
    id: 12,
    name: 'Press Room',
    path: '/about-biolife/press-room',
    page: 'press-room',
    title: 'Press Contacts'
  },
  {
    id: 13,
    name: 'Contact Us',
    path: '/about-biolife/contact-us',
    page: 'contact-us',
    title: 'Contact Us | Get Information'
  },
  {
    id: 14,
    name: 'What is Plasma?',
    path: '/about-plasma/what-is-plasma',
    page: 'what-is-plasma',
    title: 'What is Plasma? | How is it Used?'
  },
  {
    id: 15,
    name: 'Video Gallery',
    path: '/about-plasma/video-gallery',
    page: 'video-gallery',
    title: 'Video Gallery About Plasma Donation'
  },
  {
    id: 16,
    name: 'Donation Safety',
    path: '/about-plasma/donation-safety',
    page: 'donation-safety',
    title: 'Plasma Donation Health and Safety'
  },
  {
    id: 17,
    name: 'Links & Resources',
    path: '/about-plasma/links-and-resources',
    page: 'links-and-resources',
    title: 'Resource Links | About Plasma'
  },
  {
    id: 18,
    name: 'Eligibility',
    path: '/become-a-donor/eligibility',
    page: 'eligibility',
    title: 'Plasma Donor Requirements and Eligibility'
  },
  {
    id: 19,
    name: 'Program Step-by-Step',
    path: '/become-a-donor/program-step-by-step',
    page: 'program-step-by-step',
    title: 'Donation Step by Step'
  },
  {
    id: 20,
    name: 'Step-by-Step Guide',
    path: '/become-a-donor/step-by-step-guide',
    page: 'step-by-step-guide',
    title: 'Plasma Donation Step By Step'
  },
  {
    id: 21,
    name: 'Patient Testimonials',
    path: '/become-a-donor/patient-testimonials',
    page: 'patient-testimonials',
    title: 'Plasma Donation Testimonials'
  },
  {
    id: 22,
    name: 'Donor Testimonials',
    path: '/become-a-donor/donor-testimonials',
    page: 'donor-testimonials',
    title: 'Plasma Donor Testimonials'
  },
  {
    id: 23,
    name: 'Health & Nutrition',
    path: '/become-a-donor/health-&amp;-nutrition',
    page: 'health-&-nutrition',
    title: 'Health Nutrition | New Plasma Donors'
  },
  {
    id: 24,
    name: 'Compensation',
    path: '/become-a-donor/compensation',
    page: 'compensation',
    title: 'Plasma Donor Compensation'
  },
  {
    id: 25,
    name: 'FAQs',
    path: '/become-a-donor/faqs',
    page: 'faqs',
    title: 'Donating Plasma Questions | FAQs'
  },
  {
    id: 26,
    name: 'BioLife Debit Card FAQs',
    path: '/become-a-donor/biolife-debit-card-faqs',
    page: 'bioLife-debit-card-faqs',
    title: 'BioLife Debit Card FAQs | New Plasma Donors'
  },
  {
    id: 27,
    name: 'Patient Testimonials',
    path: '/current-donor/patient-testimonials',
    page: 'patient-testimonials',
    title: 'Patient Testimonials | Plasma Donation'
  },
  {
    id: 28,
    name: 'Health & Nutrition',
    path: '/current-donor/health-&amp;-nutrition',
    page: 'health-&-nutrition',
    title: 'Health Nutrition | Current Plasma Donors'
  },
  {
    id: 29,
    name: 'Compensation',
    path: '/current-donor/compensation',
    page: 'compensation',
    title: 'Plasma Donation | Payment'
  },
  {
    id: 30,
    name: 'Tell Us Your Story',
    path: '/current-donor/tell-us-your-story',
    page: 'tell-us-your-story',
    title: 'Tell Us Your Story'
  },
  {
    id: 31,
    name: 'FAQs',
    path: '/current-donor/faqs',
    page: 'faqs',
    title: 'FAQs | Current Plasma Donors'
  },
  {
    id: 32,
    name: 'BioLife Debit Card FAQs',
    path: '/current-donor/biolife-debit-card-faqs',
    page: 'bioLife-debit-card-faqs',
    title: 'BioLife Debit Card FAQs | Donors'
  },
  {
    id: 33,
    name: 'Non-discrimination Statement',
    path: '/legal/non-discrimination-statement',
    page: 'non-discrimination-statement',
    title: 'Non-Discrimination Statement'
  },
  {
    id: 34,
    name: 'Terms and Conditions of Use',
    path: '/legal/terms-and-conditions-of-use',
    page: 'terms-and-conditions-of-use',
    title: 'Terms and Conditions'
  },
  {
    id: 35,
    name: 'Privacy',
    path: '/legal/privacy-notice',
    page: 'privacy-notice',
    title: 'Privacy Notice'
  },
  {
    id: 38,
    name: 'BioLife Debit Card',
    path: '/dashboard/bioLife-payment-cards',
    page: 'bioLife-payment-cards',
    title: 'BioLife Debit Card'
  },
  {
    id: 43,
    name: 'Log In',
    path: '/login',
    page: 'login',
    title: 'Login'
  },
  {
    id: 43,
    name: 'Biolife Home',
    path: '/',
    page: 'Biolife Home',
    title: 'Biolife Home'
  },
  {
    id: 44,
    name: 'Signup',
    path: '/registration',
    page: 'Sign Up',
    title: 'Donor Registration'
  },
  {
    id: 45,
    name: 'Locations',
    path: '/locations',
    page: 'Locations',
    title: 'Locations'
  }
]
