import React, { useEffect, useState } from 'react'
import i18next from 'i18next'
import importer from 'common/importer'
import { connect } from 'react-redux'
import { Segment, Button, Icon, Menu } from 'semantic-ui-react'
import changeLanguageHelper from '../../../Helpers/changeLanguage'
import PrimaryButton from '../../Buttons/PrimaryButton'
import StartupActions from '.././../../Stores/Startup/Actions'
import { ReactComponent as CloseIcon } from '../../../../assets/media/icons/button-close-large-orange.svg'
import { ReactComponent as BackIcon } from '../../../../assets/media/icons/UI-arrow-left.svg'
import { ReactComponent as RightIcon } from '../../../../assets/media/icons/UI-arrow-right.svg'
import { ReactComponent as SearchIcon } from '../../../../assets/media/icons/search-icon.svg'
import styled from 'styled-components'
import { Link, useNavigate } from 'react-router-dom'
const { devices } = importer('utils/styledComponentHelpers', null)
const { useScreenSize } = importer('Contexts/ResponsiveContextProvider', null)
import donorsActions from '@takedapdt/biolife-core/src/Stores/Donors/Actions'
import { ROUTES, CareersSpanishURL } from '../../../NavigationService'

const SideNavbarContainer = styled(Segment)`
  padding: 0px !important;
  box-shadow: none !important;
  border: none !important;
`

const TopCloseContainer = styled.div`
  padding: 2.4rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  ${devices.mobile} {
    padding: 2.4rem;
    flex-direction: row-reverse;
  }
  .buttonWithHamburger {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: flex-start;
    ${devices.mobile} {
      /* justify-content: end; */
      align-items: center;
      &.selectedSidebarMenu {
        justify-content: space-between;
      }
    }
  }
`
const CloseButton = styled(Button)`
  background: none !important;
  align-self:baseline;
  svg {
    width: 4rem;
    height: 4rem;
    &:hover path:last-child {
      fill: var(--color-orange-hover) !important;
    }
  }
`
const BackButton = styled(Button)`
  background: none !important;
  margin: auto 0 !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
  svg {
    path:last-child {
      fill: var(--color-orange) !important;
    }
    &:hover path:last-child {
      fill: var(--color-orange-hover) !important;
    }
    width: 2.5rem;
    height: 2.5rem;
  }
`
const SearchButton = styled(Button)`
  padding-top: 56px !important;
  background: none !important;
  text-align: left !important;
  margin: 0px !important;
  svg {
    width: 30px;
    height: 30px;
  }
  ${devices.mobile} {
    padding: 0 !important;
    border-bottom: none !important;
  }
`

const NavbarItems = styled.div`
  .navbarItem {
    padding: 24px !important;
    font-size: var(--h5-font-size);
    color: var(--color-bl-blue) !important;
    border-bottom: 1px solid var(--color-light-grey);
    line-height: var(--h5-line-height) !important;
    /* To be used once search button added  */
    /* &:first-child {
      border-top: 1px solid var(--color-light-grey) !important;
    } */
    &:before {
      display: none;
    }
    &:hover {
      background: var(--color-bg-light-blue) !important;
    }
  }
`

const StyledMsgCount = styled.div`
  width: 3.2rem;
  height: 3.2rem;
  border-radius: 1.6rem;
  background: var(--color-bl-blue);
  color: var(--color-white);
  font-size: var(--caption-mobile-font-size) !important;
  text-align: center;
  display: flex;
  float: right;
  justify-content: center;
  align-items: center;
`

const SelectedSidebarSubMenu = styled.div`
  .navbarItem {
    color: var(--color-rich-black) !important;
  }
  .subMenuHeading {
    font-family: 'AvenirNextBold' !important;
    color: var(--color-bl-blue);
    padding: 0 24px 24px;
    border-bottom: 1px solid var(--color-light-grey);
    ${devices.mobile} {
      padding-top: 30px !important;
      font-size: var(--h4-font-size) !important;
    }
  }
`

const PublicMenuItems = styled.div`
  background: var(--color-header-grey);
  padding-bottom: 80px;
  .navbarItem {
    background-color: var(--color-header-grey) !important;
    color: var(--color-rich-black) !important;
    border-bottom: 1px solid var(--color-white) !important;
    svg {
      float: right;
      path:last-child {
        fill: var(--color-rich-black);
      }
    }
    &:hover {
      svg {
        float: right;
        path:last-child {
          fill: var(--color-bl-blue);
        }
      }
      color: var(--color-bl-blue) !important;
    }
    &:last-child {
      border: none !important;
    }
  }
`

const ButtonHandlerContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  column-gap: 1rem;
  row-gap: 2rem;

  div > button {
    min-width: 19.5rem;
  }
`

const ButtonHandlerDiv = styled.div`
  padding: 1rem 0rem;
  display: flex;
`

const LoggedInSideNavbar = ({
  initializeApp,
  postLoginHeaderMenu,
  drupalMenuHeader,
  drupalNavData,
  handleToggleSidebar,
  sfDonorMessages,
  updateDonorsPreferences,
  donor,
  user
}) => {
  const { isMobileWidth } = useScreenSize()
  const [selectedSidebarMenu, setSelectedSidebarMenu] = useState(null)
  const [currentLanguage, setCurrentLanguage] = useState(i18next.language)
  const navigate = useNavigate()
  const [dashboardCTAButton, setDashboardCTAButton] = useState(
    drupalNavData?.find((item) => item.type === 'header_component' && item.field_menu_type === 'user_dashboard_menu') || {}
  )
  useEffect(() => {
    setDashboardCTAButton(
      (prev) => drupalNavData?.find((item) => item.type === 'header_component' && item.field_menu_type === 'user_dashboard_menu') || prev
    )
  }, [drupalNavData])

  const handleSidebarMenuItemClick = (item) => {
    setSelectedSidebarMenu(item)
  }

  const showNavItem = (item, index) => {
    if (item.submenu.length > 0) {
      return (
        <Menu.Item key={index} className='navbarItem' onClick={() => handleSidebarMenuItemClick(item)}>
          {item.title} <RightIcon />
        </Menu.Item>
      )
    } else {
      const hasMessages = sfDonorMessages && sfDonorMessages.length > 0
      return (
        <Menu.Item
          key={index}
          className='navbarItem'
          name={item.title}
          as={Link}
          to={(item?.title?.toLowerCase() === 'carreras' ? CareersSpanishURL : item?.href)}
          onClick={() => handleToggleSidebar(false)}
          target={item.target}
        >
          {item.title} {item.href.includes('message') && hasMessages && <StyledMsgCount>{sfDonorMessages.length}</StyledMsgCount>}
        </Menu.Item>
      )
    }
  }

  /**
   * This function is called when the user clicks on the language button.
   */
  const changeLanguage = () => {
    handleToggleSidebar()
    if (currentLanguage === 'en-US') {
      internationalize('es-MX')
      setCurrentLanguage('es-MX')
    } else {
      internationalize('en-US')
      setCurrentLanguage('en-US')
    }
  }

  /**
   * This function is used to change the language of the application.
   * @param {string} lang
   */
  const internationalize = (lang) => {
    window?.localStorage?.setItem('originalLng', lang)
    changeLanguageHelper(lang)
    initializeApp('changeLanguage')
  }

  return (
    <SideNavbarContainer>
      <TopCloseContainer>
        <div className={`buttonWithHamburger ${selectedSidebarMenu && 'selectedSidebarMenu'}`}>
          {!selectedSidebarMenu ? (
            <ButtonHandlerContainer>
              {dashboardCTAButton ? (
                <div style={{ margin: '0.7rem 0' }}>
                  {dashboardCTAButton && dashboardCTAButton?.field_cta_1?.title ? (
                    <PrimaryButton
                      text={dashboardCTAButton?.field_cta_1?.title}
                      sendTo={dashboardCTAButton?.field_cta_1?.uri}
                      variant={dashboardCTAButton?.field_cta_1_style}
                      className='donateNow'
                      onClick={() => {
                        handleToggleSidebar(false)
                        navigate(user ? ROUTES.AppointmentScheduling : dashboardCTAButton?.field_cta_1?.uri?.split(':')[1])
                      }}
                    />
                  ) : null}
                </div>
                ) : null}
             
              {dashboardCTAButton ? (
                <ButtonHandlerDiv>
                  {dashboardCTAButton && dashboardCTAButton?.field_cta_2?.title ? (
                    <PrimaryButton
                      text={dashboardCTAButton?.field_cta_2?.title}
                      sendTo={dashboardCTAButton?.field_cta_2?.uri}
                      variant={dashboardCTAButton?.field_cta_2_style}
                      onClick={() => {
                        changeLanguage()
                      }}
                    />
                  ) : null}
                </ButtonHandlerDiv>
              ) : null}
            </ButtonHandlerContainer>
          ) : null}

          {selectedSidebarMenu && (
            <BackButton onClick={() => setSelectedSidebarMenu(null)}>
              <BackIcon />
            </BackButton>
          )}
          <CloseButton onClick={handleToggleSidebar} style={{ paddingLeft: 0, paddingRight: 0 }}>
            <CloseIcon />
          </CloseButton>
        </div>
        {/*
         ***** add onCLick func for Search post MVP *****
        {!selectedSidebarMenu && (
           <SearchButton onClick={null}>
            <SearchIcon />
          </SearchButton>
        )}
        */}
      </TopCloseContainer>
      <NavbarItems>
        {selectedSidebarMenu ? (
          <SelectedSidebarSubMenu>
            <h4 className='subMenuHeading'>{selectedSidebarMenu?.title}</h4>
            {selectedSidebarMenu?.submenu?.map((item, index) => showNavItem(item, index))}
          </SelectedSidebarSubMenu>
        ) : (
          <>
            {postLoginHeaderMenu?.map((item, index) => showNavItem(item, index))}
            {isMobileWidth && dashboardCTAButton ? (
            <PublicMenuItems>{drupalMenuHeader?.map((item, index) => showNavItem(item, index))}</PublicMenuItems>): null}
          </>
        )}
      </NavbarItems>
    </SideNavbarContainer>
  )
}

const mapStateToProps = (state) => ({
  postLoginHeaderMenu: state.publicContent.drupalMenuSuccess?.userDashboardMenu,
  drupalMenuHeader: state.publicContent.drupalMenuSuccess?.main,
  drupalNavData: state.publicContent?.getDrupalNavigationSuccess?.contents,
  sfDonorMessages: state.publicContent?.getMessagesSuccess?.sfDonorMessages,
  drupalNavDataLoading: state.publicContent?.getDrupalNavigationLoading,
  donor: state.donors.donors?.data,
  user: state.users?.user
})

const mapDispatchToProps = (dispatch) => ({
  initializeApp: (res) => dispatch(StartupActions.initializeApp(res)),
  updateDonorsPreferences: (id, preferences) => dispatch(donorsActions.updateDonorsPreferences(id, preferences))
})

export default connect(mapStateToProps, mapDispatchToProps)(LoggedInSideNavbar)
