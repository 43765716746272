import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import importer from '@takedapdt/biolife-core/src/importer'
import { Helmet } from 'react-helmet'
import TagManager from 'react-gtm-module'
const { SLUG_TYPES } = importer('Sagas/Constants/drupalConstants', null)
const renderDrupalComponent = importer('Constant/renderDrupalComponent')
const { getPathDetails } = importer('Helpers/Utils', null)
const LoaderSpinner = importer('Components/Loaders/LoaderSpinner')

/* ACTIONS */
const PublicContentActions = importer('Stores/PublicContent/Actions')

const Registration = ({ registrationPageData, fetchRegistrationPageData, registrationPageMeta, registrationPageDataLoading }) => {
  const signUpFormPageData = []
  const [formIndex, setformIndex] = useState()
  const [indexUpdated, setIndexUpdated] = useState()
  const existingAccountCreationStartTime = sessionStorage.getItem('accountCreationStartTime') || null

  useEffect(() => {
    if(!existingAccountCreationStartTime) {
      const accountCreationStartTime = new Date().getTime()
      sessionStorage.setItem('accountCreationStartTime', accountCreationStartTime)
    }
    TagManager.dataLayer({
      dataLayer: {
        event: 'profileRegistration',
        profileRegistrationStage: 'create account click'
      }
    })
    fetchRegistrationPageData(getPathDetails.slugType || SLUG_TYPES?.REGISTRATION)
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
  }, [])

  // Filter out components other than Text Banner and Breadcrumb from the registrationPageData array.
  registrationPageData = registrationPageData?.filter((component, index) => {
    if (component.type !== 'text_banner' && component.type !== 'breadcrumb') {
      // Push the components other than Text Banner and Breadcrumb to signUpFormPageData array.
      signUpFormPageData.push(component)
      if (!indexUpdated) {
        setformIndex(index)
        setIndexUpdated(true)
      }
      //Exclude the components other than Text Banner and Breadcrumb from the filtered array.
      return false
    } else {
      // Include Text Banner and Breadcrumb components in the filtered array.
      return true
    }
  })

  // Creates a new object representing the signup form page with components other than Text Banner and Breadcrumb
  const newSignUpFormPageData = { type: 'signup_step1_page', signUpPageContents: signUpFormPageData }
  // Insert the newSignUpFormPageData object at the specified formIndex in registrationPageData array.
  registrationPageData?.splice(formIndex, 0, newSignUpFormPageData)

  return (
    <>
      <Helmet>
        <title>{`${registrationPageMeta?.meta_title}`}</title>
        <meta name='description' content={`${registrationPageMeta?.meta_description}`} />
        <link rel='canonical' href={`${window.location.origin}${window.location.pathname.toLowerCase()}`} />
      </Helmet>
      {registrationPageDataLoading ? (
        <LoaderSpinner />
      ) : (
        registrationPageData?.map((component, index) => {
          if (component.type === 'breadcrumb') {
            component = {
              ...component,
              breadcrumbToggle: registrationPageMeta?.field_content_breadcrumb,
              breadcrumbMobileToggle: registrationPageMeta?.field_mobile_breadcrumb
            }
          }
          return renderDrupalComponent(component, index)
        })
      )}
    </>
  )
}
const mapStateToProps = (state) => ({
  registrationPageData: state.publicContent?.getRegistrationPageSuccess?.registration?.contents,
  registrationPageDataLoading: state.publicContent?.getRegistrationPageLoading?.registration,
  registrationPageMeta: state.publicContent?.getRegistrationPageSuccess?.registration?.metadata
})

const mapDispatchToProps = (dispatch) => ({
  fetchRegistrationPageData: (formType) => dispatch(PublicContentActions.getRegistrationPage(formType))
})

export default connect(mapStateToProps, mapDispatchToProps)(Registration)
