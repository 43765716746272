import React from 'react'
import styled, { keyframes } from 'styled-components'

const l20_1 = keyframes`
  0% {
    clip-path: polygon(50% 50%, 0 0, 50% 0%, 50% 0%, 50% 0%, 50% 0%, 50% 0%);
    border: 5px solid var(--color-dark-orange);
  }
  12.5% {
    clip-path: polygon(50% 50%, 0 0, 50% 0%, 100% 0%, 100% 0%, 100% 0%, 100% 0%);
  }
  25% {
    clip-path: polygon(50% 50%, 0 0, 50% 0%, 100% 0%, 100% 100%, 100% 100%, 100% 100%);
  }
  50% {
    clip-path: polygon(50% 50%, 0 0, 50% 0%, 100% 0%, 100% 100%, 50% 100%, 0% 100%);
  }
  62.5% {
    clip-path: polygon(50% 50%, 100% 0, 100% 0%, 100% 0%, 100% 100%, 50% 100%, 0% 100%);
    border: 5px solid var(--color-bl-orange);
  }
  75% {
    clip-path: polygon(50% 50%, 100% 100%, 100% 100%, 100% 100%, 100% 100%, 50% 100%, 0% 100%);
  }
  100% {
    clip-path: polygon(50% 50%, 50% 100%, 50% 100%, 50% 100%, 50% 100%, 50% 100%, 0% 100%);
    border: 5px solid var(--color-dark-orange);
  }
`

const l20_2 = keyframes`
  0% {
    transform: scaleY(1) rotate(0deg);
  }
  49.99% {
    transform: scaleY(1) rotate(135deg);
  }
  50% {
    transform: scaleY(-1) rotate(0deg);
  }
  100% {
    transform: scaleY(-1) rotate(-135deg);
  }
`

const SpinnerBackdropPartial = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 10000;
  top: 0;
  left: 0;
  background: var(--color-white-alpha-75);
`

const SpinnerBackdropFull = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 10000;
  left: 0;
  top: 0;
  background: var(--color-white, #ffffff);
`

const SpinnerContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  margin: 0;
  text-align: center;
  transform: translateX(-50%) translateY(-50%);
  border-radius: 50%;
  height: 126px;
  width: 126px;
  z-index: 1;
`

const SpinnerBorder = styled.div`
  border: 1px solid var(--color-light-grey);
  position: absolute;
  height: 122px;
  width: 122px;
  border-radius: 50%;
  left: 2px;
  top: 2px;
`

const SpinnerCircle = styled.div`
  width: 126px;
  aspect-ratio: 1;
  border-radius: 50%;
  border: 5px solid var(--color-dark-orange);
  animation: ${l20_1} 0.6s infinite linear alternate, ${l20_2} 1.2s infinite linear;
`

const SpinnerContainerRender = ({loaderMessage}) => (
  <SpinnerContainer>
    <SpinnerBorder></SpinnerBorder>
    <SpinnerCircle></SpinnerCircle>
    {loaderMessage && <LoaderMessage>{loaderMessage}</LoaderMessage>}
  </SpinnerContainer>
)

const LoaderMessage = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100vw;
  margin: 0;
  text-align: center;
  transform: translateX(-50%) translateY(100px);
`

const LoaderSpinner = ({ backdrop, loaderMessage }) => {
  return (
    <>
      {backdrop ? (
        backdrop === 'partial' ? (
          <SpinnerBackdropPartial>
            <SpinnerContainerRender loaderMessage={loaderMessage} />
          </SpinnerBackdropPartial>
        ) : (
          <SpinnerBackdropFull>
            <SpinnerContainerRender loaderMessage={loaderMessage} />
          </SpinnerBackdropFull>
        )
      ) : (
        <SpinnerContainerRender loaderMessage={loaderMessage} />
      )}
    </>
  )
}

export default LoaderSpinner
