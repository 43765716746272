import React from 'react'
import moment from 'moment'
import {commonMFAPasswordValidation} from '../../../Helpers/Utils';
const passwordValidations = (values) => {
  const errors = {}
  if (values.password) {
    commonMFAPasswordValidation({
      passwordField: 'password',
      errors: errors,
      values: values
    })
  } else {
    errors.password = 'passwordFieldIsRequired'
  }

  if (values.passwordConfirm) {
    if (values.passwordConfirm !== values.password) {
      errors.passwordConfirm = 'passwordsDoNotMatch'
    }
  } else {
    errors.passwordConfirm = 'confirmPasswordCannotBeEmpty'
  }

  if (!values.currentPassword) {
    errors.currentPassword = 'passwordCannotBeEmpty'
  }

  return errors
}

export default passwordValidations
