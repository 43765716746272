import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import {
  AppointmentSlotsGrid,
  AppointmentSlot,
  AvailableSlotTime,
  AvailableSlotTimeLabel,
  AppointmentSelectionErrorContainer,
  LoadingState,
  AppointmentSlotTimeRange
} from './styled'
import LoaderBar from '../../Loaders/LoaderBar'
import moment from 'moment'

const AppointmentHours = ({ appointment, handleAppointment, availableSlots = [], centerSlotsLoading = false }) => {
  const { t, i18n } = useTranslation('AppointmentHours')
  const [selectedHour, setSelectedHours] = useState(null)
  const [changes, setChanges] = useState(false)
  const [availableSlotsState, setAvailableSlotsState] = useState(availableSlots)
  const [morning, setMorning] = useState([])
  const [afternoon, setAfternoon] = useState([])
  const [evening, setEvening] = useState([])
  const [activeTimeSlotRange, setActiveTimeSlotRange] = useState(t('morning'))

  const handleHoursChange = (e, { value }) => {
    sessionStorage.removeItem('APPOINTMENT_LOCATION')
    sessionStorage.removeItem('SELECTED_LANGUAGE')
    let newAppointment = {
      ...appointment,
      startTime: value?.appointmentSlotTime,
      startDateTime: value?.appointmentSlotDateTime
    }
    setSelectedHours(value?.appointmentSlotTime)
    handleAppointment(newAppointment)
    sessionStorage?.setItem('APPOINTMENT_LOCATION', JSON.stringify(newAppointment))
    sessionStorage?.setItem('SELECTED_LANGUAGE', i18n.language)
  }

  /**
   * This useEffect is used to filter the available slots based on the time range selected by the user
   */
  useEffect(() => {
    const morning = [], afternoon = [], evening = []

    availableSlots?.forEach(slot => {
      const hours = moment.parseZone(slot?.appointmentSlotDateTime).hours()
      if (hours >= 0 && hours < 12) {
        morning?.push(slot)
      } else if (hours >= 12 && hours < 18) {
        afternoon?.push(slot)
      } else {
        evening?.push(slot)
      }
    })

    setTimeout(() => {
      if (appointment?.startDateTime) {
        const hours = moment.parseZone(appointment?.startDateTime).hours()
        if (hours >= 0 && hours < 12) {
          setActiveTimeSlotRange(t('morning'))
        } else if (hours >= 12 && hours < 18) {
          setActiveTimeSlotRange(t('afternoon'))
        } else {
          setActiveTimeSlotRange(t('evening'))
        }
      }
    }, 0)

    setMorning(morning)
    setAfternoon(afternoon)
    setEvening(evening)
  }, [availableSlots])

  /**
   * This useEffect is used to set the active time slot range based on the available slots.
   */
  useEffect(() => {
    if (morning?.length === 0) {
      setActiveTimeSlotRange(t('afternoon'))

      if (afternoon?.length === 0) {
        setActiveTimeSlotRange(t('evening'))
      }
    } else {
      setActiveTimeSlotRange(t('morning'))
    }

    setAvailableSlotsState(activeTimeSlotRange === t('morning') ? morning : activeTimeSlotRange === t('afternoon') ? afternoon : evening)
  }, [morning, afternoon, evening])

  /**
   * This useEffect is used to set the available slots based on the time range selected by the user.
   */
  useEffect(() => {
    setAvailableSlotsState(activeTimeSlotRange === t('morning') ? morning : activeTimeSlotRange === t('afternoon') ? afternoon : evening)
  }, [activeTimeSlotRange])

  useEffect(() => {
    if (appointment.startTime) {
      sessionStorage.removeItem('APPOINTMENT_LOCATION')
      sessionStorage.removeItem('SELECTED_LANGUAGE')
      setSelectedHours(appointment.startTime)
      sessionStorage?.setItem('APPOINTMENT_LOCATION', JSON.stringify(appointment))
      sessionStorage?.setItem('SELECTED_LANGUAGE', i18n.language)
    } else {
      setSelectedHours(null)
    }
    if (appointment.appointmentType) {
      setChanges(true)
    }
  }, [appointment, availableSlots])

  if (centerSlotsLoading) {
    return (
      <LoadingState>
        <LoaderBar />
      </LoadingState>
    )
  }

  if (appointment && appointment.appointmentType === '') {
    return (
      <AppointmentSelectionErrorContainer>
        <h5>
          <strong>{t('pleaseSelectAnAppointmentType')}</strong>
        </h5>
      </AppointmentSelectionErrorContainer>
    )
  }

  return (
    <>
      {availableSlots?.length > 0 && <AppointmentSlotTimeRange>
        {morning?.length > 0 && <button
          className={activeTimeSlotRange === t('morning') ? 'active' : ''}
          onClick={() => setActiveTimeSlotRange(t('morning'))}
        >
          {t('morning')}
        </button>}
        {afternoon?.length > 0 && <button
          className={activeTimeSlotRange === t('afternoon') ? 'active' : ''}
          onClick={() => setActiveTimeSlotRange(t('afternoon'))}
        >
          {t('afternoon')}
        </button>}
        {evening?.length > 0 && <button
          className={activeTimeSlotRange === t('evening') ? 'active' : ''}
          onClick={() => setActiveTimeSlotRange(t('evening'))}
        >
          {t('evening')}
        </button>}
      </AppointmentSlotTimeRange>}
      <AppointmentSlotsGrid $availableSlots={availableSlotsState?.length}>
        {availableSlotsState &&
          availableSlotsState.length > 0 &&
          availableSlotsState.map((slot, index) => (
            <AppointmentSlot $selectedSlot={selectedHour === slot?.appointmentSlotTime}>
              <AvailableSlotTime
                type='radio'
                id={slot?.appointmentSlotTime}
                value={slot?.appointmentSlotTime}
                name='hours'
                onChange={(e) => handleHoursChange(e, { value: slot })}
                checked={selectedHour === slot?.appointmentSlotTime}
              />
              <AvailableSlotTimeLabel for={slot?.appointmentSlotTime}>{slot?.appointmentSlotTime}</AvailableSlotTimeLabel>
            </AppointmentSlot>
          ))}
      </AppointmentSlotsGrid>
    </>
  )
}

export default AppointmentHours
