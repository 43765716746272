import React, { useEffect, useState, useMemo } from 'react'
import { connect } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { Dimmer } from 'semantic-ui-react'
import './styles.scss'

import importer from '@takedapdt/biolife-core/src/importer'
//COMPONENTS
const AccountCreateForm = importer('Components/Forms/AccountCreateForm')
const renderDrupalComponent = importer('Constant/renderDrupalComponent')
const LoaderSpinner = importer('Components/Loaders/LoaderSpinner')
//ACTIONS
const centersActions = importer('Stores/Centers/Actions')
const publicContentActions = importer('Stores/PublicContent/Actions')
const AuthCustomActions = importer('Stores/AuthCustom/Actions')
const donorsActions = importer('Stores/Donors/Actions')

const AccountCreate = ({
  centers,
  getDonorCentersAll,
  getDonorCentersAllLoading,
  getCountries,
  getAllStates,
  getGenders,
  genders,
  countries,
  states,
  getEthnicityOptions,
  ethnicityOptions,
  register,
  registerSuccess,
  registerFailure,
  phoneTypes,
  getPhoneTypes,
  donor,
  signUpUser,
  donorBySfid,
  getDonorBySfid,
  signupPageData
}) => {
  const location = useLocation()
  const [registrationId, setRegistrationId] = useState(null)
  const [registrationDonor, setRegistrationDonor] = useState(null)
  const [submittingForm, setSubmittingForm] = useState(false)

  useEffect(() => {
    if (location.search) {
      const searchParams = new URLSearchParams(location.search)
      const regId = searchParams.get('registrationId')
      const pid = searchParams.get('pid')
      setRegistrationId(regId)
      if (regId && pid) {
        getDonorBySfid(regId, pid)
      }
    }
    if (location.registrationId && location.pid) {
      setRegistrationId(location.registrationId)
      getDonorBySfid(location.registrationId, location.pid)
    }
  }, [location])

  useEffect(() => {
    if (donorBySfid && donorBySfid.sfid == registrationId) {
      window.sessionStorage.setItem('lead', JSON.stringify(donorBySfid))
      setRegistrationDonor(donorBySfid)
    }
  }, [donorBySfid])

  useEffect(() => {
    getCountries()
    getAllStates()
    getGenders()
    getPhoneTypes()
    getEthnicityOptions()
  }, [])

  const signupPageBreadcrumb = signupPageData?.metadata?.field_content_breadcrumb
  const signupPageMobileBreadcrumb = signupPageData?.metadata?.field_mobile_breadcrumb
  const { otherData, accountFormData } = useMemo(() => {
    // Find first form component in pageData
    const firstFormComponentIndex = signupPageData?.contents?.findIndex((component) => component.type === 'form_field_component')
    if (firstFormComponentIndex !== -1) {
      //Form other data is all items before the first form component
      const otherData = signupPageData?.contents?.slice(0, firstFormComponentIndex)
      const accountFormData = signupPageData?.contents?.slice(firstFormComponentIndex)
      return { otherData, accountFormData }
    }
    // If form component is not found, return all items in pageData
    return { otherData: signupPageData?.contents || [], accountFormData: [] }
  }, [signupPageData])

  return (
    <div className='AccountCreate'>
      {otherData?.map((component, index) => {
        if (component.type === 'breadcrumb') {
          let modifiedBreadcrumb = {
            ...component,
            breadcrumbToggle: signupPageBreadcrumb,
            breadcrumbMobileToggle: signupPageMobileBreadcrumb
          }
          component = modifiedBreadcrumb
        }
        return renderDrupalComponent(component, index)
      })}
      <AccountCreateForm
        genders={genders}
        ethnicityOptions={ethnicityOptions}
        states={states}
        centers={centers}
        getDonorCentersAll={getDonorCentersAll}
        accountFormData={accountFormData}
        countries={countries}
        phoneTypes={phoneTypes}
        donor={donor}
        donorDetailsSaved={registrationDonor || signUpUser?.donorDetails}
        getGenders={getGenders}
        getEthnicityOptions={getEthnicityOptions}
        register={register}
        getPhoneTypes={getPhoneTypes}
        registerSuccess={registerSuccess}
        registerFailure={registerFailure}
        getDonorCentersAllLoading={getDonorCentersAllLoading}
        setSubmissionLoader={setSubmittingForm}
      />
      {submittingForm && <LoaderSpinner backdrop='partial' />}
    </div>
  )
}

const mapStateToProps = (state) => ({
  centers: state.centers.donorCenters?.data,
  center: state.centers.getAllCentersSuccess,
  genders: state.publicContent.Genders,
  ethnicityOptions: state.publicContent.Ethnicity,
  states: state.publicContent.States,
  countries: state.publicContent.Countries,
  phoneTypes: state.publicContent.PhoneTypes,
  getDonorCentersAllLoading: state.centers.getAllCentersLoading,
  signUpUser: state.users.registerSignUp,
  registerLoading: state.authCustom.registerLoading,
  registerSuccess: state.authCustom.registerSuccess,
  registerFailure: state.authCustom.registerFailure,
  donor: state.donors.donors?.data,
  donorBySfid: state.donors.getDonorBySfidSuccess?.data
})

const mapDispatchToProps = (dispatch) => ({
  getDonorCentersAll: (searchStr, isDIS8Center, searchType, addNewDonorBonusInformation, setCenterDistance) =>
    dispatch(centersActions.getDonorCentersAll(searchStr, isDIS8Center, searchType, addNewDonorBonusInformation, setCenterDistance)),
  register: (donor) => dispatch(AuthCustomActions.register(donor)),
  getDonorBySfid: (sfid, pid) => dispatch(donorsActions.getDonorBySfid(sfid, pid)),
  getGenders: () => dispatch(publicContentActions.getGenders()),
  getEthnicityOptions: () => dispatch(publicContentActions.getEthnicityOptions()),
  getAllStates: () => dispatch(publicContentActions.getAllStates()),
  getCountries: () => dispatch(publicContentActions.getCountries()),
  getPhoneTypes: () => dispatch(publicContentActions.getPhoneTypes())
})

export default connect(mapStateToProps, mapDispatchToProps)(AccountCreate)
