import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Message } from 'semantic-ui-react'
import { connect } from 'react-redux'
import OtpInput from 'react-otp-input'
import Modal from '../../../Components/Modal'
import { isMobileWeb } from '../../../Helpers/Utils'
import PrimaryButton from '../../../Components/Buttons/PrimaryButton'
import UserActions from '@takedapdt/biolife-core/src/Stores/Users/Actions'
import DonorsActions from '@takedapdt/biolife-core/src/Stores/Donors/Actions'
import { devices } from '../../../utils/styledComponentHelpers'
import { useScreenSize } from './../../../Contexts/ResponsiveContextProvider'
const StyledContainer = styled.div`
  display: flex;
  justify-content: center;
`
const StyledModalDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${({$verifyMfaCodeSuccess})=>$verifyMfaCodeSuccess ? "100%": '95%'};
  text-align: center;
`

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: ${({$verifyMfaCodeSuccess, $isMobileWidth})=>$verifyMfaCodeSuccess ? $isMobileWidth ? "13rem 3rem" : "13rem 12rem": '4.8rem'};;
`

const Styledh4 = styled.h4`
  font-family: var(--font-family-bold) !important;
  color: var(--color-dark-focus-blue);
  font-weight: bold !important;
`

const Styledh3 = styled.h3`
  color: var(--color-dark-focus-blue);
  font-weight: bold !important;
`

const Styledp = styled.p`
  color: var(--color-rich-black);
  margin-top: ${({ $marginTop }) => $marginTop};
`

const StyledSpacingDiv = styled.div`
  margin: ${({ $margin }) => $margin};
  ${devices.mobile} {
    width: 100%;
  }
`

const StyledSpan = styled.span`
  cursor: pointer;
`

const MFAModal = ({
  user,
  getDonor,
  maskedPhoneNumber,
  unMaskedPhoneNumber,
  open,
  onClose,
  userOktaId,
  resendEnrollMfaCode,
  verifyMfaCode,
  resendEnrollMfaCodeFailure,
  verifyMfaCodeLoading,
  verifyMfaCodeSuccess,
  verifyMfaCodeFailure,
  enrollMfaSuccess
}) => {
  const { t } = useTranslation('MfaModal')
  const { isMobileWidth } = useScreenSize()
  const [otp, setOtp] = useState('')
  const [counter, setCounter] = useState(0)
  const [isSubmitted, setIsSubmitted] = useState(false)
  const modalTextContent = () => {
    return t('modalText').replace('{0}', maskedPhoneNumber)
  }

  const handlePrimary = () => {
    if (enrollMfaSuccess && userOktaId) {
      verifyMfaCode(userOktaId, otp, enrollMfaSuccess)
      setIsSubmitted(true)
    }
  }

  const handleSecondaryTextLinkClick = () => {
    if (userOktaId && unMaskedPhoneNumber) {
      resendEnrollMfaCode(userOktaId, unMaskedPhoneNumber)
    }
  }

  useEffect(() => {
    if (verifyMfaCodeSuccess) {
      getDonor(user.id)
    }
  }, [verifyMfaCodeSuccess])

  useEffect(() => {
    const interval = counter > 0 && setInterval(() => setCounter(counter - 1), 1000)
    return () => {
      clearInterval(interval)
    }
  }, [counter])

  useEffect(() => {
    if (open) {
      setCounter(30)
    }
    return () => {
      setOtp(''), setCounter(0), setIsSubmitted(false)
    }
  }, [open])

  const disableStatus = () => {
    if (otp.length < 6) {
      return true
    } else {
      return false
    }
  }
  return (
    <Modal maxWidth={836} maxWidthMobile={370} maxHeight={407} maxHeightMobile={470} isOpen={open} onClose={onClose}>
      <StyledContainer>
      <StyledModalDiv $verifyMfaCodeSuccess={verifyMfaCodeSuccess}>
        {verifyMfaCodeSuccess ? (
          <StyledDiv $verifyMfaCodeSuccess={verifyMfaCodeSuccess} $isMobileWidth={isMobileWidth}>
            <Styledh4>{t('enrollSuccessMessage')}</Styledh4>
            <StyledSpacingDiv $margin='4.8rem 0rem 0rem 0rem'>
              <PrimaryButton
                text={t('primaryModalButton')}
                style={{ padding: '1.6rem 3rem', width: '100%' }}
                onClick={onClose}
                fluid={isMobileWidth}
              />
            </StyledSpacingDiv>
          </StyledDiv>
        ) : (
          <StyledDiv>
            <Styledh4>{t('headerText')}</Styledh4>
            <Styledp $marginTop='0.8rem'>{modalTextContent()}</Styledp>
            <StyledSpacingDiv
              $margin={
                (isSubmitted && verifyMfaCodeFailure) || resendEnrollMfaCodeFailure ? '1.6rem 0rem 1.6rem 0rem' : '3.2rem 0rem 0rem 0rem'
              }
            >
              <OtpInput
                value={otp}
                onChange={setOtp}
                numInputs={6}
                renderInput={(props) => <input {...props} />}
                inputStyle={{
                  height: '63px',
                  width: '43px',
                  marginRight: isMobileWeb() ? '12px' : '16px',
                  borderColor: '#707070',
                  borderWidth: '1px'
                }}
                shouldAutoFocus
              />
            </StyledSpacingDiv>
            {(isSubmitted && verifyMfaCodeFailure) || resendEnrollMfaCodeFailure ? (
              <Message error>
                {verifyMfaCodeFailure ? <p>{verifyMfaCodeFailure?.message}</p> : null}
                {resendEnrollMfaCodeFailure ? <p>{resendEnrollMfaCodeFailure?.message}</p> : null}
              </Message>
            ) : null}
            <StyledSpacingDiv
              $margin={
                (isSubmitted && verifyMfaCodeFailure) || resendEnrollMfaCodeFailure ? '1.6rem 0rem 1.6rem 0rem' : '3.2rem 0rem 1.6rem 0rem'
              }
            >
              <PrimaryButton
                text={t('primaryModalButton')}
                loading={verifyMfaCodeLoading}
                type='button'
                width={280}
                height={50}
                fontSize={16}
                onClick={handlePrimary}
                disabled={disableStatus()}
                fluid={isMobileWidth}
              />
            </StyledSpacingDiv>
            {counter === 0 ? (
              <div>
                <Styledp>
                  {t('secondaryText')}&nbsp;
                  <StyledSpan
                    onClick={() => {
                      setCounter(30)
                      handleSecondaryTextLinkClick()
                    }}
                  >
                    {t('secondaryTextLink')}
                  </StyledSpan>
                </Styledp>
              </div>
            ) : (
              <Styledp>
                {t('resendOtpMessage')} {counter}s
              </Styledp>
            )}
          </StyledDiv>
        )}
      </StyledModalDiv>
      </StyledContainer>
    </Modal>
  )
}

const mapStateToProps = (state) => ({
  enrollMfaSuccess: state.users?.enrollMfaSuccess?.data,
  resendEnrollMfaCodeSuccess: state.users?.resendEnrollMfaCodeSuccess,
  resendEnrollMfaCodeFailure: state.users?.resendEnrollMfaCodeFailure?.response?.data,
  verifyMfaCodeSuccess: state.users?.verifyMfaCodeSuccess,
  verifyMfaCodeFailure: state.users?.verifyMfaCodeFailure?.response?.data,
  verifyMfaCodeLoading: state.users?.verifyMfaCodeLoading,
  userOktaId: state.users?.user?.oktaUserId,
  user: state.users?.user
})

const mapDispatchToProps = (dispatch) => ({
  getDonor: (id) => dispatch(DonorsActions.getDonors(id)),
  resendEnrollMfaCode: (userId, phoneNumber) => dispatch(UserActions.resendEnrollMfaCode(userId, phoneNumber)),
  verifyMfaCode: (userId, passCode, factorActivationId) => dispatch(UserActions.verifyMfaCode(userId, passCode, factorActivationId))
})

export default connect(mapStateToProps, mapDispatchToProps)(MFAModal)
