import styled from 'styled-components'
import { devices } from '../../../utils/styledComponentHelpers'
import { ReactComponent as ChevronDown } from '../../../../assets/media/icons/icon-chevron-down.svg'

export const StyledDropdownSelect = styled.div`
  width: ${(props) => `${props.$width}px`};
  position: relative;
`
export const StyledDropdownSelectButton = styled.div`
  height: 6.5rem;
  padding: 1.6rem 1.8rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 3.25rem;
  background-color: var(--color-dark-focus-blue);
  color: var(--color-white);
`
export const StyledIcon = styled(ChevronDown)`
  width: 2.4rem;
  height: 2.4rem;
  path: first-child {
    fill: var(--color-white);
  }
`
export const StyledDropdownSelectMenu = styled.div`
  background-color: var(--color-white);
  box-shadow: 0rem 0.4rem 0.8rem #00000029;
  border-radius: 0.4rem;
  position: absolute;
  overflow: auto;
  z-index: 2;
  width: ${(props) => `${props.$width}px`};
  margin-top: 0.7rem;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: left;
  & > :first-child {
    border-radius: 0.4rem 0.4rem 0rem 0rem;
  }
  & > :last-child {
    border-radius: 0rem 0rem 0.4rem 0.4rem;
  }
`
export const StyledDropdownSelectMenuOption = styled.div`
  padding: 2rem 5rem 2rem 1.6rem;
  font-size: var(--caption-mobile-font-size);
  border: 0.1rem solid #bfbfbf;
  background-color: ${(props) => props.$isOptionDisabled && 'var(--color-light-grey)'};
  color: ${(props) => props.$isOptionDisabled && 'var(--color-dark-grey)'};
`
