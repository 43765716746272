import importer from '@takedapdt/biolife-core/src/importer'
const isValidEmail = importer('Helpers/isValidEmail')
const validate = (values) => {
  const errors = {}
  if (values['Email Address']) {
    if (!isValidEmail(values['Email Address'])) {
      errors['Email Address'] = 'emailAddressMustBeInValid'
    }
  } else {
    errors['Email Address'] = 'emailAddressIsRequired'
  }

  if (!values['Password']) { 
    errors['Password'] = 'passwordIsRequired'
  } 
  return errors
}

export default validate
